import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import * as env from "../../env";
import * as plan from "../../constants/plan";
import { PROFILE_PICTURE } from "../../constants/domain";
import * as app from "../../constants/app";
import * as campaign from "../../constants/campaign";
import * as apiUtil from "../../constants/apiUtil";
import * as creditUtil from "../popup/credit/creditUtils";
import styles from "./styles";

/**
 * プロフィール.
 *
 * props
 *  -> handlePlanSelect            会員プラン選択画面を表示
 *  -> handleChangeCredit          クレジットカード変更画面を表示
 *  -> handleProfPicture           プロフィール画像変更操作
 *  -> handleSpecialCubeGroupList  スペシャル図鑑一覧を表示
 *  -> handleCampaignList          キャンペーン一覧を表示
 *  -> handleDigitalCard           デジタル会員証を表示
 *  -> handleLogout                ログアウト
 *  -> handleWithdrawal            退会
 *  -> handlePlanCancel            プランキャンセル
 */
const Profile = (props) => {
    /** CSS. */
    const classes = styles();

    /** 会員情報アイコンDOM. */
    const profInfoIconRef = useRef();
    /** 会員情報クローズDOM. */
    const profInfoCloseRef = useRef();
    /** 会員情報詳細DOM. */
    const profInfoDtlRef = useRef();

    /** 会員情報詳細の表示/非表示. */
    const [isMemberInfo, setIsMemberInfo] = useState(false);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** プロフィール画像. */
    const profPicture = useSelector(state => state.Member.profPicture);
    /** 入会日. */
    const joinDate = useSelector(state => state.Member.joinDate);
    /** 有効期限. */
    const expirationDate = useSelector(state => state.Member.expirationDate);
    /** 変更後の会員プラン. */
    const changeMemberPlan = useSelector(state => state.Member.changeMemberPlan);
    /** 変更日. */
    const changeDate = useSelector(state => state.Member.changeDate);
    /** クレジットカード番号. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** キャンペーン詳細の表示/非表示. */
    const isCampaign = useSelector(state => state.Member.isCampaign);
    /** 会員プランの金額. */
    const planPrice = useSelector(state => state.Member.planPrice);
    /** キャンペーン一覧. */
    const campaignList = useSelector(state => state.Member.campaignList);

    /**
     * 会員情報詳細押下.
     */
    const handleProfInfo = () => {
        // 要素の取得に失敗.
        if (profInfoIconRef.current === null
            || profInfoCloseRef.current === null
            || profInfoDtlRef.current === null
        ) return;

        if (isMemberInfo) {
            profInfoIconRef.current.classList.remove("is-close");
            profInfoCloseRef.current.classList.remove("is-open");
            profInfoDtlRef.current.classList.remove("is-show");
            setIsMemberInfo(!isMemberInfo);
        } else {
            profInfoIconRef.current.classList.add("is-close");
            profInfoCloseRef.current.classList.add("is-open");
            profInfoDtlRef.current.classList.add("is-show");
            setIsMemberInfo(!isMemberInfo);
        }
    }

    /**
     * TSUBURAYAアカウントページへ遷移（外部ページ）.
     */
    const handleTsuburayaAccount = () => {
        window.location.href = env.TSUBURAYA_MYPAGE + encodeURIComponent(window.location.href);
    }

    /**
     * アプリの通知設定へ遷移（アプリ対応）.
     */
    const handleAppPushSetting = () => {
        if (app.isAndroidApp()) {
            window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_PUSH_SETTING;
        }
        if (app.isIosApp()) {
            window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_PUSH_SETTING;
        }
    }

    /**
     * キャンペーン詳細を取得.
     */
    const getCampaignDetail = () => {
        switch (memberPlan) {
            case plan.STANDARD:
                return "＊有効期限が切れると現在のプラン スタンダード　月額料（月額 " + Number(planPrice[plan.STANDARD]).toLocaleString() + "円/税込）が課金されます。";
            case plan.PREMIUM:
                return "＊有効期限が切れると現在のプラン　プレミアム　年会費（年額 " + Number(planPrice[plan.PREMIUM]).toLocaleString() + "円/税込）が課金されます。";
            default:
                return "";
        }
    }

    /**
     * プロフィール画像変更操作.
     */
    const handleProfPicture = () => {
        if (isMemberInfo) {
            handleProfInfo();
        }
        props.handleProfPicture();
    }

    /**
     * 新規取得キャンペーン判定.
     */
    const isNewCampaign = () => {
        for (const camp of campaignList) {
            if (camp[campaign.NEW_FLG]) return true;
        }
        return false;
    }

    return (
        <span>
            <div className="c-prof__head c-headnav"></div>
            <div className="c-prof__headBg"></div>
            <div className="c-prof__menu c-prof__menu--app">
                <div className="c-prof__vis c-prof__vis--profile">
                    <div className="c-prof__headTxt">プロフィール</div>
                    <div className="c-prof__profile__fav">
                        <div className="c-prof__profile__favWrap">
                            <div className="c-prof__profile__favCharacter">
                                <img className="lozad loaded"
                                    data-src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)}
                                    src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)}
                                    alt="" data-loaded="true" />
                            </div>
                            <span className={classes.clickable + " c-prof__profile__favIcon c-icon c-icon--camera"} onClick={handleProfPicture}>
                                <div className="c-icon__Txt c-picIconCamera"></div>
                            </span>
                        </div>
                    </div>
                    <div className="c-prof__profile__edit">
                        <ul className="c-prof__profile__editList">
                            <li className="c-prof__profile__editWrap" key="c-prof__profile__editWrap_rank">
                                <p className="c-prof__profile__editName">登録プラン</p>
                                {plan.isFree(memberPlan) && <span className="c-prof__profile__editItem c-prof__profile__rank c-prof__profile__rank--free"></span>}
                                {plan.isStandard(memberPlan) && <span className="c-prof__profile__editItem c-prof__profile__rank c-prof__profile__rank--standard"></span>}
                                {plan.isPremium(memberPlan) && <span className="c-prof__profile__editItem c-prof__profile__rank c-prof__profile__rank--premium"></span>}
                                <span className={classes.clickable + " c-prof__profile__editIcon c-icon c-icon--edit"} onClick={props.handlePlanSelect}>
                                <div className="c-icon__Txt c-picIconEdit"></div></span>
                            </li>
                            {/* アプリ対応：アプリで非表示. */}
                            {!app.isAllApp() &&
                                <li className="c-prof__profile__editWrap">
                                    <p className="c-prof__profile__editName">クレジットカード<br />No.</p>
                                    <span className="c-prof__profile__editItem">{creditNumber === "" ? "未登録" : creditUtil.getCreditNumberSpace(creditNumber)}</span>
                                    <div className="c-prof__profile__editIcon c-icon c-icon--edit" onClick={props.handleChangeCredit}>
                                        <div className="c-icon__Txt c-picIconEdit"></div>
                                    </div>
                                </li>
                            }
                        </ul>
                        {/* アプリ対応：アプリで非表示. */}
                        {!app.isAllApp() &&
                            <div className="c-profInfo">
                                <div className="c-profInfo__head" onClick={() => handleProfInfo()}>
                                    <div className="c-profInfo__ttl">登録情報詳細</div>
                                    <img
                                        className="c-profInfo__icon"
                                        src="/assets/images/icon-triangle-border.svg"
                                        alt=""
                                        ref={profInfoIconRef}
                                    />
                                    <div
                                        className="c-profInfo__close"
                                        ref={profInfoCloseRef}
                                    >
                                        <span></span><span></span>
                                    </div>
                                </div>
                                <div
                                    className="c-profInfo__dtl"
                                    ref={profInfoDtlRef}
                                >
                                    <ul className="c-profInfo__dtlWrap">
                                        <li className="c-profInfo__dtlItem">
                                            <div className="c-profInfo__txt">現在のプラン</div>
                                            <div className="c-profInfo__status">{plan.getMemberStatus(memberPlan)}</div>
                                        </li>
                                        <li className="c-profInfo__dtlItem">
                                            <div className="c-profInfo__txt">入会日</div>
                                            <div className="c-profInfo__status">{apiUtil.getDatefromISO(joinDate)}</div>
                                        </li>
                                        {!plan.isFree(memberPlan) &&
                                            <li className="c-profInfo__dtlItem">
                                                <div className="c-profInfo__txt">有効期限（自動更新）</div>
                                                <div className="c-profInfo__status">{expirationDate}</div>
                                            </li>
                                        }
                                        {isCampaign &&
                                            <li className="c-profInfo__dtlItem">
                                                <div className="c-profInfo__announce">{getCampaignDetail()}</div>
                                            </li>
                                        }
                                    </ul>
                                    {/* プラン予約中. */}
                                    {changeMemberPlan !== "" &&
                                        <span>
                                            <div className="c-profInfo__announce">プラン変更予約中</div>
                                            <ul className="c-profInfo__dtlWrap">
                                                <li className="c-profInfo__dtlItemChange">
                                                    <div className="c-profInfo__txt">変更後のプラン</div>
                                                    <div className="c-profInfo__status">{plan.getMemberStatus(changeMemberPlan)}</div>
                                                </li>
                                                <li className="c-profInfo__dtlItemChange">
                                                    <div className="c-profInfo__txt">新プラン開始日</div>
                                                    <div className="c-profInfo__status">{changeDate}</div>
                                                </li>
                                            </ul>
                                            <span className="c-btn c-btn--black" onClick={props.handlePlanCancel}>プラン変更をキャンセルする</span>
                                        </span>
                                    }
                                </div>
                            </div>
                        }
                        {/* アプリ対応：アプリで非表示. */}
                        {!app.isAllApp() &&
                            <div
                                className={classes.clickable + " c-prof__profile__btn c-prof__profile__btn--datacube c-btn c-btn--gray"}
                                onClick={props.handleSpecialCubeGroupList}
                            >
                                <div className="c-prof__profile__btnTxt">データキューブ</div>
                            </div>
                        }
                        <div
                            className={classes.clickable + " c-prof__profile__btn c-btn c-btn--gray"}
                            onClick={props.handleCampaignList}
                        >
                            {isNewCampaign() && <div className="c-prof__profile__btnActive"></div>}
                            <div className="c-prof__profile__btnTxt">参加・取得キャンペーン一覧</div>
                        </div>
                        <div
                            className={classes.clickable + " c-prof__profile__btn c-btn c-btn--gray"}
                            onClick={props.handleDigitalCard}
                        >
                            <div className="c-prof__profile__btnTxt">デジタル会員証</div>
                        </div>
                        <div className="c-prof__profile__editFoot">
                            {/* アプリ対応：アプリで非表示. */}
                            {!app.isAllApp() &&
                                <div
                                    className={classes.clickable + " c-prof__profile__btn c-btn c-btn--tinBlack"}
                                    onClick={() => handleTsuburayaAccount()}
                                >
                                    TSUBURAYA アカウント設定を変更
                                </div>
                            }
                            {/* アプリ対応：アプリで表示. */}
                            {app.isAllApp() &&
                                <div
                                    className={classes.clickable + " c-prof__profile__btn c-btn c-btn--tinBlack"}
                                    onClick={() => handleAppPushSetting()}
                                >
                                    アプリの通知設定
                                </div>
                            }
                            <div
                                className={classes.clickable + " c-prof__profile__btn c-prof__profile__btn--logout c-btn c-btn--tinBlack"}
                                onClick={props.handleLogout}
                            >
                                ログアウト
                            </div>
                            {/* アプリ対応：アプリで非表示. */}
                            {!app.isAllApp() &&
                                <span
                                    className={classes.clickable + " c-prof__profile__drawal"}
                                    onClick={props.handleWithdrawal}
                                >
                                    退会手続きはこちら
                                </span>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default Profile;
