import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import * as actions from "./actions";
import * as lectureActions from "../lecture/actions";
import * as app from "../../../constants/app";
import * as credit from "../../../constants/credit";
import * as creditUtil from "./creditUtils";
import * as historys from "../../../historys";

/**
 * 決済完了画面.
 */
const CreditResult = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** ダイアログ種別. */
    const kind = useSelector(state => state.Credit.kind);
    /** 選択された会員プラン. */
    const plan = useSelector(state => state.Credit.plan);
    /** 決済金額. */
    const amount = useSelector(state => state.Credit.amount);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** クレジットカード番号（マスク）. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** 有効期限. */
    const expirationDate = useSelector(state => state.Member.expirationDate);
    /** 変更後のプラン開始日. */
    const changeDate = useSelector(state => state.Member.changeDate);
    /** 初回ログインフラグ. */
    const isFirstLogin = useSelector(state => state.LectureFirst.isFirstLogin);
    /** トップ画面判定. */
    const isTop = useSelector(state => state.Screen.isTop);
    /** 無料期間. */
    const freePeriod = useSelector(state => state.LocalCredit.freePeriod);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        return () => {
            document.body.classList.remove("is-fixed");
            dispatch(actions.closePopupCreditResult());
        }
    }, [dispatch]);

    /**
     * キャンセルボタン押下時の処理.
     */
    const close = () => {
        dispatch(actions.closePopupCreditResult());
        // アプリではレクチャー画面非表示（アプリ対応）.
        if (!app.isAllApp()) {
            // 初回ログインはレクチャー画面を表示.
            if (memberId !== "" && isFirstLogin && isTop) dispatch(lectureActions.openPopupLecture());
        }
        switch (kind) {
            case credit.WITHDRAWAL:         // 退会完了ダイアログを閉じたとき
            case credit.RESERVE_WITHDRAWAL: // 退会予約完了ダイアログを閉じたとき
                // TOP画面に遷移する
                historys.historyTop(history);
        }
    };

    /**
     * タイトル表示.
     */
    const title = () => {
        switch (kind) {
            case credit.REGISTER: // クレジットカード登録.
            case credit.FREE:     // 無料プラン登録.
                return "登録完了";
            case credit.CHANGE:   // クレジットカード変更.
            case credit.PLAN_CHANGE: // プラン変更完了.
                return "変更完了";
            case credit.PLAN:     // 会員プラン決済(登録込み)完了.
            case credit.RENTAL:   // レンタル決済(登録込み)完了.
            case credit.PLAN_CAMPAIGN: // 無料キャンペーンプラン登録完了.
                return "登録と決済完了";
            case credit.PLAN_EXPIRED:   // 会員プラン決済(更新込み)完了.
            case credit.RENTAL_EXPIRED: // レンタル決済(更新込み)完了.
            case credit.PLAN_CAMPAIGN_EXPIRED: // 無料キャンペーンプラン決済(更新込み)完了.
                return "更新と決済完了";
            case credit.RESERVE_CHANGE: // 変更予約完了.
                return "変更予約完了";
            case credit.PLAN_CANCEL: // 変更予約キャンセル完了.
                return "プラン変更予約キャンセル完了";
            case credit.WITHDRAWAL:  // 退会完了.
                return "退会完了";
            case credit.RESERVE_WITHDRAWAL: // 退会予約完了.
                return "退会予約完了";
            case credit.MOVIE_TICKET: // ムビチケ応募完了.
                return "ムビチケ応募完了";
            default:
                return "決済完了";
        }
    };

    /**
     * 完了メッセージ表示.
     */
    const completed = () => {
        switch (kind) {
            case credit.REGISTER: // クレジットカード登録.
            case credit.FREE:     // 無料プラン登録.
                return "登録が完了しました。";
            case credit.CHANGE:   // クレジットカード変更.
            case credit.PLAN_CHANGE: // プラン変更完了.
                return "変更が完了しました。";
            case credit.PLAN:     // 会員プラン決済(登録込み)完了.
            case credit.RENTAL:   // レンタル決済(登録込み)完了.
            case credit.PLAN_CAMPAIGN: // 無料キャンペーンプラン登録完了.
                return "決済とクレジットカード登録が完了しました。";
            case credit.PLAN_EXPIRED:   // 会員プラン決済(更新込み)完了.
            case credit.RENTAL_EXPIRED: // レンタル決済(更新込み)完了.
            case credit.PLAN_CAMPAIGN_EXPIRED: // 無料キャンペーンプラン決済(更新込み)完了.
                return "決済とクレジットカード更新が完了しました。";
            case credit.RESERVE_CHANGE: // 変更予約完了.
                return "変更予約が完了しました。";
            case credit.PLAN_CANCEL: // 変更予約キャンセル完了.
                return "プラン変更予約キャンセルが完了しました。";
            case credit.WITHDRAWAL:  // 退会完了.
                return "退会が完了しました。";
            case credit.RESERVE_WITHDRAWAL: // 退会予約完了.
                return "退会予約が完了しました。";
            case credit.MOVIE_TICKET: // ムビチケ応募完了.
                return "ムビチケ応募が完了しました。";
            default:
                return "決済が完了しました。";
        }
    };

    /**
     * 決済詳細情報表示.
     */
    const description = () => {
        // クレジットカード情報表示.
        const creditCard = () => {
            return (
                <li className="p-popupBoxWrap__dtlItem">
                    <div className="p-popupBoxWrap__dtlTxt">お支払方法</div>
                    <div className="p-popupBoxWrap__dtlCredit">
                        <div className="p-popupBoxWrap__dtlCreditTxt">クレジットカード</div>
                        <div className="p-popupBoxWrap__dtlCreditNum">{creditUtil.getCreditNumberHyphen(creditNumber)}</div>
                    </div>
                </li>
            );
        };
        switch (kind) {
            case credit.REGISTER: // クレジットカード登録.
            case credit.CHANGE:   // クレジットカード変更.
                return creditCard();
            case credit.FREE:     // 無料プラン登録.
                return (
                    <li className="p-popupBoxWrap__dtlItem">
                        <div className="p-popupBoxWrap__dtlStatus">無料プラン</div>
                    </li>
                );
            case credit.PLAN:         // 会員プラン決済(登録込み)完了.
            case credit.PLAN_ONLY:    // 会員プラン決済完了.
            case credit.PLAN_EXPIRED: // 会員プラン決済(更新込み)完了.
            case credit.PLAN_CAMPAIGN: // 無料キャンペーンプラン登録完了.
            case credit.PLAN_CAMPAIGN_ONLY: // 無料キャンペーンプラン登録（クレカ登録済み）完了.
            case credit.PLAN_CAMPAIGN_EXPIRED: // 無料キャンペーンプラン登録（更新込み）完了.
                return (
                    <>
                        <li className="p-popupBoxWrap__dtlItem">
                            <div className="p-popupBoxWrap__dtlTxt">ご契約プラン</div>
                            <div className="p-popupBoxWrap__dtlStatus">{credit.planAmount(plan, amount)}</div>
                            {[credit.PLAN_CAMPAIGN, credit.PLAN_CAMPAIGN_ONLY, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind) &&
                                <div className="p-popupBoxWrap__companyRankCoupon p-popupBoxWrap__companyRankCoupon--complate">{creditUtil.showFreePeriod(freePeriod)}</div>}
                        </li>
                        {creditCard()}
                    </>
                );
            case credit.PLAN_CHANGE:
                return (
                    <li className="p-popupBoxWrap__dtlItem">
                        <div className="p-popupBoxWrap__dtlTxt">変更後のプラン</div>
                        <div className="p-popupBoxWrap__dtlStatus">{credit.planAmount(plan, amount)}</div>
                    </li>
                );
            case credit.RESERVE_CHANGE:
                return (
                    <>
                        <li className="p-popupBoxWrap__dtlItem">
                            <div className="p-popupBoxWrap__dtlTxt">変更後のプラン</div>
                            <div className="p-popupBoxWrap__dtlStatus">{credit.planAmount(plan, amount)}</div>
                        </li>
                        <li className="p-popupBoxWrap__dtlItem">
                            <div className="p-popupBoxWrap__dtlTxt">新プラン開始日</div>
                            <div className="p-popupBoxWrap__dtlStatus">{changeDate}</div>
                        </li>
                    </>
                );
            case credit.RENTAL:         // レンタル決済(登録込み)完了.
            case credit.RENTAL_ONLY:    // レンタル決済完了.
            case credit.RENTAL_EXPIRED: // レンタル決済(更新込み)完了.
                return (
                    <>
                        <li className="p-popupBoxWrap__dtlItem">
                            <div className="p-popupBoxWrap__dtlTxt">レンタル料金 ({Number(amount).toLocaleString()}円/税込)</div>
                        </li>
                        {creditCard()}
                    </>
                );
            case credit.RESERVE_WITHDRAWAL:
                return (
                    <li className="p-popupBoxWrap__dtlItem">
                        <div className="p-popupBoxWrap__dtlTxt">退会日</div>
                        <div className="p-popupBoxWrap__dtlStatus">{expirationDate}</div>
                    </li>
                );
            default:
                return null;
        }
    };

    return (
        <div className="p-popup">
            <div className="p-popup__box p-popup__box--largeBox">
                <div className="p-popupBox">
                    <div className="p-popupBoxTtl">
                        <div className="p-popupBoxTtl__txt">{title()}</div>
                    </div>
                    <div className="p-popupBoxWrap">
                        <div className="p-popupBoxWrap__check">
                            <div className="p-popupBoxWrap__checkIcon">
                                <svg className="c-aniIconRound" width="70" height="70">
                                    <circle className="c-aniIconRound__object" cx="36" cy="36" r="30"></circle>
                                </svg>
                                <svg className="c-aniIconCheck" fill="none" height="30" viewbox="0 0 42 30" width="42">
                                    <path className="c-aniIconCheck__object" d="m2 14 13.5 14 24.5-26" stroke="#f00" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></path>
                                </svg>
                            </div>
                            <div className="p-popupBoxWrap__checkTxt">{completed()}</div>
                        </div>
                        <ul className="p-popupBoxWrap__dtlWrap">
                            {description()}
                        </ul>
                        <div className="c-btn p-popupBoxWrap__btn c-btn--thinRed" onClick={close}>閉じる</div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
    );
};

export default CreditResult;