import { encode } from "js-base64";

/**
 * Base64エンコード+URIエンコード.
 */
export const getEncodeId = (id) => {
    return encodeURIComponent(encode(id));
}

/**
 * トップ画面に遷移.
 */
export const historyTop = (history) => {
    history.push("/");
}

/**
 * トップ画面に遷移（履歴保存なし）.
 */
export const historyReplaceTop = (history) => {
    history.replace("/");
}

/**
 * 検索一覧画面に遷移.
 */
export const historySearch = (history) => {
    history.push("/search");
}

/**
 * おすすめタグで検索一覧画面に遷移.
 */
export const historySearchByTag = (history, data) => {
    history.push({
        pathname:"/search", 
        state: 
        {
            categoryId: data.searchCategoryId,
            keyword: data.searchKeyword,
            plan: data.searchPlan
        }
    });
}

/**
 * プラネット画面に遷移.
 */
export const historyPlanet = (history) => {
    history.push("/planet");
}

/**
 * プラネット別一覧画面に遷移.
 */
export const historyPlanetId = (history, id) => {
    history.push("/planet/" + getEncodeId(id));
}

/**
 * ライブラリ一覧画面に遷移.
 */
export const historylibraryId = (history, id) => {
    history.push("/library/" + id);
}

/**
 * TOP画面からライブラリ一覧画面に遷移.
 */
export const historylibraryIdTitle = (history, id, title) => {
    history.push({
        pathname:"/library/" + id, 
        state: 
        {
            title: title
        }
    });
}

/**
 * TOP画面からライブラリ一覧画面（レコメンド）に遷移.
 */
export const historylibraryIdRecommend = (history, id, title, spec) => {
    history.push({
        pathname:"/library/" + id, 
        state: 
        {
            title: title,
            spec: spec
        }
    });
}

/**
 * ライブラリ一覧画面に遷移（関連一覧）.
 */
export const historylibraryRecommend = (history, id) => {
    history.push("/library/recommend/" + getEncodeId(id));
}

/**
 * お気に入り一覧画面に遷移.
 */
export const historyFavorite = (history) => {
    history.push("/library/favorite");
}

/**
 * 視聴中の作品一覧画面に遷移.
 */
export const historyResume = (history) => {
    history.push("/library/resume");
}

/**
 * レンタル・取得した作品一覧画面に遷移.
 */
export const historyPurchase = (history) => {
    history.push("/library/purchase");
}

/**
 * イマジネーションとは画面に遷移.
 */
export const historyImagination = (history) => {
    history.push("/imagination");
}

/**
 * 詳細画面（動画）に遷移.
 */
export const historyVideoId = (history, id) => {
    history.push("/video/" + getEncodeId(id));
}

/**
 * 詳細画面（動画）に遷移（履歴保存なし）.
 */
export const historyReplaceVideoId = (history, id) => {
    history.replace("/video/" + getEncodeId(id));
}

/**
 * 詳細画面（Live）に遷移.
 */
export const historyLiveId = (history, id) => {
    history.push("/live/" + getEncodeId(id));
}

/**
 * 静的コンテンツページに遷移.
 */
export const historyContents = (history, id) => {
    history.push("/contents/" + getEncodeId(id));
}

/**
 * 静的コンテンツページに遷移（履歴保存なし）.
 */
export const historyReplaceContents = (history, id) => {
    history.replace("/contents/" + getEncodeId(id));
}

/**
 * 静的コンテンツページ（クエリ付）に遷移.
 */
export const historyContentsWithQuery = (history, id, query) => {
    history.push("/contents/" + getEncodeId(id) + query);
}

/**
 * 静的コンテンツページ（クエリ付）に遷移（履歴保存なし）.
 */
export const historyReplaceContentsWithQuery = (history, id, query) => {
    history.replace("/contents/" + getEncodeId(id) + query);
}

/**
 * 会員規約画面に遷移.
 */
export const historyTrems = (history) => {
    history.push("/trems");
}

/**
 * プライバシーポリシー画面に遷移.
 */
export const historyPrivacy = (history) => {
    history.push("/privacy");
}

/**
 * 推奨環境画面に遷移.
 */
export const historyEnvironment = (history) => {
    history.push("/environment");
}

/**
 * 特定商法取引法画面に遷移.
 */
export const historyTransaction= (history) => {
    history.push("/transaction");
}

/**
 * サービス紹介画面に遷移.
 */
 export const historyLogged= (history) => {
    history.push("/logged");
}

/**
 * 操作ガイド画面に遷移.
 */
export const historyOperation= (history) => {
    history.push("/operation");
}

/**
 * FAQ/問い合わせ画面に遷移.
 */
export const historyFaq= (history) => {
    history.push("/faq");
}

/**
 * 404ページに遷移.
 */
export const historyNotFound = (history) => {
    history.replace("/notfound");
}

/**
 * 404ページに遷移(遷移前ページの履歴保存).
 */
export const historyPushNotFound = (history) => {
    history.push("/notfound");
}

/**
 * QRクッションページに遷移.
 */
export const historyCoupon = (history, coupon) => {
    history.push("/coupon/" + coupon);
}

/**
 * QRクッションページに遷移（履歴保存なし）.
 */
export const historyReplaceCoupon = (history, coupon) => {
    history.replace("/coupon/" + coupon);
}

/**
 * ログインLP（アプリ対応）に遷移.
 */
export const historyAppLogin = (history) => {
    history.push("/app/login");
}

/*
 * プランLP（アプリ対応）に遷移.
 */
export const historyAppPlan = (history) => {
    history.push("/app/plan");
}

/*
 * TVODLP（アプリ対応）に遷移.
 */
export const historyAppTvod = (history) => {
    history.push("/app/tvod");
}

/**
 * 3分岐画面（アプリ対応）に遷移.
 */
export const historyAppBranch = (history) => {
    history.push("/app/branch");
}

/**
 * 3分岐画面（アプリ対応）に遷移（履歴保存なし）.
 */
export const historyReplaceAppBranch = (history) => {
    history.replace("/app/branch");
}

/**
 * プライバシーポリシー画面（アプリ対応）に遷移.
 */
export const historyAppPrivacy = (history) => {
    history.push("/app/privacy");
}

/*
 * データキューブの楽しみ方画面に遷移.
 */
export const historyHowtocube = (history) => {
    history.push("/howtocube");
}

/**
 * チケットクッションページ（アプリ対応）に遷移.
 */
export const historyAppTicket = (searchParams) => {
    window.location.href = window.location.protocol + "//" + window.location.hostname + "/app/ticket?" + searchParams;
}