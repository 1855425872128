/** 会員規約. */
export const TERMS = "terms";

/** プライバシーポリシー. */
export const PRIVACY = "privacy";

/** 推奨環境. */
export const ENVIRONMENT = "environment";

/** 特定商法取引法. */
export const TRANSACTION = "transaction";

/** プラネット画面. */
export const PLANET = "planet";

/** 円谷イマジネーション. */
export const IMAGINATION = "imagination"

/** 操作ガイド */
export const OPERATION = "operation"

/** FAQ */
export const FAQ = "faq"

/** SEARCH */
export const SEARCH = "search"

export const LOGIN ="login"

export const LOGOUT = "logout"

