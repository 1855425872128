/**
 * ログイン処理後のリダイレクトパラメータ.
 */

/** 円谷ログイン失敗. */
export const TSUBURAYA_LOGIN_FAILURE = "t_failure";

/** 円谷ログイン成功（初回登録時）. */
export const TSUBURAYA_LOGIN_FIRST = "t_first";

/** 円谷ログイン成功（2回目以降）. */
export const TSUBURAYA_LOGIN_SECOND = "t_second";

/** 円谷ログイン成功（dアカ紐付解除）. */
export const TSUBURAYA_LOGIN_LINKING_CLEAR = "t_link_clear";

/** dアカウントログイン失敗. */
export const DOCOMO_LOGIN_FAILURE = "d_login_failure";

/** dアカウント再紐付失敗. */
export const DOCOMO_RELINK_FAILURE = "d_relink_failure";

/** dアカウント重複（ログイン時）. */
export const DOCOMO_LOGIN_DUPLICATE = "d_login_duplicate";

/** dアカウント重複（再紐付時）. */
export const DOCOMO_RELINK_DUPLICATE = "d_relink_duplicate";

/** dアカウントログイン成功. */
export const DOCOMO_LOGIN_SUCCESS = "d_success";

/** 円谷再認証成功. */
export const TSUBURAYA_DOUBLE_SUCCESS = "double_success";

/** 円谷再認証失敗. */
export const TSUBURAYA_DOUBLE_FAILURE = "double_failure";