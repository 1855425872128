
import * as actionTypes from "./actionTypes";

const initState = {
    /** レコメンド用Cookie. */
    recommendCookie: ""
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** レコメンド用Cookie更新. */
        case actionTypes.UPDATE_RECOMMEND_COOKIE:
            return { ...state, recommendCookie: action.recommendCookie };

        /** デフォルト. */
        default:
            return state;
    }
}

export default reducer;
