import * as actionTypes from "./actionTypes";

/**
 * レコメンド用Cookie更新.
 */
export const updateRecommendCookie = (recommendCookie) => {
    return {
        type: actionTypes.UPDATE_RECOMMEND_COOKIE,
        recommendCookie: recommendCookie
    };
}