import * as actionTypes from "./actionTypes";

const initState = {
    /** シリーズタイトル. */
    seriesTitle: "",
    /** コンテンツリスト. */
    contentsList: [],
    /** 特典コンテンツリスト. */
    giftContentsList: [],
    /** レコメンドクリック情報. */
    clickData: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 初期化. */
        case actionTypes.INIT_LIBRARYID:
            return { ...state, seriesTitle: "", contentsList: [], giftContentsList: [] };

        /** シリーズタイトル更新. */
        case actionTypes.LIBRARYID_UPDATE_SERIES_TITLE:
            return { ...state, seriesTitle: action.seriesTitle };

        /** コンテンツ更新. */
        case actionTypes.LIBRARYID_UPDATE_CONTENTS_LIST:
            return { ...state, contentsList: action.contentsList };

        /** 特典コンテンツ更新. */
        case actionTypes.LIBRARYID_UPDATE_GIFT_CONTENTS_LIST:
            return { ...state, giftContentsList: action.giftContentsList };

        /** レコメンドコンテンツ更新. */
        case actionTypes.LIBRARYID_UPDATE_RECOMMEND_CONTENTS_LIST:
            return { ...state, contentsList: action.contentsList, clickData: action.clickData }

        default:
            return state;
    }
};

export default reducer;
