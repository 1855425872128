import * as actionTypes from "./actionTypes";

const initState = {
    /** Cast状態. */
    castState: "",
    /** Castデバイス名. */
    castDeviceName: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** Cast状態を更新（Play）. */
        case actionTypes.CAST_PLAY:
            return { ...state, castState: action.castState, castDeviceName: action.castDeviceName };

        /** Cast状態を更新（Idle）. */
        case actionTypes.CAST_IDLE:
            return { ...state, castState: "IDLE" };

        /** Cast状態を更新（Stop）. */
        case actionTypes.CAST_STOP:
            return { ...state, castState: "", castDeviceName: "" };

        default:
            return state;
    }
};

export default reducer;