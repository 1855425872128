/**
 * スマホアプリ.
 */

/** アプリ名. */
const APP_NAME = "TsuburayaImaginationApp";

/** Android判定識別子. */
const APP_OS_ANDROID = "Android";

/** iOS判定識別子. */
const APP_OS_IOS = "iOS";

/** Android カスタムURLスキーム. */
export const APP_URL_SCHEME_ANDROID = "jp.co.tsuburaya-prod.imagination";

/** iOS カスタムURLスキーム. */
export const APP_URL_SCHEME_IOS = "jp.co.tsuburaya-prod.imagination";

/** カスタムURLスキーム ログイン. */
export const URL_SCHEME_LOGIN = "://notifyLoginStatus/?status=Login";

/** カスタムURLスキーム ログアウト. */
export const URL_SCHEME_LOGOUT = "://notifyLoginStatus/?status=Logout";

/** カスタムURLスキーム PUSH設定表示. */
export const URL_SCHEME_PUSH_SETTING = "://displayPushSetting";

/** カスタムURLスキーム OSSライセンス. */
export const URL_SCHEME_OSS_LICENSE = "://displayOss";

/** カスタムURLスキーム 動画再生. */
export const URL_SCHEME_VIDEO_PLAY = "://videoPlay?";

/** カスタムURLスキーム フッター表示. */
export const URL_SCHEME_FOOTER_VISIBLE = "://footer?visibility=visible";

/** カスタムURLスキーム フッター非表示. */
export const URL_SCHEME_FOOTER_HIDDEN = "://footer?visibility=hidden";

/** カスタムURLスキーム キャスト開始. */
export const URL_SCHEME_PLAY_CAST = "://videoPlayCast";

/** アプリバージョン抽出用正規表現. */
const APP_VERSION_REG_EXP = new RegExp(APP_NAME + "\\/(\\d+\\.\\d+\\.\\d+)");

/** Step5.0のアプリバージョン. */
const OLD_APP_VERSION = "1.2.0";

/**
 * Androidアプリ判定.
 */
export const isAndroidApp = () => {
    const ua = window.navigator.userAgent;
    return ua.indexOf(APP_NAME) !== -1 && ua.indexOf(APP_OS_ANDROID) !== -1;
}

/**
 * iOSアプリ判定.
 */
export const isIosApp = () => {
    const ua = window.navigator.userAgent;
    return ua.indexOf(APP_NAME) !== -1 && ua.indexOf(APP_OS_IOS) !== -1;
}

/**
 * Androidアプリ or iOSアプリ判定.
 */
export const isAllApp = () => {
    return (isAndroidApp() || isIosApp());
}

export const urlScheme = () => {
    if (isAndroidApp()) {
        return APP_URL_SCHEME_ANDROID;
    }
    else if (isIosApp()) {
        return APP_URL_SCHEME_IOS;
    }
    else {
        return ""
    }
}

/**
 * 最新アプリかどうかの判定.
 */
export const isLatestApp = () => {
    const ua = window.navigator.userAgent;
    if (ua.indexOf(APP_NAME) !== -1) {
        const version = ua.match(APP_VERSION_REG_EXP);
        return version && version[1] >= OLD_APP_VERSION;
    }
    else {
        return false;
    }
};