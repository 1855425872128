/**
 * 期間単位.
 */

/** 年. */
export const YEAR = "year";

/** 月. */
export const MONTH = "month";

/** 日. */
export const DAY = "day";

/** 時間. */
export const HOUR = "hour";