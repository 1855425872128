import * as actionTypes from "./actionTypes";

/**
 * Wi-Fi/モバイル判定
 */
export const setWifiStatus = (flag) => {
    return {
        type: actionTypes.SET_WIFI_STATUS,
        isWifi: flag
    };
}