import * as actionTypes from "./actionTypes";

const initState = { // セッションストレージに保持
    video: 0,
    content: {
        title: "",
        synopsisLong: [],
        creditsList:[],
        planetId: [],
        thumbnailLandscape: "/images/noimage.jpg",
        copyright: "",
        cid: "",
        license: [],
        price: 0,
        epiNumber: "",
        parentCrid: "",
        seriesTitle: "",
        seriesSynopsisLong: "",
        nextCrid: "",
        preCrid: "",
        availPerStart: "",
        availPerEnd: "",
        isFree: false,
        planList: [],
        availStatus: "",
        campaignId: "",
        videoDifinition: ""
    },
    playUrl: "",
    licenseUrl: "",
    drmMode: "",
    customData: "",
    playToken: "",
    contentsList: [],
    addSeriesContentsList: [],
    recommendContentsList: [],
    isChangedQuality: false,
    clickData: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 初期化. */
        case actionTypes.INIT_VIDEO_ID:
            return {...state, ...initState};

         /** ID検索API実行成功. */
        case actionTypes.POST_IDSEARCH_SUCCESS:
            return { ...state, content: action.content };

        /** メタ検索API（シリーズ）実行成功. */
        case actionTypes.POST_SEARCH_SERIES_SUCCESS:
            return { ...state, contentsList: action.contentsList };

        /** メタ検索API（シリーズ）（2回目）実行成功. */
        case actionTypes.POST_SEARCH_SERIES_ADD_SUCCESS:
            return { ...state, addSeriesContentsList: action.contentsList };

        /** メタ検索API（レコメンド）実行成功.  */
        case actionTypes.POST_SEARCH_RECOMMEND_SUCCESS:
            return { ...state, recommendContentsList: action.contentsList, clickData: action.clickData };

        /** 視聴開始API実行成功. */
        case actionTypes.POST_START_WATCH_SUCCESS:
            return {
                ...state,
                playToken: action.playToken,
                playUrl: action.playUrl,
                drmMode: action.drmMode,
                licenseUrl: action.licenseUrl,
                customData: action.customData
            };
 
        /** 再生URL変更. */
        case actionTypes.UPDATE_PLAY_URL:
            return { ...state, playUrl: action.playUrl, isChangedQuality: action.isChangedQuality };

        default:
            return state;
    }
};

export default reducer;
