import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "./Default";
import { X_TOKEN, TI_SID } from "../../constants/apiUtil";
import * as app from "../../constants/app";
import * as commonActions from "../../common/status/actions";
import * as authIf from "../../middleware/authif";
import * as deliveryheif from "../../middleware/deliveryheif";
import { logoutFailure } from "../../common/status/actions";

/**
 * ログアウトダイアログ.
 *
 * props
 *  -> setIsLogout ダイアログの表示/非表示.
 */
const Logout = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** プレイトークン（視聴セッション）. */
    const playToken = useSelector(state => state.VideoId.playToken);

    /**
     * 「はい」押下時.
     */
    const onAccept = () => {
        // 視聴セッションが残っている場合は、視聴終了APIを実行.
        if (playToken) {
            deliveryheif.postEndWatch({[deliveryheif.PLAY_TOKEN]: playToken})
            .then(() => doLogout(dispatch), () => doLogout(dispatch));
        }
        else{
            doLogout(dispatch);
        }
        props.setIsLogout(false);
    }

    /**
     * 「いいえ」押下時.
     */
    const onReject = () => {
        props.setIsLogout(false);
    }

    return (
        <Dialog
            input={{title:"ログアウト確認", content:"ログアウトしますか？"}}
            onAccept={() => onAccept()}
            onReject={() => onReject()}
        />
    );
};

export const doLogout = (dispatch) => {
    authIf.postLogout({})
    .then(() => {
    })
    .catch(() => {
        dispatch(logoutFailure(true));
    });

    afterLogoutApi(dispatch);
};

export const afterLogoutApi = (dispatch) => {
    // reducerリセット.
    dispatch(commonActions.logout());

    // sessionStorageを削除.
    sessionStorage.clear();

    // cookieを削除.
    document.cookie = X_TOKEN + "=; domain=.imagination.m-78.jp; path=/; max-age=0";
    document.cookie = TI_SID + "=; domain=.imagination.m-78.jp; path=/; max-age=0";


    document.cookie = X_TOKEN + "=; domain=localhost; path=/; max-age=0";
    document.cookie = TI_SID + "=; domain=localhost; path=/; max-age=0";
    // アプリの場合3分岐画面に遷移.
    if (app.isAllApp()) window.appHandleBranch();

    // 画面更新.
    window.location.reload();
};

export default Logout;
