/** 初期化. */
export const INIT_PLANET_ID = "INIT_PLANET_ID";

/** プラネット更新. */
export const UPDATE_PLANET = "UPDATE_PLANET";

/** プラネットIDコンテンツリスト更新. */
export const UPDATE_PLANETID_CONTENTS_LIST = "UPDATE_PLANETID_CONTENTS_LIST";

/** 選択中カテゴリー更新. */
export const UPDATE_PLANETID_CURRENT_CATEGORY = "UPDATE_PLANETID_CURRENT_CATEGORY";

/** 選択中プラン更新. */
export const UPDATE_PLANETID_CURRENT_PLAN = "UPDATE_PLANETID_CURRENT_PLAN";

/** 戻るボタン更新. */
export const PLANETBACKTRUE = "PLANETBACKTRUE";