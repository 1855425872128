import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer"
    },
    planet: {
        margin: 5
    },
    thumbnail: {
        position: "relative",
        maxWidth: "100%",
        textAlign: "center",
        img: {
            onjectFit: "contain"
        }
    },
    videoBtn: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer"
    },
    noPlayBackground: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.51)",
        pointerEvents: "none"
    },
    iosPreviousBtn: {
        color: "white",
        cursor: "pointer",
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "30%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    iosPlayBtn: {
        color: "white",
        cursor: "pointer",
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    iosNextBtn: {
        color: "white",
        cursor: "pointer",
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "70%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    notPlayText: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%"
    }
}));

export default styles;
