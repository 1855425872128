import * as actionTypes from "./actionTypes";

/**
 * グリッド表示/リスト表示.
 */
export const changeGridList = (flag) => {
    return {
        type: actionTypes.CHANGE_GRID_LIST,
        isGrid: flag
    }
}
