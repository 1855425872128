import React from "react";
import { useHistory } from "react-router-dom";
import * as historys from "../../../historys";
import * as contentsKey from "../../../constants/contentsKey";
import * as contentType from "../../../constants/contentType";
import * as libraryId from "../../../constants/libraryId";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import styles from "./styles";

/**
 * 特集コンテンツ.
 *
 * props
 *  -> meta コンテンツのメタ.
 */
const Special = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const history = useHistory();

    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /**
     * コンテンツクリック時.
     */
    const handleClick = (id) => {
        // シリーズはライブラリ一覧に遷移.
        if (props.meta[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(id);
            historys.historylibraryId(history, historys.getEncodeId(list));
        }

        // 映像詳細画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.CONTENT_TYPE] === contentType.VIDEO))
            historys.historyVideoId(history, id);

        // ライブ画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.LIVE)
            historys.historyLiveId(history, id);

        // 静的コンテンツページに遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.WEB)
            historys.historyContents(history, id);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示
        event.preventDefault();
    }

    return (
        <div
            className={classes.root}
            onClick={() => handleClick(props.meta[contentsKey.CRID])}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <span className="c-contCard">
                <a class="c-mainvisual__slider--item">
                    <picture>
                        <source srcset={props.meta[contentsKey.BANNER_IMAGE_URL].replace('/original/', '/800x400/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                        + props.meta[contentsKey.BANNER_IMAGE_URL].replace('/original/', '/800x400/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                    type="image/webp"
                                    media="(min-width: 576px)" />
                        <source srcset={props.meta[contentsKey.BANNER_SMALL_IMAGE_URL].replace('/original/', '/768x614/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                        + props.meta[contentsKey.BANNER_SMALL_IMAGE_URL].replace('/original/', '/768x614/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                    type="image/webp"
                                    media="(min-width: 320px)" />
                        <source srcset={props.meta[contentsKey.BANNER_SMALL_IMAGE_URL].replace('/original/', '/320x256/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                        + props.meta[contentsKey.BANNER_SMALL_IMAGE_URL].replace('/original/', '/320x256/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                    type="image/webp" />
                        <img src={(matches ? props.meta[contentsKey.BANNER_IMAGE_URL] : props.meta[contentsKey.BANNER_SMALL_IMAGE_URL])} alt="" loading="eager" />
                    </picture>
                </a>
            </span>
        </div>
    );
};

export default Special;
