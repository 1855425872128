import React ,{useState}from "react";
import { useHistory,useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as app from "../../constants/app";
import * as footer from "../../constants/footer";
import * as historys from "../../historys";
import styles from "./styles";
import * as searchCategory from "../../constants/searchCategory";
import * as Searchactions from "../../components/screen/search/actions";
import * as topActions from "../../components/screen/top/actions";
import * as apiUtil from "../../constants/apiUtil";
import Logout from "../../components/dialog/Logout";

/**
 * フッター（全画面共通）.
 */
const Footer = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();

    /** Hooks. */
    const history = useHistory();

    const location = useLocation();

    const [isLogout, setIsLogout] = useState(false);

    const memberId = useSelector(state => state.Member.memberId);

    const appCheck = app.isAllApp()


    /** 各画面へ遷移. */
    const handleClick = (type) => {
        switch (type) {
            case footer.TERMS:
                historys.historyTrems(history);
                break;
            case footer.PRIVACY:
                historys.historyPrivacy(history);
                break;
            case footer.ENVIRONMENT:
                historys.historyEnvironment(history);
                break;
            case footer.TRANSACTION:
                historys.historyTransaction(history);
                break;
            case footer.LOGIN:
              if (app.isAllApp()) apiUtil.tsuburayaAppLogin(dispatch, location);
              else apiUtil.tsuburayaWebLogin(dispatch, location);
                break;
            case footer.LOGOUT:
              setIsLogout(true)
              break;
            case footer.PLANET:
                historys.historyTop(history);
                dispatch(topActions.changeTab(1));
                break;
            case footer.IMAGINATION:
                historys.historyImagination(history);
                break;
            case footer.OPERATION:
                historys.historyOperation(history)
                break;
            case footer.FAQ:
                historys.historyFaq(history)
                break;
            case footer.SEARCH:
              if(!location.pathname.endsWith("search")){
                historys.historySearch(history)
              }
              else {
                // 検索画面でフッターの内部リンク押下時は画面トップまでスクロール.
                window.scroll(0,0);
              }
                break;
            default:
                break;
        }
    }

    const getSearchSession =(value)=>{
      let getSearch = sessionStorage.getItem("search")
      if(getSearch){
        sessionStorage.removeItem("search")
      }
        const searchCategory = [{"category":value,"plan":"P00","keyword":""}]
        sessionStorage.setItem("search",JSON.stringify(searchCategory))  
        if(location.pathname.endsWith("search")){
          dispatch(Searchactions.footerSearch())
        }
    }

    const changeBr = (ctg) => {
      var ctgs = ctg.split("<br/>");
      if(ctgs.length >= 2){
        let ctgsAll = String(ctgs[0]+ctgs[1])
        return ctgsAll;
      }
      if (ctgs.length !== 2) return ctg;
    }
    const deleteSession =()=>{
      let getPlanet = sessionStorage.getItem("planet")
      if(getPlanet){
        sessionStorage.removeItem("planet")
      }
    }

    return (
        <>
        <div className="p-footCont"> 
        {appCheck ||
        <div className="p-footCont__inner">
          <h2 className="p-footCont__ttl">コンテンツ一覧</h2>
          <div className="p-footCont__list">
            <div className="p-footCont__listWrap">
              <ul className="p-footCont__listGroup"> 
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C11");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C11"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C16");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C16"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C12");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{changeBr(searchCategory.category["C12"])}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C17");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C17"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C13");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C13"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C18");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C18"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C14");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{changeBr(searchCategory.category["C14"])}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C19");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C19"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C15");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C10"]}］{searchCategory.category["C15"]}</a></li>
              </ul>
              <ul className="p-footCont__listGroup">
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C22");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C20"]}］{searchCategory.category["C22"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C24");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C20"]}］{searchCategory.category["C24"]}</a></li>
              </ul>
            </div>
            <div className="p-footCont__listWrap">
              <ul className="p-footCont__listGroup">
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C31");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C31"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C35");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C35"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C32");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C32"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C36");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C36"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C33");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C33"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C37");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C37"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C34");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C30"]}］{searchCategory.category["C34"]}</a></li>
              </ul>
              <ul className="p-footCont__listGroup">
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C41");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C40"]}］{searchCategory.category["C41"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C43");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C40"]}］{searchCategory.category["C43"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C42");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C40"]}］{searchCategory.category["C42"]}</a></li>
                <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();getSearchSession("C44");handleClick(footer.SEARCH)}}><a href="/search">［{searchCategory.category["C40"]}］{searchCategory.category["C44"]}</a></li>
              </ul>
            </div>
            <div className="p-footCont__listWrap p-footCont__listNavi">
              <div className="p-footCont__listNavi__wrap">
                <ul className="p-footCont__listGroup p-footCont__listNavi__group">
                {memberId === "" &&
                  <li className={classes.clickable + " p-footCont__listItem"}onClick={() => handleClick(footer.LOGIN)}><a>ログイン</a></li>
                }
                {memberId !== "" &&
                  <li className={classes.clickable + " p-footCont__listItem"}onClick={() => handleClick(footer.LOGOUT)}><a>ログアウト</a></li>
                }
                {isLogout && <Logout setIsLogout={setIsLogout} />}

                  <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();handleClick(footer.PLANET);deleteSession();}}><a href="/">プラネット（コンテンツリスト）</a></li>
                </ul>
                <ul className="p-footCont__listGroup p-footCont__listNavi__group">
                  <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();handleClick(footer.IMAGINATION)}}><a href="/imagination">TSUBURAYA IMAGINATIONとは</a></li>
                  <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();handleClick(footer.OPERATION)}}><a href="/operation">操作ガイド</a></li>
                  <li className={classes.clickable + " p-footCont__listItem"}onClick={(e)=>{e.preventDefault();handleClick(footer.FAQ)}}><a href="faq">FAQ/問合せ</a></li>
                </ul>
              </div>
              <div className="p-footCont__listWrap__group">
                <ul className="p-footCont__listGroup p-footCont__listBlock">
                  <li className="p-footCont__listItem"><a href="https://m-78.jp/" target="_blank" rel="noopener noreferrer">円谷ステーション</a></li>
                  <li className="p-footCont__listItem"><a href="https://store.m-78.jp/" target="_blank" rel="noopener noreferrer">TSUBURAYA STORE ONLINE</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
         } 
      </div>

      <footer className="l-footer p-footer">
        <div className="p-footer__inner">
          <div className="p-footer__wrap">
            <ul className="p-footer__info">
              <li className={classes.clickable + " p-footer__infoItem"} onClick={() => handleClick(footer.TERMS)}>会員規約</li>
              <li className={classes.clickable + " p-footer__infoItem p-footer__infoItem--border"} onClick={() => handleClick(footer.PRIVACY)}>プライバシーポリシー</li>
              <li className={classes.clickable + " p-footer__infoItem"} onClick={() => handleClick(footer.ENVIRONMENT)}>推奨環境</li>
              {!app.isAllApp() &&
                <li className={classes.clickable + " p-footer__infoItem"} onClick={() => handleClick(footer.TRANSACTION)}>特定商法取引法</li>
              }
            </ul>
            <div className="p-footer__icon"><i className="p-footer__iconItem c-picIconTsuburaya"></i></div>
          </div>
        </div>
      </footer>
      </>
    );
};

export default Footer;
