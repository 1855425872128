import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Parallax from "react-rellax";
import * as commonActions from "../../../../common/status/actions";
import * as constantsStatic from "../../../../constants/static";

/**
 * チケットクッションページ.
 * ローチケ・eplus
 */
const Ticket = () => {
    /** Hooks. */
    const history = useHistory();
    const dispatch = useDispatch();

    /**
     * 画面描画時に実行する処理.
     * クエリパラメータをセットしてチケットサイトへ遷移.
     */
    useEffect(() => {
        // 処理中ダイアログを表示.
        dispatch(commonActions.loading(true));

        // クエリパラメータをフォームDOMにセット.
        const params = new URLSearchParams(history.location.search);
        let form = document.createElement("form");
        form.method = "post";
        for (const [key, value] of params) {
            switch (key) {
                case constantsStatic.TICKET_ACTION: // 遷移先URL設定.
                    form.action = decodeURIComponent(value);
                    break;
                default: // その他のパラメータをセット.
                    let param = document.createElement("input");
                    param.type = "hidden";
                    param.name = key;
                    param.value = decodeURIComponent(value);
                    form.appendChild(param);
            }
        }
        // POSTでチケットサイトへ遷移.
        document.body.appendChild(form);
        form.submit();

        // ブラウザ戻るボタン対策で履歴をTOP画面に書き換え
        window.history.replaceState("", "", window.location.origin);

        return () => {
            // 処理中ダイアログを閉じる.
            dispatch(commonActions.loading(false));
        }
    }, [history])

    return (
        <div>
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main"></div>
        </div>
    );
};

export default Ticket;
