
/** 初期処理判定. */
export const INIT = "INIT";

/** ログアウト. */
export const LOGOUT = "LOGOUT";

/** 退会. */
export const WITHDRAWAL = "WITHDRAWAL";

/** 端末時刻判定（WARN）. */
export const UPDATE_TIME_WARN = "UPDATE_TIME_WARN";

/** 端末時刻判定（NG）. */
export const UPDATE_TIME_NG = "UPDATE_TIME_NG";

/** 通信失敗. */
export const UPDATE_CONECTION_ERROR = "UPDATE_CONECTION_ERROR";

/** プロフィール画像変更失敗. */
export const UPDATE_CHANGE_PROF_PICTURE_FAILURE = "UPDATE_CHANGE_PROF_PICTURE_FAILURE";

/** クレカ情報取得API 成功. */
export const POST_PAYMENT_SUCCESS = "POST_PAYMENT_SUCCESS";

/** プランキャンセル失敗. */
export const CANCEL_PLAN_FAILURE = "CANCEL_PLAN_FAILURE";

/** 退会失敗. */
export const WITHDRAWAL_FAILURE = "WITHDRAWAL_FAILURE";

/** ログアウト失敗. */
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

/** お気に入り変更失敗. */
export const FAVORITE_FAILURE = "FAVORITE_FAILURE";

/** キャンペーン遷移失敗. */
export const CAMPAIGN_DESTINATION_FAILURE = "CAMPAIGN_DESTINATION_FAILURE";

/** データキューブ情報取得失敗. */
export const DATA_CUBE_FAILURE = "DATA_CUBE_FAILURE";

/** 追加特典取得失敗. */
export const MORE_GIFT_FAILURE = "MORE_GIFT_FAILURE";

/** 追加特典取得失敗（オンラインクーポン）. */
export const MORE_GIFT_COUPON_FAILURE = "MORE_GIFT_COUPON_FAILURE";

/** 処理中ダイアログ. */
export const LOADING = "LOADING";

/** 海外エラー. */
export const OVERSEAS_ERROR = "OVERSEAS_ERROR";

/** クーポン行使画面. */
export const COUPON_USED = "COUPON_USED";

/** クーポン プラン未充足エラー. */
export const COUPON_USED_PLAN_FAILURE = "COUPON_USED_PLAN_FAILURE";

/** クーポン行使エラー. */
export const COUPON_USED_FAILURE = "COUPON_USED_FAILURE";

/** アプリ対応：アプリ閲覧不可LP警告ダイアログ表示/非表示. */
export const APP_NOT_DISPLAY_LP = "APP_NOT_DISPLAY_LP";
