import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    root: {
        cursor: "pointer"
    },
    playback: props => ({
        width: props.playBackWidth,
        height: 3,
        backgroundColor: "red"
    })
}));

export default styles;
