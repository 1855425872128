import React from "react";

/**
 * ERRORダイアログ（ダイアログの上に表示させる用・OKボタンのみ）.
 *
 * props
 *  -> input:title   タイトル.
 *  -> input:content 内容.
 *  -> input:yes     「OK」の文言変更（省略可）.
 *  -> onAccept      「OK」押下時の処理.
 */
const ErrorFrontOne = (props) => {
    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * 「OK」押下時の処理.
     */
    const onAccept = () => {
        props.onAccept();
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
            style={{zIndex: 999}}
        >
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__icon p-popup__icon--dialog">
                        <img src="/assets/images/icon-attention-comp-red.png" alt="" />
                    </div>
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{props.input.title}</div>
                            <div className="p-popup__txt">{props.input.content}</div>
                            <div className="p-popup__aplBtn c-btn c-btn--thinRed" onClick={() => onAccept()}>{props.input.yes ? props.input.yes : "OK"}</div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default ErrorFrontOne;
