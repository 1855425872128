import * as actionTypes from "./actionTypes";

/**
 * コンテンツ取得成功.
 */
export const getContentsSuccess = (data) => {
    return {
        type: actionTypes.GET_CONTENTS_SUCCESS,
        res: data
    };
}

/**
 * コンテンツ情報更新.
 */
export const updateContents = (data) => {
    return {
        type: actionTypes.CONTENTS_UPDATE_CONTENTS,
        content: data
    };
}

/**
 * シリーズタイトル更新.
 */
export const updateSeriesTitle = (data) => {
    return {
        type: actionTypes.CONTENTS_UPDATE_SERIES_TITLE,
        seriesTitle: data
    };
}

/**
 * シリーズ一覧更新.
 */
export const updateContentsList = (data) => {
    return {
        type: actionTypes.CONTENTS_UPDATE_CONTENTS_LIST,
        contentsList: data
    };
}

/**
 * クーポン利用状況チェック設定.
 */
 export const setCouponCheck = (data) => {
    return {
        type: actionTypes.CONTENTS_SET_COUPON_CHECK,
        couponCheck: data
    };
}

/**
 * レコメンドコンテンツ一覧更新.
 */
export const updateRecommendContetsList = (contentsList, clickData) => {
    return {
        type: actionTypes.CONTENTS_UPDATE_RECOMMEND_CONTENTS_LIST,
        contentsList: contentsList,
        clickData: clickData
    }
}

/**
 * コンテンツ初期化.
 */
export const resetContent = () => {
    return {
        type: actionTypes.RESET_CONTENT,
        res: "<div>loading...</div>"
    }
}