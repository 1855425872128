import React from "react";
import { useSelector } from "react-redux";
import * as noticeKey from "../../constants/noticeKey";
import NoticeContents from "../../components/contents/notice/Notice";

/**
 * 通知.
 *
 *  -> handleNotice 通知操作
 */
const Notice = (props) => {
    /** 通知一覧. */
    const noticeList = useSelector(state => state.Member.noticeList);

    return (
        <span>
            <div className="c-prof__head c-headnav">
                <div className="c-menubtn c-menubtn--close c-noticebtn is-active" onClick={props.handleNotice}><span></span><span></span><span></span></div>
            </div>
            <div className="c-prof__headBg"></div>
            <div className="c-notice__menuBox">
                <div className="c-notice__headTxt">おしらせ</div>
                <ul>
                    {noticeList.length === 0 &&
                        <li className="c-notice__noContents">新しいお知らせはありません。</li>
                    }
                    {noticeList.length > 0 &&
                        Object.entries(noticeList).map(([, value]) => (
                            <NoticeContents
                                key={value[noticeKey.LINK_CRID]}
                                meta={value}
                                onClick={props.handleNotice}
                            />
                        ))
                    }
                </ul>
            </div>
        </span>
    );
};

export default Notice;
