/**
 * クーポン.
 */

/** クーポン利用済みフラグ. */
export const USED = "used";

/**
 * 有効期間内フラグ.
 *
 * クーポン未使用時はfalseが設定される。
 * コンテンツ種別がcubeかvideoの場合はtrueが設定される。
 * 特典が別ページにあるLPの場合はtrueが設定される。
 * 上記以外の場合、特典利用期間内であればtrueが設定される。
 */
export const VALIDITY_PERIOD = "validityPeriod";

/**
 * 特典ページフラグ.
 *
 * 呼出し元が追加特典ページの場合はtrueが設定される。
 */
export const GIFT_PAGE = "giftPage";

/** 追加特典有効期間内フラグ. */
export const GIFT_VALIDITY_PERIOD = "giftValidityPeriod";

/** コンプリートステータス. */
export const STATUS = "status";
/** コンプリートステータス none：追加特典無し. */
export const NONE = "none";
/** コンプリートステータス incomplete：未コンプリート. */
export const INCOMPLETE = "incomplete";
/** コンプリートステータス complete：コンプリート済. */
export const COMPLETE = "complete";
/** コンプリートステータス acquired：追加特典取得済. */
export const ACQUIRED = "acquired";

/** 特典種別 item:会場特典. */
export const ITEM = "item";
/** 特典種別 web:応募特典（LP）. */
export const WEB = "web";

/**
 * 特典ページフラグ.
 *
 * リクエスト元ページの種別を表す。
 */
export const PAGE_TYPE = "pageType";

/** ページタイプ none: 対象外. */
export const NONE_LP = "none";
/** ページタイプ couponLp: クーポンLP. */
export const COUPON_LP = "couponLp";
/** ページタイプ onlineCoupon: オンラインクーポンLP. */
export const ONLINE_COUPON_LP = "onlineCoupon";
/** ページタイプ moreGift: 追加特典ページ. */
export const MORE_GIFT_LP = "moreGift";
/** ページタイプ movieTicketLp: ムビチケLP. */
export const MOVIE_TICKET_LP = "movieTicketLp";
/** ページタイプ formLp: フォームLP. */
export const FORM_LP = "formLp";
/** ページタイプ giftImage: 特典画像LP. */
export const GIFT_IMAGE_LP = "giftImage";
/** ページタイプ giftLottery: 特典応募LP. */
export const GIFT_LOTTERY_LP = "giftLottery";

/** 応募済みフラグ. */
export const APPLIED = "applied";

/** クーポン所有フラグ. */
export const HAVE = "have";

/** 特典種別. */
export const GIFT_TYPE = "giftType";

/** 行使確認ダイアログタイトル. */
export const MESSAGE_TITLE = "messageTitle";

/** 行使確認ダイアログ本文. */
export const MESSAGE_MAIN = "messageMain";

/** 有効期間内フラグ notYet:有効期間前. */
export const PERIOD_NOT_YET = "notYet";
/** 有効期間内フラグ valid:有効期間内. */
export const PERIOD_VALID = "valid";
/** 有効期間内フラグ over:有効期間後. */
export const PERIOD_OVER = "over";

/**
 * 特典表示があるか判定.
 */
export const isDisplayGift = (data, giftType) => {
    if (giftType === ITEM && data[STATUS] === COMPLETE) return true;
    if (giftType === ITEM && data[STATUS] === ACQUIRED) return true;
    if (giftType === WEB && data[STATUS] === COMPLETE) return true;
    if (giftType === WEB && data[STATUS] === ACQUIRED) return true;
    return false;
}

/**
 * 特典取得済み画像を表示するか判定.
 */
export const isGotBanner = (data, giftType) => {
    // 会場特典で取得済みの場合.
    if (giftType === ITEM && data[STATUS] === ACQUIRED) return true;
    return false;
}

/**
 * 特典取得済みか判定.
 */
export const isGotGift = (data) => {
    return data[STATUS] === ACQUIRED;
}
