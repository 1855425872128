import React, { useCallback, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import { decode } from "js-base64";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import Default from "../../contents/default/Default";
import PlanetContents from "../../contents/planet/Planet";
import Separator from "../../parts/separator/Separator";

import * as historys from "../../../historys";
import * as screenActions from "../../../common/status/screen/actions";
import * as actions from "./actions";
import * as couponActions from "../../../common/status/coupon/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import * as contentif from "../../../middleware/contentif";
import * as fileApi from "../../../middleware/file";
import * as eventif from "../../../middleware/eventif";
import * as recommendif from "../../../middleware/recommendif";
import * as contentsKey from "../../../constants/contentsKey";
import * as constantsPlan from "../../../constants/plan";
import * as coupon from "../../../constants/coupon";
import * as campaign from "../../../constants/campaign";
import * as cv from "../../../constants/cv";
import * as libraryId from "../../../constants/libraryId";
import * as recommendConstants from "../../../constants/recommend";
import * as app from "../../../constants/app";
import styles from "./styles";
import Static from "./Static";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import { setHeadMetaContents } from "../../../constants/sns";
import { QUERY_COUPON } from "../../../constants/static";
import FavoriteShareButton from "./FavoriteShareButton";
import useRecommendCookie from "../../../hooks/useRecommendCookie";
SwiperCore.use([Navigation]);

/**
 * 静的コンテンツ画面.
 */
const ContentsId = () => {
    /** CSS. */
    const classes = styles();
    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /** シリーズコンテンツリスト（自分以外）. */
    const [seriesContents, setSeriesContents] = useState([]);
    /** プラネットアイコン. */
    const [planets, setPlanets] = useState([]);

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** コンバージョン通知したか. */
    const conversionRef = useRef(false);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** プラネットリスト. */
    const planetList = useSelector(state => state.Member.planetList);
    /** コンテンツ情報. */
    const content = useSelector(state => state.ContentsId.content);
    /** シリーズタイトル. */
    const seriesTitle = useSelector(state => state.ContentsId.seriesTitle);
    /** シリーズ一覧. */
    const contentsList = useSelector(state => state.ContentsId.contentsList);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** コンテンツID. */
    const [id, setId] = useState("");
    /** コンテンツタイトル. */
    const [contentTitle, setContentTitle] = useState("");
    /** レコメンドコンテンツ一覧. */
    const recommendContentsList = useSelector(state => state.ContentsId.recommendContentsList);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.ContentsId.clickData);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /** 所属プラネットが無い場合のランキングの表示数 */
    const RANKING_DISPLAY = 8;

    /**
     * コンテンツ取得 失敗.
     */
    const requestFailure = useCallback((err) => {
        historys.historyNotFound(history);
    }, [history]);

    /**
     * 静的コンテンツ取得.
     */
    const getStaticContent = useCallback((flag, data) => {
        fileApi.getContents(data[0][contentsKey.CID], flag).then(
            (res) => {
                dispatch(actions.getContentsSuccess(res));
                // head設定.
                setHeadMetaContents(data[0], res);
            },
            requestFailure
        );
    }, [dispatch, requestFailure]);

    const recommendRequestFailure = useCallback((err) => {
        dispatch(actions.updateRecommendContetsList([], ""));
    });

    const recommendRequestSuccess = useCallback((data) => {
        const contentList = data[recommendif.CONTENT_LIST];
        const clickData = data[recommendif.CLICK_DATA];

        dispatch(actions.updateRecommendContetsList(contentList, clickData));
    }, [dispatch]);

    const recommendRequest = useCallback((id, plan) => {
        let request = {
            [recommendif.CRID]: id,
            [recommendif.SPEC]: recommendConstants.getTextSpec(plan),
            [recommendif.NUM]: recommendif.TEXT_RECOMMEND,
            [recommendif.RECOMMENDCOOKIE]: recommendCookie,
            [recommendif.PLATFORM]: contentif.getRequestPlatform()
        };
        recommendif.postRecommendRequest(request).then(recommendRequestSuccess, recommendRequestFailure);
    }, [recommendRequestSuccess, recommendRequestFailure]);

    /**
     * コンテンツメタ取得成功.
     */
    const searchIdSuccess = useCallback((plan, data) => {
        if (data.length > 0) {
            dispatch(actions.updateContents(data[0]));

            // 静的コンテンツ取得.
            if (!constantsPlan.isNone(plan)) {
                // ログイン時.

                // クーポン利用状況チェックを行う.
                const couponRequest = {
                    [eventif.CAMPAIGN_ID]: data[0][contentsKey.CAMPAIGN_ID],
                    [eventif.CONTENT_TYPE]: campaign.WEB,
                    [eventif.CRID]: data[0][contentsKey.CRID],
                    [eventif.GIFT_SOURCE_CRID]: data[0][contentsKey.GIFT_SOURCE_CRID]
                };
                eventif.postCouponCheck(couponRequest).then((couponRes) => {
                    // レスポンスをReducerに保存.
                    dispatch(actions.setCouponCheck(couponRes));
                    let flag = false;

                    switch(couponRes[coupon.PAGE_TYPE]) {
                        case coupon.NONE_LP: // キャンペーン対象ではないLP.
                            // プラン充足確認.
                            if (data[0][contentsKey.IS_FREE]) flag = true;
                            if (data[0][contentsKey.PLAN_LIST]?.includes(plan)) flag = true;
                            getStaticContent(flag, data);
                            break;
                        case coupon.COUPON_LP: // クーポンLP.
                            getStaticContent(couponRes[coupon.USED] === true, data);
                            break;
                        case coupon.GIFT_IMAGE_LP: // 特典画像LP.
                        case coupon.GIFT_LOTTERY_LP: // 特典応募LP.
                            getStaticContent(
                                (couponRes[coupon.USED] === true || couponRes[coupon.APPLIED] === true),
                                data
                            );
                            break;
                        case coupon.ONLINE_COUPON_LP: // オンラインクーポンLP.
                            getStaticContent(couponRes[coupon.HAVE] === true, data);
                            break;
                        case coupon.MORE_GIFT_LP: // 追加特典LP.
                            getStaticContent(couponRes[coupon.STATUS] === coupon.ACQUIRED, data);
                            break;
                        case coupon.MOVIE_TICKET_LP: // ムビチケLP.
                            getStaticContent(couponRes[coupon.APPLIED] === true, data);
                            break;
                        case coupon.FORM_LP: // フォームLP.
                            if (couponRes[coupon.APPLIED]) {
                                getStaticContent(true, data);
                            }
                            else {
                                // プラン充足確認.
                                if (data[0][contentsKey.IS_FREE]) flag = true;
                                if (data[0][contentsKey.PLAN_LIST]?.includes(plan)) flag = true;
                                getStaticContent(flag, data);
                            }
                            break;
                        default:
                            // 当てはまるページタイプがないときの処理.
                            getStaticContent(false, data);
                    }
                }, (err) => {
                    // セッションエラーを確認.
                    eventif.isSessionError(dispatch, err);
                    // falseテンプレート表示.
                    getStaticContent(false, data);
                });
            }
            else {
                // 未ログイン時.
                getStaticContent(false, data);
            }

            if (data[0][contentsKey.PARENT_CRID] !== ""){
                // シリーズタイトル取得.
                const request = {
                    [contentif.CONTENT_ID_LIST]: [data[0][contentsKey.PARENT_CRID]],
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                };
                contentif.postIdSearch(request).then((res) => {
                    if (res.length > 0) {
                        dispatch(actions.updateSeriesTitle(res[0][contentsKey.TITLE]));
                    } else {
                        dispatch(actions.updateSeriesTitle("シリーズ全話"));
                    }
                });

                // シリーズ一覧取得.
                const seriesBody = {
                    [contentif.SEARCH_TYPE]: contentif.OPTION,
                    [contentif.PARENT_CRID]: data[0][contentsKey.PARENT_CRID],
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                }
                contentif.postSearch(seriesBody).then((res) => {
                    dispatch(actions.updateContentsList(res));
                });
            }

            if (recommendConstants.getTextSpec(memberPlan)) {
                recommendRequest(data[0][contentsKey.CRID], memberPlan);
            }
            // レコメンド代替取得（所属プラネットが1つ以上ある場合）
            else if (data[0].planetId && data[0].planetId.length > 0) {
                const recommendBody = {
                    [contentif.SEARCH_TYPE]: contentif.OPTION,
                    [contentif.PLANET_ID]: data[0].planetId,
                    [contentif.ROW]: contentif.ROW_RECOMMEND,
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                }
                contentif.postSearch(recommendBody)
                .then((res) => {
                    dispatch(actions.updateRecommendContetsList(res, ""));
                });
            } else {
                // レコメンド代替取得（所属プラネットが無い場合はランキング取得）
                fileApi.getRankingMeta().then((res) =>
                    dispatch(actions.updateRecommendContetsList(res.slice(0, RANKING_DISPLAY), ""))
                );
            }

            // ファイル：プラネット定義取得.
            if (!Object.keys(planetList).length) {
                fileApi.getPlanet().then(fileApi.getPlanetSuccess.bind(this, dispatch));
            }
        } else {
            historys.historyNotFound(history);
        }
    }, [dispatch, history, memberPlan, getStaticContent]);

    /**
     * ID検索.
     */
    const searchId = useCallback((id, plan) => {
        let request = {
            [contentif.CONTENT_ID_LIST]: [id],
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(request).then(searchIdSuccess.bind(this, plan), requestFailure);
    }, [searchIdSuccess, requestFailure]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.contentsId());
        const splitPathname = location.pathname.split("/");
        const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
        conversionRef.current = false;

        // IDを更新
        setId(contentsId);

        // 前コンテンツタイトルを更新
        setContentTitle(content.title);

        // クエリがあればReducerに保存.
        const query = location.search;
        if (query && query.length > 0) {
            const [key, value] = query.slice(1).split("&")[0].split("=");
            if (key === QUERY_COUPON) {
                dispatch(couponActions.updateCouponCode(value));
            }
        }

        searchId(contentsId, memberPlan);

        return () => dispatch(actions.resetContent());
    }, [dispatch, location, memberPlan, searchId]);

    // コンテンツ情報のGA連携
    useEffect(() => {
        if (id && content.title !== "" && content.title !== undefined) {
            // 前コンテンツタイトルと当コンテンツタイトルが違う場合
            if (content.title !== contentTitle) {
                cv.sendContentInfo(
                    "/contents/" + historys.getEncodeId(id),
                    content.title,
                    memberId
                );
            }
        }
    }, [content.title, contentTitle, id, memberId]);

    /**
     * シリーズコンテンツを自分以外にする.
     */
    useEffect(() => {
        if (contentsList.length !== 0) {
            let list = [];
            for (const cnt of contentsList) {
                if (cnt[contentsKey.CRID] !== content[contentsKey.CRID]) list.push(cnt);
            }
            setSeriesContents(list);
        }
    }, [content, contentsList]);

    /**
     * 関連プラネットを取得.
     */
    useEffect(() => {
        if (Object.keys(planetList).length && content[contentsKey.PLANET_ID] && content[contentsKey.PLANET_ID].length !== 0) {
            let list = [];
            for (const pltId of content[contentsKey.PLANET_ID]) {
                for (const plt of planetList) {
                    if (plt[contentsKey.PLANET_ID] === pltId) list.push(plt);
                }
            }
            setPlanets(list);
        } else {
            setPlanets([]);
        }
    }, [content, planetList]);

    /**
     * ページの高さ（ブラウザ間のページ全体の高さをカバーする）.
     */
    const scrollHeight = () => {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    };

    /**
     * スクロール閾値が設定値を超えた場合、コンバージョン通知を実施.
     */
    const handleScrollConversion = useCallback(() => {
        // // コンバージョン通知既に実施してる場合はスキップ.
        if (conversionRef.current) return;

        // // コンバージョン通知対象ではない場合スキップ.
        if (!content[contentsKey.CONVERSION_TARGET]) return;

        // スクロール閾値判定.
        let footerHeight = app.isAllApp() ? document.querySelector("footer")?.offsetHeight : document.getElementsByClassName("p-footCont")?.[0]?.offsetHeight;
        footerHeight += document.getElementsByClassName("c-playerMore")?.[0]?.offsetHeight;
        if ((scrollHeight() - window.scrollY - window.innerHeight) * recommendConstants.CONTENT_CONVERSION_THRESHOLD <= footerHeight) {
            conversionRef.current = true;

            let conversionRequest = {
                [recommendif.CRID]: content[contentsKey.CONVERSION_TARGET],
                [recommendif.RECOMMENDCOOKIE]: recommendCookie,
                [recommendif.RECOMMENDDEVICE]: recommendConstants.getDeviceId()
            };
            recommendif.postConversion(conversionRequest).then(null, null);
        }
    }, [content, recommendCookie]);

    /**
     * コンバージョン通知用スクロールイベント登録.
     */
    useEffect(() => {
        window.addEventListener("scroll", handleScrollConversion);
        return () => window.removeEventListener("scroll", handleScrollConversion);
    }, [handleScrollConversion]);

    /*
     * プラットコンテンツ表示を返却.
     */
    const viewPlanets = () => {
        const planetsRows = [];
        for (let i = 0; i < planets.length; i = i + 3) {
            planetsRows.push (
                <div className="c-playerMore__planet">
                        {Object.entries(planets).slice(i, i + 3).map(([, value]) => (
                            <span key={value[contentif.PLANET_ID]} className={classes.planet + " p-planet__ultraSelect__item p-planet__ultraSelect__item--playerDis js-on"}>
                                <PlanetContents meta={value} isAnimation={true} delay={0} />
                            </span>
                        ))}
                </div>
            );
        }
        return (planetsRows);
    }

    /**
     * ライブラリ一覧画面に遷移.
     */
    const historylibraryId = () => {
        let list = [];
        if (recommendConstants.getTextSpec(memberPlan) !== ""){
            list.push(libraryId.RECOMMEND);
            list.push(libraryId.TEXT);
            list.push(id);
        }
        else {
            list.push(libraryId.PLANET);
            for (const pid of content.planetId) list.push(pid);
        }
        historys.historylibraryId(history, historys.getEncodeId(list));
    };

    return (
        <div>
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg l-planetBg--planetListBg rellax" speed={2}></Parallax>
            <div className="l-main l-main--planetListBg">
                <div className="c-container">
                    <div>
                        <Static />
                    </div>
                    <FavoriteShareButton content={content} location={location} />
                    <div className="c-playerMore c-playerMore--article">
                        {(seriesContents.length > 0) &&
                            <section className="c-sect c-sect--playerMore">
                                <Separator input={{title: seriesTitle}} />
                                <div className="c-contCardSlideBox">
                                    <div className="c-contCardSlideWrap">
                                        <div className="c-contCardNoCat">
                                            <div className="c-contCardNoCat__list">
                                                <Swiper
                                                    spaceBetween={matches ? 20 : 10}
                                                    slidesPerView="auto"
                                                    slidesPerGroup={3}
                                                    navigation={{
                                                        prevEl: ".swiper-button-prev",
                                                        nextEl: ".swiper-button-next"
                                                    }}
                                                    className="c-contCardNoCatList js-movieSlide"
                                                >
                                                    {Object.entries(seriesContents).map(([, value]) => (
                                                        <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                            <Default meta={value} playBack={false} />
                                                        </SwiperSlide>
                                                    ))}
                                                    <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                                    <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                        {(recommendContentsList.length > 0) &&
                            <section className="c-sect c-sect--playerMore">
                                <Separator input={{title: "あなたへのおすすめ"}} />
                                <div className="c-contCardSlideBox">
                                    <div className="c-contCardSlideWrap">
                                        <div className="c-contCardNoCat">
                                            <div className="c-contCardNoCat__list">
                                                <Swiper
                                                    spaceBetween={matches ? 20 : 10}
                                                    slidesPerView="auto"
                                                    slidesPerGroup={3}
                                                    navigation={{
                                                        prevEl: ".swiper-button-prev",
                                                        nextEl: ".swiper-button-next"
                                                    }}
                                                    className="c-contCardNoCatList js-movieSlide"
                                                >
                                                    {Object.entries(recommendContentsList).map(([, value]) => (
                                                        <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                            <Default meta={value} playBack={false} clickData={clickData} />
                                                        </SwiperSlide>
                                                    ))}
                                                    {(recommendConstants.getTextSpec(memberPlan) !== "" || content.planetId?.length > 0) &&
                                                        <SwiperSlide className="c-contCardNoCatList__item c-contCardNoCatList__item--more">
                                                            <span className={classes.clickable + " c-contCardNoCatList__itemMore"} onClick={() => historylibraryId()}>
                                                                <div className="c-contCardNoCatList__itemMoreTxt">もっと見る</div>
                                                            </span>
                                                        </SwiperSlide>
                                                    }
                                                    <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                                    <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                                </Swiper>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        }
                        {viewPlanets()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentsId;
