import * as actionTypes from "./actionTypes";

/**
 * ファイル：TOP画面取得 成功.
 */
export const getTopSuccess = (body) => {
    /** 特集バナー */
    let topBanner = [];
    /** アイちゃんメッセージ */
    let greeting = [];
    /** コーナーリスト. */
    let cornerList = [];
    /** ブロックリスト. */
    let blockList = [];
    /** コンテンツリスト. */
    let contentsList = [];
    /** URLリスト. */
    let urlList = [];
    /** おすすめタグ一覧 */
    let searchTag = [];

    // ボディを展開
    for (const item of body) {
        switch (item.topType) {
            case "topBanner":
                topBanner = item.topBanner;
                break;
            case "greeting":
                greeting = item.greeting;
                greeting["isDisplayWelcomePage"] = item.isDisplayWelcomePage;
                break;
            case "corner":
                //コーナーリストにコーナーを格納
                cornerList.push(item.corner)
                if (item.corner.blocks.length > 1) {
                    for (let j = 0; j < item.corner.blocks.length - 1; j++) {
                        //コーナーの中に複数ブロックの場合、NULLを格納
                        cornerList.push(null)
                    }
                }
                break;
            case "searchTag":
                searchTag = item.searchTag;
                cornerList.push(item.topType);
                break;
            default:
                //それ以外、topTypeを格納
                cornerList.push(item.topType);
        }
    }

    //コーナーを展開
    for (let m = 0; m < cornerList.length; m++) {
        if (cornerList[m] !== "siteLink") {
            if (cornerList[m] !== "searchTag" && cornerList[m] !== null) {
                //ブロックを展開
                for (let k = 0; k < cornerList[m].blocks.length; k++) {
                    //ブロックリストに格納
                    blockList.push(cornerList[m].blocks[k])
                    //コンテンツリストに格納
                    contentsList.push(cornerList[m].blocks[k].contents)
                    //もっと見るのリンクリストに格納
                    if(cornerList[m].blocks[k].allContentsUrl != null){
                        urlList.push(cornerList[m].blocks[k].allContentsUrl.split('/')[5].replace('.json',''))
                    }else urlList.push(null)
                    //ニュースpickupコンテンツをコンテンツリストの0番に格納
                    if(cornerList[m].blocks[k].blockDesign === "vertical" && cornerList[m].blocks[k].pickup != null){
                        for(let d = cornerList[m].blocks[k].contents.length; d > 0; d--){
                            cornerList[m].blocks[k].contents[d] = cornerList[m].blocks[k].contents[d-1]
                        }cornerList[m].blocks[k].contents[0] = cornerList[m].blocks[k].pickup.pickupContent
                    }
                }   
            } else if (cornerList[m] === "searchTag"){
                //ブロックリストに格納
                blockList.push(null)
                //コンテンツリストに格納
                contentsList.push(searchTag)
                //もっと見るのリンクリストに格納
                urlList.push(null)
            }
        } else {
            //ブロックリストに格納
            blockList.push(null)
            //コンテンツリストに格納
            contentsList.push(null)
            //もっと見るのリンクリストに格納
            urlList.push(null)
        }
    }
    
    return {
        type: actionTypes.GET_TOP_SUCCESS,
        topBanner: topBanner,
        greeting: greeting,
        cornerArr: cornerList,
        blockArr: blockList,
        contentArr: contentsList,
        urlArr: urlList
    };
}
    
/** タブを切り替え. */
export const changeTab = (active) => {
    return {
        type: actionTypes.CHANGE_TAB,
        currentActive: active
    };
}

/**
 * レコメンド取得 成功.
 */
export const getTopRecommendSuccess = (contentArr, clickData) => {
    return {
        type: actionTypes.GET_TOP_RECOMMEND_SUCCESS,
        contentArr: contentArr,
        clickData: clickData
    };
}