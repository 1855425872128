
/** 会員プラン選択画面を表示. */
export const OPEN_POPUP_PLAN_SELECT = "OPEN_POPUP_PLAN_SELECT";
/** 会員プラン選択画面を閉じる. */
export const CLOSE_POPUP_PLAN_SELECT = "CLOSE_POPUP_PLAN_SELECT";
/** 会員プラン詳細画面を表示. */
export const OPEN_POPUP_PLAN_DETAIL = "OPEN_POPUP_PLAN_DETAIL";
/** 会員プラン詳細画面を閉じる. */
export const CLOSE_POPUP_PLAN_DETAIL = "CLOSE_POPUP_PLAN_DETAIL";
/** 会員プラン選択画面を表示（クーポン引き渡し）. */
export const OPEN_POPUP_PLAN_SELECT_COUPON = "OPEN_POPUP_PLAN_SELECT_COUPON";
