/**
 * 会員プラン.
 */

/** 非ログイン. */
export const NONE = "none";

/** 無料会員. */
export const FREE = "free";

/** スタンダード会員. */
export const STANDARD = "standard";

/** スタンダード会員(税抜き). */
export const STANDARD_WITHOUT_TAX = "standard_without_tax";

/** プレミアム会員. */
export const PREMIUM = "premium";

/** プレミアム会員(税抜き). */
export const PREMIUM_WITHOUT_TAX = "premium_without_tax";

/** 会員プランのレベル順（プレミアム > スタンダード > 無料 > 非ログイン） */
export const LEVEL_LIST = { "none": 0, "free": 1, "standard": 2, "premium": 3};

/** 英名から日本名に変換 */
export const EN_TO_JP = { "free": "無料", "standard": "スタンダード", "premium": "プレミアム"};

/**
 * 非会員判定.
 */
export const isNone = (plan) => {
    return plan === NONE;
}

/**
 * 無料会員判定.
 */
export const isFree = (plan) => {
    return plan === FREE;
}

/**
 * スタンダード会員判定.
 */
export const isStandard = (plan) => {
    return plan === STANDARD;
}

/**
 * プレミアム会員判定.
 */
export const isPremium = (plan) => {
    return plan === PREMIUM;
}

/**
 * 会員状態を取得.
 */
export const getMemberStatus = (plan) => {
    switch (plan) {
        case NONE:
            return "退会";
        case FREE:
            return "無料";
        case STANDARD:
            return "スタンダード";
        case PREMIUM:
            return "プレミアム";
        default:
            return "";
    }
}

/**
 * 会員ランクの充足判定.
 */
export const isSatisfiedPlan = (memberPlan, targetPlan) => {
    return LEVEL_LIST[memberPlan] >= LEVEL_LIST[targetPlan];
}

/**
 * 最低充足ランク返却.
 */
export const minimumPlan = (planList, isFree) => {
    if (isFree) {
        return FREE;
    }
    else if (planList?.includes(STANDARD)) {
        return STANDARD;
    }
    else if (planList?.includes(PREMIUM)) {
        return PREMIUM;
    }
    else {
        return "";
    }
}