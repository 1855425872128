import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import * as historys from "../../../historys";
import * as commonActions from "../../../common/status/actions";
import * as couponActions from "../../../common/status/coupon/actions";
import * as error from "../../../constants/error";
import * as campaign from "../../../constants/campaign";
import { QUERY_COUPON } from "../../../constants/static";
import * as eventif from "../../../middleware/eventif";

/**
 * QRコード読み込み時のクッションページ.
 */
const Coupon = () => {
    /** ページパス. */
    const PATH = "coupon";
    /** ページパスのスラッシュ. */
    const SLASH = "/";

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** クーポン行使成功時のレスポンス. */
    const [couponUseRes, setCouponUseRes] = useState({});

    /**
     * クーポン管理API 失敗.
     */
    const postCouponFailure = useCallback((err) => {
        // 処理中ダイアログを閉じる.
        dispatch(commonActions.loading(false));
        // エラー処理.
        eventif.postCouponFailure(dispatch, location, history, err);
    }, [dispatch, location, history]);

    /**
     * クーポン管理API 成功.
     */
    const postCouponSuccess = useCallback((code, data) => {
        // 処理中ダイアログを閉じる.
        dispatch(commonActions.loading(false));

        // 有効性チェックのみはLP遷移.
        if (data[campaign.STATUS] === campaign.VALID) {
            const query = "?" + QUERY_COUPON + "=" + code;
            historys.historyReplaceContentsWithQuery(history, data[campaign.LP_CRID], query);
        }
        // 即時行使の場合は、クーポン行使画面を表示.
        else if (data[campaign.STATUS] === campaign.COMPLETED) {
            setCouponUseRes(data);
        }
        // 上記以外はクーポン行使エラーを表示.
        else {
            let title = error.COUPON_USED_FAILURE_TITLE;
            let content = error.COUPON_USED_FAILURE_CONTENT;
            dispatch(commonActions.couponUsedFailure(true, title, content));
        }
    }, [dispatch, history]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // 処理中ダイアログを表示.
        dispatch(commonActions.loading(true));

        // パスからクーポンコードを取得.
        const code = location.pathname.replace(PATH, "").replaceAll(SLASH, "");
        dispatch(couponActions.updateCouponCode(code));

        // クーポン管理APIを実行.
        eventif.postCoupon({
            [eventif.COUPON]: code
        }).then(postCouponSuccess.bind(this, code), postCouponFailure);
    }, [dispatch, location, postCouponSuccess, postCouponFailure]);

    /**
     * クーポン行使成功時の処理.
     */
    useEffect(() => {
        if (Object.keys(couponUseRes).length) {
            // クーポンコードを初期化.
            dispatch(couponActions.updateCouponCode(""));
            // クーポン行使画面を表示.
            dispatch(commonActions.couponUsed(true, couponUseRes));
        }
    }, [dispatch, couponUseRes]);

    return (
        <div>
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main"></div>
        </div>
    );
};

export default Coupon;
