import * as actionTypes from "./actionTypes";

/**
 * クレジットカード情報入力ダイアログを表示.
 */
export const openPopupInputCredit = (kind, plan, amount) => {
    return {
        type: actionTypes.OPEN_POPUP_INPUT_CREDIT,
        kind: kind,
        plan: plan,
        amount: amount
    };
}

/**
 * クレジットカード情報入力ダイアログを閉じる.
 */
export const closePopupInputCredit = () => {
    return {
        type: actionTypes.CLOSE_POPUP_INPUT_CREDIT
    };
}

/**
 * 決済情報入力ダイアログを表示.
 */
export const openPopupSimpleInputCredit = (kind, plan, amount) => {
    return {
        type: actionTypes.OPEN_POPUP_SIMPLE_INPUT_CREDIT,
        kind: kind,
        plan: plan,
        amount: amount
    };
}

/**
 * 決済情報入力ダイアログを閉じる.
 */
export const closePopupSimpleInputCredit = () => {
    return {
        type: actionTypes.CLOSE_POPUP_SIMPLE_INPUT_CREDIT
    };
}

/**
 * 決済完了ダイアログを表示.
 */
export const openPopupCreditResult = (kind, plan, amount) => {
    return {
        type: actionTypes.OPEN_POPUP_CREDIT_RESULT,
        kind: kind,
        plan: plan,
        amount: amount
    };
}

/**
 * 決済完了ダイアログを閉じる.
 */
export const closePopupCreditResult = () => {
    return {
        type: actionTypes.CLOSE_POPUP_CREDIT_RESULT
    };
}

/**
 * CardlistIdを保存.
 */
export const saveCardlistId = (cardlistId) => {
    return {
        type: actionTypes.SAVE_CARDLIST_ID,
        res: cardlistId
    };
}
