import * as actionTypes from "./actionTypes";

const initState = {
    isGrid: true
}

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** グリッド表示/リスト表示. */
        case actionTypes.CHANGE_GRID_LIST:
            return { ...state, isGrid: action.isGrid };

        /** デフォルト. */
        default:
            return state;
    }
}

export default reducer;
