import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isAndroid, isIOS } from "react-device-detect";
import * as actions from "./actions";
import { Swiper } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);

/**
 * レクチャーポップアップ.
 */
const Lecture = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** 次へボタンDOM. */
    const nextRef = useRef();
    /** とじるボタンDOM. */
    const closeRef = useRef();

     /** 8ページ目の画像. */
    const pageEight = (isAndroid) ? "/assets/images/img-lecture-08-android.jpg" :
        ((isIOS) ? "/assets/images/img-lecture-08-ios.jpg" : "/assets/images/img-lecture-08-pc.jpg");

    /** レクチャーリスト. */
    const lectureList = [
        "/assets/images/img-lecture-01.jpg",
        "/assets/images/img-lecture-02.jpg",
        "/assets/images/img-lecture-03.jpg",
        "/assets/images/img-lecture-04.jpg",
        "/assets/images/img-lecture-05.jpg",
        "/assets/images/img-lecture-06.jpg",
        "/assets/images/img-lecture-07.jpg",
        pageEight,
        "/assets/images/img-lecture-09.jpg"
    ];

    /** 初回ログインフラグ. */
    const isFirstLogin = useSelector(state => state.LectureFirst.isFirstLogin);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        closeRef.current.style.display = "none";
    }, [dispatch, closeRef]);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * スライド変更.
     */
    const onSlideChange = (e) => {
        if (e.activeIndex === lectureList.length - 1) {
            nextRef.current.style.display = "none";
            closeRef.current.style.display = "block";
        } else {
            nextRef.current.style.display = "block";
            closeRef.current.style.display = "none";
        }
    }

    /**
     * 使い方レクチャーを閉じる.
     */
    const handleClose = () => {
        document.body.classList.remove("is-fixed");
        dispatch(actions.closePopupLecture());
        // 初回ログインフラグを更新.
        if (isFirstLogin) dispatch(actions.updateFirstLogin());
    }

    return (
        <div
            className="p-popup p-popup--lecture"
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup__box">
                <div className="p-popup__wrap p-popup__wrap--lecture">
                    <Swiper
                        navigation={{
                            prevEl: ".l-swiper-button-prev",
                            nextEl: ".l-swiper-button-next"
                        }}
                        pagination={{
                            el: ".p-popupRecture-pagination",
                            type: "bullets",
                            clickable: true
                        }}
                        className="p-popup__slide swiper-container"
                        onSlideChange={(e) => onSlideChange(e)}
                    >
                        <ul className="p-popup__slideWrap swiper-wrapper">
                            {Object.entries(lectureList).map(([key, value]) => (
                                <li key={key} className="p-popup__slideItem swiper-slide">
                                    <img src={value} alt=""/>
                                </li>
                            ))}
                        </ul>
                    </Swiper>
                    <div className="swiper-pagination p-popupRecture-pagination"></div>
                    <div className="p-popupRectureButton">
                        <div className="l-swiper-button-prev p-popupRecture-button-prev">
                            <div className="p-popupRectureButton__txt p-popupRectureButton__txt--back">もどる</div>
                        </div>
                        <div className="l-swiper-button-next p-popupRecture-button-next" ref={nextRef}>
                            <div className="p-popupRectureButton__txt p-popupRectureButton__txt--next">次へ</div>
                        </div>
                        <div className="l-swiper-button-next p-popupRecture-button-next" ref={closeRef} onClick={() => handleClose()}>
                            <div className="p-popupRectureButton__txt p-popupRectureButton__txt--next"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
    );
};

export default Lecture;
