import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./actions";
import * as couponActions from "../../../common/status/coupon/actions";
import { overseasError } from "../../../common/status/actions";
import * as credit from "../../../constants/credit";
import * as apiUtil from "../../../constants/apiUtil";
import * as cv from "../../../constants/cv";
import * as constantsPlan from "../../../constants/plan";
import * as error from "../../../constants/error";
import * as contractif from "../../../middleware/contractif";
import * as creditUtil from "./creditUtils";
import Progress from "../../../components/dialog/Progress";
import ErrorFrontOne from "../../../components/dialog/ErrorFrontOne";
import WarningFront from "../../../components/dialog/WarningFront";

/**
 * 簡易入力決済画面.
 */
const SimpleInputCredit = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** ローディング状態. */
    const [isLoading, setIsLoading] = useState(false);
    /** エラーダイアログ */
    const [isCriticalError, setIsCriticalError] = useState(false);
    /** クーポン関連エラーダイアログ */
    const [couponErrorDialogState, setCouponErrorDialogState] = useState({open: false, title: "", content: ""});

    /** ダイアログ種別. */
    const kind = useSelector(state => state.Credit.kind);
    /** 選択された会員プラン. */
    const plan = useSelector(state => state.Credit.plan);
    /** 決済金額. */
    const amount = useSelector(state => state.Credit.amount);
    /** クレジットカードマスタの管理ID */
    const cardlistId = useSelector(state => state.Credit.cardlistId);
    /** 現在の会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** クレジットカード番号（マスク）. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** クーポンコード. */
    const couponCode = useSelector(state => state.Coupon.couponCode);
    /** キャンペーン名. */
    const campaignName = useSelector(state => state.LocalCredit.campaignName);
    /** 無料期間. */
    const freePeriod = useSelector(state => state.LocalCredit.freePeriod);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        return () => {
            if (couponCode !== "") dispatch(couponActions.updateCouponCode(""));
            document.body.classList.remove("is-fixed");
        }
        // eslint-disable-next-line
    }, [dispatch]);

    /**
     * キャンセルボタン押下時の処理.
     */
    const cancel = () => {
        dispatch(actions.closePopupSimpleInputCredit());
    };

    /**
     * 入力チェックと次処理の呼び出し.
     */
    const submit = () => {
        setIsLoading(true);

        switch (kind) {
            case credit.PLAN_ONLY:
            case credit.PLAN_CAMPAIGN_ONLY:
                let contractBody = {
                    [contractif.CONTRACT_TYPE]: contractif.PLAN,
                    [contractif.BEFORE_PLAN]: memberPlan,
                    [contractif.AFTER_PLAN]: plan,
                    [contractif.CARDLIST_ID]: cardlistId,
                    [contractif.SEC_CODE]: "000"
                };
                // 無料キャンペーンプランの場合、クーポンコードも送信.
                if (kind === credit.PLAN_CAMPAIGN_ONLY) {
                    // クーポンコードが空の場合は、不整合が発生しているのでエラーとする.
                    if (couponCode === "") {
                        setIsCriticalError(true);
                        return;
                    }
                    contractBody = {...contractBody, [contractif.COUPON_CODE]: couponCode}
                }
                contractif.postContractManage(contractBody)
                .then(postContractManageSuccess, postContractManageFailure);
                break;

            case credit.RENTAL_ONLY:
                // 契約管理API(レンタル決済)呼び出し.
                const contractLicenseBody = {
                    [contractif.CONTRACT_TYPE]: contractif.PURCHASE_TYPE,
                    [contractif.LICENSE_ID]: plan,
                    [contractif.CARDLIST_ID]: cardlistId,
                    [contractif.SEC_CODE]: "000"
                };
                contractif.postContractManage(contractLicenseBody)
                .then(postContractManageSuccess, postContractManageFailure);
                break;
            default:
                return;
        }
    };

    /**
     *  契約管理API成功.
     */
    const postContractManageSuccess = (res) => {
        // プラン登録・変更時はCVを送信.
        if ([credit.PLAN_ONLY, credit.PLAN_CAMPAIGN_ONLY].includes(kind)) {
            // 非会員からの登録は新規入会.
            if (constantsPlan.isNone(memberPlan)) {
                cv.sendPlanCV(cv.SIGN_UP, plan);
            }
            // それ以外は、プランアップグレード.
            else {
                cv.sendPlanCV(cv.UP_SELL, plan);
            }
        }

        creditUtil.updateInfo(dispatch, res, noneReadMap);
        // 完了ダイアログを表示.
        dispatch(actions.openPopupCreditResult(kind, plan, amount));
    };

    /**
     * 契約管理API失敗.
     */
    const postContractManageFailure = (err) => {
        setIsLoading(false);

        // セッションエラーを確認.
        contractif.isSessionError(dispatch, err);

        const errCode = err[apiUtil.ERROR_CODE] ? err[apiUtil.ERROR_CODE].slice(-3) : "";
        if (errCode === contractif.OVERSEAS_ERROR_CODE) {
            dispatch(overseasError(true));
            cancel();
        }
        else {
            let title = "";
            let content = "";
            switch(err[apiUtil.ERROR_CODE]) {
                // クーポンが無効（存在しないなど）.
                case contractif.WAR04017:
                    title = error.COUPON_USED_FAILURE_INVALID_TITLE;
                    content = error.COUPON_USED_FAILURE_INVALID_CONTENT;
                    break;

                // クーポンが有効期間前.
                case contractif.WAR04018:
                    title = error.COUPON_USED_FAILURE_BEFORE_VALID_TITLE;
                    content = error.COUPON_USED_FAILURE_BEFORE_VALID_CONTENT;
                    break;

                // クーポンが期限切れ.
                case contractif.WAR04019:
                    title = error.COUPON_USED_FAILURE_EXPIRED_TITLE;
                    content = error.COUPON_USED_FAILURE_EXPIRED_CONTENT;
                    break;

                // 円谷クーポンAPIでエラー.
                case contractif.WAR04020:
                    title = error.COUPON_USED_FAILURE_TITLE;
                    content = error.COUPON_USED_FAILURE_CONTENT;
                    break;

                // クーポンが他ユーザで使用済み.
                case contractif.WAR04021:
                    title = error.COUPON_USED_FAILURE_OTHER_TITLE;
                    content = error.COUPON_USED_FAILURE_OTHER_CONTENT;
                    break;

                // クーポンの使用可能プラン外.
                case contractif.WAR04024:
                    title = error.COUPON_USED_FAILURE_PLAN_TITLE;
                    content = error.COUPON_USED_FAILURE_PLAN_CONTENT;
                    break;

                default:
            }
            if (title !== "") {
                setCouponErrorDialogState({
                    open: true,
                    title: title,
                    content: content
                });
            }
            else {
                setIsCriticalError(true);
            }
        }
    };

    /**
     * 会員プラン決済とレンタル決済で表示を変更.
     */
    const creditTarget = () => {
        if (kind === credit.PLAN_ONLY || kind === credit.PLAN_CAMPAIGN_ONLY) {
            return credit.planAmount(plan, amount);
        } else {
            return "レンタル料金 (" + Number(amount).toLocaleString() + "円/税込)"
        }
    };

    /**
     * 契約管理API失敗時の処理.
     */
    const contractFailure = () => {
        window.location.reload();
        cancel();
    };

    return (
        <div className="p-popup">
            {/* ローディング表示 */}
            {isLoading && <Progress />}
            {/* エラーダイアログ表示 */}
            {isCriticalError && <ErrorFrontOne input={{title: "決済失敗", content: "決済に失敗しました。"}} onAccept={contractFailure}/>}
            {/* クーポン関連エラーダイアログ表示 */}
            {couponErrorDialogState.open && <WarningFront input={{title: couponErrorDialogState.title, content: couponErrorDialogState.content}} onAccept={contractFailure}/>}
            <div className="p-popup__box p-popup__box--largeBox">
                <div className="p-popupBox">
                    <div className="p-popupBoxTtl">
                        <div className="p-popupBoxTtl__txt">購入と決済情報の確認</div>
                    </div>
                    <div className="p-popupBoxWrap">
                        <div className="p-popupBoxWrap__companyRank">
                            <span className="p-popupBoxWrap__companyRankTtl">{creditTarget()}</span>
                            <div className="p-popupBoxWrap__companyRankTxt">を決済します。</div>
                            {kind === credit.PLAN_CAMPAIGN_ONLY && <div className="p-popupBoxWrap__companyRankCoupon">{campaignName}クーポンが適用されています<br/>{creditUtil.showFreePeriod(freePeriod)}</div>}
                            {kind === credit.PLAN_ONLY && plan === constantsPlan.STANDARD &&
                                <div className="p-popupBoxWrap__companyRankTxt">
                                    ※プラン変更時の決済に関して<br/>
                                    変更日に当月分の会費が発生します。日割り計算にはなりませんので予めご了承ください。<br/>
                                    継続の際は、会員登録は自動継続となり、次月以降は毎月1日に決済されます。
                                </div>
                            }
                            {kind === credit.PLAN_ONLY && plan === constantsPlan.PREMIUM &&
                                <div className="p-popupBoxWrap__companyRankTxt">
                                    ※プラン変更時の決済に関して<br/>
                                    変更日に初年度分の会費が発生します。日割り計算にはなりませんので予めご了承ください。<br/>
                                    継続の際は、会員登録は自動継続となり、翌年以降はご入会月の翌月1日に年額会費が決済されます。
                                </div>
                            }
                        </div>
                        <ul className="p-popupBoxWrap__form">
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt">登録済みカード番号</div>
                                <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--entered">
                                    <input className="p-popupBoxWrap__example" type="text" value={creditUtil.getCreditNumberHyphen(creditNumber)} readOnly />
                                </form>
                            </li>
                        </ul>
                        <div className="c-btnWrap p-popupBoxWrap__btnWrap">
                            <div className="c-btn c-btnWrap__size c-btn--gray" onClick={cancel}>キャンセル</div>
                            <div className="c-btn c-btnWrap__size c-btn--thinRed" onClick={submit}>決済する</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
    );
};

export default SimpleInputCredit;