import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parallax from "react-rellax";
import { useLocation, useHistory } from 'react-router-dom';

import DefaultList from "../../contents/defaultList/DefaultList";
import * as contentif from "../../../middleware/contentif";
import * as recommendif from "../../../middleware/recommendif";
import * as searchCategory from "../../../constants/searchCategory";
import * as contentsKey from "../../../constants/contentsKey";
import * as actions from "./actions";
import * as commonActions from "../../../common/status/actions";
import * as screenActions from "../../../common/status/screen/actions";
import { changeGridList } from "../../../common/status/grid/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { SEARCH_TITLE } from "../../../constants/title";
import {  setHeadDefault } from "../../../constants/sns";
import * as cv from "../../../constants/cv";
import * as app from "../../../constants/app";
import * as fileApi from "../../../middleware/file";
import {KEYWORD_COUNT,DISPLAY_COUNT,SESSIONVAL,SCROLLINTERVAL} from "../../../constants/separatorLimit"
import * as recommendConstants from "../../../constants/recommend";
import * as libraryId from "../../../constants/libraryId";
import useRecommendCookie from "../../../hooks/useRecommendCookie";
import * as historys from "../../../historys";
import styles from "./styles";

/**
 * 検索一覧画面.
 */
const Search = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** CategoryDOM. */
    const categoryRef = useRef();
    /** AllDOM. */
    const worksRef = useRef();
    /** GridボタンDOM. */
    const iconGridRef = useRef();
    /** ListボタンDOM. */
    const iconListRef = useRef();
    /** 検索ボタンDOM. */
    const searchBtnRef = useRef();
    /** カテゴリーDOM. */
    const searchCategoryRef = useRef();
    /** すべての作品DOM. */
    const searchWorksRef = useRef();
    /** 前回検索時のキーワード. */
    const prevKeywords = useRef("");

    /** カテゴリーの表示/非表示. */
    const [category, setCategory] = useState(false);
    /** プランの表示/非表示. */
    const [plan, setPlan] = useState(false);
    /** 検索ボックス入力値. */
    const [keywords, setKeywords] = useState("");
    /** 検索実行フラグ. */
    const [isSearch, setIsSearch] = useState(true);
    /** 画面表示件数. */
    const [rowCount, setRowCount] = useState(contentif.ROW_DEFAULT);
    /** 画面表示件数. */
    const [timeoutId, setTimeoutId] = useState("");

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 選択中カテゴリー. */
    const currentCategory = useSelector(state => state.Search.currentCategory);
    /** 選択中プラン. */
    const currentPlan = useSelector(state => state.Search.currentPlan);
    /** ランキングメタリスト. */
    const ranking_meta = useSelector(state => state.Search.ranking_meta);
    /** グリッド表示/リスト表示. */
    const isGrid = useSelector(state => state.Grid.isGrid);
    /** レコメンドコンテンツリスト. */
    const recommendList = useSelector(state => state.Search.recommendContentsList);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.Search.clickData);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /** TOP画面からのパラメータ. */
    const location = useLocation();

    /** 検索タイム. */
    const SEARCH_TIME = 300;

    /** カテゴリプルダウン */
    let categoryMethod = null;

    /** プランプルダウン */
    let planMethod = null;

    /** キーワード入力内容 */
    let keywordMethod = null;  

    /** 入力内容保持用 */
    let saveMethod = [{"category":categoryMethod,"plan":planMethod,"keyword":keywordMethod}];
    const checkStorage =sessionStorage.getItem("search")


    // /** TOPセパレータ表示フラグ */
    const [topCategoryFlg,setTopCategoryFlg]= useState(true)

    // /** 大カテゴリセパレータ表示フラグ */
    const [middleCategoryFlg,setMiddleCategoryFlg]= useState(false)

    // /** 中カテゴリセパレータ表示フラグ */
    const [bottomCategoryFlg,setBottomCategoryFlg]= useState(false)


    /** カテゴリ用変数 */

    const [videoFlgOn,setVideoFlgOn] = useState(false)
    const [sentenceFlgOn,setSentenceFlgOn] = useState(false)
    const [soundFlgOn,setSoundFlgOn] = useState(false)
    const [newsFlgOn,setNewsFlgOn] = useState(false)


    /** back. */
    const backInfo = useSelector(state => state.Search.back);
    /** footerクリック. */
    const footerInfo = useSelector(state => state.Search.footerVal);

    const middleCategoryLeng = ["C10","C20","C30","C40"]
    const videoLeng = ["C11","C12","C13","C14","C15","C16","C17","C18","C19"]
    const soundLeng = ["C22","C24"]
    const sentenceLeng = ["C31","C32","C33","C34","C35","C36","C37"]
    const newsLeng = ["C41","C42","C43","C44"]

    const [middleData,setMiddleData] = useState([])
    const [middleDataCategory,setMiddleDataCategory] = useState("")
    const [noneData,setNoneData] = useState(false)


    const ArrayContents = {
        C10 : [],
        C20 : [],
        C30 : [],
        C40 : [],
        C11 : [],
        C12 : [],
        C13 : [],
        C14 : [],
        C15 : [],
        C16 : [],
        C17 : [],
        C18 : [],
        C19 : [],

        C22 : [],
        C24 : [],

        C31 : [],
        C32 : [],
        C33 : [],
        C34 : [],
        C35 : [],
        C36 : [],
        C37 : [],

        C41 : [],
        C42 : [],
        C43 : [],
        C44 : [],}

    const [contents ,setContents] = useState( ArrayContents)

    const deleteflg = useRef(false)

    const noneCount = useRef(0)

    const [searchOn,setSearchOn] = useState(false)

    const [firstScroll , setFirstScroll] = useState(false)

    /**
     * ページの高さ（ブラウザ間のページ全体の高さをカバーする）.
     */
    const scrollHeight = () => {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    /**
     * 検索API 失敗.
     */
    const searchFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * 追加検索API 成功.
     */
    const scrollSearchSuccess = (data) => {
        // 連続検索用.
        setIsSearch(true);

        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);

        // 追加検索表示件数を増やす.
        if (data.length === contentif.ROW_DEFAULT) setRowCount(rowCount + contentif.ROW_DEFAULT);

        // リストに追加.
        if (data.length > 0) {
            let list = [];
            for (let i = 0; i < middleData.length; i++) list.push(middleData[i]);
            for (let i = 0; i < data.length; i++) list.push(data[i]);
            setMiddleData(list)
        }
    }

    const recommendRequestFailure = useCallback((err) => {
        dispatch(actions.updateRecommendContentsSuccess([], ""));
        setNoneData(true);
    }, [dispatch]);

    const recommendRequestSuccess = useCallback((data) => {
        const contentList = data[recommendif.CONTENT_LIST];
        const clickData = data[recommendif.CLICK_DATA];
        setNoneData(true);

        dispatch(actions.updateRecommendContentsSuccess(contentList, clickData));
    }, [dispatch]);

    /**
     * レコメンド検索.
     */
    const searchRecommend = () => {
        // レコメンド取得
        const recommendBody = {
            [recommendif.SPEC]: recommendConstants.getSearch0Spec(memberPlan),
            [recommendif.NUM]: recommendif.VIDEO_RECOMMEND,
            [recommendif.RECOMMENDCOOKIE]: recommendCookie,
            [recommendif.PLATFORM]: contentif.getRequestPlatform()
        };
        recommendif.postRecommendRequest(recommendBody).then(recommendRequestSuccess, recommendRequestFailure);
    };

    /**
     * 検索API 成功.
     */

    const searchSuccess = useCallback((keyword, data) => {
        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);
        dispatch(actions.updateSearchContentsList(data));

        // 検索キーワードをGA連携.
        if (keyword !== "" && keyword !== prevKeywords.current) {
            prevKeywords.current = keyword;
            cv.sendSearchKeywords(keyword);
        }
    }, [dispatch, memberPlan]);

    /**
     * 追加検索する条件.
     */
    const addScrollJudge = () => {
        if (bottomCategoryFlg) {
            if (app.isAllApp()) {
                // フッター位置までスクロールしたら追加検索を実施.
                const footerHeight = document.querySelector("footer")?.offsetHeight;
                return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
            }
            else {
                // フッター位置までスクロールしたら追加検索を実施.
                const footerHeight = document.getElementsByClassName("p-footCont")?.[0]?.offsetHeight;
                return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
            }
        }
    };

    /**
     * 追加検索.
     */
    const scrollSearch =  () => {
        if(addScrollJudge()) {
            if (isSearch && middleData.length > 0 ) {
                // 連続検索防止.
                setIsSearch(false);

                // 現在のカテゴリ・プラン・キーワードで検索.
                let request = {
                    [contentif.SEARCH_TYPE]: contentif.OPTION,
                    [contentif.RANGE_START]: rowCount + 1,
                    [contentif.ROW]: contentif.ROW_DEFAULT,
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                };
                if (keywords !== "") request[contentif.KEYWORDS] = keywords;
                if (currentCategory !== "C00") request[contentif.CATEGORY_ID] = searchCategory.getSearchCategory(currentCategory);
                if (currentPlan !== "P00") request[contentif.PLAN] = memberPlan;
                contentif.postSearch(request).then(scrollSearchSuccess, searchFailure);
            }
        }
    }

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.search());
            window.addEventListener("scroll", scrollSearch);
        

        // head設定.
        setHeadDefault(SEARCH_TITLE);

        return () => {
            window.removeEventListener("scroll", scrollSearch);
        }
    }, [dispatch, scrollSearch]);

    /**
     * TOP画面からパラメータを取得し、更新.
     */
    useEffect(() => {
        if (location !== undefined && location.state !== undefined) {
            // カテゴリーを更新
            if (location.state.categoryId !== undefined && location.state.categoryId !== "" && Object.keys(searchCategory.category).includes(location.state.categoryId)) {
                dispatch(actions.updateSearchCurrentCategory(location.state.categoryId));
            } else {dispatch(actions.updateSearchCurrentCategory("C00"));} 
            // キーワードを更新
            if (location.state.keyword !== undefined) {
                if (timeoutId !== "") clearTimeout(timeoutId);
                let toid = setTimeout(() => {
                    setKeywords(location.state.keyword);
                } , SEARCH_TIME);
                setTimeoutId(toid);
                //　検索入力ボックスに検索キーワードを表示
                searchBtnRef.current.value = location.state.keyword;
            }
            // プランを更新
            if (location.state.plan !== undefined && location.state.plan !== "" && Object.keys(searchCategory.plan).includes(location.state.plan)) {
                dispatch(actions.updateSearchCurrentPlan(location.state.plan));
            } else {dispatch(actions.updateSearchCurrentPlan("P00"));} 
        }
        if(!searchOn){
           setSearchOn(true) 
        }

        //画面実行時に呼び出し
        if(checkStorage){
            const checkStorage2 = JSON.parse(checkStorage)
            searchBtnRef.current.value = checkStorage2[checkStorage2.length-1].keyword
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            handleSearch()
            categoryNone()
        }else if(!checkStorage){
            newSession()
        }
    },[]);

    
    /**
     * 検索実行.
     */
    useEffect(() => {
        if(searchOn){
        // 現在のカテゴリ・プラン・キーワードで検索.
            let request = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.ROW]: contentif.ROW_MIDDLECATEGORY,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            if (keywords !== "") request[contentif.KEYWORDS] = keywords;
            if (currentCategory !== "C00") request[contentif.CATEGORY_ID] = searchCategory.getSearchCategory(currentCategory);
            if (currentPlan !== "P00") request[contentif.PLAN] = memberPlan;
            const categoryKey =searchCategory.getSearchCategory(currentCategory);
            deleteflg.current = false
            noneCount.current = 0
            setNoneData(false)
            if(!currentCategory.endsWith("0")){
                request.row = contentif.ROW_DEFAULT
            }
            if(categoryKey.endsWith("全カテゴリー")){
                for(let i =0;i<middleCategoryLeng.length;i++){
                    request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(middleCategoryLeng[i]);
                    contentif.postSearch(request).then(data=>listOn(data,keywords,middleCategoryLeng[i],middleCategoryLeng.length),searchFailure); 
                }

            }
            else if(categoryKey.endsWith("動画")){
                for(let i =0;i<videoLeng.length;i++){
                    request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(videoLeng[i]);
                    contentif.postSearch(request).then(data=>listOn(data,keywords,videoLeng[i],videoLeng.length),searchFailure);
                }
            }
            else if(categoryKey.endsWith("音声")){
                for(let i =0;i<soundLeng.length;i++){
                    request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(soundLeng[i]);
                    contentif.postSearch(request).then(data=>listOn(data,keywords,soundLeng[i],soundLeng.length),searchFailure);
                }
            }
            else if(categoryKey.endsWith("読み物")){
                for(let i =0;i<sentenceLeng.length;i++){
                    request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(sentenceLeng[i]);
                    contentif.postSearch(request).then(data=>listOn(data,keywords,sentenceLeng[i],sentenceLeng.length),searchFailure);
                }
            }
            else if(categoryKey.endsWith("ニュース")){
                for(let i =0;i<newsLeng.length;i++){
                    request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(newsLeng[i]);
                    contentif.postSearch(request).then(data=>listOn(data,keywords,newsLeng[i],newsLeng.length),searchFailure);
                }
            }
            else{

                contentif.postSearch(request).then(data=>listOn(data,keywords,currentCategory,1,true),searchFailure);
            }
    }
    }, [keywords,currentCategory, currentPlan, memberPlan, searchSuccess, searchFailure,searchOn]);



    const listOn =(data,keyword,category,numctg,midflg)=>{
        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);
        dispatch(actions.updateSearchContentsList(data));

        // 検索キーワードをGA連携.
        if (keyword !== "" && keyword !== prevKeywords.current) {
            prevKeywords.current = keyword;
            cv.sendSearchKeywords(keyword);
        }
        if(!deleteflg.current){
            resetArray()
            deleteflg.current = true
        }

        if(data.length===0){
            noneCount.current++
        }
        if(noneCount.current>=numctg){
            // 検索結果0件の時はレコメンド表示.
            if (recommendConstants.getSearch0Spec(memberPlan) !== "") {
                searchRecommend();
            }
            else {
                setNoneData(true);
            }
        }
        let dataArray =[]
            for(let i =0;i<DISPLAY_COUNT;i++){
                if(data[i] !== undefined){
                    dataArray.push(data[i])
                }
                else{
                    break;
                }
            }
        setContents((contents)=>({...contents,[category]:[...dataArray]}))
        if(midflg){
            setMiddleDataCategory(category)
            setMiddleData(data)
        } 
        if(!firstScroll){
            const checkStorage2 = JSON.parse(checkStorage)
            if(checkStorage2[checkStorage2.length-1].scrollY===undefined){
                window.scroll(0,0)
            }else{
                setTimeout(()=>(
                    window.scroll(0,checkStorage2[checkStorage2.length-1].scrollY)
                ),SCROLLINTERVAL)
            }
            setFirstScroll(true)
        }
    }

    /**
     * ファイル：プラネット定義取得 失敗.
     */
     const getRankingMetaFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * ランキングメタ定義を取得.
     */
     useEffect(() => {
        // レコメンド取得しない場合のみ、ランキングメタを取得する.
        if (recommendConstants.getSearch0Spec(memberPlan) !== "") return;
        // ファイル：ランキングメタ定義
        fileApi.getRankingMeta().then(
            (body) => dispatch(actions.getRankingMetaSuccess(body)),
            getRankingMetaFailure
        );
        dispatch(actions.updateRecommendContentsSuccess([], ""));
    }, [dispatch, getRankingMetaFailure, memberPlan]);

    /**
     * 追加検索ステータスを初期化.
     */
    const initScrollSearchState = () => {
        // 追加検索ステータスを初期値に戻す.
        setIsSearch(true);
        setRowCount(contentif.ROW_DEFAULT);
    }

    /**
     * アクティブの切り替え.
     */
    const handleActive = () => {
        // 要素の取得に失敗.
        if (iconGridRef.current === null || iconListRef.current === null) return;

        // アクティブの切り替え.
        if (isGrid) {
            iconListRef.current.classList.remove("is-active");
            iconGridRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        } else {
            iconGridRef.current.classList.remove("is-active");
            iconListRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        }
    }

    /**
     * 検索ボタン押下.
     */
    const handleSearch = () => {
        // 要素の取得に失敗.
        if (searchBtnRef.current === null) return;
        initScrollSearchState();
        setKeywords(searchBtnRef.current.value);
    }

    /**
     * 検索入力ボックス変更.
     */
    const handleOnChange = (e) => {
        if (timeoutId !== "") clearTimeout(timeoutId);
        let toid = setTimeout(() => {
            setKeywords(e.target.value);
        } , SEARCH_TIME);
        setTimeoutId(toid);
    }

    /**
     * カテゴリーの表示切り替え.
     */
    const handleCategory = () => {
        // 要素の取得に失敗.
        if (searchCategoryRef.current === null
            || searchWorksRef.current === null
            || categoryRef.current === null
            || worksRef.current === null) return;
        // アクティブの切り替え.
        if (category) {
            categoryRef.current.classList.remove("is-transform");
            searchCategoryRef.current.style.display = "none";
            setCategory(false);
        } else {
            categoryRef.current.classList.add("is-transform");
            searchCategoryRef.current.style.display = "block";
            if (plan) {
                worksRef.current.classList.remove("is-transform");
                searchWorksRef.current.style.display = "none";
                setPlan(false);
            }
            setCategory(true);
        }
    }

    /**
     * プラン表示切り替え.
     */
    const handlePlan = () => {
        // 要素の取得に失敗.
        if (searchCategoryRef.current === null
            || searchWorksRef.current === null
            || categoryRef.current === null
            || worksRef.current === null) return;

        // アクティブの切り替え.
        if (plan) {
            worksRef.current.classList.remove("is-transform");
            searchWorksRef.current.style.display = "none";
            setPlan(false);
        } else {
            worksRef.current.classList.add("is-transform");
            searchWorksRef.current.style.display = "block";
            if (category) {
                categoryRef.current.classList.remove("is-transform");
                searchCategoryRef.current.style.display = "none";
                setCategory(false);
            }
            setPlan(true);
        }
    }

    /**
     * カテゴリー選択.
     */
    const handleSearchCategory = (ctg) => {
        handleCategory();
        initScrollSearchState();
        dispatch(actions.updateSearchCurrentCategory(ctg));

        if(ctg.startsWith("C1")||
        ctg.startsWith("C2")||
        ctg.startsWith("C3")||
        ctg.startsWith("C4")
        ){
            if(ctg.endsWith("0")){
              middleflgChg()
              if(ctg.startsWith("C1")){
                  videochg()
              }else if(ctg.startsWith("C2")){
                  musicchg()
              }else if(ctg.startsWith("C3")){
                  sentencechg()
              }else if(ctg.startsWith("C4")){
                  newschg()
              }
            }else{
              bottomflgChg()
            }
            
        }else{
            topflgChg()
        }
    }
    

    /**
     * プラン選択.
     */
    const handleSearchPlan = (ctg) => {
        handlePlan();
        initScrollSearchState();
        dispatch(actions.updateSearchCurrentPlan(ctg));
    }

    /**
     * 改行を挿入.
     */
    const changeBr = (ctg) => {
      var ctgs = ctg.split("<br/>");
      if(ctgs.length >= 2){
        let ctgsAll = String(ctgs[0]+ctgs[1])
        return ctgsAll;
      }
      if (ctgs.length !== 2) return ctg;
    }
    

    const storageSave = (value) =>{
        if(value){
            if(value.indexOf("C")===0){
              categoryMethod = value
              planMethod = currentPlan
            }else if(value.indexOf("P")===0){
              categoryMethod = currentCategory
              planMethod = value
            }else{
              categoryMethod = currentCategory
              planMethod = currentPlan
            }
        }else{
            categoryMethod = currentCategory
            planMethod = currentPlan 
        }
        keywordMethod = searchBtnRef.current.value
        let checkStorage2 = JSON.parse(sessionStorage.getItem("search"))
        checkStorage2.push({"category":categoryMethod,"plan":planMethod,"keyword":keywordMethod})
        if(checkStorage2.length >= SESSIONVAL){
            let keepStorage =[]
            for(let i=1;i<SESSIONVAL ;i++){
                keepStorage.push(checkStorage2[i])
            }
            sessionStorage.setItem("search",JSON.stringify(keepStorage))
        }else{
            sessionStorage.setItem("search",JSON.stringify(checkStorage2))  
        }
    }


    const nextCategory =(value)=>{
        updateSessionScroll()
        storageSave(value)
        handleSearchPlan(currentPlan)
        handleSearchCategory(value)
        handleSearch()
        categoryNone()
        window.scroll(0,0)
    }

    const updateSessionScroll =()=>{
        const scrollY =window.scrollY
        let checkStorage2 = JSON.parse(sessionStorage.getItem("search"))
        checkStorage2.pop() 
        checkStorage2.push({"category":currentCategory,"plan":currentPlan,"keyword":searchBtnRef.current.value,"scrollY":scrollY})
        sessionStorage.setItem("search",JSON.stringify(checkStorage2))
    }

    const newSession=()=>{
        saveMethod=[{"category":"C00","plan":"P00","keyword":""}]
        sessionStorage.setItem("search",JSON.stringify(saveMethod))
        handleSearch()
    }

    const categoryNone = ()=>{
        worksRef.current.classList.remove("is-transform");
        searchWorksRef.current.style.display = "none";
        categoryRef.current.classList.remove("is-transform");
        searchCategoryRef.current.style.display = "none";
        setCategory(false)
        setPlan(false)
    }

    const topflgChg = () =>{
        setTopCategoryFlg(true)
        setMiddleCategoryFlg(false)
        setBottomCategoryFlg(false)
    }
    const middleflgChg = () =>{
        setTopCategoryFlg(false)
        setMiddleCategoryFlg(true)
        setBottomCategoryFlg(false)
    }
    const bottomflgChg = () =>{
        setTopCategoryFlg(false)
        setMiddleCategoryFlg(false)
        setBottomCategoryFlg(true)
    }
    const videochg =()=>{

        setVideoFlgOn(true)
        setSentenceFlgOn(false)
        setSoundFlgOn(false)
        setNewsFlgOn(false)
    }
    const sentencechg =()=>{

        setVideoFlgOn(false)
        setSentenceFlgOn(true)
        setSoundFlgOn(false)
        setNewsFlgOn(false)
    }
    const musicchg =()=>{
       
        setVideoFlgOn(false)
        setSentenceFlgOn(false)
        setSoundFlgOn(true)
        setNewsFlgOn(false)
    }
    const newschg =()=>{
        
        setVideoFlgOn(false)
        setSentenceFlgOn(false)
        setSoundFlgOn(false)
        setNewsFlgOn(true)
    }
    useEffect(()=>{
        const checkStorage2 = JSON.parse(sessionStorage.getItem("search"))
        if(!checkStorage2){
            newSession()
        }
        else if(checkStorage2.length>0){
            searchBtnRef.current.value = checkStorage2[checkStorage2.length-1].keyword
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            handleSearch()
            categoryNone()
            if(checkStorage2[checkStorage2.length-1].scrollY===undefined){
                window.scroll(0,0)
            }else{
                setTimeout(()=>(
                    window.scroll(0,checkStorage2[checkStorage2.length-1].scrollY)
                ),SCROLLINTERVAL)
            }
        }
    },[backInfo])

    useEffect(()=>{
        const checkStorage2 = JSON.parse(sessionStorage.getItem("search"))
        if(checkStorage2.length>0){
            searchBtnRef.current.value = checkStorage2[checkStorage2.length-1].keyword
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            handleSearch()
            categoryNone()
        }
    },[footerInfo])

    const searchValue=(props,props2)=>{
      if(props.length!== 0){
        return (
          <>
          <section className="c-sect c-sect--listDis">
            <div className="c-sect__band">
              {props.length >= DISPLAY_COUNT && !bottomCategoryFlg?
               <a className={classes.clickable+" p-searchListLink"} 
               onClick={()=>{nextCategory(props2)}}
               >{changeBr(searchCategory.category[props2])}</a>
              :
              <a className="p-searchListLink">{changeBr(searchCategory.category[props2])}</a>
              }
            </div>
            <div className={isGrid?"p-searchListGrid":"p-searchList"}>
              <ul className={isGrid?"p-searchListGrid__inner":"p-searchList__wrap"}>
                {Object.entries(props).map(([, value]) => (
                    <li key={value[contentsKey.CRID]} className={isGrid?"c-contCardCatList__item":"p-searchList__item"}onClick={()=>{updateSessionScroll()}}>
                        <DefaultList meta={value} isGrid={isGrid?true:false} />
                    </li>
                ))}
                {props.length >= DISPLAY_COUNT && !bottomCategoryFlg&&
                       <li className={isGrid?" p-searchList__item p-searchList__item__more":" p-searchList__item p-searchList__item__more"}>
                         <a className="p-searchList__itemCard--more">
                           <div className={classes.clickable+" p-searchList__itemCard__itemMoreTxt"}onClick={()=>{nextCategory(props2)}}>もっとみる</div>
                         </a>
                       </li>
                }
              </ul>
            </div>
          </section>
          </>
        )
      }

    }

    const resetArray =()=>{
        setContents({...ArrayContents})
        setMiddleData([])
        setMiddleDataCategory([])
    }

    const scrollReset =()=>{
        window.scroll(0,0)
    }

    const historyLibraryIdRecommend = () => {
        let list = [];
        list.push(libraryId.RECOMMEND);
        list.push(libraryId.SEARCH0);
        historys.historylibraryId(history, historys.getEncodeId(list));
    };

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <section className="c-sectPage">
                    <div className="c-sectPage__hero">
                        <h1 className="c-sectPage__ttl c-sectPage__ttl--delType">検索</h1>
                    </div>
                </section>
                <div className="c-container">
                    <div className="p-searchBtn">
                        <div className="p-searchBtn__wrap">
                            <div className="p-searchBtn__form">
                                <div className="c-search_container">
                                    <div className="p-searchBtn__icon c-icon c-picIconSearch2--gray"></div>
                                    <input
                                        type="text"
                                        size="25"
                                        placeholder="すべてのプラネットから検索します"
                                        className="p-searchBtn__text"
                                        onChange={(e) => {handleOnChange(e);}}
                                        ref={searchBtnRef}
                                        onBlur={()=>storageSave()}
                                        maxLength={KEYWORD_COUNT}
                                    />
                                    <input type="submit" value="検索" onClick={() => handleSearch()}/>
                                </div>
                            </div>
                            <div className="p-searchBtn__list">
                                <div className="p-searchBtn__choice">
                                    <div
                                        className="p-searchBtn__item p-searchBtn__item--category"
                                        onClick={() => handleCategory()}
                                        ref={categoryRef}
                                    >
                                        <div className="p-searchBtn__itemTxt">{changeBr(searchCategory.category[currentCategory])}</div>
                                    </div>
                                    <div
                                        className="p-searchBtn__item p-searchBtn__item--works"
                                        onClick={() => handlePlan()}
                                        ref={worksRef}
                                    >
                                        <div className="p-searchBtn__itemTxt">{searchCategory.plan[currentPlan]}</div>
                                        {/* メニュー展開- */}
                                        <div
                                            className="p-searchDeployWorks"
                                            ref={searchWorksRef}
                                        >
                                            <div className="p-searchDeployWorks__head" onClick={() => {handleSearchPlan("P00");storageSave("P00");scrollReset();}}>
                                                {searchCategory.plan["P00"]}
                                            </div>
                                            <div className="p-searchDeployWorks__compile">
                                                <ul className="p-searchDeployWorks__box">
                                                    <li className="p-searchDeployWorks__item p-searchDeployWorks__item--border" onClick={() => {handleSearchPlan("P11");storageSave("P11");scrollReset();}}>
                                                        {searchCategory.plan["P11"]}
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-searchBtn__changeDis" onClick={() => handleActive()}>
                                    <div className="p-searchBtn__item p-searchBtn__item--change">
                                        <div className={"p-searchBtn__icon c-icon c-picIconGrid " + (isGrid ? "is-active": "")} ref={iconGridRef}></div>
                                        <div className={"p-searchBtn__icon c-icon c-picIconList " + (isGrid ? "": "is-active")} ref={iconListRef}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className="p-searchDeployCategory"
                            ref={searchCategoryRef}
                        >
                            <div className="p-searchDeployCategory__head" onClick={() => {handleSearchCategory("C00");storageSave("C00");scrollReset();}}>{searchCategory.category["C00"]}</div>
                            {/* 追加項目 */}
                            <div className="p-searchDeployCategory__compile"></div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text" onClick={() => {handleSearchCategory("C10");storageSave("C10");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--play">
                                      <div className="c-icon__Txt c-picIconPlay"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C10"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C11");storageSave("C11");scrollReset();}}>{searchCategory.category["C11"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C12");storageSave("C12");scrollReset();}}>{changeBr(searchCategory.category["C12"])}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C13");storageSave("C13");scrollReset();}}>{searchCategory.category["C13"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C14");storageSave("C14");scrollReset();}}>{changeBr(searchCategory.category["C14"])}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C15");storageSave("C15");scrollReset();}}>{searchCategory.category["C15"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C16");storageSave("C16");scrollReset();}}>{searchCategory.category["C16"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C17");storageSave("C17");scrollReset();}}>{searchCategory.category["C17"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C18");storageSave("C18");scrollReset();}}>{searchCategory.category["C18"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C19");storageSave("C19");scrollReset();}}>{searchCategory.category["C19"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text"onClick={() => {handleSearchCategory("C20");storageSave("C20");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--radio">
                                      <div className="c-icon__Txt c-picIconRadio"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C20"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C22");storageSave("C22");scrollReset();}}>{searchCategory.category["C22"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C24");storageSave("C24");scrollReset();}}>{searchCategory.category["C24"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text"onClick={() => {handleSearchCategory("C30");storageSave("C30");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--article">
                                      <div className="c-icon__Txt c-picIconarticle"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C30"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C31");storageSave("C31");scrollReset();}}>{searchCategory.category["C31"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C32");storageSave("C32");scrollReset();}}>{searchCategory.category["C32"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C33");storageSave("C33");scrollReset();}}>{searchCategory.category["C33"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C34");storageSave("C34");scrollReset();}}>{searchCategory.category["C34"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C35");storageSave("C35");scrollReset();}}>{searchCategory.category["C35"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C36");storageSave("C36");scrollReset();}}>{searchCategory.category["C36"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C37");storageSave("C37");scrollReset();}}>{searchCategory.category["C37"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text" onClick={() => {handleSearchCategory("C40");storageSave("C40");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--attention">
                                      <div className="c-icon__Txt c-picIconAttention"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C40"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box p-searchDeployCategory__box--lastCont">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C41");storageSave("C41");scrollReset();}}>{searchCategory.category["C41"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C42");storageSave("C42");scrollReset();}}>{searchCategory.category["C42"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C43");storageSave("C43");scrollReset();}}>{searchCategory.category["C43"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C44");storageSave("C44");scrollReset();}}>{searchCategory.category["C44"]}</li>
                                </ul>
                              </div>
                          </div>
                    </div>
                    <div className="p-searchListBox"></div>
                    {/* 検索１件以上 */}
                       {topCategoryFlg  &&!noneData  &&
                       <>
                            <div className="p-searchListWrap">
                                <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                {Object.entries(middleCategoryLeng).map(([,value]) => (      
                                    searchValue(contents[value],value)
                                ))}
                                </div>
                            </div>
                        </>
                        }
                      {middleCategoryFlg  &&!noneData  &&
                        <div className="p-searchListWrap">
                            <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                    {/* リスト表示 */}
                                    <>
                                    {videoFlgOn  &&
                                    <>
                                    {Object.entries(videoLeng).map(([,value]) => (
                                        searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                    {soundFlgOn  &&
                                    <>
                                    {Object.entries(soundLeng).map(([,value]) => (
                                        searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                    {sentenceFlgOn&&
                                    <>
                                    {Object.entries(sentenceLeng).map(([,value]) => (
                                      searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                    {newsFlgOn && 
                                    <>
                                    {Object.entries(newsLeng).map(([,value]) => (
                                        searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                     </>
                            </div>
                        </div>
                        }
                    {/* 中カテゴリ画面 */}
                        {bottomCategoryFlg  &&!noneData  &&
                         <div className="p-searchListWrap">
                            <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                   {searchValue(middleData,middleDataCategory)}
                             </div>
                         </div>
                        }
                    {/* 検索０件 */}
                    {noneData &&
                    <div class="p-searchListWrap p-searchList__zero">
                        <p class="p-searchList__zero--txt">お探しのコンテンツは見つかりませんでした。</p>
                        {recommendList.length !== 0 && 
                            <div>
                                <h2 class="c-sect__band">あなたへのおすすめ</h2>
                                <div class="p-searchListBox p-searchListBox--list js-searchDisplay is-show">
                                    <section class="c-sect c-sect--listDis">
                                        {/* リスト表示 */}
                                        {!isGrid &&
                                            <div className="p-searchList">
                                                <ul className="p-searchList__wrap">
                                                    {Object.entries(recommendList).map(([, value]) => (
                                                        <li key={value[contentsKey.CRID]} className="p-searchList__item">
                                                            <DefaultList meta={value} isGrid={false} clickData={clickData} />
                                                        </li>
                                                    ))}
                                                    <li className="p-searchList__item p-searchList__item__more">
                                                        <a className={classes.clickable + " p-searchList__itemCard--more"} onClick={() => historyLibraryIdRecommend()}>
                                                            <div className="p-searchList__itemCard__itemMoreTxt">もっとみる</div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                        {/* グリッド表示 */}
                                        {isGrid &&
                                            <div className="p-searchListGrid">
                                                <ul className="p-searchListGrid__inner">
                                                    {Object.entries(recommendList).map(([, value]) => (
                                                        <li key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                            <DefaultList meta={value} isGrid={true} clickData={clickData} />
                                                        </li>
                                                    ))}
                                                    <li className="p-searchList__item p-searchList__item__more">
                                                        <a className={classes.clickable + " p-searchList__itemCard--more"} onClick={() => historyLibraryIdRecommend()}>
                                                            <div className="p-searchList__itemCard__itemMoreTxt">もっとみる</div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </section>
                                </div>
                            </div>
                        }
                        {recommendList.length === 0 && ranking_meta.length !== 0 && 
                            <div>
                                <h2 className="c-sect__band">みんなが見ているコンテンツ</h2>
                                <div className="p-searchListBox p-searchListBox--list js-searchDisplay is-show">
                                    <section className="c-sect c-sect--listDis">
                                        {/* リスト表示 */}
                                        {!isGrid &&
                                            <div className="p-searchList">
                                                <ul className="p-searchList__wrap">
                                                    {Object.entries(ranking_meta).map(([, value]) => (
                                                        <li key={value[contentsKey.CRID]} className={isGrid?"p-searchListGrid__item":"p-searchList__item"}>
                                                            <DefaultList meta={value} isGrid={isGrid?true:false} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                        {/* グリッド表示 */}
                                        {isGrid &&
                                            <div className={isGrid?"p-searchListGrid":"p-searchList"}>
                                                <ul className={isGrid?"p-searchListGrid__inner":"p-searchList__wrap"}>
                                                    {Object.entries(ranking_meta).map(([, value]) => (
                                                        <li key={value[contentsKey.CRID]} className="p-searchListGrid__item">
                                                            <DefaultList meta={value} isGrid={true} />
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        }
                                    </section>
                                </div>
                            </div>
                        }
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Search;
