import React, { useEffect } from "react";

import * as authIf from "../../../middleware/authif";

/**
 * dアカウント紐付画面.
 */
const DcmAccountLink = () => {
    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
    }, []);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * リンクまたは取得サイトへの遷移.
     */
    const linkDcmAcount = () => {
        document.body.classList.remove("is-fixed");

        // dアカウントログイン画面へ遷移.
        authIf.postLogin({[authIf.TYPE]: authIf.WEB_LOGIN, [authIf.DESTINATION]: authIf.DACCOUNT})
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup">
                <div className="p-popup__box p-popup__box--largeBox">
                    <div className="p-popupBox">
                        <div className="p-popupBoxTtl">
                            <img className="p-popupBoxTtl__img" src="/assets/images/logo-brand-imagination.png" alt="" />
                        </div>
                        <div className="p-popupBoxWrap">
                            <div className="p-popupBoxWrap__step">
                                <div className="p-popupBoxWrap__stepTtl">次のステップ</div>
                                <div className="p-popupBoxWrap__docomoImg">
                                    <img src="/assets/images/img-docomo.png" alt="" />
                                </div>
                                <div className="p-popupBoxWrap__stepDtl">本サービスはNTTドコモと連携したサービスです。<br/>TSUBURAYA IMAGINATIONのご登録にはdアカウントとリンクする必要があります。</div>
                            </div>
                            <div className="c-btn p-popupBoxWrap__btn p-popupBoxWrap__btn--docomoBtn c-btn--thinRed" onClick={() => linkDcmAcount()}>dアカウントをリンクまたは取得する</div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default DcmAccountLink;
