import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    cursor:{
        "cursor": "pointer",
    },
    searchListGrid:{
        "list-style-type": "none",
        "paddingBottom":"1.059546514091969%"
    },
    searchList:{
        "list-style-type": "none",
        "paddingBottom":"1.064282673478076%"
    },
    logoArea:{
        "height":"7.49400479616307%"
    }
}));

export default styles;
