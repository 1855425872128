import * as actionTypes from "./actionTypes";

/**
 *  非会員お知らせを既読に更新.
 */
export const readNoneNotice = (id) => {
    return {
        type: actionTypes.READ_NONE_NOTICE,
        id: id
    };
}
