import * as actionTypes from "./actionTypes";

/**
 * 初期化.
 */
export const initLibraryId = () => {
    return {
        type: actionTypes.INIT_LIBRARYID
    };
}

/**
 * シリーズタイトル更新.
 */
export const updateSeriesTitle = (data) => {
    return {
        type: actionTypes.LIBRARYID_UPDATE_SERIES_TITLE,
        seriesTitle: data
    };
}

/**
 * コンテンツ更新.
 */
export const updateContentsList = (data) => {
    return {
        type: actionTypes.LIBRARYID_UPDATE_CONTENTS_LIST,
        contentsList: data
    };
}

/**
 * 特典コンテンツ更新.
 */
export const updateGiftContentsList = (data) => {
    return {
        type: actionTypes.LIBRARYID_UPDATE_GIFT_CONTENTS_LIST,
        giftContentsList: data
    };
}

/**
 * レコメンドコンテンツ更新.
 */
export const updateRecommendContentsList = (contentsList, clickData) => {
    return {
        type: actionTypes.LIBRARYID_UPDATE_RECOMMEND_CONTENTS_LIST,
        contentsList: contentsList,
        clickData: clickData
    };
}
