import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as commonActions from "../../common/status/actions";
import { loading } from "../../common/status/actions";
import * as eventif from "../../middleware/eventif";
import * as campaign from "../../constants/campaign";
import * as contentsKey from "../../constants/contentsKey";
import * as error from "../../constants/error";
import * as cv from "../../constants/cv";
import { historyReplaceContents } from "../../historys";

/**
 * クーポンチケット行使確認.
 *
 * props
 *  -> input:title タイトル.
 *  -> input:content 内容.
 *  -> closePopup クーポンチケット行使確認非表示.
 */
const CouponTicket = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    /** チェックボックスDOM. */
    const checkboxRef = useRef();
    /** OKボタンDOM. */
    const okRef = useRef();

    /** チェックボックス. */
    const [isCheckBox, setIsCheckBox] = useState(false);

    /** コンテンツ情報. */
    const content = useSelector(state => state.ContentsId.content);

    /**
     * チェックボックス切り替え時の処理.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (checkboxRef.current === null || okRef.current === null) return;

        // 表示切替.
        if (isCheckBox) {
            checkboxRef.current.classList.add("is-active");
            okRef.current.classList.add("is-active");
        } else {
            checkboxRef.current.classList.remove("is-active");
            okRef.current.classList.remove("is-active");
        }
    }, [isCheckBox]);

    /**
     * 「はい」押下時の処理.
     */
    const onAccept = () => {
        if (isCheckBox) {
            dispatch(loading(true));

            // クーポン管理API呼び出し.
            eventif.postCoupon({
                [eventif.CAMPAIGN_ID]: content[contentsKey.CAMPAIGN_ID],
                [eventif.GIFT_SOURCE_CRID]: content[contentsKey.GIFT_SOURCE_CRID]
            }).then((res) => {
                if (campaign.isCompleted(res)) {
                    // cv計測処理.
                    cv.sendCouponCV(res[campaign.CAMPAIGN_NAME], res[campaign.COUPON_ID]);
                    // 履歴保存なしで自分自身に遷移.
                    historyReplaceContents(history, content[contentsKey.CRID]);
                }
                else {
                    // クーポン行使エラーを表示.
                    const title = error.COUPON_USED_FAILURE_TITLE;
                    const content = error.COUPON_USED_FAILURE_CONTENT;
                    dispatch(commonActions.couponUsedFailure(true, title, content));
                }
            }, (err) => {
                // セッションエラーを確認.
                eventif.isSessionError(dispatch, err);
                // エラー処理.
                eventif.postCouponFailure(dispatch, location, history, err);
            })
            .finally(() => {
                dispatch(loading(false));
                props.closePopup();
            });
        }
    }

    /**
     * チェックボックス切替.
     */
    const handleCheckBox = (event) => {
        setIsCheckBox(event.target.checked);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    return (
        <div onContextMenu={(event) => handleContextMenu(event)}>
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{props.input.title}</div>
                            <div className="p-popup__txt">{props.input.content?.split("\n").map(x => (<span>{x}<br /></span>))}</div>
                            <div className="c-prof__cubeInfoPop__checkBox">
                                <div className="c-prof__cubeInfoPop__checkBoxWrap">
                                    <label className="c-prof__cubeInfoPop__checkBoxItem">
                                        <input
                                            className="c-prof__cubeInfoPop__checkBoxMark"
                                            type="checkbox"
                                            onChange={handleCheckBox}
                                        />
                                        <span className="c-prof__cubeInfoPop__checkBoxDummy" ref={checkboxRef}></span>
                                        <span className="c-prof__cubeInfoPop__checkBoxTxt" for="check">私は運営スタッフとして<br/>この特典を受け取り済みにします。</span>
                                    </label>
                                </div>
                            </div>
                            <div className="c-prof__cubeInfo__attention">※誤って操作すると、特典を受け取れなくなります。</div>
                            <div className="c-btnWrap c-btnWrap--cubeInfo">
                                <div className="c-btn c-btnWrap__size c-btn--gray" onClick={props.closePopup}>キャンセル</div>
                                <div
                                    className="c-btn c-btnWrap__size c-btn--gray c-prof__cubeInfo__btnActive"
                                    ref={okRef}
                                    onClick={onAccept}
                                >
                                    OK
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default CouponTicket;
