import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer"
    },
    planet: {
        margin: 5
    }
}));

export default styles;
