import { CONTRACTIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";
import * as memberActions from "../common/status/member/actions";
import { doLogout } from "../components/dialog/Logout";

/** contractType: プラン変更. */
export const PLAN = "plan";

/** contractType: 退会. */
export const LEAVE = "leave";

/** contractType: TVOD等の購入（都度購入）. */
export const PURCHASE_TYPE = "purchase";

/** contractType: プラン変更／退会予約のキャンセル. */
export const CANCEL = "cancel";

/** 購入済み. */
export const PURCHASE = "purchase";

/** 特典. */
export const GIFT = "gift";

/** ライセンスID. */
export const LICENSE_ID = "licenseId";

/** 課金開始日. */
export const CHARGE_START_DATE_TIME = "chargeStartDatetime";

/** 有効終了日時. */
export const VALID_END_DATE_TIME = "validEndDatetime";

/** 状態. */
export const STATUS = "status";

/** 状態 現在のプラン. */
export const STATUS_0 = "0";

/** 状態 変更後のプラン. */
export const STATUS_1 = "1";

/** 契約種別. */
export const CONTRACT_TYPE = "contractType";

/** 変更前プラン. */
export const BEFORE_PLAN = "beforePlan";

/** 変更後プラン. */
export const AFTER_PLAN = "afterPlan";

/** 解約予約中プラン. */
export const RECOVER_PLAN = "recoverPlan";

/** 契約予約中プラン. */
export const CANCEL_PLAN = "cancelPlan";

/** カード管理ID. */
export const CARDLIST_ID = "cardlistId";

/** セキュリティコード. */
export const SEC_CODE = "secCode";

/** クーポンコード. */
export const COUPON_CODE = "couponCode";

/** CSRFトークン. */
export const CSRF_TOKEN = "csrfToken";

/** 海外エラー番号下3桁. */
export const OVERSEAS_ERROR_CODE = "102";

/** セッションエラー. */
export const WAR04011 = "WAR04011";

/** エラーコード：無効なクーポン. */
export const WAR04017 = "WAR04017";

/** エラーコード：クーポンが有効期間前. */
export const WAR04018 = "WAR04018";

/** エラーコード：クーポンが有効期限切れ. */
export const WAR04019 = "WAR04019";

/** エラーコード：円谷クーポンAPIでエラー. */
export const WAR04020 = "WAR04020";

/** エラーコード：クーポンを同じユーザで使用済み. */
export const WAR04021 = "WAR04021";

/** エラーコード：クーポンの使用可能プラン外. */
export const WAR04024 = "WAR04024";

/**
 * 購入一覧取得API.
 * API: GET /contractmanage
 */
export async function getContractManage() {
    return new Promise((resolve, reject) => {
        apiUtil.api.get(CONTRACTIF + "/get/contractmanage")
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 契約管理API.
 * API: POST /contractmanage
 */
export async function postContractManage(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(CONTRACTIF + "/contractmanage", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * セッションエラーはログアウト.
 */
export const isSessionError = (dispatch, err) => {
    if (apiUtil.isSessionError(err, WAR04011)) doLogout(dispatch);
}

/**
 * 購入一覧取得API成功時のデフォルト処理.
 */
export const getContractManageSuccess = (dispatch, data) => {
    // ユーザー情報を更新.
    const plan = data[PLAN] !== undefined ? data[PLAN] : [];
    const purchase = data[PURCHASE] !== undefined ? data[PURCHASE] : [];
    const gift = data[GIFT] !== undefined ? data[GIFT] : [];
    let mp = "";
    let cmp = "";
    let vedt = "";

    for (var i = 0; i < plan.length; i++) {
        // 現在のプラン情報.
        if (plan[i][STATUS] === STATUS_0) {
            mp = plan[i][LICENSE_ID];
            const csdt = plan[i][CHARGE_START_DATE_TIME];
            let ed = "";
            let isCampaign = false;

            if (csdt !== null || csdt !== undefined) {
                // 無料期間（キャンペーン）中判定.
                const now = new Date().getTime();
                const csdt_unix = Date.parse(csdt);
                if (now < csdt_unix) isCampaign = true;
                // 有効期限.
                ed = apiUtil.getExpirationDate(mp, csdt_unix, isCampaign);
            }
            dispatch(memberActions.updateMemberPlan(mp, ed, isCampaign));

            // 変更予定日用に保存.
            vedt = plan[i][VALID_END_DATE_TIME];
        }
        // 変更後のプラン情報.
        if (plan[i][STATUS] === STATUS_1) {
            cmp = plan[i][LICENSE_ID];
        }
    }

    // 変更後のプラン情報を更新.
    if (plan.length > 1) dispatch(memberActions.updateChangeMemberPlan(cmp, apiUtil.getChangeDate(vedt)));
    else dispatch(memberActions.updateChangeMemberPlan(cmp, vedt));

    // 購入一覧を更新.
    dispatch(memberActions.updatePurchaseList(purchase, gift));

    // 現在のプランを返却.
    return mp;
}
