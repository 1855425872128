import * as actionTypes from "./actionTypes";

const initState = {
    /** アクティブタブ. */
    currentActive: 0,
    /** 特集バナーリスト. */
    topBanner: [],
    /** アイちゃんメッセージ. */
    greeting: [],
    /** コーナー配列. */
    cornerArr: [],
    /** ブロック配列. */
    blockArr: [],
    /** コンテンツ配列. */
    contentArr: [],
    /** URL配列. */
    urlArr: [],
    /** レコメンドクリック情報. */
    clickData: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** ファイル：TOP画面取得 成功. */
        case actionTypes.GET_TOP_SUCCESS:
            return {
                ...state,
                topBanner: action.topBanner,
                greeting: action.greeting,
                cornerArr: action.cornerArr,
                blockArr: action.blockArr,
                contentArr: action.contentArr,
                urlArr: action.urlArr
            };
        /** タブを切り替え. */    
        case actionTypes.CHANGE_TAB:
            return {
                ...state,
                currentActive: action.currentActive
            }
         /** レコメンド取得 成功. */
        case actionTypes.GET_TOP_RECOMMEND_SUCCESS:
            return { ...state, contentArr: action.contentArr, clickData: action.clickData };
        default:
            return state;
    }
};

export default reducer;