import { ACCOUNTIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";
import * as memberActions from "../common/status/member/actions";
import { doLogout } from "../components/dialog/Logout";

/** 会員ID. */
export const USER_ID = "userId";

/** 会員プラン. */
export const PLAN_NAME = "planName";

/** プロフィール画像URL. */
export const PROFILE_IMAGE_URL = "profileImageUrl";

/** 入会日. */
export const ADMISSION_DATETIME = "admissionDatetime";

/** クレジットカード番号. */
export const CARD_NUMBER = "cardNumber";

/** プラン選択済みフラグ. */
export const PLAN_SELECTED_FLG = "planSelectedFlg";

/** 未読フラグ. */
export const READ_FLG = "readFlg";

/** ポップアップ種別. */
export const POPUP_TYPE = "popupType";
export const BIG = "big";

/** 通知ID. */
export const NOTICE_ID = "noticeId";

/** 視聴中一覧. */
export const RESUME_LIST = "resumeList";

/** 視聴完了一覧. */
export const COMPLETE_LIST = "completeList";

export const STOP_POSITION = "stopPosition";

/** Crid. */
export const CRID = "crid";

/** データ. */
export const DATA = "data";

/** 図鑑タイプ. */
export const CUBE_TYPE = "cubeType";

/** スペシャル図鑑検索. */
export const SPECIAL = "SPECIAL";

/** 図鑑ID. */
export const CUBE_GROUP_ID = "cubeGroupId";

/** キューブID. */
export const CUBE_ID = "cubeId";

/** ダイアログタイトル. */
export const DIALOG_TITLE = "dialogTitle";

/** ダイアログ本文. */
export const DIALOG_MESSAGE = "dialogMessage";

/** ボタン文言. */
export const DIALOG_BUTTON = "dialogButton";

/** セッションエラー. */
export const WAR01003 = "WAR01003";

/** フォーム重複送信エラー. */
export const WAR01005 = "WAR01005";

/** 入力失敗（通常フォーム）. */
export const ERR01100 = "ERR01100";

/** 入力完了したが付与失敗（クーポン付与パターンのみ）. */
export const ERR01200 = "ERR01200";

/**
 * レジューム情報取得API.
 * API: GET /resume
 */
export async function getResume() {
    return new Promise((resolve, reject) => {
        apiUtil.api.get(ACCOUNTIF + "/resume")
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 通知一覧取得API.
 * API: POST /noticelist
 */
export async function postNoticeList(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/noticelist", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 通知既読設定API.
 * API: POST /notice
 */
export async function postNotice(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/notice", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * アンケート回答送信API.
 * API: POST /form
 */
export async function postForm(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/form", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject([apiUtil.getErrMsg(res.data), apiUtil.getData(res.data)]);
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 会員情報取得API.
 * API: GET /userinfo
 */
export async function getUserInfo() {
    return new Promise((resolve, reject) => {
        apiUtil.api.get(ACCOUNTIF + "/userinfo")
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 会員情報変更API.
 * API: POST /userinfo
 */
export async function postUserInfo(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/userinfo", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * お気に入りコンテンツ取得API.
 * API: GET /favorite
 */
export async function getFavorite() {
    return new Promise((resolve, reject) => {
        apiUtil.api.get(ACCOUNTIF + "/favorite")
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * お気に入りコンテンツ変更API.
 * API: POST /favorite
 */
export async function postFavorite(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/favorite", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * キャンペーン一覧取得API.
 * API: GET /campaign
 */
export async function getCampaign() {
    return new Promise((resolve, reject) => {
        apiUtil.api.get(ACCOUNTIF + "/campaign")
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 図鑑一覧取得API.
 * API: POST /cubegroup
 */
export async function postCubeGroup(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/cubegroup", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * キューブ情報取得API.
 * API: POST /cube
 */
export async function postCube(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/cube", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * キューブ既読設定API.
 * API: POST /cubeview
 */
export async function postCubeView(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(ACCOUNTIF + "/cubeview", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * セッションエラーはログアウト.
 */
export const isSessionError = (dispatch, err) => {
    if (apiUtil.isSessionError(err, WAR01003)) doLogout(dispatch);
}

/**
 * レジューム情報取得API成功時のデフォルト処理.
 */
export const getResumeSuccess = (dispatch, data) => {
    const resumeList = data[RESUME_LIST] === null ? [] : data[RESUME_LIST];
    const conpleteList = data[COMPLETE_LIST] === null ? [] : data[COMPLETE_LIST];
    dispatch(memberActions.updateResumeList(resumeList, conpleteList));
}

/**
 * 通知一覧取得API成功時のデフォルト処理.
 */
export const postNoticeListSuccess = (dispatch, data) => {
    dispatch(memberActions.updateNoticeList(data));
}

/**
 * 通知一覧取得API成功時にローカルストレージの既読管理を通知のreadFlgに反映.
 */
export const postNoticeListSuccessReflectReadFlg = (dispatch, memberPlan, noneReadMap, data) => {
    // 通知一覧のreadFlg: falseかつローカルストレージの既読管理がtrueになっている通知は既読APIを呼び出し、readFlg: trueにする.
    const newData = Array.from(data).map((notice) => {
        if (!notice[READ_FLG] && noneReadMap[notice.id]) {
            const noticeRequest = {
                [NOTICE_ID]: notice.id,
                [PLAN_NAME]: memberPlan
            };
            postNotice(noticeRequest).then(() => {}, () => {});
            notice[READ_FLG] = true;
        }
        return notice;
    });
    dispatch(memberActions.updateNoticeList(newData));
}

/**
 * 会員情報取得API成功時のデフォルト処理.
 */
export const getUserInfoSuccess = (dispatch, data) => {
    dispatch(memberActions.updateMemberInfo(data));
}

/**
 * お気に入りコンテンツ取得API成功時のデフォルト処理.
 */
export const getFavoriteSuccess = (dispatch, data) => {
    dispatch(memberActions.updateFavoriteList(data));
}

/**
 * キャンペーン一覧取得API成功時のデフォルト処理.
 */
export const getCampaignSuccess = (dispatch, data) => {
    dispatch(memberActions.updateCampaignList(data));
}

/**
 * 図鑑一覧取得API成功時のデフォルト処理.
 */
export const postCubeGroupSuccess = (dispatch, data) => {
    dispatch(memberActions.updateSpecialCubeGroupList(data));
}