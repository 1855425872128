import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as historys from "../../../historys";
import * as apiUtil from "../../../constants/apiUtil";
import * as noticeKey from "../../../constants/noticeKey";
import * as plan from "../../../constants/plan";
import * as accountif from "../../../middleware/accountif";
import { readNoneNotice } from "../../../common/status/noneNotice/actions";
import styles from "./styles";

/**
 * 通知コンテンツ.
 *
 * props
 *  -> meta    コンテンツのメタ.
 *  -> onClick おしらせを閉じる.
 */
const Notice = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const history = useHistory();
    const dispatch = useDispatch();

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);

    /**
     * 通知既読設定API 失敗.
     */
    const postNoticeFailure = (id, err) => {
        // セッションエラーを確認.
        accountif.isSessionError(dispatch, err);
    }

    /**
     * 通知既読設定API 成功.
     */
    const postNoticeSuccess = (id, data) => {
        // 通知一覧を更新.
        accountif.postNoticeListSuccess(dispatch, data);
    }

    /**
     * コンテンツクリック時.
     */
    const handleClick = () => {
        let crid = props.meta[noticeKey.LINK_CRID];
        if (isUnread()) {
            // ログイン時のみ既読APIを実行.
            if (!plan.isNone(memberPlan)) {
                let request = {};
                request[accountif.NOTICE_ID] = props.meta[noticeKey.ID];
                request[accountif.PLAN_NAME] = memberPlan;
                accountif.postNotice(request).then(
                    postNoticeSuccess.bind(this, crid),
                    postNoticeFailure.bind(this, crid)
                );
            }
            // 非会員の既読管理はローカルストレージで行う.
            dispatch(readNoneNotice(props.meta[noticeKey.ID]));
        }
        // 情報画面に遷移.
        if (crid !== undefined && crid !== "") {
            props.onClick();
            historys.historyContents(history, crid);
        }
    }

    /**
     * 未読判定.
     */
    const isUnread = () => {
        return !noneReadMap[props.meta[noticeKey.ID]] && !props.meta[noticeKey.READ_FLG];
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    return (
        <li
            className={[
                classes.root,
                "c-notice__menu",
                isUnread() ? "c-notice__menu--unread" : ""
            ].join(" ")}
            onClick={() => handleClick()}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="c-notice__box">
                {isUnread() &&
                    <div className="c-notice__tag c-tag c-tag--unread">
                        <div className="c-notice__tagTxt">未読</div>
                    </div>
                }
                <div className="c-notice__icon">
                    <img src={props.meta[noticeKey.IMAGE_URL_AT_LIST]} alt=""/></div>
                <div className="c-notice__detail">
                    <div className="c-notice__ttl c-notice__ttl">{props.meta[noticeKey.MSG_AT_LIST]}</div>
                    <span className="c-notice__address">{props.meta[noticeKey.FROM]}</span>
                </div>
                <div className="c-notice__data"><span>{apiUtil.getDatefromISOSlash(props.meta[noticeKey.APPLY_START_DATE])}</span></div>
            </div>
        </li>
    );
};

export default Notice;
