import * as actionTypes from "./actionTypes";

const initState = {
    isWifi: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_WIFI_STATUS:
            return { ...state, isWifi: action.isWifi};
        default:
            return state;
    }
};

export default reducer;