import * as actionTypes from "./actionTypes";

/**
 * push通知を表示する.
 */
export const openPopupNotice = (noticeInfo) => {
    return {
        type: actionTypes.OPEN_POPUP_NOTICE,
        noticeInfo: noticeInfo
    };
}

/**
 * push通知を閉じる.
 */
export const closePopupNotice = () => {
    return {
        type: actionTypes.CLOSE_POPUP_NOTICE
    };
}
