/** 本番環境識別子. */
const PROD = "prod";

/** ソニーペイメントトークン取得接続先. */
export const SONY_PAYMENT_TOKEN_URL = process.env.REACT_APP_ENV === PROD
    ? "https://www.e-scott.jp/euser/stn/CdGetJavaScript.do?k_TokenNinsyoCode=dCTnXZEAsZYPdbWVYk6mpYXzebs2s2Bp"
    : "https://www.test.e-scott.jp/euser/stn/CdGetJavaScript.do?k_TokenNinsyoCode=dCTnXZEAsZYPdbWVYk6mpYXzebs2s2Bp";

/** TSUBURAYAマイページ. */
export const TSUBURAYA_MYPAGE = process.env.REACT_APP_ENV === PROD
    ? "https://account.m-78.jp/Account/SSO/me?redirecturl="
    : "https://dev.account.m-78.jp/Account/SSO/me?redirecturl=";

/** fairplay証明書. */
export const FAIRPLAY_CERT = process.env.REACT_APP_ENV === PROD
    ? "https://vod-origin.hikaritv.net/fairplay/fairplay.cer"
    : "https://vod-origin.hikaritv.net/fairplay/fairplay.cer";
