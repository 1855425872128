import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import * as screenActions from "../../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import * as historys from "../../../../historys";
import * as app from "../../../../constants/app";
import { PLAN_LP_TITLE } from "../../../../constants/title";
import { setHeadDefault } from "../../../../constants/sns";

/**
 * プランLP画面（アプリ対応）.
 */
const Plan = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // アプリ以外からの遷移は404へ遷移.
        if (!app.isAllApp()) {
            historys.historyNotFound(history);
        }

        document.body.classList.add("is-fixed");
        dispatch(screenActions.appPlan());

        // head設定.
        setHeadDefault(PLAN_LP_TITLE);

        return () => document.body.classList.remove("is-fixed");
    }, [dispatch, history]);

    return (
        <>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="p-popup">
                    <div className="p-popup__box p-popup__box--largeBox p-popup__box--largeBox--app p-popup__box--largeBox--app--tinBlack">
                        <div className="p-popupBox">
                            <div className="c-static">
                                <div className="c-static__appHead">
                                    <div className="c-static__box c-static__box--app">
                                        <div className="c-static__boxTxt">Webサイトのプロフィールから<br/><span className="c-staticUnderTxt">プラン変更</span>ができます。</div>
                                    </div>
                                </div>
                                <div className="c-static__appSect">
                                    <div className="c-static__appSectWrap">
                                        <section className="c-static__appSectItem c-static__appSectItem--rank">
                                            <div className="c-static__appSectNum">
                                                <div className="c-static__appSectNumTxt">1</div>
                                            </div>
                                            <div className="c-static__appSectDtl">TSUBURAYA IMAGINATIONの<br/>Web サイトにアクセスしてください。<br/><span className="c-static__appSectDtlSmall">右上のプロフィールアイコンをクリック</span></div>
                                            <div className="c-static__appSectRank c-static__appSectRank--rankUp"><img src="/assets/images/img-lp-rank-02.jpg" alt="" /></div>
                                        </section>
                                        <section className="c-static__appSectItem c-static__appSectItem--rank">
                                            <div className="c-static__appSectNum">
                                                <div className="c-static__appSectNumTxt">2</div>
                                            </div>
                                            <div className="c-static__appSectDtl">プロフィール画面から<br/><span className="c-staticUnderTxt">プラン変更</span>を行ってください。</div>
                                            <div className="c-static__appSectRank c-static__appSectRank--prof"><img src="/assets/images/img-lp-rank-03.png" alt="" /></div>
                                        </section>
                                    </div>
                                </div>
                                <div className="c-static__appStart">
                                    <div className="c-static__appStartItem"><img src="/assets/images/img-lp-start-01.png" alt="" /></div>
                                    <div className="c-static__appStartTxt">IMAGINATION（イマジネーション）の宇宙で<br/>お待ちしています！</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Plan;