/** トップ画面に遷移. */
export const TOP = "TOP";

/** 検索一覧画面に遷移. */
export const SEARCH = "SEARCH";

/** プラネット画面に遷移. */
export const PLANET = "PLANET";

/** プラネット別一覧画面に遷移. */
export const PLANET_ID = "PLANET_ID";

/** ライブラリ一覧画面に遷移. */
export const LIBRARY_ID = "LIBRARY_ID";

/** イマジネーションとは画面に遷移. */
export const IMAGINATION = "IMAGINATION";

/** 詳細画面（動画）に遷移. */
export const VIDEO_ID = "VIDEO_ID";

/** 詳細画面（Live）に遷移. */
export const LIVE_ID = "LIVE_ID";

/** 静的コンテンツページに遷移. */
export const CONTENTS_ID = "CONTENTS_ID";

/** 会員規約画面に遷移. */
export const TREMS = "TREMS";

/** プライバシーポリシー画面に遷移. */
export const PRIVACY = "PRIVACY";

/** 推奨環境画面に遷移. */
export const ENVIRONMENT = "ENVIRONMENT";

/** 特定商法取引法画面に遷移. */
export const TRANSACTION = "TRANSACTION";

/** サービス紹介画面に遷移. */
export const LOGGED = "LOGGED";

/** 操作ガイド画面に遷移. */
export const OPERATION = "OPERATION";

/** FAQ/問い合わせ画面に遷移. */
export const FAQ = "FAQ";

/** ログインLPに遷移（アプリ対応）. */
export const APP_LOGIN = "APP_LOGIN";

/** プランLPに遷移（アプリ対応）. */
export const APP_PLAN = "APP_PLAN";

/** TVODLPに遷移（アプリ対応）. */
export const APP_TVOD = "APP_TVOD";

/** 3分岐画面に遷移（アプリ対応）. */
export const APP_BRANCH = "APP_BRANCH";

/** プライバシーポリシー画面（アプリ対応）. */
export const APP_PRIVACY = "APP_PRIVACY";

/** データキューブの楽しみ方画面. */
export const HOW_TO_CUBE = "HOW_TO_CUBE";

/** メニューの表示/非表示. */
export const CHANGE_IS_MENU = "CHANGE_IS_MENU";

/** 通知の表示/非表示. */
export const CHANGE_IS_NOTICE = "CHANGE_IS_NOTICE";

/** プロフィールの表示/非表示. */
export const CHANGE_IS_PROF = "CHANGE_IS_PROFILE";

/** デジタル会員証の表示/非表示. */
export const CHANGE_IS_DIGITAL_CARD = "CHANGE_IS_DIGITAL_CARD";

/** スペシャル図鑑一覧の表示/非表示. */
export const CHANGE_IS_SPECIAL_CUBE_GROUP_LIST = "CHANGE_IS_SPECIAL_CUBE_GROUP_LIST";

/** スペシャル図鑑の表示/非表示. */
export const CHANGE_IS_SPECIAL_CUBE_GROUP = "CHANGE_IS_SPECIAL_CUBE_GROUP";

/** データキューブ詳細の表示/非表示. */
export const CHANGE_IS_DATA_CUBE = "CHANGE_IS_DATA_CUBE";

/** 追加特典取得確認の表示/非表示. */
export const CHANGE_IS_GIFT_CONFIRM = "CHANGE_IS_GIFT_CONFIRM";

/** キャンペーン一覧の表示/非表示. */
export const CHANGE_IS_CAMPAIGN_LIST = "CHANGE_IS_CAMPAIGN_LIST";

/** 退会確認画面の表示/非表示. */
export const CHANGE_IS_WITHDRAWAL= "CHANGE_IS_WITHDRAWAL";
