import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Parallax from "react-rellax";
import * as plan from "../../../constants/plan";
import * as apiUtil from "../../../constants/apiUtil";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import styles from "./styles";
import { LOGGED_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * サービス紹介画面.
 */
const Logged = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();

    /** 会員プランの金額. */
    const planPrice = useSelector(state => state.Member.planPrice);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.logged());

        // head設定.
        setHeadDefault(LOGGED_TITLE);
    }, [dispatch]);

    /**
     * ログイン.
     */
    const handleLogin = () => {
        apiUtil.tsuburayaWebLogin(dispatch, location);
    }

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg l-planetBg--logged rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="p-logged">
                    <div className="p-logged__hero"><span className="p-logged__heroSmallTxt">ヒーロー＆怪獣たちに、もっと会える。</span><br/>きたぞ！<br className="p-logged__noLine" />我らの“ウルトラ”サブスク！！</div>
                    <div className="p-logged__mainVis">
                        <div className="p-logged__logo"><img src="./assets/images/logo-brand-imagination.png" alt="" /></div>
                        <div className="p-logged__logoBg"></div>
                    </div>
                    <div className="p-logged__read">作品見放題・オリジナル新作・オンラインイベント<br/>限定商品販売・チケット先行販売 and MORE！<br/>イマジネイティブな毎日をあなたに。</div>
                    <div className="p-loggedPlan">
                        <div className="p-loggedPlan__bg">
                            <div className="p-loggedPlan__txt"><span>あなたにぴったりのプラン</span>で<br className="p-logged__noLine" />“ウルトラ”サブスクを楽しもう。</div>
                        </div>
                        <div className="p-loggedPlan__dtl p-loggedPlan__dtl--red">
                            <div className="p-loggedPlan__card"><img src="./assets/images/img-loggedcard-standard.png" alt="" /></div>
                            <div className="p-loggedPlan__band p-loggedPlan__band--red">
                                <div className="p-loggedPlan__bandHead">
                                    <div className="p-loggedPlan__bandHeadTtl">スタンダード</div>
                                </div>
                                <div className="p-loggedPlan__bandDtl">
                                    <div className="p-loggedPlan__bandDtlTxt">
                                        <div className="p-loggedPlan__bandDtlFee">月額<span className="p-loggedPlan__bandDtlFeeSmallTxt">{Number(planPrice[plan.STANDARD_WITHOUT_TAX]) + "円"}</span>（税抜）</div>
                                        <div className="p-loggedPlan__bandDtlTax">{Number(planPrice[plan.STANDARD]) + "円（税込）"}</div>
                                    </div>
                                </div>
                            </div>
                            <section className="p-loggedPlanSect">
                                <div className="p-loggedPlanSect__item">
                                    <div className="p-loggedPlanSect__num">
                                        <div className="p-loggedPlanSect__numTxt">1</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl">ウルトラマンシリーズ<br/><span className="p-loggedPlanSect__redTxt p-loggedPlanSect__lgTxt">約1,400エピソード見放題！！</span></div>
                                    <div className="p-loggedPlanSect__dtl">日本特撮テレビシリーズに金字塔を打ち立てた『ウルトラマン』をはじめ、<br className="p-logged__line" />最新作TV放送作品までウルトラマンシリーズが見放題で楽しめる。<br/><span className="p-loggedPlanSect__dtlSmallTxt">※一部の作品は見放題の対象外となります。</span></div>
                                    <ul className="p-loggedPlanSect__img">
                                        <li className="p-loggedPlanSect__imgItem"><img src="./assets/images/img-logged-001.jpg" alt="" /></li>
                                        <li className="p-loggedPlanSect__imgItem"><img src="./assets/images/img-logged-002.jpg" alt="" /></li>
                                        <li className="p-loggedPlanSect__imgItem"><img src="./assets/images/img-logged-003.jpg" alt="" /></li>
                                    </ul>
                                </div>
                                <div className="p-loggedPlanSect__item">
                                    <div className="p-loggedPlanSect__num">
                                        <div className="p-loggedPlanSect__numTxt">2</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl">TSUBURAYA IMAGINATION<br/><span className="p-loggedPlanSect__redTxt p-loggedPlanSect__lgTxt">オリジナルコンテンツも続々！</span></div>
                                    <div className="p-loggedPlanSect__dtl">TSUBURAYA IMAGINATIONでしか見られないオリジナルコンテンツが登場。<br className="p-logged__line" />映像、音声、テキストなど様々な角度から円谷作品を楽しめる！</div>
                                </div>
                                <div className="p-loggedPlanSect__item">
                                    <div className="p-loggedPlanSect__num">
                                        <div className="p-loggedPlanSect__numTxt">3</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl">チケット先行販売や限定グッズ購入など、<br/><span className="p-loggedPlanSect__redTxt p-loggedPlanSect__lgTxt">イベント・ストア特典充実！</span></div>
                                    <div className="p-loggedPlanSect__dtl">スタンダードプランに登録すれば、イベントやグッズでも毎日がウルトラ楽しくなる！<br className="p-logged__line" />イベントチケット先行や会員限定グッズの販売などの特典満載。</div>
                                </div>
                            </section>
                        </div>
                        <div className="p-loggedPlan__bg"></div>
                        <div className="p-loggedPlan__dtl p-loggedPlan__dtl--gold">
                            <div className="p-loggedPlan__card"><img src="./assets/images/img-loggedcard-premium.png" alt="" /></div>
                            <div className="p-loggedPlan__band p-loggedPlan__band--gold">
                                <div className="p-loggedPlan__bandHead">
                                    <div className="p-loggedPlan__bandHeadTtl">プレミアム</div>
                                </div>
                                <div className="p-loggedPlan__bandDtl">
                                    <div className="p-loggedPlan__bandDtlTxt">
                                        <div className="p-loggedPlan__bandDtlFee">年額<span className="p-loggedPlan__bandDtlFeeSmallTxt">{Number(planPrice[plan.PREMIUM_WITHOUT_TAX]).toLocaleString() + "円"}</span>（税抜）</div>
                                        <div className="p-loggedPlan__bandDtlTax">{Number(planPrice[plan.PREMIUM]).toLocaleString() + "円（税込）"}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-loggedPlan__bandRead"><span className="p-loggedPlan__bandReadSmallTxt">スタンダートのサービスに加えて、</span><br/>さらに広がる“ウルトラ”な体験！</div>
                            <section className="p-loggedPlanSect">
                                <div className="p-loggedPlanSect__item">
                                    <div className="p-loggedPlanSect__num p-loggedPlanSect__num--gold">
                                        <div className="p-loggedPlanSect__numTxt">1</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl p-loggedPlanSect__ttl--gold">ウルトラマンシリーズに加え、<br className="p-logged__noLine" />円谷作品も含む<span className="p-loggedPlanSect__goldTxt p-loggedPlanSect__lgTxt">約1,900エピソード</span>が<br/><span className="p-loggedPlanSect__goldTxt p-loggedPlanSect__xlTxt">“ウルトラ”見放題！！</span></div>
                                    <div className="p-loggedPlanSect__dtl">円谷プロが制作したヒーローが登場しないホラー作品<br className="p-logged__line" />『怪奇大作戦』やウルトラマンではないヒーロー<br className="p-logged__line" />『マイティジャック』『ミラーマン』『ジャンボーグA』『ファイヤーマン』などの作品が<br className="p-logged__line" />プレミアムなら見放題！</div>
                                    <div className="p-loggedPlanSect__gallery">
                                        <ul className="p-loggedPlanSect__img p-loggedPlanSect__img--premium">
                                            <li className="p-loggedPlanSect__imgItem p-loggedPlanSect__imgItem--premium"><img src="./assets/images/img-logged-004.jpg" alt="" /></li>
                                            <li className="p-loggedPlanSect__imgItem p-loggedPlanSect__imgItem--premium"><img src="./assets/images/img-logged-005.jpg" alt="" /></li>
                                            <li className="p-loggedPlanSect__imgItem p-loggedPlanSect__imgItem--premium"><img src="./assets/images/img-logged-006.jpg" alt="" /></li>
                                            <li className="p-loggedPlanSect__imgItem p-loggedPlanSect__imgItem--premium"><img src="./assets/images/img-logged-007.jpg" alt="" /></li>
                                            <li className="p-loggedPlanSect__imgItem p-loggedPlanSect__imgItem--premium"><img src="./assets/images/img-logged-008.jpg" alt="" /></li>
                                        </ul>
                                        <div className="p-loggedPlanSect__imgBg"><img src="./assets/images/img-logged-premiumBg.jpg" alt="" /></div>
                                    </div>
                                </div>
                                <div className="p-loggedPlanSect__item">
                                    <div className="p-loggedPlanSect__num p-loggedPlanSect__num--gold">
                                        <div className="p-loggedPlanSect__numTxt">2</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl p-loggedPlanSect__ttl--gold"><span className="p-loggedPlanSect__goldTxt p-loggedPlanSect__lgTxt">オンラインライブイベントが見放題！</span></div>
                                    <div className="p-loggedPlanSect__dtl">毎月1回開催のオンラインライブイベントにプレミアムなら見放題で楽しめます。<br className="p-logged__line" />プレミアムでしか体験できないオンラインライブ企画も続々登場！</div>
                                    <div className="p-loggedPlanSect__tag">
                                        <div className="p-loggedPlanSect__tagItem p-loggedPlanSect__tagItem--live">
                                            <div className="p-loggedPlanSect__tagTxt">LIVE</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-loggedPlanSect__item p-loggedPlanSect__item--pcColumn">
                                    <div className="p-loggedPlanSect__num p-loggedPlanSect__num--gold">
                                        <div className="p-loggedPlanSect__numTxt">3</div>
                                    </div>
                                    <div className="p-loggedPlanSect__ttl p-loggedPlanSect__ttl--gold">リアルでもオンラインでも<br/><span className="p-loggedPlanSect__goldTxt p-loggedPlanSect__lgTxt">“ウルトラ”プレミアムな特典！</span></div>
                                    <div className="p-loggedPlanSect__dtl">イベントチケットの最速先行販売、プレミアム限定のグッズ販売や<br className="p-logged__line" />オンラインライブイベント内でプレミアム限定に公開される特別映像など、<br className="p-logged__line" />リアルでもオンラインでも誰よりも“ウルトラ”な時間をお過ごし頂けます。</div>
                                    <div className="p-loggedPlanSect__tag">
                                        <div className="p-loggedPlanSect__icon">
                                            <div className="p-loggedPlanSect__iconItem c-picIconPlay"></div>
                                        </div>
                                        <div className="p-loggedPlanSect__icon">
                                            <div className="p-loggedPlanSect__iconItem c-picIconCart"></div>
                                        </div>
                                        <div className="p-loggedPlanSect__tagItem p-loggedPlanSect__tagItem--ticket">
                                            <div className="p-loggedPlanSect__tagTxt">TICKET</div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <div className="p-loggedRegistration"><span className={classes.clickable + " c-btn c-btn--registration"} onClick={() => handleLogin()} target="_blank">
                        <div className="p-loggedRegistration__txt">ご登録はこちらから！<br/><span className="p-loggedRegistration__smallTxt">無料プランも実施中！</span></div></span>
                        <div className="p-loggedRegistration__supplement">ご登録にはTSUBURAYA アカウントとdアカウントが必要です。</div>
                    </div>
                    <div className="p-loggedPlanList">
                        <div className="p-loggedPlanList__txt">プランについて</div>
                    </div>
                    <div className="p-popupPlanList p-popupPlanList--logged">
                        <ul className="p-popupPlanList__name p-popupPlanList__name--logged">
                            <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--free">無料</li>
                            <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--standard">スタンダード</li>
                            <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--premium">プレミアム</li>
                        </ul>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <div className="p-popupPlanList__line"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged"><span className="p-popupPlanList__middleTxt p-popupPlanList__middleTxt--logged">{"月" + Number(planPrice[plan.STANDARD_WITHOUT_TAX])}</span><span className="p-popupPlanList__smallTxt p-popupPlanList__smallTxt--logged">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.STANDARD]) + "円（税込）"}</span></p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged"><span className="p-popupPlanList__middleTxt p-popupPlanList__middleTxt--logged">{"年" + Number(planPrice[plan.PREMIUM_WITHOUT_TAX]).toLocaleString()}</span><span className="p-popupPlanList__smallTxt p-popupPlanList__smallTxt--logged">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.PREMIUM]).toLocaleString() + "円（税込）"}</span></p>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl">
                            <div className="p-popupPlanListTtl__icon c-icon c-icon--play c-picIconPlay"></div>
                            <div className="p-popupPlanListTtl__txt">映像</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__round p-popupPlanList__round--mb"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">ウルトラマン<br/>シリーズや<br/>オリジナル新作など<br/>約<span className="p-popupPlanList__largeTxt p-popupPlanList__largeTxt--logged">1,400</span><br/>エピソードが見放題！</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <div className="p-popupPlanList__round p-popupPlanList__round--double p-popupPlanList__round--mb"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">スタンダードプラン<br/>に加え、<br/>円谷作品、他<br/>約<span className="p-popupPlanList__largeTxt p-popupPlanList__largeTxt--logged">1,900</span><br/>エピソードが見放題！</p>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl">
                            <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                <div className="c-icon__Txt">LIVE</div>
                            </div>
                            <div className="p-popupPlanListTtl__txt">オンラインイベント</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <div className="p-popupPlanList__line"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> アーカイブ配信は個別課金で視聴可能（※）</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__round"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">一部のオンライン<br/>イベントに参加可能！</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">プレミアム限定の<br/>オンライン企画も！</p>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl">
                            <div className="p-popupPlanListTtl__icon c-icon c-icon--article c-picIconarticle"></div>
                            <div className="p-popupPlanListTtl__txt">読み物</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free p-popupPlanList__dtlItem">
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__round"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">小説、コミックス、<br/>コラム、解説等、<br/>一部コンテンツを除き、見放題</p>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> すべて見放題</p>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl p-popupPlanListTtl--bigSize">
                            <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                <div className="c-icon__Txt">TICKET</div>
                            </div>
                            <div className="p-popupPlanListTtl__txt">イベントチケット先行受付<br/>（リアル／オンライン）</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <div className="p-popupPlanList__line"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__round"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                            <div class="p-popupPlanList__round p-popupPlanList__round--double"></div>
                            <p class="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> 最速先行受付</p>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl">
                            <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                            <div className="p-popupPlanListTtl__txt">会員限定グッズ</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <div className="p-popupPlanList__line"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__round"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <div className="p-popupPlanList__round"></div>
                            </li>
                        </ul>
                        <div className="p-popupPlanListTtl">
                            <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                            <div className="p-popupPlanListTtl__txt">プレミアム限定グッズ販売</div>
                        </div>
                        <ul className="p-popupPlanList__dtl">
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                <div className="p-popupPlanList__line"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                <div className="p-popupPlanList__line"></div>
                            </li>
                            <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                <div className="p-popupPlanList__round"></div>
                                <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">プレミアムセールへの優先参加・<br/>ノベルティの付与なども！</p>
                            </li>
                        </ul>
                    </div>
                    <div className="p-loggedRegistration p-loggedRegistration--planInner"><span className={classes.clickable + " c-btn c-btn--registration"} onClick={() => handleLogin()} target="_blank">
                        <div className="p-loggedRegistration__txt">ご登録はこちらから！<br/><span className="p-loggedRegistration__smallTxt">無料プランも実施中！</span></div></span>
                        <div className="p-loggedRegistration__supplement">ご登録にはTSUBURAYA アカウントとdアカウントが必要です。</div>
                    </div>
                    <div className="p-loggedCaution">※画像はイメージです。サービス内容等は変更となることがございます。<br/>※サービスの内容によっては、ご利用に際して月額利用料とは別に料金が発生する場合があります。<br/>※一部のサービスは個別課金でもお楽しみいただけます。<br/>※一部配信されない作品もございます。</div>
                </div>
            </div>
        </div>
    );
}

export default Logged;
