import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * 画面トップに移動.
 */
const ScrollToTopOnMount = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTopOnMount;
