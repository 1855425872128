/**
 * 汎用設定ファイル.
 */
import { decode } from "js-base64";

/** 退会確認コンテンツのCrid. */
export const WITHDRAWAL_LP_CRID = "withdrawalLpCrid";

/** 退会アンケートコンテンツのCrid. */
export const WITHDRAWAL_REASON_CRID = "withdrawalReasonCrid";

/** Twitterハッシュタグ. */
export const TWITTER_HASH_TAGS = "twitterHashTags";

/**
 * 退会アンケートコンテンツか判定.
 */
export const isWithdrawalReasonContents = (location, appConfigWeb) => {
    const splitPathname = location.pathname.split("/");
    const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
    if (contentsId === appConfigWeb[WITHDRAWAL_REASON_CRID]) return true;
    return false;
}