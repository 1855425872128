import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer"
    },
    unClickable: {
        cursor: "default"
    },
    searchIcon: {
        paddingTop: 6
    },
    noticeBadge: {
        color: "#FFFFFF",
        backgroundColor: "#00B1FF",
        fontSize: 12
    },
    meterLine: props => ({
        background: "linear-gradient(180deg, #de0000, #5c0000)",
        width: props.meterLineWidth,
        height: 20,
        borderRadius: 10,
        position: "relative"
    }),
    dataCubeLink: {
        color: "#FF3636",
        textDecoration: "underline"
    }
}));

export default styles;
