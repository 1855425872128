import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";

import * as plan from "../../constants/plan";
import * as credit from "../../constants/credit";
import * as cv from "../../constants/cv";
import { openPopupCreditResult, saveCardlistId } from "../../common/popup/credit/actions";
import { withdrawalFailure } from "../../common/status/actions";
import { X_TOKEN, TI_SID, ERROR_CODE } from "../../constants/apiUtil";
import * as commonActions from "../../common/status/actions";
import * as authIf from "../../middleware/authif";
import { logoutFailure } from "../../common/status/actions";
import { updateInfo } from "../../common/popup/credit/creditUtils";
import * as contractif from "../../middleware/contractif";
import * as payment from "../../middleware/payment";
import Progress from "./Progress";
import * as historys from "../../historys";

/**
 * 退会確認ダイアログ.
 *
 * props
 *  -> onClose ダイアログの非表示.
 */
const Withdrawal = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** ローディング状態. */
    const [isLoading, setIsLoading] = useState(false);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** キャンペーン中判定. */
    const isCampaign = useSelector(state => state.Member.isCampaign);
    /** クレジットカードマスタの管理ID */
    const cardlistId = useSelector(state => state.Credit.cardlistId);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        return () => {
            document.body.classList.remove("is-fixed");
        }
    }, []);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 退会処理.
     */
    const doWithdrawal = (cardlistIdNow) => {
        const body = {
            [contractif.CONTRACT_TYPE]: contractif.LEAVE,
            [contractif.BEFORE_PLAN]: memberPlan,
            [contractif.CARDLIST_ID]: cardlistIdNow
        };

        contractif.postContractManage(body)
        .then((res) => {
            // 契約管理API 成功.

            // 非会員以外の退会の場合、CVを送信.
            if (!plan.isNone(memberPlan)) {
                cv.sendPlanCV(cv.UNSUBSCRIBE, memberPlan);
            }

            // 無料プラン or 移行キャンペーン中の退会は即時.
            if (memberPlan === plan.FREE || isCampaign) {
                // 退会を行う.
                withdrawal(dispatch, history);

                // 完了画面表示.
                dispatch(openPopupCreditResult(credit.WITHDRAWAL, "", 0));
            }
            // 有料プランの退会は予約.
            else {
                // 会員情報更新.
                updateInfo(dispatch, res);

                // 完了画面表示.
                dispatch(openPopupCreditResult(credit.RESERVE_WITHDRAWAL, "", 0));
            }
        })
        .catch((err) => {
            // 契約管理API 失敗
            const errCode = err[ERROR_CODE] ? err[ERROR_CODE].slice(-3) : "";
            if (errCode === contractif.OVERSEAS_ERROR_CODE) {
                // 海外エラー
                dispatch(commonActions.overseasError(true));
            } else {
                // 退会失敗
                dispatch(withdrawalFailure(true));
            }
        })
        .finally(() => {
            // 後処理.
            props.onClose();
        });
    };

    /**
     * 「はい」押下時.
     */
    const onAccept = () => {
        setIsLoading(true);

        // クレジットカードマスタの管理IDがstoreにある場合.
        if (cardlistId && cardlistId !== "") {
            doWithdrawal(cardlistId);
        }
        // クレジットカードマスタの管理IDがstoreにない場合.
        else {
            payment.postPayment(`Command=${payment.GET_CARD_USR_INFO}`)
            .then((res) => {
                // クレカ連携API 成功.

                // セッションエラー確認.
                if (payment.isSessionError(dispatch, res)) {
                    return;
                }

                // "0" 以外は取得失敗なのでエラー.
                if (res.OutputCardUserInfoList.Result[0] !== "0") {
                    postPaymentFailure();
                } else {
                    const cardUserInfo = res.OutputCardUserInfoList.CardUserInfo[0];
                    dispatch(saveCardlistId(cardUserInfo.CardlistId[0]));

                    // 退会処理を行う.
                    doWithdrawal(cardUserInfo.CardlistId[0]);
                }
            })
            .catch(() => {
                // クレカ連携API 失敗.
                postPaymentFailure();
            });
        }
    }

    /**
     * クレカ連携API失敗時の処理.
     */
    const postPaymentFailure = () => {
        dispatch(withdrawalFailure(true));
        props.onClose();
    };

    /**
     * 「いいえ」押下時.
     */
    const onReject = () => {
        props.onClose();
    }

    /**
     * ダイアログ説明テキスト.
     */
    const dialogContent = () => {
        if (isCampaign) {
            return "退会すると無料期間が終了となります。退会しますか？";
        }
        else {
            return "退会しますか？";
        }
    }

    return (
        <>
            {isLoading && <Progress />}
            <div
                onContextMenu={(event) => handleContextMenu(event)}
            >
                <div className="p-popup p-popup--dialog">
                    <div className="p-popup__box">
                        <div className="p-popup__wrap p-popup__wrap--dialog">
                            <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{"退会確認"}</div>
                                <div className="p-popup__txt">{dialogContent()}</div>
                                <div className="p-popup__btnWrap">
                                    <div className="p-popup__btnItem c-btn c-btn--gray" onClick={() => onReject()}>{"キャンセル"}</div>
                                    <div className="p-popup__btnItem c-btn c-btn--thinRed" onClick={() => onAccept()}>{"退会する"}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-popup__bg"></div>
                </div>
            </div>
        </>
    );
};

export const withdrawal = (dispatch, history) => {
    authIf.postLogout({})
    .then(() => {
    })
    .catch(() => {
        dispatch(logoutFailure(true));
    });

    // reducerリセット.
    dispatch(commonActions.withdrawal());

    // sessionStorageを削除.
    sessionStorage.clear();

    // cookieを削除.
    document.cookie = X_TOKEN + "=; domain=.imagination.m-78.jp; path=/; max-age=0";
    document.cookie = TI_SID + "=; domain=.imagination.m-78.jp; path=/; max-age=0";

    // TOP画面を表示
    historys.historyTop(history);
}

export default Withdrawal;
