import * as actionTypes from "./actionTypes";

/**
 * 決済情報保存.
 */
export const saveCreditInfo = (data) => {
    return {
        type: actionTypes.SAVE_CREDIT_INFO,
        plan: data.plan,
        amount: data.amount,
        kind: data.kind
    };
}

/**
 * 無料キャンペーン情報保存.
 */
export const saveCampaignInfo = (campaignName, freePeriod) => {
    return {
        type: actionTypes.SAVE_CAMPAIGN_INFO,
        campaignName: campaignName,
        freePeriod: freePeriod
    };
}