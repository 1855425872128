import * as actionTypes from "./actionTypes";

const initState = {
    /** 静的コンテンツ. */
    textHtml: "",
    /** コンテンツ情報. */
    content: {
        crid: "",
        planList: [],
        isFree: false,
        planetId: []
    },
    /** シリーズタイトル. */
    seriesTitle: "",
    /** シリーズ一覧. */
    contentsList: [],
    /** クーポン利用状況チェック. */
    couponCheck: {
        used: "",
        validityPeriod: ""
    },
    /** レコメンドコンテンツ一覧. */
    recommendContentsList: [],
    /** レコメンドクリック情報. */
    clickData: {
        rqid: "",
        spec: ""
    }
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 静的コンテンツ取得成功. */
        case actionTypes.GET_CONTENTS_SUCCESS:
            return { ...state, textHtml: action.res };

        /** コンテンツ情報更新. */
        case actionTypes.CONTENTS_UPDATE_CONTENTS:
            return { ...state, content: action.content };

        /** シリーズタイトル更新. */
        case actionTypes.CONTENTS_UPDATE_SERIES_TITLE:
            return { ...state, seriesTitle: action.seriesTitle };

        /** シリーズ一覧更新. */
        case actionTypes.CONTENTS_UPDATE_CONTENTS_LIST:
            return { ...state, contentsList: action.contentsList };

        case actionTypes.CONTENTS_SET_COUPON_CHECK:
            return { ...state, couponCheck: action.couponCheck };

        /** レコメンドコンテンツ一覧更新. */
        case actionTypes.CONTENTS_UPDATE_RECOMMEND_CONTENTS_LIST:
            return { ...state, recommendContentsList: action.contentsList, clickData: action.clickData };

        /** コンテンツリセット. */
        case actionTypes.RESET_CONTENT:
            return { ...state, textHtml: action.res, seriesTitle: "", contentsList: [], couponCheck: initState.couponCheck };
        default:
            return state;
    }
};

export default reducer;
