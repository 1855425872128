import React, { useEffect, useState, useRef } from "react"

import IconButton from "@material-ui/core/IconButton";
import Slider from "@material-ui/core/Slider";

import * as Param from "./param";
import Touch from "./Touch";
import * as touchTypes from "./touchTypes";
import * as app from "../../constants/app";

import { styles } from "./styles";

/**
 * コミックビューワ.
 *
 * props
 *  -> input:images    コミック画像.
 *  -> input:type      コンテンツタイプ.
 */
const Viewer = (props) => {
    /** CSS. */
    const classes = styles(
        (props.input.type === Param.CONTENTS_TYPE_AMECOMI) ? {rail: "#2F2F2F", track: "#B9B9B9"} : {rail: "#B9B9B9", track: "#2F2F2F"}
    );

    /** ビューワー DOM. */
    const viewerRef = useRef(null);
    const zoomPanWindowRef = useRef(null);
    const zoomPanRef = useRef(null);

    /** コントローラー DOM. */
    const controllerRef = useRef(null);
    /** コントローラー非表示(無操作時)までのタイマー. */
    const controllerTimerRef = useRef(null);

    /** スライダー DOM. */
    const sliderRef = useRef(null);
    /** スライダー非表示(無操作時)までのタイマー. */
    const sliderTimerRef = useRef(null);

    /** 表示ページ. */
    const [isPaging, setIsPaging] = useState(1);
    const currentPageRef = useRef();
    currentPageRef.current = isPaging;

    /** 全画面表示状態. */
    const [isFullScreen, setIsFullScreen] = useState(false);
    const fullScreenRef = useRef();
    fullScreenRef.current = isFullScreen;

    /** ページ数最小値. */
    const pageMin = 1;
    /** ページ数最大値. */
    const pageMax = props.input.images.length;

    /** 画像拡縮 初期幅. */
    const baseWidth = useRef(0);
    /** 画像拡縮 初期高さ. */
    const baseHeight = useRef(0);
    /** 画像拡大率 初期値. */
    const initZoom = 1.0;
    /** 画像拡大率 最大値. */
    const maxZoom = 2.0;
    /** 画像拡大率. */
    const zoomRef = useRef(initZoom);
    /** スライダー高さ(px). */
    const sliderHeight = 62;
    /** iOS全画面 位置補正値(px) */
    const adjustHeight = 15;

    /**
     * 左ページ送り.
     */
    const leftPageSend = () => {
        let send;
        if (props.input.type === Param.CONTENTS_TYPE_AMECOMI) {
            send = (currentPageRef.current - 1 < pageMin) ? pageMin : currentPageRef.current - 1;
        } else {
            send = (currentPageRef.current + 1 > pageMax) ? pageMax : currentPageRef.current + 1;
        }
        setIsPaging(send);
    }

    /**
     * 右ページ送り.
     */
    const rightPageSend = () => {
        let send;
        if (props.input.type === Param.CONTENTS_TYPE_AMECOMI) {
            send = (currentPageRef.current + 1 > pageMax) ? pageMax : currentPageRef.current + 1;
        } else {
            send = (currentPageRef.current - 1 < pageMin) ? pageMin : currentPageRef.current - 1;
        }
        setIsPaging(send);
    }

    /**
     * スライドバー ページ遷移.
     */
    const handlePage = (event, newValue) => {
        // 値の絶対値をページとして扱う.
        if (isPaging !== Math.abs(newValue)) {
            setIsPaging(Math.abs(newValue));
        }
    };

    /**
     * フルスクリーン表示切替.
     */
    const handleFullScreen = () => {
        if (!viewerRef || !viewerRef.current) return;
        if (isFullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if(document.webkitExitFullscreen){
                document.webkitExitFullscreen();
            } else {
                viewerRef.current.classList.remove("fullScreen");
                viewerRef.current.style.width = "";
                viewerRef.current.style.height = "";
                viewerRef.current.style.transform = "";
                setIsFullScreen(false);
                // iOSにフルスクリーン解除を通知（アプリ対応）.
                if (app.isIosApp()) window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_VISIBLE;
                initViewer();
            }
        } else {
            if(viewerRef.current.webkitRequestFullScreen){
                viewerRef.current.webkitRequestFullScreen();
            } else {
                viewerRef.current.classList.add("fullScreen");
                setIsFullScreen(true);
                // iOSにフルスクリーンを通知（アプリ対応）.
                if (app.isIosApp()) window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_HIDDEN;
            }
        }
    };

    /**
     * フルスクリーン表示判定.
     */
    const isFullScreenFlg = () => {
        if (
            // Firefox.
            (document.FullscreenElement !== undefined && document.FullscreenElement !== null) ||
            // Chrome・Safari など.
            (document.webkitFullscreenElement !== undefined && document.webkitFullscreenElement !== null) ||
            // IE.
            (document.msFullscreenElement !== undefined && document.msFullscreenElement !== null)
        ) {
            // FullscreenElementに何か入ってる = フルスクリーン中.
            return true;
        } else {
            // フルスクリーンではない or フルスクリーン非対応の環境.
            return false;
        }
    }

    /**
     *  コントローラー 表示.
     */
    const fadeinController = () => {
        if (controllerTimerRef.current != null) {
            clearTimeout(controllerTimerRef.current);
        }
        controllerRef.current.classList.add("active");
    };

    /**
     *  コントローラー 非表示.
     */
    const fadeoutController = () => {
        if (controllerTimerRef.current != null) {
            clearTimeout(controllerTimerRef.current);
        }
        controllerTimerRef.current = setTimeout(() => {
            if (controllerRef.current) {
                controllerRef.current.classList.remove("active");
                controllerTimerRef.current = null;
            }
        }, 5000);
    }

    /**
     *  表示画像状態初期化.
     *  ページ遷移,全画面切り替え,画面リサイズ等.
     */
    const initViewer = () => {
        zoomPanRef.current.style.width = "auto";
        zoomPanRef.current.style.height = "auto";
        zoomPanRef.current.style.maxWidth = "100%";
        zoomPanRef.current.style.maxHeight = "100%";
        zoomPanWindowRef.current.style.margin = "0px";

        var origin = zoomPanRef.current.getBoundingClientRect();
        if (fullScreenRef.current && (origin.height > (window.innerHeight - sliderHeight))) {
            zoomPanRef.current.style.height = (window.innerHeight - sliderHeight) + "px";
            origin = zoomPanRef.current.getBoundingClientRect();
            zoomPanWindowRef.current.style.margin = "0px 0px " + sliderHeight / 2 + "px 0px";
        }
        baseWidth.current = origin.width;
        baseHeight.current = origin.height;
        zoomPanWindowRef.current.style.maxHeight = origin.height + "px";
        zoomPanRef.current.style.width = origin.width + "px";
        zoomPanRef.current.style.height = origin.height + "px";
        zoomPanRef.current.style.maxWidth = "none";
        zoomPanRef.current.style.maxHeight = "none";

        zoomRef.current = initZoom;
        zoomPanRef.current.style.transform = "translate3d(0px, 0px, 0px)";
        zoomPanRef.current.style.webkitTransform = "translate3d(0px, 0px, 0px)";
    }

    /**
     *  現在の変換座標を取得.
     */
    const getTransMatrix = () => {
        var matrix = zoomPanRef.current.style.transform;
        if (matrix !== "none") {
            matrix = matrix.substring(7,matrix.length - 1);
            var matrixList = matrix.split(",");
            var nowTransX = matrixList[0].substr(5);
            nowTransX = nowTransX.replace( /px/g , "" );
            var nowTransY = matrixList[1].trim();
            nowTransY = nowTransY.replace( /px/g , "" );
            return {x: nowTransX, y: nowTransY}
        } else {
            return null;
        }
    }

    /**
     *  タッチ操作時の処理.
     */
    const touchAction = (props) => {
        var matrix;
        var dx;
        var dy;
        switch(props.action) {
            case touchTypes.TOUCH_ACTION_SEND_LEFT:
                leftPageSend();
                break;
            case touchTypes.TOUCH_ACTION_SEND_RIGHT:
                rightPageSend();
                break;
            case touchTypes.TOUCH_ACTION_PINT_IN:
            case touchTypes.TOUCH_ACTION_PINT_OUT:
                var params = props.params;
                matrix = getTransMatrix();
                if (matrix) {
                    zoomRef.current += params.zoom;
                    if (zoomRef.current < initZoom) zoomRef.current = initZoom;
                    if (zoomRef.current > maxZoom) zoomRef.current = maxZoom;

                    var newWidth = baseWidth.current * zoomRef.current;
                    var newHeight = baseHeight.current * zoomRef.current;

                    let zoomPan = zoomPanRef.current.getBoundingClientRect();
                    dx = newWidth * ((-parseInt(matrix.x) + params.originX) / zoomPan.width) - params.originX;
                    dy = newHeight * ((-parseInt(matrix.y) + params.originY) / zoomPan.height) - params.originY;

                    if (dx < 0) dx = 0;
                    if (dx > newWidth - baseWidth.current) dx = newWidth - baseWidth.current;
                    if (dy < 0) dy = 0;
                    if (dy > newHeight - baseHeight.current) dy = newHeight - baseHeight.current;

                    zoomPanRef.current.style.transform = "translate3d(-" + dx + "px, -" + dy + "px, 0px)";
                    zoomPanRef.current.style.webkitTransform = "translate3d(-" + dx + "px, -" + dy + "px, 0px)";
                    zoomPanRef.current.style.width = newWidth + "px";
                    zoomPanRef.current.style.height = newHeight + "px";
                }
                break;
            case touchTypes.TOUCH_ACTION_MOVE:
                matrix = getTransMatrix();
                if (matrix) {
                    dx = parseInt(matrix.x) + parseInt(props.dx);
                    dy = parseInt(matrix.y) + parseInt(props.dy);

                    let zoomPan = zoomPanRef.current.getBoundingClientRect();
                    var maxDx = zoomPan.width - baseWidth.current;
                    var maxDy = zoomPan.height - baseHeight.current;

                    if (dx > 0) dx = 0;
                    if (Math.abs(dx) > Math.abs(maxDx)) dx = -(maxDx);
                    if (Math.abs(dy) > Math.abs(maxDy)) dy = -(maxDy);
                    if (dy > 0) dy = 0;

                    zoomPanRef.current.style.transform = "translate3d(" + dx + "px, " + dy + "px, 0px)";
                    zoomPanRef.current.style.webkitTransform = "translate3d(" + dx + "px, " + dy + "px, 0px)";
                }
                break;
            default:
                break;
        }
    }

    /**
     * 現在の拡大率取得.
     */
    const getZoom = () => {
        return zoomRef.current;
    }

    /**
     * iOS全画面表示用パラメータ設定.
     */
    const setParamFullscreen = () => {
        viewerRef.current.style.width = window.outerWidth + "px";
        viewerRef.current.style.height = window.outerHeight + sliderHeight + "px";
        if (window.orientation === 0) {
            viewerRef.current.style.transform = "translate3d(-50%, calc( -50% - " + adjustHeight + "px), 0px)";
        } else {
            viewerRef.current.style.transform = "translate3d(-50%, calc( -50% - " + adjustHeight + "px), 0px)";
        }
    }

    /**
     * リサイズ処理.
     */
    const resize = () => {
        if (viewerRef.current) {
            if (viewerRef.current.classList.contains("fullScreen")) {
                setTimeout(() => setParamFullscreen(), 200);
            }
        }
        initViewer();
    }

    useEffect(() => {
        document.onfullscreenchange = document.onmozfullscreenchange = document.onwebkitfullscreenchange = document.onmsfullscreenchange = (event) => {
            // esc,×ボタン押下時に変更を検出する必要があるため全画面表示切替とは別でStateを更新する.
            setIsFullScreen(isFullScreenFlg());
            // iOSにフルスクリーン状態を通知（アプリ対応）.
            if (app.isIosApp()) {
                isFullScreenFlg() ?
                window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_HIDDEN :
                window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_VISIBLE;
            }
            initViewer();
        }

        // マウス押下時画面操作用のUIを表示.
        controllerRef.current.addEventListener("mousedown", fadeinController);

        // 一定時間操作がない場合UIを非表示.
        controllerRef.current.addEventListener("mouseup", fadeoutController);
        controllerRef.current.addEventListener("touchmove", fadeoutController);

        zoomPanRef.current.addEventListener("load", initViewer);
        window.addEventListener("orientationchange", initViewer);
        window.addEventListener("resize", resize);

        return () => {
            window.removeEventListener("resize", resize);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (sliderTimerRef.current != null) {
            clearTimeout(sliderTimerRef.current);
        }
        sliderRef.current.classList.add("active");
        sliderTimerRef.current = setTimeout(() => {
            if (sliderRef.current) {
                sliderRef.current.classList.remove("active");
                sliderTimerRef.current = null;
            }
        }, 2000);
    }, [isPaging, isFullScreen]);

    /**
     * iOS全画面表示切替時の処理.
     */
    useEffect(() => {
        if (!viewerRef || !viewerRef.current) return;

        if (isFullScreen && !viewerRef.current.webkitRequestFullScreen) {
            setParamFullscreen();
            initViewer();
        }
    }, [isFullScreen]);

    return (
        <div ref={viewerRef} className={classes.viewer}>
            <div className={classes.viewerBase}>
                {!isFullScreen &&
                    <div className={classes.viewerHeader}>
                        <img
                            src="/images/xs/viewer/IMAGINATION_CONTENTS_VIEWER.svg"
                            alt=""
                        />
                    </div>
                }
                <div className={classes.contentsViewer} >
                    <div ref={zoomPanWindowRef} className={classes.contentsImg}>
                        <img ref={zoomPanRef} src={props.input.images[isPaging - 1]} alt="" />
                    </div>
                    <div ref={controllerRef} className={classes.viewerController}>
                        <Touch touchAction={(e) => touchAction(e)} getZoom={() => getZoom()} />
                        {props.input.type !== Param.CONTENTS_TYPE_NOVEL &&
                            <div className="viewerSwitchScreen">
                                {!isFullScreen &&
                                    <IconButton
                                        id="viewerFullScreen"
                                        onClick={() => handleFullScreen()}
                                    >
                                        <img src="/images/xs/viewer/full_screen.png" alt="" />
                                    </IconButton>
                                }
                                {isFullScreen &&
                                    <IconButton
                                        id="viewerShlinkScreen"
                                        onClick={() => handleFullScreen()}
                                    >
                                        <img src="/images/xs/viewer/shlink_screen.png" alt="" />
                                    </IconButton>
                                }
                            </div>
                        }
                        <table>
                            <thead></thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <IconButton onClick={() => leftPageSend()}>
                                            <img src="/images/xs/viewer/viewer_arrow_left.png" alt="" />
                                        </IconButton>
                                    </td>
                                    <td ref={sliderRef} className={classes.viewerSlider}>
                                        {props.input.type === Param.CONTENTS_TYPE_AMECOMI &&
                                            <Slider
                                                value={isPaging}
                                                max={pageMax}
                                                min={pageMin}
                                                step={1}
                                                scale={(x) => x}
                                                onChange={handlePage}
                                                valueLabelDisplay="on"
                                                aria-labelledby="non-linear-slider"
                                                valueLabelFormat={(x) => x + "/" + pageMax}
                                            />
                                        }
                                        {/**
                                         *  右から左にページを送る場合スライドバーの向きとは逆になるため
                                         *  値を負数にし、最大値と最小値を逆転させ絶対値をページとして処理する.
                                         */}
                                        {props.input.type !== Param.CONTENTS_TYPE_AMECOMI &&
                                            <Slider
                                                track="inverted"
                                                value={-isPaging}
                                                max={-pageMin}
                                                min={-pageMax}
                                                step={1}
                                                scale={(x) => -x}
                                                onChange={handlePage}
                                                valueLabelDisplay="on"
                                                aria-labelledby="non-linear-slider"
                                                valueLabelFormat={(x) => x + "/" + pageMax}
                                            />
                                        }
                                    </td>
                                    <td>
                                        <IconButton onClick={() => rightPageSend()}>
                                            <img src="/images/xs/viewer/viewer_arrow_right.png" alt="" />
                                        </IconButton>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {!isFullScreen &&
                    <div className={classes.viewerMargin}></div>
                }
            </div>
        </div>
    );
};
export default Viewer;
