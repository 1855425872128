/**
 * SNSシェア.
 */

import { getDateISO } from "./apiUtil";
import * as contentsKey from "./contentsKey";
import { ULTRA_SUBSC } from "./title";

/** meta:name. */
const NAME = "name";

/** meta:property. */
const PROPERTY = "property";

/** meta:content. */
const CONTENT = "content";

/** meta:name:description. */
const DESCRIPTION = "description";

/** meta:name:keywords. */
const KEYWORDS = "keywords";

/** meta:property:og:url. */
const OG_URL = "og:url";

/** meta:property:og:image. */
const OG_IMAGE = "og:image";

/** meta:property:og:title. */
const OG_TITLE = "og:title";

/** meta:property:og:description. */
const OG_DESCRIPTION = "og:description";

/** meta:property:article:published_time. */
const ARTICLE_PUBLISHED_TIME = "article:published_time";

/** meta:property:article:modified_time. */
const ARTICLE_MODIFIED_TIME = "article:modified_time";

/** meta:property:twitter:url. */
const TWITTER_URL = "twitter:url";

/** meta:property:twitter:image. */
const TWITTER_IMAGE = "twitter:image";

/** meta:property:twitter:title. */
const TWITTER_TITLE = "twitter:title";

/** meta:property:twitter:description. */
const TWITTER_DESCRIPTION = "twitter:description";

/** description（デフォルト値）. */
const DEFAULT_DESCRIPTION = "作品見放題・オリジナル新作・オンラインイベント 限定商品販売・チケット先行販売 and MORE！ イマジネイティブな毎日をあなたに。";

/** keyword（デフォルト値）. */
const DEFAULT_KEYWORD = "TSUBURAYA IMAGINATION,ウルトラマン,円谷プロダクション";

/** OGP画像（デフォルト値）. */
const DEFAULT_OGP_IMAGE = "https://imagination.m-78.jp/assets/images/ogp.jpg";

/** 公開日（デフォルト値）. */
const DEFAULT_ARTICLE_PUBLISHED_TIME = "";

/** 最終更新日（デフォルト値）. */
const DEFAULT_ARTICLE_MODIFED_TIME = "";

/** Twitterシェア用URL. */
const TWITTER_SHARE_URL = "https://twitter.com/intent/tweet?url=";
const TWITTER_SHARE_HASHTAGS = "&hashtags=";

/** Facebookシェア用URL. */
const FACEBOOK_SHARE_URL = "https://www.facebook.com/sharer/sharer.php?u=";

/**
 * Twitterシェア用URLを取得.
 */
export const getTwitterShareUrl = (url, hashtags) => {
    let htgs = "";
    for (const tag of hashtags) {
        if (htgs !== "") htgs += ",";
        htgs += tag;
    }
    return TWITTER_SHARE_URL + encodeURIComponent(url) + TWITTER_SHARE_HASHTAGS + encodeURIComponent(htgs);
}

/**
 * Facebookシェア用URLを取得.
 */
export const getFacebookShareUrl = (url) => {
    return FACEBOOK_SHARE_URL + encodeURIComponent(url);
}

/**
 * URLを取得.
 */
export const getUrl = () => {
    return window.location.protocol + "//" + window.location.hostname + window.location.pathname;
}

/**
 * keywordsを取得.
 */
const getKeywords = (meta) => {
    let keywords = DEFAULT_KEYWORD;

    // プラネットを追加.
    const planetId = meta[contentsKey.PLANET_ID];
    if (planetId) {
        for (const planet of planetId) {
            keywords += "," + planet;
        }
    }
    // 出演者（キャラクタを含む）を追加.
    const keywordsList = meta[contentsKey.KEYWORDS_LIST];
    if (keywordsList) {
        for (const keyword of keywordsList) {
            keywords += "," + keyword;
        }
    }

    return keywords;
}

/**
 * headを設定.
 */
const setHead = (title, description, keywords, image, published, modified) => {
    // タイトルを設定.
    document.title = title;

    // タイトル以外のheadを設定.
    const headData = document.head;
    Object.values(headData.children).forEach((value) => {
        // nameを確認.
        const name = value.getAttribute(NAME);
        if (name != null) {
            if (name === DESCRIPTION) {
                value.setAttribute(CONTENT, description);
                return;
            }
            if (name === KEYWORDS) {
                value.setAttribute(CONTENT, keywords);
                return;
            }
        }

        // propertyを確認.
        const property = value.getAttribute(PROPERTY);
        if (property != null) {
            if (property === OG_URL) {
                value.setAttribute(CONTENT, getUrl());
                return;
            }
            if (property === OG_IMAGE) {
                value.setAttribute(CONTENT, image);
                return;
            }
            if (property === OG_TITLE) {
                value.setAttribute(CONTENT, title);
                return;
            }
            if (property === OG_DESCRIPTION) {
                value.setAttribute(CONTENT, description);
                return;
            }
            if (property === ARTICLE_PUBLISHED_TIME) {
                value.setAttribute(CONTENT, published);
                return;
            }
            if (property === ARTICLE_MODIFIED_TIME) {
                value.setAttribute(CONTENT, modified);
                return;
            }
            if (property === TWITTER_URL) {
                value.setAttribute(CONTENT, getUrl());
                return;
            }
            if (property === TWITTER_IMAGE) {
                value.setAttribute(CONTENT, image);
                return;
            }
            if (property === TWITTER_TITLE) {
                value.setAttribute(CONTENT, title);
                return;
            }
            if (property === TWITTER_DESCRIPTION) {
                value.setAttribute(CONTENT, description);
                return;
            }
        }
    });
}

/**
 * デフォルト値でheadを設定.
 */
export const setHeadDefault = (title) => {
    setHead(title, DEFAULT_DESCRIPTION, DEFAULT_KEYWORD,
        DEFAULT_OGP_IMAGE, DEFAULT_ARTICLE_PUBLISHED_TIME, DEFAULT_ARTICLE_MODIFED_TIME);
}

/**
 * メタデータからheadを設定（Video・Live）.
 */
export const setHeadMetaVideo = (meta) => {
    const epiTitle = meta[contentsKey.EPI_TITLE]
    const title = meta[contentsKey.TITLE] + (epiTitle ? " / " + epiTitle : "") + ULTRA_SUBSC;
    const description = meta[contentsKey.SYNOPSIS_LONG];
    const image = meta[contentsKey.THUMBNAIL_L];
    const published = getDateISO(meta[contentsKey.DISP_START] * 1000);
    const modified = published;
    setHead(title, description, getKeywords(meta), image, published, modified);
}

/**
 * メタデータからheadを設定（Contents）.
 */
export const setHeadMetaContents = (meta, contents) => {
    const element = document.createElement("div");
    element.innerHTML = contents;
    Object.values(element.children).forEach((value) => {
        const name = value.getAttribute(NAME);
        if (name != null) {
            if (name === DESCRIPTION) {
                const epiTitle = meta[contentsKey.EPI_TITLE]
                const title = meta[contentsKey.TITLE] + (epiTitle ? " / " + epiTitle : "") + ULTRA_SUBSC;
                let description = value.getAttribute(CONTENT);
                const image = meta[contentsKey.THUMBNAIL_L];
                const published = getDateISO(meta[contentsKey.DISP_START] * 1000);
                const modified = published;
                if (meta[contentsKey.SEO_LIST]) {
                    for (const seoKeyword of meta[contentsKey.SEO_LIST]) {
                        description += " " + seoKeyword;
                    }
                }
                setHead(title, description, getKeywords(meta), image, published, modified);
                return;
            }
        }
    });
}
