import * as actionTypes from "./actionTypes";

const initState = {
    isFirstLogin: true
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 初回ログインフラグ. */
        case actionTypes.UPDATE_FIRST_LOGIN:
            return { ...state, isFirstLogin: false };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
