import * as actionTypes from "./actionTypes";

const initState = {
    /** 非会員お知らせ既読マップ. */
    noneReadMap: {}
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 非会員お知らせを既読に更新. */
        case actionTypes.READ_NONE_NOTICE:
            const newState = { ...state["noneReadMap"], [action.id]: true };
            return { ...state, noneReadMap: newState }

        default:
            return state;
    }
};

export default reducer;