/** 検索コンテンツリスト更新. */
export const UPDATE_SEARCH_CONTENTS_LIST = "UPDATE_SEARCH_CONTENTS_LIST";

/** 選択中カテゴリー更新. */
export const UPDATE_SEARCH_CURRENT_CATEGORY = "UPDATE_SEARCH_CURRENT_CATEGORY";

/** 選択中プラン更新. */
export const UPDATE_SEARCH_CURRENT_PLAN = "UPDATE_SEARCH_CURRENT_PLAN";

/** ランキングメタJSON取得 成功. */
export const GET_RANKING_META_SUCCESS = "GET_RANKING_META_SUCCESS";

/** 戻るボタン更新. */
export const BACKTRUE = "BACKTRUE";

/** footer更新. */
export const FOOTERSEARCH = "FOOTERSEARCH";

/** レコメンドコンテンツ取得 成功. */
export const UPDATE_SEARCH_RECOMMEND_CONTENTS_LIST = "actionTypes.UPDATE_SEARCH_RECOMMEND_CONTENTS_LIST";