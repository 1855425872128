import * as actions from "../common/status/actions";
import * as accountif from "../middleware/accountif";
import * as contractif from "../middleware/contractif";
import * as sessionif from "../middleware/sessionif";
import * as fileApi from "../middleware/file";
import { doLogout } from "../components/dialog/Logout";
import * as plan from "../constants/plan";

/**
 * 購入一覧取得API 成功.
 */
export const getContractManageSuccess = (dispatch, noneReadMap, data) => {
    // 会員プラン情報更新.
    const plan = contractif.getContractManageSuccess(dispatch, data);

    // 通知一覧取得API.
    let request = {};
    request[accountif.PLAN_NAME] = plan;
    accountif.postNoticeList(request).then(accountif.postNoticeListSuccessReflectReadFlg.bind(this, dispatch, plan, noneReadMap), accountif.isSessionError.bind(this, dispatch));
}

/**
 * 端末時刻判定API 成功.
 */
const postTimeSuccess = (dispatch, data) => {
    const diff = data[sessionif.STATUS];
    switch (diff) {
        case sessionif.WARN:
            dispatch(actions.updateTimeWarn(true));
            break;
        case sessionif.NG:
            dispatch(actions.updateTimeNg(true));
            break;
        case sessionif.OK:
            dispatch(actions.updateTimeNg(false));
            break;
        default:
            break;
    }
}

/**
 * 端末時刻判定APIを実行.
 */
export const postTime = (dispatch) => {
    let request = {};
    const date = new Date();
    request[sessionif.CLIENT_TIME] = date.toUTCString();
    sessionif.postTime(request).then(postTimeSuccess.bind(this, dispatch));
}

/**
 * ログイン時の初期処理.
 */
export const init = (dispatch, noneReadMap) => {
    // 会員情報取得API.
    accountif.getUserInfo().then(accountif.getUserInfoSuccess.bind(this, dispatch), doLogout.bind(this, dispatch));

    // お気に入りコンテンツ取得API.
    accountif.getFavorite().then(accountif.getFavoriteSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));

    // レジューム情報取得API.
    accountif.getResume().then(accountif.getResumeSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));

    // 購入一覧取得API.
    contractif.getContractManage().then(getContractManageSuccess.bind(this, dispatch, noneReadMap), doLogout.bind(this, dispatch));

    // キャンペーン一覧取得API.
    accountif.getCampaign().then(accountif.getCampaignSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));

    // スペシャル図鑑一覧取得API.
    accountif.postCubeGroup({
        [accountif.CUBE_TYPE]: accountif.SPECIAL,
    }).then(accountif.postCubeGroupSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));

    // 端末時刻判定API.
    postTime(dispatch);

    // ファイル：プロフィール画像取得.
    fileApi.getProfPicture().then(fileApi.getProfPictureSuccess.bind(this, dispatch));

    // 初期処理済み.
    dispatch(actions.init(false));
}

export const noLoginPostNoticeList = (dispatch) => {
    accountif.postNoticeList({[accountif.PLAN_NAME]: plan.NONE}).then(accountif.postNoticeListSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));
}