import React from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as commonActions from "../../../common/status/actions";
import * as screenActions from "../../../common/status/screen/actions";
import * as apiUtil from "../../../constants/apiUtil";
import * as campaign from "../../../constants/campaign";
import * as error from "../../../constants/error";
import * as eventif from "../../../middleware/eventif";
import styles from "./styles";

/**
 * キャンペーン.
 *
 * props
 *  -> meta  キャンペーンのメタ.
 */
const Campaign = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /**
     * コンテンツクリック時.
     */
    const handleClick = () => {
        if (props.meta[campaign.IMMEDIATE_FLG]) {
            // 処理中ダイアログを表示.
            dispatch(commonActions.loading(true));

            // 即時行使の場合はクーポン管理APIを実行.
            eventif.postCoupon({
                [eventif.CAMPAIGN_ID]: props.meta[campaign.CAMPAIGN_ID],
                [eventif.GIFT_SOURCE_CRID]: props.meta[campaign.GIFT_SOURCE_CRID]
            }).then((data) => {
                if (campaign.isCompleted(data)) {
                    // クーポン行使画面を表示.
                    dispatch(commonActions.couponUsed(true, data));
                } else {
                    // クーポン行使エラーを表示.
                    let title = error.COUPON_USED_FAILURE_TITLE;
                    let content = error.COUPON_USED_FAILURE_CONTENT;
                    dispatch(commonActions.couponUsedFailure(true, title, content));
                }
            }, (err) => {
                // セッションエラーを確認.
                eventif.isSessionError(dispatch, err);
                // エラー処理.
                eventif.postCouponFailure(dispatch, location, history, err);
            }).finally(() => {
                // 処理中ダイアログを閉じる.
                dispatch(commonActions.loading(false));
                // キャンペーン一覧を閉じる.
                dispatch(screenActions.changeIsProf(false));
            });
        } else {
            // 即時行使でない場合は遷移.
            campaign.handleDestination(dispatch, history, props.meta, campaign.SOURCE_CAMPAIGN_LIST);
        }
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 新規キャンペーンか判定.
     */
    const isNew = () => {
        return props.meta[campaign.NEW_FLG] === undefined ?
            false : props.meta[campaign.NEW_FLG];
    }

    /**
     * 要素のcss.
     */
    const css = () => {
        const css = " c-prof__couponItem";
        return isNew() ? css + " c-prof__couponItem--new" : css;
    }

    return (
        <li
            key={props.meta[campaign.CAMPAIGN_ID]}
            className={classes.root + css()}
            onClick={() => handleClick()}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="c-prof__couponStatus"></div>
            <div className="c-prof__couponTxt">{props.meta[campaign.CAMPAIGN_NAME]}</div>
            <div className="c-prof__couponDay">{apiUtil.getDatefromISO(props.meta[campaign.USE_DATE_TIME])}</div>
        </li>
    );
};

export default Campaign;
