import * as actionTypes from "./actionTypes";

/**
 * レクチャー画面を表示する.
 */
export const openPopupLecture = () => {
    return {
        type: actionTypes.OPEN_POPUP_LECTURE
    };
}

/**
 * レクチャー画面を閉じる.
 */
export const closePopupLecture = () => {
    return {
        type: actionTypes.CLOSE_POPUP_LECTURE
    };
}

/**
 * 初回ログインフラグ.
 */
export const updateFirstLogin = () => {
    return {
        type: actionTypes.UPDATE_FIRST_LOGIN
    };
}
