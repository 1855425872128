import { NONE, FREE, STANDARD, PREMIUM } from "./plan";
import { isAllApp } from "./app";
import { isMobile } from "react-device-detect";

/** コンバージョン通知再生時間閾値. 例： 95%=0.95 */
export const VIDEO_CONVERSION_THRESHOLD = 0.95;

/** 静的コンテンツのスクロール閾値. 例: 95%=0.95 */
export const CONTENT_CONVERSION_THRESHOLD = 0.95;

/** レコメンド枠識別ID. PC/SP/APに分かれている. */
/** PC=PCブラウザ、SP=スマホブラウザ、AP=アプリ. */
/** 空文字を設定するとレコメンド取得せずに、画面に応じてプラネット検索やランキング表示を行う. */

/** 映像詳細 */
/** プレミアム */
const VIDEO_PC_PREMIUM = "pc311";
const VIDEO_SP_PREMIUM = "sp311";
const VIDEO_AP_PREMIUM = "ap311";

/** スタンダード */
const VIDEO_PC_STANDARD = "pc312";
const VIDEO_SP_STANDARD = "sp312";
const VIDEO_AP_STANDARD = "ap312";

/** 無料 */
const VIDEO_PC_FREE = "pc313";
const VIDEO_SP_FREE = "sp313";
const VIDEO_AP_FREE = "ap313";

/** 未ログイン */
const VIDEO_PC_NONE = "pc314";
const VIDEO_SP_NONE = "sp314";
const VIDEO_AP_NONE = "ap314";


/** 読み物詳細 */
/** プレミアム */
const TEXT_PC_PREMIUM = "pc321";
const TEXT_SP_PREMIUM = "sp321";
const TEXT_AP_PREMIUM = "ap321";

/** スタンダード */
const TEXT_PC_STANDARD = "pc322";
const TEXT_SP_STANDARD = "sp322";
const TEXT_AP_STANDARD = "ap322";

/** 無料 */
const TEXT_PC_FREE = "pc323";
const TEXT_SP_FREE = "sp323";
const TEXT_AP_FREE = "ap323";

/** 未ログイン */
const TEXT_PC_NONE = "pc324";
const TEXT_SP_NONE = "sp324";
const TEXT_AP_NONE = "ap324";


/** 検索結果0件 */
/** プレミアム */
const SEARCH0_PC_PREMIUM = "pc711";
const SEARCH0_SP_PREMIUM = "sp711";
const SEARCH0_AP_PREMIUM = "ap711";

/** スタンダード */
const SEARCH0_PC_STANDARD = "pc712";
const SEARCH0_SP_STANDARD = "sp712";
const SEARCH0_AP_STANDARD = "ap712";

/** 無料 */
const SEARCH0_PC_FREE = "pc713";
const SEARCH0_SP_FREE = "sp713";
const SEARCH0_AP_FREE = "ap713";

/** 未ログイン */
const SEARCH0_PC_NONE = "pc714";
const SEARCH0_SP_NONE = "sp714";
const SEARCH0_AP_NONE = "ap714";


/** 遷移元（映像詳細） ライブラリ一覧 */
/** プレミアム */
const LIBRARY_VIDEO_PC_PREMIUM = "pc821";
const LIBRARY_VIDEO_SP_PREMIUM = "sp821";
const LIBRARY_VIDEO_AP_PREMIUM = "ap821";

/** スタンダード */
const LIBRARY_VIDEO_PC_STANDARD = "pc822";
const LIBRARY_VIDEO_SP_STANDARD = "sp822";
const LIBRARY_VIDEO_AP_STANDARD = "ap822";

/** 無料 */
const LIBRARY_VIDEO_PC_FREE = "pc823";
const LIBRARY_VIDEO_SP_FREE = "sp823";
const LIBRARY_VIDEO_AP_FREE = "ap823";

/** 未ログイン */
const LIBRARY_VIDEO_PC_NONE = "pc824";
const LIBRARY_VIDEO_SP_NONE = "sp824";
const LIBRARY_VIDEO_AP_NONE = "ap824";


/** 遷移元（読み物詳細） ライブラリ一覧 */
/** プレミアム */
const LIBRARY_TEXT_PC_PREMIUM = "pc831";
const LIBRARY_TEXT_SP_PREMIUM = "sp831";
const LIBRARY_TEXT_AP_PREMIUM = "ap831";

/** スタンダード */
const LIBRARY_TEXT_PC_STANDARD = "pc832";
const LIBRARY_TEXT_SP_STANDARD = "sp832";
const LIBRARY_TEXT_AP_STANDARD = "ap832";

/** 無料 */
const LIBRARY_TEXT_PC_FREE = "pc833";
const LIBRARY_TEXT_SP_FREE = "sp833";
const LIBRARY_TEXT_AP_FREE = "ap833";

/** 未ログイン */
const LIBRARY_TEXT_PC_NONE = "pc834";
const LIBRARY_TEXT_SP_NONE = "sp834";
const LIBRARY_TEXT_AP_NONE = "ap834";


/** 遷移元（検索結果0件） ライブラリ一覧 */
/** プレミアム */
const LIBRARY_SEARCH0_PC_PREMIUM = "pc841";
const LIBRARY_SEARCH0_SP_PREMIUM = "sp841";
const LIBRARY_SEARCH0_AP_PREMIUM = "ap841";

/** スタンダード */
const LIBRARY_SEARCH0_PC_STANDARD = "pc842";
const LIBRARY_SEARCH0_SP_STANDARD = "sp842";
const LIBRARY_SEARCH0_AP_STANDARD = "ap842";

/** 無料 */
const LIBRARY_SEARCH0_PC_FREE = "pc843";
const LIBRARY_SEARCH0_SP_FREE = "sp843";
const LIBRARY_SEARCH0_AP_FREE = "ap843";

/** 未ログイン */
const LIBRARY_SEARCH0_PC_NONE = "pc844";
const LIBRARY_SEARCH0_SP_NONE = "sp844";
const LIBRARY_SEARCH0_AP_NONE = "ap844";

// デバイスID.
const DEVICE_PC = "p";
const DEVICE_SP = "s";
const DEVICE_APP = "a";

// TOP画面レコメンドキー
const TOP_KEY = "recommendTopID";

// 遷移元TOP画面のライブラリ一覧レコメンドキー
const TOP_LIBRARY_KEY = "recommendLibraryID";

/**
 * 映像詳細画面のレコメンド枠識別IDを返却.
 */
export const getVideoSpec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return VIDEO_AP_NONE;
            }
            else if (isMobile) {
                return VIDEO_SP_NONE;
            }
            else {
                return VIDEO_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return VIDEO_AP_FREE;
            }
            else if (isMobile) {
                return VIDEO_SP_FREE;
            }
            else {
                return VIDEO_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return VIDEO_AP_STANDARD;
            }
            else if (isMobile) {
                return VIDEO_SP_STANDARD;
            }
            else {
                return VIDEO_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return VIDEO_AP_PREMIUM;
            }
            else if (isMobile) {
                return VIDEO_SP_PREMIUM;
            }
            else {
                return VIDEO_PC_PREMIUM;
            }
        default:
            return "";
    }
};

/**
 * 読み物詳細画面のレコメンド枠識別IDを返却.
 */
export const getTextSpec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return TEXT_AP_NONE;
            }
            else if (isMobile) {
                return TEXT_SP_NONE;
            }
            else {
                return TEXT_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return TEXT_AP_FREE;
            }
            else if (isMobile) {
                return TEXT_SP_FREE;
            }
            else {
                return TEXT_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return TEXT_AP_STANDARD;
            }
            else if (isMobile) {
                return TEXT_SP_STANDARD;
            }
            else {
                return TEXT_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return TEXT_AP_PREMIUM;
            }
            else if (isMobile) {
                return TEXT_SP_PREMIUM;
            }
            else {
                return TEXT_PC_PREMIUM;
            }
        default:
            return "";
    }
};

/**
 * 検索結果0件画面のレコメンド枠識別IDを返却.
 */
export const getSearch0Spec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return SEARCH0_AP_NONE;
            }
            else if (isMobile) {
                return SEARCH0_SP_NONE;
            }
            else {
                return SEARCH0_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return SEARCH0_AP_FREE;
            }
            else if (isMobile) {
                return SEARCH0_SP_FREE;
            }
            else {
                return SEARCH0_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return SEARCH0_AP_STANDARD;
            }
            else if (isMobile) {
                return SEARCH0_SP_STANDARD;
            }
            else {
                return SEARCH0_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return SEARCH0_AP_PREMIUM;
            }
            else if (isMobile) {
                return SEARCH0_SP_PREMIUM;
            }
            else {
                return SEARCH0_PC_PREMIUM;
            }
        default:
            return "";
    }
};

export const getLibraryVideoSpec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return LIBRARY_VIDEO_AP_NONE;
            }
            else if (isMobile) {
                return LIBRARY_VIDEO_SP_NONE;
            }
            else {
                return LIBRARY_VIDEO_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return LIBRARY_VIDEO_AP_FREE;
            }
            else if (isMobile) {
                return LIBRARY_VIDEO_SP_FREE;
            }
            else {
                return LIBRARY_VIDEO_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return LIBRARY_VIDEO_AP_STANDARD;
            }
            else if (isMobile) {
                return LIBRARY_VIDEO_SP_STANDARD;
            }
            else {
                return LIBRARY_VIDEO_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return LIBRARY_VIDEO_AP_PREMIUM;
            }
            else if (isMobile) {
                return LIBRARY_VIDEO_SP_PREMIUM;
            }
            else {
                return LIBRARY_VIDEO_PC_PREMIUM;
            }
        default:
            return "";
    }
};

export const getLibraryTextSpec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return LIBRARY_TEXT_AP_NONE;
            }
            else if (isMobile) {
                return LIBRARY_TEXT_SP_NONE;
            }
            else {
                return LIBRARY_TEXT_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return LIBRARY_TEXT_AP_FREE;
            }
            else if (isMobile) {
                return LIBRARY_TEXT_SP_FREE;
            }
            else {
                return LIBRARY_TEXT_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return LIBRARY_TEXT_AP_STANDARD;
            }
            else if (isMobile) {
                return LIBRARY_TEXT_SP_STANDARD;
            }
            else {
                return LIBRARY_TEXT_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return LIBRARY_TEXT_AP_PREMIUM;
            }
            else if (isMobile) {
                return LIBRARY_TEXT_SP_PREMIUM;
            }
            else {
                return LIBRARY_TEXT_PC_PREMIUM;
            }
        default:
            return "";
    }
};

export const getLibrarySearch0Spec = (plan) => {
    switch (plan) {
        case NONE:
            if (isAllApp()) {
                return LIBRARY_SEARCH0_AP_NONE;
            }
            else if (isMobile) {
                return LIBRARY_SEARCH0_SP_NONE;
            }
            else {
                return LIBRARY_SEARCH0_PC_NONE;
            }
        case FREE:
            if (isAllApp()) {
                return LIBRARY_SEARCH0_AP_FREE;
            }
            else if (isMobile) {
                return LIBRARY_SEARCH0_SP_FREE;
            }
            else {
                return LIBRARY_SEARCH0_PC_FREE;
            }
        case STANDARD:
            if (isAllApp()) {
                return LIBRARY_SEARCH0_AP_STANDARD;
            }
            else if (isMobile) {
                return LIBRARY_SEARCH0_SP_STANDARD;
            }
            else {
                return LIBRARY_SEARCH0_PC_STANDARD;
            }
        case PREMIUM:
            if (isAllApp()) {
                return LIBRARY_SEARCH0_AP_PREMIUM;
            }
            else if (isMobile) {
                return LIBRARY_SEARCH0_SP_PREMIUM;
            }
            else {
                return LIBRARY_SEARCH0_PC_PREMIUM;
            }
        default:
            return "";
    }
};

/**
 * TOP画面のレコメンド枠識別IDのキー.
 */
export const getTopSpecKey = () => {
    switch(getDeviceId()) {
        case DEVICE_SP:
            return TOP_KEY + "SP";
        case DEVICE_APP:
            return TOP_KEY + "APP"
        default:
            return TOP_KEY + "PC";
    }
};

/**
 * 遷移元TOP画面のライブラリ一覧画面のレコメンド枠識別IDのキー.
 */
export const getTopLibrarySpecKey = () => {
    switch(getDeviceId()) {
        case DEVICE_SP:
            return TOP_LIBRARY_KEY + "SP";
        case DEVICE_APP:
            return TOP_LIBRARY_KEY + "APP"
        default:
            return TOP_LIBRARY_KEY + "PC";
    }
};

/**
 * コンバージョン通知リクエストに使用するデバイスIDを返却.
 */
export const getDeviceId = () => {
    if (isAllApp()) {
        return DEVICE_APP;
    }
    else if (isMobile) {
        return DEVICE_SP;
    }
    else {
        return DEVICE_PC;
    }
};
