import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as memberActions from "../../common/status/member/actions";
import * as contentsKey from "../../constants/contentsKey";
import * as cube from "../../constants/cube";
import * as accountif from "../../middleware/accountif";
import * as contentif from "../../middleware/contentif";
import DataCubeRecommend from "../../components/contents/dataCubeRecommend/DataCubeRecommend";
import styles from "./styles";

/**
 * データキューブ詳細.
 *
 * props
 *  -> handleDataCube     データキューブ詳細の非表示
 *  -> scrollTopDataCube  データキューブのTOPにスクロール
 */
const DataCube = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();

    /** データキューブ詳細の表示/非表示. */
    const isDataCube = useSelector(state => state.Screen.isDataCube);
    /** スペシャル図鑑ID. */
    const specialCubeGroupId = useSelector(state => state.Screen.specialCubeGroupId);
    /** データキューブ一覧更新. */
    const dataCubeList = useSelector(state => state.Member.dataCubeList);
    /** データキューブ詳細. */
    const dataCube = useSelector(state => state.Member.dataCube);

    /** 関連作コンテンツ. */
    const [recommendContents, setRecommendContents] = useState([]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        if (isDataCube) {
            // 関連作をID検索.
            if (dataCube[cube.CRID_LIST] !== undefined && dataCube[cube.CRID_LIST].length > 0) {
                contentif.postIdSearch({
                    [contentif.CONTENT_ID_LIST]: dataCube[cube.CRID_LIST],
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                }).then((data) => {
                    setRecommendContents(data)
                }).catch((err) => {
                    setRecommendContents([])
                });
            }
        }
    }, [isDataCube, dataCube]);

    /**
     * キューブ既読処理.
     */
    useEffect(() => {
        if (isDataCube) {
            if (dataCube[cube.FIRST_ACCESS_FLG] !== undefined && dataCube[cube.FIRST_ACCESS_FLG]) {
                // キューブ既読設定API.
                accountif.postCubeView({
                    [accountif.CUBE_ID]: dataCube[cube.CUBE_ID],
                    [accountif.CUBE_GROUP_ID]: specialCubeGroupId
                }).then((data) => {
                    dispatch(memberActions.updateDataCubeList(data));
                }).catch((err) => {
                    // セッションエラーを確認.
                    accountif.isSessionError(dispatch, err);
                });
            }
        }
    }, [dispatch, isDataCube, specialCubeGroupId, dataCube]);

    /**
     * 前のデータキューブがあるか判定.
     */
    const isPrevCube = () => {
        let position = 0;
        for (let i = 0; i < dataCubeList.length; i++) {
            if (dataCubeList[i][cube.CUBE_ID] === dataCube[cube.CUBE_ID]) {
                position = i;
                break;
            }
        }

        // 先頭要素の場合は無し.
        if (position === 0) return false;

        // 前の要素のパラメータがあればtrue.
        for (let i = position - 1; i >= 0; i--) {
            if (dataCubeList[i][cube.CUBE_IMAGE_FILE] !== "") return true;
        }
        return false;
    }

    /**
     * 次のデータキューブがあるか判定.
     */
    const isNextCube = () => {
        let position = 0;
        for (let i = 0; i < dataCubeList.length; i++) {
            if (dataCubeList[i][cube.CUBE_ID] === dataCube[cube.CUBE_ID]) {
                position = i;
                break;
            }
        }

        // 末尾要素の場合は無し.
        if (position === dataCubeList.length - 1) return false;

        // 次の要素のパラメータがあればtrue.
        for (let i = position + 1; i < dataCubeList.length; i++) {
            if (dataCubeList[i][cube.CUBE_IMAGE_FILE] !== "") return true;
        }
        return false;
    }

    /**
     * 前のデータキューブを表示.
     */
    const handlePrevCube = () => {
        let prev = {};
        for (const d of dataCubeList) {
            if (d[cube.CUBE_ID] === dataCube[cube.CUBE_ID]) {
                if (Object.keys(prev).length) {
                    // 前のデータキューブをdispatch.
                    dispatch(memberActions.updateDataCube(prev));
                    props.scrollTopDataCube();
                    return;
                }
                return;
            } else {
                if (d[cube.CUBE_IMAGE_FILE] !== "") prev = d;
            }
        }
    }

    /**
     * 次のデータキューブを表示.
     */
    const handleNextCube = () => {
        let next = false;
        for (const d of dataCubeList) {
            if (next) {
                if (d[cube.CUBE_IMAGE_FILE] !== "") {
                    // 次のデータキューブをdispatch.
                    dispatch(memberActions.updateDataCube(d));
                    props.scrollTopDataCube();
                    return;
                }
            }
            if (d[cube.CUBE_ID] === dataCube[cube.CUBE_ID]) next = true;
        }
    }

    return (
        <span>
            <div className="c-prof__datacubeFoot c-prof__datacubeFoot--app">
                <p className="c-prof__datacubeFoot__txt c-prof__datacubeFoot__txt--app">{dataCube[cube.NAME]}</p>
                {isPrevCube() &&
                    <div className="c-prof__datacubeFoot__arrow c-prof__datacubeFoot__arrow--black c-prof__datacubeFoot__arrow--left" onClick={() => handlePrevCube()}></div>
                }
                {isNextCube() &&
                    <div className="c-prof__datacubeFoot__arrow c-prof__datacubeFoot__arrow--black c-prof__datacubeFoot__arrow--right" onClick={() => handleNextCube()}></div>
                }
            </div>
            <div className="c-prof__datacubeDtl__head">
                <div className="c-back c-back--datacube" onClick={props.handleDataCube}>
                    <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                    <div className="c-back__txt">もどる</div>
                </div>
            </div>
            <div className="c-prof__datacubeDtl__headBg"></div>
            <div className="c-prof__datacubeDtl__wrap">
                <div className="c-prof__datacubeDtl__item"><img src={dataCube[cube.CUBE_IMAGE_FILE]} alt="" /></div>
                <div className="c-prof__datacubeDtl__ttl">{dataCube[cube.NAME]}</div>
                <div className="c-prof__datacubeDtl__txt">{dataCube[cube.DESCRIPTION]?.split("\n").map(x => (<span>{x}<br /></span>))}</div>
                {dataCube[cube.LINK] !== "" &&
                    <div>
                        <br/>
                        <a
                            href={dataCube[cube.LINK]}
                            className={classes.dataCubeLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            関連情報もチェック！
                        </a>
                        <br/>
                        <br/>
                    </div>
                }
            </div>
            {recommendContents.length > 0 &&
                <section className="c-sect c-sect--listDis">
                    <h2 className="c-sect__band">おすすめ</h2>
                    <div className="c-contCardSlideBox c-contCardSlideBox--datacube">
                        <div className="p-searchListBox">
                            <div className="p-searchListGrid p-searchListGrid--datacube">
                                <ul className="p-searchListGrid__inner">
                                    {Object.entries(recommendContents).map(([, value]) => (
                                        <li key={value[contentsKey.CRID]} className="p-searchListGrid__item">
                                            <DataCubeRecommend meta={value} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </span>
    );
};

export default DataCube;
