/**
 * 決済.
 */

import * as constantsPlan from "./plan";

/** クレジットカード登録. */
export const REGISTER = "register";

/** クレジットカード変更. */
export const CHANGE = "change";

/** 会員プラン決済(登録込み). */
export const PLAN = "plan";

/** レンタル決済(登録込み). */
export const RENTAL = "rental";

/** 会員プラン決済. */
export const PLAN_ONLY = "planOnly";

/** レンタル決済. */
export const RENTAL_ONLY = "rentalOnly";

/** 会員プラン決済(クレジットカード有効期限切れ). */
export const PLAN_EXPIRED = "planExpired";

/** レンタル決済(クレジットカード有効期限切れ). */
export const RENTAL_EXPIRED = "rentalExpired";

/** 無料会員. ※無料会員プラン登録完了ダイアログでの使用を想定 */
export const FREE = "free";

/** プラン変更. */
export const PLAN_CHANGE = "planChange";

/** 変更予約. */
export const RESERVE_CHANGE = "reserveChange";

/** プランの変更予約キャンセル. */
export const PLAN_CANCEL = "planCancel";

/** 退会. */
export const WITHDRAWAL = "Withdrawal";

/** 退会予約. */
export const RESERVE_WITHDRAWAL = "reserveWithdrawal";

/** ムビチケ応募. */
export const MOVIE_TICKET = "movieTicket";

/** 無料キャンペーンプラン. */
export const PLAN_CAMPAIGN = "planCampaign";

/** 無料キャンペーンプラン（クレカ登録済み）. */
export const PLAN_CAMPAIGN_ONLY = "planCampaignOnly";

/** 無料キャンペーンプラン（クレカ有効期限切れ）. */
export const PLAN_CAMPAIGN_EXPIRED = "planCampaignExpired";

/**
 * 会員プラン別料金.
 */
export const planAmount = (plan, amount) => {
    switch(plan) {
        case constantsPlan.FREE:
            return "無料プラン";
        case constantsPlan.STANDARD:
            return "スタンダードプラン (月額 " + Number(amount).toLocaleString() + "円/税込)";
        case constantsPlan.PREMIUM:
            return "プレミアムプラン (年額 " + Number(amount).toLocaleString() + "円/税込)";
        default:
            return "";
    }
}