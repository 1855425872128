import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import { createLogger } from "redux-logger";

// ルートコンポーネントを取得.
import App from "./App";

// Reducerを取得.
import reducers from "./reducers";

// LocalStorage設定.
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["LectureFirst", "Player", "Cushion", "Coupon", "LocalCredit", "Cast", "NoneNotice", "Grid", "statusQuality", "RecommendCookie"]
}

// 永続化設定されたReducer.
const persistedReducer = persistReducer(persistConfig, reducers);

// コマンドのログ出力のパラメーターを取得する.
const config = process.env.REACT_APP_LOGGER;

// ストアを作成.
// ログ出力のパラメーターの有無でストアの作成方法を変える.
const store = ( config === "true" )?createStore(
        persistedReducer,
        applyMiddleware(createLogger())
    ):createStore(
        persistedReducer
);


const persistor = persistStore(store);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById("root")
);
