import React, { useCallback, useEffect } from "react";
import { useDispatch,useSelector } from "react-redux";
import Parallax from "react-rellax";
import { encode } from "js-base64";
import * as contentsKey from "../../../constants/contentsKey";
import PlanetContents from "../../contents/planet/Planet";
import * as fileApi from "../../../middleware/file";
import * as commonActions from "../../../common/status/actions";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import styles from "./styles";
import { PLANET_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * プラネット画面.
 */
const Planet = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();

    /** プラネットリスト. */
    const planetList = useSelector(state => state.Member.planetList);

    /**
     * ファイル：プラネット定義取得 失敗.
     */
    const getPlanetFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.planet());

        // head設定.
        setHeadDefault(PLANET_TITLE);

        // ファイル：プラネット定義.
        fileApi.getPlanet().then(fileApi.getPlanetSuccess.bind(this, dispatch), getPlanetFailure);
    }, [dispatch, getPlanetFailure]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <section className="c-sectPage">
                    <div className="c-sectPage__hero">
                        <h1 className="c-sectPage__ttl">プラネット</h1>
                        <p className="c-sectPage__dtl">プラネットには、映像や小説・コミックス、解説やコラムなどのテキスト、作品にまつわる様々なコンテンツが集まっています。<br/>好きなプラネットを選択してください。</p>
                    </div>
                </section>
                <div className="c-container">
                    <div className="p-planet">
                        <ul className="p-planet__ultraSelect__wrap">
                            {Object.entries(planetList).map(([key, value]) => (
                                <li key={encode(value[contentsKey.PLANET_ID])} className={classes.clickable +" p-planet__ultraSelect__item js-on"}>
                                    <PlanetContents meta={value} isAnimation={true} delay={(key * 100)} />
                                </li>
                            ))}
                        </ul>
                        <div style={{height: "200px"}}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Planet;
