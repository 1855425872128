import * as actionTypes from "./actionTypes";

/**
 * Cast状態を更新（Play）.
 */
export const castPlay = (state, name) => {
    return {
        type: actionTypes.CAST_PLAY,
        castState: state,
        castDeviceName: name
    };
}

/**
 * Cast状態を更新（Idle）.
 */
export const castIdle = () => {
    return {
        type: actionTypes.CAST_IDLE
    };
}

/**
 * Cast状態を更新（Stop）.
 */
export const castStop = () => {
    return {
        type: actionTypes.CAST_STOP
    };
}