import React from "react";
import { useDispatch } from "react-redux";
import * as memberActions from "../../../common/status/member/actions";
import * as cube from "../../../constants/cube";
import styles from "./styles";

/**
 * データキューブ.
 *
 * props
 *  -> meta            データキューブのメタ.
 *  -> handleDataCube  データキューブ詳細の表示
 */
const DataCube = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();

    /**
     * コンテンツクリック時.
     */
    const handleClick = () => {
        if (props.meta[cube.CUBE_IMAGE_FILE] !== "") {
            dispatch(memberActions.updateDataCube(props.meta));
            props.handleDataCube();
        }
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 新規データキューブか判定.
     */
    const isNew = () => {
        return props.meta[cube.NEW_FLG];
    }

    /**
     * 新規取得データキューブか判定.
     */
    const isGet = () => {
        return props.meta[cube.FIRST_ACCESS_FLG];
    }

    return (
        <span
            className={classes.root}
            onClick={() => handleClick()}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            {isNew() && <div className="c-tag c-tag--new c-tag--new--collectionList">NEW</div>}
            <img src={props.meta[cube.THUMBNAIL_FILE]} alt=""/>
            {isGet() && <div className={classes.get}><img src="/assets/images/img-get.png" alt=""/></div>}
        </span>
    );
};

export default DataCube;
