/** クレジットカード情報入力ダイアログを表示. */
export const OPEN_POPUP_INPUT_CREDIT = "OPEN_POPUP_INPUT_CREDIT";
/** クレジットカード情報入力ダイアログを閉じる. */
export const CLOSE_POPUP_INPUT_CREDIT = "CLOSE_POPUP_INPUT_CREDIT";
/** 決済情報入力ダイアログを表示. */
export const OPEN_POPUP_SIMPLE_INPUT_CREDIT = "OPEN_POPUP_SIMPLE_INPUT_CREDIT";
/** 決済情報入力ダイアログを閉じる. */
export const CLOSE_POPUP_SIMPLE_INPUT_CREDIT = "CLOSE_POPUP_SIMPLE_INPUT_CREDIT";
/** 決済完了ダイアログを表示. */
export const OPEN_POPUP_CREDIT_RESULT = "OPEN_POPUP_CREDIT_RESULT";
/** 決済完了ダイアログを閉じる. */
export const CLOSE_POPUP_CREDIT_RESULT = "CLOSE_POPUP_CREDIT_RESULT";
/** CardlistIdを保存. */
export const SAVE_CARDLIST_ID = "SAVE_CARDLIST_ID";