import * as commonActions from "../common/status/actions";
import * as couponActions from "../common/status/coupon/actions";
import { EVENTIF } from "../constants/domain";
import * as app from "../constants/app";
import * as apiUtil from "../constants/apiUtil";
import * as error from "../constants/error";
import { EPLUS_LINK, CN_PLAYGUIDE_LINK } from "../constants/static";
import { doLogout } from "../components/dialog/Logout";
import * as historys from "../historys";

/** 購入番号. */
export const PURCHASE_NUMBER = "purchaseNumber";

/** 暗証番号. */
export const PIN = "pin";

/** キャンペーンコード. */
export const CAMPAIGN_CODE = "campaignCode";

/** コンテンツID(CRID). */
export const CRID = "crid";

/** プレイガイド名. */
export const AGENCY = "agency";

/** agency: ローチケ. */
export const L_TIKE = "l-tike";

/** agency: e+. */
export const EPLUS = "eplus";

/** agency: CNプレイガイド. */
export const CN_PLAYGUIDE = "cnplayguide";

/** コンテンツID. */
export const CID = "cid";

/** サイトID. */
export const STID = "STID";

/** キーワード. */
export const KEYWORD = "keyword";

/** キャンペーンID. */
export const CAMPAIGN_ID = "campaignId";

/** コンテンツタイプ. */
export const CONTENT_TYPE = "contentType";

/** クーポンコード. */
export const COUPON = "coupon";

/** 無料キャンペーン対象プラン. */
export const TARGET = "target";

/** 無料キャンペーン対象期間. */
export const PERIOD = "period";

/** 特典元CRID. */
export const GIFT_SOURCE_CRID = "giftSourceCrid";

/** エラーコード：ムビチケ購入番号が不正. */
export const WAR06005 = "WAR06005";

/** エラーコード：ムビチケ応募済み. */
export const WAR06006 = "WAR06006";

/** エラーコード：ムビチケ購入番号使用済み. */
export const WAR06008 = "WAR06008";

/** エラーコード：ムビチケ応募期間開始前. */
export const WAR06023 = "WAR06023";

/** エラーコード：ムビチケ応募期間終了済. */
export const WAR06026 = "WAR06026";

/** セッションエラー. */
export const WAR06007 = "WAR06007";

/** エラーコード：クーポンが無効（存在しないなど）. */
export const WAR06010 = "WAR06010";

/** エラーコード：クーポンが有効期間前. */
export const WAR06011 = "WAR06011";

/** エラーコード：クーポンが期限切れ. */
export const WAR06012 = "WAR06012";

/** エラーコード：クーポンが他ユーザで使用済み. */
export const WAR06013 = "WAR06013";

/** エラーコード：クーポンを同じユーザで使用済み. */
export const WAR06014 = "WAR06014";

/** エラーコード：プラン未充足. */
export const WAR06015 = "WAR06015";

/** エラーコード：プラン未選択. */
export const WAR06016 = "WAR06016";

/** エラーコード：クーポンとリクエストのキャンペーンIDが不一致. */
export const WAR06022 = "WAR06022";

/** エラーコード：追加特典取得失敗（オンラインクーポン）. */
export const ERR06006 = "ERR06006";

/**
 * ムビチケ連携API.
 * API: POST /movieticket
 */
export async function postMovieticket(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(EVENTIF + "/movieticket", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 暗号化API.
 * API: POST /encryption
 */
export async function postEncryption(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(EVENTIF + "/encryption", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * クーポン管理API.
 * API: POST /coupon
 */
export async function postCoupon(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(EVENTIF + "/coupon", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * クーポン利用状況チェックAPI.
 * API: POST /couponcheck
 */
export async function postCouponCheck(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(EVENTIF + "/couponcheck", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 追加特典取得API.
 * API: POST /moregift
 */
export async function postMoreGift(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(EVENTIF + "/moregift", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * セッションエラーはログアウト.
 */
export const isSessionError = (dispatch, err) => {
    if (apiUtil.isSessionError(err, WAR06007)) doLogout(dispatch);
}

export const postEncryptionSuccess = (parts, data) => {
    let form = document.createElement("form");
    form.action = parts.url;
    form.method = "post";

    // APIレスポンスのkey-valueを設定.
    for (const[key, value] of Object.entries(data)) {
        let param = document.createElement("input");
        param.type = "hidden";
        param.name = key;
        param.value = value;
        form.appendChild(param);
    }

    // サイトID設定.
    let siteId = document.createElement("input");
    if (parts.type === EPLUS_LINK || parts.type === CN_PLAYGUIDE_LINK) {
        siteId.name = KEYWORD;
    }
    else {
        siteId.name = STID;
    }
    siteId.type = "hidden";
    siteId.value = parts.id;
    form.appendChild(siteId);

    document.body.appendChild(form);
    form.submit();
}

/**
 * クーポン管理API 失敗.
 */
export const postCouponFailure = (dispatch, location, history, err) => {
    let title = "";
    let content = "";
    switch (err[apiUtil.ERROR_CODE]) {
        // ログインエラー判定.
        case WAR06007:
            // 円谷ログインに遷移.
            if (app.isAllApp()) {
                apiUtil.tsuburayaAppLogin(dispatch, location);
            }
            else {
                apiUtil.tsuburayaWebLogin(dispatch, location);
            }
            break;

        // プラン未選択エラー判定.
        case WAR06016:
            // クーポンコードを初期化.
            dispatch(couponActions.updateCouponCode(""));
            // エラーダイアログ表示せずTOP画面に遷移.
            historys.historyReplaceTop(history);
            break;

        // プラン未充足エラー判定.
        case WAR06015:
            // プラン未充足エラーダイアログ表示.
            dispatch(commonActions.couponUsedPlanFailure(true));
            break;

        // クーポンが無効（存在しないなど）.
        case WAR06010:
            title = error.COUPON_USED_FAILURE_INVALID_TITLE;
            content = error.COUPON_USED_FAILURE_INVALID_CONTENT;
            break;

        // クーポンが有効期間前.
        case WAR06011:
            title = error.COUPON_USED_FAILURE_BEFORE_VALID_TITLE;
            content = error.COUPON_USED_FAILURE_BEFORE_VALID_CONTENT;
            break;

        // クーポンが期限切れ.
        case WAR06012:
            title = error.COUPON_USED_FAILURE_EXPIRED_TITLE;
            content = error.COUPON_USED_FAILURE_EXPIRED_CONTENT;
            break;

        // クーポンが他ユーザで使用済み.
        case WAR06013:
            title = error.COUPON_USED_FAILURE_OTHER_TITLE;
            content = error.COUPON_USED_FAILURE_OTHER_CONTENT;
            break;

        // クーポンを同じユーザで使用済み.
        case WAR06014:
            title = error.COUPON_USED_FAILURE_USED_TITLE;
            content = error.COUPON_USED_FAILURE_USED_CONTENT;
            break;

        // クーポンとリクエストのキャンペーンIDが不一致.
        case WAR06022:
            title = error.COUPON_USED_FAILURE_MISMATCH_TITLE;
            content = error.COUPON_USED_FAILURE_MISMATCH_CONTENT;
            break;

        default:
            title = error.COUPON_USED_FAILURE_TITLE;
            content = error.COUPON_USED_FAILURE_CONTENT;
    }

    // クーポン行使エラーダイアログ表示.
    if (title !== "") {
        dispatch(commonActions.couponUsedFailure(true, title, content));
    }
}