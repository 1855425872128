import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as historys from "../../../historys";
import * as screenActions from "../../../common/status/screen/actions";
import * as plan from "../../../constants/plan";
import * as contentType from "../../../constants/contentType";
import * as iconType from "../../../constants/iconType";
import * as contentsKey from "../../../constants/contentsKey";
import * as libraryId from "../../../constants/libraryId";

import styles from "./styles";

/**
 * データキューブのおすすめコンテンツ.
 *
 * props
 *  -> meta  コンテンツのメタ.
 */
const DataCubeRecommend = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);

    /**
     * コンテンツクリック時.
     */
    const handleClick = (id) => {
        // シリーズはライブラリ一覧に遷移.
        if (props.meta[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(id);
            historys.historylibraryId(history, historys.getEncodeId(list));
            dispatch(screenActions.changeIsSpecialCubeGroupList(false));
        }

        // 映像詳細画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.CONTENT_TYPE] === contentType.VIDEO)) {
            historys.historyVideoId(history, id);
            dispatch(screenActions.changeIsSpecialCubeGroupList(false));
        }

        // ライブ画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.LIVE) {
            historys.historyLiveId(history, id);
            dispatch(screenActions.changeIsSpecialCubeGroupList(false));
        }

        // 静的コンテンツページに遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.WEB) {
            historys.historyContents(history, id);
            dispatch(screenActions.changeIsSpecialCubeGroupList(false));
        }
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 視聴中コンテンツか判定.
     */
    const isResume = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        for (const resume of resumeList) {
            if (resume[contentsKey.CRID] === props.meta[contentsKey.CRID]) return true;
        }
        return false;
    }

    /**
     * 特典映像コンテンツか判定.
     */
    const isGiftVideo = () => {
        if (isVideo() && props.meta[contentsKey.CAMPAIGN_ID] && props.meta[contentsKey.CAMPAIGN_ID] !== "") return true;
        return false;
    }

    /**
     * プレミアムコンテンツか判定.
     */
    const isPremium = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        if (!props.meta[contentsKey.PLAN_LIST]) return false;
        return props.meta[contentsKey.PLAN_LIST].length === 1 && props.meta[contentsKey.PLAN_LIST].includes(plan.PREMIUM);
    }

    /**
     * 無料コンテンツか判定.
     */
    const isFree = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.IS_FREE];
    }

    /**
     * Videoコンテンツか判定.
     */
    const isVideo = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.VIDEO;
    }

    /**
     * Musicコンテンツか判定.
     */
    const isMusic = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.MUSIC;
    }

    /**
     * Liveコンテンツか判定.
     */
    const isLive = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.LIVE;
    }

    /**
     * WatchPartyコンテンツか判定.
     */
    const isParty = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.PARTY;
    }

    /**
     * VRコンテンツか判定.
     */
    const isVr = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.VR;
    }

    /**
     * テキストコンテンツか判定.
     */
    const isText = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.TEXT;
    }

    /**
     * 情報コンテンツか判定.
     */
    const isInfo = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.INFO;
    }

    /**
     * チケットコンテンツか判定.
     */
    const isTicket = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.TICKET;
    }

    /**
     * NEWSコンテンツか判定.
     */
    const isNews = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.NEWS;
    }

    /**
     * グッズコンテンツか判定.
     */
    const isGoods = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        return props.meta[contentsKey.ICON_TYPE] === iconType.GOODS;
    }

    return (
        <div
            className={classes.root + " c-p-searchListGrid__itemCard p-searchListGrid__itemCard--datacube c-contCard"}
            onClick={() => handleClick(props.meta[contentsKey.CRID])}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <span className="c-contCard__inner">
                {/* ステータスアイコン（サムネイル左上に表示）. */}
                {/* 視聴中アイコン. */}
                {isResume() &&
                    <div className="c-contCard__tag c-contCard__tag--datacube c-tag c-tag--datacube c-tag--view">
                        <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                    </div>
                }

                {/* 特典映像アイコン. */}
                {(!isResume() && isGiftVideo()) &&
                    <div className="c-contCard__tag c-contCard__tag--datacube c-tag c-tag--datacube c-tag--special">
                        <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                    </div>
                }

                {/* プレミアムアイコン. */}
                {(!isResume() && !isGiftVideo() && isPremium()) &&
                    <div className="c-contCard__tag c-contCard__tag--datacube c-tag c-tag--datacube c-tag--premium">
                        <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                    </div>
                }

                {/* 無料アイコン. */}
                {(!isResume() && !isGiftVideo() && !isPremium() && isFree()) &&
                    <div className="c-contCard__tag c-contCard__tag--datacube c-tag c-tag--datacube c-tag--free">
                        <div className="c-icon__Txt">無料</div>
                    </div>
                }

                {/* メディアアイコン（サムネイル右上に表示）. */}
                {/* Videoアイコン. */}
                {isVideo() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--play c-icon--play--datacube">
                        <div className="c-icon__Txt c-picIconPlay"></div>
                    </div>
                }

                {/* Musicアイコン. */}
                {isMusic() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--radio c-icon--radio--datacube">
                        <div className="c-icon__Txt c-picIconRadio"></div>
                    </div>
                }

                {/* Liveアイコン. */}
                {isLive() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--rect c-icon--rect--datacube">
                        <div className="c-icon__Txt">LIVE</div>
                    </div>
                }

                {/* WatchPartyアイコン. */}
                {isParty() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--rect c-icon--rect--datacube">
                        <div className="c-icon__Txt">PARTY</div>
                    </div>
                }

                {/* VRアイコン. */}
                {isVr() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--rect c-icon--rect--datacube">
                        <div className="c-icon__Txt">VR</div>
                    </div>
                }

                {/* テキストアイコン. */}
                {isText() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--article c-icon--article--datacube">
                        <div className="c-icon__Txt c-picIconarticle--gray"></div>
                    </div>
                }

                {/* 情報アイコン. */}
                {isInfo() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--attention c-icon--attention--datacube">
                        <div className="c-icon__Txt c-picIconAttention"></div>
                    </div>
                }

                {/* チケットアイコン. */}
                {isTicket() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--rect c-icon--rect--datacube">
                        <div className="c-icon__Txt">TICKET</div>
                    </div>
                }

                {/* NEWSアイコン. */}
                {isNews() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--rect c-icon--rect--datacube">
                        <div className="c-icon__Txt">NEWS</div>
                    </div>
                }

                {/* カートアイコン. */}
                {isGoods() &&
                    <div className="c-contCard__icon c-contCard__icon--datacube c-icon c-icon--datacube c-icon--cart c-icon--cart--datacube">
                        <div className="c-icon__Txt c-picIconCart"></div>
                    </div>
                }
                <div className="c-contCard__photo c-contCard__photo--sizeAuto">
                    <img
                        className="lozad loaded"
                        src={props.meta[contentsKey.THUMBNAIL_P]}
                        data-src={props.meta[contentsKey.THUMBNAIL_P]}
                        alt=""
                        data-loaded="true"
                    ></img>
                </div>
                <div className="c-contCard__meta c-contCard__meta--datacube">
                    <span className="c-contCard__name c-contCard__name--datacube" data-sp-truncation="2" data-truncation="1">
                        {props.meta[contentsKey.TITLE]}
                    </span>
                </div>
            </span>
        </div>
    );
};

export default DataCubeRecommend;
