import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as plan from "../../constants/plan";
import { PROFILE_PICTURE } from "../../constants/domain";
import * as actions from "../status/actions";
import * as memberActions from "../status/member/actions";
import * as accountif from "../../middleware/accountif";
import * as fileApi from "../../middleware/file";

/**
 * プロフィール画像変更.
 *
 * props
 *  -> handleProfPicture プロフィール画像変更操作
 */
const ProfPicture = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** 有効なプロフィール画像リスト. */
    const [pictureList, setPictureList] = useState([]);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** プロフィール画像. */
    const profPicture = useSelector(state => state.Member.profPicture);
    /** プロフィール画像リスト. */
    const profPictureList = useSelector(state => state.Member.profPictureList);

    /**
     * 表示するプロフィール画像を取得.
     */
    const getDisplayPicture = useCallback(() => {
        let list = [];
        // プラン充足対象と自身が設定している画像を追加.
        const myPlan = plan.LEVEL_LIST[memberPlan];
        for (const profPic of profPictureList) {
            const picturePlan = plan.LEVEL_LIST[profPic[fileApi.PLAN]];
            if (myPlan >= picturePlan) list.push(profPic[fileApi.IMAGE_PATH]);
            else if (profPic[fileApi.IMAGE_PATH] === profPicture) list.push(profPic[fileApi.IMAGE_PATH]);
        }
        setPictureList(list);
    }, [memberPlan, profPictureList, profPicture]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        getDisplayPicture();
    }, [getDisplayPicture]);

    /**
     * 会員情報変更API 成功.
     */
    const postUserInfoSuccess = (data) => {
        dispatch(memberActions.updateMemberInfo(data));
    }

    /**
     * 会員情報変更API 失敗.
     */
    const postUserInfoFailure = (err) => {
        // セッションエラーを確認.
        accountif.isSessionError(dispatch, err);
        dispatch(actions.updateChangeProfPictureFailure(true));
    }

    /**
     * プロフィール画像変更画面 画像選択時.
     */
    const handleSelectProfPicture = (e) => {
        const element = document.getElementsByClassName("profPicture_is-current");

        // 要素の取得に失敗.
        if (element.length !== 1) return;

        // 選択中を変更.
        element[0].classList.remove("is-current");
        element[0].classList.remove("profPicture_is-current");
        e.currentTarget.classList.add("is-current");
        e.currentTarget.classList.add("profPicture_is-current");
    }

    /**
     * プロフィール画像変更画面 登録押下.
     */
    const handleRegistProfPicture = () => {
        const element = document.getElementsByClassName("profPicture_is-current");

        // 要素の取得に失敗.
        if (element.length !== 1) return;
        if (element[0].childNodes.length !== 1) return;

        // 画像を取得.
        const selectImage = element[0].childNodes[0].src.replace(PROFILE_PICTURE, "");

        // 初回ログインフラグを更新.
        let request = {};
        request[accountif.PROFILE_IMAGE_URL] = selectImage;
        accountif.postUserInfo(request).then(postUserInfoSuccess, postUserInfoFailure);

        // プロフィール画像変更画面を閉じる.
        props.handleProfPicture();
    }

    return (
        <span>
            <div className="c-prof__selectCharaHead">プロフィール画像変更</div>
            <div className="c-prof__selectCharaWrap">
                <div className="c-prof__selectCharaChoose">
                    <img src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)} loading="lazy" alt="" />
                    <p className="c-prof__selectCharaDtl">プロフィール画像を変更できます。<br />下のリストから選んでください。</p>
                </div>
                <ul className="c-prof__selectCharaBox">
                    {Object.entries(pictureList).map(([, value]) => (
                        <li className="c-prof__selectCharaItem" key={value} onClick={(e) => handleSelectProfPicture(e)}>
                            <img src={PROFILE_PICTURE + value} loading="lazy" alt="" />
                        </li>
                    ))}
                </ul>
                <div className="c-btnWrap c-prof__selectCharaBtn">
                    <div className="c-btn c-btnWrap__size c-btn--gray" onClick={props.handleProfPicture}>キャンセル</div>
                    <div className="c-btn c-btnWrap__size c-btn--thinRed" onClick={() => handleRegistProfPicture()}>登録</div>
                </div>
            </div>
        </span>
    );
};

export default ProfPicture;
