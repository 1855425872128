/**
 * アイコン種別（サムネイル右上に表示）.
 */
import * as contentsKey from "../constants/contentsKey";
import * as contentType from "../constants/contentType";

/** 動画. */
export const VIDEO = "動画";

/** 音声. */
export const MUSIC = "音声";

/** LIVE. */
export const LIVE = "Live";

/** PARTY. */
export const PARTY = "party";

/** VR. */
export const VR = "vr";

/** テキスト. */
export const TEXT = "読み物";

/** 情報. */
export const INFO = "情報";

/** TICKET. */
export const TICKET = "Ticket";

/** NEWS. */
export const NEWS = "news";

/** グッズ. */
export const GOODS = "カート";

/** 特集. */
export const SPECIAL = "特集";

/**
 * Musicコンテンツか判定.
 */
 export const isMusic = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === MUSIC;
}

/**
 * WatchPartyコンテンツか判定.
 */
export const isParty = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === PARTY;
}

/**
 * VRコンテンツか判定.
 */
export const isVr = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === VR;
}

/**
 * テキストコンテンツか判定.
 */
export const isText = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === TEXT;
}

/**
 * 情報コンテンツか判定.
 */
export const isInfo = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === INFO;
}

/**
 * チケットコンテンツか判定.
 */
export const isTicket = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === TICKET;
}

/**
 * NEWSコンテンツか判定.
 */
export const isNews = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === NEWS;
}

/**
 * グッズコンテンツか判定.
 */
export const isGoods = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.ICON_TYPE] === GOODS;
}

/**
 * 無料アイコンを表示するか判定
 * 特典動画でなく、プレミアム動画でなく、無料コンテンツの場合
 */
export const isFreeIcon = (meta) => {
    if (contentType.isGiftVideo(meta)) return false;
    if (contentType.isPremium(meta)) return false;
    return contentType.isFree(meta);
}

/**
 * プレミアムアイコンを表示するか判定.
 * 特典動画でなく、プレミアム動画の場合
 */
 export const isPremiumIcon = (meta) => {
    if (contentType.isGiftVideo(meta)) return false;
    return contentType.isPremium(meta);
}
