import * as actionTypes from "./actionTypes";

const initState = {
    /** トップ画面判定. */
    isTop: false,
    /** 検索画面判定. */
    isSearch: false,
    /** メニューの表示/非表示. */
    isMenu: false,
    /** 通知の表示/非表示. */
    isNotice: false,
    /** プロフィールの表示/非表示. */
    isProf: false,
    /** デジタル会員証の表示/非表示. */
    isDigitalCard: false,
    /** スペシャル図鑑一覧の表示/非表示. */
    isSpecialCubeGroupList: false,
    /** スペシャル図鑑の表示/非表示. */
    isSpecialCubeGroup: false,
    /** スペシャル図鑑ID. */
    specialCubeGroupId: "",
    /** スペシャル図鑑 対象キューブにスクロールするか. */
    isSpecialCubeGroupScroll: false,
    /** データキューブ詳細の表示/非表示. */
    isDataCube: false,
    /** 追加特典取得確認の表示/非表示. */
    isGiftConfirm: false,
    /** キャンペーン一覧の表示/非表示. */
    isCampaignList: false,
    /** 退会確認画面の表示/非表示. */
    isWithdrawal: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** トップ画面に遷移. */
        case actionTypes.TOP:
            return { ...state, isTop: true, isSearch: false };

        /** 検索一覧画面に遷移. */
        case actionTypes.SEARCH:
            return { ...state, isTop: false, isSearch: true };

        /** プラネット画面に遷移. */
        case actionTypes.PLANET:
            return { ...state, isTop: false, isSearch: false };

        /** プラネット別一覧画面に遷移. */
        case actionTypes.PLANET_ID:
            return { ...state, isTop: false, isSearch: false };

        /** ライブラリ一覧画面に遷移. */
        case actionTypes.LIBRARY_ID:
            return { ...state, isTop: false, isSearch: false };

        /** イマジネーションとは画面に遷移. */
        case actionTypes.IMAGINATION:
            return { ...state, isTop: false, isSearch: false };

        /** 詳細画面（動画）に遷移. */
        case actionTypes.VIDEO_ID:
            return { ...state, isTop: false, isSearch: false };

        /** 詳細画面（ライブ）に遷移. */
        case actionTypes.LIVE_ID:
            return { ...state, isTop: false, isSearch: false };

        /** 静的コンテンツページに遷移. */
        case actionTypes.CONTENTS_ID:
            return { ...state, isTop: false, isSearch: false };

        /** 会員規約画面に遷移. */
        case actionTypes.TREMS:
            return { ...state, isTop: false, isSearch: false };

        /** プライバシーポリシー画面に遷移. */
        case actionTypes.PRIVACY:
            return { ...state, isTop: false, isSearch: false };

        /** 推奨環境画面に遷移. */
        case actionTypes.ENVIRONMENT:
            return { ...state, isTop: false, isSearch: false };

        /** 特定商法取引法画面に遷移. */
        case actionTypes.TRANSACTION:
            return { ...state, isTop: false, isSearch: false };

        /** サービス紹介画面に遷移. */
        case actionTypes.LOGGED:
            return { ...state, isTop: false, isSearch: false };

        /** 操作ガイド画面に遷移. */
        case actionTypes.OPERATION:
            return { ...state, isTop: false, isSearch: false };

        /** FAQ/問い合わせ画面に遷移. */
        case actionTypes.FAQ:
            return { ...state, isTop: false, isSearch: false };

        /** ログインLPに遷移（アプリ対応）. */
        case actionTypes.APP_LOGIN:
            return { ...state, isTop: false, isSearch: false };

        /** プランLPに遷移（アプリ対応）. */
        case actionTypes.APP_PLAN:
            return { ...state, isTop: false, isSearch: false };

        /** TVODLPに遷移（アプリ対応）. */
        case actionTypes.APP_TVOD:
            return { ...state, isTop: false, isSearch: false };

        /** 3分岐画面に遷移（アプリ対応）. */
        case actionTypes.APP_BRANCH:
            return { ...state, isTop: false, isSearch: false };

        /** プライバシーポリシー画面（アプリ対応）. */
        case actionTypes.APP_PRIVACY:
            return { ...state, isTop: false, isSearch: false };

        /** データキューブの楽しみ方画面. */
        case actionTypes.HOW_TO_CUBE:
            return { ...state, isTop: false, isSearch: false };

        /** メニューの表示/非表示. */
        case actionTypes.CHANGE_IS_MENU:
            return { ...state, isMenu: action.isMenu };

        /** 通知の表示/非表示. */
        case actionTypes.CHANGE_IS_NOTICE:
            return { ...state, isNotice: action.isNotice };

        /** プロフィールの表示/非表示. */
        case actionTypes.CHANGE_IS_PROF:
            return { ...state, isProf: action.isProf };

        /** デジタル会員証の表示/非表示. */
        case actionTypes.CHANGE_IS_DIGITAL_CARD:
            return { ...state, isDigitalCard: action.isDigitalCard };

        /** スペシャル図鑑一覧の表示/非表示. */
        case actionTypes.CHANGE_IS_SPECIAL_CUBE_GROUP_LIST:
            return { ...state, isSpecialCubeGroupList: action.isSpecialCubeGroupList };

        /** スペシャル図鑑の表示/非表示. */
        case actionTypes.CHANGE_IS_SPECIAL_CUBE_GROUP:
            return {
                ...state,
                isSpecialCubeGroup: action.isSpecialCubeGroup,
                specialCubeGroupId: action.specialCubeGroupId,
                isSpecialCubeGroupScroll: action.isSpecialCubeGroupScroll
            };

        /** データキューブ詳細の表示/非表示. */
        case actionTypes.CHANGE_IS_DATA_CUBE:
            return { ...state, isDataCube: action.isDataCube };

        /** 追加特典取得確認の表示/非表示. */
        case actionTypes.CHANGE_IS_GIFT_CONFIRM:
            return { ...state, isGiftConfirm: action.isGiftConfirm };

        /** キャンペーン一覧の表示/非表示. */
        case actionTypes.CHANGE_IS_CAMPAIGN_LIST:
            return { ...state, isCampaignList: action.isCampaignList };

        /** 退会確認画面の表示/非表示. */
        case actionTypes.CHANGE_IS_WITHDRAWAL:
            return { ...state, isWithdrawal: action.isWithdrawal };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
