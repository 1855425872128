import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as historys from "../../../historys";
import * as contentType from "../../../constants/contentType";
import * as contentsKey from "../../../constants/contentsKey";
import * as libraryId from "../../../constants/libraryId";

import styles from "./styles";

/**
 * 標準コンテンツ.
 *
 * props
 *  -> meta     コンテンツのメタ.
 *  -> playBack 視聴中バーの表示フラグ.
 */
const DefaultResume = (props) => {
    /** Hooks. */
    const history = useHistory();

    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);

    /**
     * 視聴中コンテンツの割合を計算.
     */
    const calcPlayBackWidth = () => {
        for (const resume of resumeList) {
            if (resume[contentsKey.CRID] === props.meta[contentsKey.CRID])
                return String(resume[contentsKey.STOP_POSITION] / resume[contentsKey.VIDEO_DUR] * 100);
        }
        return "0";
    }
    
    /** CSS. */
    const cssProps = {
        playBackWidth: calcPlayBackWidth() + "%"
    };
    const classes = styles(cssProps);

    /**
     * コンテンツクリック時.
     */
    const handleClick = (id) => {
        // シリーズはライブラリ一覧に遷移.
        if (props.meta[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(id);
            historys.historylibraryId(history, historys.getEncodeId(list));
        }

        // 映像詳細画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.CONTENT_TYPE] === contentType.VIDEO))
            historys.historyVideoId(history, id);

        // ライブ画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.LIVE)
            historys.historyLiveId(history, id);

        // 静的コンテンツページに遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.WEB)
            historys.historyContents(history, id);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    return (
        <div
            className="c-contCard"
            onClick={() => handleClick(props.meta[contentsKey.CRID])}
            onContextMenu={(event) => handleContextMenu(event)}
        >  
            <div>
                <span className="c-contCard__inner">
                    {/* ステータスアイコン（サムネイル左上に表示）. */}
                    {/* 視聴中アイコン. */}
                    <div className="c-contCard__tag c-tag c-tag--view">
                        <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                    </div>
                    <div className="c-contCard__photo">
                        <picture>
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                media="(min-width:640px)"
                                type="image/webp" />
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                            <img className="lozad loaded" src={props.meta[contentsKey.THUMBNAIL_L]} alt="" loading="lazy" />
                        </picture>
                        {props.playBack &&
                            <div className="c-contCard__playback">
                                <div className={classes.playback}></div>
                            </div>
                        }
                    </div>
                    <div className="c-contCard__meta">
                        <span className="c-contCard__name js-textLimit" data-sp-truncation="2" data-truncation="1">
                            {/* アイテムテキスト. （動画・情報アイテム以外、テキスト先頭に表示）*/}
                            {props.meta[contentsKey.ICON_TYPE]!=null && props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                            <span class="c-icon c-icon--rect">
                                <span class="c-icon__Txt">{props.meta[contentsKey.ICON_TYPE]}</span>
                            </span>
                            }
                            {/* ママイキとタイトルにスペース追加 */}
                            <span>{props.meta[contentsKey.ICON_TYPE]!=null && " "}{props.meta[contentsKey.TITLE]}</span>
                        </span>
                    </div>
                </span>
            </div>
        </div>
    );
};

export default DefaultResume;
