import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import * as historys from "../../../historys";
import * as contentsKey from "../../../constants/contentsKey";
import * as contentType from "../../../constants/contentType";
import * as iconType from "../../../constants/iconType";
import * as libraryId from "../../../constants/libraryId";
import * as recommendif from "../../../middleware/recommendif";
import useRecommendCookie from "../../../hooks/useRecommendCookie";

import styles from "./styles";


/**
 * 標準リストコンテンツ.
 *
 * props
 *  -> meta   コンテンツのメタ.
 *  -> isGrid グリッド表示フラグ.
 */
const DefaultList = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const history = useHistory();

    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /**
     * コンテンツクリック時.
     */
    const handleClick = (id) => {
        // レコメンドコンテンツの場合は、レコメンドクリック通知APIを呼び出す.
        if (props.clickData && props.clickData !== "") {
            let request = {
                [recommendif.CRID]: props.meta[contentsKey.CRID],
                [recommendif.SPEC]: props.clickData[recommendif.SPEC],
                [recommendif.CREF]: props.clickData[recommendif.RQID],
                [recommendif.RECOMMENDCOOKIE]: recommendCookie
            };
            recommendif.postRecommendClick(request).then(null, null);
        }

        // シリーズはライブラリ一覧に遷移.
        if (props.meta[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(id);
            historys.historylibraryId(history, historys.getEncodeId(list));
        }

        // 映像詳細画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.CONTENT_TYPE] === contentType.VIDEO))
            historys.historyVideoId(history, id);

        // ライブ画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.LIVE)
            historys.historyLiveId(history, id);

        // 静的コンテンツページに遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.WEB)
            historys.historyContents(history, id);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    return (
        <div>
            {/* リスト表示 */}
            {!props.isGrid &&
                <div
                    onClick={() => handleClick(props.meta[contentsKey.CRID])}
                    onContextMenu={(event) => handleContextMenu(event)}
                    className={[
                        classes.root,
                        "p-searchList__itemCard"
                    ].join(" ")}
                >
                    <div className="p-searchList__dis">
                        <div className="p-searchList__inner">
                            <div className="p-searchList__photo">
                                <div className="p-searchList__photoInner">
                                    <img
                                        className="lozad loaded"
                                        src={props.meta[contentsKey.THUMBNAIL_P]}
                                        data-src={props.meta[contentsKey.THUMBNAIL_P]}
                                        alt=""
                                        loading="lazy"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="p-searchList__detail">
                            {props.meta[contentsKey.LIST_FLG] && <div className="p-searchList__ttl">{props.meta[contentsKey.TITLE]}</div>}
                            {/* アイテムテキスト. （動画・情報アイテム以外）*/}
                            {!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.ICON_TYPE]!=null && props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                                <span className="c-icon c-icon--rect">
                                    <span className="c-icon__Txt">
                                        {/* 英文字の場合、小文字チェック後大文字出力*/}
                                        {props.meta[contentsKey.ICON_TYPE] === "カート" ? "買い物" : props.meta[contentsKey.ICON_TYPE].toUpperCase()}
                                    </span>
                                </span>
                            }
                            {(!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.TITLE] === null || props.meta[contentsKey.TITLE] === ""))
                                && <div className="p-searchList__ttl">{props.meta[contentsKey.EPI_TITLE]}</div>}
                            {(!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.TITLE] !== null && props.meta[contentsKey.TITLE] !== "")
                                && <div className="p-searchList__ttl">{props.meta[contentsKey.TITLE]}　{props.meta[contentsKey.EPI_TITLE]}</div>}
                        </div>
                    </div>
                    {/* ステータスアイコン（サムネイル左上に表示）. */}
                    {/* 視聴中アイコン. */}
                    {contentType.isResume(props.meta, resumeList) &&
                        <div className="p-searchList__tag c-tag c-tag--view">
                            <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                        </div>
                    }

                    {/* 特典映像アイコン. */}
                    {(!contentType.isResume(props.meta, resumeList) && contentType.isGiftVideo(props.meta)) &&
                        <div className="p-searchList__tag c-tag c-tag--special">
                            <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                        </div>
                    }

                    {/* プレミアムアイコン. */}
                    {(!contentType.isResume(props.meta, resumeList) && iconType.isPremiumIcon(props.meta)) &&
                        <div className="p-searchList__tag c-tag c-tag--premium">
                            <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                        </div>
                    }

                    {/* 無料アイコン. */}
                    {(!contentType.isResume(props.meta, resumeList) && iconType.isFreeIcon(props.meta)) &&
                        <div className="p-searchList__tag c-tag c-tag--free">
                            <div className="c-icon__Txt c-icon__Txt--free">無料</div>
                        </div>
                    }
                </div>
            }
            {/* グリッド表示 */}
            {props.isGrid &&
                <div
                    onClick={() => handleClick(props.meta[contentsKey.CRID])}
                    onContextMenu={(event) => handleContextMenu(event)}
                    className={[
                        classes.root,
                        "c-contCard"
                    ].join(" ")}
                >
                    <span className="c-contCard__inner">
                        {/* ステータスアイコン（サムネイル左上に表示）. */}
                        {/* 視聴中アイコン. */}
                        {contentType.isResume(props.meta, resumeList) &&
                            <div className="c-contCard__tag c-tag c-tag--view">
                                <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                            </div>
                        }

                        {/* 特典映像アイコン. */}
                        {(!contentType.isResume(props.meta, resumeList) && contentType.isGiftVideo(props.meta)) &&
                            <div className="c-contCard__tag c-tag c-tag--special">
                                <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                            </div>
                        }

                        {/* プレミアムアイコン. */}
                        {(!contentType.isResume(props.meta, resumeList) && iconType.isPremiumIcon(props.meta)) &&
                            <div className="c-contCard__tag c-tag c-tag--premium">
                                <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                            </div>
                        }

                        {/* 無料アイコン. */}
                        {(!contentType.isResume(props.meta, resumeList) && iconType.isFreeIcon(props.meta)) &&
                            <div className="c-contCard__tag c-tag c-tag--free">
                                <div className="c-icon__Txt c-icon__Txt--free">無料</div>
                            </div>
                        }
                        <div className="c-contCard__photo">
                            <img
                                className="lozad loaded"
                                src={matches ? props.meta[contentsKey.THUMBNAIL_L] : props.meta[contentsKey.THUMBNAIL_P]}
                                data-src={matches ? props.meta[contentsKey.THUMBNAIL_L] : props.meta[contentsKey.THUMBNAIL_P]}
                                alt="" 
                                loading="lazy"
                            />
                        </div>
                        <div className="c-contCard__meta">
                            <span className="c-contCard__name js-textLimit" data-sp-truncation="2" data-truncation="1">
                                {/* アイテムテキスト. （動画・情報アイテム以外、テキスト先頭に表示）*/}
                                {props.meta[contentsKey.ICON_TYPE]!=null && props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                                <span className="c-icon c-icon--rect">
                                    <span className="c-icon__Txt">
                                        {/* 英文字の場合、小文字チェック後大文字出力*/}
                                        {props.meta[contentsKey.ICON_TYPE] === "カート" ? "買い物" : props.meta[contentsKey.ICON_TYPE].toUpperCase()}
                                    </span>
                                </span>
                                }
                                {/* ママイキとタイトルにスペース追加 */}
                                {props.meta[contentsKey.ICON_TYPE]!=null && " "}{props.meta[contentsKey.TITLE]}
                            </span>
                        </div>
                    </span>
                </div>
            }
        </div>
    );
};

export default DefaultList;
