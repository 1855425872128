import * as actionTypes from "./actionTypes";

/**
 * トップ画面に遷移.
 */
export const top = () => {
    return {
        type: actionTypes.TOP
    };
}

/**
 * 検索一覧画面に遷移.
 */
export const search = () => {
    return {
        type: actionTypes.SEARCH
    };
}

/**
 * プラネット画面に遷移.
 */
export const planet = () => {
    return {
        type: actionTypes.PLANET
    };
}

/**
 * プラネット別一覧画面に遷移.
 */
export const planetId = () => {
    return {
        type: actionTypes.PLANET_ID
    };
}

/**
 * ライブラリ一覧画面に遷移.
 */
export const libraryId = () => {
    return {
        type: actionTypes.LIBRARY_ID
    };
}

/**
 * イマジネーションとは画面に遷移.
 */
export const imagination = () => {
    return {
        type: actionTypes.IMAGINATION
    };
}

/**
 * 詳細画面（動画）に遷移.
 */
export const videoId = () => {
    return {
        type: actionTypes.VIDEO_ID
    };
}

/**
 * 詳細画面（Live）に遷移.
 */
export const liveId = () => {
    return {
        type: actionTypes.LIVE_ID
    };
}

/**
 * 静的コンテンツページに遷移.
 */
export const contentsId = () => {
    return {
        type: actionTypes.CONTENTS_ID
    };
}

/**
 * 会員規約画面に遷移.
 */
export const trems = () => {
    return {
        type: actionTypes.TREMS
    };
}

/**
 * プライバシーポリシー画面に遷移.
 */
export const privacy = () => {
    return {
        type: actionTypes.PRIVACY
    };
}

/**
 * 推奨環境画面に遷移.
 */
export const environment = () => {
    return {
        type: actionTypes.ENVIRONMENT
    };
}

/**
 * 特定商法取引法画面に遷移.
 */
export const transaction = () => {
    return {
        type: actionTypes.TRANSACTION
    };
}

/**
 * サービス紹介画面に遷移.
 */
export const logged = () => {
    return {
        type: actionTypes.LOGGED
    };
}

/**
 * 操作ガイド画面に遷移.
 */
export const operation = () => {
    return {
        type: actionTypes.OPERATION
    };
}

/**
 * FAQ/問い合わせ画面に遷移.
 */
export const faq = () => {
    return {
        type: actionTypes.FAQ
    };
}

/**
 * ログインLPに遷移（アプリ対応）.
 */
export const appLogin = () => {
    return {
        type: actionTypes.APP_LOGIN
    };
}

/**
 * プランLPに遷移（アプリ対応）.
 */
export const appPlan = () => {
    return {
        type: actionTypes.APP_PLAN
    };
}

/**
 * TVODLPに遷移（アプリ対応）.
 */
export const appTvod = () => {
    return {
        type: actionTypes.APP_TVOD
    };
}

/**
 * 3分岐画面に遷移（アプリ対応）.
 */
export const appBranch = () => {
    return {
        type: actionTypes.APP_BRANCH
    };
}

/**
 * プライバシーポリシー画面（アプリ対応）.
 */
export const appPrivacy = () => {
    return {
        type: actionTypes.APP_PRIVACY
    };
}

/**
 * データキューブの楽しみ方画面.
 */
export const howtocube = () => {
    return {
        type: actionTypes.HOW_TO_CUBE
    };
}

/**
 * メニューの表示/非表示.
 */
export const changeIsMenu = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_MENU,
        isMenu: flag
    };
}

/**
 * 通知の表示/非表示.
 */
export const changeIsNotice = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_NOTICE,
        isNotice: flag
    };
}

/**
 * プロフィールの表示/非表示.
 */
export const changeIsProf = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_PROF,
        isProf: flag
    };
}

/**
 * デジタル会員証の表示/非表示.
 */
export const changeIsDigitalCard = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_DIGITAL_CARD,
        isDigitalCard: flag
    };
}

/**
 * スペシャル図鑑一覧の表示/非表示.
 */
export const changeIsSpecialCubeGroupList = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_SPECIAL_CUBE_GROUP_LIST,
        isSpecialCubeGroupList: flag
    };
}

/**
 * スペシャル図鑑の表示/非表示.
 */
export const changeIsSpecialCubeGroup = (flag, id, scroll) => {
    return {
        type: actionTypes.CHANGE_IS_SPECIAL_CUBE_GROUP,
        isSpecialCubeGroup: flag,
        specialCubeGroupId: id,
        isSpecialCubeGroupScroll: scroll
    };
}

/**
 * データキューブ詳細の表示/非表示.
 */
export const changeIsDataCube = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_DATA_CUBE,
        isDataCube: flag
    };
}

/**
 * 追加特典取得確認の表示/非表示.
 */
export const changeIsGiftConfirm = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_GIFT_CONFIRM,
        isGiftConfirm: flag
    };
}

/**
 * キャンペーン一覧の表示/非表示.
 */
export const changeIsCampaignList = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_CAMPAIGN_LIST,
        isCampaignList: flag
    };
}

/**
 * 退会確認画面の表示/非表示.
 */
export const changeIsWithdrawal = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_WITHDRAWAL,
        isWithdrawal: flag
    };
}
