import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    root: {
        cursor: "pointer"
    },
    get: {
        width: "100%",
        maxWidth: 60,
        position: "absolute",
        top: "calc(50% + 5px)",
        left: "50%",
        transform: "translate(-50%,-50%)",
        animation: "$fadeIn 1.5s"
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
            maxWidth: 160
        },
        "70%": {
            opacity: 1
        },
        "95%": {
            maxWidth: 54
        },
        "100%": {
            maxWidth: 60
        }
    }
}));

export default styles;
