import React, { useEffect } from "react";
import Parallax from "react-rellax";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { DEFAULT_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * 404ページ.
 */
const Default = () => {

    useEffect(() => {
        // head設定.
        setHeadDefault(DEFAULT_TITLE);
    }, []);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="p-notpage">
                    <div className="p-notpage__txt">お探しのページが<br/>見つかりませんでした。</div>
                    <img src="/assets/images/img-404.png" alt="" />
                </div>
            </div>
        </div>
    );
}

export default Default;
