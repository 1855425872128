import * as actions from "./actions";
import * as credit from "../../../constants/credit";
import * as constantsStatic from "../../../constants/static";
import { doLogout } from "../../../components/dialog/Logout";
import * as payment from "../../../middleware/payment";
import * as accountif from "../../../middleware/accountif";
import * as authif from "../../../middleware/authif";
import { savePrevPage } from "../../../components/screen/cushion/actions";
import { saveCreditInfo } from "../../status/localCredit/actions";
import { updateMemberInfo } from "../../status/member/actions";
import { getContractManageSuccess } from "../../init";

/** クレジットカード新規登録必要. */
export const CARD_NEW = "new";

/** クレジットカード有効期限切れ. */
export const CARD_EXPIRED = "expired";

/** クレジットカード登録済み. */
export const CARD_REGISTERED = "registered";

/** クレジットカード情報取得エラー. */
export const CARD_ERROR = "error";

/** プラン契約. */
export const PLAN = "plan";

/** レンタル契約. */
export const RENTAL = "rental";

/** プラン契約（キャンペーン）. */
export const PLAN_CAMPAIGN = "planCampaign";

/** 確認のみ. */
export const ONLY = "Only";

/** 有効期限切れ. */
export const EXPIRED = "Expired";

/**
 * クレカ連携API(登録カード情報取得)を呼び出し.
 *
 * dispatch     dispatch.
 * creditNumber 登録済みのクレカ番号.
 */
export const getCredit = (dispatch, creditNumber) => {
    return new Promise((resolve, reject) => {
        // クレカ連携APIを実行
        payment.postPayment(`Command=${payment.GET_CARD_USR_INFO}`)
        .then((res) => {
            // クレカ連携API(登録カード情報取得)成功.

            // セッションエラー確認.
            if (payment.isSessionError(dispatch, res)) {
                return;
            }

            // クレカ連携API(内部処理)の実行結果が"0" 以外は取得失敗なのでエラーを返却.
            if (res.Output !== undefined && res.Output.Result[0] !== "0") reject(CARD_ERROR);

            // クレカ連携API(外部通信)の実行結果が"0" 以外は取得失敗なのでエラーを返却.
            if (res.OutputCardUserInfoList.Result[0] !== "0") reject(CARD_ERROR);

            const cardUserInfo = res.OutputCardUserInfoList.CardUserInfo[0];

            // クレカが登録されていない場合.
            if (cardUserInfo.CardlistId[0] === "") {
                resolve(CARD_NEW);
            }

            // 有効期限切れの場合.
            else if (!checkExpDate(cardUserInfo.ExpDate[0])) {
                dispatch(actions.saveCardlistId(cardUserInfo.CardlistId[0]));
                resolve(CARD_EXPIRED);
            }

            // クレカが登録されている場合.
            else {
                // クレカ連携APIで取得したクレカ番号とReducerのクレカ番号が異なる場合、同期を取る.
                if (creditNumber !== cardUserInfo.CardNum[0]) {
                    accountif.postUserInfo({"cardNumber": cardUserInfo.CardNum[0]}).then(
                        (data) => dispatch(updateMemberInfo(data)),
                        accountif.isSessionError.bind(this, dispatch)
                    );
                }

                dispatch(actions.saveCardlistId(cardUserInfo.CardlistId[0]));
                resolve(CARD_REGISTERED);
            }
        }, () => {
            // クレカ連携API(登録カード情報取得)失敗.

            reject(CARD_ERROR);
        });
    })
}

/**
 * クレカ有効期限チェック.
 *
 * expDate クレカ有効期限.
 */
const checkExpDate = (expDate) => {
    if (!expDate) return false;

    // YYYYMM -> YYYY-MM-01 00:00:00 に変換.
    let expTime = expDate.substr(0, 4) + "/" + expDate.substr(4, 2) + "/01 00:00:00";
    expTime = new Date(Date.parse(expTime));

    // 有効期限の1日後(ex. 202103だと、2021-04-01 00:00:00).
    expTime.setMonth(expTime.getMonth() + 1);

    // 現在時刻より大きければ、有効期限がまだ切れていないと判定.
    return expTime.getTime() > Date.now();
}

/**
 * 決済完了時、ユーザ情報を更新する.
 * dispatch dispatch.
 * res      契約管理APIのレスポンス.
 */
export const updateInfo = (dispatch, res, noneReadMap = {}) => {
    getContractManageSuccess(dispatch, noneReadMap, res);
    accountif.getUserInfo().then(accountif.getUserInfoSuccess.bind(this, dispatch), doLogout.bind(this, dispatch));
}

/**
 * クレジットカード番号のフォーマット変更（Space）.
 */
export const getCreditNumberSpace = (num) => {
    let result = "";
    for (let i = 0; i < num.length; i++) {
        if (i !== 0 && i % 4 === 0) result += " ";
        result += num.charAt(i);
    }
    return result;
}

/**
 * クレジットカード番号のフォーマット変更（Hyphen）.
 */
export const getCreditNumberHyphen = (num) => {
    let result = "";
    for (let i = 0; i < num.length; i++) {
        if (i !== 0 && i % 4 === 0) result += "-";
        result += num.charAt(i);
    }
    return result.replace(/\*/g, "X");
}

/**
 * クレカ情報の状態によって表示する決済画面の種類を変更.
 *
 * dispatch   dispatch.
 * location   location.
 * kind       決済画面種別.
 * plan       変更後のプラン.
 * amount     プラン/レンタル金額.
 * haveCredit クレカ登録済みかどうか.
 */
export const goPopupCredit = (dispatch, location, kind, plan, amount, haveCredit) => {
    switch(kind) {
        case PLAN:
        case RENTAL:
        case PLAN_CAMPAIGN:
            if (haveCredit) {
                dispatch(saveCreditInfo({plan: plan, amount: amount, kind: kind}));

                const couponInput = document.getElementsByClassName(constantsStatic.COUPON_CODE);

                if (couponInput.length > 0 && couponInput[0].value !== "") {
                    dispatch(savePrevPage(location.pathname + "?coupon=" + couponInput[0].value));
                }
                else{
                    dispatch(savePrevPage(location.pathname + location.search));
                }
                authif.postLogin({[authif.TYPE]: authif.WEB_DOUBLE, [authif.DESTINATION]: authif.TSUBURAYA});
            }
            else {
                dispatch(actions.openPopupInputCredit(kind, plan, amount));
            }
            break;
        default:
            if (haveCredit) {
                dispatch(actions.openPopupInputCredit(credit.CHANGE, plan, amount));
            }
            else {
                dispatch(actions.openPopupInputCredit(credit.REGISTER, plan, amount));
            }
    }
}

/**
 * 無料キャンペーン期間表示.
 * freePeriod 無料期間.
 * ・1ヵ月→初月無料
 * ・n（n >= 2）ヵ月→（n-1）ヵ月無料
 */
export const showFreePeriod = (freePeriod) => {
    if (freePeriod === "1") {
        return "初月利用が無料となります";
    }
    else {
        if (freePeriod && freePeriod !== "") {
            return (parseInt(freePeriod, 10) - 1) + "ヶ月利用が無料となります";
        }
        else{
            return "";
        }
    }
}