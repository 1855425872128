import React, { useEffect } from "react";

/**
 * WARNING（contentをリスト指定して複数行で表示）ダイアログ.
 *
 * props
 *  -> input:title       タイトル.
 *  -> input:contentList 内容（文字列をリストで指定）.
 *  -> onAccept          「はい」押下時の処理.
 */
const WarningContentList = (props) => {
    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        return () => {
            document.body.classList.remove("is-fixed");
        }
    }, []);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * 「はい」押下時の処理.
     */
    const onAccept = () => {
        props.onAccept();
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__icon p-popup__icon--dialog">
                        <img src="/assets/images/icon-attention-comp-yellow.png" alt="" />
                    </div>
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{props.input.title}</div>
                            <div className="p-popup__txt">{props.input.contentList.map(content => <>{content}<br/></>)}
                            </div>
                            <div className="p-popup__aplBtn c-btn c-btn--thinRed" onClick={() => onAccept()}>OK</div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default WarningContentList;
