import React from "react";
import { useSelector } from "react-redux";
import Campaign from "../../components/contents/campaign/Campaign";

/**
 * キャンペーン一覧.
 *
 * props
 *  -> handleCampaignList キャンペーン一覧の非表示
 */
const CampaignList = (props) => {
    /** キャンペーン一覧. */
    const campaignList = useSelector(state => state.Member.campaignList);

    return (
        <span>
            <div className="c-prof__digitalCard__head">
                <div className="c-back c-back--datacube" onClick={props.handleCampaignList}>
                    <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                    <div className="c-back__txt">もどる</div>
                </div>
            </div>
            <div className="c-prof__coupon__headBg"></div>
            <div className="c-prof__couponTtl">
                <div className="c-prof__couponTtlTxt">参加・取得キャンペーン一覧</div>
            </div>
            <ul className="c-prof__couponBox">
                {campaignList.length === 0 &&
                    <li className="c-prof__couponItemNoContents">参加・取得されたキャンペーンはありません。</li>
                }
                {campaignList.length > 0 &&
                    Object.entries(campaignList).map(([, value]) => (
                        <Campaign meta={value} />
                    ))
                }
            </ul>
        </span>
    );
};

export default CampaignList;
