/**
 * CV対応.
 */

/** プラン登録時. */
export const SIGN_UP = "sign_up";

/** プランアップグレード時. */
export const UP_SELL = "up_sell";

/** プランダウングレード予約時. */
export const DOWN_SELL = "down_sell";

/** プラン退会（予約）時. */
export const UNSUBSCRIBE = "unsubscribe";

/** クーポン償還イベント. */
const COUPON_USE = "coupon_use";

/** クーポン名（実際はキャンペーン名）. */
const COUPON_NAME = "coupon_name";

/** クーポンID. */
const COUPON_ID = "coupon_id";

/** event key. */
const EVENT_KEY = "event";

/** contract_plan key. */
const CONTRACT_PLAN_KEY = "contract_plan";

/** details key. */
const DETAILS = "details";

/** 検索イベント. */
const SEARCH = "search";

/** search_term key. */
const SEARCH_TERM_KEY = "search_term";

/** ページの情報. */
const DISP_PAGE_INFO = "disp_page_info";

/** ページのURL. */
const DISP_PAGE_URL = "disp_page_url";

/** ページのタイトル. */
const DISP_PAGE_TITLE = "disp_page_title";

/** ユーザーID. */
const USER_ID = "user_id";

/**
 * プラン登録・変更時発火.
 */
export const sendPlanCV = (planEvent, contractPlan) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({[EVENT_KEY]: planEvent, [CONTRACT_PLAN_KEY]: contractPlan});
}

/**
 * クーポン償還時発火.
 */
export const sendCouponCV = (couponName, couponId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        [EVENT_KEY]: COUPON_USE,
        [DETAILS]: {
            [COUPON_NAME]: couponName,
            [COUPON_ID]: couponId
        }
    });
}

export const sendSearchKeywords = (keywords) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({[EVENT_KEY]: SEARCH, [SEARCH_TERM_KEY]: keywords})
}

/**
 * コンテンツ詳細画面に遷移時発火.
 */
 export const sendContentInfo = (dispPageUrl, dispPageTitle, userId) => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
        [EVENT_KEY]: DISP_PAGE_INFO,
        [DETAILS]: {
            [DISP_PAGE_URL]: dispPageUrl,
            [DISP_PAGE_TITLE]: dispPageTitle,
            [USER_ID]: userId
        }
    });
}