/** コンテンツ取得 成功. */
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";

/** コンテンツ情報更新. */
export const CONTENTS_UPDATE_CONTENTS = "CONTENTS_UPDATE_CONTENTS";

/** シリーズタイトル更新. */
export const CONTENTS_UPDATE_SERIES_TITLE = "CONTENTS_UPDATE_SERIES_TITLE";

/** シリーズ一覧更新. */
export const CONTENTS_UPDATE_CONTENTS_LIST = "CONTENTS_UPDATE_CONTENTS_LIST";

/** クーポン利用状況チェック設定. */
export const CONTENTS_SET_COUPON_CHECK = "CONTENTS_SET_COUPON_CHECK";

/** レコメンドコンテンツ一覧更新. */
export const CONTENTS_UPDATE_RECOMMEND_CONTENTS_LIST = "CONTENTS_UPDATE_RECOMMEND_CONTENTS_LIST";

/** コンテンツリセット. */
export const RESET_CONTENT = "RESET_CONTENT";