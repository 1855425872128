import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Parallax from "react-rellax";
import { decode } from "js-base64";
import styles from "./styles"

import useMediaQuery from "@material-ui/core/useMediaQuery";

import DefaultList from "../../contents/defaultList/DefaultList";
import * as contentif from "../../../middleware/contentif";
import * as fileApi from "../../../middleware/file";
import * as searchCategory from "../../../constants/searchCategory";
import * as contentsKey from "../../../constants/contentsKey";
import * as app from "../../../constants/app";
import * as actions from "./actions";
import * as commonActions from "../../../common/status/actions";
import * as memberActions from "../../../common/status/member/actions";
import * as screenActions from "../../../common/status/screen/actions";
import { changeGridList } from "../../../common/status/grid/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { PLANET_ID_FAILURE_TITLE, ULTRA_SUBSC } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";
import * as planetIdContents from "../../../constants/planetId"
import {DISPLAY_COUNT,SESSIONVAL,SCROLLINTERVAL} from "../../../constants/separatorLimit"


/**
 * プラネット別一覧画面.
 */
const PlanetId = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();

    const classes = styles();

    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 576px)");

    /** CategoryDOM. */
    const categoryRef = useRef();
    /** AllDOM. */
    const worksRef = useRef();
    /** GridボタンDOM. */
    const iconGridRef = useRef();
    /** ListボタンDOM. */
    const iconListRef = useRef();
    /** ドロップメニューDOM. */
    const dropMenuRef = useRef();
    /** ドロップメニューボタンDOM. */
    const btnDropMenuRef = useRef();
    /** カテゴリーDOM. */
    const searchCategoryRef = useRef();
    /** すべての作品DOM. */
    const searchWorksRef = useRef();

    /** プラネットID. */
    const [planetId, setPlanetId] = useState("");
    /** ドロップメニューの表示/非表示. */
    const [dropMenu, setDropMenu] = useState(false);
    /** カテゴリーの表示/非表示. */
    const [category, setCategory] = useState(false);
    /** プランの表示/非表示. */
    const [plan, setPlan] = useState(false);
    /** 検索実行フラグ. */
    const [isSearch, setIsSearch] = useState(true);
    /** 画面表示件数. */
    const [rowCount, setRowCount] = useState(contentif.ROW_DEFAULT);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** Planet情報. */
    const planet = useSelector(state => state.PlanetId.planet);
    /** 選択中カテゴリー. */
    const currentCategory = useSelector(state => state.PlanetId.currentCategory);
    /** 選択中プラン. */
    const currentPlan = useSelector(state => state.PlanetId.currentPlan);
    /** グリッド表示/リスト表示. */
    const isGrid = useSelector(state => state.Grid.isGrid);

    /** . */
    let saveMethod = [];
    const checkStorage =sessionStorage.getItem("planet")

    /** カテゴリ格納. */
    let categoryMethod =""
    /** プラン格納 */
    let planMethod =""

    /** back. */
    const backInfo = useSelector(state => state.PlanetId.back);


    /** TOPセパレータ表示フラグ */
    const [topCategoryFlg,setTopCategoryFlg]= useState(true)

    /** 大カテゴリセパレータ表示フラグ */
    const [middleCategoryFlg,setMiddleCategoryFlg]= useState(false)

    /** 中カテゴリセパレータ表示フラグ */
    const [bottomCategoryFlg,setBottomCategoryFlg]= useState(false)



    const [videoFlgOn,setVideoFlgOn] = useState(false)
    const [sentenceFlgOn,setSentenceFlgOn] = useState(false)
    const [soundFlgOn,setSoundFlgOn] = useState(false)
    const [newsFlgOn,setNewsFlgOn] = useState(false)



    const middleCategoryLeng = ["C10","C20","C30","C40"]
    const videoLeng = ["C11","C12","C13","C14","C15","C16","C17","C18","C19"]
    const soundLeng = ["C22","C24"]
    const sentenceLeng = ["C31","C32","C33","C34","C35","C36","C37"]
    const newsLeng = ["C41","C42","C43","C44"]

    const [middleData,setMiddleData] = useState([])
    const [middleDataCategory,setMiddleDataCategory] = useState("")
    const [noneData,setNoneData] = useState(false)

    const ArrayContents = {
        C10 : [],
        C20 : [],
        C30 : [],
        C40 : [],
        C11 : [],
        C12 : [],
        C13 : [],
        C14 : [],
        C15 : [],
        C16 : [],
        C17 : [],
        C18 : [],
        C19 : [],

        C22 : [],
        C24 : [],

        C31 : [],
        C32 : [],
        C33 : [],
        C34 : [],
        C35 : [],
        C36 : [],
        C37 : [],

        C41 : [],
        C42 : [],
        C43 : [],
        C44 : [],}

    const [contents ,setContents] = useState( ArrayContents)

    const deleteflg = useRef(false)

    const noneCount = useRef(0)

    const [firstScroll , setFirstScroll] = useState(false)
    
    /**
     * ページの高さ（ブラウザ間のページ全体の高さをカバーする）.
     */
    const scrollHeight = () => {
        return Math.max(
            document.body.scrollHeight, document.documentElement.scrollHeight,
            document.body.offsetHeight, document.documentElement.offsetHeight,
            document.body.clientHeight, document.documentElement.clientHeight
        );
    }

    /**
     * コンテンツ取得 失敗.
     */
    const requestFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * ファイル：プラネット定義取得 成功.
     */
    const getPlanetSuccess = useCallback((id, data) => {
        dispatch(memberActions.updatePlanetList(data));
        setPlanetId(id);
        for (const plt of data) {
            if (plt[contentsKey.PLANET_ID] === id) dispatch(actions.updatePlanet(plt));
        }
    }, [dispatch]);

    /**
     * 追加検索API 成功.
     */
    const scrollSearchSuccess = (data) => {
        // 連続検索用.
        setIsSearch(true);
        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);

        // 追加検索表示件数を増やす.
        if (data.length === contentif.ROW_DEFAULT) setRowCount(rowCount + contentif.ROW_DEFAULT);

        // リストに追加.
        if (data.length > 0) {
            let list = [];
            for (let i = 0; i < middleData.length; i++) list.push(middleData[i]);
            for (let i = 0; i < data.length; i++) list.push(data[i]);
            setMiddleData(list)
        }
    };

    /**
     * 検索API 成功.
     */
    const searchSuccess = useCallback((data,category) => {
        // 追加検索無しにする.
        if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);
        dispatch(actions.updatePlanetIdContentsList(data));

    }, [dispatch]);

    /**
     * 追加検索する条件.
     */
    const addScrollJudge = () => {
        if (bottomCategoryFlg) {
            if (app.isAllApp()) {
                // フッター位置までスクロールしたら追加検索を実施.
                const footerHeight = document.querySelector("footer")?.offsetHeight;
                return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
            }
            else {
                // フッター位置までスクロールしたら追加検索を実施.
                const footerHeight = document.getElementsByClassName("p-footCont")?.[0]?.offsetHeight;
                return footerHeight && scrollHeight() - window.scrollY - window.innerHeight <= footerHeight;
            }
        }
    };

    /**
     * 追加検索.
     */
    const scrollSearch =  () => {
        if (addScrollJudge()) {
            if (isSearch && middleData.length > 0) {
                // 連続検索防止.
                setIsSearch(false);

                // 現在のカテゴリ・プランで検索.
                let request = {
                    [contentif.SEARCH_TYPE]: contentif.OPTION,
                    [contentif.RANGE_START]: rowCount + 1,
                    [contentif.ROW]: contentif.ROW_DEFAULT,
                    [contentif.PLANET_ID]: [planetId],
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                };
                if (currentCategory !== "C00") request[contentif.CATEGORY_ID] = searchCategory.getSearchCategory(currentCategory);
                if (currentPlan !== "P00") request[contentif.PLAN] = memberPlan;
                contentif.postSearch(request).then(scrollSearchSuccess, requestFailure);
            }
        }
    }

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.planetId());
        window.addEventListener("scroll", scrollSearch);
        return () => {
            window.removeEventListener("scroll", scrollSearch);
        }
    }, [dispatch, scrollSearch]);

    /**
     * プラネット定義ファイルを取得.
     */
    useEffect(() => {
        // URLデコードとbase64デコードを行う.
        const splitPathname = location.pathname.split("/");
        const id = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
        // ファイル：プラネット定義.
        fileApi.getPlanet().then(getPlanetSuccess.bind(this, id), requestFailure);
        return () => dispatch(actions.initPlanetId());
    }, [dispatch, location, getPlanetSuccess, requestFailure]);



    /**
     * 検索実行.
     */
    useEffect(() => {
        if (planetId !== "") {
            // 現在のカテゴリ・プランで検索.
            let request = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.ROW]: contentif.ROW_MIDDLECATEGORY,
                [contentif.PLANET_ID]: [planetId],
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            if (currentCategory !== "C00") request[contentif.CATEGORY_ID] = searchCategory.getSearchCategory(currentCategory);
            if (currentPlan !== "P00") request[contentif.PLAN] = memberPlan;
            
            
            const categoryKey =searchCategory.getSearchCategory(currentCategory);
            if(!currentCategory.endsWith("0")){
                request.row = contentif.ROW_DEFAULT
            }
        deleteflg.current = false
        noneCount.current = 0
        setNoneData(false)
        if(categoryKey.endsWith("全カテゴリー")){
            for(let i =0;i<middleCategoryLeng.length;i++){
                request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(middleCategoryLeng[i]);
                contentif.postSearch(request).then(data=>listOn(data,middleCategoryLeng[i],middleCategoryLeng.length),requestFailure); 
            }
            
        }
        else if(categoryKey.endsWith("動画")){
            for(let i =0;i<videoLeng.length;i++){
                request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(videoLeng[i]);
                contentif.postSearch(request).then(data=>listOn(data,videoLeng[i],videoLeng.length),requestFailure);
            }
        }
        else if(categoryKey.endsWith("音声")){
            for(let i =0;i<soundLeng.length;i++){
                request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(soundLeng[i]);
                contentif.postSearch(request).then(data=>listOn(data,soundLeng[i],soundLeng.length),requestFailure);
            }
        }
        else if(categoryKey.endsWith("読み物")){
            for(let i =0;i<sentenceLeng.length;i++){
                request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(sentenceLeng[i]);
                contentif.postSearch(request).then(data=>listOn(data,sentenceLeng[i],sentenceLeng.length),requestFailure);
            }
        }
        else if(categoryKey.endsWith("ニュース")){
            for(let i =0;i<newsLeng.length;i++){
                request[contentif.CATEGORY_ID] =searchCategory.getSearchCategory(newsLeng[i]);
                contentif.postSearch(request).then(data=>listOn(data,newsLeng[i],newsLeng.length),requestFailure);
            }
        }
        else{
            contentif.postSearch(request).then(data=>listOn(data,currentCategory,1,true),requestFailure);
        }
    }    
    }, [planetId, currentCategory, currentPlan, memberPlan, searchSuccess, requestFailure]);

    const listOn =(data,category,numctg,midflg)=>{
        // 追加検索無しにする.
         if (data.length < contentif.ROW_DEFAULT) setIsSearch(false);
        dispatch(actions.updatePlanetIdContentsList(data));

        if(!deleteflg.current){
            resetArray()
            deleteflg.current = true
        }
        if(data.length===0){
            noneCount.current++
        }
        if(noneCount.current>=numctg){
            setNoneData(true)
        }

        let dataArray =[]
            for(let i =0;i<DISPLAY_COUNT;i++){
                if(data[i] !== undefined){
                    dataArray.push(data[i])
                }
                else{
                    break;
                }
            }
        setContents((contents)=>({...contents,[category]:[...dataArray]}))
        if(midflg){
            setMiddleDataCategory(category)
            setMiddleData(data)
        }
        if(!firstScroll){
            const checkStorage2 = JSON.parse(checkStorage)
            if(checkStorage2[checkStorage2.length-1].scrollY===undefined){
                window.scroll(0,0)
            }else{
                setTimeout(()=>(
                    window.scroll(0,checkStorage2[checkStorage2.length-1].scrollY)
                ),SCROLLINTERVAL)
            }
            setFirstScroll(true)
        }
    }
    
    const resetArray =()=>{
        setContents({...ArrayContents})
        setMiddleData([])
        setMiddleDataCategory([])
    }

    /**
     * head設定.
     */
    useEffect(() => {
        if (planet !== {}) {
            // head設定.
            setHeadDefault(planet[contentsKey.PLANET_ID] + ULTRA_SUBSC);
        }
        else {
            // プラネット定義ファイル取得失敗時.
            setHeadDefault(PLANET_ID_FAILURE_TITLE);
        }
    }, [planet]);

    /**
     * 追加検索ステータスを初期化.
     */
    const initScrollSearchState = () => {
        // 追加検索ステータスを初期値に戻す.
        setIsSearch(true);
        setRowCount(contentif.ROW_DEFAULT);
    }

    /**
     * ドロップメニューの表示切り替え.
     */
    const handleDropMenu = () => {
        // 要素の取得に失敗.
        if (dropMenuRef.current === null || btnDropMenuRef.current === null) return;

        if (dropMenu) {
            dropMenuRef.current.classList.remove("is-active");
            btnDropMenuRef.current.style.display = "none";
            setDropMenu(!dropMenu);
        } else {
            dropMenuRef.current.classList.add("is-active");
            btnDropMenuRef.current.style.display = "block";
            setDropMenu(!dropMenu);
        }
    }

    /**
     * アクティブの切り替え.
     */
    const handleActive = () => {
        // 要素の取得に失敗.
        if (iconGridRef.current === null || iconListRef.current === null) return;

        // アクティブの切り替え.
        if (isGrid) {
            iconListRef.current.classList.remove("is-active");
            iconGridRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        } else {
            iconGridRef.current.classList.remove("is-active");
            iconListRef.current.classList.add("is-active");
            dispatch(changeGridList(!isGrid));
        }
    }

    /**
     * カテゴリーの表示切り替え.
     */
    const handleCategory = () => {
        // 要素の取得に失敗.
        if (searchCategoryRef.current === null
            || searchWorksRef.current === null
            || categoryRef.current === null
            || worksRef.current === null) return;

        // アクティブの切り替え.
        if (category) {
            categoryRef.current.classList.remove("is-transform");
            searchCategoryRef.current.style.display = "none";
            setCategory(false);
        } else {
            categoryRef.current.classList.add("is-transform");
            searchCategoryRef.current.style.display = "block";
            if (plan) {
                worksRef.current.classList.remove("is-transform");
                searchWorksRef.current.style.display = "none";
                setPlan(false);
            }
            setCategory(true);
        }
    }

    /**
     * プラン表示切り替え.
     */
    const handlePlan = () => {
        // 要素の取得に失敗.
        if (searchCategoryRef.current === null
            || searchWorksRef.current === null
            || categoryRef.current === null
            || worksRef.current === null) return;

        // アクティブの切り替え.
        if (plan) {
            worksRef.current.classList.remove("is-transform");
            searchWorksRef.current.style.display = "none";
            setPlan(false);
        } else {
            worksRef.current.classList.add("is-transform");
            searchWorksRef.current.style.display = "block";
            if (category) {
                categoryRef.current.classList.remove("is-transform");
                searchCategoryRef.current.style.display = "none";
                setCategory(false);
            }
            setPlan(true);
        }
    }

    /**
     * カテゴリー選択.
     */
    const handleSearchCategory = (ctg) => {
        handleCategory();
        initScrollSearchState();
        dispatch(actions.updatePlanetIdCurrentCategory(ctg));

        if(ctg.startsWith("C1")||
        ctg.startsWith("C2")||
        ctg.startsWith("C3")||
        ctg.startsWith("C4")
        ){
            if(ctg.endsWith("0")){
              middleflgChg()
              if(ctg.startsWith("C1")){
                  videochg()
              }else if(ctg.startsWith("C2")){
                  musicchg()
              }else if(ctg.startsWith("C3")){
                  sentencechg()
              }else if(ctg.startsWith("C4")){
                  newschg()
              }
            }else{
              bottomflgChg()
            }
            
        }else{
            topflgChg()
        }
    }

    /**
     * プラン選択.
     */
    const handleSearchPlan = (ctg) => {
        handlePlan();
        initScrollSearchState();
        dispatch(actions.updatePlanetIdCurrentPlan(ctg));
    }

    /**
     * 改行を挿入.
     */
    const changeBr = (ctg) => {
        var ctgs = ctg.split("<br/>");
        if(ctgs.length >= 2){
          let ctgsAll = String(ctgs[0]+ctgs[1])
          return ctgsAll;
        }
        if (ctgs.length !== 2) return ctg;
      }
    const sessionUpdate=()=>{
        if(!checkStorage){
            saveMethod=[{"category":categoryMethod,"plan":planMethod}]
            sessionStorage.setItem("planet",JSON.stringify(saveMethod))
        }else if(checkStorage){
            const checkStorage2 = JSON.parse(checkStorage)
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            categoryNone()
        }
    }


    useEffect(()=>{
      const checkStorage2 = JSON.parse(sessionStorage.getItem("planet"))
        if(!checkStorage2){
          newSession()
        }
        else if(checkStorage2.length>0){
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            categoryNone()
            if(checkStorage2[checkStorage2.length-1].scrollY===undefined){
                window.scroll(0,0)
            }else{
                setTimeout(()=>(
                    window.scroll(0,checkStorage2[checkStorage2.length-1].scrollY)
                ),SCROLLINTERVAL)
            }
        }

    },[backInfo])

    useEffect(()=>{
        const checkStorage2 = JSON.parse(sessionStorage.getItem("planet"))
        if(!checkStorage2){
            newSession()
        }else{
            handleSearchCategory(checkStorage2[checkStorage2.length-1].category)
            handleSearchPlan(checkStorage2[checkStorage2.length-1].plan)
            categoryNone()
        }  
    },[])

    const newSession=()=>{

      saveMethod=[{"category":"C00","plan":"P00"}]
      sessionStorage.setItem("planet",JSON.stringify(saveMethod))
  }

    const categoryNone = ()=>{
        worksRef.current.classList.remove("is-transform");
        searchWorksRef.current.style.display = "none";
        categoryRef.current.classList.remove("is-transform");
        searchCategoryRef.current.style.display = "none";
        setCategory(false)
        setPlan(false)
    }

    const storageSave = (value) =>{
        if(value){
            if(value.indexOf("C")===0){
              categoryMethod = value
              planMethod = currentPlan
              if(value.startsWith("C1")||value.startsWith("C2")||value.startsWith("C3")||value.startsWith("C4")){
                if(value.endsWith("0")){
                    middleflgChg()
                }else{
                    bottomflgChg()
                }
              }else if(value.startsWith("C00")){
                topflgChg() 
              }
            }else if(value.indexOf("P")===0){
              categoryMethod = currentCategory
              planMethod = value
            }else{
              categoryMethod = currentCategory
              planMethod = currentPlan
            }
        }else{
            categoryMethod = currentCategory
            planMethod = currentPlan 
        }
        let checkStorage2 = JSON.parse(sessionStorage.getItem("planet"))
        checkStorage2.push({"category":categoryMethod,"plan":planMethod})
        if(checkStorage2.length >= SESSIONVAL){
            let keepStorage =[]
            for(let i=1;i<SESSIONVAL ;i++){
                keepStorage.push(checkStorage2[i])
            }
            sessionStorage.setItem("planet",JSON.stringify(keepStorage))
        }else{
            sessionStorage.setItem("planet",JSON.stringify(checkStorage2))  
        }
    }


    const nextCategory =(value)=>{
        updateSessionScroll()
        storageSave(value)
        handleSearchPlan(currentPlan)
        handleSearchCategory(value)
        categoryNone()
        window.scroll(0,0)
    }

    const updateSessionScroll =()=>{
        const scrollY =window.scrollY
        let checkStorage2 = JSON.parse(sessionStorage.getItem("planet"))
        checkStorage2.pop() 
        checkStorage2.push({"category":currentCategory,"plan":currentPlan,"scrollY":scrollY})
        sessionStorage.setItem("planet",JSON.stringify(checkStorage2))
    }

    const searchValue=(props,props2)=>{
      if(props.length!== 0){
        return (
          <>
          <section className="c-sect c-sect--listDis">
            <div className="c-sect__band">
              {props.length >= DISPLAY_COUNT && !bottomCategoryFlg?
               <a className={classes.cursor+" p-searchListLink"} 
               onClick={()=>{nextCategory(props2)}}
               >{changeBr(searchCategory.category[props2])}</a>
              :
              <a className="p-searchListLink">{changeBr(searchCategory.category[props2])}</a>
              }
            </div>
            <div className={isGrid?"p-searchListGrid":"p-searchList"}>
              <ul className={isGrid?"p-searchListGrid__inner":"p-searchList__wrap"}>
                {Object.entries(props).map(([, value]) => (
                    <li key={value[contentsKey.CRID]} className={isGrid?"c-contCardCatList__item":"p-searchList__item"} onClick={()=>{updateSessionScroll()}}>
                        <DefaultList meta={value} isGrid={isGrid?true:false} />
                    </li>
                ))}
                {props.length >= DISPLAY_COUNT && !bottomCategoryFlg&&
                       <li className={isGrid?" p-searchList__item p-searchList__item__more":" p-searchList__item p-searchList__item__more"}>
                         <a className="p-searchList__itemCard--more">
                           <div className={classes.cursor+" p-searchList__itemCard__itemMoreTxt"}onClick={()=>{nextCategory(props2)}}>もっとみる</div>
                         </a>
                       </li>
                }
              </ul>
            </div>
          </section>
          </>
        )
      }
      
    }
    const topflgChg = () =>{
        setTopCategoryFlg(true)
        setMiddleCategoryFlg(false)
        setBottomCategoryFlg(false)
    }
    const middleflgChg = () =>{
        setTopCategoryFlg(false)
        setMiddleCategoryFlg(true)
        setBottomCategoryFlg(false)
        

    }
    const bottomflgChg = () =>{
        setTopCategoryFlg(false)
        setMiddleCategoryFlg(false)
        setBottomCategoryFlg(true)
    }
    const videochg =()=>{

        setVideoFlgOn(true)
        setSentenceFlgOn(false)
        setSoundFlgOn(false)
        setNewsFlgOn(false)
    }
    const sentencechg =()=>{

        setVideoFlgOn(false)
        setSentenceFlgOn(true)
        setSoundFlgOn(false)
        setNewsFlgOn(false)
    }
    const musicchg =()=>{
       
        setVideoFlgOn(false)
        setSentenceFlgOn(false)
        setSoundFlgOn(true)
        setNewsFlgOn(false)
    }
    const newschg =()=>{
        
        setVideoFlgOn(false)
        setSentenceFlgOn(false)
        setSoundFlgOn(false)
        setNewsFlgOn(true)
    }

    const scrollReset =()=>{
        window.scroll(0,0)
    }

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg l-planetBg--planetListBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-mainvisual c-mainvisual--planet">
                    {matches && <div className="c-mainvisual__heroPlanet c-mainvisual__heroPlanet--pc"><img src={planet[contentsKey.PLANET_THUMBNAIL_L]} alt="" /></div>}
                    {!matches && <div className="c-mainvisual__heroPlanet c-mainvisual__heroPlanet--sp"><img src={planet[contentsKey.PLANET_THUMBNAIL_P]} alt="" /></div>}
                </div>
                <div className="c-container p-planetList">
                    <div className={classes.logoArea+" p-planetList__logoWrap"}>
                        <h1 className="p-planetList__logo">
                            <img src={planet[contentsKey.PLANET_TITLE_LOGO]} alt="" />
                        </h1>
                    </div>
                    <div className="p-planetList__box p-planetList__box--planetList">
                        <div className="p-planet__btnWrap p-planet__btnWrap--planetList">
                          <h1 className="p-planetList__title">{planet[contentsKey.PLANET_TITLE]}</h1>
                            <div className="p-planet__btnWrap__dropMenu p-planet__btnWrap__dropMenu--planetList">
                                <div
                                    className="c-btn c-btn--black c-btn--dropMenuBtn"
                                    onClick={() => handleDropMenu()}
                                    ref={dropMenuRef}
                                >
                                    <div className="p-planet__btnWrap__dropMenuTxt">{planetIdContents.headerPlanet}</div>
                                </div>
                                <div
                                    className="c-btnDropMenu c-btnDropMenu--black"
                                    ref={btnDropMenuRef}
                                >
                                    <div className="c-btnDropMenu__detail">{planet[contentsKey.PLANET_SYNOPSIS]}</div>
                                    <div className="c-btnDropMenu__close" onClick={() => handleDropMenu()}>とじる</div>
                                </div>
                            </div>
                        </div>
                        <div className="p-searchBtn p-searchBtn--planetList">
                            <div className="p-searchBtn__wrap p-searchBtn__wrap--planetList">
                                <div className="p-searchBtn__list p-searchBtn__list--planetList">
                                    <div className="p-searchBtn__choice p-searchBtn__choice--planetList">
                                        <div
                                            className="p-searchBtn__item p-searchBtn__item--planetList p-searchBtn__item--category"
                                            onClick={() => handleCategory()}
                                            ref={categoryRef}
                                        >
                                            <div className="p-searchBtn__itemTxt">{changeBr(searchCategory.category[currentCategory])}</div>
                                        </div>
                                        <div
                                            className="p-searchBtn__item p-searchBtn__item--planetList p-searchBtn__item--works"
                                            onClick={() => handlePlan()}
                                            ref={worksRef}
                                        >
                                            <div className="p-searchBtn__itemTxt">{searchCategory.plan[currentPlan]}</div>
                                            {/* メニュー展開- */}
                                            <div
                                                className="p-searchDeployWorks"
                                                ref={searchWorksRef}
                                            >
                                                <div className="p-searchDeployWorks__head" onClick={() => {handleSearchPlan("P00");storageSave("P00");scrollReset();}}>
                                                    {searchCategory.plan["P00"]}
                                                </div>
                                                <div className="p-searchDeployWorks__compile">
                                                    <ul className="p-searchDeployWorks__box">
                                                        <li className="p-searchDeployWorks__item p-searchDeployWorks__item--border" onClick={() => {handleSearchPlan("P11");storageSave("P11");scrollReset();}}>
                                                            {searchCategory.plan["P11"]}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="p-searchBtn__changeDis p-searchBtn__changeDis--planetList" onClick={() => handleActive()}>
                                        <div className="p-searchBtn__item p-searchBtn__item--change">
                                            <div className={"p-searchBtn__icon c-icon c-picIconGrid " + (isGrid ? "is-active": "")} ref={iconGridRef}></div>
                                            <div className={"p-searchBtn__icon c-icon c-picIconList " + (isGrid ? "": "is-active")} ref={iconListRef}></div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="p-searchDeployCategory p-searchDeployCategory--planetList"
                                    ref={searchCategoryRef}
                                >
                                    <div className=" p-searchDeployCategory__head" onClick={() => {handleSearchCategory("C00");storageSave("C00");scrollReset();}}>{searchCategory.category["C00"]}</div>
                                    {/* 追加項目 */}
                                    <div className="p-searchDeployCategory__compile"></div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text" onClick={() => {handleSearchCategory("C10");storageSave("C10");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--play">
                                      <div className="c-icon__Txt c-picIconPlay"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C10"]}</p>
                                </div>
                              </div>
                              <div className=" p-searchDeployCategory__box">
                                <ul className=" p-searchDeployCategory__list">
                                  <li className=" p-searchDeployCategory__item"onClick={() =>{handleSearchCategory("C11");storageSave("C11");scrollReset();}}>{searchCategory.category["C11"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C12");storageSave("C12");scrollReset();}}>{changeBr(searchCategory.category["C12"])}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C13");storageSave("C13");scrollReset();}}>{searchCategory.category["C13"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C14");storageSave("C14");scrollReset();}}>{changeBr(searchCategory.category["C14"])}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C15");storageSave("C15");scrollReset();}}>{searchCategory.category["C15"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C16");storageSave("C16");scrollReset();}}>{searchCategory.category["C16"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C17");storageSave("C17");scrollReset();}}>{searchCategory.category["C17"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C18");storageSave("C18");scrollReset();}}>{searchCategory.category["C18"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C19");storageSave("C19");scrollReset();}}>{searchCategory.category["C19"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text"onClick={() => {handleSearchCategory("C20");storageSave("C20");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--radio">
                                      <div className="c-icon__Txt c-picIconRadio"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C20"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C22");storageSave("C22");scrollReset();}}>{searchCategory.category["C22"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C24");storageSave("C24");scrollReset();}}>{searchCategory.category["C24"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text"onClick={() => {handleSearchCategory("C30");storageSave("C30");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--article">
                                      <div className="c-icon__Txt c-picIconarticle"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C30"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C31");storageSave("C31");scrollReset();}}>{searchCategory.category["C31"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C32");storageSave("C32");scrollReset();}}>{searchCategory.category["C32"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C33");storageSave("C33");scrollReset();}}>{searchCategory.category["C33"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C34");storageSave("C34");scrollReset();}}>{searchCategory.category["C34"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C35");storageSave("C35");scrollReset();}}>{searchCategory.category["C35"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C36");storageSave("C36");scrollReset();}}>{searchCategory.category["C36"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C37");storageSave("C37");scrollReset();}}>{searchCategory.category["C37"]}</li>
                                </ul>
                              </div>
                              <div className="p-searchDeployCategory__body">
                                <div className="p-searchDeployCategory__text" onClick={() => {handleSearchCategory("C40");storageSave("C40");scrollReset();}}>
                                  <div className="p-searchDeployCategory__link">
                                    <div className="p-searchDeployCategory__icon c-icon c-icon--attention">
                                      <div className="c-icon__Txt c-picIconAttention"></div>
                                    </div>
                                  </div>
                                  <p>{searchCategory.category["C40"]}</p>
                                </div>
                              </div>
                              <div className="p-searchDeployCategory__box p-searchDeployCategory__box--lastCont">
                                <ul className="p-searchDeployCategory__list">
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C41");storageSave("C41");scrollReset();}}>{searchCategory.category["C41"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C42");storageSave("C42");scrollReset();}}>{searchCategory.category["C42"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C43");storageSave("C43");scrollReset();}}>{searchCategory.category["C43"]}</li>
                                  <li className="p-searchDeployCategory__item"onClick={() => {handleSearchCategory("C44");storageSave("C44");scrollReset();}}>{searchCategory.category["C44"]}</li>
                                </ul>
                              </div>
                                </div>
                            </div>
                        </div>
                    </div>

                        {topCategoryFlg && !noneData &&
                            <div className="p-searchListWrap">
                                 <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                        {Object.entries(middleCategoryLeng).map(([,value]) => (      
                                            searchValue(contents[value],value)
                                        ))}
                                </div>
                            </div>
                        }

                        {middleCategoryFlg && !noneData &&
                        <div className="p-searchListWrap">
                             <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                    <>
                                    {videoFlgOn  &&
                                    <>
                                        {Object.entries(videoLeng).map(([,value]) => (      
                                            searchValue(contents[value],value)
                                        ))}
                                      </>
                                    }
                                    {soundFlgOn  &&
                                    <>
                                    {Object.entries(soundLeng).map(([,value]) => (
                                        searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                    {sentenceFlgOn&&
                                    <>
                                    {Object.entries(sentenceLeng).map(([,value]) => (
                                      searchValue(contents[value],value)
                                    ))}
                                    </>
                                    }
                                    {newsFlgOn && 
                                    <>
                                    {Object.entries(newsLeng).map(([,value]) => (
                                        searchValue(contents[value],value)
                                    ))}
                                        </>
                                    }
                                        </>

                            </div>
                        </div>
                        }

                    {/* 中カテゴリ画面 */}
                    {bottomCategoryFlg && !noneData &&
                         <div className="p-searchListWrap">
                              <div className={isGrid?"p-searchListBox p-searchListBox--grid js-searchDisplay is-show":"p-searchListBox p-searchListBox--list js-searchDisplay is-show"}>
                                   {searchValue(middleData,middleDataCategory)}
                             </div>
                         </div>
                    }
                    {/* 検索０件 */}
                    {noneData &&
                        <div className="p-searchListWrap p-searchList__zero">
                            <p className="p-searchList__zero--txt">お探しのコンテンツは見つかりませんでした。</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default PlanetId;
