import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import * as historys from "../../../historys";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import * as plan from "../../../constants/plan";
import styles from "./styles";
import { TREM_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * 会員規約画面.
 */
const Trems = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** 会員プランの金額. */
    const planPrice = useSelector(state => state.Member.planPrice);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.trems());

        // head設定.
        setHeadDefault(TREM_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg l-planetBg--trems rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo"><img src="/assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination" /></div>
                    <div className="c-static__box">
                        <div className="c-static__boxTop">
                            <div className="c-static__boxTopTtl">TSUBURAYA IMAGINATION 会員規約</div>
                            <div className="c-static__boxTopDtl">本規約は、当社の本サービスの提供条件及び本サービスに関するユーザーと当社との間の権利義務関係を定めることを目的とするものであり、ユーザーと当社との間の本サービスにかかわる一切の事項に適用されるものとします。ユーザーは本規約の全文を確認し、本規約の内容にご同意頂けた場合に限り、本サービスを利用することができます。</div>
                        </div>
                        <ul className="c-static__wrap">
                            <li className="c-static__item">
                                <div className="c-static__ttl">第１条（定義）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">本規約における用語の定義は以下の各号に定める通りとします。</div>
                                    <div className="c-static__indent">①「本規約」<br/>当社が定める、この「TSUBURAYA IMAGINATION 会員規約」をいいます。</div>
                                    <div className="c-static__indent">②「本サービス利用契約」<br/>本規約に同意いただいたうえで本サービスの提供を受けることにより、当社と会員の間で締結される本サービスの利用契約をいいます。</div>
                                    <div className="c-static__indent">③「当社」<br/>本サービスの提供主体である株式会社円谷プロダクション（本店所在地：東京都渋谷区南平台町16番17号）をいいます。</div>
                                    <div className="c-static__indent">④「有料会員」<br/>第３条（登録）に基づいて登録をすることにより、加入プランに応じた本サービスの提供を受けることができる個人の会員をいいます。</div>
                                    <div className="c-static__indent">⑤「無料会員」<br/>第３条（登録）に基づいて登録をすることにより、本サービスの提供を受けることができる個人の会員をいいます。</div>
                                    <div className="c-static__indent">⑥「会員」<br/>有料会員と無料会員の総称をいいます。</div>
                                    <div className="c-static__indent">⑦「本サービス」<br/>当社が本サイト又は本アプリを通じて提供する、有償又は無償で本コンテンツを閲覧等できるサービス「TSUBURAYA IMAGINATION」（サービスの名称又は内容が変更された場合は、当該変更後のサービスを含むものとします。）をいい、プランとしては、「無料プラン」「スタンダードプラン」「プレミアムプラン」があります。なお、当社が本サービスの提供主体となりますが、株式会社NTTドコモ（以下「ドコモ」といいます。）も本サービスの運営に関与しております。そのため、ドコモから本サービスに関連する広告がなされる場合がございます。</div>
                                    <div className="c-static__indent">⑧「有料サービス」<br/>「スタンダードプラン」又は「プレミアムプラン」に加入することで、それぞれのプランに応じて利用することができる有償のサービスをいいます。</div>
                                    <div className="c-static__indent">⑨「本サイト」<br/>そのドメインが「imagination.m-78.jp」である、当社又は当社の外部委託先が運営する、本サービスに係るウェブサイト（当該ウェブサイトのドメイン又は内容が変更された場合は、当該変更後のウェブサイトを含むものとします。）をいいます。</div>
                                    <div className="c-static__indent">⑩「本アプリ」<br/>当社又は当社の外部委託先が運営する、本サービスに係る通信電子機器端末向けアプリケーションをいいます。</div>
                                    <div className="c-static__indent">⑪「本コンテンツ」<br/>当社が本サービスとして本サイト又は本アプリを通じて提供する、デジタルデータ化された映像、画像、テキスト、音声及びこれらの一又は二以上の結合物をいいます。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第２条（適用範囲）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　本規約は、本サービスの提供条件及び本サービスの利用に関して、当社と会員との間の本サービスの利用に関する一切の関係に適用されます。</div>
                                    <div className="c-static__indent">2.　当社が本サイト上又は本アプリ上で掲載する本サービスの利用に関するあらゆる条件は、本規約の一部を構成するものとします。</div>
                                    <div className="c-static__indent">3.　会員が本サービスを利用した場合、当該ユーザーは本規約に同意していただいたものとみなされます。本規約の条件に同意していただけないユーザーは、本サービスを利用することができません。</div>
                                    <div className="c-static__indent">4.　前項により、会員が本サービスを利用した時点をもって、当社と会員との間において、本サービス利用契約が成立するものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第３条（登録）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、有料会員であると無料会員であるとを問わず、本サイト上又は本アプリ上で当社が案内するところに従い、TSUBURAYAアカウント会員登録とドコモが定める「dアカウント規約」に基づき発行提供する「dアカウント」（以下「dアカウント」といいます。）の取得を行っていただく必要があります。</div>
                                    <div className="c-static__indent">2.　本コンテンツには、有料のコンテンツと無料のコンテンツとがあり、有料会員は、自らが選択するプランにより、当該プランに応じた有料の本コンテンツを閲覧等することが可能となります。</div>
                                    <div className="c-static__indent">3.　当社は、以下の場合に本サービスの会員登録を承諾しないことがあります。承諾しなかった場合の判断の理由については一切申込者に開示しません。また、申込者は判断の結果に対して異議を述べることはできません。<br/>
                                        <div className="c-static__indentSub">（1）ユーザーが規約違反等により当社又はそのグループ会社が運営するサービス等の利用を停止されたことがある場合等、当社が会員登録を承諾すべきではないと判断した場合</div>
                                        <div className="c-static__indentSub">（2）当社の日常的な業務の遂行上又は技術上、支障がある場合</div>
                                        <div className="c-static__indentSub">（3）その他、当社が不適当と合理的な理由により認めた場合</div>
                                    </div>
                                    <div className="c-static__indent">４.　未成年者が会員登録をする場合は、登録にあたり、事前に法定代理人（親権者）の承諾を得てから会員登録をするものとし、未成年の方からの会員登録が完了した時点で、当社は法定代理人（親権者）の同意があったものとみなします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第４条（登録事項の変更）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、登録事項に変更が生じた場合、本サイト上に設置するプロフィールより、遅滞なく登録事項を変更するものとします。会員が当該変更の入力を怠るなど、登録事項の変更が適切に行われないために不利益を被ったとしても、当社は一切の責任を負わないものとします。</div>
                                    <div className="c-static__indent">2.　TSUBURAYAアカウント会員登録事項及びdアカウント登録事項に変更が生じた場合も、前項と同様とし、登録事項の変更は、それぞれの登録をした場所で遅滞なく行っていただくものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第５条（会員番号、ログインID及びパスワードの管理）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、自己の責任において、本サービスに関する会員番号並びに本サービスの利用に必要となるTSUBURAYAアカウントのログインID及びパスワードを適切に管理するものとし、これらを第三者に利用させ、又は譲渡し、貸与し、担保設定するなどの行為をしてはならないものとします。</div>
                                    <div className="c-static__indent">2.　ログインID又はパスワードの再発行については、本サイト又は本アプリの表示に従うものとします。</div>
                                    <div className="c-static__indent">3.　会員による会員番号、ログインID及びパスワードの管理不十分、紛失、失念及び使用上の過誤に起因して、又はこれらに関連して生じる会員の不利益又は損害に関する責任は会員が負うものとし、当社は一切の責任を負わないものとします。但し、当社の責めに帰すべき事由による場合はこの限りではありません。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第６条（本サービスの利用）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　本サービスは、日本国内に居住する個人のユーザーにより、日本国内でのみ利用可能です。</div>
                                    <div className="c-static__indent">2.　会員は、会員登録後、加入プランに応じた本サービスの提供を受けることができます。また、いずれの加入プランの会員であっても、当社の商品に付属されている「ウルトラコード」、その他のコードを入力することにより、特定のコンテンツを閲覧することができるサービスがあります。</div>
                                    <div className="c-static__indent">3.　本サービスを利用するために必要な対応端末、ソフトウェア、電気通信回線等の環境は、会員の負担と責任においてご準備いただくものとします（通信料金は会員の負担となります）。本サービスを快適にご利用いただくための推奨環境は、<span className={classes.clickable + " c-static__dtlLink"} onClick={() => historys.historyEnvironment(history)}>こちら</span>をご確認ください。なお、本サービスを利用中の対応端末の破損等について、当社は一切の責任を負いません。</div>
                                    <div className="c-static__indent">4.　当社は、本サービス内（当社又は当社の委託先が送信するメールマガジンを含みます。）において、広告を掲載する場合があります。当該広告に掲載されるリンク先のサービスは、本サービスとは別のサービスであり、当社は、当該広告及び当該リンク先のサービスについて、いかなる責任も負いません。</div>
                                    <div className="c-static__indent">5.　当社は、本サービスの内容（本コンテンツを含みます）を定期的に、又は不定期的に更新します。会員は、本サイト又は本アプリにアクセスすることで、加入プランに応じ、その時点における本サービスを利用することができます。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第７条（料金及び支払方法）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">{"1.　本サービスのうち、有料となるサービスの利用料金は、スタンダードプラン月額" + Number(planPrice[plan.STANDARD]) + "円（税込）、プレミアムプラン年額" + Number(planPrice[plan.PREMIUM]).toLocaleString() + "円（税込）とします。"}</div>
                                    <div className="c-static__indent">2.　会員は、本サービス利用の対価として、加入プランに応じた利用料金を、本サイト上又は本アプリ上で表示する方法により当社に支払うものとします。利用料金は日割り計算されませんので、月途中に本サービス利用契約が成立又は終了（登録抹消、退会等その理由の如何を問いません。）した場合でも、スタンダードプランは1か月分、プレミアムプランは1年分の利用料金をお支払いいただきます。</div>
                                    <div className="c-static__indent">3.　当社は第１項の利用料金を変更することができるものとし、当該変更は将来に向けて効力を有するものとします。この場合、当社は予めその効力発生時期を定め、かつ、利用料金を変更する旨及び変更後の利用料金の額並びにその効力発生時期について、本サイトを通じて公表するものとします。</div>
                                    <div className="c-static__indent">4.　会員は、変更後の利用料金に同意いただけない場合は、変更が適用される日までに、本規約第12条に基づき本サービスの退会手続きを行ってください。有料会員が変更適用日までに退会手続きを完了していない場合、変更後の利用料金に同意したものとみなされ、変更適用日の属する暦月分から、変更後の利用料金が課金されます。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第８条（禁止事項）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、本サービスの利用又は本コンテンツの閲覧等にあたり、以下の各号のいずれかに該当する行為又は該当すると当社が判断する行為をしてはなりません。
                                        <div className="c-static__indentSub">（1）法令に違反する行為又は犯罪行為に関連する行為</div>
                                        <div className="c-static__indentSub">（2）当社、本サービスの他の会員その他の第三者に対する詐欺又は脅迫行為</div>
                                        <div className="c-static__indentSub">（3）公序良俗に反する行為</div>
                                        <div className="c-static__indentSub">（4）当社、本サービスの他の会員その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、声望、声価、その他の権利又は法律上保護される利益を侵害する行為</div>
                                        <div className="c-static__indentSub">（5）本サービスを通じ、以下に該当し、又は該当すると当社が判断する情報を当社又は本サービスの他の会員その他の第三者に送信する行為又は当該行為を扇動する行為<br/>・過度に暴力的又は残虐的な表現を含む情報<br/>・コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報<br/>・当社、本サービスの他の会員その他の第三者の名誉又は信用を毀損する表現を含む情報<br/>・過度にわいせつな表現を含む情報<br/>・差別を助長し又はそのおそれのある表現を含む情報<br/>・自殺又は自傷行為を助長し又はそのおそれのある表現を含む情報<br/>・薬物の不適切な利用を助長し又はそのおそれのある表現を含む情報<br/>・反社会的な表現を含む情報<br/>・他人に不快感を与える表現を含む行為</div>
                                        <div className="c-static__indentSub">（6）本サービスの運営を妨害し又は妨害するおそれのある行為又は当該行為を扇動する行為</div>
                                        <div className="c-static__indentSub">（7）本サービスのネットワーク又はシステム等に過度な負荷をかける行為又は当該行為を扇動する行為</div>
                                        <div className="c-static__indentSub">（8）当社のネットワーク又はシステム等に不正にアクセスし、又は不正なアクセスを試みる行為又は当該行為を扇動する行為</div>
                                        <div className="c-static__indentSub">（9）本コンテンツの全部又は一部を複製、改変、改ざんし、又は逆コンパイル、逆アセンブル等のリバースエンジニアリング、その他内容を解析して、人間が読み取り可能な形に変換する行為。</div>
                                        <div className="c-static__indentSub">（10）第三者に成りすます行為</div>
                                        <div className="c-static__indentSub">（11）本サービスの他の会員のID若しくはパスワードを利用する行為又は当社の商品に付属されている「ウルトラコード」、その他のコードのうち、自己のものではないものを利用する行為</div>
                                        <div className="c-static__indentSub">（12）当社が事前に承諾しない本サービス上での広告宣伝、勧誘又は営業行為</div>
                                        <div className="c-static__indentSub">（13）当社、本サービスの他の利用者その他の第三者に不利益、損害又は不快感を与える行為</div>
                                        <div className="c-static__indentSub">（14）本サービスの他の会員に関する情報を収集する行為</div>
                                        <div className="c-static__indentSub">（15）本サービスを日本国外で利用する行為</div>
                                        <div className="c-static__indentSub">（16）dアカウントを不正に使用する行為</div>
                                        <div className="c-static__indentSub">（17）本サービスに付されている著作権表示その他の権利表示を除去し、又は変更する行為</div>
                                        <div className="c-static__indentSub">（18）本規約に反する行為</div>
                                        <div className="c-static__indentSub">（19）前各号の行為を直接又は関接に惹起し、又は容易にする行為</div>
                                        <div className="c-static__indentSub">（20）その他当社が不適切であると判断する行為</div>
                                    </div>
                                    <div className="c-static__indent">2.　前項の各号のいずれかに該当すると当社が判断した場合、当社は当社の裁量の範囲において、これらの行為の停止措置、削除措置、会員のアカウント停止処分又は退会処分を採るものとし、正当な理由がない限り、会員はこれらの措置又は処分に異議を唱えないものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第９条（本サービスの停止等）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、以下のいずれかに該当する場合には、会員に事前に通知又は公表することなく、本サービスの全部又は一部の提供を停止し又は中断することができるものとします。なお、この場合であっても第7条に定める利用料金の減額等は行ないません。
                                        <div className="c-static__indentSub">（1）本サービスに係るシステムの点検又は保守作業を緊急に行う場合</div>
                                        <div className="c-static__indentSub">（2）ソフトウェア、ネットワーク等が事故により停止した場合</div>
                                        <div className="c-static__indentSub">（3）地震、落雷、火災、風水害、疫病の流行その他の天災事変又は停電などの不可抗力により本サービスの運営ができなくなった場合</div>
                                        <div className="c-static__indentSub">（4）法令又は規制当局により要請された場合</div>
                                        <div className="c-static__indentSub">（5）その他、当社が停止又は中断を必要と判断した場合</div>
                                    </div>
                                    <div className="c-static__indent">2.　当社は、本条に基づき当社が行った措置によって生じる会員の不利益又は損害について、一切の責任を負いません。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第10条（権利帰属）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　本サイト、本アプリ及び本サービスに関する知的財産権は、すべて当社又は当社に権利を許諾している第三者に帰属しており、本規約に基づく本サービスの提供は、法令又は本規約に別段の定めがある場合を除き、本サイト、本アプリ又は本サービスに関する当社又は当社に権利を許諾している第三者の知的財産権の利用許諾を意味するものではありません。</div>
                                    <div className="c-static__indent">2.　会員がお問い合わせフォーム、その他の本サイト又は本アプリの投稿機能等を使用して当社又は他の会員に情報を発信する場合、会員は、当該情報に含まれる画像データ、音声データ、動画データ、テキストデータその他のデータ（以下「会員発信情報」といいます。）について、自らが投稿その他の送信をすることについての適法な権利を有していること及び会員発信情報が第三者の権利を侵害していないことを当社に対し表明し、保証するものとします。</div>
                                    <div className="c-static__indent">3.　会員は、会員発信情報について、当社に対し、全世界において非独占的な利用権を無償で許諾するものとします。当該利用権は、再許諾及び譲渡が可能なものであり、その許諾期間は当該会員発信情報の送信完了時点から、本サービスが終了するまでの期間とします。</div>
                                    <div className="c-static__indent">4.　会員は、前項の利用権に関して、当社、当該利用権の当社からの譲受人、承継人その他の当社が指定する者に対し、著作者人格権を行使しないことに同意するものとします。</div>
                                    <div className="c-static__indent">5.　会員は、当社が会員発信情報の全部又は一部の削除若しくは編集をすることに同意するものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第11条（登録抹消等）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、会員が、以下の各号のいずれかの事由に該当する場合、事前の通知又は催告を要することなく、会員発信情報を削除したうえ、当該会員について本サービスの利用を一時的に停止し、会員としての登録を抹消又は本サービス利用契約を解除することができるものとします。
                                        <div className="c-static__indentSub">（1）本規約のいずれかの条項に違反した場合</div>
                                        <div className="c-static__indentSub">（2）登録事項に虚偽が含まれていることが判明した場合</div>
                                        <div className="c-static__indentSub">（3）支払停止又は支払不能となった場合</div>
                                        <div className="c-static__indentSub">（4）その他、当社が本サービスの利用、会員としての登録又は本サービス利用契約の継続を適当でないと正当な理由により判断した場合</div>
                                    </div>
                                    <div className="c-static__indent">2.　前項各号のいずれかに該当した会員は、当社に対して負っている債務のすべてについて、当然に期限の利益を喪失するものとし、直ちに当社に対してすべての債務の履行を行わなければならないものとします。</div>
                                    <div className="c-static__indent">3.　当社は、本条に基づき当社が行った行為に関して生じる会員の不利益又は損害について、一切の責任を負いません。また、当社が、本サービス利用契約の全部又は一部を解除した場合であっても、当社が会員に対して有する損害賠償請求権その他の法的補償を求める権利に何らの影響を与えないものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第12条（退会）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、本サイト又は本アプリで案内するところに従い、本サービスから退会し、自己の登録を抹消することができます。</div>
                                    <div className="c-static__indent">2.　無料会員は、退会時点をもって、本サービスを利用することができなくなります。</div>
                                    <div className="c-static__indent">3.　有料会員は、退会手続きを行なった後、プラン契約期間満了時点で有料会員の資格を喪失し、以後有料サービスは利用できなくなります。退会後、有料サービスのご利用を希望される場合には、再度有料会員登録をしてください。なお、退会された原因によっては、再度の登録の申込みが認められない場合があります。</div>
                                    <div className="c-static__indent">4.　退会した時点において、会員に当社に対して負っている債務が存在する場合、会員は、当社に対して負っている債務の一切について当然に期限の利益を喪失するものとし、直ちに当社に対してすべての債務の履行を行わなければならないものとします。</div>
                                    <div className="c-static__indent">5.　退会後の会員情報の取り扱いについても、第16条の定めに従うものとします。</div>
                                    <div className="c-static__indent">6.　退会に際して、会員に如何なる事情があった場合であっても、当社は返金対応をすることはいたしません。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第13条（本サービスの内容の変更及び終了）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、当社の都合により、会員に対する事前の承諾を得ることなく、本サービスの内容を変更し、又は提供を終了する場合があります。この場合、当社は事前に本サイト又は本アプリを通じて、会員にその旨を公表するものとします。但し、本規約第６条第５項に定める本サービスの内容（本コンテンツを含みます）の更新についてはこの限りではありません。</div>
                                    <div className="c-static__indent">2.　当社は、本条に基づき当社が行った本サービスの内容の変更又は本サービスの提供の終了によって生じるユーザーの不利益又は損害について、一切の責任を負いません。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第14条（保証の否認及び免責）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、本サービスが会員の特定の目的に適合すること、期待する機能、商品的価値、正確性及び有用性を有すること、会員による本サービスの利用が、会員に適用ある法令又は業界団体の規則等に適合すること、及び不具合が生じないことについて、何等保証するものではありません。</div>
                                    <div className="c-static__indent">2.　当社は、故意又は重過失による場合を除き、当社による本サービスの提供の中断、停止、終了、利用不能又は変更、会員が本サービスに送信した会員発信情報の削除又は消失、会員登録の抹消、本サービスの利用による登録データの消失又は機器の故障若しくは損傷、その他本サービスに関して会員が被った損害について賠償する責任を一切負わないものとします。</div>
                                    <div className="c-static__indent">3.　法令その他の理由により当社が責任を負う場合であっても、当社は、有料会員においては、当該有料会員に現実的に発生した直接かつ通常の損害につき、過去1ヶ月間に当該有料会員が当社に支払った対価の金額を超えて賠償する責任を、また無料会員においては、当該無料会員に現実的に発生した直接かつ通常の損害につき、金5,000円を超えて賠償する責任を、それぞれ負わないものとします。但し、有料会員がプレミアムプランに加入されている場合に限り、当社は、当該有料会員については、当該有料会員に現実的に発生した直接かつ通常の損害につき、過去12ヶ月間に当該有料会員が当社に支払った対価の金額を超えて賠償する責任を負わないもの読み替えるものとします。また、特別の事情によって生じた損害であっても、その事情の予見可能性の有無及び予見すべきであったか否かにかかわらず、当社は当該特別の事情によって生じた損害を賠償する責任を負わず、かつ付随的損害、間接損害、将来の損害及び逸失利益に係る損害については賠償する責任を負わないものとします。</div>
                                    <div className="c-static__indent">4.　本サービス、本サイト又は本アプリに関連して、会員と他の会員又は第三者との間において生じた取引、連絡及び紛争については、当社は一切の責任を負いません。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第15条（秘密保持）</div>
                                <div className="c-static__dtl">会員は、本サービスに関連して当社が会員に対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、厳重に秘密として取り扱うものとします。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第16条（会員情報の取り扱い）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、本サービスに関連して取得した会員の個人情報その他の会員に関するデータ（メンバーシップ及び、認証識別情報、クッキー、IPアドレス、お問い合わせ内容、本コンテンツの視聴履歴その他本サービスの利用状況等を含み、以下「会員データ」といいます。）を当社のプライバシーポリシーに定める利用目的の範囲で利用します。</div>
                                    <div className="c-static__indent">2.　当社は、本サービスの契約又は解約に係る分析、マーケティング及び会員又は本サービス未契約のユーザーに対する広告、ドコモの本サービス以外の他事業を含む今後の新事業への活用のため、会員データのうち識別子、契約プラン、本サイト閲覧履歴、動画視聴履歴、ECサイトの利用履歴、イベントチケットの購買履歴、当社が会員に実施したアンケートの回答及び入退会日等をドコモに提供します。</div>
                                    <div className="c-static__indent">3.　会員が当社にお問い合わせいただいた内容が、ドコモサービス又はドコモと会員間の契約に関する場合、当社は、会員のご指示に基づき会員の同意を得た上で、ドコモに会員データの一部を伝える場合があります。</div>
                                    <div className="c-static__indent">4.　本サイトではドコモが提供する情報収集モジュールを用い、サイト利用者のアクセス履歴等の情報を収集する場合があります。サイト利用者はそのような情報の収集を希望しない場合、これを停止することができます。詳細については、以下のサイトをご覧ください。<a className="c-static__dtlLink" href="https://www.nttdocomo.co.jp/utility/term/handling_information.html" target="_blank" rel="noreferrer">お客さまが閲覧するwebサイトでのNTTドコモによる情報収集と利用について（NTTドコモ）</a></div>
                                    <div className="c-static__indent">5.　本条は、会員データの取扱いのうち本サービスに特有の内容を定めるものです。会員データの取扱いについては、本条の定めのほか、当社が別途定める<a className="c-static__dtlLink" href="https://m-78.jp/privacy/" target="_blank" rel="noreferrer">プライバシーポリシー</a>が適用されます。また、本アプリにより取得する会員データについては<span className={classes.clickable + " c-static__dtlLink"} onClick={() => historys.historyAppPrivacy(history)}>「アプリケーション・プライバシーポリシー」</span>が、本サイトにより取得する会員データについては<a className="c-static__dtlLink" href="https://m-78.jp/cookie/" target="_blank" rel="noreferrer">「クッキーについて」</a>がそれぞれ適用されます。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第17条（本規約の変更）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　当社は、次に掲げる場合には、個別に会員と合意をすることなく本規約及び本サービス利用契約の内容を変更することができるものとします。
                                        <div className="c-static__indentSub">（1）本規約の変更が、会員の一般の利益に適合するとき。</div>
                                        <div className="c-static__indentSub">（2）本規約の変更が、本サービス利用契約をした目的に反せず、かつ、民法548条の4に定める変更の事情に照らして合理的なものであるとき。</div>
                                    </div>
                                    <div className="c-static__indent">2.　前項の場合、本サービスの利用条件は、変更後の規約によるものとします。</div>
                                    <div className="c-static__indent">3.　当社は、本規約を変更する場合には予めその効力発生時期を定め、かつ、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期について、本サイト及び本アプリを通じて公表するものとします。</div>
                                    <div className="c-static__indent">4.　会員は、本規約の変更の効力が生じた後に本サービスを利用した場合、変更後の本規約に同意したものとみなされます。変更後の本規約に同意できない場合、会員は本サービスを利用することはできません。会員は、常に最新の本規約及び補足条件等を確認した上で、本サービスを利用してください。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第18条（連絡及び通知）</div>
                                <div className="c-static__dtl">本サービスに関するお問い合わせその他会員から当社に対する連絡又は通知、及び本規約の変更に関する通知その他当社から会員に対する連絡又は通知は、当社の定める方法で行うものとします。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第19条（サービス利用契約上の地位の譲渡等）</div>
                                <div className="c-static__dtl">
                                    <div className="c-static__indent">1.　会員は、当社の書面による事前の承諾なく、本サービス利用契約上の地位又は本規約に基づく権利義務につき、第三者に対して譲渡、承継、移転、貸与、担保設定その他の処分をすることはできません。</div>
                                    <div className="c-static__indent">2.　当社は本サービスに係る事業の全部又は一部を第三者に譲渡又は承継する場合があります。この場合、事業譲渡又は事業承継に伴って本サービス利用契約上の地位、本規約に基づく権利義務並びに会員の登録事項が、当該事業譲渡の譲受人に譲渡され又は承継されることとなります。会員は、本項に定める事業譲渡及び事業承継の可能性を予め理解のうえ、同意するものとします。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第20条（分離可能性）</div>
                                <div className="c-static__dtl">本規約のいずれかの条項の全部又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残部の規定及び一部が無効又は執行不能と判断された場合の規定の残部は、なお完全に効力を有するものとします。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">第21条（準拠法及び管轄裁判所）</div>
                                <div className="c-static__dtl">本規約及び本サービス利用契約は日本法に準拠するものとし、本規約又は本サービス利用契約の内容に関して、当社と会員との間に生じる一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</div>
                            </li>
                            <div className="c-static__version">2021年3月15日制定　Ver.1.0<br/>2021年7月29日改訂　Ver.1.1<br/>2023年2月20日改訂　Ver.1.2</div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Trems;
