import * as actionTypes from "./actionTypes";

const initState = {
    popupNotice: false,
    noticeInfo: {
        id: "",
        linkCrid: "",
        imageUrlAtPopup: ""
    }
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** push通知を表示. */
        case actionTypes.OPEN_POPUP_NOTICE:
            return { ...state, popupNotice: true, noticeInfo: action.noticeInfo };

        /** push通知を閉じる. */
        case actionTypes.CLOSE_POPUP_NOTICE:
            return { ...state, popupNotice: false };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
