import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { encode } from "js-base64";

import * as screenActions from "../../../common/status/screen/actions";
import * as actions from "./actions";
import * as commonActions from "../../../common/status/actions";
import * as lectureActions from "../../../common/popup/lecture/actions";
import * as noticeActions from "../../../common/popup/notice/actions";
import * as historys from "../../../historys";
import * as contentif from "../../../middleware/contentif";
import * as fileApi from "../../../middleware/file";
import * as app from "../../../constants/app";
import * as plan from "../../../constants/plan";
import * as contentsKey from "../../../constants/contentsKey";
import * as searchKey from "../../../constants/searchKey";
import * as contentType from "../../../constants/contentType";
import * as libraryId from "../../../constants/libraryId";
import { getTopSpecKey, getTopLibrarySpecKey } from "../../../constants/recommend";
import * as accountif from "../../../middleware/accountif";
import * as recommendif from "../../../middleware/recommendif";

import DefaultTop from "../../contents/default/DefaultTop";
import DefaultResume from "../../contents/default/DefaultResume";
import Special from "../../contents/special/Special";
import PlanetContents from "../../contents/planet/Planet";

import styles from "./styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import { TOP_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
import useRecommendCookie from "../../../hooks/useRecommendCookie";
SwiperCore.use([Autoplay, Navigation]);


/**
 * トップ画面.
 */
const Top = () => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 通知一覧. */
    const noticeList = useSelector(state => state.Member.noticeList);
    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);
    /** プラン選択済みフラグ. */
    const planSelectedFlg = useSelector(state => state.Member.planSelectedFlg);
    /** 初回ログインフラグ. */
    const isFirstLogin = useSelector(state => state.LectureFirst.isFirstLogin);
    /** push通知の表示/非表示. */
    const popupNotice = useSelector(state => state.Notice.popupNotice);
    /** 決済完了ダイアログの表示/非表示. */
    const popupCreditResult = useSelector(state => state.Credit.popupCreditResult);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);
    /** 特集バナー. */
    const topBanner = useSelector(state => state.Top.topBanner);
    /** アイちゃんメッセージ. */
    const greeting = useSelector(state => state.Top.greeting);
    /** コーナー配列. */
    const cornerArr = useSelector(state => state.Top.cornerArr);
    /** ブロック配列. */
    const blockArr = useSelector(state => state.Top.blockArr);
    /** コンテンツ配列. */
    const contentArr = useSelector(state => state.Top.contentArr);
    /** URL配列. */
    const urlArr = useSelector(state => state.Top.urlArr);
    /** プラネットリスト. */
    const planetList = useSelector(state => state.Member.planetList);
    /** プラネットグループ（プラネットセレクト）. */
    const planetGroupList = useSelector(state => state.Member.planetGroupList);
    /** タブ処理. */
    const active = useSelector(state => state.Top.currentActive);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.Top.clickData);
    /** 視聴中コンテンツ追加リスト. */
    const [resume, setResume] = useState([]);

    const { recommendCookie } = useRecommendCookie();

    /** TOP定義ファイル名. */
    /** 未ログイン. */
    const FILENAME_NOLOGIN = "nologin";
    /** 無料. */
    const FILENAME_FREE = "free";
    /** スタンダード. */
    const FILENAME_STANDARD = "standard";
    /** プレミアム. */
    const FILENAME_PREMIUM = "premium";

    /** 特集バナーの欄でコンテンツが１つ. */
    const TOP_BANNER_CONTENT_1 = 1;

    /** ニュースのdiv classタグ */
    const classSel_news = "c-contCardSlideWrap c-contCardNews"
    /** 小バナーのdiv classタグ */
    const classSel_kobana = "c-contCardSlideWrap c-contCard__spBig"
    /** ランキングのdiv classタグ */
    const classSel_ranking1 = "c-contCardSlideWrap c-contCardSlide"
    const classSel_ranking2 = "c-contCardSlideBox c-contCard__spBand"
    const classSel_ranking3 = "c-contCardCatList swiper-container c-rankSlide js-mainrankSlide"
    /** その他のdiv classタグ */
    const classSel = "c-contCardSlideWrap"

    /**
    * 画面描画時に実行する処理.
    */
    useEffect(() => {
        dispatch(screenActions.top());

        // head設定.
        setHeadDefault(TOP_TITLE);
        
        // ファイル・TOP定義取得.
        const filename = plan.isStandard(memberPlan)
            ? FILENAME_STANDARD : plan.isPremium(memberPlan)
            ? FILENAME_PREMIUM : plan.isFree(memberPlan)
            ? FILENAME_FREE : FILENAME_NOLOGIN
            ;
        fileApi.getTop("top_"+filename).then(
            (body) => dispatch(actions.getTopSuccess(body)),
            () => dispatch(commonActions.updateConectionError(true))
        );
    }, [dispatch, memberPlan]);

    /**
     * アイちゃんクリック時.
     */
    const handleClickAichan = () => {

        // 映像詳細画面に遷移.
        if (greeting.contentType === contentType.VIDEO)
            historys.historyVideoId(history, greeting.crid);

        // ライブ画面に遷移.
        if (greeting.contentType === contentType.LIVE)
            historys.historyLiveId(history, greeting.crid);

        // 静的コンテンツページに遷移.
        if (greeting.contentType === contentType.WEB)
            historys.historyContents(history, greeting.crid);
    }
    
    /**
     * ファイル：プラネット定義取得 失敗.
     */
    const getPlanetFailure = useCallback((err) => {
        dispatch(commonActions.updateConectionError(true));
    }, [dispatch]);

    /**
     * 画面描画時に実行する処理（プラネットタブ用）.
     */
    useEffect(() => {
        // ファイル：プラネット定義
        fileApi.getPlanetGroup().then(fileApi.getPlanetGroupSuccess.bind(this, dispatch), getPlanetFailure);
    }, [dispatch, getPlanetFailure]);

    /**
     * タブを切り替え.
     */
    const setActiveTab = (active) => {
        dispatch(actions.changeTab(active));
    }

    /**
     * レクチャー画面とポップアップ（大）表示.
     */
    useEffect(() => {
        // アプリではレクチャー画面非表示（アプリ対応）.
        if (!app.isAllApp()) {
            // レクチャー画面未表示時.
            if (memberId !== "" && planSelectedFlg && isFirstLogin && !popupCreditResult) dispatch(lectureActions.openPopupLecture());
        } else {
            // 初回ログインフラグを更新.
            if (isFirstLogin) dispatch(lectureActions.updateFirstLogin());
        }

        // ポップアップ（大）表示.
        if (memberId !== "" && planSelectedFlg && !isFirstLogin && !popupCreditResult && !popupNotice) {
            for (let i = 0; i < noticeList.length; i++) {
                if (!noticeList[i][accountif.READ_FLG] && !noneReadMap[noticeList[i].id] && noticeList[i][accountif.POPUP_TYPE] === accountif.BIG) {
                    dispatch(noticeActions.openPopupNotice(noticeList[i]));
                    break;
                }
            }
        }
        // 未ログイン時は判定方法を変更.
        else if(memberId === "" && !popupCreditResult && !popupNotice) {
            for (let i = 0; i < noticeList.length; i++) {
                if (!noticeList[i][accountif.READ_FLG] && !noneReadMap[noticeList[i].id] && noticeList[i][accountif.POPUP_TYPE] === accountif.BIG) {
                    dispatch(noticeActions.openPopupNotice(noticeList[i]));
                    break;
                }
            }
        }
        // planSelectedFlg,popupCreditResultは発火不要.
        // eslint-disable-next-line
    }, [dispatch, memberId, isFirstLogin, noticeList, noneReadMap]);

    /**
     * サービス紹介画面へ遷移.
     */
    const historyLogged = () => {
        historys.historyLogged(history);
    };

    /**
     * おすすめタグで検索一覧画面に遷移.
     */
    const historySearchByTag = (data) => {
        historys.historySearchByTag(history, data);
    }

    /**
    * ライブラリ一覧画面に遷移.
    */
     const historylibraryIdTitle = (id, title) => {
        historys.historylibraryIdTitle(history, id, title);
    };
    
    /** 
    * SPで出力項目数設定.
    */
    const contentsSPchk = (content,index) => {
        if(content === "vertical" && blockArr[index].pickup !== undefined){
            //pick up項目がある場合、表示コンテンツ1件追加
            return blockArr[index].numberOfContentsSP + 1
        }else if(blockArr[index].isShowResume && resumeList.length !== 0){
            //視聴中項目がある場合、表示コンテンツ1件削除
            return blockArr[index].numberOfContentsSP - 1
        }else return blockArr[index].numberOfContentsSP
    }

    /** 
     * ニュースpickup項目の指定. 
    */
    let count = 0
    const pickupCount = () => {
        count++
        if(count === 1){
            return true
        }else return false
    }
    
    /**
    * 視聴中判定.
    */
    const isResume = (id) => {
        for (const resume of resumeList) {
            if (resume[contentsKey.CRID] === id) return true;
        }
        return false;
    };

    /**
     * 必見orプレミアムコンテンツの先頭に視聴中を1つ追加する.
     */
    useEffect(() => {
        setResume([])
        if (resumeList.length !== 0) {
            let list = [];
            let request = {
                [contentif.CONTENT_ID_LIST]: [resumeList[0][contentsKey.CRID]],
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postIdSearch(request).then((body) => {
                if (body.length !== 0) {
                    body[0][contentsKey.COMMENT] = "視聴中の作品";
                    list.push(body[0]);
                }
                setResume(list)
            });
        }
    },[resumeList]);

    /**
    * アイちゃんのメッセージ改行.
    */
    const aichanMessage = (string) => {
        return string.split('\n').map((item, index) => {
          return (index === 0) ? item : [<br key={index} />, item]
        })
    }

    const deletePlanetSession=()=>{
        let getSearch = sessionStorage.getItem("planet")
        if(getSearch){
          sessionStorage.removeItem("planet")
        }
    }

    const setSearchSession =(props)=>{
        let getSearch = sessionStorage.getItem("search")
        if(getSearch){
          sessionStorage.removeItem("search")
        }
        const categoryId = props.searchCategoryId
        const searchCategory = [{"category":categoryId,"plan":"P00","keyword":""}]
        sessionStorage.setItem("search",JSON.stringify(searchCategory))  

      }

    /**
     * ライブラリ一覧画面（レコメンド）に遷移.
     */
    const historylibraryIdRecommend = (recommendSpec) => {
        let list = [];
        list.push(libraryId.RECOMMEND);
        list.push(libraryId.TOP);
        list.push(recommendSpec[getTopLibrarySpecKey()]);
        historys.historylibraryId(history, historys.getEncodeId(list));
    };

    /**
     * レコメンド取得 失敗.
     */
    const recommendRequestFailure = useCallback((err) => {
        dispatch(actions.getTopRecommendSuccess(contentArr, ""));
    }, [dispatch, contentArr]);

    /**
     * レコメンド取得 成功.
     */
    const recommendRequestSuccess = useCallback((index, data) => {
        contentArr[index] = data[recommendif.CONTENT_LIST];
        const clickData = data[recommendif.CLICK_DATA];

        dispatch(actions.getTopRecommendSuccess(contentArr, clickData));
    }, [dispatch, contentArr]);

    /**
     * 画面表示時 レコメンド取得処理.
     */
    useEffect(() => {
        const index = blockArr.findIndex((block) => block && block["recommend"]);
        if (index === -1) return;
        if (contentArr[index] !== undefined) return;

        // レコメンド取得
        const recommendBody = {
            [recommendif.SPEC]: blockArr[index]["recommend"][getTopSpecKey()],
            [recommendif.NUM]: matches ? blockArr[index]["numberOfContents"] : blockArr[index]["numberOfContentsSP"],
            [recommendif.RECOMMENDCOOKIE]: recommendCookie,
            [recommendif.PLATFORM]: contentif.getRequestPlatform()
        };
        recommendif.postRecommendRequest(recommendBody).then(recommendRequestSuccess.bind(this, index), recommendRequestFailure);

     }, [blockArr, contentArr, recommendCookie, recommendRequestSuccess, recommendRequestFailure]);

    return (
        <div class="l-main l-index">
            <section class="c-mainvisual">
                <h1 className={classes.logolayer +" c-mainvisual__logo c-index__logo"}>TSUBURAYA・IMAGINATION
                    <picture>
                        <source srcset="./assets/images/logo-brand-imagination-pc.svg" media="(min-width:768px)"/>
                        <img src="./assets/images/logo-brand-imagination-sp.svg" alt="TSUBURAYA IMAGINATION" />
                    </picture>
                </h1>
                {/* 特集バナー */}
                {/* PCの特集バナーの欄でコンテンツが２つ以上の場合、画像スライド */}
                {(topBanner.length > 1) && matches && 
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            prevEl: ".swiper-button-left",
                            nextEl: ".swiper-button-right"
                        }}
                        pagination= {true}
                        loop={true}
                        modules={[Autoplay, Navigation]}
                        className="c-mainvisualSlider swiper-container"
                    >
                        <div class="c-contCardNoCatList__inner swiper-wrapper">
                            {Object.entries(topBanner).map(([, value]) => (
                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item c-contCardNoCatList__item--sizeL ">
                                    <Special meta={value} />
                                </SwiperSlide>
                            ))}
                        </div>
                        
                        <div class="c-mainvisualSlider__pagenation swiper-pagination"></div>
                        {/* 特集バナーの欄でコンテンツが１つの場合、左右の矢印は表示なしとする */}
                        {(topBanner.length > TOP_BANNER_CONTENT_1) && 
                            <div>
                                <div class="c-mainvisualSlider__right swiper-button-right"></div>
                                <div class="c-mainvisualSlider__left swiper-button-left"></div>
                            </div>                           
                        }
                    </Swiper>
                }
                {/* スマートフォンの特集バナーの欄でコンテンツが２つ以上の場合、画像スライド */}
                {(topBanner.length > 1) && !matches && 
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        centeredSlides={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        navigation={{
                            prevEl: ".swiper-button-left",
                            nextEl: ".swiper-button-right"
                        }}
                        pagination= {true}
                        loop={true}
                        modules={[Autoplay, Navigation]}
                        className="c-mainvisualSlider swiper-container"
                    >
                        <div class="c-contCardNoCatList__inner swiper-wrapper">
                            {Object.entries(topBanner).map(([, value]) => (
                                <SwiperSlide key={value[contentsKey.CRID]} className={"c-contCardNoCatList__item c-contCardNoCatList__item--sizeL " + classes.imgwidth}>
                                    <Special meta={value} />
                                </SwiperSlide>
                            ))}
                        </div>
                        
                        <div class="c-mainvisualSlider__pagenation swiper-pagination"></div>
                        {/* 特集バナーの欄でコンテンツが１つの場合、左右の矢印は表示なしとする */}
                        {(topBanner.length > TOP_BANNER_CONTENT_1) && 
                            <div>
                                <div class="c-mainvisualSlider__right swiper-button-right"></div>
                                <div class="c-mainvisualSlider__left swiper-button-left"></div>
                            </div>                           
                        }
                    </Swiper>
                }
                {/* 特集バナーの欄でコンテンツが１つの場合、画像固定 */}
                {(topBanner.length === 1) &&
                    <div className="c-mainvisualSlider">
                        <div className="c-contCardNoCatList__inner">
                            {Object.entries(topBanner).map(([, value]) => (
                                <SwiperSlide key={value[contentsKey.CRID]} className={"c-contCardNoCatList__item c-contCardNoCatList__item--sizeL " + classes.img1page}>
                                    <Special meta={value} />
                                </SwiperSlide>
                            ))}
                        </div>
                    </div>
                }
                {/* アイちゃんメッセージ */}
                <div class="c-mainVisual__box">
                    {greeting && greeting.link !== undefined &&
                        <a class="c-mainVisual__boxWrap" href={greeting.link}>
                            <div class="c-mainVisual__boxSentence">
                                <p class="c-mainVisual__boxSentence__Txt">{aichanMessage(greeting.message)}</p>
                            </div>
                            <p class="c-mainVisual__box--icon">
                                <picture>
                                    <source srcset={greeting.logoUrl.replace('/original/', '/70x70/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                        + greeting.logoUrl.replace('/original/', '/70x70/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                            type="image/webp" />
                                    <img src={greeting.logoUrl} alt="" loading="eager" />
                                </picture>
                            </p>
                        </a>
                    }
                    {greeting && greeting.crid !== undefined &&
                        <div className={classes.clickable +" c-mainVisual__boxWrap"} onClick={() => handleClickAichan()}>
                            <div class="c-mainVisual__boxSentence">
                                <p class="c-mainVisual__boxSentence__Txt">{aichanMessage(greeting.message)}</p>
                            </div>
                            <p class="c-mainVisual__box--icon">
                                <picture>
                                    <source srcset={greeting.logoUrl.replace('/original/', '/70x70/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                        + greeting.logoUrl.replace('/original/', '/70x70/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                            type="image/webp" />
                                    <img src={greeting.logoUrl} alt="" loading="eager" />
                                </picture>
                            </p>
                        </div>
                    }
                    
                </div>
                {/* 未ログインの時のみ表示（サービス紹介画面） */}
                {greeting && greeting.isDisplayWelcomePage &&
                    <div class="c-mainVisual__imagination">
                        <div className={classes.clickable +" c-mainVisual__imaginationWrap"}>
                            <div class="c-mainVisual__imaginationBtn" onClick={() => historyLogged()}>
                                <span class="c-mainVisual__imagination__Txt">TSUBURAYA IMAGINATIONとは</span>
                            </div>
                        </div>
                    </div>
                }              
            </section>
            <div class="c-tabs">
                <div class="c-tabs__inner">
                    <input id="tab--1" type="radio" name="tab_item" checked={active===0} onClick={() => setActiveTab(0)}/>
                    <input id="tab--2" type="radio" name="tab_item" checked={active===1} onClick={() => setActiveTab(1)}/>
                    <div class="c-tabList">
                        <div class="c-tabItem c-tabItem--1"> 
                        <label class="c-tabItem__inner c-tabLabel--1" for="tab--1">IMAGINATION BASE</label>
                        </div>
                        <div class="c-tabItem c-tabItem--2" onClick={()=>deletePlanetSession()}>
                        <label class="c-tabItem__inner c-tabLabel--2" for="tab--2">
                            <div>プラネット<br/><small>（コンテンツリスト）</small></div>
                        </label>
                        </div>
                    </div>
                    <div class="c-tabContent">
                        <div class="c-tabContent__inner" id="tabContent--1">
                            {/** 特集バナー・アイちゃんメッセージ以外の項目自動表示　↓ */}
                            {cornerArr && cornerArr.map((corner,index) => {
                                //おすすめタグ一覧
                                return corner === "searchTag" ? 
                                    <section class="c-sect">
                                    <h2 class="c-sect__band">おすすめタグ一覧</h2>
                                    <div class="c-contTagBox">
                                    <div class="c-contTagList">
                                        {contentArr && index > 0 && contentArr[index] &&
                                        contentArr[index].length !== 0 && 
                                        <ul class="c-contTagList__inner">
                                            {Object.entries(contentArr[index]).map(([, value]) => (
                                                <li class="c-contTagList__item">
                                                    <div className={classes.clickable +" c-contTagList__Txt"} onClick={() => {historySearchByTag(value);setSearchSession(value);}}>{value[searchKey.SEARCH_TAG_TITLE]}</div>
                                                </li>
                                            ))}
                                        </ul>}
                                    </div>
                                    </div>
                                    </section>
                                //リンク
                                : corner === "siteLink" ? 
                                    <section class="c-sect">
                                    <div class="c-sectLinkBox"> 
                                    <div class="c-sectLinkList">
                                        <ul class="c-sectLinkList__inner">
                                        <li class="c-sectLinkList__item c-sectLinkList__item--Block"><a href="https://twitter.com/m78_imagination" target="blank"><img src="/assets/images/icon-x.png" alt="TSUBURAYA IMAGINATION公式"/></a></li>
                                        <li class="c-sectLinkList__item"><a href="https://m-78.jp/" target="blank"><img src="/assets/images/banner-tsuburaya_station.svg" alt="円谷ステーション"/></a></li>
                                        <li class="c-sectLinkList__item"><a href="https://store.m-78.jp/?utm_source=imagination&amp;utm_medium=web&amp;utm_campaign=220401_imgfooter" target="blank"><img src="/assets/images/banner-tsuburaya_store.svg" alt="ツブラヤストア"/></a></li>
                                        <li class="c-sectLinkList__item"><a href="https://ultraman-cardgame.com/page/jp/top" target="blank"><img src="/assets/images/banner-tsuburaya_cardgame.png" alt="ウルトラマン カードゲーム" /></a></li>
                                        </ul>
                                    </div>
                                    </div>
                                    </section>
                                // レコメンド
                                : blockArr && blockArr.length > 0 && blockArr[index] && blockArr[index].recommend && contentArr[index] && contentArr[index].length > 0 ?
                                  <section class="c-sect">
                                    <h2 class="c-sect__band"> {cornerArr[index].cornerSeparatorText}</h2>
                                    <div class={blockArr[index].blockDesign === "carousel" ? classSel_ranking2 : "c-contCardSlideBox"}>
                                        <div class={blockArr[index].blockDesign === "vertical" ? classSel_news : 
                                                    blockArr[index].blockDesign === "miniBanner" ? classSel_kobana : 
                                                    blockArr[index].blockDesign === "carousel" ? classSel_ranking1 :
                                                    classSel}>
                                            <div class="c-contCardCat">
                                                <div class="c-contCardCat__list">
                                                    <div class= {blockArr[index].blockDesign === "carousel" ? classSel_ranking3 : "c-contCardCatList swiper-container js-mainMoviesSlide"}>
                                                        {/** SP用幅設定 */}
                                                        {contentArr[index].length !== 0 && (!matches && blockArr[index].blockDesign === "carousel") && active === 0 &&
                                                        <Swiper
                                                            spaceBetween= {10}
                                                            slidesPerView="auto"
                                                            slidesPerGroup= {2}
                                                            navigation={{
                                                                prevEl: ".swiper-button-prev",
                                                                nextEl: ".swiper-button-next"
                                                            }}
                                                            class="c-contCardCatList__inner swiper-wrapper">

                                                            {/** 出力コンテンツ指定の数だけコンテンツ出力 */}
                                                            {Object.entries(contentArr[index]).map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value} playBack={resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg={blockArr[index].blockDesign} clickData={clickData} />
                                                                </SwiperSlide>
                                                            ))}

                                                            {/** もっと見るボタン(SP) */}
                                                            <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdRecommend(blockArr[index].recommend)}>
                                                                    <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                </span>
                                                            </SwiperSlide>

                                                            <div class="c-contCardCatList__prev swiper-button-prev m-swiper-button-prev"></div>
                                                            <div class="c-contCardCatList__next swiper-button-next"></div>
                                                        </Swiper>
                                                        }
                                                        {/** PC用のコンテンツごとの幅設定 */}
                                                        {contentArr[index].length !== 0 && matches && active === 0 &&
                                                        <Swiper
                                                            spaceBetween= {20}
                                                            slidesPerView="auto"
                                                            slidesPerGroup= {2}
                                                            navigation={{
                                                                prevEl: ".swiper-button-prev",
                                                                nextEl: ".swiper-button-next"
                                                            }}
                                                            class="c-contCardCatList__inner swiper-wrapper">

                                                            {/** allContentsUrlがある場合、制限なく全コンテンツ出力 */}
                                                            {Object.entries(contentArr[index])
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value} playBack={resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg = {blockArr[index].blockDesign} clickData={clickData} />
                                                                </SwiperSlide>
                                                            ))
                                                            }

                                                            {/** もっと見るボタン(PC) */}
                                                            <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdRecommend(blockArr[index].recommend)}>
                                                                    <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                </span>
                                                            </SwiperSlide>

                                                            <div class="c-contCardCatList__prev swiper-button-prev m-swiper-button-prev"></div>
                                                            <div class="c-contCardCatList__next swiper-button-next"></div>
                                                        </Swiper>
                                                        }
                                                        {/** SP用のコンテンツごとの幅設定 */}
                                                        {contentArr[index].length !== 0 && (!matches && blockArr[index].blockDesign !== "carousel") &&
                                                        <ul class="c-contCardCatList__inner swiper-wrapper">
                                                            {Object.entries(contentArr[index])
                                                            .slice(0,contentsSPchk(blockArr[index].blockDesign,index))
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta = {value} playBack = {resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg = {blockArr[index].blockDesign} clickData={clickData}
                                                                    />
                                                                </SwiperSlide>
                                                            ))
                                                            }

                                                            {/** もっと見るボタン（スマホ） */}
                                                            <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdRecommend(blockArr[index].recommend)}>
                                                                    <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                </span>
                                                            </SwiperSlide>
                                                        </ul>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </section>
                                //その他（上記3件以外の全項目を以下で自動対応）
                                : blockArr && blockArr.length > 0 && blockArr[index] && contentArr[index] ?
                                  <section class="c-sect">
                                    {/** コーナーならコーナータイトル表示 */}
                                    { cornerArr && cornerArr.length > 0 && cornerArr[index] && cornerArr[index].cornerSeparatorText != null &&
                                    <h2 class="c-sect__band"> {cornerArr[index].cornerSeparatorText}</h2>}
                                    {/** ブロックならブロックタイトル表示 */}
                                    {blockArr && blockArr.length > 0 && blockArr[index] && blockArr[index].blockSeparatorText != null &&
                                    <h3 class="c-contCardSlideTitle">{blockArr[index].blockSeparatorText}</h3>}
                                    {/** 項目ごとにdiv classタグの区分(blockDesign参照) */}
                                    <div class={blockArr[index].blockDesign === "carousel" ? classSel_ranking2 : "c-contCardSlideBox"}>
                                        <div class={blockArr[index].blockDesign === "vertical" ? classSel_news : 
                                                    blockArr[index].blockDesign === "miniBanner" ? classSel_kobana : 
                                                    blockArr[index].blockDesign === "carousel" ? classSel_ranking1 :
                                                    classSel}>
                                            <div class="c-contCardCat">
                                                <div class="c-contCardCat__list">
                                                    <div class= {blockArr[index].blockDesign === "carousel" ? classSel_ranking3 : "c-contCardCatList swiper-container js-mainMoviesSlide"}>
                                                        {/** SPランキングコンテンツの幅設定 */}
                                                        {contentArr[index].length !== 0 && (!matches && blockArr[index].blockDesign === "carousel") && active === 0 &&
                                                        <Swiper
                                                            spaceBetween= {10}
                                                            slidesPerView="auto"
                                                            slidesPerGroup= {2}
                                                            navigation={{
                                                                prevEl: ".swiper-button-prev",
                                                                nextEl: ".swiper-button-next"
                                                            }}
                                                            class="c-contCardCatList__inner swiper-wrapper">
                                                            
                                                            {/** 出力コンテンツ指定の数だけコンテンツ出力 */}
                                                            {Object.entries(contentArr[index]).slice(0,blockArr[index].numberOfContentsSP).map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value} playBack={resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg={blockArr[index].blockDesign}/>
                                                                </SwiperSlide>
                                                            ))}

                                                            {/** 出力コンテンツ指定の数より用意されてるコンテンツの数が少ない場合/URLが存在しない場合、もっと見るボタンを出力しない */}
                                                            {contentArr[index].length > blockArr[index].numberOfContentsSP && urlArr[index] !== null && 
                                                                <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                    <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdTitle(urlArr[index], blockArr[index].libraryPageTitle)}>
                                                                        <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                    </span>
                                                                </SwiperSlide>
                                                            }

                                                            <div class="c-contCardCatList__prev swiper-button-prev m-swiper-button-prev"></div>
                                                            <div class="c-contCardCatList__next swiper-button-next"></div>
                                                        </Swiper>
                                                        }
                                                        {/** PC用のコンテンツごとの幅設定 */}
                                                        {contentArr[index].length !== 0 && matches && active === 0 &&
                                                        <Swiper
                                                            spaceBetween= {20}
                                                            slidesPerView="auto"
                                                            slidesPerGroup= {2}
                                                            navigation={{
                                                                prevEl: ".swiper-button-prev",
                                                                nextEl: ".swiper-button-next"
                                                            }}
                                                            class="c-contCardCatList__inner swiper-wrapper">
                                                            
                                                            {/** allContentsUrlがある場合、制限なく全コンテンツ出力 */}
                                                            {/** 必見orプレミアムコンテンツの場合、視聴中のサムネイルを出力 */}
                                                            {blockArr[index].isShowResume && resume && resume[0] && 
                                                                <SwiperSlide className="c-contCardCatList__item">
                                                                    <DefaultResume meta={resume[0]} playBack={isResume(resume[0][contentsKey.CRID])}/>
                                                                </SwiperSlide>
                                                            }
                                                            {/** 上記以外のサムネイルを出力 */}
                                                            {/** 必見orプレミアムコンテンツの場合、重複チェックを行った上でサムネイルを作成 */}
                                                            {resume && resume[0] && blockArr[index].isShowResume ? 
                                                            Object.entries(contentArr[index]).filter(([, value]) => value[contentsKey.CRID] !== resume[0][contentsKey.CRID])
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value}
                                                                    contflg = {blockArr[index].blockDesign}/>
                                                                </SwiperSlide>
                                                            ))
                                                            : Object.entries(contentArr[index])
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value} playBack={resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg = {blockArr[index].blockDesign}/>
                                                                </SwiperSlide>
                                                            ))
                                                            }

                                                            {/** もっと見るボタン表示フラグ(PC)がtrueの場合 */}
                                                            {blockArr[index].isShowMoreButton &&
                                                                <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                    <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdTitle(urlArr[index], blockArr[index].libraryPageTitle)}>
                                                                        <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                    </span>
                                                                </SwiperSlide>
                                                            }
                                                            
                                                            <div class="c-contCardCatList__prev swiper-button-prev m-swiper-button-prev"></div>
                                                            <div class="c-contCardCatList__next swiper-button-next"></div>
                                                        </Swiper>
                                                        }
                                                        {/** ランキング以外のSP用のコンテンツごとの幅設定 */}
                                                        {contentArr[index].length !== 0 && (!matches && blockArr[index].blockDesign !== "carousel") &&
                                                        <ul class="c-contCardCatList__inner swiper-wrapper">

                                                            {/** 出力コンテンツ指定の数だけコンテンツ出力 */}
                                                            {/** 必見orプレミアムコンテンツの場合、視聴中のサムネイルを出力 */}
                                                            {blockArr[index].isShowResume && resume && resume[0] && 
                                                                <SwiperSlide className="c-contCardCatList__item">
                                                                    <DefaultResume meta={resume[0]} playBack={isResume(resume[0][contentsKey.CRID])}/>
                                                                </SwiperSlide>
                                                            }
                                                            {/** 上記以外のサムネイルを出力 */}
                                                            {/** 必見orプレミアムコンテンツの場合、視聴中コンテンツとの重複チェックを行った上でサムネイルを作成 */}
                                                            {blockArr[index].isShowResume && resume && resume[0] ? 
                                                            Object.entries(contentArr[index]).filter(([, value]) => value[contentsKey.CRID] !== resume[0][contentsKey.CRID])
                                                            .slice(0,contentsSPchk(blockArr[index].blockDesign,index))
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta={value}
                                                                    contflg = {blockArr[index].blockDesign} pickupflg = {blockArr[index].blockDesign === "vertical" && pickupCount(count)}
                                                                    pickupColor = {blockArr[index].pickup !== undefined ? blockArr[index].pickup.pickupColor : ""}
                                                                    />
                                                                </SwiperSlide>
                                                            ))
                                                            : Object.entries(contentArr[index])
                                                            .slice(0,contentsSPchk(blockArr[index].blockDesign,index))
                                                            .map(([, value]) => (
                                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardCatList__item">
                                                                    <DefaultTop meta = {value} playBack = {resume[0] && resume[0][contentsKey.CRID] === value[contentsKey.CRID] && isResume(value[contentsKey.CRID])}
                                                                    contflg = {blockArr[index].blockDesign} pickupflg = {blockArr[index].blockDesign === "vertical" && pickupCount(count)}
                                                                    pickupColor = {blockArr[index].pickup !== undefined ? blockArr[index].pickup.pickupColor : ""}
                                                                    />
                                                                </SwiperSlide>
                                                            ))
                                                            }

                                                            {/** もっと見るボタン表示フラグ(スマホ)がtrueの場合 */}
                                                            {blockArr[index].isShowMoreButtonSP &&
                                                                <SwiperSlide className="c-contCardCatList__item c-contCardCatList__item--more swiper-slide">
                                                                    <span className={" c-contCardCatList__itemMore"} onClick={() => historylibraryIdTitle(urlArr[index], blockArr[index].libraryPageTitle)}>
                                                                        <div className="c-contCardCatList__itemMoreTxt">もっと見る</div>
                                                                    </span>
                                                                </SwiperSlide>
                                                            }
                                                        </ul>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                : <>/</>
                            })}
                        </div>
                    </div>
                    <div class="c-tabContent">
                        <div class="c-tabContent__inner c-tabContent__planet" id="tabContent--2">
                            <h2 class="c-sect__band">プラネットセレクト</h2>
                            <div class="c-container">
                                <div class="p-planet">
                                {planetGroupList.planetGroup && Object.entries(planetGroupList.planetGroup).map(([key, group]) => (
                                    <div>
                                        <h3 class="c-contCardSlideTitle">{group.groupName}</h3>
                                        <div class="p-planet__ultraSelect">
                                            {/* PC用の各グループのコンテンツ */}
                                            {matches && active === 1 && 
                                                <div class="swiper-container js-mainUltraSelect">
                                                    <ul class="p-planet__ultraSelect__wrap swiper-wrapper">
                                                        {group.planetIdList && Object.entries(group.planetIdList).map(([key, planetId]) => (
                                                            planetGroupList.planetMaster && Object.entries(planetGroupList.planetMaster)
                                                                .filter(([key, value]) => (value[contentsKey.PLANET_ID] === planetId))
                                                                .map(([key, value]) => (
                                                                    <li key={encode(value[contentsKey.PLANET_ID])} class="p-planet__ultraSelect__item swiper-slide">
                                                                        <PlanetContents meta={value}/>
                                                                    </li>
                                                                    )
                                                                )
                                                            )
                                                        )}
                                                    </ul>
                                                </div>
                                            }
                                            {/* SP用の各グループのコンテンツ */}
                                            {!matches && active === 1 && 
                                                <Swiper
                                                    observer={true}
                                                    observeParents={true}
                                                    spaceBetween={10}
                                                    slidesPerView="auto"
                                                    className="swiper-container js-mainUltraSelect"
                                                >
                                                    <div class="p-planet__ultraSelect__wrap swiper-wrapper">
                                                        {group.planetIdList && Object.entries(group.planetIdList).map(([key, planetId]) => (
                                                            planetGroupList.planetMaster && Object.entries(planetGroupList.planetMaster)
                                                                .filter(([key, value]) => (value[contentsKey.PLANET_ID] === planetId))
                                                                .map(([key, value]) => (
                                                                    <li key={encode(value[contentsKey.PLANET_ID])} class="p-planet__ultraSelect__item swiper-slide">
                                                                        <PlanetContents meta={value}/>
                                                                    </li>
                                                                    )
                                                                )
                                                            )
                                                        )}
                                                    </div>                                                   
                                                </Swiper>
                                            }
                                        </div>
                                    </div>
                                ))}                             
                                </div>
                            </div>
                            <h2 class="c-sect__band">すべてのプラネット</h2>
                            <div class="c-container">
                                <div class="p-planet p-planet--all">
                                    <div class="p-planet__ultraSelect">
                                        <ul class="p-planet__ultraSelect__wrap">
                                        {Object.entries(planetList).map(([key, value]) => (
                                            <li key={encode(value[contentsKey.PLANET_ID])} class="p-planet__ultraSelect__item swiper-slide">
                                                <PlanetContents meta={value} />
                                            </li>
                                        ))}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    );
}

export default Top;
