import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as accountif from "../middleware/accountif";
import { favoriteFailure, loading } from "../common/status/actions";

const useFavorite = (crid) => {
    /** お気に入り状態. */
    const [favStatus, setFavStatus] = useState(false);
    /** お気に入りボタンDOM. */
    const favRef = useRef();
    /** Hooks. */
    const dispatch = useDispatch();
    /** お気に入り一覧. */
    const favoriteList = useSelector(state => state.Member.favoriteList);

    /**
     * お気に入りボタン表示切替.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (!favRef.current || !favRef.current.classList) return;

        // お気に入り一覧にコンテンツIDがあるか探す.
        for (const fav of favoriteList) {
            if (fav === crid) {
                // あればお気に入り状態にする.
                favRef.current.classList.add("is-active");
                setFavStatus(true);
                return;
            }
        }
        // なければお気に入りではない状態にする.
        setFavStatus(false);
        favRef.current.classList.remove("is-active");
    }, [dispatch, crid, favoriteList]);


    /**
     * お気に入り変更失敗.
     */
    const getFavoriteFailure = (err) => {
        // セッションエラーを確認.
        accountif.isSessionError(dispatch, err);
        // お気に入り削除失敗.
        if (favStatus) {
            dispatch(favoriteFailure(true, "お気に入りの解除に失敗しました。"));
        }
        // お気に入り追加失敗.
        else {
            dispatch(favoriteFailure(true, "お気に入りの追加に失敗しました。"));
        }
    };

    /**
     * お気に入り追加/削除.
     */
    const handleFav = () => {
        dispatch(loading(true));
        accountif.postFavorite({changeFlg: !favStatus, crid: crid})
        .then(accountif.getFavoriteSuccess.bind(this, dispatch), getFavoriteFailure)
        .finally(() => dispatch(loading(false)));
    };

    return [favRef, handleFav];
};

export default useFavorite;