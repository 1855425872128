/**
 * キャンペーン.
 */

import * as historys from "../historys";
import * as commonActions from "../common/status/actions";
import * as screenActions from "../common/status/screen/actions";
import * as app from "../constants/app";
import * as contentsKey from "../constants/contentsKey";
import * as contentif from "../middleware/contentif";
import { decode } from "js-base64";

/** クーポンID. */
export const COUPON_ID = "couponId";

/** キャンペーンID. */
export const CAMPAIGN_ID = "campaignId";

/** キャンペーン遷移先. */
export const DESTINATION_TYPE = "destinationType";

/** 詳細画面 映像. */
export const VIDEO = "video";

/** 詳細画面 情報. */
export const WEB = "web";

/** 詳細画面 情報. */
export const CUBE = "cube";

/** コンテンツID. */
export const CRID = "crid";

/** ライセンスID. */
export const LICENSE_ID = "licenseId";

/** 図鑑ID. */
export const CUBE_GROUP_ID = "cubeGroupId";

/** スクロール先キューブID. */
export const CUBE_ID = "cubeId";

/** キャンペーン名. */
export const CAMPAIGN_NAME = "campaignName";

/** キャンペーン取得日. */
export const USE_DATE_TIME = "useDatetime";

/** 新規フラグ. */
export const NEW_FLG = "newFlg";

/** クーポン行使ステータス. */
export const STATUS = "status";

/** 有効チェックのみ. */
export const VALID = "valid";

/** 即時行使. */
export const COMPLETED = "completed";

/** LPのCRID. */
export const LP_CRID = "lpCrid";

/** 即時利用フラグ. */
export const IMMEDIATE_FLG = "immediateFlg";

/** 特典元CRID. */
export const GIFT_SOURCE_CRID = "giftSourceCrid";

/** 遷移元がキャンペーン一覧. */
export const SOURCE_CAMPAIGN_LIST = "SOURCE_CAMPAIGN_LIST";

/** 遷移元がクーポン行使画面. */
export const SOURCE_COUPON_USED = "SOURCE_COUPON_USED";

/**
 * 動画ページへの遷移処理.
 */
const historyVideoId = (history, crid) => {
    // QRクッション画面からは履歴保存なし.
    if (window.location.pathname.indexOf("/coupon/") !== -1) {
        historys.historyReplaceVideoId(history, crid);
    }

    // 動画ページからは、CRIDが同じ場合は履歴保存なし.
    else if (window.location.pathname.indexOf("/video/") !== -1) {
        // 現在のページのCRIDを取得.
        const splitPathname = window.location.pathname.split("/");
        const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
        if (contentsId === crid) {
            historys.historyReplaceVideoId(history, crid);
        } else {
            historys.historyVideoId(history, crid);
        }
    }

    // その他のページ.
    else historys.historyVideoId(history, crid);
}

/**
 * 情報ページへの遷移処理.
 */
const historyContents = (history, meta) => {
    // QRクッション画面からは履歴保存なし.
    if (window.location.pathname.indexOf("/coupon/") !== -1) {
        historys.historyReplaceContents(history, meta[CRID]);
    }

    // 情報ページからは、CRIDが同じ場合は遷移なし.
    else if (window.location.pathname.indexOf("/contents/") !== -1) {
        // 現在のページのCRIDを取得.
        const splitPathname = window.location.pathname.split("/");
        const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
        if (!(contentsId === meta[CRID])) {
            historys.historyContents(history, meta[CRID]);
        }
    }

    // その他のページ.
    else historys.historyContents(history, meta[CRID]);
}

/**
 * 遷移元を閉じる処理.
 */
const closeSource = (dispatch, source) => {
    // 遷移元がキャンペーン一覧の場合は閉じる.
    if (source === SOURCE_CAMPAIGN_LIST) {
        dispatch(screenActions.changeIsProf(false));
    }

    // 遷移元がクーポン行使画面の場合は閉じる.
    if (source === SOURCE_COUPON_USED) {
        dispatch(commonActions.couponUsed(false, {}));
    }
}

/**
 * ライセンスID検索 失敗.
 */
const postLicenseSearchFailure = (dispatch, source, err) => {
    closeSource(dispatch, source);
    // キャンペーン遷移失敗ダイアログ.
    dispatch(commonActions.campaignDestinationFailure(true));
}

/**
 * ライセンスID検索 成功.
 */
const postLicenseSearchSuccess = (dispatch, history, source, data) => {
    // 遷移先が1件の場合は、そのコンテンツに遷移.
    if (data.length === 1) {
        closeSource(dispatch, source);
        // 動画ページに遷移.
        historyVideoId(history, data[0][contentsKey.CRID]);
        return;
    }

    // 遷移先が2件以上の場合は、1話のコンテンツを探して遷移.
    if (data.length > 1) {
        for (const content of data) {
            if (content[contentsKey.EPI_NUMBER] === "1") {
                closeSource(dispatch, source);
                // 動画ページに遷移.
                historyVideoId(history, content[contentsKey.CRID]);
                return;
            }
        }
    }

    // キャンペーン遷移失敗ダイアログ.
    closeSource(dispatch, source);
    dispatch(commonActions.campaignDestinationFailure(true));
}

/**
 * キャンペーンに指定されている遷移先に遷移.
 */
export const handleDestination = (dispatch, history, meta, source) => {
    // 遷移先が情報ページの場合.
    if (meta[DESTINATION_TYPE] === WEB) {
        // アプリとWebで処理切替.
        if (app.isAllApp()) {
            // アプリの場合、表示可能なLPか確認する.
            dispatch(commonActions.loading(true));

            // ID検索で確認.
            const requestBody = {
                [contentif.CONTENT_ID_LIST]: [meta[CRID]],
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postIdSearch(requestBody).then((res) => {
                if (res.length > 0) {
                    // アプリで表示できるLPなので、LPに遷移.
                    historyContents(history, meta);
                } else {
                    // アプリで表示できないLPなので、警告ダイアログ表示.
                    dispatch(commonActions.appNotDisplayLP(true));
                }
            })
            .catch(() => dispatch(commonActions.appNotDisplayLP(true)))
            .finally(() => {
                closeSource(dispatch, source);
                dispatch(commonActions.loading(false));
            });
        } else {
            // アプリでない場合、確認なしでLPに遷移.
            closeSource(dispatch, source);
            historyContents(history, meta);
        }
    }

    // 遷移先がスペシャル図鑑の場合.
    else if (meta[DESTINATION_TYPE] === CUBE) {
        closeSource(dispatch, source);
        dispatch(screenActions.changeIsCampaignList(false));
        dispatch(screenActions.changeIsSpecialCubeGroupList(true));
        dispatch(screenActions.changeIsSpecialCubeGroup(true, meta[CUBE_GROUP_ID], source === SOURCE_CAMPAIGN_LIST));
        // QRクッション画面からはTOP画面へ遷移.
        if (window.location.pathname.indexOf("/coupon/") !== -1) {
            historys.historyReplaceTop(history);
        }
    }

    // 遷移先が映像ページの場合.
    else if (meta[DESTINATION_TYPE] === VIDEO) {
        // 遷移先が無い場合は何もしない.
        if (meta[LICENSE_ID].length === 0) {
            closeSource(dispatch, source);
            dispatch(commonActions.campaignDestinationFailure(true));
        }

        // ライセンスIDでメタ検索.
        contentif.postLicenseSearch({
            [contentif.LICENSE_ID_LIST]: meta[LICENSE_ID],
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        }).then(
            postLicenseSearchSuccess.bind(this, dispatch, history, source),
            postLicenseSearchFailure.bind(this, dispatch, source)
        );
    }

    else {
        // キャンペーン遷移失敗ダイアログ.
        closeSource(dispatch, source);
        dispatch(commonActions.campaignDestinationFailure(true));
    }
}

/**
 * クーポンが有効か.
 */
export const isValid = (res) => {
    return res[STATUS] === VALID;
}

/**
 * クーポンが即時行使に成功したか.
 */
export const isCompleted = (res) => {
    return res[STATUS] === COMPLETED
}