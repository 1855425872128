import * as actionTypes from "./actionTypes";

/**
 * ボリューム変更.
 */
export const handleVolume = (volume) => {
    return {
        type: actionTypes.HANDLE_VOLUME,
        volume: volume
    };
}

/**
 * ミュート判定.
 */
export const handleMute = (muted) => {
    return {
        type: actionTypes.HANDLE_MUTE,
        muted: muted
    };
}

/**
 * 画質設定ポップアップの表示/非表示.
 */
export const changeIsImageQuality = (flag) => {
    return {
        type: actionTypes.CHANGE_IS_IMAGE_QUALITY,
        changeIsImageQuality: flag
    };
}

/**
 * 全体設定の画質（ブラウザ版）
 */
export const allQuality = (allQuality) => {
    return {
        type: actionTypes.ALL_QUALITY,
        allQuality: allQuality
    };
}

/**
 * 全体設定の画質（アプリ版/Wi-Fi）
 */
export const allWifiQuality = (allWifiQuality) => {
    return {
        type: actionTypes.ALL_WIFI_QUALITY,
        allWifiQuality: allWifiQuality
    };
}

/**
 * 全体設定の画質（アプリ版/モバイル）
 */
export const allMobileQuality = (allMobileQuality) => {
    return {
        type: actionTypes.ALL_MOBILE_QUALITY,
        allMobileQuality: allMobileQuality
    };
}