import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { LOGOUT, WITHDRAWAL } from "./common/status/actionTypes";
import Common from "./common/status/reducer";
import Coupon from "./common/status/coupon/reducer";
import Cast from "./common/status/cast/reducer";
import Member from "./common/status/member/reducer";
import Screen from "./common/status/screen/reducer";
import LocalCredit from "./common/status/localCredit/reducer";
import NoneNotice from "./common/status/noneNotice/reducer";
import RecommendCookie from "./common/status/recommendCookie/reducer";
import ContentsId from "./components/screen/contentsId/reducer";
import LibraryId from "./components/screen/libraryId/reducer";
import PlanetId from "./components/screen/planetId/reducer";
import Search from "./components/screen/search/reducer";
import Top from "./components/screen/top/reducer";
import VideoId from "./components/screen/videoId/reducer";
import Player from "./components/player/reducer";
import Plan from "./common/popup/plan/reducer";
import Credit from "./common/popup/credit/reducer";
import Notice from "./common/popup/notice/reducer";
import Lecture from "./common/popup/lecture/reducer";
import LectureFirst from "./common/popup/lecture/reducerFrist";
import Cushion from "./components/screen/cushion/reducer";
import Grid from "./common/status/grid/reducer";
import ImageQualityPopup from "./common/popup/imageQuality/reducer";
import statusQuality from "./common/status/statusQuality/reducer";

/** Session storage設定. */
const sessionPersistConfig = {
    key: "member",
    storage: storageSession
};

/**
 * 全てのReducerを結合する.
 */
const appReducer = combineReducers({
    Common,
    Member: persistReducer(sessionPersistConfig, Member),
    Screen,
    Plan,
    Credit,
    Notice,
    Lecture,
    LectureFirst,
    Top,
    Search: persistReducer(sessionPersistConfig, Search),
    PlanetId: persistReducer(sessionPersistConfig, PlanetId),
    LibraryId,
    VideoId,
    ContentsId,
    Player,
    Cushion,
    Coupon,
    LocalCredit,
    Cast,
    NoneNotice,
    Grid,
    ImageQualityPopup,
    statusQuality,
    RecommendCookie
});

const reducers = (state, action) => {
    if (action.type === LOGOUT) {
        const { Player, LectureFirst, Cushion, Coupon, NoneNotice, RecommendCookie, Grid } = state;
        state = { Player, LectureFirst, Cushion, Coupon, NoneNotice, RecommendCookie, Grid };
    }
    if (action.type === WITHDRAWAL) {
        const { Player, NoneNotice, RecommendCookie, Grid } = state;
        state = { Player, NoneNotice, RecommendCookie, Grid };
    }
    return appReducer(state, action);
}
export default reducers;
