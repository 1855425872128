import * as actionTypes from "./actionTypes";

/** みんなが見ているコンテンツ表示条件数. */
const RANKING_DISPLAY = 8;

/**
 * 検索コンテンツリスト更新.
 */
export const updateSearchContentsList = (data) => {
    return {
        type: actionTypes.UPDATE_SEARCH_CONTENTS_LIST,
        contentsList: data
    };
}

/**
 * 選択中カテゴリー更新.
 */
export const updateSearchCurrentCategory = (data) => {
    return {
        type: actionTypes.UPDATE_SEARCH_CURRENT_CATEGORY,
        currentCategory: data
    };
}

/**
 * 選択中プラン更新.
 */
export const updateSearchCurrentPlan = (data) => {
    return {
        type: actionTypes.UPDATE_SEARCH_CURRENT_PLAN,
        currentPlan: data
    };
}

/**
 * ファイル：ランキングメタJSON取得 成功.
 */
 export const getRankingMetaSuccess = (body) => {
    return {
        type: actionTypes.GET_RANKING_META_SUCCESS,
        ranking_meta: body.slice(0, RANKING_DISPLAY)
    };
}

/**
 * ファイル：back操作取得.
 */
export const backTrue = () => {

        return {
            type: actionTypes.BACKTRUE,
        };
}

/**
 * ファイル：フッターサーチ画面クリック時.
 */
export const footerSearch = () => {

    return {
        type: actionTypes.FOOTERSEARCH,
    };
}

/**
 * レコメンドコンテンツ取得 成功.
 */
export const updateRecommendContentsSuccess = (contentsList, clickData) => {
    return {
        type: actionTypes.UPDATE_SEARCH_RECOMMEND_CONTENTS_LIST,
        contentsList: contentsList,
        clickData: clickData
    };
}
