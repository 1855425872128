import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Parallax from "react-rellax";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { ENVIRONMENT_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * 推奨環境画面.
 */
const Environment = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.environment());

        // head設定.
        setHeadDefault(ENVIRONMENT_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo"><img src="./assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination" /></div>
                    <div className="c-static__box">
                        <div className="c-static__boxTop">
                            <div className="c-static__boxTopTtl">推奨環境について</div>
                        </div>
                        <ul className="c-static__wrap">
                            <li className="c-static__item">
                                <div className="c-static__ttl">アプリケーション</div>
                                <div className="c-static__dtl">【Android】<br/>対応OS：Ver.8/9/10/11/12/13/14<br/>【iOS】<br/>対応OS：Ver.13/14/15/16/17</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">Webブラウザ</div>
                                <div className="c-static__subTtl">PC</div>
                                <div className="c-static__dtl">【Windows】<br/>対応OS：最新Windows10/11<br/>ブラウザ：Chrome<br/>【Mac】<br/>対応OS：最新MacOS<br/>ブラウザ：Safari,Chrome</div>
                            </li>
                            <li className="c-static__item c-static__item--subItem">
                                <div className="c-static__subTtl c-static__subTtl--borderTop">スマートフォン</div>
                                <div className="c-static__dtl">【Android】<br/>対応OS：Ver.8/9/10/11/12/13/14<br/>ブラウザ：Chrome<br/>【iOS】<br/>対応OS：Ver.13/14/15/16/17<br/>ブラウザ：Safari</div>
                            </li>
                            <li className="c-static__item c-static__item--subItem">
                                <div className="c-static__subTtl c-static__subTtl--borderTop">タブレット</div>
                                <div className="c-static__dtl">【Android】<br/>対応OS：Ver.8/9/10/11/12/13/14<br/>ブラウザ：Chrome<br/>【iOS】<br/>対応OS：Ver.13/14/15/16/17<br/>ブラウザ：Safari</div>
                            </li>
                            <li className="c-static__item c-static__item--subItem">
                                <div className="c-static__subTtl c-static__subTtl--borderTop">テレビデバイス</div>
                                <div className="c-static__dtl">【Apple TV】<br/>Apple TV 4K (第3世代)<br/>Apple TV 4K (第2世代)<br/>Apple TV 4K (第1世代)<br/>Apple TV (第4世代)</div>
                            </li>
                            <li className="c-static__enviAtn">
                                <div className="c-static__dtl c-static__dtl--enviAtn">その他の環境においてはサービス利用の際に不具合が起こる可能性があります。<br/>あらかじめご了承ください。<br/><span className="c-static__smallDtl">※ 一部サービスで推奨環境に指定がある場合は、そちらに準じます。<br/>※ すべての環境で動作保証をするものではありません。推奨環境でも使用する機種・ブラウザによって、ご利用できない場合、もしくは正しく表示されない場合があります。</span></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Environment;
