import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import * as historys from "../../../historys";
import * as contentsKey from "../../../constants/contentsKey";
import styles from "./styles";
import "animate.css/animate.min.css";

/**
 * プラネットコンテンツ.
 *
 * props
 *  -> meta        コンテンツのメタ.
 */
const Planet = (props) => {
    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const history = useHistory();

    /** アイコンDOM. */
    const itemIcon = useRef();
    /** ロゴDOM. */
    const itemLogo = useRef();

    /**
     * プラネット別一覧画面に遷移.
     */
    const historyPlanetId = (id) => {
        const getSession = sessionStorage.getItem("planet")
        if(getSession){
            sessionStorage.removeItem("planet")
        }
        historys.historyPlanetId(history, id);
    };

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    return (
        <span
            className={classes.clickable}
            onClick={() => {historyPlanetId(props.meta[contentsKey.PLANET_ID]);}}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-planet__ultraSelect__itemIcon" ref={itemIcon}>
                <picture>
                    <source srcset={props.meta[contentsKey.PLANET_ICON].replace('/original/', '/132x132/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                    + props.meta[contentsKey.PLANET_ICON].replace('/original/', '/132x132/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                    <img src={props.meta[contentsKey.PLANET_ICON]} alt="" loading="lazy" />
                </picture>
            </div>
            <div className="p-planet__ultraSelect__itemLogo" ref={itemLogo}>
                <picture>
                    <source srcset={props.meta[contentsKey.PLANET_TITLE_LOGO].replace('/original/', '/132x60/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                    + props.meta[contentsKey.PLANET_TITLE_LOGO].replace('/original/', '/132x60/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                    <img src={props.meta[contentsKey.PLANET_TITLE_LOGO]} alt="" loading="lazy" />
                </picture>
            </div>
        </span>
    );
};

export default Planet;
