import React, { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { isIOS, isMobile, isSafari } from "react-device-detect";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import * as actions from "./actions";
import * as commonActions from "../../common/status/actions";
import * as videoIdActions from "../../components/screen/videoId/actions";
import * as constants from "../../constants/player";
import * as mediaType from "../../constants/mediaType";
import * as contentsKey from "../../constants/contentsKey";
import * as app from "../../constants/app";
import * as constansQuality from "../../constants/imageQuality";
import { VIDEO_CONVERSION_THRESHOLD, getDeviceId } from "../../constants/recommend";
import { styles } from "./styles";

import ImageQualityPopup from "../../common/popup/imageQuality/ImageQualityPopup";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FAIRPLAY_CERT } from "../../env";
import { STOP_POSITION } from "../../middleware/accountif";
import * as recommendif from "../../middleware/recommendif";
import useRecommendCookie from "../../hooks/useRecommendCookie";

const screenfull = require("screenfull");

const Player = (props) => {
    /** 内部ステータス（ページ遷移すると初期化される）. */
    // スライダー(シークバー・音量バー)を動かしているかどうか.
    const [sliderChanging, setSliderChanging] = useState(false);
    // シークバーを動かしているかどうか.
    const [seekChanging, setSeekChanging] = useState(false);
    // 現在の再生時間.
    const [currentTime, setCurrentTime] = useState(0);
    // 読み込みできている時間.
    const [buffered, setBuffered] = useState(0);
    // フルスクリーン状態かどうか.
    const [isFullscreen, setIsFullscreen] = useState(false);
    // 動画読み込み中かどうか.
    const [isLoading, setIsLoading] = useState(false);
    // バッファリング中かどうか.
    const [isBuffering, setIsBuffering] = useState(false);
    // コントロールバー表示判定.
    const [isDisplayedControls, setIsDisplayedControls] = useState(false);
    // 連続再生の動画数カウント.
    const [videoCount, setVideoCount] = useState(0);
    // 連続再生で次話へ遷移するまでの待機状態.
    const [isWaiting, setIsWaiting] = useState(false);
    // 連続再生待機のカウントダウン変数.
    const [waitingCount, setWaitingCount] = useState(constants.INITIAL_WAITING_COUNT);
    // 次話のサムネイル.
    const [nextThumnail, setNextThumnail] = useState("");
    // 次話のタイトル.
    const [nextTitle, setNextTitle] = useState("");

    /** Hooks. */
    const dispatch = useDispatch();
    // 動画(videoタグ)のDOM.
    const video = useRef();
    // mediaSDKの実行結果を格納.
    const player = useRef(null);
    // 動画プレイヤー全体のDOM.
    const videoScreen = useRef();
    // マウスカーソル監視用のref.
    const cursorRef = useRef();
    // 動画全体の長さ.
    const duration = useRef(0);
    // シークバークリック前の再生状態.
    const beforeSeekPaused = useRef(false);
    // 読込完了前の時間保存.
    const beforeTime = useRef(0);
    // iOSのネットワークエラー監視用.
    const iOSNetworkErrorIntervalId = useRef(null);
    // iOSのネットワークエラータイムアウトカウント.
    const iOSNetworkErrorCount = useRef(0);
    // ポーリングインターバル格納用変数.
    const pollingId = useRef();
    /** 再生識別文字列のref. */
    const playTokenRef = useRef();
    // 待機時間更新インターバル格納用変数.
    const waitingCountId = useRef();
    // コンバージョン通知対象の再生時間.
    const conversionDuration = useRef(0);
    // コンバージョン通知したか.
    const conversionRef = useRef(false);

    /** Reduxステータス. */
    // 音量.
    const volume = useSelector(state => state.Player.volume);
    // ミュート.
    const muted = useSelector(state => state.Player.muted);
    /** コンテンツ情報. */
    const content = useSelector(state => state.VideoId.content);
    /** 再生URL. */
    const playUrl = useSelector(state => state.VideoId.playUrl);
    /** DRMモード. */
    const drmMode = useSelector(state => state.VideoId.drmMode);
    /** DRM情報. */
    const customData = useSelector(state => state.VideoId.customData);
    /** ライセンスサーバURL. */
    const licenseUrl = useSelector(state => state.VideoId.licenseUrl);
    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);
    /** 再生識別文字列. */
    const playToken = useSelector(state => state.VideoId.playToken);
    /** ユーザID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** 画質設定ポップアップの表示/非表示 */
    const isImageQuality = useSelector(state => state.Player.changeIsImageQuality);
    // 画質詳細設定
    const detailQuality = useSelector(state => state.ImageQualityPopup.detailQuality);
    // 画質全体設定（ブラウザ版）
    const allQuality = useSelector(state => state.Player.allQuality);
    // 画質全体設定（アプリ版/Wi-FI）
    const allWifiQuality = useSelector(state => state.Player.allWifiQuality);
    // 画質全体設定（アプリ版/モバイル)
    const allMobileQuality = useSelector(state => state.Player.allMobileQuality);
    /** Wi-Fi/モバイル判定 */
    const isWifi = useSelector(state => state.statusQuality.isWifi);
    /** 画質変更切替フラグ. */
    const isChangedQuality = useSelector(state => state.VideoId.isChangedQuality);
    // Wi-Fiステータス切替判定用.
    const prevWifiStatus = useRef(isWifi);
    // Wi-Fiステータス監視用格納Ref.
    const checkWifiStatusRef = useRef(null);
    // 画質設定ポップアップDOM.
    // リアルタイムに画質設定ポップアップの表示/非表示の状態管理するフラグ.
    const imageQualityDom = useRef();

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /** props. */
    // 映像(V) or ライブ(L).
    const type = props.type;

    /**
     * ネットワークエラー時の再試行.
     */
    const networkError = () => {
        dispatch(commonActions.updateConectionError(true));
    }

    /**
     * iOSネットワークエラー監視開始.
     */
    const iOSStartNetworkErrorInterval = () => {
        if (!video.current) return;

        if (isIOS && !video.current.paused && iOSNetworkErrorIntervalId.current === null) {
            iOSNetworkErrorIntervalId.current = setInterval(() => {
                iOSNetworkErrorCount.current = iOSNetworkErrorCount.current + 1;
                if (iOSNetworkErrorCount.current > 10) {
                    iOSNetworkErrorCount.current = 0;
                    destroyPlayer();
                    networkError();
                    clearInterval(iOSNetworkErrorIntervalId.current);
                    iOSNetworkErrorIntervalId.current = null;
                }
            }, 1000);
        }
    };

    /**
     * iOSネットワークエラー監視終了.
     */
    const iOSStopNetworkErrorInterval = (isPlayerBuffering) => {
        if (!video.current) return;

        // ネットワーク計測中にplayer.buffering=falseなら停止.
        if (isIOS && iOSNetworkErrorIntervalId.current !== null && !isPlayerBuffering) {
            clearInterval(iOSNetworkErrorIntervalId.current);
            iOSNetworkErrorIntervalId.current = null;
        }
        // ネットワーク計測中にvideo.paused=trueなら停止.
        if (isIOS && video.current.paused && iOSNetworkErrorIntervalId.current !== null) {
            clearInterval(iOSNetworkErrorIntervalId.current);
            iOSNetworkErrorIntervalId.current = null;
        }
    };

    const initImageQuality = () => {
        video.current.removeEventListener("loadeddata", initImageQuality);
        if (drmMode !== constants.FAIR_PLAY) {
            if (detailQuality < 10) { // 詳細画質設定がある場合は、詳細画質設定を引き継いで再生
                const maxDetailQualityIndex = (content.videoDifinition === "SD" ? constansQuality.SD_QUALITY_LIST.length : constansQuality.HD_QUALITY_LIST.length) - 1;
                player.current.setEnabledVideoRepresentationIndexes(Math.min(detailQuality, maxDetailQualityIndex), true);
            }
            else {
                if (app.isAllApp() && app.isLatestApp()) { // ブラウザ・アプリ判定
                    if (isWifi) { // アプリ版/Wi-Fi
                        player.current.setEnabledVideoRepresentationIndexes(allWifiQuality ? constansQuality.DATASAVER_INDEX_LIST : [], true);
                    } else { // アプリ版/モバイル
                        player.current.setEnabledVideoRepresentationIndexes(allMobileQuality ? constansQuality.DATASAVER_INDEX_LIST : [], true);
                    }
                } else { // ブラウザ版
                    player.current.setEnabledVideoRepresentationIndexes(allQuality ? constansQuality.DATASAVER_INDEX_LIST : [], true);
                }
            }
        }
    };

    /**
     * WiFiステータスが切り替わっていたら基本画質設定によって切り替える.（アプリ）
     */
    const checkWifiStatus = useCallback(() => {
        if (app.isAllApp() && app.isLatestApp()) {
            if (isWifi !== prevWifiStatus.current) {
                prevWifiStatus.current = isWifi;

                // 詳細画質が設定されている場合は、基本画質設定で変更しない.
                if (detailQuality < 10) return;

                // 基本画質設定がWiFiとモバイルで同じ場合は、画質変更しない.
                if (allWifiQuality === allMobileQuality) return;

                const autoFlag = isWifi ? allWifiQuality : allMobileQuality;
                if (drmMode === constants.FAIR_PLAY) {
                    let newPlayUrl;
                    if (type === mediaType.LIVE_TYPE) {
                        if (autoFlag) {
                            newPlayUrl = constants.getLiveFairPlayUrl(playUrl, "DATASAVER");
                        }
                        else {
                            newPlayUrl = constants.getLiveFairPlayUrl(playUrl, content.videoDifinition === "SD" ? "SD" : "HD");
                        }
                    }
                    else {
                        if (autoFlag) {
                            newPlayUrl = constants.getPlayUrl(playUrl, "DATASAVER");
                        }
                        else {
                            newPlayUrl = constants.getPlayUrl(playUrl, content.videoDifinition === "SD" ? "SD" : "HD");
                        }
                    }
                    dispatch(videoIdActions.updatePlayUrl(newPlayUrl, true));
                }
                else {
                    player.current.setEnabledVideoRepresentationIndexes(autoFlag ? constansQuality.DATASAVER_INDEX_LIST : [], true);
                }
            }
        }
    }, [dispatch, type, drmMode, isWifi, detailQuality, allWifiQuality, allMobileQuality, playUrl, content]);

    /**
     * Wi-Fiステータス監視用関数更新.（アプリ)
     */
    useEffect(() => {
        checkWifiStatusRef.current = checkWifiStatus;
    }, [checkWifiStatus]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        video.current.addEventListener("loadeddata", () => {
            beforeTime.current = player.current.currentTime;

            // rc-slider@8.6.1は整数でないとWarningが発生するため、四捨五入しておく.
            duration.current = Math.floor(player.current.duration);
            conversionDuration.current = duration.current * VIDEO_CONVERSION_THRESHOLD;

            setIsLoading(true);
        });
        video.current.addEventListener("loadeddata", initImageQuality);

        if (muted) video.current.muted = true;
        video.current.volume = volume / 100.0;

        video.current.addEventListener("loadeddata", () => {
            if (video.current.paused) {
                video.current.play();
                hideCursor();
            }
        });

        // MediaSDKプラグイン初期化完了後にプレイヤー初期化と再生開始
        // （プラグイン初期化に失敗しても何もせず再生開始する）
        window.initPlugin(constants.INIT_JSON_URL, constants.KEYS_INI_URL, startPlay, startPlay);

        // フルスクリーン切替イベント登録.
        const handleFullscreenState = () => {
            screenfull.isFullscreen ? setIsFullscreen(true) : setIsFullscreen(false);
        };
        if (screenfull.isEnabled) screenfull.onchange(handleFullscreenState);

        // スマホ向き切替イベント登録.
        const handleOrientation = () => {
            if (!video.current) return;

            if (!video.current.paused) {
                if (isLandscape()) {
                    // 動画再生中かつ横回転時に動画をフルスクリーンにする.
                    if (!screenfull.isFullscreen) {
                        if (screenfull.isEnabled) {
                            screenfull.request(videoScreen.current, { navigationUI: "hide" }).catch();
                            // スマホは横向きに固定する(iOS Safariはサポートされていない).
                            if (window.screen.orientation && isMobile) {
                                window.screen.orientation.lock("landscape");
                            }
                        } else {
                            if (video.current && video.current.webkitEnterFullscreen) video.current.webkitEnterFullscreen({ navigationUI: "hide" });
                        }
                    }
                }
            }
            if (isPortrait()) {
                if (window.screen.orientation) window.screen.orientation.unlock();
            }
        };
        if (isSafari) {
            // Safariはwindow.screen.orientation.onchangeをサポートしてないので古いイベント利用.
            // Deprecated: onorientationchangeは非推奨なので今後使えなくなる可能性あり.
            window.addEventListener("orientationchange", handleOrientation);
        }
        else {
            if (window.screen.orientation) {
                window.screen.orientation.onchange = handleOrientation;
            }
            else {
                window.addEventListener("orientationchange", handleOrientation);
            }
        }

        // ポーリング処理登録.
        pollingId.current = setInterval(() => {
            if (!player.current) return;
            props.polling();
        }, constants.POLLING_INTERVAL);
        // WiFiステータス切替検知用
        const wifiStatusCheckId = setInterval(() => checkWifiStatusRef.current(), constants.POLLING_INTERVAL);

        playTokenRef.current = playToken;
        const beforeunloadFunc = () => {
            if (player.current) props.endAwaitWatch(player.current.duration, player.current.currentTime, playTokenRef.current)
        }
        window.addEventListener("beforeunload", beforeunloadFunc);

        return () => {
            dispatch(actions.changeIsImageQuality(false));
            window.removeEventListener("beforeunload", () => beforeunloadFunc);
            endWatch();
            if (screenfull.isEnabled) screenfull.off("change", handleFullscreenState);
            window.removeEventListener("orientationchange", handleOrientation);
            clearInterval(pollingId.current);
            clearInterval(waitingCountId.current);
            clearInterval(wifiStatusCheckId);
            destroyPlayer();
        };
        // eslint-disable-next-line
    }, []);

    /**
     * プレイヤー初期化と再生開始
     */
    const startPlay = () => {
        // レジューム取得.
        let resumeTime = undefined;
        for (const resume of resumeList) {
            if (resume[contentsKey.CRID] === content[contentsKey.CRID]) {
                resumeTime = resume[STOP_POSITION];
                break;
            }
        }
        player.current = window.startPlayer(createPlayerOptions(resumeTime), setIsBuffering, iOSStartNetworkErrorInterval, iOSStopNetworkErrorInterval, networkError, memberId, video.current);
        player.current.play();
        if (isIOS) {
            setTimeout(() => {
                if (video.current.paused) {
                    video.current.play();
                }
            }, 1);
        }
    }

    /**
     * MediaPlayerに渡すオプションを作成.
     */
    const createPlayerOptions = (resumePosition) => {
        // ライブの場合にstartTimeを設定すると、dynamicで更新されていく動画manifestの古い方を見て、
        // 現在時間とのズレが発生してしまうので、ライブではstartTimeを設定しない
        if (type === mediaType.LIVE_TYPE) {
            return {
                video: video.current,
                url: playUrl,
                enableSidxSeek: false,
                manifest: {},
                drm: setDrmOptions(),
                useCache: true,
                contentInfo: {
                    title: content.title + (content.epiTitle === "" ? "" : " / " + content.epiTitle),
                    id: content.crid
                }
            };
        } else {
            return {
                video: video.current,
                url: playUrl,
                enableSidxSeek: false,
                manifest: {},
                drm: setDrmOptions(),
                useCache: true,
                contentInfo: {
                    title: content.title + (content.epiTitle === "" ? "" : " / " + content.epiTitle),
                    id: content.crid
                },
                startTime: resumePosition ? resumePosition : 0
            };
        }
    };

    /**
     * DRMオプションの設定.
     */
    const setDrmOptions = () => {
        switch (drmMode) {
            case constants.FAIR_PLAY:
                return {
                    fairplay: {
                        url: licenseUrl,
                        customData: customData,
                        certURL: FAIRPLAY_CERT,
                        getAssetID: function (initData) {
                            var str = window.utf16leArrayToString(initData);
                            const slash = str.lastIndexOf("/");
                            return str.substring(slash + 1);
                        },
                        requestFilter: function (requestBody, xhr, drmOptions) {
                            xhr.responseType = "text";

                            xhr.setRequestHeader(
                                "AcquireLicenseAssertion",
                                drmOptions.customData
                            );
                            xhr.setRequestHeader(
                                "Content-type",
                                "application/x-www-form-urlencoded"
                            );
                            return (
                                "spc=" +
                                window.arrayToBase64(requestBody) +
                                "&assetId=" +
                                drmOptions.assetID
                            );
                        },
                        responseFilter: function (responseBody, xhr, drmOptions) {
                            var keyText = responseBody.trim();
                            if (
                                keyText.substr(0, 5) === "<ckc>" &&
                                keyText.substr(-6, 6) === "</ckc>"
                            ) {
                                keyText = keyText.slice(5, -6);
                            }
                            return window.base64ToArray(keyText).buffer;
                        }
                    }
                };
            case constants.PLAY_READY:
                return {
                    playready: {
                        url: licenseUrl,
                        customData: customData
                    }
                };
            case constants.CLEAR_KEY:
                return {
                    clearkey: {
                        url: licenseUrl,
                        customData: customData
                    }
                };
            default:
                return {
                    widevine: {
                        url: licenseUrl,
                        customData: customData
                    }
                };
        }
    }

    /**
     * 横向きかどうか判定.
     */
    const isLandscape = () => {
        const orientation = window.screen && window.screen.orientation;
        if (orientation) {
            return orientation.type === "landscape-primary" || orientation.type === "landscape-secondary";
        } else {
            return window.orientation % 180 !== 0;
        }
    };

    /**
     * 縦向きかどうか判定.
     */
    const isPortrait = () => {
        const orientation = window.screen && window.screen.orientation;
        if (orientation) {
            return orientation.type === "portrait-primary" || orientation.type === "portrait-secondary";
        } else {
            return window.orientation % 180 === 0;
        }
    };

    /**
     * 動画再生開始・一時停止.
     */
    const togglePlayer = () => {
        if (!video.current) return;

        if (video.current.paused) { // 動画一時停止から再開.
            beforeTime.current = player.current.currentTime;
            video.current.play();
            setIsLoading(true);
            hideCursor();
        } else { // 再生中から一時停止に遷移.
            video.current.pause();
        }
    };

    /**
     * 動画再生停止.
     */
    const destroyPlayer = () => {
        if (!player.current) return;

        player.current.destroy();
        player.current = null;
    };

    /**
     * マウスカーソルやコントロールバーを非表示とする.
     */
    const hideCursorControl = () => {
        if (!video.current) return null;
        if (!video.current.paused) {
            // 画質設定ポップアップが非表示（DOMが存在しない）の場合のみマウスカーソルを消す.
            if (!imageQualityDom.current) {
                videoScreen.current.style.cursor = "none";
            }
            setIsDisplayedControls(false);
        }
    };

    /**
     * マウスカーソルを表示する.
     * スマホはコントロールバーの表示切替を実施する.
     * コントロールバーが非表示の場合は表示する.
     * 動画再生中、動画上で一定時間操作なしでマウスカーソルを削除する.
     */
    const hideCursor = (event) => {
        if (event) event.preventDefault();

        videoScreen.current.style.cursor = "auto";
        clearTimeout(cursorRef.current);
        if (isMobile) {
            // スマホは動画タップでコントロールバー表示切替
            setIsDisplayedControls(!isDisplayedControls);
        } else if (!isDisplayedControls) {
            setIsDisplayedControls(true);
        }
        // 無操作で一定時間経過した際にマウスカーソルやコントロールバーを非表示とするsetTimeoutをセットする.
        cursorRef.current = setTimeout(hideCursorControl, constants.HIDE_CURSOR_INTERVAL);
    };

    /**
     * 音量変更.
     */
    const handleVolume = (newValue) => {
        if (newValue === 0) {
            // スライダーで0を指定したらミュートステータスをtrueにする.
            dispatch(actions.handleMute(true));
        } else if (muted) {
            // スライダーで1以上を指定したらミュートステータスをfalseにする.
            dispatch(actions.handleMute(false));
            video.current.muted = false;
        }
        // video.volumeのrangeは0～1なので、100で割る.
        video.current.volume = newValue / 100.0;
        dispatch(actions.handleVolume(newValue));
    };

    /**
     * ミュート切替.
     */
    const handleMute = () => {
        // ミュート解除の際に音量が0だった場合、5を設定する.
        if (muted && volume === 0) {
            video.current.volume = 0.05;
            dispatch(actions.handleVolume(5));
        }
        video.current.muted = !muted;
        dispatch(actions.handleMute(!muted));
        // ミュート解除した際に前の音量設定を反映するため、音量自体は0にしない.
    };

    /**
     * 10秒戻す.
     */
    const frontSeek10 = () => {
        if (currentTime < 10) {
            player.current.currentTime = 0;
        }
        else {
            player.current.currentTime = currentTime - 10;
        }
    };

    /**
     * 10秒進める.
     */
    const delaySeek10 = () => {
        if (currentTime + 10 > player.current.duration) {
            // currentTimeにdurationを設定するとiPhone SafariやMacで表示不具合が発生するので、-0.01秒した値を設定する.
            player.current.currentTime = player.current.duration - 0.01;
        }
        else {
            player.current.currentTime = currentTime + 10;
        }
    };

    /**
     * 画質設定アイコンを押下した際の処理
     */
    const handleImageQuality = () => {
        dispatch(actions.changeIsImageQuality(true));
    };

    /**
     * 動画タイトル表示.
     */
    const videoTitle = () => {
        if (isFullscreen && (isDisplayedControls || video.current.paused) && !isWaiting) {
            if (isMobile) {
                return (<div className={classes.spVideoTitle}>{content.title + (content.epiTitle === "" ? "" : " / " + content.epiTitle)}</div>);
            } else {
                return (<div className={classes.pcVideoTitle}>{content.title + (content.epiTitle === "" ? "" : " / " + content.epiTitle)}</div>);
            }
        }
    };

    /**
     * 前話・再生or停止・次話・画質設定アイコンの表示.
     * スマホは10秒戻し・進むボタンも表示.
     */
    const playControlButtons = () => {
        if (!video.current) return null;

        const [prevCss, playCss, nextCss] = isMobile
            ? [classes.spPreviousBtn, classes.spPlayPauseBtn, classes.spNextBtn]  // スマホ.
            : [classes.pcPreviousBtn, classes.pcPlayPauseBtn, classes.pcNextBtn]; // PC.

        // 前話ボタンは映像の場合表示.
        // 次話ボタンは、映像かつ次話が存在する場合に表示.
        // 10秒戻し・送りボタンは映像の場合表示.
        return (
            <>
                <img alt="" src="/images/xs/player/prev_button.svg" className={prevCss} onClick={prevVideo} />
                {app.isAllApp() && <img alt="" src="/images/cast_icon.svg" className={classes.castIcon} onClick={() => props.handleCast(type === mediaType.VIDEO_TYPE ? player.current.currentTime : null)} />}
                {isMobile && <img alt="" src="/images/xs/player/configuration.svg" className={classes.spImageQualityBtn} onClick={handleImageQuality} />}
                {type === mediaType.VIDEO_TYPE && isMobile && <img alt="" src="/images/ms/player/10_front_icon.svg" className={classes.sp10FrontBtn} onClick={frontSeek10} />}
                {playPause(playCss)}
                {type === mediaType.VIDEO_TYPE && isMobile && <img alt="" src="/images/ms/player/10_delay_icon.svg" className={classes.sp10DelayBtn} onClick={delaySeek10} />}
                <img alt="" src="/images/xs/player/next_button.svg" className={nextCss} onClick={nextVideo} />
            </>
        );
    };

    /**
     * 再生or停止のボタン表示.
     */
    const playPause = (cssClass) => {
        if (video.current.paused) {
            return (<img alt="" src="/images/xs/player/play_button_icon.svg" className={cssClass} onClick={togglePlayer} />);
        } else {
            return (<img alt="" src="/images/xs/player/pause_button_icon.svg" className={cssClass} onClick={togglePlayer} />);
        }
    };

    /**
     * 動画時間表示.
     */
    const videoTime = (cssClass) => {
        if (type === mediaType.LIVE_TYPE) return null;
        if (duration.current === 0) return (<span className={cssClass}>0:00 / 0:00</span>);

        // 秒を時分秒に変換.
        const convertTime = (time) => {
            const hour = Math.floor(time / 3600);
            const minute = Math.floor(time % 3600 / 60);
            const second = Math.floor(time % 60);

            const zeroPadding = (num) => ("0" + num).slice(-2);

            if (hour !== 0) {
                return hour + ":" + zeroPadding(minute) + ":" + zeroPadding(second);
            } else {
                return minute + ":" + zeroPadding(second);
            }
        };

        return (<span className={cssClass}>{convertTime(currentTime)} / {convertTime(duration.current)}</span>);
    };

    /**
     * フルスクリーンアイコン表示.
     */
    const fullscreenIcon = (cssClass) => {
        if (isFullscreen) {
            return (<img alt="" src="/images/xs/player/off_fullscreen.svg" onClick={handleFullscreen} className={cssClass} />);
        } else {
            return (<img alt="" src="/images/xs/player/on_fullscreen.svg" onClick={handleFullscreen} className={cssClass} />);
        }
    }

    /**
     * 画質設定ポップアップの表示（PC・ライブ対応）
     */
    const qualityPopupBtn = () => {
        return (
            <img alt="" src="/images/xs/player/configuration.svg" className={classes.pcImageQualityBtn} onClick={handleImageQuality} />
        );
    }

    /**
     * 動画上に再生・停止ボタンを表示.
     */
    const videoButton = () => {
        if (!video.current) return null;
        if (isWaiting) return null;

        if (isMobile) {
            if (isDisplayedControls || video.current.paused) {
                return (
                    <>
                        <div className={classes.spPlayControlBackground} />
                        {playControlButtons()}
                    </>
                );
            }
        } else {
            const playPauseBtn = () => {
                if (video.current.paused) {
                    return (
                        <img alt="" src="/images/xs/player/play_button.svg" className={classes.videoBtn} onClick={togglePlayer} />
                    )
                } else {
                    if (isDisplayedControls) {
                        return (
                            <img alt="" src="/images/xs/player/pause_button.svg" className={classes.videoBtn} onClick={togglePlayer} />
                        )
                    }
                }
            };

            return (
                <>
                    {(video.current.paused || isDisplayedControls) && type === mediaType.VIDEO_TYPE && <img alt="" src="/images/xs/player/10_front_icon.svg" className={classes.pc10FrontBtn} onClick={frontSeek10} />}
                    {playPauseBtn()}
                    {(video.current.paused || isDisplayedControls) && type === mediaType.VIDEO_TYPE && <img alt="" src="/images/xs/player/10_delay_icon.svg" className={classes.pc10DelayBtn} onClick={delaySeek10} />}
                </>
            );
        }
    };

    /**
     * 動画コントロールバー表示.
     * ライブの場合は、シークバーを表示しない.
     */
    const videoControls = () => {
        if (!video.current) return null;
        if (!isDisplayedControls && !sliderChanging && !video.current.paused) return null;
        if (isWaiting) return null;

        if (isMobile) {
            if (type === mediaType.LIVE_TYPE) {
                return (
                    <div className={classes.videoControls}>
                        <div className={classes.videoBottom}>
                            {fullscreenIcon(classes.spLiveFullscreenIcon)}
                        </div>
                    </div>
                );
            }
            else {
                return (
                    <div style={{ display: "flex" }} className={classes.videoControls}>
                        {videoTime(classes.spPlayTime)}
                        <Slider
                            value={Math.floor(currentTime)}
                            onChange={handleSeek}
                            max={duration.current}
                            onBeforeChange={startSeek}
                            onAfterChange={endSeek}
                            step={1}
                            trackStyle={{ backgroundColor: "red", height: 3 }}
                            railStyle={{ backgroundColor: "rgba(112, 112, 112, 0.5)", height: 3 }}
                            handleStyle={{ backgroundColor: "red", border: "none" }}
                            className={classes.spSeekRoot}
                        />
                        {fullscreenIcon(classes.spFullscreenIcon)}
                    </div>
                );
            }
        } else {
            return (
                <div className={classes.videoControls}>
                    {type === mediaType.VIDEO_TYPE &&
                        <div className="seekBox">
                            <Slider
                                value={Math.floor(currentTime)}
                                onChange={handleSeek}
                                max={duration.current}
                                min={0}
                                onBeforeChange={startSeek}
                                onAfterChange={endSeek}
                                trackStyle={{ backgroundColor: "red", height: 3 }}
                                railStyle={{ backgroundColor: "rgba(112, 112, 112, 0.5)", height: 3 }}
                                handleStyle={{ backgroundColor: "red", cursor: "pointer", border: "none" }}
                                className={classes.pcSeekRoot}
                            >
                            </Slider>
                            <div className={classes.seekBuffer} />
                        </div>
                    }

                    <div className={classes.videoBottom}>
                        {playControlButtons()}
                        {muted ? <img alt="" src="/images/xs/player/mute_icon.svg" onClick={handleMute} className={classes.volumeIcon} />
                            : <img alt="" src="/images/xs/player/volume_icon.svg" onClick={handleMute} className={classes.volumeIcon} />}
                        <Slider
                            value={muted ? 0 : volume}
                            onChange={handleVolume}
                            max={100}
                            onBeforeChange={() => setSliderChanging(true)}
                            onAfterChange={() => setSliderChanging(false)}
                            trackStyle={{ backgroundColor: "#8D8D8D", height: 3 }}
                            railStyle={{ backgroundColor: "#8D8D8D", height: 3 }}
                            handleStyle={{ backgroundColor: "white", cursor: "pointer", border: "none" }}
                            className={classes.volumeSlider}
                        />
                        {videoTime(classes.pcPlayTime)}
                        {qualityPopupBtn()}
                        {fullscreenIcon(classes.pcFullscreenIcon)}
                    </div>
                </div>
            );
        }
    };

    /**
     * シークバー移動開始.
     */
    const startSeek = () => {
        beforeSeekPaused.current = video.current.paused;
        if (!video.current.paused) video.current.pause();
        setSliderChanging(true);
        setSeekChanging(true);
    };

    /**
     * シークバー移動.
     */
    const handleSeek = (newValue) => {
        setCurrentTime(newValue);
    };

    /**
     * シークバー移動完了.
     */
    const endSeek = () => {
        setIsLoading(true);

        player.current.currentTime = currentTime;
        // シークバー移動開始時点で再生中だった場合は再生開始する.
        if (!beforeSeekPaused.current) {
            beforeTime.current = player.current.currentTime;
            video.current.play();
            setIsLoading(true);
        }

        setSliderChanging(false);
        if (!isIOS) setSeekChanging(false);
    };

    /**
     * 動画の再生時間表示更新.
     */
    const timeUpdate = () => {
        if (player.current && !seekChanging) {
            if (type === mediaType.LIVE_TYPE) {
                setCurrentTime(((new Date().getTime() / 1000) - content.availPerStart));
            } else {
                setCurrentTime(player.current.currentTime);
            }
        }
        if (isIOS && seekChanging) setSeekChanging(false);
        if (player.current && isLoading && beforeTime.current !== player.current.currentTime) setIsLoading(false);
        if (player.current && Math.round(player.current.currentTime) === Math.round(player.current.duration)) completeWatch();
        vodConversion();
    };

    /**
     * 動画の読込完了時間表示更新.
     */
    const progress = () => {
        if (video.current && player.current.duration > 0) {
            const videoBuffered = video.current.buffered;
            for (var i = 0; i < videoBuffered.length; i++) {
                if (videoBuffered.start(videoBuffered - 1 - i) < player.current.currentTime) {
                    setBuffered(videoBuffered.end(videoBuffered.length - 1 - i));
                }
            }
        }
    };

    /**
     * 前話ボタンを押下した際の処理.
     */
    const prevVideo = () => {
        if (Math.floor(currentTime) < 3) {
            if (content.preCrid) {
                props.prevVideo();
            } else {
                player.current.currentTime = 0;
                timeUpdate();
            }
        } else {
            // 先頭に戻る.
            player.current.currentTime = 0;
            timeUpdate();
        }
    };

    /**
     * 次話ボタンを押下した際の処理.
     */
    const nextVideo = () => {
        props.nextVideo();
    };

    /**
     * フルスクリーン切替.
     */
    const handleFullscreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle(videoScreen.current, { navigationUI: "hide" });
            // スマホは横向きに固定する(iOS Safariはサポートされていない).
            if (window.screen.orientation && isMobile) {
                window.screen.orientation.lock("landscape");
            }
        } else {
            if (video.current.webkitEnterFullscreen) {
                video.current.webkitEnterFullscreen({ navigationUI: "hide" });
            }
        }
    };

    /**
     * フルスクリーン状態かどうか（iOS Safari等の標準プレイヤーにも対応）
     */
    const isFullscreenVideo = () => {
        if (!video.current) return false;
        return screenfull.isEnabled ? isFullscreen : video.current.webkitDisplayingFullscreen;
    };

    /**
     * 視聴終了した際の処理.
     */
    const endWatch = () => {
        if (player.current) props.endWatch(player.current.duration, player.current.currentTime, playTokenRef.current);
    };

    /**
     * 視聴完了した際の処理.
     */
    async function completeWatch() {
        // 次話がある場合、連続再生処理を行う.
        if (type === mediaType.VIDEO_TYPE && content.nextCrid) {
            // 次話のサムネイルとタイトルを取得.
            // nextContentInfo[0]: サムネイルURL, nextContentInfo[1]: タイトル
            const nextContentInfo = props.getNextContentInfo;

            // タイトルが取得できなかった場合、連続再生しない.
            if (!nextContentInfo[1]) {
                setIsDisplayedControls(true);
            }
            else {
                // 現在の動画のポーリング解除・視聴終了API呼び出し.
                clearInterval(pollingId.current)
                endWatch();

                // 標準プレイヤーの全画面状態の場合、全画面を解除.
                if (!screenfull.isEnabled && video.current.webkitDisplayingFullscreen) {
                    if (video.current.webkitExitFullscreen) video.current.webkitExitFullscreen();
                    // フルスクリーン解除終了まで待機.
                    await (new Promise((resolve) => setTimeout(resolve, 100)));
                }
                player.current.currentTime = 0;
                video.current.pause();

                // 待機時間中の情報をセット.
                setNextThumnail(nextContentInfo[0]);
                setNextTitle(nextContentInfo[1]);
                setIsWaiting(true);

                // 待機時間のカウントダウン開始.
                setWaitingCount(constants.INITIAL_WAITING_COUNT);
                waitingCountId.current = setInterval(() => {
                    setWaitingCount(c => c - 1);
                }, 1000);
                destroyPlayer();
            }
        }
        else {
            setIsDisplayedControls(true);
        }
    };

    /**
     * 次話への移動.
     */
    const moveVideo = () => {
        props.moveVideo().then((playTokenRes) => {
            playTokenRef.current = playTokenRes;
            setVideoCount(prev => prev + 1);
        });
    };

    /**
     * 待機時間カウントダウン終了時の処理.
     */
    useEffect(() => {
        if (waitingCount === 0) {
            clearInterval(waitingCountId.current);
            moveVideo();
        }
        // eslint-disable-next-line
    }, [waitingCount]);

    /**
     * 連続再生で遷移したときに、自動再生.
     */
    useEffect(() => {
        if (videoCount > 0) {
            pollingId.current = setInterval(() => {
                props.polling();
            }, constants.POLLING_INTERVAL);
            player.current = window.startPlayer(createPlayerOptions(), setIsBuffering, iOSStartNetworkErrorInterval, iOSStopNetworkErrorInterval, networkError, memberId, video.current);
            video.current.addEventListener("loadeddata", initImageQuality);
            player.current.play();
            if (isIOS) {
                setTimeout(() => {
                    if (video.current.paused) {
                        video.current.play();
                    }
                }, 1);
            }
            setIsWaiting(false);
        }
        // eslint-disable-next-line
    }, [videoCount]);

    useEffect(() => {
        if (isChangedQuality){
            dispatch(videoIdActions.updatePlayUrl(playUrl, false));
            const playerCurrentTime = player.current.currentTime;
            destroyPlayer();
            player.current = window.startPlayer(createPlayerOptions(playerCurrentTime), setIsBuffering, iOSStartNetworkErrorInterval, iOSStopNetworkErrorInterval, networkError, memberId);
            player.current.play();
            if (isIOS) {
                setTimeout(() => {
                    if (video.current.paused) {
                        video.current.play();
                    }
                }, 1);
            }
        }
        // eslint-disable-next-line
    }, [dispatch, isChangedQuality]);

    /**
     * 再生終了時に待機時間中に表示する画面（インライン）.
     */
    const completeWatchDisplay = () => {
        return (
            <div className="c-playerDis">
                <div className="c-playerDis__frame">
                    <img alt="" src={content.thumbnailLandscape} onError={(e) => e.target.src = "/images/noimage.jpg"} />
                    <div className="c-playerOverlay"></div>
                    <div className="c-playerCount">
                        <div className="c-playerCount__txtWrap"><span className="c-playerCount__txtSec">{waitingCount}</span>
                            <div className="c-playerCount__txtPlay">秒後に、次の動画を再生します</div>
                        </div>
                        <div className="c-playerCount__thumb"><img src={nextThumnail} alt="" /></div>
                        <div className="c-playerCount__ttl">
                            <div className="c-playerCount__ttl--inner">{nextTitle}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    /**
     * 再生終了時に待機時間中に表示する画面（フルスクリーン）.
     */
    const completeWatchFullscreen = () => {
        return (
            <>
                <img alt="" className={classes.completeWatchBackground} src={content.thumbnailLandscape} onError={(e) => e.target.src = "/images/noimage.jpg"} />
                <div className="c-playerOverlay"></div>
                <div className="c-playerCount">
                    <div className="c-playerCount__txtWrap"><span className="c-playerCount__txtSec">{waitingCount}</span>
                        <div className="c-playerCount__txtPlay">秒後に、次の動画を再生します</div>
                    </div>
                    <div className="c-playerCount__thumb"><img src={nextThumnail} alt="" /></div>
                    <div className="c-playerCount__ttl">
                        <div className="c-playerCount__ttl--inner">{nextTitle}</div>
                    </div>
                </div>
            </>
        )
    };

    /**
     * スマホでコントロールバー表示時は動画背景を暗くする.
     */
    const spBackgroundOpacity = () => {
        if (isMobile && (isDisplayedControls || (video.current && video.current.paused))) {
            return "rgba(0, 0, 0, 0.51)";
        } else {
            return "rgba(255, 255, 255, 0)";
        }
    };

    /**
     * 読込済時間割合(シークバー用).
     */
    const calcBufferedRate = () => (duration.current === 0) ? 0 : Math.min(100, (buffered / duration.current) * 100);

    /**
     * 前話の表示をスキップ.
     */
    const skipPrevDisplayed = () => {
        if (isMobile) {
            return type === mediaType.VIDEO_TYPE ? "visible" : "hidden";
        } else {
            return type === mediaType.VIDEO_TYPE ? "inline" : "none";
        }
    };

    /**
     * 次話の表示をスキップ.
     */
    const skipNextDisplayed = () => {
        // 次話がない場合は映像でも非表示にする
        if (isMobile) {
            return (type === mediaType.VIDEO_TYPE && content.nextCrid) ? "visible" : "hidden";
        } else {
            return (type === mediaType.VIDEO_TYPE && content.nextCrid) ? "inline" : "none";
        }
    };

    const isVideoDisplay = () => {
        if (!video.current || !videoScreen.current) return "block";

        if (isWaiting && !isFullscreenVideo()) return "none";
        else return "block";
    };

    /**
     * 画質設定ポップアップを閉じる.
     */
    const closeImageQualityPopup = () => {
        dispatch(actions.changeIsImageQuality(false));
        // 無操作で一定時間経過した際にマウスカーソルやコントロールバーを非表示とするsetTimeoutをセットする.
        clearTimeout(cursorRef.current);
        cursorRef.current = setTimeout(hideCursorControl, constants.HIDE_CURSOR_INTERVAL);
    };

    /**
     * VODの場合、再生時間が設定値を経過していたらコンバージョン通知を実施.
     */
    const vodConversion = () => {
        // コンバージョン通知既に実施してる場合はスキップ.
        if (conversionRef.current) return;

        // コンバージョン通知対象ではない場合スキップ.
        if (!content[contentsKey.CONVERSION_TARGET]) return;

        if (type === mediaType.VIDEO_TYPE) {
            if (conversionDuration.current !== 0 && player.current.currentTime >= conversionDuration.current) {
                conversionRef.current = true;

                const conversionRequest = {
                    [recommendif.CRID]: content[contentsKey.CONVERSION_TARGET],
                    [recommendif.RECOMMENDCOOKIE]: recommendCookie,
                    [recommendif.RECOMMENDDEVICE]: getDeviceId()
                };
                recommendif.postConversion(conversionRequest).then(null, null);
            }
        }
    };

    /** CSS. */
    const cssProps = {
        bufferWidth: calcBufferedRate() + "%",
        videoWidth: video.current ? video.current.offsetWidth : 0,
        videoBackground: spBackgroundOpacity(),
        skipPrevDisplayed: skipPrevDisplayed(),
        skipNextDisplayed: skipNextDisplayed(),
    };
    const classes = styles(cssProps);

    return (
        <>
            <div
                className={classes.videoContainer}
                ref={videoScreen}
                onMouseEnter={() => setIsDisplayedControls(true)}
                onMouseLeave={() => setIsDisplayedControls(false)}
                style={{ display: isVideoDisplay() }}
            >
                {isImageQuality && <ImageQualityPopup player={player} mediaType={type} domRef={imageQualityDom} close={closeImageQualityPopup}/>}
                <div className={classes.videoWrapper}>
                    <video
                        playsInline
                        disablePictureInPicture
                        ref={video}
                        className={isFullscreen ? classes.fullscreenVideo : classes.video}
                        onTimeUpdate={timeUpdate}
                        onProgress={progress}
                        onMouseMove={hideCursor}
                        onTouchEnd={hideCursor}
                        onWaiting={() => setIsLoading(true)}
                    />
                    {videoButton()}
                    {(isLoading || isBuffering) && <div className={classes.loading}><CircularProgress style={{ color: "white" }} /></div>}
                    {videoControls()}
                </div>
                {videoTitle()}
                {isFullscreenVideo() && isWaiting && completeWatchFullscreen()}
            </div>
            {!isFullscreenVideo() && isWaiting && completeWatchDisplay()}
        </>
    );
};

export default Player;