import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import * as historys from "../../../historys";
import * as contentType from "../../../constants/contentType";
import * as iconType from "../../../constants/iconType";
import * as contentsKey from "../../../constants/contentsKey";
import * as libraryId from "../../../constants/libraryId";
import * as recommendif from "../../../middleware/recommendif";
import useRecommendCookie from "../../../hooks/useRecommendCookie";

import styles from "./styles";

/**
 * 標準コンテンツ.
 *
 * props
 *  -> meta     コンテンツのメタ.
 */
const Default = (props) => {
    /** Hooks. */
    const history = useHistory();

    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /**
     * 視聴中コンテンツの割合を計算.
     */
     const calcPlayBackWidth = () => {
        if (resumeList && resumeList[0] && resumeList[0][contentsKey.CRID] === props.meta[contentsKey.CRID])
            return String(resumeList[0][contentsKey.STOP_POSITION] / resumeList[0][contentsKey.VIDEO_DUR] * 100);
        return "0";
    }

    /** CSS. */
    const cssProps = {
        playBackWidth: calcPlayBackWidth() + "%"
    };
    const classes = styles(cssProps);

    /**
     * コンテンツクリック時.
     */
    const handleClick = (id) => {
        // レコメンドコンテンツの場合は、レコメンドクリック通知APIを呼び出す.
        if (props.clickData && props.clickData !== "") {
            let request = {
                [recommendif.CRID]: props.meta[contentsKey.CRID],
                [recommendif.SPEC]: props.clickData[recommendif.SPEC],
                [recommendif.CREF]: props.clickData[recommendif.RQID],
                [recommendif.RECOMMENDCOOKIE]: recommendCookie
            };
            recommendif.postRecommendClick(request).then(null, null);
        }

        // シリーズはライブラリ一覧に遷移.
        if (props.meta[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(id);
            historys.historylibraryId(history, historys.getEncodeId(list));
        }

        // 映像詳細画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && (props.meta[contentsKey.CONTENT_TYPE] === contentType.VIDEO))
            historys.historyVideoId(history, id);

        // ライブ画面に遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.LIVE)
            historys.historyLiveId(history, id);

        // 静的コンテンツページに遷移.
        if (!props.meta[contentsKey.LIST_FLG] && props.meta[contentsKey.CONTENT_TYPE] === contentType.WEB)
            historys.historyContents(history, id);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 視聴中コンテンツか判定.
     */
     const isResume = () => {
        if (props.meta[contentsKey.LIST_FLG]) return false;
        if (resumeList && resumeList[0] && resumeList[0][contentsKey.CRID] === props.meta[contentsKey.CRID]) return true;
        else return false;
    }

    /**
     * ニュースの背景色.
     */
    const pickupColor = props.pickupColor

    return (
        <div
            /* ニュースpick up項目の場合、背景カラー変更 */
            className="c-contCard"
            onClick={() => handleClick(props.meta[contentsKey.CRID])}
            onContextMenu={(event) => handleContextMenu(event)}
        >
        {props.contflg === "vertical" && !matches? 
        /* SP用のニュースのサムネイル */
        <div class="c-contCard__inner">
            <div className="c-contCard__photo">
                <picture>
                    <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                        + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                        type="image/webp" />
                    <img className="lozad loaded" src={props.meta[contentsKey.THUMBNAIL_L]} alt="" loading="lazy" />
                </picture>
                {props.playBack &&
                    <div className="c-contCard__playback">
                        <div className={classes.playback}></div>
                    </div>
                }
            </div>
            {props.pickupflg &&
                <div className="c-contCard__meta" style={{background : pickupColor}}>
                    {/* アイテムテキスト・ステータスアイコンどっちもない場合はiconwrapを表示させない*/}
                    {props.meta[contentsKey.ICON_TYPE] != null && (contentType.isGiftVideo(props.meta) || contentType.isPremium(props.meta) || contentType.isFree(props.meta)) &&
                        <div class = "c-iconWrap">
                            {/* アイテムテキスト. （動画・情報アイテム以外、テキスト先頭に表示）*/}
                            {props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                                <span class="c-icon c-icon--rect">
                                    <span class="c-icon__Txt">
                                        {/* 英文字の場合、小文字チェック後大文字出力*/}
                                        {props.meta[contentsKey.ICON_TYPE] === "カート" ? "買い物" : props.meta[contentsKey.ICON_TYPE].toUpperCase()}
                                    </span>
                                </span>
                            }
                            {/* ステータスアイコン.（タイトルの前に表示） */}
                            {/* 視聴中アイコン. */}
                            {isResume() &&
                                <div className="c-contCard__tag c-tag c-tag--view">
                                    <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                                </div>
                            }

                            {/* 特典映像アイコン. */}
                            {(!isResume() && contentType.isGiftVideo(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--special">
                                    <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                                </div>
                            }

                            {/* プレミアムアイコン. */}
                            {(!isResume() && iconType.isPremiumIcon(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--premium">
                                    <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                                </div>
                            }

                            {/* 無料アイコン. */}
                            {(!isResume() && iconType.isFreeIcon(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--free">
                                    <div className="c-icon__Txt">無料</div>
                                </div>
                            }
                        </div>
                    }
                    <span class="c-contCard__name js-textLimit" data-sp-truncation="2">
                        {props.meta[contentsKey.ICON_TYPE]!=null}{props.meta[contentsKey.TITLE]}
                    </span>
                </div>
            }
            {!props.pickupflg &&
                <div className="c-contCard__meta">
                    {/* アイテムテキスト・ステータスアイコンどっちもない場合はiconwrapを出さない*/}
                    {props.meta[contentsKey.ICON_TYPE] != null && (contentType.isGiftVideo(props.meta) || iconType.isPremiumIcon(props.meta) || iconType.isFreeIcon(props.meta)) &&
                        <div class = "c-iconWrap">
                            {/* アイテムテキスト. （動画・情報アイテム以外、テキスト先頭に表示）*/}
                            {props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                                <span class="c-icon c-icon--rect">
                                    <span class="c-icon__Txt">
                                        {/* 英文字の場合、小文字チェック後大文字出力*/}
                                        {props.meta[contentsKey.ICON_TYPE] === "カート" ? "買い物" : props.meta[contentsKey.ICON_TYPE].toUpperCase()}
                                    </span>
                                </span>
                            }
                            {/* ステータスアイコン.（タイトルの前に表示） */}
                            {/* 視聴中アイコン. */}
                            {isResume() &&
                                <div className="c-contCard__tag c-tag c-tag--view">
                                    <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                                </div>
                            }

                            {/* 特典映像アイコン. */}
                            {(!isResume() && contentType.isGiftVideo(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--special">
                                    <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                                </div>
                            }

                            {/* プレミアムアイコン. */}
                            {(!isResume() && iconType.isPremiumIcon(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--premium">
                                    <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                                </div>
                            }

                            {/* 無料アイコン. */}
                            {(!isResume() && iconType.isFreeIcon(props.meta)) &&
                                <div className="c-contCard__tag c-tag c-tag--free">
                                    <div className="c-icon__Txt">無料</div>
                                </div>
                            }
                        </div>
                    }
                    <span class="c-contCard__name js-textLimit" data-sp-truncation="2">
                        {props.meta[contentsKey.ICON_TYPE]!=null}{props.meta[contentsKey.TITLE]}
                    </span>
                </div>
            }
        </div>
        /* 以外のサムネイル */
            :  
        <div>
            <span className="c-contCard__inner">
                {/* ステータスアイコン（サムネイル左上に表示）. */}
                {/* 視聴中アイコン. */}
                {isResume() &&
                    <div className="c-contCard__tag c-tag c-tag--view">
                        <div className="c-icon__Txt c-icon__Txt--view">視聴中</div>
                    </div>
                }

                {/* 特典映像アイコン. */}
                {(!isResume() && contentType.isGiftVideo(props.meta)) &&
                    <div className="c-contCard__tag c-tag c-tag--special">
                        <div className="c-icon__Txt c-icon__Txt--special">特典映像</div>
                    </div>
                }

                {/* プレミアムアイコン. */}
                {(!isResume() && iconType.isPremiumIcon(props.meta)) &&
                    <div className="c-contCard__tag c-tag c-tag--premium">
                        <div className="c-icon__Txt c-icon__Txt--premium">PREMIUM</div>
                    </div>
                }

                {/* 無料アイコン. */}
                {(!isResume() && iconType.isFreeIcon(props.meta)) &&
                    <div className="c-contCard__tag c-tag c-tag--free">
                        <div className="c-icon__Txt">無料</div>
                    </div>
                }
                
                {/* サムネ画像 */}
                <div className="c-contCard__photo">
                    {/* blockDesignがcarouselとminiBanner以外の場合、横サムネ表示 */}
                    {(props.contflg !== "carousel" && props.contflg !== "miniBanner") &&
                        <picture>
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                media="(min-width:640px)"
                                type="image/webp" />
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/160x90/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                            <img className="lozad loaded" src={props.meta[contentsKey.THUMBNAIL_L]} alt="" loading="lazy" />
                        </picture>                        
                    }
                    {/* blockDesignがminiBanner = スマホレイアウトでは横幅いっぱい表示の場合 */}
                    {(props.contflg === "miniBanner") &&
                        <picture>
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                media="(min-width:768px)"
                                type="image/webp" />
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/768x432/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/768x432/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                media="(min-width:640px)"
                                type="image/webp" />
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                            <img className="lozad loaded" src={props.meta[contentsKey.THUMBNAIL_L]} alt="" loading="lazy" />
                        </picture>                        
                    }
                    {/* blockDesignがcarousel = スマホレイアウトでは横スクロールかつ縦画像を表示する場合 */}
                    {(props.contflg === "carousel") &&
                        <picture>
                            <source srcset={props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/320x180/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_L].replace("/landscape/", "/landscape/280x158/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                media="(min-width:768px)"
                                type="image/webp" />
                            <source srcset={props.meta[contentsKey.THUMBNAIL_P].replace("/portrait/", "/portrait/105x140/").replace(/\.[^/.]+$/, "") + ".webp 1x, "
                                + props.meta[contentsKey.THUMBNAIL_P].replace("/portrait/", "/portrait/105x140/").replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                type="image/webp" />
                            <img className="lozad loaded" src={(!matches) ? props.meta[contentsKey.THUMBNAIL_P] : props.meta[contentsKey.THUMBNAIL_L]} alt="" loading="lazy" />
                        </picture>                        
                    }
                    {props.playBack &&
                        <div className="c-contCard__playback">
                            <div className={classes.playback}></div>
                        </div>
                    }
                </div>
                <div className="c-contCard__meta"><div className="c-contCard__meta__wrap">
                    <span className="c-contCard__name js-textLimit" data-sp-truncation="2" data-truncation="1">
                        {/* アイテムテキスト. （動画・情報アイテム以外、テキスト先頭に表示）*/}
                        {props.meta[contentsKey.ICON_TYPE]!=null && props.meta[contentsKey.ICON_TYPE] !== "動画" && props.meta[contentsKey.ICON_TYPE] !== "情報" &&
                        <span class="c-icon c-icon--rect">
                            <span class="c-icon__Txt">
                                {/* 英文字の場合、小文字チェック後大文字出力*/}
                                {props.meta[contentsKey.ICON_TYPE] === "カート" ? "買い物" : props.meta[contentsKey.ICON_TYPE].toUpperCase()}
                            </span>
                        </span>
                        }
                        {/* ママイキとタイトルにスペース追加 */}
                        {props.meta[contentsKey.ICON_TYPE]!=null && " "}{props.meta[contentsKey.TITLE]}
                    </span>
                </div></div>
            </span>
        </div>
        }
            
        </div>
    );
};

export default Default;
