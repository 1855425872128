import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { TwitterShareButton, FacebookShareButton } from "react-share";

import * as app from "../../constants/app";
import * as sns from "../../constants/sns";
import { TWITTER_HASH_TAGS } from "../../constants/setting";
import { getUrl } from "../../constants/sns";

const ShareButton = (props) => {

    /** リンクコピーされました文言表示/非表示. */
    const [isClickCopyLink, setIsClickCopyLink] = useState(false);

    /** 汎用設定（app_config_web）. */
    const appConfigWeb = useSelector(state => state.Member.appConfigWeb);

    /** ダイアログDOM(ダイアログ外側タップ判定用) */
    const dialogRef = useRef();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // ダイアログ外側タップで閉じる.
        const handleClickOutside = (e) => {
            if (!dialogRef.current) return;
            if (!dialogRef.current.contains(e.target)) {
                props.close()
            }
        }
        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        }
        // eslint-disable-next-line
    }, []);

    useLayoutEffect(() => {
        document.body.style.paddingRight = "17px";
        document.body.style.overflow = "hidden"
        return () => {
            document.body.style.paddingRight = "0";
            document.body.style.overflow = "auto"
        }
    },[]);

    /**
     * iOSアプリのTwitterシェア処理.
     */
    const iosTwitterShare = () => {
        window.location.href = sns.getTwitterShareUrl(getUrl(), appConfigWeb[TWITTER_HASH_TAGS]);
    };

    /**
     * iOSアプリのFacebookシェア処理.
     */
    const iosFacebookShare = () => {
        window.location.href = sns.getFacebookShareUrl(getUrl());
    };

    /**
     * リンクコピー.
     */
    const copyLink = () => {
        navigator.clipboard.writeText(window.location.href).then(() => {
            setIsClickCopyLink(true);
            setTimeout(() => setIsClickCopyLink(false), 2000);
        })
    };

    return (
        <div className="c-modal is-open">
            <div className="c-modal__overlay"></div>
            <div className="c-modal__share c-share" ref={dialogRef} >
                <div className="c-closeModalBtn c-modal__close" onClick={() => props.close()}><img src="/assets/images/icon-close.png" alt="" /></div>
                <div className="c-share__inner js-modalScrollCont">
                <p className="c-share__txt">このページをシェアする</p>
                <div className="c-share__btnWrap">
                    <div className="c-share__btn__item"><a className="c-share__btn">
                        {app.isIosApp() &&
                            <img src="/assets/images/icon-x.png" alt="" onClick={() => iosTwitterShare()}/>
                        }
                        {!app.isIosApp() &&
                            <TwitterShareButton url={getUrl()} hashtags={appConfigWeb[TWITTER_HASH_TAGS]}>
                                <img src="/assets/images/icon-x.png" alt="" />
                            </TwitterShareButton>
                        }
                    </a></div>
                    <div className="c-share__btn__item"><a className="c-share__btn">
                        {app.isIosApp() &&
                            <img src="/assets/images/icon-facebook.png" alt="" onClick={() => iosFacebookShare()}/>
                        }
                        {!app.isIosApp() &&
                            <FacebookShareButton url={getUrl()}>
                                <img src="/assets/images/icon-facebook.png" alt="" />
                            </FacebookShareButton>
                        }
                    </a></div>
                    <div className="c-share__btn__item">
                    <div className="c-share__btn c-share__btn__click js-copyUrlButton" id="copyButton" onClick={() => copyLink()}><img src="/assets/images/icon-link.png" alt="" /></div><span className="c-share__btn__txt">リンクをコピー</span>
                    </div>
                </div>
                </div>
                <div className="c-modal__share c-share c-copied" id="copyText" style={{opacity : isClickCopyLink ? 1 : 0, display: isClickCopyLink ? 'block' : 'none'}}>
                    <div className="c-copied__inner js-modalScrollCont">
                        <p className="c-copied__txt">リンクがコピーされました</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareButton;