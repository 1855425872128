import React from "react";
import { useSelector } from "react-redux";
import * as app from "../../constants/app";
import styles from "./styles";

/**
 * メニュー.
 *
  *  -> historyTop         トップ画面へ遷移
  *  -> historyPlanet      プラネット画面へ遷移
  *  -> historySearch      検索一覧画面へ遷移
  *  -> historyFavorite    お気に入り一覧画面へ遷移
  *  -> historyResume      視聴中の作品一覧画面へ遷移
  *  -> historyPurchase    レンタル・取得した作品一覧画面へ遷移
  *  -> handleProf         プロフィール押下
  *  -> historyImagination イマジネーションとは画面へ遷移
  *  -> historyOperation   操作ガイド画面に遷移
  *  -> historyFaq         FAQ/問い合わせ画面へ遷移
  *  -> handleLogin        ログイン
  *  -> handleLogout       ログアウト
  *  -> handleOssLicense   OSSライセンスページに遷移（Androidアプリのみ）
 */
const Menu = (props) => {
    /** CSS. */
    const classes = styles();

    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);

    return (
        <span>
            {!app.isAllApp() && <div className="c-gnav__head c-headnav"></div>}
            <div className="c-gnav__menu c-headnav__item">
                <ul>
                    <li
                        className={classes.clickable + " c-gnav__menu c-gnav__menu--item c-gnav__menu--red"}
                        key="c-gnav__menu--top"
                        onClick={props.historyTop}
                    >
                        <a>IMAGINATION BASE</a>
                    </li>
                    <li
                        className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                        key="c-gnav__menu--planet"
                        onClick={props.historyPlanet}
                    >
                        <a>プラネット<span className="c-gnav__smallTxt">（コンテンツリスト）<i className="c-gnav__menuIcon c-gnav__menuIcon--planet c-picIconPlanet"></i></span></a>
                    </li>
                    <li
                        className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                        key="c-gnav__menu--search"
                        onClick={props.historySearch}
                    >
                        <a>検　索<i className="c-gnav__menuIcon c-gnav__menuIcon--search c-picIconSearch--gray"></i></a>
                    </li>
                    {memberId !== "" && [
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--favorite"
                            onClick={props.historyFavorite}
                        >
                            <a>お気に入り一覧<i className="c-gnav__menuIcon c-gnav__menuIcon--heart c-picIconHeart--gray"></i></a>
                        </li>,
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--resume" onClick={props.historyResume}
                        >
                            <a>視聴中の作品一覧<i className="c-gnav__menuIcon c-gnav__menuIcon--play"><div className="c-picIconPlay--gray"></div></i></a>
                        </li>,
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--purchase" onClick={props.historyPurchase}
                        >
                            <a>レンタル・取得した作品一覧<i className="c-gnav__menuIcon c-gnav__menuIcon--star c-picIconStar--gray"></i></a>
                        </li>
                    ]}
                    {/* アプリ対応：アプリで非表示. */}
                    {(memberId !== "" && !app.isAllApp()) &&
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--prof" onClick={props.handleProf}
                        >
                            <a>マイシップ<span className="c-gnav__smallTxt">（プロフィール）<i className="c-gnav__menuIcon c-gnav__menuIcon--prolife c-picIconMypage--gray"></i></span></a>
                        </li>
                    }
                    {/* アプリ対応：アプリで非表示. */}
                    {!app.isAllApp() &&
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item c-gnav__menu--gray"}
                            key="c-gnav__menu--imagination"
                            onClick={props.historyImagination}
                        >
                            <a>TSUBURAYA IMAGINATIONとは</a>
                        </li>
                    }
                    <li
                        className={classes.clickable + " c-gnav__menu c-gnav__menu--item c-gnav__menu--gray"}
                        key="c-gnav__menu--lecture"
                        onClick={props.historyOperation}
                    >
                        <a>操作ガイド</a>
                    </li>
                    <li
                        className={classes.clickable + " c-gnav__menu c-gnav__menu--item c-gnav__menu--gray"}
                        key="c-gnav__menu--faq"
                        onClick={props.historyFaq}
                    >
                        <a>FAQ / 問合せ</a>
                    </li>
                    {memberId === "" &&
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--login"
                            onClick={props.handleLogin}
                        >
                            <a>ログイン</a>
                        </li>}
                    {memberId !== "" &&
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item"}
                            key="c-gnav__menu--logout"
                            onClick={props.handleLogout}
                        >
                            <a>ログアウト</a>
                        </li>
                    }
                    {/* アプリ対応：Androidアプリで表示. */}
                    {app.isAndroidApp() &&
                        <li
                            className={classes.clickable + " c-gnav__menu c-gnav__menu--item c-gnav__menu--license"}
                            key="c-gnav__menu--oss"
                            onClick={props.handleOssLicense}
                        >
                            <span className="c-gnav__license">ライセンス情報</span>
                        </li>
                    }
                </ul>
            </div>
        </span>
    );
};

export default Menu;
