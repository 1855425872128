import React from "react";
import { useSelector } from "react-redux";
import * as plan from "../../constants/plan";
import { PROFILE_PICTURE } from "../../constants/domain";

/**
 * デジタル会員証.
 *
 * props
 *  -> handleDigitalCard  デジタル会員証の非表示
 */
const DigitalCard = (props) => {
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** プロフィール画像. */
    const profPicture = useSelector(state => state.Member.profPicture);

    return (
        <span>
            <div className="c-prof__digitalCard__head">
                <div className="c-back c-back--datacube" onClick={props.handleDigitalCard}>
                    <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                    <div className="c-back__txt">もどる</div>
                </div>
            </div>
            <div className="c-prof__digitalCard__headBg"></div>
            <div className="c-prof__headTxt">デジタル会員証</div>
            <div className="c-prof__digitalCard__wrap">
                <div className="c-prof__digitalCard__vis">
                    {plan.isFree(memberPlan) &&
                        <div className="c-prof__digitalCard__item c-prof__digitalCard__item--free">
                            <img src="/assets/images/img-digitalCard-free.png" alt="" loading="lazy" />
                        </div>
                    }
                    {plan.isStandard(memberPlan) &&
                        <div className="c-prof__digitalCard__item c-prof__digitalCard__item--standard">
                            <img src="/assets/images/img-digitalCard-standard.png" alt="" loading="lazy" />
                        </div>
                    }
                    {plan.isPremium(memberPlan) &&
                        <div className="c-prof__digitalCard__item c-prof__digitalCard__item--premium">
                            <img src="/assets/images/img-digitalCard-premium.png" alt="" loading="lazy" />
                        </div>
                    }
                    <div className="c-prof__digitalCard__detil">
                        <div className="c-prof__digitalCard__fav"><img src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)} alt="" loading="lazy" /></div>
                        <div className="c-prof__digitalCard__name">{memberId}</div>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default DigitalCard;
