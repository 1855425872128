import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as commonActions from "../../common/status/actions";
import * as cubeGroup from "../../constants/cubeGroup";
import * as eventif from "../../middleware/eventif";

/**
 * 追加特典取得確認.
 *
 * props
 *  -> handleGiftConfirm  追加特典取得確認の非表示
 */
const GiftConfirm = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** チェックボックスDOM. */
    const checkboxRef = useRef();
    /** OKボタンDOM. */
    const okRef = useRef();

    /** チェックボックス. */
    const [isCheckBox, setIsCheckBox] = useState(false);

    /** スペシャル図鑑. */
    const specialCubeGroup = useSelector(state => state.Member.specialCubeGroup);

    /**
     * チェックボックス切り替え時の処理.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (checkboxRef.current === null || okRef.current === null) return;

        // 表示切替.
        if (isCheckBox) {
            checkboxRef.current.classList.add("is-active");
            okRef.current.classList.add("is-active");
        } else {
            checkboxRef.current.classList.remove("is-active");
            okRef.current.classList.remove("is-active");
        }
    }, [isCheckBox]);

    /**
     * 「はい」押下時の処理.
     */
    const onAccept = () => {
        if (isCheckBox) {
            // 追加特典取得APIを実行.
            eventif.postMoreGift({
                [eventif.CAMPAIGN_ID]: specialCubeGroup[cubeGroup.CAMPAIGN_ID]
            }).then((data) => {
                // 追加特典取得確認の非表示.
                props.handleGiftConfirm();
            }).catch((err) => {
                // セッションエラーを確認.
                eventif.isSessionError(dispatch, err);
                // 追加特典取得失敗ダイアログ.
                dispatch(commonActions.moreGiftFailure(true));
            });
        }
    }

    /**
     * チェックボックス切替.
     */
    const handleCheckBox = (event) => {
        setIsCheckBox(event.target.checked);
    }

    return (
        <span>
            <div className="c-prof__cubeInfo__head">
                <div className="c-back c-back--datacube" onClick={props.handleGiftConfirm}>
                    <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                    <div className="c-back__txt">もどる</div>
                </div>
            </div>
            <div className="c-prof__cubeInfo__headBg"></div>
            <div className="c-prof__cubeInfoPop">
                <div className="c-prof__cubeInfoPop__wrap">
                    <div className="c-prof__cubeInfoPop__ttl">{specialCubeGroup[cubeGroup.MESSAGE_TITLE]}</div>
                    <div className="c-prof__cubeInfoPop__dtl">{specialCubeGroup[cubeGroup.MESSAGE_MAIN]?.split("\n").map(x => (<span>{x}<br /></span>))}</div>
                    <div className="c-prof__cubeInfoPop__checkBox">
                        <div className="c-prof__cubeInfoPop__checkBoxWrap">
                            <label className="c-prof__cubeInfoPop__checkBoxItem">
                                <input
                                    className="c-prof__cubeInfoPop__checkBoxMark"
                                    type="checkbox"
                                    onChange={handleCheckBox}
                                />
                                <span className="c-prof__cubeInfoPop__checkBoxDummy" ref={checkboxRef}></span>
                                <span className="c-prof__cubeInfoPop__checkBoxTxt" for="check">私は運営スタッフとして<br/>この特典を受け取り済みにします。</span>
                            </label>
                        </div>
                    </div>
                    <div className="c-prof__cubeInfo__attention">※誤って操作すると、特典を受け取れなくなります。</div>
                    <div className="c-btnWrap c-btnWrap--cubeInfo">
                        <div className="c-btn c-btnWrap__size c-btn--gray" onClick={props.handleGiftConfirm}>キャンセル</div>
                        <div
                            className="c-btn c-btnWrap__size c-btn--gray c-prof__cubeInfo__btnActive"
                            ref={okRef}
                            onClick={() => onAccept()}
                        >
                            OK
                        </div>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default GiftConfirm;
