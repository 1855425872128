import { RECOMMENDIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";

/** レコメンドコンテンツ表示件数. */
export const TOP_RECOMMEND = 12;
export const VIDEO_RECOMMEND = 15;
export const TEXT_RECOMMEND = 15;
export const SEARCH0_RECOMMEND = 16;
export const LIBRARY_RECOMMEND = 48;

export const CRID = "crid";

export const SPEC = "spec";

export const NUM = "num";

export const CUST = "cust";

export const PLATFORM = "platform";

export const CONTENT_LIST = "contentList";

export const CLICK_DATA = "clickData";

export const RQID = "rqid";

export const CREF = "cref";

export const RECOMMENDCOOKIE = "recommendcookie";

export const RECOMMENDDEVICE = "recommenddevice";

/**
 * レコメンドリクエストAPI.
 * API: POST /recommendrequest
 */
export async function postRecommendRequest(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(RECOMMENDIF + "/request", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    })
}

/**
 * レコメンドクリック通知API.
 * API: POST /recommendclick
 */
export async function postRecommendClick(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(RECOMMENDIF + "/click", data)
        .then(res => resolve(res.data))
        .catch(err => {
            reject(err.toString());
        });
    })
}

/**
 * コンバージョン通知API.
 * API: POST /conversion
 */
export async function postConversion(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(RECOMMENDIF + "/conversion", data)
        .then(res => resolve(res.data))
        .catch(err => {
            reject(err.toString());
        });
    })
}
