import * as actionTypes from "./actionTypes";
import * as accountif from "../../../middleware/accountif";

/**
 * 会員情報取得API 成功.
 */
export const updateMemberInfo = (data) => {
    return {
        type: actionTypes.UPDATE_MEMBER_INFO,
        memberId: data[accountif.USER_ID],
        profPicture: data[accountif.PROFILE_IMAGE_URL],
        joinDate: data[accountif.ADMISSION_DATETIME],
        creditNumber: data[accountif.CARD_NUMBER],
        planSelectedFlg: data[accountif.PLAN_SELECTED_FLG]
    };
}

/**
 * 通知一覧更新.
 */
export const updateNoticeList = (data) => {
    return {
        type: actionTypes.UPDATE_NOTICE_LIST,
        noticeList: data
    };
}

/**
 * お気に入りコンテンツ一覧更新.
 */
export const updateFavoriteList = (data) => {
    return {
        type: actionTypes.UPDATE_FAVORITE_LIST,
        favoriteList: data
    };
}

/**
 * 視聴中一覧更新.
 */
export const updateResumeList = (resumeList, completeList) => {
    return {
        type: actionTypes.UPDATE_RESUME_LIST,
        resumeList: resumeList,
        completeList: completeList
    };
}

/**
 *  現在のプラン情報更新.
 */
export const updateMemberPlan = (mp, ed, isCampaign) => {
    return {
        type: actionTypes.UPDATE_MEMBER_PLAN,
        memberPlan: mp,
        expirationDate: ed,
        isCampaign: isCampaign
    };
}

/**
 * 変更後のプラン情報更新.
 */
export const updateChangeMemberPlan = (cmp, cd) => {
    return {
        type: actionTypes.UPDATE_CHANGE_MEMBER_PLAN,
        changeMemberPlan: cmp,
        changeDate: cd
    };
}

/**
 * 購入一覧（ライセンスID）更新.
 */
export const updatePurchaseList = (purchase, gift) => {
    return {
        type: actionTypes.UPDATE_PURCHASE_LIST,
        purchaseList: purchase,
        giftList: gift
    };
}

/**
 * キャンペーン一覧更新.
 */
export const updateCampaignList = (data) => {
    return {
        type: actionTypes.UPDATE_CAMPAIGN_LIST,
        campaignList: data
    };
}

/**
 * スペシャル図鑑一覧更新.
 */
export const updateSpecialCubeGroupList = (data) => {
    return {
        type: actionTypes.UPDATE_SPECIAL_CUBE_GROUP_LIST,
        specialCubeGroupList: data
    };
}

/**
 * スペシャル図鑑更新.
 */
export const updateSpecialCubeGroup = (data) => {
    return {
        type: actionTypes.UPDATE_SPECIAL_CUBE_GROUP,
        specialCubeGroup: data
    };
}

/**
 * データキューブ一覧更新.
 */
export const updateDataCubeList = (data) => {
    return {
        type: actionTypes.UPDATE_DATA_CUBE_LIST,
        dataCubeList: data
    };
}

/**
 * データキューブ更新.
 */
export const updateDataCube = (data) => {
    return {
        type: actionTypes.UPDATE_DATA_CUBE,
        dataCube: data
    };
}

/**
 * ファイル：プラン料金更新.
 */
export const updatePlanPrice = (data) => {
    return {
        type: actionTypes.UPDATE_PLAN_PRICE,
        planPrice: data
    };
}

/**
 * ファイル：汎用設定（app_config_web）更新.
 */
export const updateAppConfigWeb = (data) => {
    return {
        type: actionTypes.UPDATE_APP_CONFIG_WEB,
        appConfigWeb: data
    };
}

/**
 * ファイル：プロフィール画像リスト更新.
 */
export const updateProfPictureList = (data) => {
    return {
        type: actionTypes.UPDATE_PROF_PICTURE_LIST,
        profPictureList: data
    };
}

/**
 * ファイル：プラネット定義リスト更新.
 */
export const updatePlanetList = (data) => {
    return {
        type: actionTypes.UPDATE_PLANET_LIST,
        planetList: data
    };
}

/**
 * ファイル：プラネット定義リスト更新.
 */
 export const updatePlanetGroupList = (data) => {
    return {
        type: actionTypes.UPDATE_PLANET_GROUP_LIST,
        planetGroupList: data
    };
}

/**
 * プロフィール画像更新.
 */
export const updateProfPicture = (data) => {
    return {
        type: actionTypes.UPDATE_PROF_PICTURE,
        profPicture: data
    };
}
