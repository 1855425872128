import React, { useState } from "react";
import { useSelector } from "react-redux";
import { decode } from "js-base64";

import * as plan from "../../../constants/plan";
import * as contentsKey from "../../../constants/contentsKey";
import { WITHDRAWAL_LP_CRID, WITHDRAWAL_REASON_CRID } from "../../../constants/setting";
import useFavorite from "../../../hooks/useFavorite";
import ShareButton from "../../dialog/ShareButton";

/**
 * お気に入り・シェアボタン.
 */
const FavoriteShareButton = (props) => {
    /** シェアボタンダイアログ開閉 */
    const [isShare, setIsShare] = useState(false);

    /** お気に入り機能実装用フック. */
    const [favRef, handleFav] = useFavorite(props.content.crid);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 汎用設定（app_config_web）. */
    const appConfigWeb = useSelector(state => state.Member.appConfigWeb);

    /**
     * 退会アンケートコンテンツか判定.
     */
    const isWithdrawalContents = () => {
        const splitPathname = props.location.pathname.split("/");
        const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
        if ([appConfigWeb[WITHDRAWAL_REASON_CRID], appConfigWeb[WITHDRAWAL_LP_CRID]].includes(contentsId)) return true;
        return false;
    }

    /**
     * お気に入り・シェアボタンの表示/非表示.
     */
    const favoriteShareButton = () => {
        const favoriteButton = () => {
            return (
                <div
                className="c-serviceBtn__item c-serviceBtn__item--fav"
                onClick={() => handleFav()}
                ref={favRef}
            >
                <div className="c-serviceBtn__icon">
                    <div className="c-picIconPlus"></div>
                    <div className="c-picIconHeart"></div>
                </div>
            </div>
            );
        }

        // お気に入りボタンとシェアボタン両方表示.
        if (props.content[contentsKey.FAVORITE_BUTTON] && props.content[contentsKey.SNS_BUTTON] && !plan.isNone(memberPlan) && !isWithdrawalContents()) {
            return (
                <div className="c-serviceBtn c-contentsBtn">
                    <div className="c-serviceBtn__btnWrap">
                        {/* お気に入りボタン. */}
                        {favoriteButton()}
                        {/* シェアボタン. */}
                        <div className="c-serviceBtn__item c-serviceBtn__item--share" onClick={() => setIsShare(true)}>
                            <div className="c-serviceBtn__icon">
                                <div className="c-picIconShare" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        // お気に入りボタンのみ表示.
        else if (props.content[contentsKey.FAVORITE_BUTTON] && !plan.isNone(memberPlan) && !isWithdrawalContents()) {
            return (
                <div className="c-serviceBtn__btnWrap c-serviceBtn__btnWrap--artical">
                    {favoriteButton()}
                </div>
            );
        }
        // シェアボタンのみ表示.
        else if (props.content[contentsKey.SNS_BUTTON]) {
            return (
                <div className="c-serviceBtn c-contentsBtn">
                    <div className="c-serviceBtn__btnWrap c-contentsBtn--noLogin">
                        <div className="c-serviceBtn__item c-serviceBtn__item--share" onClick={() => setIsShare(true)}>
                            <div className="c-serviceBtn__icon">
                                <div className="c-picIconShare" />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        // 両方非表示.
        else {
            return null;
        }
    };

    return (
        <>
            {isShare && <ShareButton close={() => setIsShare(false)}/>}
            <div className="c-contents__txt">
                {favoriteShareButton()}
            </div>
        </>
    );
};

export default FavoriteShareButton;