/**
 * エラーダイアログ文言.
 */

/** 円谷再認証失敗. */
export const TSUBURAYA_DOUBLE_FAILURE_TITLE = "ログインに失敗しました。";
export const TSUBURAYA_DOUBLE_FAILURE_CONTENT = "エラーが発生したため、ログインに失敗しました。時間をおいて再度ログインをお試しください。";

/** キャンペーン遷移失敗. */
export const CAMPAIGN_DESTINATION_FAILURE_TITIE = "お探しのキャンペーンを見つけることができませんでした。";
export const CAMPAIGN_DESTINATION_FAILURE_CONTENT = "";

/** キューブ情報取得失敗. */
export const DATA_CUBE_FAILURE_TITLE = "お探しのデータキューブリストを見つけることができませんでした。";
export const DATA_CUBE_FAILURE_CONTENT = "";

/** 追加特典取得失敗. */
export const MORE_GIFT_FAILURE_TITIE = "お探しのデータキューブを見つけることができませんでした。";
export const MORE_GIFT_FAILURE_CONTENT = "";

/** 追加特典取得失敗（オンラインクーポン）. */
export const MORE_GIFT_COUPON_FAILURE_TITIE = "特典の取得に失敗しました。";
export const MORE_GIFT_COUPON_FAILURE_CONTENT = "ご迷惑をおかけして申し訳ございません。メニュー＞問合わせよりお問い合わせください。";

/** 無料キャンペーンプラン選択誤り. */
export const FREE_CAMPAIGN_SELECT_FAILURE_TITLE = "キャンペーン対象外のプランです。";
export const FREE_CAMPAIGN_SELECT_FAILURE_CONTENT = "選択したプランはキャンペーン対象外となります。対象のキャンペーンを選択してください。";

/** クーポン行使 プラン未充足. */
export const COUPON_USED_FAILURE_PLAN_TITLE = "お客様の契約プランではクーポン・エントリーコードをご利用いただけません。";
export const COUPON_USED_FAILURE_PLAN_CONTENT = "お客様の契約プランではクーポン・エントリーコードをご利用いただけません。クーポン・エントリーコードをご利用になる場合は、プラン変更が必要となります。";

/** クーポン行使 無効. */
export const COUPON_USED_FAILURE_INVALID_TITLE = "このクーポン・エントリーコードはご使用いただけません。";
export const COUPON_USED_FAILURE_INVALID_CONTENT = "既にクーポン・エントリーコードをご利用いただいているか、入力の誤りがある可能性がございます。再度入力内容をご確認ください。";

/** クーポン行使 有効期間前. */
export const COUPON_USED_FAILURE_BEFORE_VALID_TITLE = "ご利用可能期間前のクーポン・エントリーコードです。";
export const COUPON_USED_FAILURE_BEFORE_VALID_CONTENT = "クーポン・エントリーコードのご利用可能期間前です。ご利用可能期間になりましたら再度アクセスしてください。";

/** クーポン行使 有効期限切れ. */
export const COUPON_USED_FAILURE_EXPIRED_TITLE = "クーポン・エントリーコードの有効期限が過ぎています。";
export const COUPON_USED_FAILURE_EXPIRED_CONTENT = "申し訳ございません。クーポン・エントリーコードの有効期限が過ぎているため、ご利用いただけません。";

/** クーポン行使 他ユーザが使用済み. */
export const COUPON_USED_FAILURE_OTHER_TITLE = "既に使用済みのクーポン・エントリーコードです。";
export const COUPON_USED_FAILURE_OTHER_CONTENT = "このクーポン・エントリーコードは既に使用済みです。申し訳ございませんが、一度ご利用になったクーポン・エントリーコードは再度ご利用にはなれません。";

/** クーポン行使 同じユーザが使用済み. */
export const COUPON_USED_FAILURE_USED_TITLE = "既にご利用済みクーポン・エントリーコードです。";
export const COUPON_USED_FAILURE_USED_CONTENT = "このクーポン・エントリーコードは既にご利用済みです。申し訳ございませんが、一度ご利用されたクーポン・エントリーコードは再度ご利用できません。";

/** クーポン行使 クーポンとリクエストのキャンペーンIDが不一致. */
export const COUPON_USED_FAILURE_MISMATCH_TITLE = "このクーポン・エントリーコードはご使用いただけません。";
export const COUPON_USED_FAILURE_MISMATCH_CONTENT = "既にクーポン・エントリーコードをご利用いただいているか、入力の誤りがある可能性がございます。再度入力内容をご確認ください。";

/** クーポン未入力エラー. */
export const COUPON_USED_FAILURE_REQUIRED_TITLE = "クーポン・エントリーコードが未入力です。";
export const COUPON_USED_FAILURE_REQUIRED_CONTENT = "クーポン・エントリーコードをご確認の上、入力をお願いいたします。";

/** クーポン行使 上記以外のエラー. */
export const COUPON_USED_FAILURE_TITLE = "エラーが発生いたしました。";
export const COUPON_USED_FAILURE_CONTENT = "ご迷惑をおかけして申し訳ございません。システムエラーが発生いたしました。時間をおいて再度お試しください。";

/** アンケート登録失敗時. */
export const SEND_QUESTIONNAIRE_INPUT_FAILURE_TITLE = "ご登録に失敗しました。";
export const SEND_QUESTIONNAIRE_INPUT_FAILURE_CONTENT = "ご入力内容のシステム登録に失敗しました。お手数ですが、再度「送信する」ボタンを押してください。";

/** アンケート送信失敗. */
export const SEND_QUESTIONNAIRE_FAILURE_TITLE = "送信エラー";
export const SEND_QUESTIONNAIRE_FAILURE_CONTENT = "エラーが発生したため、送信できませんでした。";

/** 重複投稿. */
export const DUPLICATE_POSTS_TITLE = "すでに送信済です。";
export const DUPLICATE_POSTS_CONTENT = "すでに送信いただいています。おひとり様一度のみ送信可能です。";

/** 重複投稿（退会アンケート）. */
export const DUPLICATE_POSTS_WITHDRAWAL_TITLE = "ご利用ありがとうございました。";
export const DUPLICATE_POSTS_WITHDRAWAL_CONTENT = "まだ退会処理は完了していません。このまま退会手続きをお進めください。";

/** アプリ対応：ログイン時dアカウント未指定. */
export const APP_DACCOUNT_FAILURE_TITLE = "dアカウントのリンクが必要です。";
export const APP_DACCOUNT_FAILURE_CONTENT = "dアカウントの登録が必須となります。「TSUBURAYA　IMAGINATION」のWEBサイトからdアカウントの登録をお願いいたします。";

/** アプリ対応：ログイン時プラン未選択. */
export const APP_SELECT_PLAN_FAILURE_TITLE = "プランの登録をお願いいたします。";
export const APP_SELECT_PLAN_FAILURE_CONTENT = "本アプリを楽しむには、ご利用プランの登録が必要です。「TSUBURAYA IMAGINATION」のWEBサイトからプラン登録をお願いいたします。";

/** アプリ対応：アプリ閲覧不可LP警告. */
export const APP_NOT_DISPLAY_LP_TITLE = "アプリではご覧になれないページです。";
export const APP_NOT_DISPLAY_LP_CONTENT = "TSUBURAYA IMAGINATION のWebサイトへ移動してご覧ください。";

/** ムビチケ購入番号が不正. */
export const MOVIE_TICKET_FAILURE_ILLEGAL_NUMBER_TITLE = "この購入番号はご使用いただけません。";
export const MOVIE_TICKET_FAILURE_ILLEGAL_NUMBER_CONTENT = "既に購入番号をご利用いただいているか、入力の誤りがある可能性がございます。再度入力内容をご確認ください。";

/** ムビチケ応募済み. */
export const MOVIE_TICKET_FAILURE_APPLIED_TITLE = "お客様はすでに応募済みです。";
export const MOVIE_TICKET_FAILURE_APPLIED_CONTENT = "このキャンペーンは、複数の購入者番号をお持ちの場合でも応募は1度限りとなります。";

/** ムビチケ購入番号使用済み. */
export const MOVIE_TICKET_FAILURE_USED_NUMBER_TITLE = "この購入番号は使用済みです。";
export const MOVIE_TICKET_FAILURE_USED_NUMBER_CONTENT = "入力された購入番号は既に使用されています。";

/** ムビチケ応募期間開始前. */
export const MOVIE_TICKET_FAILURE_BEFORE_START_TITLE = "ご利用可能期間前です。";
export const MOVIE_TICKET_FAILURE_BEFORE_START_CONTENT = "入力いただいた購入番号はご利用可能期間前です。ご利用可能期間になりましたら再度アクセスしてください。";

/** ムビチケ応募期間終了済. */
export const MOVIE_TICKET_FAILURE_AFTER_END_TITLE = "有効期限が過ぎています。";
export const MOVIE_TICKET_FAILURE_AFTER_END_CONTENT = "申し訳ございません。購入番号の有効期限が過ぎているため、ご利用いただけません。";

/** ムビチケ応募失敗. */
export const MOVIE_TICKET_FAILURE_TITLE = "応募に失敗しました。";
export const MOVIE_TICKET_FAILURE_CONTENT = "応募に失敗しました。時間をおいて再度応募をお試しください。";

/** 契約未充足で視聴不可. */
export const VIDEO_LICENSE_FAILURE_TITLE = "視聴エラー";
export const VIDEO_LICENSE_FAILURE_CONTENT = "お客様の契約では視聴できません。";

/** TVOD購入エラー. */
export const TVOD_PURCHASE_FAILURE_TITLE = "お客様の契約プランではレンタル購入いただけません。";
export const TVOD_PURCHASE_FAILURE_CONTENT = "レンタル購入いただくにはプラン変更が必要となります。";

/** 特典未取得エラー. */
export const UNACQUIRED_GIFT_FAILURE_TITLE = "こちらは特典ページです";
export const UNACQUIRED_GIFT_FAILURE_CONTENT = "特典をお持ちでないため、続きをご覧いただくことができません。";
