import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancelPlanFailure, overseasError } from "../../common/status/actions";
import { openPopupCreditResult } from "../../common/popup/credit/actions";
import * as plan from "../../constants/plan";
import { PLAN_CANCEL } from "../../constants/credit";
import * as apiUtil from "../../constants/apiUtil";
import * as contractif from "../../middleware/contractif";
import { updateInfo } from "../../common/popup/credit/creditUtils";
import Dialog from "./Default";
import Progress from "./Progress";

/**
 * プラン変更キャンセル確認ダイアログ.
 *
 * props
 *  -> setIsPlanCancel ダイアログの表示/非表示.
 */
const PlanCancel = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** ローディング状態. */
    const [isLoading, setIsLoading] = useState(false);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 変更後の会員プラン. */
    const changeMemberPlan = useSelector(state => state.Member.changeMemberPlan);

    const contractBody = () => {
        switch(changeMemberPlan) {
            case plan.NONE:     // 退会をキャンセル
            case plan.FREE:     // 無料プランへのダウングレードをキャンセル
                return {
                    [contractif.CONTRACT_TYPE]: contractif.CANCEL,
                    [contractif.RECOVER_PLAN]: memberPlan,
                };
            case plan.STANDARD: // スタンダードプランへのダウングレードをキャンセル
                return {
                    [contractif.CONTRACT_TYPE]: contractif.CANCEL,
                    [contractif.RECOVER_PLAN]: memberPlan,
                    [contractif.CANCEL_PLAN]: changeMemberPlan
                };
            default:
                return null;
        }
    };

    /**
     * 「はい」押下時.
     */
    const onAccept = () => {
        setIsLoading(true);

        const body = contractBody();
        if (body) {
            contractif.postContractManage(body)
            .then((res) => {
                // 契約管理API 成功.

                // 会員情報更新.
                updateInfo(dispatch, res);

                // 完了画面表示.
                dispatch(openPopupCreditResult(PLAN_CANCEL, "", 0));
            })
            .catch((err) => {
                // 契約管理API 失敗.

                const errCode = err[apiUtil.ERROR_CODE] ? err[apiUtil.ERROR_CODE].slice(-3) : "";
                if (errCode === contractif.OVERSEAS_ERROR_CODE) {
                    dispatch(overseasError(true));
                    onReject();
                }
                else {
                    // 警告ダイアログ表示.
                    dispatch(cancelPlanFailure(true));
                }
            })
            .finally(() => {
                // 後処理.
                props.setIsPlanCancel(false);
            })
        }
    };

    /**
     * 「いいえ」押下時.
     */
    const onReject = () => {
        props.setIsPlanCancel(false);
    };

    return (
        <>
            {isLoading && <Progress />}
            <Dialog
                input={{title:"プラン変更キャンセル確認", content:"プランの変更予約をキャンセルしますか？"}}
                onAccept={() => onAccept()}
                onReject={() => onReject()}
            />
        </>
    );
};

export default PlanCancel;
