import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";

import Footer from "./common/footer/Footer";
import Header from "./common/header/Header";
import Imagination from "./components/screen/imagination/Imagination";
import LibraryId from "./components/screen/libraryId/LibraryId";
import LiveId from "./components/screen/videoId/LiveId";
import Planet from "./components/screen/planet/Planet";
import PlanetId from "./components/screen/planetId/PlanetId";
import Search from "./components/screen/search/Search";
import ContentsId from "./components/screen/contentsId/ContentsId";
import Top from "./components/screen/top/Top";
import VideoId from "./components/screen/videoId/VideoId";
import Trems from "./components/screen/trems/Trems";
import Privacy from "./components/screen/privacy/Privacy";
import Environment from "./components/screen/environment/Environment";
import Transaction from "./components/screen/transaction/Transaction";
import Logged from "./components/screen/logged/Logged";
import Operation from "./components/screen/operation/Operation";
import Faq from "./components/screen/faq/Faq";
import Cushion from "./components/screen/cushion/Cushion";
import Coupon from "./components/screen/coupon/Coupon";
import Default from "./components/screen/default/Default";
import Login from "./components/screen/app/login/Login";
import Plan from "./components/screen/app/plan/Plan";
import Tvod from "./components/screen/app/tvod/Tvod";
import Branch from "./components/screen/app/branch/Branch";
import AppPrivacy from "./components/screen/app/privacy/Privacy";
import Howtocube from "./components/screen/howtocube/Howtocube";
import Ticket from "./components/screen/app/ticket/Ticket";

const App = () => {
    /** Hooks. */
    const location = useLocation();

    /**
     * 3択画面判定（アプリ対応）.
     */
    const isAppBranch = () => {
        return location.pathname.indexOf("/app/branch") !== -1;
    }

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                <Header />
            </Grid>
            <Grid item xs={12}>
                <Switch>
                    {/** トップ画面. */}
                    <Route exact path="/" component={Top} />

                    {/** 検索一覧画面. */}
                    <Route exact path="/search" component={Search} />

                    {/** プラネット画面. */}
                    <Route exact path="/planet" component={Planet} />

                    {/** プラネット別一覧画面. */}
                    <Route exact path="/planet/:id" component={PlanetId} />

                    {/** ライブラリ一覧画面. */}
                    {/** お気に入り一覧画面. /library/favorite */}
                    {/** 視聴中の作品一覧画面. /library/resume */}
                    {/** レンタル・取得した作品一覧画面. /library/purchase */}
                    <Route exact path="/library/:id" component={LibraryId} />

                    {/** ライブラリ一覧画面（関連一覧）. */}
                    <Route exact path="/library/recommend/:id" component={LibraryId} />

                    {/** イマジネーションとは画面. */}
                    <Route exact path="/imagination" component={Imagination} />

                    {/** 詳細画面（動画）. */}
                    <Route exact path="/video/:id" component={VideoId} />

                    {/** 詳細画面（Live）. */}
                    <Route exact path="/live/:id" component={LiveId} />

                    {/** 静的コンテンツ. */}
                    <Route exact path="/contents/:id" component={ContentsId} />

                    {/** 会員規約画面. */}
                    <Route exact path="/trems" component={Trems} />

                    {/** プライバシーポリシー画面. */}
                    <Route exact path="/privacy" component={Privacy} />

                    {/** 推奨環境画面. */}
                    <Route exact path="/environment" component={Environment} />

                    {/** 特定商法取引法画面. */}
                    <Route exact path="/transaction" component={Transaction} />

                    {/** サービス紹介画面. */}
                    <Route exact path="/logged" component={Logged} />

                    {/** 操作ガイド画面. */}
                    <Route exact path="/operation" component={Operation} />

                    {/** FAQ/問い合わせ画面. */}
                    <Route exact path="/faq" component={Faq} />

                    {/** クッションページ. */}
                    <Route exact path="/auth" component={Cushion} />

                    {/** QRクッションページ. */}
                    <Route exact path="/coupon/:id" component={Coupon} />

                    {/** ログインLP（アプリ対応）. */}
                    <Route exact path="/app/login" component={Login} />

                    {/** プランLP（アプリ対応）. */}
                    <Route exact path="/app/plan" component={Plan} />

                    {/** TVODLP（アプリ対応）. */}
                    <Route exact path="/app/tvod" component={Tvod} />

                    {/** 3分岐画面（アプリ対応）. */}
                    <Route exact path="/app/branch" component={Branch} />

                    {/** プライバシーポリシー画面（アプリ対応）. */}
                    <Route exact path="/app/privacy" component={AppPrivacy} />

                    {/** データキューブの楽しみ方画面. */}
                    <Route exact path="/howtocube" component={Howtocube} />

                    {/** チケットクッションページ（アプリ対応）. */}
                    <Route exact path="/app/ticket" component={Ticket} />

                    {/** 不正パスは404画面. */}
                    <Route component={Default} />
                </Switch>
            </Grid>
            {!isAppBranch() &&
                <Grid item xs={12}>
                    <Footer />
                </Grid>
            }
        </Grid>
    );
}

export default App;
