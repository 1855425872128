import * as actionTypes from "./actionTypes";

/**
 * 初期処理判定.
 */
export const init = (isInit) => {
    return {
        type: actionTypes.INIT,
        isInit: isInit
    };
}

/**
 * ログアウト.
 */
export const logout = () => {
    return {
        type: actionTypes.LOGOUT
    };
}

/**
 * 退会.
 */
export const withdrawal = () => {
    return {
        type: actionTypes.WITHDRAWAL
    };
}

/**
 * 端末時刻判定（WARN）.
 */
export const updateTimeWarn = (isTimeWarn) => {
    return {
        type: actionTypes.UPDATE_TIME_WARN,
        isTimeWarn: isTimeWarn
    };
}

/**
 * 端末時刻判定（NG）.
 */
export const updateTimeNg = (isTimeNG) => {
    return {
        type: actionTypes.UPDATE_TIME_NG,
        isTimeNG: isTimeNG
    };
}

/**
 * 通信失敗.
 */
export const updateConectionError = (isConectionError, errorCode = "") => {
    return {
        type: actionTypes.UPDATE_CONECTION_ERROR,
        isConectionError: isConectionError,
        httpErrorCode: errorCode
    };
}

/**
 * プロフィール画像変更失敗.
 */
export const updateChangeProfPictureFailure = (isChangeProfPictureFailure) => {
    return {
        type: actionTypes.UPDATE_CHANGE_PROF_PICTURE_FAILURE,
        isChangeProfPictureFailure: isChangeProfPictureFailure
    };
}

/**
 * プランキャンセル失敗.
 */
export const cancelPlanFailure = (isCancelPlanFailure) => {
    return {
        type: actionTypes.CANCEL_PLAN_FAILURE,
        isCancelPlanFailure: isCancelPlanFailure
    };
}

/**
 * 退会失敗.
 */
export const withdrawalFailure = (isWithdrawalFailure) => {
    return {
        type: actionTypes.WITHDRAWAL_FAILURE,
        isWithdrawalFailure: isWithdrawalFailure
    };
}

/**
 * ログアウト失敗.
 */
export const logoutFailure = (isLogoutFailure) => {
    return {
        type: actionTypes.LOGOUT_FAILURE,
        isLogoutFailure: isLogoutFailure
    };
}

/**
 * お気に入り変更失敗.
 */
export const favoriteFailure = (isFavoriteFailure, content) => {
    return {
        type: actionTypes.FAVORITE_FAILURE,
        favoriteFailure: {
            status: isFavoriteFailure,
            content: content
        }
    };
}

/**
 * キャンペーン遷移失敗.
 */
export const campaignDestinationFailure = (isCampaignDestinationFailure) => {
    return {
        type: actionTypes.CAMPAIGN_DESTINATION_FAILURE,
        isCampaignDestinationFailure: isCampaignDestinationFailure
    };
}

/**
 * データキューブ情報取得失敗.
 */
export const dataCubeFailure = (isDataCubeFailure) => {
    return {
        type: actionTypes.DATA_CUBE_FAILURE,
        isDataCubeFailure: isDataCubeFailure
    };
}

/**
 * 追加特典取得失敗.
 */
export const moreGiftFailure = (isMoreGiftFailure) => {
    return {
        type: actionTypes.MORE_GIFT_FAILURE,
        isMoreGiftFailure: isMoreGiftFailure
    };
}

/**
 * 追加特典取得失敗（オンラインクーポン）.
 */
export const moreGiftCouponFailure = (isMoreGiftCouponFailure) => {
    return {
        type: actionTypes.MORE_GIFT_COUPON_FAILURE,
        isMoreGiftCouponFailure: isMoreGiftCouponFailure
    };
}

/**
 * 処理中ダイアログ.
 */
export const loading = (isLoading) => {
    return {
        type: actionTypes.LOADING,
        isLoading: isLoading
    };
}

/**
 * 海外エラー.
 */
export const overseasError = (isOverseasError) => {
    return {
        type: actionTypes.OVERSEAS_ERROR,
        isOverseasError: isOverseasError
    };
}

/**
 * クーポン行使画面.
 */
export const couponUsed = (status, meta) => {
    return {
        type: actionTypes.COUPON_USED,
        isCouponUsed: {
            status: status,
            campaignMeta: meta
        }
    };
}

/**
 * クーポン プラン未充足エラー.
 */
export const couponUsedPlanFailure = (isCouponUsedPlanFailure) => {
    return {
        type: actionTypes.COUPON_USED_PLAN_FAILURE,
        isCouponUsedPlanFailure: isCouponUsedPlanFailure
    };
}

/**
 * クーポン行使エラー.
 */
export const couponUsedFailure = (status, title, content) => {
    return {
        type: actionTypes.COUPON_USED_FAILURE,
        isCouponUsedFailure: {
            status: status,
            title: title,
            content: content
        }
    };
}

/**
 * アプリ対応：アプリ閲覧不可LP警告ダイアログ表示/非表示.
 */
export const appNotDisplayLP = (isAppNotDisplayLP) => {
    return {
        type: actionTypes.APP_NOT_DISPLAY_LP,
        isAppNotDisplayLP: isAppNotDisplayLP
    };
}