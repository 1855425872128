/** 初期化. */
export const INIT_LIBRARYID = "INIT_LIBRARYID";

/** シリーズタイトル更新. */
export const LIBRARYID_UPDATE_SERIES_TITLE = "LIBRARYID_UPDATE_SERIES_TITLE";

/** コンテンツ更新. */
export const LIBRARYID_UPDATE_CONTENTS_LIST = "LIBRARYID_UPDATE_CONTENTS_LIST";

/** 特典コンテンツ更新. */
export const LIBRARYID_UPDATE_GIFT_CONTENTS_LIST = "LIBRARYID_UPDATE_GIFT_CONTENTS_LIST";

/** レコメンドコンテンツ更新. */
export const LIBRARYID_UPDATE_RECOMMEND_CONTENTS_LIST = "LIBRARYID_UPDATE_RECOMMEND_CONTENTS_LIST";