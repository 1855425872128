import * as actionTypes from "./actionTypes";

const initState = {
    volume: 50,
    muted: false,
    /** 画質設定ポップアップ表示/非表示. */
    changeIsImageQuality: false,
    /** データセーバー: true、自動: false. */
    /** 全体の画質設定（ブラウザ). */
    allQuality: false,
    /** WiFi回線の画質設定（アプリ）. */
    allWifiQuality: false,
    /** モバイル回線の画質設定（アプリ）. */
    allMobileQuality: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.HANDLE_VOLUME:
            return { ...state, volume: action.volume };
        case actionTypes.HANDLE_MUTE:
            return { ...state, muted: action.muted };
        case actionTypes.CHANGE_IS_IMAGE_QUALITY:
            return{ ...state, changeIsImageQuality: action.changeIsImageQuality};
        case actionTypes.ALL_QUALITY:
            return{ ...state, allQuality: action.allQuality};
        case actionTypes.ALL_WIFI_QUALITY:
            return{ ...state, allWifiQuality: action.allWifiQuality};
        case actionTypes.ALL_MOBILE_QUALITY:
            return{ ...state, allMobileQuality: action.allMobileQuality};
        default:
            return state;
    }
};

export default reducer;