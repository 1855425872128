import * as actionTypes from "./actionTypes";

const initState = {
    /** Planet情報. */
    planet: {},
    /** コンテンツリスト. */
    contentsList: [],
    /** 選択中カテゴリー. */
    currentCategory: "C00",
    /** 選択中プラン. */
    currentPlan: "P00",

    back:0
};
let sessionSet = 0

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 初期化. */
        case actionTypes.INIT_PLANET_ID:
            return {...state, planet: {}, contentsList: []};

        /** プラネット更新. */
        case actionTypes.UPDATE_PLANET:
            return { ...state, planet: action.planet };

        /** 検索コンテンツリスト更新. */
        case actionTypes.UPDATE_PLANETID_CONTENTS_LIST:
            return { ...state, contentsList: action.contentsList };

        /** 選択中カテゴリー更新. */
        case actionTypes.UPDATE_PLANETID_CURRENT_CATEGORY:
            return { ...state, currentCategory: action.currentCategory };

        /** 選択中プラン更新. */
        case actionTypes.UPDATE_PLANETID_CURRENT_PLAN:
            return { ...state, currentPlan: action.currentPlan };

        case actionTypes.PLANETBACKTRUE:
            sessionSet++
            return {...state,back:sessionSet}
        default:
            return state;
    }
};

export default reducer;
