import { SESSIONIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";

/** クライアント端末時間. */
export const CLIENT_TIME = "client_time";

/** 端末時間差分. */
export const STATUS = "status";

/** 正常. */
export const OK = "ok";

/** 5分未満遅れ. */
export const WARN = "warn";

/** 5分以上遅れ. */
export const NG = "ng";

/**
 * 端末時刻判定API.
 * API: POST /time
 */
export async function postTime(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(SESSIONIF + "/time", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}
