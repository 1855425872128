import React, {useEffect} from "react";
import * as cubeGroup from "../../../constants/cubeGroup";
import styles from "./styles";
import lozad from "lozad";

/**
 * スペシャル図鑑バナー.
 *
 * props
 *  -> meta                    スペシャル図鑑バナーのメタ.
 *  -> handleSpecialCubeGroup  スペシャル図鑑の表示.
 */
const SpecialCubeGroupBannar = (props) => {
    /** CSS. */
    const classes = styles();

    /** 画面描画時の処理
    */
    useEffect(() => {
        // 画像遅延読み込みの設定
        let observer = lozad('.lozad', {rootMargin: '300px',loaded: function(el) {el.classList.add('loaded');}});
        observer.observe();
    }, [])

    /**
     * コンテンツクリック時.
     */
    const handleClick = () => {
        // 対象のスペシャル図鑑を表示.
        props.handleSpecialCubeGroup(props.meta[cubeGroup.GROUP_ID]);
    }

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 新規バナーか判定.
     */
    const isNew = () => {
        return props.meta[cubeGroup.NEW_FLG];
    }

    return (
        <span
            className={classes.root}
            onClick={() => handleClick()}
            onContextMenu={(event) => handleContextMenu(event)}
        >
            {isNew() && <div className="c-tag c-tag--new">NEW</div>}
            <img className="lozad" data-src={props.meta[cubeGroup.BANNER_FILE]} alt="" />
        </span>
    );
};

export default SpecialCubeGroupBannar;
