import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./actions";
import * as planActions from "../../../common/popup/plan/actions";
import * as couponActions from "../../../common/status/coupon/actions";
import * as screenActions from "../../../common/status/screen/actions";
import * as commonActions from "../../../common/status/actions";
import * as historys from "../../../historys";
import * as contentif from "../../../middleware/contentif";
import * as eventif from "../../../middleware/eventif";
import * as accountif from "../../../middleware/accountif";
import * as authif from "../../../middleware/authif";
import * as constantsStatic from "../../../constants/static";
import * as error from "../../../constants/error";
import * as plan from "../../../constants/plan"
import * as apiUtil from "../../../constants/apiUtil";
import * as contentsKey from "../../../constants/contentsKey";
import * as contentType from "../../../constants/contentType";
import * as libraryId from "../../../constants/libraryId";
import { FILE_TEMPLATE } from "../../../constants/domain";
import { isWithdrawalReasonContents } from "../../../constants/setting";
import * as app from "../../../constants/app";
import * as campaign from "../../../constants/campaign";
import * as constantsCoupon from "../../../constants/coupon";
import { loading, updateConectionError } from "../../../common/status/actions";
import { CONTENTS_TYPE_AMECOMI, CONTENTS_TYPE_COMIC } from "../../viewer/param";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Viewer from "../../viewer/Viewer";
import DefaultOne from "../../dialog/DefaultOne";
import Warning from "../../dialog/Warning";
import WarningFrontTwo from "../../dialog/WarningFrontTwo";
import "./styles.css";
import WarningContentList from "../../dialog/WarningContentList";
import MovieTicket from "./MovieTicket";
import { savePrevPage } from "../cushion/actions";
import CouponTicket from "../../dialog/CouponTicket";

const Static = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    /** アンケート送信成功ダイアログ. */
    const questSuccessInitValue = {
        open: false,
        title: "送信完了",
        content: "送信が完了しました。",
        ok: "OK"
    };
    const [questSuccess, setQuestSuccess] = useState(questSuccessInitValue);
    /** アンケート送信失敗ダイアログ. */
    const questFailureInitValue = {
        open: false,
        title: "",
        content: "",
        ok: ""
    };
    const [questFailure, setQuestFailure] = useState(questFailureInitValue);
    /** 必須項目未入力エラーダイアログ. */
    const [requiredFailure, setRequiredFailure] = useState({
        open: false,
        contentList: []
    });
    /** クーポン プラン未充足エラー. */
    const [isCouponUsedPlanFailure, setIsCouponUsedPlanFailure] = useState(false);
    /** クーポンチケット行使確認ダイアログ. */
    const [couponTicketConfirm, setCouponTicketConfirm] = useState({
        title: "",
        content: "",
        status: false
    });
    /** 特典未取得エラー. */
    const [unacquiredGiftFailure, setUnacquiredGiftFailure] = useState(false);
    /** ムビチケAPIエラーダイアログ. */
    const [movieTicketFailure, setMovieTicketFailure] = useState({
        status: false,
        title: "",
        content: ""
    });

    /** 会員ランク. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 汎用設定（app_config_web）. */
    const appConfigWeb = useSelector(state => state.Member.appConfigWeb);
    /** 静的コンテンツURL. */
    const textHtml = useSelector(state => state.ContentsId.textHtml);
    /** コンテンツ情報. */
    const content = useSelector(state => state.ContentsId.content);
    /** クーポンコード. */
    const defaultCoupon = useSelector(state => state.Coupon.couponCode);
    /** クーポン利用状況チェックレスポンス. */
    const couponCheck = useSelector(state => state.ContentsId.couponCheck);

    /**
     * 必要最低限のプラン.
     */
    const minimumPlan = useMemo(() => {
        return plan.minimumPlan(content[contentsKey.PLAN_LIST], content[contentsKey.IS_FREE]);
    }, [content]);

    /**
     * 会員ラベル.
     */
    const memberPlanLabel = (targetPlan) => {
        if (!plan.isSatisfiedPlan(memberPlan, targetPlan)) {
            switch(targetPlan) {
                case plan.PREMIUM:
                    return (<div className="c-rankLabel c-rankLabel--premium c-contents__label"></div>);
                case plan.STANDARD:
                    return (<div className="c-rankLabel c-rankLabel--standard c-contents__label"></div>);
                default:
                    return null;
            }
        } else {
            return null;
        }
    };

    /**
     * 会員ラベルNode.
     */
    const memberPlanLabelNode = (targetPlan) => {
        const label = document.createElement("div");

        switch(targetPlan) {
            case plan.PREMIUM:
                label.setAttribute("class", "c-rankLabel c-rankLabel--premium c-contents__label");
                break;
            case plan.STANDARD:
                label.setAttribute("class", "c-rankLabel c-rankLabel--standard c-contents__label");
                break;
            default:
        }
        return label;
    };

    // アプリはプランLP、Webはプラン選択へ遷移.
    const openPlanSelect = (isPremiumContents) => {
        if (app.isAllApp()) {
            historys.historyAppPlan(history);
        }
        else {
            dispatch(planActions.openPopupPlanSelect(false, isPremiumContents));
        }
    };

    /**
     * アップグレードへの遷移処理.
     */
    const upgrade = useCallback((targetPlan) => {
        if (plan.isNone(memberPlan)) {
            // ログイン画面へ遷移.
            apiUtil.tsuburayaLogin(dispatch, location, history);
        }
        else if (!plan.isSatisfiedPlan(memberPlan, targetPlan)) {
            // アップグレードのため、会員ランク選択画面へ遷移.
            openPlanSelect(plan.isPremium(targetPlan));
        }
        else if ([constantsCoupon.ONLINE_COUPON_LP, constantsCoupon.MORE_GIFT_LP, constantsCoupon.GIFT_IMAGE_LP, constantsCoupon.GIFT_LOTTERY_LP].includes(couponCheck[constantsCoupon.PAGE_TYPE])) {
            // 特典未取得のため、エラーダイアログ表示.
            setUnacquiredGiftFailure(true);
        }
    }, [memberPlan, dispatch, location, couponCheck]);

    /**
     * 外部サイトへの遷移処理.
     */
    const goOutside = (props) => {
        switch(props.type) {
            case constantsStatic.EC_LINK:
                /**
                 * 会員ランクチェックとECサイトへ遷移.
                 */
                if (plan.isNone(memberPlan)) {
                    // ログイン画面へ遷移.
                    apiUtil.tsuburayaLogin(dispatch, location, history);
                } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    // 外部サイトへ遷移.
                    // TODO: クエリ対応.
                    window.open(props.url);
                } else {
                    // アップグレードのため、会員ランク選択画面へ遷移.
                    openPlanSelect(plan.isPremium(minimumPlan));
                }
                break;

            case constantsStatic.TICKET_LINK:
                /**
                 * 会員ランクチェックとローチケサイトへ遷移.
                 */
                if (plan.isNone(memberPlan)) {
                    // ログイン画面へ遷移.
                    apiUtil.tsuburayaLogin(dispatch, location, history);
                } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    // ローチケへの遷移.
                    dispatch(loading(true));
                    eventif.postEncryption({ [eventif.AGENCY]: eventif.L_TIKE, [eventif.CID]: content[contentsKey.CRID]})
                    .then((encryptionData) => {
                        if (app.isAllApp()) {
                            let searchParams = "";
                            for (const[edKey, edValue] of Object.entries(encryptionData)) {
                                searchParams += edKey + "=" + encodeURIComponent(edValue) + "&";
                            }
                            searchParams += constantsStatic.TICKET_ACTION + "=" + encodeURIComponent(props.url) + "&";
                            searchParams += eventif.STID + "=" + encodeURIComponent(props.id) + "&";
                            historys.historyAppTicket(searchParams);
                        }
                        else {
                            eventif.postEncryptionSuccess(props, encryptionData);
                        }
                    })
                    .catch((err) => {
                        // セッションエラーを確認.
                        eventif.isSessionError(dispatch, err);
                        dispatch(updateConectionError(true));
                    })
                    .finally(() => dispatch(loading(false)));
                } else {
                    // アップグレードのため、会員ランク選択画面へ遷移.
                    openPlanSelect(plan.isPremium(minimumPlan));
                }
                break;

            case constantsStatic.EPLUS_LINK:
            case constantsStatic.CN_PLAYGUIDE_LINK:
                /**
                 * 会員ランクチェックとe+/CNプレイガイドのサイトへ遷移.
                 */
                if (plan.isNone(memberPlan)) {
                    // ログイン画面へ遷移.
                    apiUtil.tsuburayaLogin(dispatch, location, history);
                } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    // e+、CNプレイガイドへの遷移.
                    dispatch(loading(true));
                    let agency = (props.type === constantsStatic.EPLUS_LINK) ? eventif.EPLUS : eventif.CN_PLAYGUIDE;
                    eventif.postEncryption({ [eventif.AGENCY]: agency, [eventif.CID]: content[contentsKey.CRID]})
                    .then((encryptionData) => {
                        if (app.isAllApp()) {
                            let searchParams = "";
                            for (const[edKey, edValue] of Object.entries(encryptionData)) {
                                searchParams += edKey + "=" + encodeURIComponent(edValue) + "&";
                            }
                            searchParams += constantsStatic.TICKET_ACTION + "=" + encodeURIComponent(props.url) + "&";
                            searchParams += eventif.KEYWORD + "=" + encodeURIComponent(props.id) + "&";
                            historys.historyAppTicket(searchParams);
                        }
                        else {
                            eventif.postEncryptionSuccess(props, encryptionData);
                        }
                    })
                    .catch((err) => {
                        // セッションエラーを確認.
                        eventif.isSessionError(dispatch, err);
                        dispatch(updateConectionError(true));
                    })
                    .finally(() => dispatch(loading(false)));
                } else {
                    // アップグレードのため、会員ランク選択画面へ遷移.
                    openPlanSelect(plan.isPremium(minimumPlan));
                }
                break;

            case constantsStatic.DOWNLOAD_LINK:
                /**
                 * 会員ランクチェックとダウンロード処理.
                 */
                if (plan.isNone(memberPlan)) {
                    // ログイン画面へ遷移.
                    apiUtil.tsuburayaLogin(dispatch, location, history);
                } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    // ダウンロードリンクを作成し、ダウンロードさせる.
                    const a = document.createElement("a");
                    document.body.appendChild(a);
                    // ダウンロードされるファイル名は、data-filenameを設定.
                    a.download = props.filename;
                    a.href = FILE_TEMPLATE + content[contentsKey.CID] + "/" + props.filename;
                    a.click();
                    a.remove();
                } else {
                    // アップグレードのため、会員ランク選択画面へ遷移.
                    openPlanSelect(plan.isPremium(minimumPlan));
                }
                break;

            default:
                /**
                 * 会員ランクチェックと外部サイトへ遷移.
                 */
                if (plan.isNone(memberPlan)) {
                    // ログイン画面へ遷移.
                    apiUtil.tsuburayaLogin(dispatch, location, history);
                } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    // 外部サイトへ遷移.
                    window.open(props.url);
                } else {
                    // アップグレードのため、会員ランク選択画面へ遷移.
                    openPlanSelect(plan.isPremium(minimumPlan));
                }
        }
    };

    /**
     * コミック.
     */
    const Comic = (props) => {
        /**
        * コミック表示.
        */
        const viewer = () => {
            // 画像ベースURLを設定.
            const baseUrl = FILE_TEMPLATE + content[contentsKey.CID] + "/" + constantsStatic.COMIC_PATH;
            if (props.page === "1") {
                return (
                    <div className="c-contents__txt c-contents__cover">
                        <div style={{textAlign: "center"}}>
                            <img src={baseUrl + "/001" + constantsStatic.COMIC_EXTENSION} alt="" />
                        </div>
                        <div className="c-contents__coverBtn">
                            <a className="c-btn c-btn--thinRed" onClick={() => upgrade(minimumPlan)}>続きを読む</a>
                            {memberPlanLabel(minimumPlan)}
                        </div>
                    </div>
                );
            } else {
                let imageList = [];
                for (let i = 1; i <= Number(props.page); i++) {
                    imageList.push(baseUrl + "/" + ("000" + i).slice(-3) + constantsStatic.COMIC_EXTENSION);
                }
                const viewerType = props.type === CONTENTS_TYPE_AMECOMI ? CONTENTS_TYPE_AMECOMI : CONTENTS_TYPE_COMIC;
                return <Viewer input={{ type: viewerType, images: imageList }} />
            }
        };
        return (
            <>
                {viewer()}
            </>
        );
    };

    const Thumbnail = (props) => {
        /** 画面幅閾値 */
        const matches = useMediaQuery("(min-width: 768px)");

        return (
            <img src={matches ? props.content[contentsKey.THUMBNAIL_L] : props.content[contentsKey.THUMBNAIL_P]} alt="" />
        );
    };

    /**
     * 遷移先設定.
     */
    const contentLinkClick = useCallback((nextContent, coupon) => {
        // シリーズはライブラリ一覧に遷移.
        if (nextContent[contentsKey.LIST_FLG]) {
            let list = [];
            list.push(libraryId.SERIES_META);
            list.push(nextContent[contentsKey.CRID]);
            historys.historylibraryId(history, historys.getEncodeId(list));
        }

        // 映像詳細画面に遷移.
        else if (!nextContent[contentsKey.LIST_FLG] && (nextContent[contentsKey.CONTENT_TYPE] === contentType.VIDEO))
            historys.historyVideoId(history, nextContent[contentsKey.CRID]);

        // ライブ画面に遷移.
        else if (!nextContent[contentsKey.LIST_FLG] && nextContent[contentsKey.CONTENT_TYPE] === contentType.LIVE)
            historys.historyLiveId(history, nextContent[contentsKey.CRID]);

        // 静的コンテンツページに遷移.
        else if (!nextContent[contentsKey.LIST_FLG] && nextContent[contentsKey.CONTENT_TYPE] === contentType.WEB){
            // クーポンコードがパーツにセットされている場合は、クエリにクーポンを付与した遷移先とする.
            if (coupon) {
                historys.historyContentsWithQuery(history, nextContent[contentsKey.CRID], "?coupon=" + coupon);
            } else {
                historys.historyContents(history, nextContent[contentsKey.CRID]);
            }
        }

        // 上記以外の場合は404に遷移.
        else
            historys.historyNotFound(history);
    }, [history]);

    /**
     * 内部リンクコンテンツ.
     */
    const setContentsLink = useCallback((contentLinks) => {
        let contentIdList = [];
        for (const c of contentLinks) {
            contentIdList.push(c.dataset.id);
        }
        contentif.postIdSearch({
            [contentif.CONTENT_ID_LIST]: contentIdList,
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        })
        .then((response) => {
            for (const contentLink of contentLinks) {
                let targetContent = null;
                // レスポンスの中からパーツに一致するメタを検索.
                for (const res of response) {
                    if (res[contentsKey.CRID] === contentLink.dataset.id) {
                        targetContent = res;
                        break;
                    }
                }
                contentLink.style.cursor = "pointer";

                // メタに存在する場合.
                if (targetContent) {
                    // 遷移先の設定.
                    contentLink.onclick = () => contentLinkClick(targetContent, contentLink.dataset.coupon)

                    // サムネイルフラグがオンの場合は、サムネイル画像を設定.
                    if (contentLink.getAttribute(constantsStatic.THUMBNAIL) === constantsStatic.THUMBNAIL_ON) {
                        ReactDOM.render(<Thumbnail content={targetContent} />, contentLink);
                    }
                }
                else {
                    contentLink.onclick = () => historys.historyPushNotFound(history);
                }
            }
        }, () => {
            // 通信エラーダイアログ表示.
            dispatch(updateConectionError(true));
        })
    }, [dispatch, history, contentLinkClick]);

    /**
     * チェックボックス変更時の処理.
     */
    const handleCheckboxChange = (e) => {
        const parent = e.target.parentNode;
        const cbs = parent.getElementsByClassName(constantsStatic.ANSWER);
        const maxCount = parent.dataset.count;

        // チェック数を計上.
        const checkedCount = Array.from(cbs).reduce((acc, cb, _) => {
            if (cb.checked) acc++;
            return acc;
        }, 0);

        // チェック数がチェック可能最大数に達している場合、非チェック項目を非活性化.
        if (maxCount <= checkedCount) {
            for (const cb of cbs) {
                if (!cb.checked) {
                    cb.disabled = true;
                    cb.nextElementSibling.classList.add("checkbox-lock")
                }
            }
        }
        // チェック数がチェック可能最大数に満たない場合、非チェック項目を活性化.
        else {
            for (const cb of cbs) {
                if (!cb.checked) {
                    cb.disabled = false;
                    cb.nextElementSibling.classList.remove("checkbox-lock")
                }
            }
        }
    };

    /**
     * 入力したクーポンコードを保存してログイン.
     */
    const couponLogin = (couponValue) => {
        // LocalStorageのクーポンコードを初期化.
        dispatch(couponActions.updateCouponCode(""));
        dispatch(savePrevPage(location.pathname + "?coupon=" + couponValue));
        if (app.isAllApp()) {
            authif.postLogin({[authif.TYPE]: authif.APP_LOGIN, [authif.DESTINATION]: authif.TSUBURAYA});
        }
        else {
            authif.postLogin({[authif.TYPE]: authif.WEB_LOGIN, [authif.DESTINATION]: authif.TSUBURAYA});
        }
    }

    /**
     * 通常クーポン利用.
     */
    const useCoupon = (e) => {
        const couponInput = document.getElementsByClassName(constantsStatic.COUPON_CODE);
        // クーポン入力欄が空のとき、エラーダイアログ表示.
        if (couponInput[0].value === "") {
            dispatch(commonActions.couponUsedFailure(true, error.COUPON_USED_FAILURE_REQUIRED_TITLE, error.COUPON_USED_FAILURE_REQUIRED_CONTENT));
            return;
        }

        if (plan.isNone(memberPlan)) {
            // ログイン画面へ遷移.
            couponLogin(couponInput[0].value);
        } else if (plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
            dispatch(loading(true));

            const responseBody = {
                [eventif.COUPON]: couponInput[0].value,
                [eventif.CAMPAIGN_ID]: content[contentsKey.CAMPAIGN_ID],
                [eventif.GIFT_SOURCE_CRID]: content[contentsKey.GIFT_SOURCE_CRID]
            };

            // クーポン管理API呼び出し.
            eventif.postCoupon(responseBody).then((res) => {
                if (campaign.isCompleted(res)) {
                    // クーポンコードを初期化.
                    dispatch(couponActions.updateCouponCode(""));
                    // クーポン行使画面を表示.
                    dispatch(commonActions.couponUsed(true, res));
                }
                else {
                    // クーポン行使エラーを表示.
                    const title = error.COUPON_USED_FAILURE_TITLE;
                    const content = error.COUPON_USED_FAILURE_CONTENT;
                    dispatch(commonActions.couponUsedFailure(true, title, content));
                }
            }, (err) => {
                // セッションエラーを確認.
                eventif.isSessionError(dispatch, err);
                // エラー処理.
                eventif.postCouponFailure(dispatch, location, history, err);
            })
            .finally(() => dispatch(loading(false)));
        } else {
            // アップグレードのため、プラン未充足エラー表示後、会員ランク選択画面へ遷移.
            setIsCouponUsedPlanFailure(true);
        }
    };

    /**
     * 無料キャンペーンクーポン利用.
     */
    const useFreeCampaignCoupon = (e) => {
        // プラン選択画面にクーポンコードを引き渡すため、Reducerに保存.
        const couponInput = document.getElementsByClassName(constantsStatic.COUPON_CODE);

        if (couponInput[0].value === "") {
            dispatch(commonActions.couponUsedFailure(true, error.COUPON_USED_FAILURE_REQUIRED_TITLE, error.COUPON_USED_FAILURE_REQUIRED_CONTENT));
            return;
        }

        if (plan.isNone(memberPlan)) {
            // ログイン画面へ遷移.
            couponLogin(couponInput[0].value);
        } else {
            // アップグレードのため、会員ランク選択画面へ遷移.
            if (app.isAllApp()) {
                historys.historyAppPlan(history);
            }
            else {
                dispatch(planActions.openPopupPlanSelectCoupon(false, plan.isPremium(minimumPlan), couponInput[0].value));
            }
        }
    };

    /**
     * クーポン プラン未充足エラー時の処理.
     */
    const handleCouponUsedPlanFailure = () => {
        openPlanSelect(plan.isPremium(minimumPlan));
        setIsCouponUsedPlanFailure(false);
    }

    /**
     * 非表示要素判定.
     */
    const isNotDisplay = useCallback((type) => {
        if (type === constantsStatic.APP_ANDROID) {
            return app.isAndroidApp();
        }
        else if (type === constantsStatic.APP_IOS) {
            return app.isIosApp();
        }
        else if (type === constantsStatic.APP_ALL) {
            return app.isAllApp();
        }
        else if (type === constantsStatic.WEB) {
            return !app.isAllApp();
        }
        else {
            return false;
        }
    }, []);

    /**
     * 図鑑への遷移.
     */
    const goCubeGroup = (id) => {
        dispatch(screenActions.changeIsSpecialCubeGroupList(true));
        dispatch(screenActions.changeIsSpecialCubeGroup(true, id, false));
    };

    /**
     * プラン選択画面を開く. またはプラン情報が無ければログイン画面を開く．
     */
    const openPlanSelectPopup = () => {
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
        } else {
            openPlanSelect(plan.isPremium(minimumPlan));
        }
    }

    /**
     * クーポンチケット未行使ボタンにonclick処理を付与.
     */
    const addOnclickForCouponTicket = (notUsedCoupon) => {
        // パーツが存在し、クーポン未行使の場合、ボタン押下時の処理を付与.
        if (notUsedCoupon.length > 0 && !couponCheck[constantsCoupon.USED]) {
            for (const nuc of notUsedCoupon) {
                nuc.onclick = () => {
                    dispatch(loading(true));
                    // クーポン利用状況チェックを行う.
                    const couponCheckRequest = {
                        [eventif.CAMPAIGN_ID]: content[contentsKey.CAMPAIGN_ID],
                        [eventif.CONTENT_TYPE]: campaign.WEB,
                        [eventif.CRID]: content[contentsKey.CRID],
                        [eventif.GIFT_SOURCE_CRID]: content[contentsKey.GIFT_SOURCE_CRID]
                    };
                    eventif.postCouponCheck(couponCheckRequest)
                    .then((cres) => {
                        dispatch(actions.setCouponCheck(cres));

                        // 会場特典の場合は、クーポンチケット行使確認ダイアログを表示.
                        if (!cres[constantsCoupon.HAVE] || cres[constantsCoupon.USED]) {
                            dispatch(loading(false));
                            // 未所有 or 行使済みの場合エラーを表示.
                            dispatch(commonActions.moreGiftCouponFailure(true));
                        }
                        else if (cres[constantsCoupon.GIFT_TYPE] === constantsCoupon.ITEM) {
                            dispatch(loading(false));
                            setCouponTicketConfirm({
                                title: cres[constantsCoupon.MESSAGE_TITLE],
                                content: cres[constantsCoupon.MESSAGE_MAIN],
                                status: true
                            });
                        }
                        // それ以外はクーポン行使処理を行う.
                        else {
                            // クーポン管理API呼び出し.
                            eventif.postCoupon({
                                [eventif.CAMPAIGN_ID]: content[contentsKey.CAMPAIGN_ID],
                                [eventif.GIFT_SOURCE_CRID]: content[contentsKey.GIFT_SOURCE_CRID]
                            }).then((res) => {
                                if (campaign.isCompleted(res)) {
                                    // クーポン行使画面を表示.
                                    dispatch(commonActions.couponUsed(true, res));
                                }
                                else {
                                    // クーポン行使エラーを表示.
                                    const title = error.COUPON_USED_FAILURE_TITLE;
                                    const content = error.COUPON_USED_FAILURE_CONTENT;
                                    dispatch(commonActions.couponUsedFailure(true, title, content));
                                }
                            }, (err) => {
                                // セッションエラーを確認.
                                eventif.isSessionError(dispatch, err);
                                // エラー処理.
                                eventif.postCouponFailure(dispatch, location, history, err);
                            })
                            .finally(() => dispatch(loading(false)));
                        }
                    }, (err) => {
                        dispatch(loading(false));
                        // セッションエラーを確認.
                        eventif.isSessionError(dispatch, err);

                        dispatch(commonActions.moreGiftCouponFailure(true));
                    });
                };
                nuc.style.cursor = "pointer";
            }
        }
    };

    /**
     * 退会遷移ボタンにonclick処理を付与.
     */
    const addOnclickForLinkWithdrawal = (linkWithdrawal) => {
        if (linkWithdrawal.length > 0) {
            for (const lw of linkWithdrawal) {
                lw.onclick = () => dispatch(screenActions.changeIsWithdrawal(true));
                lw.style.cursor = "pointer";
            }
        }
    };

    /**
     * プラン選択画面表示ボタンにonclick処理を付与．
     */
    const addOnclickForLinkPlanSelect = (linkPlanSelect) => {
        if (linkPlanSelect.length > 0) {
            for (const lp of linkPlanSelect) {
                lp.onclick = () => openPlanSelectPopup();
                lp.style.cursor = "pointer";
            }
        }
    }

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() =>{
        // 非表示要素.
        const notDisplay = document.getElementsByClassName(constantsStatic.NOT_DISPLAY);
        Array.from(notDisplay).forEach((nd) => {
            if (isNotDisplay(nd.dataset.type)) {
                nd.remove();
            }
        });

        // 内部リンク.
        const contentLinks = document.getElementsByClassName(constantsStatic.CONTENTS_LINK);
        if (contentLinks.length > 0) setContentsLink(contentLinks)

        // 外部リンク表示.
        const outsides = document.getElementsByClassName(constantsStatic.CONTRACT_RESTRICT_LINK);
        for (const outside of outsides) {
            outside.onclick = () => goOutside(outside.dataset);
            outside.style.cursor = "pointer";
        }

        // コミック表示.
        const comics = document.getElementsByClassName(constantsStatic.COMIC);
        for (const comicParent of comics) {
            ReactDOM.render(<Comic { ...comicParent.dataset} />, comicParent);
        }

        // ムビチケ表示.
        const movieTickets = document.getElementsByClassName(constantsStatic.CONTRACT_RESTRICT_MT);
        for (const movieTicket of movieTickets) {
            ReactDOM.render(<MovieTicket dispatch={dispatch} location={location} history={history} memberPlan={memberPlan} content={content} minimumPlan={minimumPlan} setMovieTicketFailure={setMovieTicketFailure}/>, movieTicket);
        }

        // 図鑑
        const cubeGroups = document.getElementsByClassName(constantsStatic.CONTENTS_LINK_CUBE_GROUP);
        for (const cubeGroup of cubeGroups) {
            cubeGroup.onclick = () => goCubeGroup(cubeGroup.dataset.id);
            cubeGroup.style.cursor = "pointer";
        }

        // クーポン.
        const couponInput = document.getElementsByClassName(constantsStatic.COUPON_CODE);

        // クーポンパーツがある場合、コードデフォルト入力やonclick処理の追加を行う.
        if (couponInput.length > 0) {
            // couponInput[0].valueにプリセットされていない場合のみ、更新
            if (couponInput[0].value === '') {
                couponInput[0].value = defaultCoupon;
            }

            const couponButton = document.getElementsByClassName(constantsStatic.COUPON_USE);
            // 通常のクーポン.
            if (couponButton.length > 0) {
                couponButton[0].onclick = useCoupon;

                const memberPlanLabelDom = document.getElementsByClassName("c-rankLabel");
                if(memberPlanLabelDom.length === 0) {
                    // 会員ラベルの追加.
                    if (!plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                        couponButton[0].parentNode.insertBefore(memberPlanLabelNode(minimumPlan), couponButton[0].nextSibling);
                    }
                }
            }
            // 無料キャンペーンのクーポン.
            else {
                const couponFreeButton = document.getElementsByClassName(constantsStatic.COUPON_USE_FREE_PLAN);
                if (couponFreeButton.length > 0) {
                    couponFreeButton[0].onclick = useFreeCampaignCoupon;
                }
            }
        }

        // クーポンチケット.
        const notUsedCoupon = document.getElementsByClassName(constantsStatic.NOT_USED_COUPON);
        addOnclickForCouponTicket(notUsedCoupon);

        // 退会遷移.
        const linkWithdrawal = document.getElementsByClassName(constantsStatic.LINK_WITHDRAWAL);
        addOnclickForLinkWithdrawal(linkWithdrawal);

        // プラン選択画面表示
        const linkPlanSelect = document.getElementsByClassName(constantsStatic.LINK_PLAN_SELECT);
        addOnclickForLinkPlanSelect(linkPlanSelect);

        // アンケート.
        const questions = document.getElementsByClassName(constantsStatic.QUESTION);

        // アンケート項目がない場合.
        if (questions.length === 0) {
            const falseDom = document.getElementsByClassName(constantsStatic.FALSE_FLAG);
            const nextButtonDom = document.getElementsByClassName(constantsStatic.NEXT_BUTTON_DOM);
            // 未充足テキストが存在し、コミックdomとクーポン入力フォームとムビチケがない場合、「続きを読む」ボタンとラベル表示.
            if (falseDom.length > 0 && comics.length === 0 && couponInput.length === 0 && movieTickets.length === 0 && nextButtonDom.length === 0) {
                // class="false" にcssを追加.
                falseDom[0].classList.add("c-contents__txt", "c-contents__cover", constantsStatic.NEXT_BUTTON_DOM);

                // ボタントラベルの親要素を作成.
                const buttonGroup = document.createElement("div");
                buttonGroup.setAttribute("class", "c-contents__coverBtn");

                // 「続きを読む」ラベルを作成.
                const nextButton = document.createElement("a");
                nextButton.setAttribute("class", "c-btn c-btn--thinRed");
                nextButton.innerText = "続きを読む";
                nextButton.onclick = () => upgrade(minimumPlan);

                // それぞれの子要素に追加.
                buttonGroup.appendChild(nextButton);
                // プラン未充足の場合、会員ラベルも追加.
                if (!plan.isSatisfiedPlan(memberPlan, minimumPlan)) {
                    buttonGroup.appendChild(memberPlanLabelNode(minimumPlan));
                }
                falseDom[0].appendChild(buttonGroup);
            }
        }
        // アンケート項目がある場合.
        else {
            const sendButtonDom = document.getElementsByClassName(constantsStatic.SEND_BUTTON_DOM);
            if (sendButtonDom.length === 0) {
                // チェックボックス項目DOMに処理追加.
                for (const question of questions) {
                    if (question.dataset.count) {
                        const checkboxes = question.getElementsByClassName(constantsStatic.ANSWER);
                        for (const checkbox of checkboxes) {
                            checkbox.onchange = handleCheckboxChange;
                        }
                    }
                }

                // 送信ボタン作成.
                const buttonContainer = document.createElement("div");
                buttonContainer.setAttribute("class", "c-contentsApply");
                buttonContainer.classList.add(constantsStatic.SEND_BUTTON_DOM);
                const button = document.createElement("div");
                button.setAttribute("class", "c-btn c-btn--apply");
                button.onclick = submitQuestions;
                button.innerText = "送信する";
                buttonContainer.appendChild(button);

                // 有効期間パーツが存在する場合は、有効期間内パーツの中に送信ボタンを追加.
                const during = document.getElementsByClassName(constantsStatic.DURING);
                if (during.length > 0) {
                    during[0].appendChild(buttonContainer);
                }
                else {
                    document.getElementsByClassName(constantsStatic.CONTRACT_RESTRICT)[0].children[0].appendChild(buttonContainer);
                }
            }
        }
    }, [history, textHtml, upgrade, setContentsLink]);

    /**
     * クーポン利用状況チェックが更新されたときにパーツの出し分けを行う.
     */
    useEffect(() => {
        const notUsedCoupon = document.getElementsByClassName(constantsStatic.NOT_USED_COUPON);
        const usedCoupon = document.getElementsByClassName(constantsStatic.USED_COUPON);

        // パーツが存在する場合、クーポン利用状況チェックAPIレスポンス結果から不要なDOMを非表示.
        if (notUsedCoupon.length > 0 || usedCoupon.length > 0) {
            // クーポン行使済みであれば、クーポンチケット未行使DOMを非表示.
            if (couponCheck[constantsCoupon.USED]) {
                for (const nuc of notUsedCoupon) {
                    nuc.style.display = "none";
                }
                for (const uc of usedCoupon) {
                    uc.style.display = "block";
                }
            }
            // それ以外はクーポンチケット行使済みDOMを非表示.
            else {
                for (const uc of usedCoupon) {
                    uc.style.display = "none";
                }
                for (const nuc of notUsedCoupon) {
                    nuc.style.display = "block";
                }
            }
        }

        // 有効期間判定.
        const expiredPeriod = document.getElementsByClassName(constantsStatic.EXPIRED_PERIOD);
        if (expiredPeriod.length > 0) {
            for (const ep of expiredPeriod) {
                const before = ep.getElementsByClassName(constantsStatic.BEFORE);
                const during = ep.getElementsByClassName(constantsStatic.DURING);
                const after = ep.getElementsByClassName(constantsStatic.AFTER);

                const period = couponCheck[constantsCoupon.PAGE_TYPE] === constantsCoupon.MORE_GIFT_LP
                    ? couponCheck[constantsCoupon.GIFT_VALIDITY_PERIOD]
                    : couponCheck[constantsCoupon.VALIDITY_PERIOD];

                switch (period) {
                    case constantsCoupon.PERIOD_NOT_YET: // 有効期間前のパーツのみ表示.
                        if (before.length > 0) before[0].style.display = "block";
                        if (during.length > 0) during[0].style.display = "none";
                        if (after.length > 0) after[0].style.display = "none";
                        break;
                    case constantsCoupon.PERIOD_VALID: // 有効期間中のパーツのみ表示.
                        if (before.length > 0) before[0].style.display = "none";
                        if (during.length > 0) during[0].style.display = "block";
                        if (after.length > 0) after[0].style.display = "none";
                        break;
                    case constantsCoupon.PERIOD_OVER: // 有効期間後のパーツのみ表示.
                        if (before.length > 0) before[0].style.display = "none";
                        if (during.length > 0) during[0].style.display = "none";
                        if (after.length > 0) after[0].style.display = "block";
                        break;
                    default:
                }
            }
        }
    }, [couponCheck, textHtml]);

    /**
     * アンケート送信処理.
     */
    const submitQuestions = () => {
        dispatch(loading(true));
        const questions = document.getElementsByClassName(constantsStatic.QUESTION);

        // 必須入力エラーの文言リスト.
        let requiredErrorList = ["必須項目が未記入となっています。入力をお願いします。"];

        const requestBody = Array.from(questions).map((question) => {
            const answer = question.getElementsByClassName(constantsStatic.ANSWER);
            const required = question.dataset.required;
            if (answer.length === 0) return null;

            if (answer[0].tagName === "INPUT" && answer[0].type === "text") { // フリーフォーム.
                if (required && answer[0].value === "") requiredErrorList.push(question.dataset.requiredError);

                return { [answer[0].name]: answer[0].value };
            } else if (answer[0].tagName === "INPUT" && answer[0].type === "radio") { // ラジオボタン.
                for (const ans of answer) {
                    if (ans.checked) {
                        return { [ans.name]: ans.value };
                    }
                }
                if (required) requiredErrorList.push(question.dataset.requiredError);

                return { [answer[0].name]: "" };
            } else if (answer[0].tagName === "SELECT") { // セレクトボックス.
                const index = answer[0].selectedIndex;
                if (required && answer[0].options[index].value === "") requiredErrorList.push(question.dataset.requiredError);

                return { [answer[0].name]: answer[0].options[index].value }
            } else if (answer[0].tagName === "TEXTAREA") { // 複数行フリーフォーム.
                if (required && answer[0].value === "") requiredErrorList.push(question.dataset.requiredError);

                return { [answer[0].name]: answer[0].value };
            } else if (answer[0].tagName === "INPUT" && answer[0].type === "checkbox") { // チェックボックス.
                let result = "";
                for (const ans of answer) {
                    if (ans.checked) {
                        if (result !== "") result += ",";
                        result += ans.value;
                    }
                }
                if (required && result === "") requiredErrorList.push(question.dataset.requiredError);

                return { [answer[0].name]: result };
            } else {
                return null;
            }
        }).filter(qu => qu !== null);

        // 必須項目で未入力がある場合、エラーとする.
        if (requiredErrorList.length > 1) {
            setRequiredFailure({open: true, contentList: requiredErrorList});
            dispatch(loading(false));
            return;
        }

        // アンケート送信API呼び出し.
        const request = {
            [accountif.CRID]: content[contentsKey.CRID],
            [accountif.DATA]: requestBody
        };
        accountif.postForm(request).then(
            (res) => {
                dispatch(loading(false));
                if (res[accountif.DIALOG_TITLE] !== "") {
                    setQuestSuccess({
                        open: true,
                        title: res[accountif.DIALOG_TITLE],
                        content: res[accountif.DIALOG_MESSAGE],
                        ok: res[accountif.DIALOG_BUTTON]
                    });
                } else {
                    setQuestSuccess({open: true, title: questSuccess.title, content: questSuccess.content, ok: questSuccess.ok});
                }
            },
            (err) => {
                dispatch(loading(false));

                // セッションエラーを確認.
                accountif.isSessionError(dispatch, err[0]);

                let title = "";
                let content = "";
                let ok = "OK";
                switch (err[0][apiUtil.ERROR_CODE]) {
                    // 重複送信エラー.
                    case accountif.WAR01005:
                        // 退会アンケートコンテンツの場合は別文言.
                        if (isWithdrawalReasonContents(location, appConfigWeb)) {
                            title = error.DUPLICATE_POSTS_WITHDRAWAL_TITLE;
                            content = error.DUPLICATE_POSTS_WITHDRAWAL_CONTENT;
                        } else {
                            title = error.DUPLICATE_POSTS_TITLE;
                            content = error.DUPLICATE_POSTS_CONTENT;
                        }
                        break;
                    // 入力失敗（通常フォーム）.
                    case accountif.ERR01100:
                        title = error.SEND_QUESTIONNAIRE_INPUT_FAILURE_TITLE;
                        content = error.SEND_QUESTIONNAIRE_INPUT_FAILURE_CONTENT;
                        break;
                    // 入力完了したが付与失敗（クーポン付与パターンのみ）.
                    case accountif.ERR01200:
                        if (err[1][accountif.DIALOG_TITLE] === undefined || err[1][accountif.DIALOG_TITLE] === "") {
                            title = error.SEND_QUESTIONNAIRE_FAILURE_TITLE;
                            content = error.SEND_QUESTIONNAIRE_FAILURE_CONTENT;
                        } else {
                            title = err[1][accountif.DIALOG_TITLE];
                            content = err[1][accountif.DIALOG_MESSAGE];
                            ok = err[1][accountif.DIALOG_BUTTON];
                        }
                        break;
                    default:
                        title = error.SEND_QUESTIONNAIRE_FAILURE_TITLE;
                        content = error.SEND_QUESTIONNAIRE_FAILURE_CONTENT;
                        break;
                }

                // エラーダイアログ表示.
                setQuestFailure({open: true, title: title, content: content, ok: ok});
            }
        );
    };

    /**
     * アンケート送信完了時の処理.
     */
    const acceptQuestions = () => {
        setQuestSuccess(questSuccessInitValue);
        // キャンペーン一覧を再取得.
        accountif.getCampaign().then(
            accountif.getCampaignSuccess.bind(this, dispatch),
            accountif.isSessionError.bind(this, dispatch)
        );
    };

    return (
        <>
            {/* アンケート送信成功. */}
            {questSuccess.open && <DefaultOne input={{title: questSuccess.title, content: questSuccess.content, ok: questSuccess.ok}} onAccept={() => acceptQuestions()} />}
            {/* アンケート送信失敗. */}
            {questFailure.open && <Warning input={{title: questFailure.title, content: questFailure.content, ok: questFailure.ok}} onAccept={() => setQuestFailure(questFailureInitValue)} />}
            {/* 必須項目未入力. */}
            {requiredFailure.open && <WarningContentList input={{title: "未記入の項目があります。", contentList: requiredFailure.contentList}} onAccept={() => setRequiredFailure({open: false, contentList: []})} />}
            {/* クーポン プラン未充足エラー. */}
            {isCouponUsedPlanFailure && <WarningFrontTwo input={{title: error.COUPON_USED_FAILURE_PLAN_TITLE, content: error.COUPON_USED_FAILURE_PLAN_CONTENT}} onAccept={() => handleCouponUsedPlanFailure()} onReject={() => setIsCouponUsedPlanFailure(false)}/>}
            {/* クーポン行使確認. */}
            {couponTicketConfirm.status && <CouponTicket input={{title: couponTicketConfirm.title, content: couponTicketConfirm.content}} closePopup={() => setCouponTicketConfirm({title: "", content: "", status: false})} />}
            {/* 特典未取得エラー. */}
            {unacquiredGiftFailure && <Warning input={{title: error.UNACQUIRED_GIFT_FAILURE_TITLE, content: error.UNACQUIRED_GIFT_FAILURE_CONTENT}} onAccept={() => setUnacquiredGiftFailure(false)} />}
            {/* ムビチケAPIエラー. */}
            {movieTicketFailure.status && <Warning input={{title: movieTicketFailure.title, content: movieTicketFailure.content}} onAccept={() => setMovieTicketFailure({status: false, title: "", content: ""})} />}
            <div dangerouslySetInnerHTML={{ __html: textHtml }} />
        </>
    )
};

export default Static;