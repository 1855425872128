import * as apiUtil from "../constants/apiUtil";
import { PAYMENT_DOMAIN } from "../constants/domain";
import { doLogout } from "../components/dialog/Logout";
const parseString = require("xml2js").parseString;

/** Command:登録カード情報取得. */
export const GET_CARD_USR_INFO = "GetCardUsrInfo";

/** Command:クレジットカード有効性チェック. */
export const AUTH_CARD = "AuthCard";

/** Command:クレジットカード削除. */
export const DELETE_CARD_INFO = "DeleteCardInfo";

/** クレカ連携APIで使用. */
export const SHOP_CODE = "00012723";

/** クレカ連携APIで使用. */
export const USER_KBN = 3;

/** CSRFキー. */
export const CSRF = "csrf";

/** CSRFトークン. */
export const CSRF_TOKEN = "x-csrf-token";

/** セッションエラー. */
const SESSION_ERROR = "WAR04011";

const config = {
    transformResponse: [(data) => {
        let json;
        parseString(data, (err, result) => {json = result})
        return json;
    }]
}

/**
 * クレカ連携API.
 * API: POST
 */
export async function postPayment(data, isRegister = false) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(PAYMENT_DOMAIN, data, config)
        .then(res => {
            // クレジットカード登録のときはcsrfトークンをヘッダから取得.
            if (isRegister) {
                res.data[CSRF] = res.headers[CSRF_TOKEN];
            }
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * セッションエラーはログアウト.
 */
export const isSessionError = (dispatch, res) => {
    if (res.Output === undefined) return false;
    if (String(res.Output.Errorno[0]) === SESSION_ERROR) {
        doLogout(dispatch);
        return true;
    }
    return false;
}
