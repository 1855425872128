/** 左ページ遷移. */
export const TOUCH_ACTION_SEND_LEFT = "TOUCH_ACTION_SEND_LEFT";
/** 右ページ遷移. */
export const TOUCH_ACTION_SEND_RIGHT = "TOUCH_ACTION_SEND_RIGHT";
/** ピンチ操作. */
export const TOUCH_ACTION_PINTING = "TOUCH_ACTION_PINTING";
/** ピンチイン. */
export const TOUCH_ACTION_PINT_IN = "TOUCH_ACTION_PINT_IN";
/** ピンチアウト. */
export const TOUCH_ACTION_PINT_OUT = "TOUCH_ACTION_PINT_OUT";
/** ページ位置移動. */
export const TOUCH_ACTION_MOVE = "TOUCH_ACTION_MOVE";
