/** 会員情報更新. */
export const UPDATE_MEMBER_INFO = "UPDATE_MEMBER_INFO";

/** 通知一覧更新. */
export const UPDATE_NOTICE_LIST = "UPDATE_NOTICE_LIST";

/** お気に入りコンテンツ一覧更新. */
export const UPDATE_FAVORITE_LIST = "UPDATE_FAVORITE_LIST";

/** 視聴中一覧更新. */
export const UPDATE_RESUME_LIST = "UPDATE_RESUME_LIST";

/** 現在のプラン情報更新. */
export const UPDATE_MEMBER_PLAN = "UPDATE_MEMBER_PLAN";

/** 変更後のプラン情報更新. */
export const UPDATE_CHANGE_MEMBER_PLAN = "UPDATE_CHANGE_MEMBER_PLAN";

/** 購入一覧（ライセンスID）更新. */
export const UPDATE_PURCHASE_LIST = "UPDATE_PURCHASE_LIST";

/** キャンペーン一覧更新. */
export const UPDATE_CAMPAIGN_LIST = "UPDATE_CAMPAIGN_LIST";

/** スペシャル図鑑一覧更新. */
export const UPDATE_SPECIAL_CUBE_GROUP_LIST = "UPDATE_SPECIAL_CUBE_GROUP_LIST";

/** スペシャル図鑑更新. */
export const UPDATE_SPECIAL_CUBE_GROUP = "UPDATE_SPECIAL_CUBE_GROUP";

/** データキューブ一覧更新. */
export const UPDATE_DATA_CUBE_LIST = "UPDATE_DATA_CUBE_LIST";

/** データキューブ更新. */
export const UPDATE_DATA_CUBE = "UPDATE_DATA_CUBE";

/** ファイル：プラン料金更新. */
export const UPDATE_PLAN_PRICE = "UPDATE_PLAN_PRICE";

/** ファイル：汎用設定（app_config_web）更新. */
export const UPDATE_APP_CONFIG_WEB = "UPDATE_APP_CONFIG_WEB";

/** ファイル：プロフィール画像リスト更新. */
export const UPDATE_PROF_PICTURE_LIST = "UPDATE_PROF_PICTURE_LIST";

/** ファイル：プラネット定義リスト更新. */
export const UPDATE_PLANET_LIST = "UPDATE_PLANET_LIST";

/** ファイル：プラネット定義リスト更新. */
export const UPDATE_PLANET_GROUP_LIST = "UPDATE_PLANET_GROUP_LIST";

/** プロフィール画像更新. */
export const UPDATE_PROF_PICTURE = "UPDATE_PROF_PICTURE";
