import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles(theme => ({
    viewer: {
        "&": {
            display: "flex",
            "-ms-user-select": "none",
            "user-select": "none"

        },
        "&.fullScreen": {
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate3d(-50%, -50%, 0px)",
            backgroundColor: "#000000",
            zIndex: "999",
            "-ms-touch-action": "none",
            "touch-action": "none"
        }
    },
    viewerBase: {
        width: "100%",
        maxWidth: "604px",
        margin: "auto",
        backgroundColor: "#000000",
        position: "relative",
        textAlign: "center"
    },
    viewerHeader: {
        width: "70%",
        marginLeft: "15%",
        marginBottom: "10px"
    },
    contentsViewer: {
        maxHeight: "100%",
        position: "relative"
    },
    contentsImg: {
        width: "100%",
        height: "100%",
        overflow: "hidden",
        "&>img": {
            width: "auto",
            height: "auto",
            maxWidth: "100%",
            maxHeight: "100%"
        }
    },
    viewerMargin: {
        width: "100%",
        height: "62px",
        position: "relative"
    },
    touchController: {
        width: "100%",
        height: "100%"
    },
    viewerController: {
        position: "absolute",
        top: "0px",
        bottom: "0px",
        left: "0px",
        right: "0px",
        textAlign: "center",
        zIndex: "2",
        "-ms-touch-action": "pan-y",
        "touch-action": "pan-y",
        "&>.viewerSwitchScreen": {
            position: "absolute",
            top: "0px",
            right: "0px",
            opacity: "0",
            display: "none",
            transition: ".2s"
        },
        "&.active>.viewerSwitchScreen": {
            opacity: "1",
            display: "block"
        },
        "&>table": {
            width: "100%",
            height: "62px",
            position: "absolute",
            top: "100%",
            opacity: "0",
            display: "none",
            backgroundColor: "#000000",
            transition: ".2s",
            borderCollapse: "separate",
            borderSpacing: "2px",
            "&>tbody>tr>td": {
                verticalAlign: "middle",
            },
            "&>tbody>tr>td:nth-child(1)": {
                width: "70px"
            },
            "&>tbody>tr>td:nth-child(2)": {
                lineHeight: "1.4"
            },
            "&>tbody>tr>td:nth-child(3)": {
                width: "70px"
            }
        },
        "&.active>table": {
            opacity: "1",
            display: "table"
        }
    },
    viewerSlider: props => ({
        "&>.MuiSlider-root>.MuiSlider-thumb": {
            width: "30px",
            height: "30px",
            marginTop: "-13px",
            marginLeft: "-15px",
            color: "#ffffff"
        },
        "&>.MuiSlider-root>.MuiSlider-thumb>.MuiSlider-valueLabel": {
            top: "-15px",
            left: "0px",
            color: "rgba(0,0,0,0)",
            opacity: "0",
            display: "none"
        },
        "&.active >.MuiSlider-root>.MuiSlider-thumb>.MuiSlider-valueLabel": {
            opacity: "1",
            display: "block"
        },
        "&>.MuiSlider-root>.MuiSlider-rail": {
            height: "5px",
            color: props.rail,
            opacity: "1"
        },
        "&>.MuiSlider-root>.MuiSlider-track": {
            height: "5px",
            backgroundColor: props.track,
            opacity: "1"
        }
    })
}));
