import axios from "axios";
import * as plan from "./plan";
import * as app from "./app";
import * as authIf from "../middleware/authif";
import { savePrevPage } from "../components/screen/cushion/actions";
import { updateCouponCode } from "../common/status/coupon/actions";
import { historyAppLogin } from "../historys";

/** 接続トークン. */
export const X_TOKEN = "X-TOKEN";

/** ログイントークン. */
export const TI_SID = "ti_sid";

/** データ. */
const DATA = "data";

/** メッセージ. */
const MESSAGE = "msg";

/** エラーコード. */
export const ERROR_CODE = "errCd";

/** 成功コード. */
const SUCCESS_CODE = "0";

/**
 * クッキーからX-TOKENを取得.
 */
export const getXToken = () => {
    let cookies = document.cookie;
    let cookiesArray = cookies.split(";");
    for (var c of cookiesArray) {
        let cArray = c.split("=");
        if (cArray[0].trim() === X_TOKEN) {
            return cArray[1];
        }
    }
    return "";
}

/**
 * 実行API.
 */
export const api = axios.create({
    headers: { [X_TOKEN]: getXToken() },
    data: {}
});

/**
 * リクエストの成功判定.
 */
export const isSuccess = (body) => {
    if (body === undefined) return false;
    return SUCCESS_CODE === body[MESSAGE][ERROR_CODE];
}

/**
 * セッションエラー判定.
 */
export const isSessionError = (data, errCd) => {
    if (data === undefined || data === null) return false;
    return data[ERROR_CODE] === errCd;
}

/**
 * レスポンスを取得.
 */
export const getData = (body) => {
    return body[DATA];
}

/**
 * エラーメッセージを取得.
 */
export const getErrMsg = (body) => {
    return body[MESSAGE];
}

/**
 * ISO形式の日本時間に変換.
 */
export const getDateISO = (d) => {
    const date = new Date(d + 9 * 60 * 60 * 1000);
    return date.toISOString().replace(/\..*/, "+09:00");
}

/**
 * ISO形式から（yy年mm月dd日）形式に変換.
 */
export const getDatefromISO = (d) => {
    const date = new Date(d);
    return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
}

/**
 * ISO形式（秒）から（yy/mm/dd/）形式に変換.
 */
export const getDatefromISOSlash = (d) => {
    const date = new Date(d * 1000);
    return date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate();
}

/**
 * 有効期限日を取得.
 * FIXME：クライアントで計算せず、サーバAPIから取得したい
 */
export const getExpirationDate = (mr, csdt, isCampaign) => {
    if (isCampaign) {
        // 課金開始日の1日前.
        const date = new Date(csdt);
        date.setDate(date.getDate() - 1);
        return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
    } else {
        // スタンダード会員は現在月の最終日.
        if (plan.isStandard(mr)) {
            const now = new Date();
            // 今月末日.
            const last = new Date(now.getFullYear(), now.getMonth() + 1, 0);
            return last.getFullYear() + "年" + (last.getMonth() + 1) + "月" + last.getDate() + "日";
        }
        // プレミアム会員は、月日が課金開始日の前月の最終日、年は今年 or 翌年
        if (plan.isPremium(mr)) {
            // 課金開始日の前月の最終日.
            const chargeStartDate = new Date(csdt);
            const last = new Date(chargeStartDate.getFullYear(), chargeStartDate.getMonth() + 1, 0);
            
            // 有効期限の年の計算
            const now = new Date();
            let year = 0;
            // 現在日時が課金開始日と同じ年月の場合、有効期限は翌年
            // 例：now:2021/3 charge:2021/3 expire:2022/3(=2021+1)
            if (now.getFullYear() === chargeStartDate.getFullYear() && now.getMonth() === chargeStartDate.getMonth()) {
                year = now.getFullYear() + 1;
            } else if (now.getMonth() <= chargeStartDate.getMonth()) {
                // 現在月 <= 課金開始月の場合、かつ課金開始した翌年以降、の場合、有効期限は現在年
                // 例(<)：now 2022/2 charge:2021/3 expire:2022/3(=2022)
                // 例(=)：now 2022/3 charge:2021/3 expire:2022/3(=2022)
                year = now.getFullYear();
            } else {
                // 現在月 > 課金開始月の場合、有効期限は翌年
                // 例(>)：now:2021/4 charge:2021/3 expire:2022/3(=2021+1)
                year = now.getFullYear() + 1;
            }
            return year + "年" + (last.getMonth() + 1) + "月" + last.getDate() + "日";
        }
    }
    return "";
}

/**
 * 変更予定日を取得.
 * vsdt: UnixTime
 */
export const getChangeDate = (vsdt) => {
    // 有効終了日時の1日後.
    const date = new Date(vsdt);
    date.setDate(date.getDate() + 1);
    return date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日";
}

/**
 * 円谷ログイン画面（Web）へ遷移.
 * dispatch: dispatch
 * location: location
 */
export const tsuburayaWebLogin = (dispatch, location) => {
    dispatch(savePrevPage(location.pathname + location.search));
    authIf.postLogin({[authIf.TYPE]: authIf.WEB_LOGIN, [authIf.DESTINATION]: authIf.TSUBURAYA});
}

/**
 * 円谷ログイン画面（アプリ）へ遷移.
 * dispatch: dispatch
 * location: location
 */
export const tsuburayaAppLogin = (dispatch, location) => {
    dispatch(savePrevPage(location.pathname + location.search));
    authIf.postLogin({[authIf.TYPE]: authIf.APP_LOGIN, [authIf.DESTINATION]: authIf.TSUBURAYA});
}

/**
 * 円谷ログイン画面へ遷移.
 * dispatch: dispatch
 * location: location
 */
export const tsuburayaLogin = (dispatch, location, history) => {
    // LocalStorageのクーポンコードを初期化.
    dispatch(updateCouponCode(""));
    if (app.isAllApp()) {
        historyAppLogin(history);
    }
    else {
        tsuburayaWebLogin(dispatch, location);
    }
}
