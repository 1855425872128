import React from "react";

/**
 * 処理中ダイアログ.
 */
const Progress = () => {
    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    return (
        <div
            className="p-popupSettlement"
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popupSettlement__box">
                <div className="p-popupSettlement__wrap">
                    <div className="p-popupSettlement__icon"></div>
                    <div className="p-popupSettlement__txt">処理中です。<br />このままおまちください。</div>
                </div>
            </div>
            <div className="p-popupSettlement__bg"></div>
        </div>
    );
};

export default Progress;
