import * as actionTypes from "./actionTypes";

const initState = {
    popupPlanSelect: false,
    popupPlanDetail: false,
    isNotJoin: false,
    isPremiumContents: false,
    couponCode: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 会員プラン選択画面を表示. */
        case actionTypes.OPEN_POPUP_PLAN_SELECT:
            return {
                ...state,
                popupPlanSelect: true,
                isNotJoin: action.isNotJoin,
                isPremiumContents: action.isPremiumContents
            };

        /** 会員プラン選択画面を閉じる. */
        case actionTypes.CLOSE_POPUP_PLAN_SELECT:
            return { ...state, popupPlanSelect: false, couponCode: "" };

        /** 会員プラン詳細画面を表示. */
        case actionTypes.OPEN_POPUP_PLAN_DETAIL:
            return { ...state, popupPlanSelect: false, popupPlanDetail: true };

        /** 会員プラン詳細画面を閉じる. */
        case actionTypes.CLOSE_POPUP_PLAN_DETAIL:
            return { ...state, popupPlanSelect: true, popupPlanDetail: false };

        /** 会員プラン選択画面を表示（クーポン引き渡し）. */
        case actionTypes.OPEN_POPUP_PLAN_SELECT_COUPON:
            return {
                ...state,
                popupPlanSelect: true,
                isNotJoin: action.isNotJoin,
                isPremiumContents: action.isPremiumContents,
                couponCode: action.couponCode
            };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
