import * as actionTypes from "./actionTypes";
import * as deliveryheif from "../../../middleware/deliveryheif";

/**
 * 初期化.
 */
export const initVideoId = () => {
    return {
        type: actionTypes.INIT_VIDEO_ID
    };
}

/**
* ID検索API実行成功.
*/
export const postIdSearchSuccess = (body) => {
    return {
        type: actionTypes.POST_IDSEARCH_SUCCESS,
        content: body
    };
}

/**
* メタ検索API（シリーズ）実行成功.
*/
export const postSearchSeriesSuccess = (body) => {
    return {
        type: actionTypes.POST_SEARCH_SERIES_SUCCESS,
        contentsList: body
    }
}

/**
* メタ検索API（シリーズ）（2回目）実行成功.
*/
export const postSearchSeriesAddSuccess = (body) => {
    return {
        type: actionTypes.POST_SEARCH_SERIES_ADD_SUCCESS,
        contentsList: body
    }
}

/**
* メタ検索API（レコメンド関連）実行成功.
*/
export const postSearchRecommendSuccess = (body, clickData) => {
    return {
        type: actionTypes.POST_SEARCH_RECOMMEND_SUCCESS,
        contentsList: body,
        clickData: clickData
    }
}

/**
* 視聴開始API実行成功.
*/
export const postStartWatchSuccess = (body) => {
    const playList = body[deliveryheif.PLAY_LIST][0];
    return {
        type: actionTypes.POST_START_WATCH_SUCCESS,
        playToken: body[deliveryheif.PLAY_TOKEN],
        playUrl: playList[deliveryheif.PLAY_URL],
        drmMode: playList[deliveryheif.DRM_MODE],
        licenseUrl: playList[deliveryheif.LA_URL],
        customData: playList[deliveryheif.CUSTOM_DATA]
    };
}

/**
 * 再生URL変更.
 */
export const updatePlayUrl = (playUrl, isChangedQuality) => {
    return {
        type: actionTypes.UPDATE_PLAY_URL,
        playUrl: playUrl,
        isChangedQuality: isChangedQuality
    };
}