import { DELIVERYHEIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";
import { doLogout } from "../components/dialog/Logout";

/** ユーザ種別. */
export const AVAIL_STATUS = "avail_status";

/** コンテンツリスト. */
export const CONTENT_LIST = "content_list";

/** kind: main. */
export const KIND_MAIN = "main";

/** kind: sub. */
export const KIND_SUB = "sub";

/** 種別. */
export const KIND = "kind";

/** サービスID. */
export const SERVICE_ID = "service_id";

/** コンテンツID. */
export const CRID = "crid";

/** 配信制御用ID. */
export const CID = "cid";

/** ライセンスID. */
export const LID = "lid";

/** 再生識別文字列. */
export const PLAY_TOKEN = "play_token";

/** 動画の長さ. */
export const DUR = "dur";

/** 再生終了位置. */
export const STOP_POSITION = "stop_position";

/** 再生情報. */
export const PLAY_LIST = "play_list";

/** 再生URL. */
export const PLAY_URL = "play_url";

/** DRMモード. */
export const DRM_MODE = "drm_mode";

/** DRMモードに応じたライセンスサーバURL. */
export const LA_URL = "la_url";

/** DRM情報. */
export const CUSTOM_DATA = "custom_data";

/** 海外エラー番号下3桁. */
export const OVERSEAS_ERROR_CODE = "102";

/** 未契約エラー番号下3桁. */
export const NO_CONTRACT_ERROR_CODE = "014";

/** 契約エラー番号下3桁. */
export const CONTRACT_ERROR_CODE = "015";

/** 配信コンテンツエラー番号下3桁. */
export const CONTENT_ERROR_CODE = "016";

/** 同時視聴エラーエラー番号下3桁. */
export const VIEWING_CTL_ERROR_CODE = "018";

/** ポーリングAPI視聴終了済みエラー番号. */
export const EXPIRED_PLAY_TOKEN_ERROR_CODE = "WAR05024";

/** セッションエラー. */
export const WAR05001 = "WAR05001";

/**
 * 視聴開始API.
 * API: POST /startwatch
 */
export async function postStartWatch(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(DELIVERYHEIF + "/startwatch", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ポーリングAPI.
 * API: POST /polling
 */
export async function postPolling(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(DELIVERYHEIF + "/polling", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 視聴終了API.
 * API: POST /endwatch
 */
export async function postEndWatch(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(DELIVERYHEIF + "/endwatch", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 同期視聴終了API.
 * API: POST /endwatch
 */
export function postAwaitEndWatch(data) {
    fetch(DELIVERYHEIF + "/endwatch", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            [apiUtil.X_TOKEN]: apiUtil.getXToken()
        },
        keepalive: true,
        body: JSON.stringify(data)
    })
    .then(() => {})
    .catch(() => {});
}

/**
 * セッションエラーはログアウト.
 */
export const isSessionError = (dispatch, err) => {
    if (apiUtil.isSessionError(err, WAR05001)) doLogout(dispatch);
}
