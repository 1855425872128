import React, { useState } from "react";

import * as planActions from "../../../common/popup/plan/actions";
import * as commonActions from "../../../common/status/actions";
import * as historys from "../../../historys";
import * as eventif from "../../../middleware/eventif";
import * as error from "../../../constants/error";
import * as plan from "../../../constants/plan"
import * as apiUtil from "../../../constants/apiUtil";
import * as contentsKey from "../../../constants/contentsKey";
import * as app from "../../../constants/app";
import { loading } from "../../../common/status/actions";
import "./styles.css";

/**
 * ムビチケ.
*/
const MovieTicket = (props) => {
    /** 入力ダイアログオープン状態. */
    const [openForm, setOpenForm] = useState(false);
    /** 購入番号. */
    const [buyNo, setBuyNo] = useState("");
    /** 暗証番号. */
    const [pin, setPin] = useState("");

    /**
     * 購入番号変更.
     */
    const handleBuyNo = (event) => {
        // 半角英数以外入力不可.
        setBuyNo(event.target.value.replace(/[^\x20-\x7E]*/g, ""));
    };

    /**
     * 暗証番号変更.
     */
    const handlePin = (event) => {
        // 半角英数以外入力不可.
        setPin(event.target.value.replace(/[^\x20-\x7E]*/g, ""));
    };

    /**
     * ムビチケ入力情報ダイアログオープンチェック.
     */
    const applyOpen = () => {
        if (plan.isNone(props.memberPlan)) {
            // ログイン画面へ遷移.
            apiUtil.tsuburayaLogin(props.dispatch, props.location, props.history);
        } else if (plan.isSatisfiedPlan(props.memberPlan, props.minimumPlan)) {
            // 入力フォームダイアログ表示.
            document.body.classList.add("is-fixed");
            setOpenForm(true);
        } else {
            // アップグレードのため、会員ランク選択画面へ遷移.
            if (app.isAllApp()) {
                historys.historyAppPlan(props.history);
            }
            else {
                props.dispatch(planActions.openPopupPlanSelect(false, plan.isPremium(props.minimumPlan)));
            }
        }
    };

    /**
     * ムビチケ入力情報をAPIに送信.
     */
    const apply = () => {
        props.dispatch(loading(true));
        setOpenForm(false);

        // ムビチケ応募API呼び出し.
        // 購入番号・暗証番号・cridを送信.

        const body = {
            [eventif.PURCHASE_NUMBER]: buyNo,
            [eventif.PIN]: pin,
            [eventif.CRID]: props.content[contentsKey.CRID]
        };

        eventif.postMovieticket(body).then((res) => {
            // クーポン行使画面を表示.
            props.dispatch(commonActions.couponUsed(true, res));
        }, (err) => {
            // ムビチケ連携API失敗.

            // セッションエラーを確認.
            eventif.isSessionError(props.dispatch, err);

            let title = "";
            let message = "";
            switch (err[apiUtil.ERROR_CODE]) {
                // ムビチケ購入番号が不正.
                case eventif.WAR06005:
                    title = error.MOVIE_TICKET_FAILURE_ILLEGAL_NUMBER_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_ILLEGAL_NUMBER_CONTENT;
                    break;
                // ムビチケ応募済み.
                case eventif.WAR06006:
                    title = error.MOVIE_TICKET_FAILURE_APPLIED_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_APPLIED_CONTENT;
                    break;
                // ムビチケ購入番号使用済み.
                case eventif.WAR06008:
                    title = error.MOVIE_TICKET_FAILURE_USED_NUMBER_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_USED_NUMBER_CONTENT;
                    break;
                // ムビチケ応募期間開始前.
                case eventif.WAR06023:
                    title = error.MOVIE_TICKET_FAILURE_BEFORE_START_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_BEFORE_START_CONTENT;
                    break;
                // ムビチケ応募期間終了済.
                case eventif.WAR06026:
                    title = error.MOVIE_TICKET_FAILURE_AFTER_END_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_AFTER_END_CONTENT;
                    break;
                // それ以外.
                default:
                    title = error.MOVIE_TICKET_FAILURE_TITLE;
                    message = error.MOVIE_TICKET_FAILURE_CONTENT;
                    break;
            }
            props.setMovieTicketFailure({status: true, title: title, content: message});
        })
        .finally(() => {
            props.dispatch(loading(false));
        });
    };

    /**
     * ムビチケ入力ダイアログを閉じる.
     */
    const cancel = () => {
        document.body.classList.remove("is-fixed");
        setOpenForm(false);
    };

    /**
     * フォーム入力中のEnterボタン押下時の処理をキーボードを閉じる（フォーカスを外す）に変更.
     */
    const notEnterAction = (e) => {
        const key = e.keyCode || e.charCode || 0;
        if (key === 13) {
            e.preventDefault();
            document.activeElement.blur();
        }
    };

    /**
     * Eキーが押下された時は入力を受け付けない.
     */
    const notEKeyDown = (e) => {
        const key = e.keyCode || e.charCode || 0;
        if (key === 69) {
            e.preventDefault();
        }
    };

    /**
     * ムビチケ入力ダイアログ.
     */
    const inputForm = () => {
        return (
            <div className="p-popup">
            <div className="p-popup__box p-popup__box--largeBox">
                <div className="p-popupBox">
                    <div className="p-popupBoxTtl">
                        <div className="p-popupBoxTtl__txt">エントリー - ムビチケ購入者番号入力</div>
                    </div>
                    <div className="p-popupBoxWrap">
                        <div className="p-popupBoxWrap__companyRank">
                            <div className="p-popupBoxWrap__companyRankTxt">購入番号と暗証番号を入力してください。</div>
                        </div>
                        <div className="p-popupBoxWrap__attention">
                            <div className="p-popupBoxWrap__attentionTxt">は入力必須項目です</div>
                        </div>
                        <ul className="p-popupBoxWrap__form">
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory">購入番号</div>
                                <form className="p-popupBoxWrap__formBox" onKeyPress={notEnterAction}>
                                    <input className="p-popupBoxWrap__example" type="text" placeholder="例) 1234567891234" value={buyNo} onChange={handleBuyNo} onKeyDown={notEKeyDown} maxLength="16" style={{color:"black"}}/>
                                </form>
                            </li>
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory">暗証番号</div>
                                <form className="p-popupBoxWrap__formBox" onKeyPress={notEnterAction}>
                                    <input className="p-popupBoxWrap__example" type="password" placeholder="例) 1234567891234" value={pin} maxLength="16" style={{color:"black"}}
                                      onChange={handlePin}
                                      onKeyDown={notEKeyDown}
                                    />
                                </form>
                            </li>
                        </ul>
                        <div className="c-btnWrap p-popupBoxWrap__btnWrap">
                            <div className="c-btn c-btnWrap__size c-btn--gray" onClick={cancel}>キャンセル</div>
                            <div className="c-btn c-btnWrap__size c-btn--thinRed" onClick={apply}>送信する</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
        );
    };

    return (
        <>
            <div className="c-contentsApply">
                <span className="c-btn c-btn--apply" onClick={applyOpen}>応募する</span>
            </div>
            {openForm && inputForm()}
        </>
    );
};

export default MovieTicket;