import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Parallax from "react-rellax";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { HOW_TO_CUBE_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * データキューブの楽しみ方画面.
 */
const Howtocube = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.howtocube());

        // head設定.
        setHeadDefault(HOW_TO_CUBE_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo c-static__logo--aboutDc"><img src="./assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination" /></div>
                    <div className="c-staticAboutDc">
                        <div className="c-staticAboutDc__wrap">
                            <div className="c-staticAboutDc__mainVisual">
                                <div className="c-staticAboutDc__cube"><img src="./assets/images/img-datacube-cube.png" alt="" /></div>
                            </div>
                            <div className="c-staticAboutDc__contents">
                                <div className="c-staticAboutDc__txt">
                                    <div className="c-staticAboutDc__txtBg"><img src="./assets/images/img-datacube-cube.png" alt="" /></div>
                                    <div className="c-staticAboutDc__ttl">データキューブの楽しみ方</div>
                                    <div className="c-staticAboutDc__dtl">データキューブ（デジタル図鑑）とは<br />商品やイベントなど、オンラインだけではなくリアルな場所からでもTSUBURAYA IMAGINATIONを楽しめるコンテンツです。<br /><br />データキューブ専用二次元コードを読み取るとTSUBURAYA IMAGINATION内に登録され、円谷作品にまつわる様々な情報が入ったデータキューブ（デジタル図鑑）を集めることができます。</div>
                                </div>
                                <section className="c-staticAboutDcSect">
                                    <ul className="c-staticAboutDcSect__wrap">
                                        <li className="c-staticAboutDcSect__item">
                                            <div className="c-staticAboutDcSect__band">
                                                <div className="c-staticAboutDcSect__bandNum">
                                                    <div className="c-staticAboutDcSect__bandNumTxt">1</div>
                                                </div>
                                                <div className="c-staticAboutDcSect__bandTtl">探す</div>
                                            </div>
                                            <div className="c-staticAboutDcSect__cont">
                                                <div className="c-staticAboutDcSect__head">グッズやイベント会場などで<br />データキューブ専用二次元コードを探そう！</div>
                                                <div className="c-staticAboutDcSect__imgBox">
                                                    <div className="c-staticAboutDcSect__chara"><img src="./assets/images/img-datacube-chara-01.png" alt="" /></div>
                                                    <div className="c-staticAboutDcSect__qr"><img src="./assets/images/img-datacube-qr.png" alt="" /></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="c-staticAboutDcSect__item">
                                            <div className="c-staticAboutDcSect__band">
                                                <div className="c-staticAboutDcSect__bandNum">
                                                    <div className="c-staticAboutDcSect__bandNumTxt">2</div>
                                                </div>
                                                <div className="c-staticAboutDcSect__bandTtl">スキャン</div>
                                            </div>
                                            <div className="c-staticAboutDcSect__cont">
                                                <div className="c-staticAboutDcSect__head">データキューブ専用二次元コードを見つけたら、<br />スキャンしよう！<br /><span>※お手持ちの二次元コードリーダーで<br className="c-staticAboutDcSect__newLine" />読み取ってアクセスしてください。</span></div>
                                                <div className="c-staticAboutDcSect__imgBox">
                                                    <div className="c-staticAboutDcSect__phone"><img src="./assets/images/img-datacube-phone.png" alt="" /></div>
                                                    <div className="c-staticAboutDcSect__chara c-staticAboutDcSect__chara--phone"><img src="./assets/images/img-datacube-chara-02.png" alt="" /></div>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="c-staticAboutDcSect__item">
                                            <div className="c-staticAboutDcSect__band">
                                                <div className="c-staticAboutDcSect__bandNum">
                                                    <div className="c-staticAboutDcSect__bandNumTxt">3</div>
                                                </div>
                                                <div className="c-staticAboutDcSect__bandTtl">GET</div>
                                            </div>
                                            <div className="c-staticAboutDcSect__cont">
                                                <div className="c-staticAboutDcSect__head">データキューブGET！<br />リストに追加されたデータキューブを<br />チェックしてみよう！</div>
                                                <div className="c-staticAboutDcSect__imgBox">
                                                    <div className="c-staticAboutDcSect__cubeItem c-staticAboutDcSect__cubeItem--pc"><img src="./assets/images/img-datacube-item-04.png" alt="" /></div>
                                                    <div className="c-staticAboutDcSect__cubeItem c-staticAboutDcSect__cubeItem--sp"><img src="./assets/images/img-datacube-item-01.png" alt="" /><img src="./assets/images/img-datacube-item-02.png" alt="" /><img src="./assets/images/img-datacube-item-03.png" alt="" /></div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </section>
                                <div className="c-staticAboutDc__gide">その他にも、スタンプラリーにように様々な場所を巡って集められるデータキューブや<br />データキューブリストをコンプリートするとさらに特典がGETできたり、様々な楽しみ方が出来ます。<br />是非色んなキャンペーンに参加してみてください。</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Howtocube;
