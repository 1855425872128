import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    root: {
        width: "100%"
    },
    clickable: {
        cursor: "pointer"
    },
    logolayer: {
        zIndex:"2 !important"
    },
    img1page: {
        margin: "auto",
        width: "auto"
    },
    imgwidth: {
        width: "auto"
    }
}));

export default styles;
