import * as actionTypes from "./actionTypes";

/**
 * 直前に閲覧していたページPATHを保存.
 */
export const savePrevPage = (prevPage) => {
    return {
        type: actionTypes.SAVE_PREV_PAGE,
        prevPage: prevPage
    };
}