import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./actions";
import * as plan from "../../../constants/plan";

/**
 * 会員プラン詳細画面.
 */
const PlanDetail = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** プラン料金. */
    const planPrice = useSelector(state => state.Member.planPrice);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
    }, []);

    /**
     * 会員選択画面へ遷移.
     */
    const handleClick = () => {
        document.body.classList.remove("is-fixed");
        dispatch(actions.closePopupPlanDetail());
    }

    return (
        <div className="p-popup">
            <div className="p-popup__box p-popup__box--largeBox js-scroll">
                <div className="p-popupBox">
                    <div className="p-popupBoxTtl p-popupBoxTtl--plan">
                        <div className="p-popupBoxTtl__txt">会員プラン</div>
                    </div>
                    <div className="p-popupPlanWrap">
                        <div className="p-popupPlanBox">
                            <div className="p-popupPlanList">
                                <ul className="p-popupPlanList__name">
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--free">無料</li>
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--standard">スタンダード</li>
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--premium">プレミアム</li>
                                </ul>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <p className="p-popupPlanList__txt"><span className="p-popupPlanList__middleTxt">{"月" + Number(planPrice[plan.STANDARD_WITHOUT_TAX]).toLocaleString()}</span><span className="p-popupPlanList__smallTxt">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.STANDARD]).toLocaleString() + "円（税込）"}</span></p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <p className="p-popupPlanList__txt"><span className="p-popupPlanList__middleTxt">{"年" + Number(planPrice[plan.PREMIUM_WITHOUT_TAX]).toLocaleString()}</span><span className="p-popupPlanList__smallTxt">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.PREMIUM]).toLocaleString() + "円（税込）"}</span></p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--play c-picIconPlay"></div>
                                    <div className="p-popupPlanListTtl__txt">映像</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--mb"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">ウルトラマン<br/>シリーズや<br/>オリジナル新作など<br/>約<span className="p-popupPlanList__largeTxt">1200</span><br/>エピソードが見放題！</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double p-popupPlanList__round--mb"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">スタンダードプラン<br/>に加え、<br/>円谷作品、他<br/>約<span className="p-popupPlanList__largeTxt">1700</span><br/>エピソードが見放題！</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                        <div className="c-icon__Txt">LIVE</div>
                                    </div>
                                    <div className="p-popupPlanListTtl__txt">オンラインイベント</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt"> アーカイブ配信は個別課金で視聴可能（※）</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">一部のオンライン<br/>イベントに参加可能！</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">プレミアム限定の<br/>オンライン企画も！</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--article c-picIconarticle"></div>
                                    <div className="p-popupPlanListTtl__txt">読み物</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free p-popupPlanList__dtlItem">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">小説、コミックス、<br/>コラム、解説等、<br/>一部コンテンツを除き、見放題</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">すべて見放題</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl p-popupPlanListTtl--bigSize">
                                    <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                        <div className="c-icon__Txt">TICKET</div>
                                    </div>
                                    <div className="p-popupPlanListTtl__txt">イベントチケット先行受付<br/>（リアル／オンライン）</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl p-popupPlanListTtl--bigSize">
                                    <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                        <div className="c-icon__Txt">TICKET</div>
                                    </div>
                                    <div className="p-popupPlanListTtl__txt">イベントチケット最速先行受付<br/>（リアル／オンライン）</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                                        <div className="p-popupPlanListTtl__txt">会員限定グッズ</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                                    <div className="p-popupPlanListTtl__txt">プレミアム限定グッズ販売</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--delTxt">プレミアムセールへの優先参加・ノベルティの付与なども！</p>
                                    </li>
                                </ul>
                            </div>
                            <div className="p-popupPlanBox__attention">※一部イベントは対象とならない場合があります。</div>
                            <div className="c-btn p-popupBoxWrap__btn c-btn--thinRed" onClick={() => handleClick()}>会員プランの選択へ</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
    );
};
export default PlanDetail;
