import * as actionTypes from "./actionTypes";

const initState = {
    /** 初期処理判定. */
    isInit: false,
    /** 端末時刻判定（WARN）. */
    isTimeWarn: false,
    /** 端末時刻判定（NG）. */
    isTimeNG: false,
    /** 通信失敗. */
    isConectionError: false,
    /** プロフィール画像変更失敗. */
    isChangeProfPictureFailure: false,
    /** プランキャンセル失敗. */
    isCancelPlanFailure: false,
    /** 退会失敗. */
    isWithdrawalFailure: false,
    /** ログアウト失敗. */
    isLogoutFailure: false,
    /** お気に入り変更失敗. */
    favoriteFailure: {
        status: false,
        content: ""
    },
    /** キャンペーン遷移失敗. */
    isCampaignDestinationFailure: false,
    /** データキューブ情報取得失敗. */
    isDataCubeFailure: false,
    /** 追加特典取得失敗. */
    isMoreGiftFailure: false,
    /** 追加特典取得失敗（オンラインクーポン）. */
    isMoreGiftCouponFailure: false,
    /** 処理中ダイアログ. */
    isLoading: false,
    /** 海外エラー. */
    isOverseasError: false,
    /** クーポン行使画面. */
    isCouponUsed: {
        status: false,
        campaignMeta: {}
    },
    /** クーポン プラン未充足エラー. */
    isCouponUsedPlanFailure: false,
    /** クーポン行使エラー. */
    isCouponUsedFailure: {
        status: false,
        title: "",
        content: ""
    },
    /** アプリ対応：アプリ閲覧不可LP警告ダイアログ表示/非表示. */
    isAppNotDisplayLP: false,
    /** HTTPレスポンスエラーコード. */
    httpErrorCode: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 初期処理判定. */
        case actionTypes.INIT:
            return { ...state, isInit: action.isInit };

        /** 端末時刻判定（WARN）. */
        case actionTypes.UPDATE_TIME_WARN:
            return { ...state, isTimeWarn: action.isTimeWarn };

        /** 端末時刻判定（NG）. */
        case actionTypes.UPDATE_TIME_NG:
            return { ...state, isTimeNG: action.isTimeNG };

        /** 通信失敗. */
        case actionTypes.UPDATE_CONECTION_ERROR:
            return { ...state, isConectionError: action.isConectionError, httpErrorCode: action.httpErrorCode };

        /** プロフィール画像変更失敗. */
        case actionTypes.UPDATE_CHANGE_PROF_PICTURE_FAILURE:
            return { ...state, isChangeProfPictureFailure: action.isChangeProfPictureFailure };

        /** プランキャンセル失敗. */
        case actionTypes.CANCEL_PLAN_FAILURE:
            return { ...state, isCancelPlanFailure: action.isCancelPlanFailure };

        /** 退会失敗. */
        case actionTypes.WITHDRAWAL_FAILURE:
            return { ...state, isWithdrawalFailure: action.isWithdrawalFailure };

        /** ログアウト失敗. */
        case actionTypes.LOGOUT_FAILURE:
            return { ...state, isLogoutFailure: action.isLogoutFailure };

        /** お気に入り変更失敗. */
        case actionTypes.FAVORITE_FAILURE:
            return { ...state, favoriteFailure: action.favoriteFailure };

        /** キャンペーン遷移失敗. */
        case actionTypes.CAMPAIGN_DESTINATION_FAILURE:
            return { ...state, isCampaignDestinationFailure: action.isCampaignDestinationFailure };

        /** データキューブ情報取得失敗. */
        case actionTypes.DATA_CUBE_FAILURE:
            return { ...state, isDataCubeFailure: action.isDataCubeFailure };

        /** 追加特典取得失敗. */
        case actionTypes.MORE_GIFT_FAILURE:
            return { ...state, isMoreGiftFailure: action.isMoreGiftFailure };

        /** 追加特典取得失敗（オンラインクーポン）. */
        case actionTypes.MORE_GIFT_COUPON_FAILURE:
            return { ...state, isMoreGiftCouponFailure: action.isMoreGiftCouponFailure };

        /** 処理中ダイアログ. */
        case actionTypes.LOADING:
            return { ...state, isLoading: action.isLoading };

        /** 海外エラー. */
        case actionTypes.OVERSEAS_ERROR:
            return { ...state, isOverseasError: action.isOverseasError };

        /** クーポン行使画面. */
        case actionTypes.COUPON_USED:
            return { ...state, isCouponUsed: action.isCouponUsed };

        /** クーポン プラン未充足エラー. */
        case actionTypes.COUPON_USED_PLAN_FAILURE:
            return { ...state, isCouponUsedPlanFailure: action.isCouponUsedPlanFailure };

        /** クーポン行使エラー. */
        case actionTypes.COUPON_USED_FAILURE:
            return { ...state, isCouponUsedFailure: action.isCouponUsedFailure };

        /** アプリ対応：アプリ閲覧不可LP警告ダイアログ表示/非表示. */
        case actionTypes.APP_NOT_DISPLAY_LP:
            return { ...state, isAppNotDisplayLP: action.isAppNotDisplayLP };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
