import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./actions";
import * as playerActions from "../../../components/player/actions";
import { updatePlayUrl } from "../../../components/screen/videoId/actions";
import * as app from "../../../constants/app";
import * as constans from "../../../constants/imageQuality";
import * as playerConstants from "../../../constants/player";
import * as mediaType from "../../../constants/mediaType";

/**
 * 画質設定ポップアップ.
 */
const ImageQualityPopup = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** ダイアログDOM(ダイアログ外側タップ判定用) */
    const dialogRef = useRef();

    // 画質詳細設定
    const detailQuality = useSelector(state => state.ImageQualityPopup.detailQuality);

    // 画質全体設定（ブラウザ版）
    const allQuality = useSelector(state => state.Player.allQuality);

    // 画質全体設定（アプリ版/Wi-Fi）
    const allWifiQuality = useSelector(state => state.Player.allWifiQuality);

    // 画質全体設定（アプリ版/モバイル）
    const allMobileQuality = useSelector(state => state.Player.allMobileQuality);

    // HD・SD画質判別
    const videoDifinition = useSelector(state => state.VideoId.content.videoDifinition);

    // 通信モード判定
    const isWifi = useSelector(state => state.statusQuality.isWifi);

    // DRMモード
    const drmMode = useSelector(state => state.VideoId.drmMode);

    // 再生URL
    const playUrl = useSelector(state => state.VideoId.playUrl);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // ダイアログ外側タップで閉じる.
        const handleClickOutside = (e) => {
            if (!dialogRef.current) return;
            if (!dialogRef.current.contains(e.target)) {
                close();
            }
        }
        document.addEventListener("click", handleClickOutside);
        document.body.classList.add("is-fixed");
        return () => {
            document.removeEventListener("click", handleClickOutside);
            document.body.classList.remove("is-fixed");
        }
        // eslint-disable-next-line
    }, []);

    /**
     * 閉じるアイコン押下時の処理
     */
    const close = () => {
        props.close();
    }

    /**
     * 詳細設定の表示切替
     */
    const detailImageQuality = () => {

        switch (videoDifinition) {
            case "SD":
                return (
                    <fragment>
                        <div className="c-qualityList__flag">
                            <label className="c-qualityList__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.SD_AUTO} name="present" onClick={() => { detailImageQualitySetting(constans.SD_AUTO) }} /><span className="c-qualityList__btn">自動（推奨）</span>
                            </label>
                        </div>
                        <div className="c-qualityList__wrap">
                            <label className="c-qualityList__item c-qualityList__wrap__item c-qualityList__wrap__item__sd">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.SD_480} name="present" onClick={() => { detailImageQualitySetting(constans.SD_480) }} /><span className="c-qualityList__wrap__btn">480p</span>
                            </label>
                            <label className="c-qualityList__item c-qualityList__wrap__item c-qualityList__wrap__item__sd">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.SD_180} name="present" onClick={() => { detailImageQualitySetting(constans.SD_180) }} /><span className="c-qualityList__wrap__btn">180p</span>
                            </label>
                        </div>
                    </fragment>
                );
            case "HD":
            default:
                return (
                    <fragment>
                        <div className="c-qualityList__flag">
                            <label className="c-qualityList__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_AUTO} name="present" onClick={() => { detailImageQualitySetting(constans.HD_AUTO) }} /><span className="c-qualityList__btn">自動（推奨）</span>
                            </label>
                        </div>
                        <div className="c-qualityList__wrap">
                            <label className="c-qualityList__item c-qualityList__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_1080} name="present" onClick={() => { detailImageQualitySetting(constans.HD_1080) }} /><span className="c-qualityList__wrap__btn">1080p</span>
                            </label>
                            <label className="c-qualityList__item c-qualityList__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_720} name="present" onClick={() => { detailImageQualitySetting(constans.HD_720) }} /><span className="c-qualityList__wrap__btn">720p</span>
                            </label>
                            <label className="c-qualityList__item c-qualityList__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_540} name="present" onClick={() => { detailImageQualitySetting(constans.HD_540) }} /><span className="c-qualityList__wrap__btn">540p</span>
                            </label>
                            <label className="c-qualityList__item c-qualityList__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_480} name="present" onClick={() => { detailImageQualitySetting(constans.HD_480) }} /><span className="c-qualityList__wrap__btn">480p</span>
                            </label>
                            <label className="c-qualityList__item c-qualityList__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={detailQuality === constans.HD_180} name="present" onClick={() => { detailImageQualitySetting(constans.HD_180) }} /><span className="c-qualityList__wrap__btn">180p</span>
                            </label>
                        </div>
                    </fragment>
                );
        }
    };

    /**
     * 全体設定の表示切替
     */
    const allImageQuality = () => {
        if (app.isAllApp() && app.isLatestApp()) { // アプリ版
            return (
                <fragment>
                    <div className="c-quality__basis__list">
                        <div className="c-quality__basis__txt">自動画質設定（Wi-Fi）</div>
                        <div className="c-quality__basis__wrap">
                            <label className="c-qualityList__item c-quality__basis__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={!allWifiQuality} name="wifi" onClick={() => { allWifiImageQualitySetting(false) }} /><span className="c-qualityList__btn">自動（高画質）</span>
                            </label>
                            <label className="c-qualityList__item c-quality__basis__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={allWifiQuality} name="wifi" onClick={() => { allWifiImageQualitySetting(true) }} /><span className="c-qualityList__btn">データセーバー</span>
                            </label>
                        </div>
                    </div>
                    <div className="c-quality__basis__list">
                        <div className="c-quality__basis__txt">自動画質設定（モバイル）</div>
                        <div className="c-quality__basis__wrap">
                            <label className="c-qualityList__item c-quality__basis__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={!allMobileQuality} name="mobile" onClick={() => { allMobileImageQualitySetting(false) }} /><span className="c-qualityList__btn">自動（高画質）</span>
                            </label>
                            <label className="c-qualityList__item c-quality__basis__wrap__item">
                                <input className="c-qualityList__item__btn" type="radio" checked={allMobileQuality} name="mobile" onClick={() => { allMobileImageQualitySetting(true) }} /><span className="c-qualityList__btn">データセーバー</span>
                            </label>
                        </div>
                    </div>
                </fragment>
            );
        } else { // ブラウザ版
            return (
                <div className="c-quality__basis__list">
                    <div className="c-quality__basis__txt">自動画質設定</div>
                    <div className="c-quality__basis__wrap">
                        <label className="c-qualityList__item c-quality__basis__wrap__item">
                            <input className="c-qualityList__item__btn" type="radio" checked={!allQuality} name="wifi" onClick={() => { allImageQualitySetting(false) }} /><span className="c-qualityList__btn">自動（高画質）</span>
                        </label>
                        <label className="c-qualityList__item c-quality__basis__wrap__item">
                            <input className="c-qualityList__item__btn" type="radio" checked={allQuality} name="wifi" onClick={() => { allImageQualitySetting(true) }} /><span className="c-qualityList__btn">データセーバー</span>
                        </label>
                    </div>
                </div>
            );
        }
    };

    /** 自動とデータセーバーどちらが設定されているか返却.
     * true: データセーバー、false: 自動
     */
    const baseQualitySetting = () => {
        if (app.isAllApp() && app.isLatestApp()) {
            return isWifi ? allWifiQuality : allMobileQuality;
        }
        else {
            return allQuality;
        }
    };

    /**
     * 詳細画質「自動」の場合の画質変更.
     * autoFlag: 自動: false、データセーバー: true
     */
    const changeAutoQuality = (autoFlag) => {
        if (drmMode === playerConstants.FAIR_PLAY) {
            let newPlayUrl;
            if (props.mediaType === mediaType.LIVE_TYPE) {
                if (autoFlag) {
                    newPlayUrl = playerConstants.getLiveFairPlayUrl(playUrl, "DATASAVER");
                }
                else {
                    newPlayUrl = playerConstants.getLiveFairPlayUrl(playUrl, videoDifinition === "SD" ? "SD" : "HD");
                }
            }
            else {
                if (autoFlag) {
                    newPlayUrl = playerConstants.getPlayUrl(playUrl, "DATASAVER");
                }
                else {
                    newPlayUrl = playerConstants.getPlayUrl(playUrl, videoDifinition === "SD" ? "SD" : "HD");
                }
            }
            dispatch(updatePlayUrl(newPlayUrl, true));
        }
        else {
            props.player.current.setEnabledVideoRepresentationIndexes(autoFlag ? constans.DATASAVER_INDEX_LIST : [], true);
        }
    };

    // 詳細設定の画質
    const detailImageQualitySetting = (detailQualitySetting) => {
        if (detailQuality === detailQualitySetting) return;

        // セッションストレージに値を保存（連続再生用）
        dispatch(actions.detailQuality(detailQualitySetting));

        // 自動の場合は、基本設定の画質を設定.
        if (detailQualitySetting >= 10) {
            changeAutoQuality(baseQualitySetting());
        }
        else {
            // 詳細設定の場合は、その画質を設定.
            if (drmMode === playerConstants.FAIR_PLAY) {
                let newPlayUrl;
                if (props.mediaType === mediaType.LIVE_TYPE) {
                    newPlayUrl = playerConstants.getLiveFairPlayUrl(playUrl, constans.BITRATE_LIST[detailQualitySetting]);
                }
                else {
                    if (videoDifinition === "SD"){
                        newPlayUrl = playerConstants.getPlayUrl(playUrl, constans.SD_QUALITY_LIST[detailQualitySetting]);
                    }
                    else {
                        newPlayUrl = playerConstants.getPlayUrl(playUrl, constans.HD_QUALITY_LIST[detailQualitySetting]);
                    }
                }
                dispatch(updatePlayUrl(newPlayUrl, true));
            }
            else {
                props.player.current.setEnabledVideoRepresentationIndexes(detailQualitySetting, true);
            }
        }
        setTimeout(() => close(), 500);
    };

    // ブラウザ版全体設定の画質
    const allImageQualitySetting = (allQualitySetting) => {
        if (allQuality !== allQualitySetting) {
            // ローカルストレージに値を保存
            dispatch(playerActions.allQuality(allQualitySetting));
            // 詳細画質設定が「自動」に設定されている場合に選択した画質を適用する
            if (detailQuality >= 10) {
                changeAutoQuality(allQualitySetting);
            }
        }
    };

    // アプリ版/Wi-Fi全体設定の画質
    const allWifiImageQualitySetting = (allQualitySetting) => {
        if (allWifiQuality !== allQualitySetting) {
            // ローカルストレージに値を保存
            dispatch(playerActions.allWifiQuality(allQualitySetting));
            // 詳細画質設定が「自動」に設定されている、かつ通信がWiFiの場合に選択した画質を適用する
            if (detailQuality >= 10 && isWifi) {
                changeAutoQuality(allQualitySetting);
            }
        }
    };

    // アプリ版/モバイル全体設定の画質
    const allMobileImageQualitySetting = (allQualitySetting) => {
        if (allMobileQuality !== allQualitySetting) {
            // ローカルストレージに値を保存
            dispatch(playerActions.allMobileQuality(allQualitySetting));
            // 詳細画質設定が「自動」に設定されている、かつ通信がモバイルの場合に選択した画質を適用する
            if (detailQuality >= 10 && !isWifi) {
                changeAutoQuality(allQualitySetting);
            }
        }
    };

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    return (
        <div onContextMenu={(event) => handleContextMenu(event)} ref={props.domRef}>
            <div className="c-modal is-open">
                <div className="c-modal__overlay"></div>
                <div className="c-modal__quality c-quality" ref={dialogRef}>
                    <div className="c-quality__inner js-modalScrollCont">
                        <div className="c-closeModalBtn c-modal__close"><img src="/assets/images/icon-close.png" alt="" onClick={() => { close() }} /></div>
                        <div className="c-quality__present">
                            <div className="c-quality__present__inner">
                                <div className="c-quality__txt">現在の動画の画質</div>
                                <div className="c-quality__present__list c-qualityList">
                                    {detailImageQuality()}
                                    <div className="c-qualityList__note">
                                        上の設定は現在の動画のみに適応されます。<br></br>自動設定はできるだけ高画質で配信します。</div>
                                </div>
                            </div>
                        </div>
                        <div className="c-quality__basis">
                            <div className="c-quality__basis__inner">
                                <div className="c-quality__txt">基本の画質設定</div>
                                {allImageQuality()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ImageQualityPopup;
