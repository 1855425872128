import React, { useState } from "react";
import { useSelector } from "react-redux";

import * as plan from "../../../constants/plan";
import * as contentsKey from "../../../constants/contentsKey";
import useFavorite from "../../../hooks/useFavorite";
import ShareButton from "../../dialog/ShareButton";

/**
 * お気に入り・シェアボタン.
 */
const FavoriteShareButton = (props) => {
    /** シェアボタンダイアログ開閉 */
    const [isShare, setIsShare] = useState(false);

    /** お気に入り機能実装用フック. */
    const [favRef, handleFav] = useFavorite(props.content.crid);

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);

    /**
     * お気に入り・シェアボタンの表示/非表示.
     */
    const favoriteShareButton = () => {
        const favoriteButton = () => {
            return (
                <div
                className="c-serviceBtn__item c-serviceBtn__item--fav"
                onClick={() => handleFav()}
                ref={favRef}
            >
                <div className="c-serviceBtn__icon">
                    <div className="c-picIconPlus"></div>
                    <div className="c-picIconHeart"></div>
                </div>
            </div>
            );
        }

        // お気に入りボタンとシェアボタン両方表示.
        if (props.content[contentsKey.FAVORITE_BUTTON] && props.content[contentsKey.SNS_BUTTON] && !plan.isNone(memberPlan)) {
            return (
                <>
                    {/* お気に入りボタン. */}
                    {favoriteButton()}
                    {/* シェアボタン. */}
                    <div className="c-serviceBtn__item c-serviceBtn__item--share" onClick={() => setIsShare(true)}>
                        <div className="c-serviceBtn__icon">
                            <div className="c-picIconShare" />
                        </div>
                    </div>
                </>
            );
        }
        // お気に入りボタンのみ表示.
        else if (props.content[contentsKey.FAVORITE_BUTTON] && !plan.isNone(memberPlan)) {
            return favoriteButton();
        }
        // シェアボタンのみ表示.
        else if (props.content[contentsKey.SNS_BUTTON]) {
            return (
                <div className="c-serviceBtn__item c-serviceBtn__item--share" onClick={() => setIsShare(true)}>
                    <div className="c-serviceBtn__icon">
                        <div className="c-picIconShare" />
                    </div>
                </div>
            );
        }
        // 両方非表示.
        else {
            return null;
        }
    };

    return (
        <>
            {isShare && <ShareButton close={() => setIsShare(false)}/>}
            <div className="c-serviceBtn__btnWrap">
                {favoriteShareButton()}
            </div>
        </>
    );
};

export default FavoriteShareButton;