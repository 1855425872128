import { makeStyles } from "@material-ui/core/styles";

export const styles = makeStyles(theme => ({
    videoContainer: {
        backgroundColor: "black",
        position: "relative",
    },
    videoBtn: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        cursor: "pointer",
        zIndex: 1
    },
    videoWrapper: {
        width: "100%",
        height: "100%",
        textAlign: "center"
    },
    video: {
        width: "100%",
        display: "block"
    },
    fullscreenVideo: {
        height: "100vh",
    },
    spVideoTitle: {
        position: "absolute",
        top: "5%",
        left: "5%",
        zIndex: 1,
        height: 47,
        width: "90%",
        boxSizing: "border-box",
        fontWeight: "bold",
        fontSize: 14,
        color: "white",
        wordBreak: "break-all"
    },
    pcVideoTitle: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: 1,
        paddingTop: 9,
        paddingLeft: 13,
        height: 47,
        width: "100%",
        boxSizing: "border-box",
        fontWeight: "bold",
        fontSize: 14,
        color: "white",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        wordBreak: "break-all"
    },
    videoControls: {
        backgroundColor: "black",
        width: "100%",
        position: "absolute",
        bottom: 0,
        left: 0,
        zIndex: 4,
        opacity: 0.75
    },
    seekBox: {
        width: "100%",
        height: 3,
        position: "relative"
    },
    seekBuffer: props => ({
        backgroundColor: "#A2A2A2",
        width: props.bufferWidth,
        height: 3,
        position: "absolute",
        top: -2,
        left: 0
    }),
    videoBottom: {
        height: 38,
        verticalAlign: "middle",
        display: "flex"
    },
    pcPreviousBtn: props => ({
        color: "white",
        cursor: "pointer",
        marginLeft: 30,
        marginTop: "auto",
        marginBottom: "auto",
        display: props.skipPrevDisplayed,
        width: 15,
        height: "auto"
    }),
    pcPlayPauseBtn: {
        color: "white",
        cursor: "pointer",
        marginLeft: 30,
        marginTop: "auto",
        marginBottom: "auto",
        width: 15,
        height: "auto"
    },
    pcNextBtn: props => ({
        color: "white",
        cursor: "pointer",
        marginLeft: 30,
        marginTop: "auto",
        marginBottom: "auto",
        display: props.skipNextDisplayed,
        width: 15,
        height: "auto"
    }),
    pcPlayTime: {
        color: "white",
        fontSize: 12,
        marginLeft: 20,
        marginTop: "auto",
        marginBottom: "auto"
    },
    spPlayTime: {
        color: "white",
        padding: 13,
        fontSize: 10,
        marginLeft: "1%",
        marginRight: "1%"
    },
    volumeIcon: {
        cursor: "pointer",
        color: "white",
        marginLeft: 30,
        marginTop: "auto",
        marginBottom: "auto",
        width: 22,
        height: "auto"
    },
    volumeSlider: {
        cursor: "pointer",
        width: 50,
        marginTop: "auto",
        marginBottom: "auto",
        marginLeft: "1.5%"
    },
    pcFullscreenIcon: {
        color: "white",
        position: "absolute",
        top: 10,
        right: "2%",
        cursor: "pointer",
        width: 18,
        height: "auto"
    },
    spFullscreenIcon: {
        color: "white",
        margin: "0 4%",
        marginLeft: 20,
        cursor: "pointer",
        width: 15,
        height: "auto"
    },
    spLiveFullscreenIcon: {
        color: "white",
        position: "absolute",
        top: 14,
        right: "4%",
        cursor: "pointer",
        width: 15,
        height: "auto"
    },
    pcSeekRoot: {
        backgroundColor: "transparent",
        position: "absolute",
        top: 0,
        left: 0,
        transform: "translateY(-50%)",
        cursor: "pointer",
        zIndex: 3
    },
    spSeekRoot: {
        backgroundColor: "transparent",
        transform: "translateY(105%)",
        flex: 1
    },
    spVideoBtn: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2,
        display: "flex",
        justifyContent: "space-between",
        width: "50%"
    },
    spPreviousBtn: props => ({
        color: "white",
        cursor: "pointer",
        visibility: props.skipPrevDisplayed,
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "10%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    }),
    spPlayPauseBtn: {
        color: "white",
        cursor: "pointer",
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    spNextBtn: props => ({
        color: "white",
        cursor: "pointer",
        visibility: props.skipNextDisplayed,
        width: "7%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "90%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    }),
    spPlayControlBackground: props =>({
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1,
        backgroundColor: props.videoBackground ,
        pointerEvents: "none"
    }),
    loading: {
        position: "absolute",
        top: "75%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)"
    },
    pc10FrontBtn: {
        color: "white",
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        left: "30%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    pc10DelayBtn: {
        color: "white",
        cursor: "pointer",
        position: "absolute",
        top: "50%",
        left: "70%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    sp10FrontBtn: {
        color: "white",
        cursor: "pointer",
        width: "10%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "30%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    sp10DelayBtn: {
        color: "white",
        cursor: "pointer",
        width: "10%",
        height: "auto",
        position: "absolute",
        top: "50%",
        left: "70%",
        transform: "translate(-50%, -50%)",
        "-webkit-transform": "translate(-50%, -50%)",
        zIndex: 2
    },
    castIcon: {
        color: "white",
        cursor: "pointer",
        position: "absolute",
        top: "6%",
        right: "15%",
        zIndex: 2,
        width: "5%",
        minWidth: 30,
        height: "auto"
    },
    completeWatchBackground: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },
    pcImageQualityBtn: {
        color: "white",
        position: "absolute",
        top: 10,
        right: "2%",
        marginRight: 40,
        cursor: "pointer",
        width: 20,
        height: "auto"
    },
    spImageQualityBtn: {
        color: "white",
        cursor: "pointer",
        position: "absolute",
        top: "5%",
        right: "3%",
        zIndex: 2,
        width: "5%",
        minWidth: 30,
        height: "auto"
    },
}));
