import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Parallax from "react-rellax";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { OPERATION_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * 操作ガイド画面.
 */
const Operation = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.operation());

        // head設定.
        setHeadDefault(OPERATION_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo">
                        <img src="./assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination"/>
                    </div>
                    <div className="c-static__box c-static__box--operation">
                        <div className="c-static__boxTop">
                            <div className="c-static__boxTopTtl">操作ガイド</div>
                        </div>
                        <ul className="c-static__wrap c-static__wrap--operation">
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-001-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-001-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-002-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-002-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-003-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-003-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-004-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-004-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-005-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-005-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-006-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-006-sp.jpg" alt="" /></div>
                            </li>
                            <li className="c-static__image">
                                <div className="c-static__image__pc"><img src="./assets/images/img-guide-007-pc.jpg" alt="" /></div>
                                <div className="c-static__image__sp"><img src="./assets/images/img-guide-007-sp.jpg" alt="" /></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Operation;
