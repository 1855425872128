import React, { useEffect, useRef } from "react";

import * as touchTypes from "./touchTypes";
import { styles } from "./styles";

/**
 * タッチ操作の処理.
 *
 * props
 *  -> getZoom     ズーム関数.
 *  -> touchAction タッチ操作関数.
 */
const Touch = (props) => {
    /** CSS. */
    const classes = styles();

    /** タッチエリア DOM. */
    const touchRef = useRef(null);
    /** タッチ操作. */
    const action = useRef(null);
    /** スワイプ向き判定用の閾値. */
    const directionBase = [-2.08, -0.78, 0.78, 2.08];

    /** スワイプ向き. */
    /** 左. */
    const DIRECTION_LEFT = "0";
    /** 上. */
    // const DIRECTION_UP = "1";
    /** 右. */
    const DIRECTION_RIGHT = "2";
    /** 下. */
    // const DIRECTION_DOWN = "3";
    /** 操作なし. */
    const DIRECTION_NUTRAL = "4";

    /** スワイプ向き 指1. */
    const dir1 = useRef(DIRECTION_NUTRAL);
    /** スワイプ向き 指2. */
    const dir2 = useRef(DIRECTION_NUTRAL);
    /** タッチ開始地点. */
    const startPoint = useRef(null);
    /** タッチ移動地点. */
    const movePoint = useRef(null);
    /** タッチ動作判定用変数. */
    const d0 = useRef();
    const d1 = useRef();

    /**
     * タッチ開始.
     */
    const touchStart = (e) => {
        startPoint.current = e;
        dir1.current = DIRECTION_NUTRAL;
        dir2.current = DIRECTION_NUTRAL;
        action.current = null;
    }

    /**
     * タッチ移動.
     */
    const touchMove = (e) => {
        // 移動、ページ送り判定 閾値.
        const zoomThr = 1.01;

        movePoint.current = e;
        var tilt1 = getTiltAtoB(startPoint.current.touches[0], movePoint.current.touches[0]);
        var tilt2 = getTiltAtoB(startPoint.current.touches[1], movePoint.current.touches[1]);
        dir1.current = getDirection(tilt1);
        dir2.current = getDirection(tilt2);
        if (movePoint.current.touches[0] && !movePoint.current.touches[1]) {
            if (props.getZoom() > zoomThr) {
                var dx = movePoint.current.touches[0].pageX - startPoint.current.touches[0].pageX;
                var dy = movePoint.current.touches[0].pageY - startPoint.current.touches[0].pageY;

                props.touchAction({
                    action: touchTypes.TOUCH_ACTION_MOVE,
                    dx: dx,
                    dy: dy
                });

                startPoint.current = e;
            }
        } else if (movePoint.current.touches[0] && movePoint.current.touches[1]) {
            if (action.current !== touchTypes.TOUCH_ACTION_PINTING) {
                action.current = touchTypes.TOUCH_ACTION_PINTING;

                d0.current = Math.sqrt(
                    Math.pow(movePoint.current.touches[1].pageX - movePoint.current.touches[0].pageX, 2) +
                    Math.pow(movePoint.current.touches[1].pageY - movePoint.current.touches[0].pageY, 2)
                );
            } else if (action.current === touchTypes.TOUCH_ACTION_PINTING) {
                let sco = touchRef.current.getBoundingClientRect();
                d1.current = Math.sqrt(
                    Math.pow(movePoint.current.touches[1].pageX - movePoint.current.touches[0].pageX, 2) +
                    Math.pow(movePoint.current.touches[1].pageY - movePoint.current.touches[0].pageY, 2)
                );

                var zoom = (d1.current / d0.current) - 1;

                var scaleOriginX = (parseInt(movePoint.current.touches[0].pageX) + parseInt(movePoint.current.touches[1].pageX)) / 2 - (window.pageXOffset + sco.left);
                var scaleOriginY = (parseInt(movePoint.current.touches[0].pageY) + parseInt(movePoint.current.touches[1].pageY)) / 2 - (window.pageYOffset + sco.top);

                props.touchAction({
                    action: (d1.current - d0.current) >= 0 ? touchTypes.TOUCH_ACTION_PINT_OUT : touchTypes.TOUCH_ACTION_PINT_IN ,
                    params: {
                        zoom: zoom,
                        originX: scaleOriginX,
                        originY: scaleOriginY,
                    }
                });

                d0.current = Math.sqrt(
                    Math.pow(movePoint.current.touches[1].pageX - movePoint.current.touches[0].pageX, 2) +
                    Math.pow(movePoint.current.touches[1].pageY - movePoint.current.touches[0].pageY, 2)
                );
            }
        }
    }

    /**
     * タッチ終了.
     */
    const touchEnd = (e) => {
        // ページ送り操作判定 閾値.
        const sendThr = 100;
        if (!action.current) {
            if (dir1.current === DIRECTION_RIGHT && dir2.current === DIRECTION_NUTRAL) {
                // 初期地点から一定量以上動いた場合に動作させる.
                if (getDistance(startPoint.current.touches[0], movePoint.current.touches[0]) > sendThr) {
                    action.current = touchTypes.TOUCH_ACTION_SEND_LEFT;
                }
            } else if (dir1.current === DIRECTION_LEFT && dir2.current === DIRECTION_NUTRAL) {
                // 初期地点から一定量以上動いた場合に動作させる.
                if (getDistance(startPoint.current.touches[0], movePoint.current.touches[0]) > sendThr) {
                    action.current = touchTypes.TOUCH_ACTION_SEND_RIGHT;
                }
            }
            props.touchAction({
                action: action.current
            });
        }
    }

    /**
     * 2点間の傾き.
     */
    const getTiltAtoB = (from, to) => {
        if (!from || !to) return null;
        return Math.atan2(to.pageY - from.pageY, to.pageX - from.pageX);
    }

    /**
     * 傾きから向きを判定.
     * 傾きがnullの場合は操作なし（NUTRAL）として返却.
     */
    const getDirection = (tilt) => {
        if (!tilt) return DIRECTION_NUTRAL;
        for (var i in directionBase) {
            if (directionBase[i] > tilt) return i;
        }
        return DIRECTION_LEFT;
    }

    /**
     * 2点間の距離.
     */
    const getDistance = (from, to) => {
        return Math.sqrt(Math.abs(Math.pow(from.pageX - to.pageX, 2) - Math.pow(from.pageY - to.pageY, 2)));
    }

    useEffect(() => {
        touchRef.current.addEventListener("touchstart", (e) => {touchStart(e)});
        touchRef.current.addEventListener("touchmove", (e) => {touchMove(e)});
        touchRef.current.addEventListener("touchend", (e) => {touchEnd(e)});
        // eslint-disable-next-line
    }, []);

    return (
        <div ref={touchRef} className={classes.touchController}></div>
    );
};

export default Touch;
