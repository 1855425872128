/** タイトルタグ部品： ウルトラサブスク. */
export const ULTRA_SUBSC = " | ウルトラサブスク";

/** タイトルタグ部品： 動画見放題. */
export const UNLIMITED_STREAMING = " 動画見放題";

/** タイトルタグ： TOP画面. */
export const TOP_TITLE = "TSUBURAYA IMAGINATION" + ULTRA_SUBSC + UNLIMITED_STREAMING;

/** タイトルタグ： 検索一覧画面. */
export const SEARCH_TITLE = "検索" + ULTRA_SUBSC;

/** タイトルタグ： プラネット画面. */
export const PLANET_TITLE = "各種コンテンツ" + ULTRA_SUBSC;

/** タイトルタグ： プラネット別一覧画面、定義ファイル取得失敗. */
export const PLANET_ID_FAILURE_TITLE = "プラネット" + ULTRA_SUBSC;

/** タイトルタグ： ライブラリ一覧画面、シリーズタイトル取得失敗. */
export const LIBRARY_ID_FAILURE_TITLE = "シリーズ全話" + ULTRA_SUBSC;

/** タイトルタグ： サービス紹介画面. */
export const LOGGED_TITLE = "サービス内容について" + ULTRA_SUBSC + UNLIMITED_STREAMING;

/** タイトルタグ： イマジネーションとは画面. */
export const IMAGINATION_TITLE = "TSUBURAYA IMAGINATIONとは" + ULTRA_SUBSC;

/** タイトルタグ： 操作ガイド画面. */
export const OPERATION_TITLE = "操作ガイド" + ULTRA_SUBSC;

/** タイトルタグ： FAQ/問い合わせ画面. */
export const FAQ_TITLE = "FAQ / 問い合わせ" + ULTRA_SUBSC;

/** タイトルタグ： 会員規約画面. */
export const TREM_TITLE = "TSUBURAYA IMAGINATION会員規約" + ULTRA_SUBSC;

/** タイトルタグ： プライバシーポリシー画面. */
export const PRIVACY_TITLE = "プライバシーポリシーについて" + ULTRA_SUBSC;

/** タイトルタグ： 推奨環境画面. */
export const ENVIRONMENT_TITLE = "TSUBURAYA IMAGINATION推奨環境" + ULTRA_SUBSC;

/** タイトルタグ： 特定商法取引法画面. */
export const TRANSACTION_TITLE = "特定商法取引法に基づく表記" + ULTRA_SUBSC;

/** タイトルタグ： 404エラー画面. */
export const DEFAULT_TITLE = "お探しのぺ―ジが見つかりませんでした" + ULTRA_SUBSC;

/** タイトルタグ： データキューブの楽しみ方画面. */
export const HOW_TO_CUBE_TITLE = "データキューブの楽しみ方" + ULTRA_SUBSC;

/** タイトルタグ： ログインLP. */
export const LOGIN_LP_TITLE = "ログイン方法について" + ULTRA_SUBSC;

/** タイトルタグ： プランLP. */
export const PLAN_LP_TITLE = "プラン変更方法について" + ULTRA_SUBSC;

/** タイトルタグ： TVOD LP. */
export const TVOD_LP_TITLE = "レンタル方法について" + ULTRA_SUBSC;

/** タイトルタグ： 3分岐画面. */
export const BRANCH_TITLE = "現在のご利用状況について" + ULTRA_SUBSC;

/** タイトルタグ： プライバシーポリシー画面（アプリ）. */
export const APP_PRIVACY_TITLE = "アプリケーション・プライバシーポリシー" + ULTRA_SUBSC;