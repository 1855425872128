import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import * as historys from "../../../historys";
import * as screenActions from "../../../common/status/screen/actions";
import * as actions from "./actions";
import * as playerActions from "../../player/actions";
import { loading, updateConectionError } from "../../../common/status/actions";
import { openPopupPlanSelect } from "../../../common/popup/plan/actions";
import * as contentif from "../../../middleware/contentif";
import * as deliveryheif from "../../../middleware/deliveryheif";
import * as fileApi from "../../../middleware/file";
import * as recommendif from "../../../middleware/recommendif";
import * as mediaType from "../../../constants/mediaType";
import * as plan from "../../../constants/plan";
import * as apiUtil from "../../../constants/apiUtil";
import * as contentsKey from "../../../constants/contentsKey";
import * as libraryId from "../../../constants/libraryId";
import * as app from "../../../constants/app";
import * as cv from "../../../constants/cv";
import * as detailQualityActions from "../../../common/popup/imageQuality/actions";
import * as constans from "../../../constants/imageQuality";
import * as playerConstants from "../../../constants/player";
import * as recommendConstants from "../../../constants/recommend";

import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import Default from "../../contents/default/Default";
import PlanetContents from "../../contents/planet/Planet";
import Player from "../../player/Player";
import Separator from "../../parts/separator/Separator";
import Warning from "../../dialog/Warning";

import styles from "./styles";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { decode } from "js-base64";
import { setHeadMetaVideo } from "../../../constants/sns";
import FavoriteShareButton from "./FavoriteShareButton";
import useRecommendCookie from "../../../hooks/useRecommendCookie";
SwiperCore.use([Navigation]);

/**
 * ライブ配信画面.
 */
const LiveId = () => {
    /** CSS. */
    const classes = styles();
    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** コンテンツ詳細. */
    const detailRef = useRef();

    /** コンテンツID. */
    const [id, setId] = useState("");
    /** コンテンツ詳細の表示/非表示. */
    const [isdetail, setIsDetail] = useState(false);
    /** メディア再生可能かどうか. */
    const [canPlay, setCanPlay] = useState(false);
    /** 視聴開始API失敗ダイアログ. */
    const [startWatchFailure, setStartWatchFailure] = useState({
        status: false,
        title: "",
        content: ""
    });
    /** シリーズコンテンツリスト（自分以外）. */
    const [seriesContents, setSeriesContents] = useState([]);
    /** プラネットアイコン. */
    const [planets, setPlanets] = useState([]);
    /** ポーリングAPI失敗ダイアログ. */
    const [pollingFailure, setPollingFailure] = useState(false);

    /** コンテンツリスト. */
    const contentsList = useSelector(state => state.VideoId.contentsList);
    /** あなたへのおすすめコンテンツリスト. */
    const recommendContentsList = useSelector(state => state.VideoId.recommendContentsList);
    /** コンテンツ情報. */
    const content = useSelector(state => state.VideoId.content);
    /** 再生識別文字列. */
    const playToken = useSelector(state => state.VideoId.playToken);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** プラネットリスト. */
    const planetList = useSelector(state => state.Member.planetList);
    /** Cast状態. */
    const castState = useSelector(state => state.Cast.castState);
    /** Castデバイス名. */
    const castDeviceName = useSelector(state => state.Cast.castDeviceName);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    // 画質全体設定（ブラウザ版）
    const allQuality = useSelector(state => state.Player.allQuality);
    // 画質全体設定（アプリ版/Wi-FI）
    const allWifiQuality = useSelector(state => state.Player.allWifiQuality);
    // 画質全体設定（アプリ版/モバイル)
    const allMobileQuality = useSelector(state => state.Player.allMobileQuality);
    /** Wi-Fi/モバイル判定 */
    const isWifi = useSelector(state => state.statusQuality.isWifi);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.VideoId.clickData);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /** 所属プラネットが無い場合のランキングの表示数 */
    const RANKING_DISPLAY = 8;

    /**
     * ID検索API失敗処理.
     */
    const postIdSearchFailure = useCallback(() => {
        historys.historyNotFound(history);
    }, [history]);

    const recommendRequestFailure = useCallback((err) => {
        dispatch(actions.postSearchRecommendSuccess([], ""));
    }, [dispatch]);

    const recommendRequestSuccess = useCallback((data) => {
        const contentList = data[recommendif.CONTENT_LIST];
        const clickData = data[recommendif.CLICK_DATA];

        dispatch(actions.postSearchRecommendSuccess(contentList, clickData));
    }, [dispatch]);

    /**
     * ID検索API成功処理.
     */
    const postIdSearchSuccess = useCallback((body) => {
        // ID検索API成功処理.

        // レスポンスが空の時は取得失敗.
        if (body.length === 0) {
            postIdSearchFailure();
            return;
        }

        const response = body[0];
        dispatch(actions.postIdSearchSuccess(response));

        // head設定.
        setHeadMetaVideo(response);

        if (response.parentCrid) {
            // シリーズ一覧取得.
            const seriesBody = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.PARENT_CRID]: response.parentCrid,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            }
            contentif.postSearch(seriesBody)
            .then((res) => {
                dispatch(actions.postSearchSeriesSuccess(res));
            });
        }

        // レコメンド取得
        if (recommendConstants.getVideoSpec(memberPlan) !== ""){
            const recommendBody = {
                [recommendif.CRID]: response.crid,
                [recommendif.SPEC]: recommendConstants.getVideoSpec(memberPlan),
                [recommendif.NUM]: recommendif.VIDEO_RECOMMEND,
                [recommendif.RECOMMENDCOOKIE]: recommendCookie,
                [recommendif.PLATFORM]: contentif.getRequestPlatform()
            };
            recommendif.postRecommendRequest(recommendBody).then(recommendRequestSuccess, recommendRequestFailure);
        }
        // レコメンド代替取得（所属プラネットが1つ以上ある場合）
        else if (response.planetId?.length) {
            const recommendBody = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.PLANET_ID]: response.planetId,
                [contentif.ROW]: contentif.ROW_RECOMMEND,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            }
            contentif.postSearch(recommendBody)
            .then((res) => {
                dispatch(actions.postSearchRecommendSuccess(res, ""));
            });
        } else {
            // レコメンド代替取得（所属プラネットが無い場合はランキング取得）
            fileApi.getRankingMeta().then((res) =>
                dispatch(actions.postSearchRecommendSuccess(res.slice(0, RANKING_DISPLAY), ""))
            );
        }

        // ファイル：プラネット定義取得.
        if (!Object.keys(planetList).length) {
            fileApi.getPlanet().then(fileApi.getPlanetSuccess.bind(this, dispatch));
        }
    }, [dispatch, memberPlan, postIdSearchFailure]);

    /**
     * 視聴開始API成功処理.
     */
    const postStartWatchSuccess = useCallback((body) => {
        // FAIR_PLAYの場合は、画質調整のため、playUrlを変更する.
        if (body?.[deliveryheif.PLAY_LIST]?.[0]?.[deliveryheif.DRM_MODE] === playerConstants.FAIR_PLAY) {
            // データセーバー設定がされていれば適用する.
            if (app.isAllApp() && app.isLatestApp()) {
                if ((isWifi && allWifiQuality) || (!isWifi && allMobileQuality)) {
                    body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getLiveFairPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], "DATASAVER");
                }
            }
            else {
                if (allQuality){
                    body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getLiveFairPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], "DATASAVER");
                }
            }
        }
        dispatch(actions.postStartWatchSuccess(body));
        setCanPlay(true);
    }, [dispatch, allQuality, allWifiQuality, allMobileQuality, isWifi]);

    /**
     * 視聴開始API失敗処理.
     */
    const postStartWatchFailure = useCallback((err) => {
        // 視聴開始API失敗処理.

        // セッションエラーを確認.
        deliveryheif.isSessionError(dispatch, err);

        let title = "";
        let message = "";
        const errCode = err[apiUtil.ERROR_CODE] ? err[apiUtil.ERROR_CODE].slice(-3) : "";

        switch(errCode) {
            case deliveryheif.OVERSEAS_ERROR_CODE: // 国内判定エラー.
                title = "海外エラー";
                message = "日本国外ではご利用いただけません。";
                break;
            case deliveryheif.NO_CONTRACT_ERROR_CODE: // 未契約エラー.
            case deliveryheif.CONTRACT_ERROR_CODE: // 契約エラー.
                title = "視聴エラー";
                message = "お客様の契約では視聴できません。";
                break;
            case deliveryheif.CONTENT_ERROR_CODE: // 配信コンテンツエラー.
                title = "配信停止中";
                message = "配信期間外または停止中のコンテンツです。";
                break;
            case deliveryheif.VIEWING_CTL_ERROR_CODE: // 同時視聴エラー.
                title = "同時視聴エラー";
                message = "同時に視聴できる端末数が上限に達しています。";
                break;
            default: // 上記以外のエラーは通信エラー.
                dispatch(updateConectionError(true));
                return;
        }
        setStartWatchFailure({
            status: true,
            title: title,
            content: message
        });
    }, [dispatch]);

    /**
     * ポーリングAPI失敗処理.
     */
    const postPollingFailure = useCallback((err) => {
        // セッションエラーを確認.
        deliveryheif.isSessionError(dispatch, err);

        // 有効なプレイトークンが存在しない時、動画再生を停止する.
        if (err[apiUtil.ERROR_CODE] === deliveryheif.EXPIRED_PLAY_TOKEN_ERROR_CODE) {
            setPollingFailure(true);
            setCanPlay(false);
        }
    }, [dispatch]);

    /**
     * ID検索.
     */
    const idSearch = useCallback((contentId) => {
        const body = {
            [contentif.CONTENT_ID_LIST]: [contentId],
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(body).then(postIdSearchSuccess, postIdSearchFailure);
    }, [postIdSearchSuccess, postIdSearchFailure]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        if (canPlay) setCanPlay(false);
        const splitPathname = location.pathname.split("/");
        const liveId = splitPathname[splitPathname.length - 1];

        // URLデコードとbase64デコードを行う.
        const contentId = decode(decodeURIComponent(liveId));
        setId(contentId);

        dispatch(screenActions.liveId());
        // 画質設定
        dispatch(playerActions.changeIsImageQuality(false));

        idSearch(contentId);

        return () => dispatch(actions.initVideoId());
        // eslint-disable-next-line
    }, [dispatch, location, idSearch]);

    // コンテンツ情報のGA連携
    useEffect(() => {
        if (id && content.title !== "" && content.title !== undefined) {
            cv.sendContentInfo(
                "/live/" + historys.getEncodeId(id),
                content.title,
                memberId
            );
        }
    }, [content.title, id, memberId]);

    /**
     * 関連プラネットを取得.
     */
    useEffect(() => {
        if (Object.keys(planetList).length && content[contentsKey.PLANET_ID] && content[contentsKey.PLANET_ID].length !== 0) {
            let list = [];
            for (const pltId of content[contentsKey.PLANET_ID]) {
                for (const plt of planetList) {
                    if (plt[contentsKey.PLANET_ID] === pltId) list.push(plt);
                }
            }
            setPlanets(list);
        }
    }, [content, planetList]);

    /**
     * シリーズコンテンツを自分以外にする.
     */
    useEffect(() => {
        if (contentsList.length !== 0) {
            let list = [];
            for (const cnt of contentsList) {
                if (cnt[contentsKey.CRID] !== content[contentsKey.CRID]) list.push(cnt);
            }
            setSeriesContents(list);
        }
    }, [content, contentsList]);

    /**
     * 続きを読むを押下.
     */
    const handleReadmore = () => {
        // 要素の取得に失敗.
        if (detailRef.current === null) return;

        if (isdetail) {
            detailRef.current.classList.remove("js-open");
            setIsDetail(false);
        } else {
            detailRef.current.classList.add("js-open");
            setIsDetail(true);
        }
    };

    /**
     * キャスト中はWeb上のプレイヤーは再生しない.
     */
    useEffect(() => {
        if (castState === id) {
            setCanPlay(false);
        }
    }, [castState, id]);


    /**
     * キャスト用URLスキーム.
     */
    const handleCast = () => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
            return;
        }
        if (!checkPlay()) return;

        let customUrlScheme = app.urlScheme() + app.URL_SCHEME_PLAY_CAST;
        // crid設定.
        customUrlScheme += "?crid=" + content[contentsKey.CRID] + "&startPoint=0";
        // スキーム設定.
        window.location.href = customUrlScheme;
    };

    /**
     * 動画再生ボタン押下時の処理.
     */
    const play = () => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
            return;
        }
        if (!checkPlay()) return;

        // キャスト状態が空じゃないとき、キャストを呼び出す.
        if(castState){
            handleCast();
            return;
        }

        // リクエストボディ作成.
        const body = {
            [deliveryheif.AVAIL_STATUS]: content.availStatus,
            [deliveryheif.CONTENT_LIST]: [
                {
                    [deliveryheif.KIND]: deliveryheif.KIND_MAIN,
                    [deliveryheif.CRID]: id,
                    [deliveryheif.SERVICE_ID]: content.cid,
                    [deliveryheif.LID]: memberPlan
                }
            ]
        };

        // 詳細画質の初期値設定
        if (content.videoDifinition === "SD"){
            dispatch(detailQualityActions.detailQuality(constans.SD_AUTO));
        }
        else {
            dispatch(detailQualityActions.detailQuality(constans.HD_AUTO));
        }

        // iOSアプリネイティブ再生用のソース.
        if (false) {
            let customUrlScheme = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_VIDEO_PLAY;
            // 視聴開始のリクエストボディ設定.
            customUrlScheme += "startwatch=" + JSON.stringify(body);
            // 動画タイトルを設定.
            customUrlScheme += "&title=" + content.title + (!content.epiTitle ? "" : " / " + content.epiTitle);
            // スキーム設定.
            window.location.href = customUrlScheme;
        }
        else {
            dispatch(loading(true));
            // 視聴開始API呼び出し.
            deliveryheif.postStartWatch(body)
            .then(postStartWatchSuccess, postStartWatchFailure)
            .finally(() => dispatch(loading(false)));
        }
    };

    /**
     * 再生可能な条件を満たしているかチェックする.
     */
    const checkPlay = () => {
        // 非会員は閲覧不可.
        if (plan.isNone(memberPlan)) {
            return false;
        }
        // 無料公開中 or 会員プラン充足.
        else if (content.isFree || content.planList?.includes(memberPlan)) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * ポーリングAPI.
     */
    const polling = () => {
        deliveryheif.postPolling({ [deliveryheif.PLAY_TOKEN]: playToken })
        .then(()=>{}, postPollingFailure);
    };

    /**
     * 視聴終了API.
     */
    const endWatch = (duration, stopPosition) => {
        const body = {
            [deliveryheif.PLAY_TOKEN]: playToken
        };

        // 視聴終了API呼び出し.
        deliveryheif.postEndWatch(body).then(() => {}, (err) => {
            // セッションエラーを確認.
            deliveryheif.isSessionError(dispatch, err);
        });
    };

    /**
     * 同期視聴終了API.
     */
    async function endAwaitWatch(duration, stopPosition) {
        const body = {
            [deliveryheif.PLAY_TOKEN]: playToken,
        };

        // 同期視聴終了API呼び出し.
        deliveryheif.postAwaitEndWatch(body);
    };

    const upgradePlan = (afterPlan) => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
        }
        // 有料プランにアップグレードの場合は、プラン選択画面に遷移.
        else {
            // アプリの場合、プランLPに遷移.
            if (app.isAllApp()) {
                historys.historyAppPlan(history);
            }
            else {
                dispatch(openPopupPlanSelect(false, plan.isPremium(afterPlan)));
            }
        }
    };

    const upgradeButton = () => {
        // アップグレードボタン表示.
        const upgradePlanButton = () => {
            // 非会員の場合.
            if (plan.isNone(memberPlan)) {
                // 無料プランで閲覧可能.
                if (content.isFree || content.planList?.includes(plan.FREE)) {
                    return (<div className="c-playerRank__upgrade" onClick={() => upgradePlan(plan.FREE)}>無料で観る</div>)
                }
                // 有料プランで閲覧可能.
                else {
                    // 対象プラン取得（スタンダード以上 or プレミアムのみ）.
                    const requiredPlan = content.planList?.includes(plan.STANDARD) ? plan.STANDARD : plan.PREMIUM;

                    return (
                        <div className="c-playerRank__upgrade" onClick={() => upgradePlan(requiredPlan)}>{plan.EN_TO_JP[requiredPlan]}に登録して見放題</div>
                    );
                }
            }
            // ログインユーザの場合.
            else{
                // 対象プラン取得（スタンダード以上 or プレミアムのみ）.
                const requiredPlan = content.planList?.includes(plan.STANDARD) ? plan.STANDARD : plan.PREMIUM;

                return (
                    <div className="c-playerRank__upgrade" onClick={() => upgradePlan(requiredPlan)}>{plan.EN_TO_JP[requiredPlan]}にアップグレードで見放題</div>
                );
            }
        };

        return (
            <div className="c-playerRank">
                <div className="c-playerRank__column">
                    {upgradePlanButton()}
                </div>
            </div>
        );
    };

    /**
     * ライブラリ一覧画面に遷移.
     */
    const historylibraryId= () => {
        let list = [];
        if (recommendConstants.getVideoSpec(memberPlan) !== ""){
            list.push(libraryId.RECOMMEND);
            list.push(libraryId.VIDEO);
            list.push(id);
        }
        else {
            list.push(libraryId.PLANET);
            for (const pid of content.planetId) list.push(pid);
        }
        historys.historylibraryId(history, historys.getEncodeId(list));
    };

    /**
     * 現在時刻が配信期間内か.
     */
    const isAvailStart = () => {
        // 配信期間を取得できてない場合.
        if ((!content.availPerStart || !content.availPerEnd) && content.availPerStart !== 0) return false;

        return (content.availPerStart * 1000) <= Date.now() && Date.now() <= (content.availPerEnd * 1000);
    };

    const playButton = () => {
        if (castState === id) {
            return <img alt="" src="/images/xs/player/play_button_icon.svg" className={classes.iosPlayBtn} onClick={handleCast} />
        }
        else if (isAvailStart()) {
            // iOSアプリネイティブ再生用のソース.
            if (false){
                return (
                    <>
                        <img alt="" src="/images/xs/player/play_button_icon.svg" className={classes.iosPlayBtn} onClick={play} />
                        {app.isAllApp() && !castState && <div className="c-playerCastBtn"><div className="c-playerCastBtn__inner"><img alt="" src="/images/cast_icon.svg" onClick={handleCast}/></div></div>}
                    </>
                );
            }
            else {
                return (
                    <>
                        <img alt="" src="/images/xs/player/play_button.svg" className={classes.videoBtn} onClick={play} />
                        {app.isAllApp() && !castState && <div className="c-playerCastBtn"><div className="c-playerCastBtn__inner"><img alt="" src="/images/cast_icon.svg" onClick={handleCast}/></div></div>}
                    </>
                );
            }
        }
        else {
            return null;
        }
    };

    /*
     * プラットコンテンツ表示を返却.
     */
    const viewPlanets = () => {
        const planetsRows = [];
        for (let i = 0; i < planets.length; i = i + 3) {
            planetsRows.push (
                <div className="c-playerMore__planet">
                        {Object.entries(planets).slice(i, i + 3).map(([, value]) => (
                            <span key={value[contentif.PLANET_ID]} className={classes.planet + " p-planet__ultraSelect__item p-planet__ultraSelect__item--playerDis js-on"}>
                                <PlanetContents meta={value} isAnimation={true} delay={0} />
                            </span>
                        ))}
                </div>
            );
        }
        return (planetsRows);
    }

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            {startWatchFailure.status && <Warning input={{title: startWatchFailure.title, content: startWatchFailure.content}} onAccept={() => setStartWatchFailure({...startWatchFailure, status: false})}/>}
            {pollingFailure && <Warning input={{title: "視聴エラー", content: "エラーが発生しました。もう一度再生しなおしてください。"}} onAccept={() => setPollingFailure(false)}/>}
            <Parallax className="l-planetBg l-planetBg--planetListBg rellax" speed={2}></Parallax>
            <div className="l-main">
            <div className="c-container">
                <div className="c-player" onContextMenu={(e) => e.preventDefault()}>
                    {!canPlay && (
                        <div className={classes.thumbnail}>
                            <picture>
                                <source srcset={content.thumbnailLandscape.replace('/landscape/', '/landscape/960x540/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                    + content.thumbnailLandscape.replace('/landscape/', '/landscape/960x540/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                    type="image/webp" />
                                <img src={content.thumbnailLandscape} onError={(e) => {e.onerror = null; e.target.src = "/images/noimage.jpg"}} alt="" />
                            </picture>
                            {castState === id && castDeviceName && <div className="c-playerCastTxt" style={{zIndex: 1}}>{castDeviceName}で再生しています</div>}
                            {playButton()}
                            {(!checkPlay() || castState === id) && <div className={classes.noPlayBackground} />}
                        </div>
                    )}
                    {canPlay &&
                        <Player
                            type={mediaType.LIVE_TYPE}
                            polling={polling}
                            endWatch={endWatch}
                            endAwaitWatch={endAwaitWatch}
                            handleCast={handleCast}
                        />
                    }
                </div>
                {!checkPlay() && upgradeButton()}
                <div className="c-playerBtn">
                <div className="c-playerDetail">
                    <h1 className="c-playerDetail__ttl">{content.title + (!content.epiTitle ? "" : " / " + content.epiTitle)}</h1>
                    <input className="readmore-check" id="check1" type="checkbox" />
                    <div
                        className="c-playerDetail__txt readmore-content"
                        ref={detailRef}
                    >
                        {content.synopsisLong?.[0] ?? ""}
                        <br></br>
                        {content.creditsList?.length > 0 &&
                            Object.entries(content.creditsList).map(([, value]) => (
                                value.length >= 3 ? <>{value.split("|").slice(2, 4).join("：")}<br></br></> : null
                            ))
                        }
                        {content.copyright}
                    </div>
                    <label className="readmore-label" for="check1" onClick={() => handleReadmore()}></label>
                </div>
                <div className="c-serviceBtn">
                    <FavoriteShareButton content={content} />
                </div>
                </div>
            </div>
            <br/>
            <div className="c-playerMore">
                {(seriesContents.length > 0) &&
                    <section className="c-sect c-sect--playerMore">
                        <Separator input={{title: content.seriesTitle}} />
                        <div className="c-contCardSlideBox">
                            <div className="c-contCardSlideWrap">
                                <div className="c-contCardNoCat">
                                    <div className="c-contCardNoCat__list">
                                        <Swiper
                                            spaceBetween={matches ? 20 : 10}
                                            slidesPerView="auto"
                                            slidesPerGroup={3}
                                            navigation={{
                                                prevEl: ".swiper-button-prev",
                                                nextEl: ".swiper-button-next"
                                            }}
                                            className="c-contCardNoCatList js-movieSlide"
                                        >
                                            {Object.entries(seriesContents).map(([, value]) => (
                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                    <Default meta={value} playBack={false} />
                                                </SwiperSlide>
                                            ))}
                                            <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                            <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {(recommendContentsList.length > 0) &&
                    <section className="c-sect c-sect--playerMore">
                        <Separator input={{title: "あなたへのおすすめ"}} />
                        <div className="c-contCardSlideBox">
                            <div className="c-contCardSlideWrap">
                                <div className="c-contCardNoCat">
                                    <div className="c-contCardNoCat__list">
                                    <Swiper
                                            spaceBetween={matches ? 20 : 10}
                                            slidesPerView="auto"
                                            slidesPerGroup={3}
                                            navigation={{
                                                prevEl: ".swiper-button-prev",
                                                nextEl: ".swiper-button-next"
                                            }}
                                            className="c-contCardNoCatList js-movieSlide"
                                        >
                                            {Object.entries(recommendContentsList).map(([, value]) => (
                                                <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                    <Default meta={value} playBack={false} clickData={clickData} />
                                                </SwiperSlide>
                                            ))}
                                            {/** 所属プラネットが無い場合=ランキング表示の場合は、もっと見るボタンを表示しない */}
                                            {(recommendConstants.getVideoSpec(memberPlan) !== "" || content.planetId?.length) &&
                                                <SwiperSlide className="c-contCardNoCatList__item c-contCardNoCatList__item--more">
                                                    <span className={classes.clickable + " c-contCardNoCatList__itemMore"} onClick={() => historylibraryId()}>
                                                        <div className="c-contCardNoCatList__itemMoreTxt">もっと見る</div>
                                                    </span>
                                                </SwiperSlide>
                                            }
                                            <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                            <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                        </Swiper>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
                {viewPlanets()}
            </div>
            </div>
        </div>
    );
};

export default LiveId;