import * as actionTypes from "./actionTypes";

const initState = {
    /** クーポンコード. */
    couponCode: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** クーポンコード更新. */
        case actionTypes.UPDATE_COUPON_CODE:
            return { ...state, couponCode: action.couponCode };

        default:
            return state;
    }
};

export default reducer;