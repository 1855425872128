import React, { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import * as historys from "../../../historys";
import * as screenActions from "../../../common/status/screen/actions";
import * as actions from "./actions";
import * as playerActions from "../../player/actions";
import { openPopupPlanSelect } from "../../../common/popup/plan/actions";
import { loading, updateConectionError } from "../../../common/status/actions";
import * as contentif from "../../../middleware/contentif";
import * as contractif from "../../../middleware/contractif";
import * as deliveryheif from "../../../middleware/deliveryheif";
import * as accountif from "../../../middleware/accountif";
import * as recommendif from "../../../middleware/recommendif";
import * as fileApi from "../../../middleware/file";
import * as mediaType from "../../../constants/mediaType";
import * as plan from "../../../constants/plan";
import * as apiUtil from "../../../constants/apiUtil";
import * as contentsKey from "../../../constants/contentsKey";
import * as libraryId from "../../../constants/libraryId";
import * as unit from "../../../constants/unit";
import * as app from "../../../constants/app";
import * as error from "../../../constants/error";
import * as creditUtil from "../../../common/popup/credit/creditUtils";
import * as cv from "../../../constants/cv";
import * as detailQualityActions from "../../../common/popup/imageQuality/actions";
import * as constans from "../../../constants/imageQuality";
import * as playerConstants from "../../../constants/player";
import * as recommendConstants from "../../../constants/recommend";

import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import Default from "../../contents/default/Default";
import PlanetContents from "../../contents/planet/Planet";
import Player from "../../player/Player";
import Separator from "../../parts/separator/Separator";
import Warning from "../../dialog/Warning";

import styles from "./styles";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

import { decode } from "js-base64";
import { setHeadMetaVideo } from "../../../constants/sns";
import FavoriteShareButton from "./FavoriteShareButton";
import useRecommendCookie from "../../../hooks/useRecommendCookie";
SwiperCore.use([Navigation]);

/**
 * 動画画面.
 */
const VideoId = () => {
    /** CSS. */
    const classes = styles();
    /** 画面幅閾値 */
    const matches = useMediaQuery("(min-width: 768px)");

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** コンテンツ詳細. */
    const detailRef = useRef();
    /** ドロップメニューDOM. */
    const dropMenuRef = useRef();
    /** ドロップメニューボタンDOM. */
    const btnDropMenuRef = useRef();

    /** コンテンツID. */
    const [id, setId] = useState("");
    /** コンテンツ詳細の表示/非表示. */
    const [isdetail, setIsDetail] = useState(false);
    /** ドロップメニューの表示/非表示. */
    const [dropMenu, setDropMenu] = useState(false);
    /** メディア再生可能かどうか. */
    const [canPlay, setCanPlay] = useState(false);
    /** 視聴開始API失敗ダイアログ. */
    const [startWatchFailure, setStartWatchFailure] = useState({
        status: false,
        title: "",
        content: ""
    });
    /** レンタル契約情報. */
    const [rental, setRental] = useState(null);
    /** シリーズコンテンツリスト（自分以外）. */
    const [seriesContents, setSeriesContents] = useState([]);
    /** プラネットアイコン. */
    const [planets, setPlanets] = useState([]);
    /** ポーリングAPI失敗ダイアログ. */
    const [pollingFailure, setPollingFailure] = useState(false);
    /** 連続再生で次話遷移した際の視聴権限なしエラーダイアログ */
    const [licenseFailure, setLicenseFailure] = useState(false);
    /** TVOD購入可能プランではないユーザのエラーダイアログ */
    const [tvodPurchaseFailure, setTvodPurchaseFailure] = useState(false);

    /** コンテンツリスト. */
    const contentsList = useSelector(state => state.VideoId.contentsList);
    /** 追加シリーズコンテンツリスト(2回目リクエスト分). */
    const addSeriesContentsList = useSelector(state => state.VideoId.addSeriesContentsList);
    /** あなたへのおすすめコンテンツリスト. */
    const recommendContentsList = useSelector(state => state.VideoId.recommendContentsList);
    /** コンテンツ情報. */
    const content = useSelector(state => state.VideoId.content);
    /** 再生識別文字列. */
    const playToken = useSelector(state => state.VideoId.playToken);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** クレジットカード番号. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** 購入一覧. */
    const purchaseList = useSelector(state => state.Member.purchaseList);
    /** プラネットリスト. */
    const planetList = useSelector(state => state.Member.planetList);
    /** 特典一覧. */
    const giftList = useSelector(state => state.Member.giftList);
    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);
    /** Cast状態. */
    const castState = useSelector(state => state.Cast.castState);
    /** Castデバイス名. */
    const castDeviceName = useSelector(state => state.Cast.castDeviceName);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    // 画質詳細設定
    const detailQuality = useSelector(state => state.ImageQualityPopup.detailQuality);
    // 画質全体設定（ブラウザ版）
    const allQuality = useSelector(state => state.Player.allQuality);
    // 画質全体設定（アプリ版/Wi-FI）
    const allWifiQuality = useSelector(state => state.Player.allWifiQuality);
    // 画質全体設定（アプリ版/モバイル)
    const allMobileQuality = useSelector(state => state.Player.allMobileQuality);
    /** Wi-Fi/モバイル判定 */
    const isWifi = useSelector(state => state.statusQuality.isWifi);
    /** レコメンドクリック情報. */
    const clickData = useSelector(state => state.VideoId.clickData);

    /** レコメンド用Cookie. */
    const { recommendCookie } = useRecommendCookie();

    /** 所属プラネットが無い場合のランキングの表示数 */
    const RANKING_DISPLAY = 8;

    /**
     * ID検索API失敗処理.
     */
    const postIdSearchFailure = useCallback(() => {
        historys.historyNotFound(history);
    }, [history]);

    const recommendRequestFailure = useCallback((err) => {
        dispatch(actions.postSearchRecommendSuccess([], ""));
    }, [dispatch]);

    const recommendRequestSuccess = useCallback((data) => {
        const contentList = data[recommendif.CONTENT_LIST];
        const clickData = data[recommendif.CLICK_DATA];

        dispatch(actions.postSearchRecommendSuccess(contentList, clickData));
    }, [dispatch]);

    /**
     * ID検索API成功処理.
     */
    const postIdSearchSuccess = useCallback((body) => {
        // ID検索API成功処理.

        // レスポンスが空の時は取得失敗.
        if (body.length === 0) {
            postIdSearchFailure();
            return;
        }

        const response = body[0];
        dispatch(actions.postIdSearchSuccess(response));

        // head設定.
        setHeadMetaVideo(response);

        // シリーズ一覧取得.
        if (response.parentCrid) {
            const seriesBody = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.PARENT_CRID]: response.parentCrid,
                [contentif.ROW]: contentif.ROW_SERIES,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            }
            contentif.postSearch(seriesBody)
                .then((res) => {
                    dispatch(actions.postSearchSeriesSuccess(res));
                });
        }

        // レコメンド取得
        if (recommendConstants.getVideoSpec(memberPlan) !== ""){
            const recommendBody = {
                [recommendif.CRID]: response.crid,
                [recommendif.SPEC]: recommendConstants.getVideoSpec(memberPlan),
                [recommendif.NUM]: recommendif.VIDEO_RECOMMEND,
                [recommendif.RECOMMENDCOOKIE]: recommendCookie,
                [recommendif.PLATFORM]: contentif.getRequestPlatform()
            };
            recommendif.postRecommendRequest(recommendBody).then(recommendRequestSuccess, recommendRequestFailure);
        }
        // レコメンド代替取得（所属プラネットが1つ以上ある場合）
        else if (response.planetId?.length) {
            const recommendBody = {
                [contentif.SEARCH_TYPE]: contentif.OPTION,
                [contentif.PLANET_ID]: response.planetId,
                [contentif.ROW]: contentif.ROW_RECOMMEND,
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            }
            contentif.postSearch(recommendBody)
                .then((res) => {
                    dispatch(actions.postSearchRecommendSuccess(res, ""));
                });
        } else {
            // レコメンド代替取得（所属プラネットが無い場合はランキング取得）
            fileApi.getRankingMeta().then((res) =>
                dispatch(actions.postSearchRecommendSuccess(res.slice(0, RANKING_DISPLAY), ""))
            );
        }

        // ファイル：プラネット定義取得.
        if (!Object.keys(planetList).length) {
            fileApi.getPlanet().then(fileApi.getPlanetSuccess.bind(this, dispatch));
        }
    // eslint-disable-next-line
    }, [dispatch, memberPlan, postIdSearchFailure]);

    /**
     * 視聴開始API成功処理.
     */
    const postStartWatchSuccess = useCallback((isContinued, body) => {
        // FAIR_PLAYの場合は、画質調整のため、playUrlを変更する.
        if (body?.[deliveryheif.PLAY_LIST]?.[0]?.[deliveryheif.DRM_MODE] === playerConstants.FAIR_PLAY) {
            if (isContinued && detailQuality < 10) {
                // 連続再生時は詳細画質を優先する.
                if (content.videoDifinition === "SD") {
                    const sdDetailQuality = Math.min(detailQuality, constans.SD_QUALITY_LIST.length - 1);
                    body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], constans.SD_QUALITY_LIST[sdDetailQuality]);
                }
                else {
                    const hdDetailQuality = Math.min(detailQuality, constans.HD_QUALITY_LIST.length - 1);
                    body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], constans.HD_QUALITY_LIST[hdDetailQuality]);
                }
            }
            else {
                // データセーバー設定がされていれば適用する.
                if (app.isAllApp() && app.isLatestApp()) {
                    if ((isWifi && allWifiQuality) || (!isWifi && allMobileQuality)) {
                        body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], "DATASAVER");
                    }
                }
                else {
                    if (allQuality){
                        body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL] = playerConstants.getPlayUrl(body[deliveryheif.PLAY_LIST][0][deliveryheif.PLAY_URL], "DATASAVER");
                    }
                }
            }
        }
        dispatch(actions.postStartWatchSuccess(body));
        setCanPlay(true);
    }, [dispatch, detailQuality, allQuality, allWifiQuality, allMobileQuality, isWifi, content]);

    /**
     * 視聴開始API失敗処理.
     */
    const postStartWatchFailure = useCallback((err) => {
        // 視聴開始API失敗処理.

        // セッションエラーを確認.
        deliveryheif.isSessionError(dispatch, err);

        let title = "";
        let message = "";
        const errCode = err[apiUtil.ERROR_CODE] ? err[apiUtil.ERROR_CODE].slice(-3) : "";

        switch (errCode) {
            case deliveryheif.OVERSEAS_ERROR_CODE: // 国内判定エラー.
                title = "海外エラー";
                message = "日本国外ではご利用いただけません。";
                break;
            case deliveryheif.NO_CONTRACT_ERROR_CODE: // 未契約エラー.
            case deliveryheif.CONTRACT_ERROR_CODE: // 契約エラー.
                title = "視聴エラー";
                message = "お客様の契約では視聴できません。";
                break;
            case deliveryheif.CONTENT_ERROR_CODE: // 配信コンテンツエラー.
                title = "配信停止中";
                message = "配信期間外または停止中のコンテンツです。";
                break;
            case deliveryheif.VIEWING_CTL_ERROR_CODE: // 同時視聴エラー.
                title = "同時視聴エラー";
                message = "同時に視聴できる端末数が上限に達しています。";
                break;
            default: // 上記以外のエラーは通信エラー.
                dispatch(updateConectionError(true));
                return;
        }
        setStartWatchFailure({
            status: true,
            title: title,
            content: message
        });
    }, [dispatch]);

    /**
     * ポーリングAPI失敗処理.
     */
    const postPollingFailure = useCallback((err) => {
        // セッションエラーを確認.
        deliveryheif.isSessionError(dispatch, err);

        // 有効なプレイトークンが存在しない時、動画再生を停止する.
        if (err[apiUtil.ERROR_CODE] === deliveryheif.EXPIRED_PLAY_TOKEN_ERROR_CODE) {
            setPollingFailure(true);
            setCanPlay(false);
        }
    }, [dispatch]);

    /**
     * 視聴終了API成功処理.
     */
    const postEndWatchSuccess = useCallback((body) => {
        accountif.getResume().then(accountif.getResumeSuccess.bind(this, dispatch), accountif.isSessionError.bind(this, dispatch));
    }, [dispatch]);

    /**
     * ID検索.
     */
    const idSearch = useCallback((contentId) => {
        const body = {
            [contentif.CONTENT_ID_LIST]: [contentId],
            [contentif.PLATFORM]: contentif.getRequestPlatform()
        };
        contentif.postIdSearch(body).then(postIdSearchSuccess, postIdSearchFailure);
    }, [postIdSearchSuccess, postIdSearchFailure]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        if (canPlay) setCanPlay(false);
        const splitPathname = location.pathname.split("/");
        const videoId = splitPathname[splitPathname.length - 1];

        // URLデコードとbase64デコードを行う.
        const contentId = decode(decodeURIComponent(videoId));
        setId(contentId);

        dispatch(screenActions.videoId());
        // 画質設定
        dispatch(playerActions.changeIsImageQuality(false));

        idSearch(contentId);

        return () => dispatch(actions.initVideoId());
        // eslint-disable-next-line
    }, [dispatch, location, idSearch]);

    // コンテンツ情報のGA連携
    useEffect(() => {
        if (id && content.title !== "" && content.title !== undefined) {
            cv.sendContentInfo(
                "/video/" + historys.getEncodeId(id),
                content.title,
                memberId
            );
        }
    }, [content.title, id, memberId]);

    /**
     * コンテンツのレンタル契約・特典状況取得.
     */
    useEffect(() => {
        for (const purchase of purchaseList) {
            if (purchase[contractif.LICENSE_ID] === content.license[0]) {
                setRental(purchase);
                return;
            }
        }
        for (const gift of giftList) {
            if (gift[contractif.LICENSE_ID] === content.license[0]) {
                setRental(gift);
                return;
            }
        }
        setRental(null);
    }, [content, purchaseList, giftList]);

    /**
     * シリーズコンテンツを自分以外にする.
     */
    useEffect(() => {
        if (contentsList.length !== 0) {
            let list = [];
            for (const cnt of contentsList) {
                if (cnt[contentsKey.CRID] !== content[contentsKey.CRID]) list.push(cnt);
            }

            // 追加取得分のシリーズ一覧
            if (addSeriesContentsList.length !== 0) {
                for (const cnt of addSeriesContentsList) {
                    if (cnt[contentsKey.CRID] !== content[contentsKey.CRID]) list.push(cnt);
                }
            } else if (contentsList.length === contentif.ROW_SERIES) {
                //　シリーズコンテンツの未取得分がある場合に、追加のシリーズ一覧取得
                const reqeustBody = {
                    [contentif.SEARCH_TYPE]: contentif.OPTION,
                    [contentif.PARENT_CRID]: content[contentsKey.PARENT_CRID],
                    [contentif.ROW]: contentif.ROW_SERIES,
                    [contentif.RANGE_START]: contentsList.length + 1,
                    [contentif.PLATFORM]: contentif.getRequestPlatform()
                }
                contentif.postSearch(reqeustBody)
                    .then((res) => {
                        if (res.length > 0) {
                            dispatch(actions.postSearchSeriesAddSuccess(res));
                        }
                    });
            }
            setSeriesContents(list);
        }
    }, [dispatch, content, contentsList, addSeriesContentsList]);

    /**
     * 関連プラネットを取得.
     */
    useEffect(() => {
        if (Object.keys(planetList).length && content[contentsKey.PLANET_ID] && content[contentsKey.PLANET_ID].length !== 0) {
            let list = [];
            for (const pltId of content[contentsKey.PLANET_ID]) {
                for (const plt of planetList) {
                    if (plt[contentsKey.PLANET_ID] === pltId) list.push(plt);
                }
            }
            setPlanets(list);
        }
    }, [content, planetList]);

    /**
     * キャスト中はWeb上のプレイヤーは再生しない.
     */
    useEffect(() => {
        if (castState === id) {
            setCanPlay(false);
        }
    }, [castState, id]);

    /**
     * 続きを読むを押下.
     */
    const handleReadmore = () => {
        // 要素の取得に失敗.
        if (detailRef.current === null) return;

        if (isdetail) {
            detailRef.current.classList.remove("js-open");
            setIsDetail(false);
        } else {
            detailRef.current.classList.add("js-open");
            setIsDetail(true);
        }
    };

    /**
     * ドロップメニュー切替.
     */
    const handleDropMenu = () => {
        // 要素の取得に失敗.
        if (dropMenuRef.current === null || btnDropMenuRef.current === null) return;

        if (dropMenu) {
            dropMenuRef.current.classList.remove("is-active");
            btnDropMenuRef.current.style.display = "none";
            setDropMenu(!dropMenu);
        } else {
            dropMenuRef.current.classList.add("is-active");
            btnDropMenuRef.current.style.display = "block";
            setDropMenu(!dropMenu);
        }
    };

    /**
     * キャスト用URLスキーム.
     */
    const handleCast = (currentTime = null) => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
            return;
        }
        if (!checkPlay()) return;

        let startPoint = 0;
        if (canPlay) {
            startPoint = currentTime === null ? 0 : Math.floor(currentTime);
        }
        else {
            for (const resume of resumeList) {
                if (resume[contentsKey.CRID] === content[contentsKey.CRID]) {
                    startPoint = resume[accountif.STOP_POSITION];
                    break;
                }
            }
        }
        let customUrlScheme = app.urlScheme() + app.URL_SCHEME_PLAY_CAST;
        // crid設定.
        customUrlScheme += "?crid=" + content[contentsKey.CRID] + "&startPoint=" + startPoint;
        // スキーム設定.
        window.location.href = customUrlScheme;
    };

    /**
     * 視聴開始APIに渡すライセンス情報を抽出.
     */
    const getLicense = () => {
        // レンタル中の場合、コンテンツライセンス返却.
        if (rental) {
            return content.license[0];
        }
        // プラン充足の場合、プランライセンス返却.
        else if (content.planList?.includes(memberPlan) || content.isFree) {
            return memberPlan;
        }
        else {
            return "";
        }
    };

    /**
     * iOSアプリに再生開始を伝達.
     * resume 再生開始位置
     * stBody 視聴開始APIのリクエストボディ
     */
    const playIosCustomUrlScheme = (resume, stBody) => {
        let customUrlScheme = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_VIDEO_PLAY;
        // 視聴開始のリクエストボディ設定.
        customUrlScheme += "startwatch=" + JSON.stringify(stBody);
        // レジュームが存在すれば設定.
        if (resume) customUrlScheme += "&resume=" + resume;
        // 動画タイトルを設定.
        customUrlScheme += "&title=" + content.title + (!content.epiTitle ? "" : " / " + content.epiTitle);
        // スキーム設定.
        window.location.href = customUrlScheme;
    };

    /**
     * 動画再生ボタン押下時の処理.
     */
    const play = () => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
            return;
        }
        if (!checkPlay()) return;

        // キャスト状態が空じゃないとき、キャストを呼び出す.
        if (castState) {
            handleCast();
            return;
        }

        dispatch(loading(true));

        // リクエストボディ作成.
        let body = { // constからletへ
            [deliveryheif.AVAIL_STATUS]: content.availStatus,
            [deliveryheif.CONTENT_LIST]: [
                {
                    [deliveryheif.KIND]: deliveryheif.KIND_MAIN,
                    [deliveryheif.CRID]: id,
                    [deliveryheif.CID]: content.cid,
                    [deliveryheif.LID]: getLicense()
                }
            ]
        };
        // 詳細画質の初期値設定
        if (content.videoDifinition === "SD"){
            dispatch(detailQualityActions.detailQuality(constans.SD_AUTO));
        }
        else {
            dispatch(detailQualityActions.detailQuality(constans.HD_AUTO));
        }

        // iOSアプリネイティブ再生用のソース.
        if (false) {
            accountif.getResume().then((res) => {
                accountif.getResumeSuccess(dispatch, res);
                let resumeTime = null;
                const list = res[accountif.RESUME_LIST] || [];
                for (const resume of list) {
                    if (resume[contentsKey.CRID] === content[contentsKey.CRID]) {
                        resumeTime = resume[accountif.STOP_POSITION];
                        break;
                    }
                }
                playIosCustomUrlScheme(resumeTime, body);
            }, (err) => {
                accountif.isSessionError(dispatch, err);
                let resumeTime = null;
                for (const resume of resumeList) {
                    if (resume[contentsKey.CRID] === content[contentsKey.CRID]) {
                        resumeTime = resume[accountif.STOP_POSITION];
                        break;
                    }
                }
                playIosCustomUrlScheme(resumeTime, body);
            })
                .finally(() => dispatch(loading(false)));
        }
        else {
            // 視聴開始API呼び出し.
            deliveryheif.postStartWatch(body)
                .then(postStartWatchSuccess.bind(this, false), postStartWatchFailure)
                .finally(() => dispatch(loading(false)));
        }
    };

    /**
     * 再生可能な条件を満たしているかチェックする.
     */
    const checkPlay = () => {
        // 非会員は閲覧不可.
        if (plan.isNone(memberPlan)) {
            return false;
        }
        // レンタル中であれば閲覧可.
        else if (rental) {
            return true;
        }
        // 未レンタルでキャンペーンIDが存在すれば閲覧不可（特典映像未取得）.
        else if (content.campaignId && content.campaignId !== "") {
            return false;
        }
        // 無料公開中 or 会員プラン充足であれば閲覧可.
        else if (content.isFree || content.planList?.includes(memberPlan)) {
            return true;
        } else {
            return false;
        }
    };

    /**
     * ポーリングAPI.
     */
    const polling = useCallback(() => {
        deliveryheif.postPolling({ [deliveryheif.PLAY_TOKEN]: playToken })
            .then(() => { }, postPollingFailure);
    }, [playToken, postPollingFailure]);

    /**
     * 視聴終了API.
     */
    const endWatch = (duration, stopPosition, token) => {
        const body = {
            [deliveryheif.PLAY_TOKEN]: token,
            [deliveryheif.DUR]: Math.floor(duration),
            [deliveryheif.STOP_POSITION]: Math.floor(stopPosition)
        };

        // 視聴終了API呼び出し.
        deliveryheif.postEndWatch(body).then(postEndWatchSuccess, (err) => {
            // セッションエラーを確認.
            deliveryheif.isSessionError(dispatch, err);
        });
    };

    /**
     * 同期視聴終了API.
     */
    async function endAwaitWatch(duration, stopPosition, token) {
        const body = {
            [deliveryheif.PLAY_TOKEN]: token,
            [deliveryheif.DUR]: Math.floor(duration),
            [deliveryheif.STOP_POSITION]: Math.floor(stopPosition)
        };

        // 同期視聴終了API呼び出し.
        deliveryheif.postAwaitEndWatch(body);
    };

    /**
     * 前話へ遷移.
     */
    const prevVideo = () => {
        historys.historyVideoId(history, content.preCrid);
    };

    /**
     * 次話へ遷移.
     */
    const nextVideo = () => {
        historys.historyVideoId(history, content.nextCrid);
    };

    const upgradePlan = (afterPlan) => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
        }
        // 有料プランにアップグレードの場合は、プラン選択画面に遷移.
        else {
            // アプリの場合、プランLPに遷移.
            if (app.isAllApp()) {
                historys.historyAppPlan(history);
            }
            else {
                dispatch(openPopupPlanSelect(false, plan.isPremium(afterPlan)));
            }
        }
    };

    const goRental = () => {
        // 非会員の場合ログイン画面に遷移.
        if (plan.isNone(memberPlan)) {
            apiUtil.tsuburayaLogin(dispatch, location, history);
        }
        // 会員の場合、決済画面に遷移.
        else {
            // アプリの場合、TVODLPに遷移.
            if (app.isAllApp()) {
                historys.historyAppTvod(history);
            }
            else {
                // TVOD購入可能プランリストに含まれるプランのユーザのみ購入画面に進む.
                if (content[contentsKey.TVOD_PLAN_LIST]?.includes(memberPlan)) {
                    creditUtil.goPopupCredit(dispatch, location, creditUtil.RENTAL, content.license[0], content.price, creditNumber !== "");
                }
                else {
                    setTvodPurchaseFailure(true);
                }
            }
        }
    };

    /**
     * レンタル期間.
     */
    const rentalRange = () => {
        const range = content.purchaseRange || "";

        // purchaseUnitよってレンタル期間単位を設定.
        switch (content.purchaseUnit) {
            case unit.YEAR:
                return range + "年";
            case unit.MONTH:
                return range + "ヶ月";
            case unit.DAY:
                return range + "日";
            case unit.HOUR:
                return range + "時間";
            default:
                return "";
        };
    };

    /**
     * 残りレンタル時間(残りY年M月D日h時間m分).
     */
    const expriationDate = () => {
        if (!rental) return "";

        const date = new Date(rental[contractif.VALID_END_DATE_TIME]).getTime();
        const diffDate = date - Date.now();

        if (diffDate < 0) {
            return "視聴期間終了";
        }

        // レンタル終了日時と現在日時の差を年月日時分に変換.
        const year = Math.floor(diffDate / (1000 * 3600 * 24 * 30 * 12));
        const month = Math.floor(diffDate % (1000 * 3600 * 24 * 30 * 12) / (1000 * 3600 * 24 * 30))
        const day = Math.floor(diffDate % (1000 * 3600 * 24 * 30) / (1000 * 3600 * 24));
        const hour = Math.floor(diffDate % (1000 * 3600 * 24) / (1000 * 3600));
        const minute = Math.floor(diffDate % (1000 * 3600) / (1000 * 60));

        let text = "残り";

        // 残りY年.
        // 残りMヶ月.
        // 残りD日h時間m分.
        // 残りh時間m分.
        // 残りm分.
        if (year !== 0) text += (year + "年");
        if (year === 0 && month !== 0) text += (month + "ヶ月");
        if (year === 0 && month === 0 && day !== 0) text += (day + "日");
        if (year === 0 && month === 0 && hour !== 0) text += (hour + "時間");
        if (year === 0 && month === 0) text += (minute + "分");
        return text + "で視聴期間終了"
    };

    /**
     * アップグレードボタン・レンタルボタン表示.
     */
    const upgradeRentalButton = () => {
        // アップグレードボタン表示.
        const upgradeButton = () => {
            // ライセンスが存在し、対象プランがない場合は、TVODのみ運用.
            if ((!content.planList || content.planList.length === 0) && content.license[0]) return null;
            // キャンペーンIDがある場合（特典映像）、非表示.
            if (content.campaignId && content.campaignId !== "") return null;

            // 非会員の場合.
            if (plan.isNone(memberPlan)) {
                // 無料プランで閲覧可能.
                if (content.isFree || content.planList?.includes(plan.FREE)) {
                    return (<div className="c-playerRank__upgrade" onClick={() => upgradePlan(plan.FREE)}>無料で観る</div>)
                }
                // 有料プランで閲覧可能.
                else {
                    // 対象プラン取得（スタンダード以上 or プレミアムのみ）.
                    const requiredPlan = content.planList?.includes(plan.STANDARD) ? plan.STANDARD : plan.PREMIUM;

                    return (
                        <div className="c-playerRank__upgrade" onClick={() => upgradePlan(requiredPlan)}>{plan.EN_TO_JP[requiredPlan]}に登録して見放題</div>
                    );
                }
            }
            // ログインユーザの場合.
            else {
                // 対象プラン取得（スタンダード以上 or プレミアムのみ）.
                const requiredPlan = content.planList?.includes(plan.STANDARD) ? plan.STANDARD : plan.PREMIUM;

                return (
                    <div className="c-playerRank__upgrade" onClick={() => upgradePlan(requiredPlan)}>{plan.EN_TO_JP[requiredPlan]}にアップグレードで見放題</div>
                );
            }
        };

        // レンタルボタン表示.
        const rentalButton = () => {
            // 対象プランが存在し、ライセンスがない場合は、SVODのみ運用.
            if (!content.license?.[0] && content.planList && content.planList.length > 0) return null;
            // レンタル可能期間外の場合は非表示.
            if (Date.now() < (content.purchaseStart * 1000) || Date.now() > (content.purchaseEnd * 1000)) return null;
            // キャンペーンIDがある場合（特典映像）、非表示.
            if (content.campaignId && content.campaignId !== "") return null;

            return (
                <div className="c-playerRank__rental" onClick={goRental}>
                    <div className="c-playerRank__rentalPrice">レンタル ￥{content.price}</div>
                    <div className="c-playerRank__rentalPrice">レンタル期間 {rentalRange()}</div>
                </div>
            );
        };

        // レンタル中ボタン表示.
        const rentaledButton = () => {
            if (!rental) return null;
            // キャンペーンIDがある場合（特典映像）、非表示.
            if (content.campaignId && content.campaignId !== "") return null;

            return (
                <div className="c-playerRank__rentalWrap">
                    <div className="c-playerRank__rentalTxtBox">
                        <div className="c-playerRank__rentalTxt">レンタル中</div>
                        <div className="c-playerRank__rentalLine">{expriationDate()}</div>
                    </div>
                </div>
            );
        };

        return (
            <div className="c-playerRank">
                {!checkPlay() &&
                    <div className="c-playerRank__column">
                        {upgradeButton()}
                        {rentalButton()}
                    </div>
                }
                {rentaledButton()}
            </div>
        );
    };

    /**
     * ライブラリ一覧画面に遷移.
     */
    const historylibraryId = () => {
        let list = [];
        if (recommendConstants.getVideoSpec(memberPlan) !== ""){
            list.push(libraryId.RECOMMEND);
            list.push(libraryId.VIDEO);
            list.push(id);
        }
        else {
            list.push(libraryId.PLANET);
            for (const pid of content.planetId) list.push(pid);
        }
        historys.historylibraryId(history, historys.getEncodeId(list));
    };

    /**
     * 現在時刻が配信期間内か.
     */
    const isAvailStart = () => {
        // 配信期間を取得できてない場合.
        if ((!content.availPerStart || !content.availPerEnd) && content.availPerStart !== 0) return false;

        return (content.availPerStart * 1000) <= Date.now() && Date.now() <= (content.availPerEnd * 1000);
    };

    /**
     * 配信開始日の表示.
     */
    const scheduledStream = () => {
        // 配信開始日が取得できてない場合 or 現在時刻が配信開始日を超えている場合は表示しない.
        if ((!content.availPerStart && content.availPerStart !== 0) || (content.availPerStart * 1000) <= Date.now()) return null;

        const date = new Date(content.availPerStart * 1000);

        return (
            <div>配信予定：{date.getFullYear() + "年" + (date.getMonth() + 1) + "月" + date.getDate() + "日" + date.getHours() + "時" + date.getMinutes() + "分"}～</div>
        );
    };

    /**
     * 再生ボタンの表示.
     * 特典映像の視聴権限がない場合、文言を表示.
     * iOSアプリのときのみ前へ・次へボタンも表示.
     */
    const playButton = () => {
        if (content.campaignId && content.campaignId !== "" && !rental) {
            return (
                <div className={classes.notPlayText}>
                    <div className="c-playerDis__notPlay">
                        <div className="c-playerDis__notPlayTxt">
                            <div className="c-playerDis__notPlayTtl">この映像は視聴できません。</div>
                            <div className="c-playerDis__notPlayDisc">この特典映像は権利をお持ちでないため視聴できません。</div>
                        </div>
                    </div>
                </div>
            );
        }
        else if (castState === id) {
            return (
                <>
                    {content.preCrid && <img alt="" src="/images/xs/player/prev_button.svg" className={classes.iosPreviousBtn} onClick={prevVideo} />}
                    <img alt="" src="/images/xs/player/play_button_icon.svg" className={classes.iosPlayBtn} onClick={handleCast} />
                    {content.nextCrid && <img alt="" src="/images/xs/player/next_button.svg" className={classes.iosNextBtn} onClick={nextVideo} />}
                </>
            )
        }
        else if (isAvailStart()) {
            // iOSアプリネイティブ再生用のソース.
            if (false) {
                return (
                    <>
                        {content.preCrid && <img alt="" src="/images/xs/player/prev_button.svg" className={classes.iosPreviousBtn} onClick={prevVideo} />}
                        <img alt="" src="/images/xs/player/play_button_icon.svg" className={classes.iosPlayBtn} onClick={play} />
                        {content.nextCrid && <img alt="" src="/images/xs/player/next_button.svg" className={classes.iosNextBtn} onClick={nextVideo} />}
                        {app.isAllApp() && !castState && <div className="c-playerCastBtn"><div className="c-playerCastBtn__inner"><img alt="" src="/images/cast_icon.svg" onClick={handleCast} /></div></div>}
                    </>
                );
            }
            else {
                return (
                    <>
                        <img alt="" src="/images/xs/player/play_button.svg" className={classes.videoBtn} onClick={play} />
                        {app.isAllApp()  && !castState && <div className="c-playerCastBtn"><div className="c-playerCastBtn__inner"><img alt="" src="/images/cast_icon.svg" onClick={handleCast} /></div></div>}
                    </>
                );
            }
        }
        else {
            return null;
        }
    };

    /*
     * 連続再生機能で次の動画へ遷移.
     */
    async function moveVideo() {
        return new Promise((resolve, reject) => {
            const nc = content.nextCrid
            window.history.pushState('', '', historys.getEncodeId(nc));
            setId(nc);

            const body = {
                [contentif.CONTENT_ID_LIST]: [nc],
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postIdSearch(body).then((res) => {
                postIdSearchSuccess(res);

                // useEffectのrentalの設定は間に合わないので、ここで設定する.
                let lid = "";

                // プラン充足確認.
                if (!res[0].campaignId && (res[0].planList?.includes(memberPlan) || res[0].isFree)) {
                    lid = memberPlan;
                }

                // レンタル・特典取得状況確認.
                for (const purchase of purchaseList) {
                    if (purchase[contractif.LICENSE_ID] === res[0].license[0]) {
                        lid = res[0].license[0];
                        break;
                    }
                }
                for (const gift of giftList) {
                    if (gift[contractif.LICENSE_ID] === res[0].license[0]) {
                        lid = res[0].license[0];
                        break;
                    }
                }

                // 視聴権限がない場合、エラーダイアログを表示.
                if (lid === "") {
                    setLicenseFailure(true);
                    setCanPlay(false);
                    reject("");
                }
                // 視聴権限がある場合、視聴開始APIを実行.
                else {
                    let swbody = { // constからletへ
                        [deliveryheif.AVAIL_STATUS]: res[0].availStatus,
                        [deliveryheif.CONTENT_LIST]: [
                            {
                                [deliveryheif.KIND]: deliveryheif.KIND_MAIN,
                                [deliveryheif.CRID]: res[0].crid,
                                [deliveryheif.CID]: res[0].cid,
                                [deliveryheif.LID]: lid
                            }
                        ]
                    };
                    deliveryheif.postStartWatch(swbody)
                        .then((res) => { postStartWatchSuccess(true, res); resolve(res[deliveryheif.PLAY_TOKEN]) }, (err) => { postStartWatchFailure(err); setCanPlay(false); reject("") });
                }
            }, () => { postIdSearchFailure(); reject("") });
        });
    };

    /*
     * 次話のサムネイルとタイトルを返却.
     */
    const getNextContentInfo = useMemo(() => {
        for (const c of seriesContents) {
            if (content.nextCrid === c.crid) {
                const nextThumnail = c.thumbnailLandscape;
                const nextTitle = c.title + (!c.epiTitle ? "" : " / " + c.epiTitle);
                return [nextThumnail, nextTitle];
            }
        }
        // 次話を発見できなかった場合、nullを返却.
        return [null, null];
    }, [content, seriesContents]);

    /*
     * プラットコンテンツ表示を返却.
     */
    const viewPlanets = () => {
        const planetsRows = [];
        for (let i = 0; i < planets.length; i = i + 3) {
            planetsRows.push(
                <div className="c-playerMore__planet">
                    {Object.entries(planets).slice(i, i + 3).map(([, value]) => (
                        <span key={value[contentif.PLANET_ID]} className={classes.planet + " p-planet__ultraSelect__item p-planet__ultraSelect__item--playerDis js-on"}>
                            <PlanetContents meta={value} isAnimation={true} delay={0} />
                        </span>
                    ))}
                </div>
            );
        }
        return (planetsRows);
    }


    /*
     * クレジットの文字列作成
     * クレジットに特定の役職と名前のペアが入っている場合は置き換えてから結合。
     * 置換対象がなければそのまま結合。
     */
    const toCreditString = (value) => {
        const CREDIT_REPLACEMENT_LIST = [
            {'position':'声の出演', 'name':'中村裕', 'toPosition': 'ディレクター'},
            {'position':'声の出演', 'name':'吉田一貴', 'toPosition': 'ディレクター'}
        ];
        let creditList = [];
        creditList = value.split("|").slice(2, 4);
        for (const replacementList of CREDIT_REPLACEMENT_LIST) {
            if (replacementList['position'] === creditList[0] && replacementList['name'] === creditList[1]) {
                creditList[0] = replacementList['toPosition'];
            }
        }
        return creditList.join("：")
    }

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            {startWatchFailure.status && <Warning input={{ title: startWatchFailure.title, content: startWatchFailure.content }} onAccept={() => setStartWatchFailure({ ...startWatchFailure, status: false })} />}
            {pollingFailure && <Warning input={{ title: "視聴エラー", content: "エラーが発生しました。もう一度再生しなおしてください。" }} onAccept={() => setPollingFailure(false)} />}
            {licenseFailure && <Warning input={{ title: error.VIDEO_LICENSE_FAILURE_TITLE, content: error.VIDEO_LICENSE_FAILURE_CONTENT }} onAccept={() => setLicenseFailure(false)} />}
            {tvodPurchaseFailure && <Warning input={{ title: error.TVOD_PURCHASE_FAILURE_TITLE, content: error.TVOD_PURCHASE_FAILURE_CONTENT }} onAccept={() => setTvodPurchaseFailure(false)} />}
            <Parallax className="l-planetBg l-planetBg--planetListBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-container">
                    <div className="c-player" onContextMenu={(e) => e.preventDefault()}>
                        {!canPlay && (
                            <div className={classes.thumbnail}>
                                <picture>
                                <source srcset={content.thumbnailLandscape.replace('/landscape/', '/landscape/960x540/').replace(/\.[^/.]+$/, "") + ".webp 1x,"
                                    + content.thumbnailLandscape.replace('/landscape/', '/landscape/960x540/').replace(/\.[^/.]+$/, "") + "@2x.webp 2x"}
                                    type="image/webp" />
                                <img src={content.thumbnailLandscape} onError={(e) => {e.onerror = null; e.target.src = "/images/noimage.jpg"}} alt=""  />
                            </picture>
                                {castState === id && castDeviceName && <div className="c-playerCastTxt" style={{ zIndex: 1 }}>{castDeviceName}で再生しています</div>}
                                {playButton()}
                                {((!checkPlay() && !content.campaignId) || castState === id) && <div className={classes.noPlayBackground} />}
                            </div>
                        )}
                        {canPlay &&
                            <Player
                                type={mediaType.VIDEO_TYPE}
                                polling={polling}
                                endWatch={endWatch}
                                endAwaitWatch={endAwaitWatch}
                                prevVideo={prevVideo}
                                nextVideo={nextVideo}
                                moveVideo={moveVideo}
                                getNextContentInfo={getNextContentInfo}
                                handleCast={handleCast}
                            />
                        }
                    </div>
                    {upgradeRentalButton()}
                    <div className="c-playerBtn">
                        <div className="c-playerDetail">
                            <h1 className="c-playerDetail__ttl">{content.title + (!content.epiTitle ? "" : " / " + content.epiTitle)}</h1>
                            {scheduledStream()}
                            <input className="readmore-check" id="check1" type="checkbox" />
                            <div
                                className="c-playerDetail__txt readmore-content"
                                ref={detailRef}
                            >
                                {content.synopsisLong?.[0] ?? ""}
                                <br></br>
                                {content.creditsList?.length > 0 &&
                                    Object.entries(content.creditsList).map(([, value]) => (
                                        value.length >= 3 ? <>{toCreditString(value)}<br></br></> : null
                                    ))
                                }
                                {content.copyright}
                            </div>
                            <label className="readmore-label" for="check1" onClick={() => handleReadmore()}></label>
                        </div>
                        <div className="c-serviceBtn">
                            <FavoriteShareButton content={content} />
                            {content.seriesTitle !== "" &&
                                <div className="c-playerDropMenu">
                                    <div className="c-playerDropMenu__wrap">
                                        <div className="c-playerDropMenu__item">
                                            <div
                                                className="c-btn c-btn--black c-btn--dropMenuBtn c-playerDropMenu__btn"
                                                onClick={() => handleDropMenu()}
                                                ref={dropMenuRef}
                                            >
                                                <div className="c-btn--dropMenuBtnTxt">{content.seriesTitle}とは</div>
                                            </div>
                                            <div
                                                className="c-btnDropMenu c-btnDropMenu--black"
                                                ref={btnDropMenuRef}
                                            >
                                                <div className="c-btnDropMenu__detail">{content.seriesSynopsisLong}</div>
                                                <div className="c-btnDropMenu__close" onClick={() => handleDropMenu()}>とじる</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            <div className="c-playerMore">
                    {(seriesContents.length > 0) &&
                        <section className="c-sect c-sect--playerMore">
                            <Separator input={{ title: content.seriesTitle }} />
                            <div className="c-contCardSlideBox">
                                <div className="c-contCardSlideWrap">
                                    <div className="c-contCardNoCat">
                                        <div className="c-contCardNoCat__list">
                                            <Swiper
                                                spaceBetween={matches ? 20 : 10}
                                                slidesPerView="auto"
                                                slidesPerGroup={3}
                                                navigation={{
                                                    prevEl: ".swiper-button-prev",
                                                    nextEl: ".swiper-button-next"
                                                }}
                                                className="c-contCardNoCatList js-movieSlide"
                                            >
                                                {Object.entries(seriesContents).map(([, value]) => (
                                                    <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                        <Default meta={value} playBack={false} />
                                                    </SwiperSlide>
                                                ))}
                                                <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                                <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {seriesContents.length === 0 && <br />}
                    {(recommendContentsList.length > 0) &&
                        <section className="c-sect c-sect--playerMore">
                            <Separator input={{ title: "あなたへのおすすめ" }} />
                            <div className="c-contCardSlideBox">
                                <div className="c-contCardSlideWrap">
                                    <div className="c-contCardNoCat">
                                        <div className="c-contCardNoCat__list">
                                            <Swiper
                                                spaceBetween={matches ? 20 : 10}
                                                slidesPerView="auto"
                                                slidesPerGroup={3}
                                                navigation={{
                                                    prevEl: ".swiper-button-prev",
                                                    nextEl: ".swiper-button-next"
                                                }}
                                                className="c-contCardNoCatList js-movieSlide"
                                            >
                                                {Object.entries(recommendContentsList).map(([, value]) => (
                                                    <SwiperSlide key={value[contentsKey.CRID]} className="c-contCardNoCatList__item">
                                                        <Default meta={value} playBack={false} clickData={clickData} />
                                                    </SwiperSlide>
                                                ))}
                                                {/** 所属プラネットが無い場合=ランキング表示の場合は、もっと見るボタンを表示しない */}
                                                {(recommendConstants.getVideoSpec(memberPlan) !== "" || content.planetId?.length) &&
                                                    <SwiperSlide className="c-contCardNoCatList__item c-contCardNoCatList__item--more">
                                                        <span className={classes.clickable + " c-contCardNoCatList__itemMore"} onClick={() => historylibraryId()}>
                                                            <div className="c-contCardNoCatList__itemMoreTxt">もっと見る</div>
                                                        </span>
                                                    </SwiperSlide>
                                                }
                                                <div className="swiper-button-prev c-contCardNoCatList__prev"></div>
                                                <div className="swiper-button-next c-contCardNoCatList__next"></div>
                                            </Swiper>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    }
                    {viewPlanets()}
                </div>
            </div>
        </div>
    );
};


export default VideoId;
