import * as actionTypes from "./actionTypes";

/**
 * 初期化.
 */
export const initPlanetId = () => {
    return {
        type: actionTypes.INIT_PLANET_ID
    };
}

/**
 * プラネット更新.
 */
export const updatePlanet = (data) => {
    return {
        type: actionTypes.UPDATE_PLANET,
        planet: data
    };
}

/**
 * プラネットIDコンテンツリスト更新.
 */
export const updatePlanetIdContentsList = (data) => {
    return {
        type: actionTypes.UPDATE_PLANETID_CONTENTS_LIST,
        contentsList: data
    };
}

/**
 * 選択中カテゴリー更新.
 */
export const updatePlanetIdCurrentCategory = (data) => {
    return {
        type: actionTypes.UPDATE_PLANETID_CURRENT_CATEGORY,
        currentCategory: data
    };
}

/**
 * 選択中プラン更新.
 */
export const updatePlanetIdCurrentPlan = (data) => {
    return {
        type: actionTypes.UPDATE_PLANETID_CURRENT_PLAN,
        currentPlan: data
    };
}

export const planetbackTrue = () => {
    return {
        type: actionTypes.PLANETBACKTRUE,
    };
}