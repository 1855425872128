import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as cubeGroup from "../../constants/cubeGroup";
import * as app from "../../constants/app";
import SpecialCubeGroupBannar from "../../components/contents/specialCubeGroupBannar/SpecialCubeGroupBannar";
import { historyHowtocube } from "../../historys";

/**
 * スペシャル図鑑一覧.
 *
 * props
 *  -> handleProf                  プロフィールの表示
 *  -> handleSpecialCubeGroupList  スペシャル図鑑一覧の非表示
 *  -> handleSpecialCubeGroup      スペシャル図鑑の表示
 */
const SpecialCubeGroupList = (props) => {
    /** Hooks. */
    const history = useHistory();

    /** スペシャル図鑑一覧. */
    const specialCubeGroupList = useSelector(state => state.Member.specialCubeGroupList);

    /**
     * データキューブの楽しみ方画面に遷移.
     */
    const handleDataCube = () => {
        props.handleSpecialCubeGroupList();
        historyHowtocube(history);
    }

    /**
     * もどる押下時の処理.
     */
    const handleBack = () => {
        props.handleSpecialCubeGroupList();
        props.handleProf();
    }

    return (
        <span>
            <div className="c-prof__datacubeTable__head">
                {!app.isAllApp() &&
                    <div className="c-back c-back--datacube" onClick={() => handleBack()}>
                        <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                        <div className="c-back__txt">もどる</div>
                    </div>
                }
            </div>
            <div className="c-prof__datacubeTable__headBg"></div>
            <div className="c-prof__datacube__wrap">
                <div className="c-prof__datacube__vis">
                    <div className="c-prof__datacube__ttl">
                        <p>データキューブ一覧</p>
                    </div>
                    <div className="c-prof__datacubeList">
                        <div className="c-prof__datacubeList__dtl">商品やイベントなど、オンラインだけではなくリアルな場所からでもTSUBURAYA IMAGINATIONを楽しめるコンテンツです。<br/>データキューブ専用の二次元コードを読み取ることで、作品についてのデータキューブ（デジタル図鑑）が入手できます。</div>
                        <div
                            className="c-prof__profile__btn c-prof__profile__btn--cubeFun c-btn c-btn--gray"
                            onClick={() => handleDataCube()}
                        >
                            <div className="c-prof__profile__btnTxt">データキューブの楽しみ方</div>
                        </div>
                        <ul className="c-prof__datacubeList__bannerBox">
                            {Object.entries(specialCubeGroupList).map(([, value]) => (
                                <li key={value[cubeGroup.GROUP_ID]} className="c-prof__datacubeList__bannerItem">
                                    <SpecialCubeGroupBannar meta={value} handleSpecialCubeGroup={props.handleSpecialCubeGroup} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default SpecialCubeGroupList;
