import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateRecommendCookie } from "../common/status/recommendCookie/actions";

const crypto = require('crypto');

/** レコメンド用Cookie取得フック. */
const useRecommendCookie = () => {

    const dispatch = useDispatch();

    /** レコメンド用Cookie. */
    const recommendCookie = useSelector(state => state.RecommendCookie.recommendCookie);
    /** Cookie使用可能文字列（先頭）.*/
    const firstCookieChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    /** Cookie文字数. */
    const cookieLength = 16;

    useEffect(() => {
        if (recommendCookie === "") {
            const randomArr = new Uint32Array(new Uint8Array(crypto.randomBytes(cookieLength * 4)).buffer);
            const cookie = [...randomArr].map(n => firstCookieChars.charAt(n % firstCookieChars.length)).join('');
            dispatch(updateRecommendCookie(cookie));
        }
    }, [dispatch, recommendCookie]);

    return {recommendCookie};
};

export default useRecommendCookie;