import * as actionTypes from "./actionTypes";

const initState = {
    prevPage: "/"
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 直前に閲覧していたページPATHを保存. */
        case actionTypes.SAVE_PREV_PAGE:
            return { ...state, prevPage: action.prevPage };
        default:
            return state;
    }
};

export default reducer;