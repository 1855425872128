import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles(theme => ({
    clickable: {
        cursor: "pointer"
    },
    displayNone: {
        display: "none"
    }
}));

export default styles;
