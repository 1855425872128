import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as campaign from "../../../constants/campaign";
import * as cv from "../../../constants/cv";
import * as accountif from "../../../middleware/accountif";
import * as contractif from "../../../middleware/contractif";
import * as historys from "../../../historys";
import { doLogout } from "../../../components/dialog/Logout";
import { decode } from "js-base64";

/**
 * クーポン行使画面.
 */
const CouponUsed = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** cvリクエスト済み. */
    const [isCv, setIsCv] = useState(false);

    /** クーポン行使画面. */
    const isCouponUsed = useSelector(state => state.Common.isCouponUsed);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");

        // キャンペーン一覧を再取得.
        accountif.getCampaign().then(
            accountif.getCampaignSuccess.bind(this, dispatch),
            accountif.isSessionError.bind(this, dispatch)
        );

        // 特典一覧を再取得.
        contractif.getContractManage().then(
            contractif.getContractManageSuccess.bind(this, dispatch),
            doLogout.bind(this, dispatch)
        );

        // 情報ページの場合は同じ画面に遷移してテンプレート再取得.
        if (window.location.pathname.indexOf("/contents/") !== -1) {
            // 現在のページのCRIDを取得.
            const splitPathname = window.location.pathname.split("/");
            const contentsId = decode(decodeURIComponent(splitPathname[splitPathname.length - 1]));
            historys.historyReplaceContents(history, contentsId);
        }

        return () => {
            document.body.classList.remove("is-fixed");
        }
    }, [dispatch, history]);

    /**
     * cv計測処理.
     */
    useEffect(() => {
        if (!isCv && Object.keys(isCouponUsed.campaignMeta).length) {
            cv.sendCouponCV(isCouponUsed.campaignMeta[campaign.CAMPAIGN_NAME], isCouponUsed.campaignMeta[campaign.COUPON_ID]);
            setIsCv(true);
        }
    }, [isCv, isCouponUsed]);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    }

    /**
     * 「OK」押下時の処理.
     */
    const handleOk = () => {
        campaign.handleDestination(dispatch, history, isCouponUsed.campaignMeta, campaign.SOURCE_COUPON_USED);
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl p-popupCoupon">
                            <div className="p-popupCoupon__chara"><img src="/assets/images/img-coupon-chara.png" alt="" /></div>
                            <div className="p-popupCoupon__ttl">キャンペーンへのエントリーが<br/>完了しました。</div>
                            <div className="p-popup__aplBtn p-popupCoupon__btn">
                                <span className="c-btn c-btn--thinRed" onClick={() => handleOk()}>OK</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default CouponUsed;
