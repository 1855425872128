import React from "react";

/**
 * WARNINGダイアログ（ダイアログの上に表示させる用）.
 *
 * props
 *  -> input:title   タイトル.
 *  -> input:content 内容.
 *  -> onAccept      「はい」押下時の処理.
 */
const WarningFront = (props) => {
    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * 「はい」押下時の処理.
     */
    const onAccept = () => {
        props.onAccept();
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
            style={{zIndex: 999}}
        >
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__icon p-popup__icon--dialog">
                        <img src="/assets/images/icon-attention-comp-yellow.png" alt="" />
                    </div>
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{props.input.title}</div>
                            <div className="p-popup__txt">{props.input.content}</div>
                            <div className="p-popup__aplBtn c-btn c-btn--thinRed" onClick={() => onAccept()}>OK</div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default WarningFront;
