/**
 * 検索カテゴリー.
 */

export const category = {
    "C00": "全カテゴリー",

    /** 動画. */
    "C10": "動画",
    "C11": "TVシリーズ",
    "C12": "劇場版・ビデオ作品<br/>他",
    "C13": "ステージショー",
    "C14": "IMAGINATION<br/>オリジナル作品",
    "C15": "ライブ配信",
    "C16": "プロモーション",
    "C17": "アニメ",
    "C18": "ドキュメンタリー",
    "C19": "その他",

    /** 音声. */
    "C20": "音声",
    "C21": "音楽",
    "C22": "ラジオ",
    "C23": "音声ガイド",
    "C24": "その他",

    /** 読み物. */
    "C30": "読み物",
    "C31": "小説",
    "C32": "コミックス",
    "C33": "コラム",
    "C34": "インタビュー／対談",
    "C35": "投稿",
    "C36": "紹介／レビュー",
    "C37": "その他",

    /** ニュース. */
    "C40": "ニュース",
    "C41": "イベント情報",
    "C42": "作品情報",
    "C43": "グッズ情報",
    "C44": "その他"
}

export const plan = {
    "P00": "すべてのプラン",
    "P11": "あなたのプラン",
}

/**
 * 検索用のカテゴリー文字列を取得.
 */
export const getSearchCategory = (ctg) => {
    let searchCtg = "";
    if (ctg.indexOf("C1") !== -1&&ctg !== "C10") searchCtg += "動画";
    if (ctg.indexOf("C2") !== -1&&ctg !== "C20") searchCtg += "音声";
    if (ctg.indexOf("C3") !== -1&&ctg !== "C30") searchCtg += "読み物";
    if (ctg.indexOf("C4") !== -1&&ctg !== "C40") searchCtg += "ニュース";
    searchCtg += "-";
    searchCtg += category[ctg];
    if(ctg === "C10"||ctg === "C20"||ctg === "C30"||ctg === "C40"){
        searchCtg = category[ctg];
    }
    return searchCtg.replace("<br/>", "")
}
