import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as couponActions from "../status/coupon/actions";
import * as commonActions from "../../common/status/actions";
import * as memberActions from "../../common/status/member/actions";
import * as app from "../../constants/app";
import * as apiUtil from "../../constants/apiUtil";
import * as campaign from "../../constants/campaign";
import * as contentType from "../../constants/contentType";
import * as coupon from "../../constants/coupon";
import * as cube from "../../constants/cube";
import * as cubeGroup from "../../constants/cubeGroup";
import * as accountif from "../../middleware/accountif";
import * as eventif from "../../middleware/eventif";
import * as contentif from "../../middleware/contentif";
import DataCube from "../../components/contents/dataCube/DataCube";
import { historyContents } from "../../historys";
import styles from "./styles";

/**
 * スペシャル図鑑詳細.
 *
 * props
 *  -> handleSpecialCubeGroupList  スペシャル図鑑一覧の非表示
 *  -> handleSpecialCubeGroup      スペシャル図鑑の非表示
 *  -> handleDataCube              データキューブ詳細の表示
 *  -> handleGiftConfirm           追加特典確認の表示
 */
const SpecialCubeGroup = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /** ドロップメニューDOM. */
    const dropMenuRef = useRef();
    /** ドロップメニューボタンDOM. */
    const btnDropMenuRef = useRef();
    /** コレクションDOM. */
    const collectionRef = useRef();

    /** ドロップメニューの表示/非表示. */
    const [isDropMenu, setIsDropMenu] = useState(false);
    /** データキューブのCollection表示/非表示. */
    const [isCollection, setIsCollection] = useState(false);
    /** 表示用データキューブリスト. */
    const [displayDataCubeList, setDisplayDataCubeList] = useState([]);
    /** 特典の表示/非表示. */
    const [isDisplayGift, setIsDisplayGift] = useState(false);
    /** 表示する特典画像. */
    const [isGotBanner, setIsGotBanner] = useState(false);
    /** 特典取得済みフラグ. */
    const [isGotGift, setIsGotGift] = useState(false);

    /** スペシャル図鑑一覧の表示/非表示. */
    const isSpecialCubeGroupList = useSelector(state => state.Screen.isSpecialCubeGroupList);
    /** スペシャル図鑑の表示/非表示. */
    const isSpecialCubeGroup = useSelector(state => state.Screen.isSpecialCubeGroup);
    /** 追加特典取得確認の表示/非表示. */
    const isGiftConfirm = useSelector(state => state.Screen.isGiftConfirm);
    /** スペシャル図鑑ID. */
    const specialCubeGroupId = useSelector(state => state.Screen.specialCubeGroupId);
    /** スペシャル図鑑 対象キューブにスクロールするか. */
    const isSpecialCubeGroupScroll = useSelector(state => state.Screen.isSpecialCubeGroupScroll);
    /** キャンペーン一覧. */
    const campaignList = useSelector(state => state.Member.campaignList);
    /** スペシャル図鑑一覧. */
    const specialCubeGroupList = useSelector(state => state.Member.specialCubeGroupList);
    /** スペシャル図鑑. */
    const specialCubeGroup = useSelector(state => state.Member.specialCubeGroup);
    /** データキューブ一覧. */
    const dataCubeList = useSelector(state => state.Member.dataCubeList);

    /**
     * データキューブ取得率を計算.
     */
    const getCollectRate = () => {
        let count = 0;
        for (const d of dataCubeList) {
            if (d[cube.CUBE_IMAGE_FILE] !== "") count++;
        }
        return dataCubeList.length === 0 ? 0 : parseInt((count / dataCubeList.length) *  100);
    }

    /** CSS. */
    const cssProps = {
        meterLineWidth: "calc(" + getCollectRate() + "%)"
    };
    const classes = styles(cssProps);

    /**
     * クーポン利用状況チェックAPI 成功.
     */
    const postCouponCheckSuccess = useCallback((data) => {
        // 特典表示があるか.
        setIsDisplayGift(coupon.isDisplayGift(data, specialCubeGroup[cubeGroup.GIFT_TYPE]));

        // 特典未取得 or 特典取得済み画像を表示するか.
        setIsGotBanner(coupon.isGotBanner(data, specialCubeGroup[cubeGroup.GIFT_TYPE]));

        // 特典取得済みか.
        setIsGotGift(coupon.isGotGift(data));

        // キャンペーン一覧を再取得.
        accountif.getCampaign().then(
            accountif.getCampaignSuccess.bind(this, dispatch),
            accountif.isSessionError.bind(this, dispatch)
        );
    }, [dispatch, specialCubeGroup]);

    /**
     * 遷移時に実行.
     */
    useEffect(() => {
        if (isSpecialCubeGroupList && isSpecialCubeGroup) {
            // スペシャル図鑑を設定.
            if (specialCubeGroupId !== "") {
                for (const scg of specialCubeGroupList) {
                    if (specialCubeGroupId === scg[cubeGroup.GROUP_ID]) {
                        dispatch(memberActions.updateSpecialCubeGroup(scg));
                        return;
                    }
                }
            }
        }
    }, [dispatch, isSpecialCubeGroupList, isSpecialCubeGroup, specialCubeGroupId, specialCubeGroupList]);

    /**
     * 表示するスペシャル図鑑が変更されたときに実行.
     */
    useEffect(() => {
        if (isSpecialCubeGroupList && isSpecialCubeGroup && !isGiftConfirm) {
            if (Object.keys(specialCubeGroup).length) {
                // クーポン利用状況チェックAPI実行.
                const request = {[eventif.CONTENT_TYPE]: contentType.CUBE};
                if (specialCubeGroup[cubeGroup.CAMPAIGN_ID] !== "") {
                    // キャンペーンIDがある時はキャンペーンIDを使用.
                    request[eventif.CAMPAIGN_ID] = specialCubeGroup[cubeGroup.CAMPAIGN_ID];
                } else {
                    // キャンペーンIDがない時はムビチケCRIDを使用.
                    request[eventif.GIFT_SOURCE_CRID] = specialCubeGroup[cubeGroup.MOVIE_TICKET_CRID];
                }
                eventif.postCouponCheck(request)
                .then(postCouponCheckSuccess)
                .catch((err) => {
                    // セッションエラーを確認.
                    eventif.isSessionError(dispatch, err);
                    // 追加特典取得失敗（オンラインクーポン）ダイアログ.
                    if (err[apiUtil.ERROR_CODE] === eventif.ERR06006) {
                        dispatch(commonActions.moreGiftCouponFailure(true));
                    }
                });

                // データキューブ一覧を取得.
                accountif.postCube({
                    [accountif.CUBE_GROUP_ID]: specialCubeGroupId
                }).then((data) => {
                    dispatch(memberActions.updateDataCubeList(data));
                }).catch((err) => {
                    // セッションエラーを確認.
                    accountif.isSessionError(dispatch, err);
                    // データキューブ情報取得失敗ダイアログ.
                    dispatch(commonActions.dataCubeFailure(true));
                });
            }
        }
        // eslint-disable-next-line
    }, [dispatch, isSpecialCubeGroupList, isSpecialCubeGroup, isGiftConfirm, specialCubeGroup]);

    /**
     * データキューブ一覧取得時の処理.
     */
    useEffect(() => {
        if (dataCubeList.length > 0) {
            setDisplayDataCubeList(dataCubeList);
        }
    }, [dataCubeList]);

    /**
     * キャンペーン一覧から表示時の処理.
     */
    useEffect(() => {
        if (isSpecialCubeGroupScroll && displayDataCubeList.length > 0 && Object.keys(specialCubeGroup).length && Object.keys(campaignList).length) {
            for (let camp of campaignList) {
                if (camp[campaign.CAMPAIGN_ID] === specialCubeGroup[cubeGroup.CAMPAIGN_ID]) {
                    const cube = document.getElementsByClassName(camp[campaign.CUBE_ID]);
                    if (cube.length === 0) return;
                    // 対象のキューブまで自動スクロールする.
                    cube[0].scrollIntoView(true);
                    return;
                }
            }
        }
    }, [isSpecialCubeGroupScroll, displayDataCubeList, specialCubeGroup, campaignList]);

    /**
     * 非表示時に表示要素を空に設定.
     */
    useEffect(() => {
        if (!isSpecialCubeGroupList || !isSpecialCubeGroup) {
            if (isDropMenu) handleDropMenu();
            dispatch(memberActions.updateSpecialCubeGroup({}));
            setDisplayDataCubeList([]);
            setIsDisplayGift(false);
            setIsGotBanner(false);
            setIsGotGift(false);
        }
        // eslint-disable-next-line
    }, [dispatch, isSpecialCubeGroupList, isSpecialCubeGroup]);

    /**
     * 取得済みデータキューブを取得.
     */
    const getCollectedDataCube = () => {
        let collected = [];
        for (const d of dataCubeList) {
            if (d[cube.CUBE_IMAGE_FILE] !== "") collected.push(d);
        }
        return collected;
    }

    /**
     * 取得済みデータキューブ数を取得.
     */
    const getCollectedDataCubeNum = () => {
        let collected = [];
        for (const d of dataCubeList) {
            if (d[cube.CUBE_IMAGE_FILE] !== "") collected.push(d);
        }
        return collected.length;
    }

    /**
     * スペシャル図鑑を非表示.
     */
    const handleSpecialCubeGroup = () => {
        props.handleSpecialCubeGroup("");
    }

    /**
     * アプリ表示可能なLPか確認.
     */
    const checkAppContents = (crid) => {
        // アプリとWebで処理切替.
        if (app.isAllApp()) {
            // アプリの場合、表示可能なLPか確認する.
            dispatch(commonActions.loading(true));

            // ID検索で確認.
            const requestBody = {
                [contentif.CONTENT_ID_LIST]: [crid],
                [contentif.PLATFORM]: contentif.getRequestPlatform()
            };
            contentif.postIdSearch(requestBody).then((res) => {
                if (res.length > 0) {
                    // アプリで表示できるLPなので、LPに遷移.
                    props.handleSpecialCubeGroupList();
                    historyContents(history, crid);
                } else {
                    // アプリで表示できないLPなので、警告ダイアログ表示.
                    dispatch(commonActions.appNotDisplayLP(true));
                }
            })
            .catch(() => dispatch(commonActions.appNotDisplayLP(true)))
            .finally(() => dispatch(commonActions.loading(false)));
        } else {
            // アプリでない場合、確認なしでLPに遷移.
            props.handleSpecialCubeGroupList();
            historyContents(history, crid);
        }
    }

    /**
     * バナー画像押下時の処理.
     */
    const handleBanner = () => {
        // LPに遷移.
        dispatch(couponActions.updateCouponCode(""));
        checkAppContents(specialCubeGroup[cubeGroup.CRID]);
    }

    /**
     * ドロップメニュー切替.
     */
    const handleDropMenu = () => {
        // 要素の取得に失敗.
        if (dropMenuRef.current === null || btnDropMenuRef.current === null) return;

        if (isDropMenu) {
            dropMenuRef.current.classList.remove("is-active");
            btnDropMenuRef.current.style.display = "none";
            setIsDropMenu(!isDropMenu);
        } else {
            dropMenuRef.current.classList.add("is-active");
            btnDropMenuRef.current.style.display = "block";
            setIsDropMenu(!isDropMenu);
        }
    }

    /**
     * コレクション切替.
     */
    const handleDataCubeCollection = () => {
        // 要素の取得に失敗.
        if (dropMenuRef.current === null) return;

        if (isCollection) {
            collectionRef.current.classList.remove("is-color");
            // 全てのデータキューブを設定.
            setDisplayDataCubeList(dataCubeList);
            setIsCollection(!isCollection);
        } else {
            collectionRef.current.classList.add("is-color");
            // 取得済みのデータキューブを設定.
            setDisplayDataCubeList(getCollectedDataCube());
            setIsCollection(!isCollection);
        }
    }

    /**
     * 特典処理.
     */
    const handleGift = () => {
        // 特典種別.
        const giftType = specialCubeGroup[cubeGroup.GIFT_TYPE];

        // 会場特典の場合.
        if (giftType === coupon.ITEM) {
            props.handleGiftConfirm();
        }

        // LP遷移の場合.
        if (giftType === coupon.WEB) {
            if (isGotGift) {
                // 追加特典のLPに遷移.
                checkAppContents(specialCubeGroup[cubeGroup.LOTTERY_CRID]);
            } else {
                // 特典未取得は追加特典取得APIを実行.
                eventif.postMoreGift({
                    [eventif.CAMPAIGN_ID]: specialCubeGroup[cubeGroup.CAMPAIGN_ID]
                }).then((data) => {
                    // 追加特典のLPに遷移.
                    checkAppContents(specialCubeGroup[cubeGroup.LOTTERY_CRID]);
                }).catch((err) => {
                    // セッションエラーを確認.
                    eventif.isSessionError(dispatch, err);
                    // 追加特典取得失敗ダイアログ.
                    dispatch(commonActions.moreGiftFailure(true));
                });
            }
        }
    }

    return (
        <span>
            <div className="c-prof__datacubeTable__head">
                <div className="c-back c-back--datacube" onClick={() => handleSpecialCubeGroup()}>
                    <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                    <div className="c-back__txt">もどる</div>
                </div>
            </div>
            <div className="c-prof__datacubeTable__headBg"></div>
            <div className="c-prof__datacubeTable__wrap">
                <div className={classes.clickable + " c-prof__datacubeTable__item"} onClick={() => handleBanner()}>
                    <img src={specialCubeGroup[cubeGroup.HEADER_FILE]} alt=""/>
                </div>
                <div className="c-playerDropMenu c-playerDropMenu--datacube">
                    <div className="c-playerDropMenu__wrap c-playerDropMenu__wrap--datacube">
                        <div className="c-playerDropMenu__item">
                            <div
                                className="c-btn c-btn--black c-btn--dropMenuBtn c-playerDropMenu__btn"
                                onClick={() => handleDropMenu()}
                                ref={dropMenuRef}
                            >
                                <div className="c-btn--dropMenuBtnTxt">このキューブリストについて</div>
                            </div>
                            <div
                                className="c-btnDropMenu c-btnDropMenu--black"
                                ref={btnDropMenuRef}
                            >
                                <div className="c-btnDropMenu__detail">{specialCubeGroup[cubeGroup.DESCRIPTION]}</div>
                                <div className="c-btnDropMenu__close" onClick={() => handleDropMenu()}>とじる</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="c-prof__datacubeCorrection">
                    <div className="c-prof__datacubeCorrection__box">
                        <div className="c-prof__datacubeCorrection__meter">
                            <div className={classes.meterLine}>
                                <div className="c-prof__datacubeCorrection__meterNum">{getCollectRate() + "%"}</div>
                            </div>
                        </div>
                        <div
                            className="c-prof__datacubeCorrection__total"
                            onClick={() => handleDataCubeCollection()}
                            ref={collectionRef}
                        >
                            <div className="c-prof__datacubeCorrection__totalTxt">
                                <p className="c-prof__datacubeCorrection__totalTtl">COLLECTION</p>
                                <div className="c-prof__datacubeCorrection__totalPossession">
                                    <span className="c-prof__datacubeCorrection__totalChange">{getCollectedDataCubeNum()}</span>
                                    <span className="c-prof__datacubeCorrection__totalNum">{"/" + dataCubeList.length}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* 特典画像（未取得）. */}
                    {(isDisplayGift && !isGotBanner) &&
                        <div className="c-prof__datacubeCorrection__banner" onClick={() => handleGift()}>
                            <div className="c-prof__datacubeCorrection__bannerItem">
                                <img src={specialCubeGroup[cubeGroup.COMPLETE_GET_BANNER]} alt="" />
                            </div>
                        </div>
                    }
                    {/* 特典画像（取得済み）. */}
                    {(isDisplayGift && isGotBanner) &&
                        <div className="c-prof__datacubeCorrection__banner">
                            <div className={classes.unClickable + " c-prof__datacubeCorrection__bannerItem"}>
                                <img src={specialCubeGroup[cubeGroup.COMPLETE_GOT_BANNER]} alt="" />
                            </div>
                        </div>
                    }
                    <div className="c-prof__datacubeCorrection__list">
                        <ul className="c-prof__datacubeCorrection__listBox">
                            {Object.entries(displayDataCubeList).map(([, value]) => (
                                <li key={value[cube.CUBE_ID]} className={"c-prof__datacubeCorrection__listItem " + value[cube.CUBE_ID]}>
                                    <DataCube meta={value} handleDataCube={props.handleDataCube}/>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </span>
    );
};

export default SpecialCubeGroup;
