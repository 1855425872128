import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Parallax from "react-rellax";
import * as screenActions from "../../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import * as historys from "../../../../historys";
import * as app from "../../../../constants/app";
import { LOGIN_LP_TITLE } from "../../../../constants/title";
import { setHeadDefault } from "../../../../constants/sns";

/**
 * ログインLP画面（アプリ対応）.
 */
const Login = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // アプリ以外からの遷移は404へ遷移.
        if (!app.isAllApp()) {
            historys.historyNotFound(history);
        }

        document.body.classList.add("is-fixed");
        dispatch(screenActions.appLogin());

        // head設定.
        setHeadDefault(LOGIN_LP_TITLE);

        return () => document.body.classList.remove("is-fixed");
    }, [dispatch, history]);

    return (
        <>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="p-popup">
                    <div className="p-popup__box p-popup__box--largeBox p-popup__box--largeBox--app p-popup__box--largeBox--app--tinBlack">
                        <div className="p-popupBox">
                            <div className="c-static">
                                <div className="c-static__appHead">
                                    <div className="c-static__box c-static__box--app">
                                    <div className="c-static__boxTxt">このアプリケーションの利用には、<br/><span className="c-staticUnderTxt">TSUBURAYA IMAGINATION</span>への<br/>登録が必要です。</div>
                                    </div>
                                </div>
                                <div className="c-static__appSect">
                                    <div className="c-static__appSectWrap">
                                        <section className="c-static__appSectItem">
                                            <div className="c-static__appSectNum">
                                                <div className="c-static__appSectNumTxt">1</div>
                                            </div>
                                            <div className="c-static__appSectDtl">Web ブラウザから「ウルトラサブスク」と検索</div>
                                            <div className="c-static__appSectSearch">
                                                <div className="c-static__appSectSearchTxt">ウルトラサブスク</div>
                                                <div className="c-static__appSectSearchIcon c-picIconSearch"></div>
                                            </div>
                                            <div className="c-static__appSectChara"><img src="/assets/images/img-lp-chara-01.png" alt="" /></div>
                                        </section>
                                    <section className="c-static__appSectItem c-static__appSectItem--rank">
                                        <div className="c-static__appSectNum">
                                            <div className="c-static__appSectNumTxt">2</div>
                                        </div>
                                        <div className="c-static__appSectDtl">TSUBURAYA IMAGINATIONの<br/><span className="c-staticUnderTxt">Web サイトからアカウント</span>を作成してくだい。</div>
                                        <div className="c-static__appSectRank--w120"><img src="/assets/images/img-lp-rank-01.jpg" alt="" /></div>
                                        <div className="c-static__appSectRankDtl">TSUBURAYA IMAGINATIONのWeb サイトの<br/>トップぺージです。</div>
                                    </section>
                                    </div>
                                </div>
                                <div className="c-static__appStart">
                                    <div className="c-static__appStartItem"><img src="/assets/images/img-lp-start-01.png" alt="" /></div>
                                    <div className="c-static__appStartTxt">IMAGINATION（イマジネーション）の宇宙で<br/>お待ちしています！</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;