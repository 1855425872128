/** ID検索API実行成功. */
export const INIT_VIDEO_ID = "INIT_VIDEO_ID";

/** ID検索API実行成功. */
export const POST_IDSEARCH_SUCCESS = "POST_IDSEARCH_SUCCESS";

/** メタ検索API（シリーズ）実行成功. */
export const POST_SEARCH_SERIES_SUCCESS = "POST_SEARCH_SERIES_SUCCESS";

/** メタ検索API（シリーズ）（2回目）実行成功. */
export const POST_SEARCH_SERIES_ADD_SUCCESS = "POST_SEARCH_SERIES_ADD_SUCCESS";

/** メタ検索API（プラネット関連）実行成功. */
export const POST_SEARCH_RECOMMEND_SUCCESS = "POST_SEARCH_RECOMMEND_SUCCESS";

/** 視聴開始API実行成功. */
export const POST_START_WATCH_SUCCESS = "POST_START_WATCH_SUCCESS";

/** 再生URL変更. */
export const UPDATE_PLAY_URL = "UPDATE_PLAY_URL";