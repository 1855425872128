import * as actionTypes from "./actionTypes";

/**
 * 会員プラン選択画面を表示.
 */
export const openPopupPlanSelect = (isNotJoin, isPremiumContents) => {
    return {
        type: actionTypes.OPEN_POPUP_PLAN_SELECT,
        isNotJoin: isNotJoin,
        isPremiumContents: isPremiumContents
    };
}

/**
 * 会員プラン選択画面を閉じる.
 */
export const closePopupPlanSelect = () => {
    return {
        type: actionTypes.CLOSE_POPUP_PLAN_SELECT
    };
}

/**
 * 会員プラン詳細画面を表示.
 */
export const openPopupPlanDetail = () => {
    return {
        type: actionTypes.OPEN_POPUP_PLAN_DETAIL
    };
}

/**
 * 会員プラン詳細画面を閉じる.
 */
export const closePopupPlanDetail = () => {
    return {
        type: actionTypes.CLOSE_POPUP_PLAN_DETAIL
    };
}

/**
 * 会員プラン選択画面を表示（クーポン引き渡し）.
 */
export const openPopupPlanSelectCoupon = (isNotJoin, isPremiumContents, coupon) => {
    return {
        type: actionTypes.OPEN_POPUP_PLAN_SELECT_COUPON,
        isNotJoin: isNotJoin,
        isPremiumContents: isPremiumContents,
        couponCode: coupon
    };
}