/** ボリュームの変更. */
export const HANDLE_VOLUME = "HANDLE_VOLUME";

/** ミュート判定. */
export const HANDLE_MUTE = "HANDLE_MUTE";

/** 画質設定ポップアップの表示/非表示. */
export const CHANGE_IS_IMAGE_QUALITY = "CHANGE_IS_IMAGE_QUALITY";

/** 全体設定の画質（ブラウザ版） */
export const ALL_QUALITY = "ALL_QUALITY";

/** 全体設定の画質（アプリ版/Wi-Fi） */
export const ALL_WIFI_QUALITY = "ALL_WIFI_QUALITY";

/** 全体設定の画質（アプリ版/モバイル） */
export const ALL_MOBILE_QUALITY = "ALL_MOBILE_QUALITY";