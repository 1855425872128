/**
 * コンテンツタイプ.
 */

import * as contentsKey from "../constants/contentsKey";
import * as plan from "../constants/plan";

/** 詳細画面 映像. */
export const VIDEO = "video";

/** 詳細画面 映像（ライブ）. */
export const LIVE = "tv";

/** 詳細画面 情報. */
export const WEB = "web";

/** データキューブ. */
export const CUBE = "cube";

/**
 * Videoコンテンツか判定.
 */
export const isVideo = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.CONTENT_TYPE] === VIDEO;
}

/**
 * Liveコンテンツか判定.
 */
 export const isLive = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.CONTENT_TYPE] === LIVE;
}

/**
 * プレミアムコンテンツか判定.
 * planList内にpremiumのみが指定されていれば、プレミアムコンテンツと判定
 */
 export const isPremium = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    if (!meta[contentsKey.PLAN_LIST]) return false;
    return meta[contentsKey.PLAN_LIST].length === 1 && meta[contentsKey.PLAN_LIST].includes(plan.PREMIUM);
}

/**
 * 無料コンテンツか判定.
 */
export const isFree = (meta) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    return meta[contentsKey.IS_FREE];
}

/**
 * 特典映像コンテンツか判定.
 * 動画であり、キャンペーンIDか特典元CRIDがあれば、特典動画である
 */
export const isGiftVideo = (meta) => {
    if (isVideo(meta)) {
        // 動画であり、キャンペーンIDか特典元CRIDがあれば、特典動画である
        if  (meta[contentsKey.CAMPAIGN_ID] ||  meta[contentsKey.GIFT_SOURCE_CRID]) {
            return true;
        }
    }
    return false;
}

/**
 * 視聴中コンテンツか判定.
 */
export const isResume = (meta, resumeList) => {
    if (meta[contentsKey.LIST_FLG]) return false;
    if (resumeList) {
        for (const resume of resumeList) {
            if (resume[contentsKey.CRID] === meta[contentsKey.CRID]) {
                return true;
            }
        }
    }
    return false;
}
