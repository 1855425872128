import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import Parallax from "react-rellax";
import * as screenActions from "../../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import * as apiUtil from "../../../../constants/apiUtil";
import * as historys from "../../../../historys";
import * as app from "../../../../constants/app";
import { BRANCH_TITLE } from "../../../../constants/title";
import { setHeadDefault } from "../../../../constants/sns";

/**
 * 3分岐画面（アプリ対応）.
 */
const Branch = () => {
    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // アプリ以外からの遷移は404へ遷移.
        if (!app.isAllApp()) {
            historys.historyNotFound(history);
        }

        document.body.classList.add("is-fixed");
        dispatch(screenActions.appBranch());

        // head設定.
        setHeadDefault(BRANCH_TITLE);

        return () => document.body.classList.remove("is-fixed");
    }, [dispatch, history]);

    /**
     * ログイン画面に遷移.
     */
    const handleLogin = () => {
        // 円谷ログイン画面に遷移.
        if (app.isAllApp()) apiUtil.tsuburayaAppLogin(dispatch, location);
        else apiUtil.tsuburayaWebLogin(dispatch, location);
    };

    /**
     * ログインLPに遷移.
     */
    const handleLoginLP = () => {
        historys.historyAppLogin(history);
    };

    /**
     * トップ画面に遷移.
     */
    const handleTop = () => {
        historys.historyTop(history);
    };

    return (
        <>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="p-popup">
                    <div className="p-popup__box p-popup__box--largeBox p-popup__box--largeBox--app p-popup__box--largeBox--app--tinGray">
                        <div className="p-popupBox">
                            <div className="p-popupBoxWrap p-popupBoxWrap--login p-popupBoxWrap--tinGray">
                                <div className="p-popupLoginDtl">
                                    <div className="p-popupLoginDtl__wrap">
                                        <div className="p-popupLoginIcon">
                                            <div className="p-popupLoginIcon__box">
                                                <div className="p-popupLoginIcon__wrap"><i className="p-popupLoginIcon__logo c-picIconTsuburaya"></i>
                                                    <p className="p-popupLoginIcon__txt">アカウント</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="p-popupLoginDtl__ttl">アプリの利用について</div>
                                        <div className="p-popupLoginDtl__txt">このアプリケーションの利用には、<br />TSUBURAYAアカウントが必要です。<br />あなたの今の状態を以下の３つから選択してください。</div>
                                        <div className="p-popupLoginBtn p-popupLoginBtn--red" onClick={handleLogin}>すでにTSUBURAYA IMAGINATIONを<br />利用している方</div>
                                        <div className="p-popupLoginBtn p-popupLoginBtn--red" onClick={handleLoginLP}>TSUBURAYA IMAGINATIONを<br />これから利用される方</div>
                                    </div>
                                </div>
                                <div className="p-popupLoginBtn p-popupLoginBtn--gray" onClick={handleTop}>ログインせずに<br />TSUBURAYA IMAGINATIONの世界を試し見する</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Branch;