import React, { useEffect, useRef, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { isIOS } from "react-device-detect";

import Badge from "@material-ui/core/Badge";
import useMediaQuery from "@material-ui/core/useMediaQuery";

import * as actions from "../status/actions";
import * as Searchactions from "../../components/screen/search/actions";
import * as Planetactions from "../../components/screen/planetId/actions";
import * as castActions from "../status/cast/actions";
import * as couponActions from "../status/coupon/actions";
import * as memberActions from "../status/member/actions";
import * as screenActions from "../status/screen/actions";
import * as planActions from "../popup/plan/actions";
import * as init from "../init";

import * as historys from "../../historys";
import * as app from "../../constants/app";
import * as apiUtil from "../../constants/apiUtil";
import * as error from "../../constants/error";
import { PROFILE_PICTURE } from "../../constants/domain";
import { WITHDRAWAL_LP_CRID } from "../../constants/setting";
import * as plan from "../../constants/plan";
import * as contentType from "../../constants/contentType";
import * as noticeKey from "../../constants/noticeKey";
import * as fileApi from "../../middleware/file";
import * as accountif from "../../middleware/accountif";
import * as contractif from "../../middleware/contractif";
import * as deliveryheif from "../../middleware/deliveryheif";
import * as creditUtil from "../popup/credit/creditUtils";
import * as topActions from "../../components/screen/top/actions";
import * as qualityActions from "../status/statusQuality/actions";

import Menu from "./Menu";
import Notice from "./Notice";
import Profile from "./Profile";
import DigitalCard from "./DigitalCard";
import ProfPicture from "./ProfPicture";
import SpecialCubeGroupList from "./SpecialCubeGroupList";
import SpecialCubeGroup from "./SpecialCubeGroup";
import DataCube from "./DataCube";
import CampaignList from "./CampaignList";
import GiftConfirm from "./GiftConfirm";
import PlanSelect from "../popup/plan/PlanSelect";
import PlanDetail from "../popup/plan/PlanDetail";
import InputCredit from "../popup/credit/InputCredit";
import SimpleInputCredit from "../popup/credit/SimpleInputCredit";
import CreditResult from "../popup/credit/CreditResult";
import NoticePopup from "../popup/notice/Notice";
import LecturePopup from "../popup/lecture/Lecture";
import CouponUsed from "../popup/coupon/CouponUsed";
import PlanCancel from "../../components/dialog/PlanCancel";
import Logout from "../../components/dialog/Logout";
import Withdrawal from "../../components/dialog/Withdrawal";
import Warning from "../../components/dialog/Warning";
import WarningFront from "../../components/dialog/WarningFront";
import WarningFrontTwo from "../../components/dialog/WarningFrontTwo";
import Progress from "../../components/dialog/Progress";

import { doLogout } from "../../components/dialog/Logout";

import styles from "./styles";

import { QUERY_COUPON } from "../../constants/static";

let backNum = 0

/**
 * ヘッダー（全画面共通）.
 */
const Header = () => {
    /** Responsive. */
    const matches = useMediaQuery("(min-width:768px)");

    /** CSS. */
    const classes = styles();

    /** Hooks. */
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    /** メニューボタンDOM. */
    const menuRef = useRef();
    /** メニューリストDOM. */
    const menuListRef = useRef();
    /** 通知ボタンDOM. */
    const noticeRef = useRef();
    /** プロフィールDOM. */
    const profRef = useRef();
    /** デジタル会員証DOM. */
    const digitalCardRef = useRef();
    /** プロフィール画像変更DOM. */
    const profPictureRef = useRef();
    /** キャンペーン一覧DOM. */
    const campaignListRef = useRef();
    /** スペシャル図鑑一覧DOM. */
    const specialCubeGroupListRef = useRef();
    /** スペシャル図鑑DOM. */
    const specialCubeGroupRef = useRef();
    /** データキューブ詳細DOM. */
    const dataCubeRef = useRef();
    /** 追加特典取得確認DOM. */
    const giftConfirmRef = useRef();

    /** プロフィール画像変更画面の表示/非表示. */
    const [isProfPicture, setIsProfPicture] = useState(false);
    /** ログアウト確認画面の表示/非表示. */
    const [isLogout, setIsLogout] = useState(false);
    /** プラン変更キャンセル確認画面の表示/非表示. */
    const [isPlanCancel, setIsPlanCancel] = useState(false);
    /** プラン未選択エラー（アプリ対応）. */
    const [notSelectedPlanError, setNotSelectedPlanError] = useState(false);

    /** 初期処理判定. */
    const isInit = useSelector(state => state.Common.isInit);
    /** 会員ID. */
    const memberId = useSelector(state => state.Member.memberId);
    /** プロフィール画像. */
    const profPicture = useSelector(state => state.Member.profPicture);
    /** プロフィール画像リスト. */
    const profPictureList = useSelector(state => state.Member.profPictureList);
    /** 通知一覧. */
    const noticeList = useSelector(state => state.Member.noticeList);
    /** プラン選択済みフラグ. */
    const planSelectedFlg = useSelector(state => state.Member.planSelectedFlg);
    /** クレジットカード番号. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** 汎用設定（app_config_web）. */
    const appConfigWeb = useSelector(state => state.Member.appConfigWeb);
    /** トップ画面判定. */
    const isTop = useSelector(state => state.Screen.isTop);
    /** 検索画面判定. */
    const isSearch = useSelector(state => state.Screen.isSearch);
    /** メニューの表示/非表示. */
    const isMenu = useSelector(state => state.Screen.isMenu);
    /** 通知の表示/非表示. */
    const isNotice = useSelector(state => state.Screen.isNotice);
    /** プロフィールの表示/非表示. */
    const isProf = useSelector(state => state.Screen.isProf);
    /** デジタル会員証の表示/非表示. */
    const isDigitalCard = useSelector(state => state.Screen.isDigitalCard);
    /** キャンペーン一覧の表示/非表示. */
    const isCampaignList = useSelector(state => state.Screen.isCampaignList);
    /** スペシャル図鑑一覧の表示/非表示. */
    const isSpecialCubeGroupList = useSelector(state => state.Screen.isSpecialCubeGroupList);
    /** スペシャル図鑑の表示/非表示. */
    const isSpecialCubeGroup = useSelector(state => state.Screen.isSpecialCubeGroup);
    /** データキューブ詳細の表示/非表示. */
    const isDataCube = useSelector(state => state.Screen.isDataCube);
    /** 追加特典取得確認の表示/非表示. */
    const isGiftConfirm = useSelector(state => state.Screen.isGiftConfirm);
    /** 退会確認画面の表示/非表示. */
    const isWithdrawal = useSelector(state => state.Screen.isWithdrawal);
    /** 端末時刻判定（WARN）. */
    const isTimeWarn = useSelector(state => state.Common.isTimeWarn);
    /** 端末時刻判定（NG）. */
    const isTimeNG = useSelector(state => state.Common.isTimeNG);
    /** 通信失敗. */
    const isConectionError = useSelector(state => state.Common.isConectionError);
    /** プロフィール画像変更失敗. */
    const isChangeProfPictureFailure = useSelector(state => state.Common.isChangeProfPictureFailure);
    /** 会員プラン選択画面の表示/非表示. */
    const popupPlanSelect = useSelector(state => state.Plan.popupPlanSelect);
    /** 会員プラン詳細画面の表示/非表示. */
    const popupPlanDetail = useSelector(state => state.Plan.popupPlanDetail);
    /** クレジットカード情報入力ダイアログの表示/非表示. */
    const popupInputCredit = useSelector(state => state.Credit.popupInputCredit);
    /** 決済情報入力ダイアログの表示/非表示. */
    const popupSimpleInputCredit = useSelector(state => state.Credit.popupSimpleInputCredit);
    /** 決済完了ダイアログの表示/非表示. */
    const popupCreditResult = useSelector(state => state.Credit.popupCreditResult);
    /** push通知の表示/非表示. */
    const popupNotice = useSelector(state => state.Notice.popupNotice);
    /** 使い方レクチャーの表示/非表示. */
    const popupLecture = useSelector(state => state.Lecture.popupLecture);
    /** クーポン行使画面の表示/非表示. */
    const isCouponUsed = useSelector(state => state.Common.isCouponUsed);
    /** プランキャンセル失敗 */
    const isCancelPlanFailure = useSelector(state => state.Common.isCancelPlanFailure);
    /** 退会失敗 */
    const isWithdrawalFailure = useSelector(state => state.Common.isWithdrawalFailure);
    /** ログアウト失敗 */
    const isLogoutFailure = useSelector(state => state.Common.isLogoutFailure);
    /** お気に入り変更失敗 */
    const favoriteFailure = useSelector(state => state.Common.favoriteFailure);
    /** キャンペーン遷移失敗 */
    const isCampaignDestinationFailure = useSelector(state => state.Common.isCampaignDestinationFailure);
    /** データキューブ情報取得失敗 */
    const isDataCubeFailure = useSelector(state => state.Common.isDataCubeFailure);
    /** 追加特典取得失敗 */
    const isMoreGiftFailure = useSelector(state => state.Common.isMoreGiftFailure);
    /** 追加特典取得失敗（オンラインクーポン） */
    const isMoreGiftCouponFailure = useSelector(state => state.Common.isMoreGiftCouponFailure);
    /** クーポン プラン未充足エラー */
    const isCouponUsedPlanFailure = useSelector(state => state.Common.isCouponUsedPlanFailure);
    /** クーポン行使エラー */
    const isCouponUsedFailure = useSelector(state => state.Common.isCouponUsedFailure);
    /** 処理中ダイアログ */
    const isLoading = useSelector(state => state.Common.isLoading);
    /** 海外エラー */
    const isOverseasError = useSelector(state => state.Common.isOverseasError);
    /** アプリ対応：アプリ閲覧不可LP警告. */
    const isAppNotDisplayLP = useSelector(state => state.Common.isAppNotDisplayLP);
    /** 動画コンテンツ情報. */
    const videoContent = useSelector(state => state.VideoId.content);
    /** 動画シリーズコンテンツリスト. */
    const videoContentsList = useSelector(state => state.VideoId.contentsList);
    /** 動画シリーズコンテンツリスト(2回目リクエスト分). */
    const addSeriesContentsList = useSelector(state => state.VideoId.addSeriesContentsList);
    /** 購入一覧. */
    const purchaseList = useSelector(state => state.Member.purchaseList);
    /** 特典一覧. */
    const giftList = useSelector(state => state.Member.giftList);
    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 視聴中一覧. */
    const resumeList = useSelector(state => state.Member.resumeList);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);
    /** 画質設定ポップアップの表示/非表示 */
    const isImageQuality = useSelector(state => state.Player.changeIsImageQuality);
    /** HTTPエラーコード. */
    const httpErrorCode = useSelector(state => state.Common.httpErrorCode);


    
    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // ファイル：プラン料金取得.
        fileApi.getPlan().then(fileApi.getPlanSuccess.bind(this, dispatch));
        // ファイル：汎用設定ファイル（app_config_web）取得.
        fileApi.getAppConfigWeb().then(fileApi.getAppConfigWebSuccess.bind(this, dispatch));

        // ログイン判定.
        if (apiUtil.getXToken() !== "") init.init(dispatch, noneReadMap);
        else {
            // reducerリセット.
            dispatch(actions.logout());
            // sessionStorageを削除.
            sessionStorage.clear();
            // 未ログイン時のお知らせ取得.
            init.noLoginPostNoticeList(dispatch);
        }
        // プラン未選択時は会員プラン選択画面を表示.
        if (memberId !== "" && !planSelectedFlg) {
            // アプリの場合はプラン未選択エラーダイアログ、Webの場合はプラン選択画面を表示.
            if (app.isAllApp()) {
                setNotSelectedPlanError(true);
            }
            else {
                // 会員プラン選択画面にクーポンコードを渡す
                // クエリよりクーポンコードを取得
                const query = location.search;
                let coupon = "";
                if (query && query.length > 0) {
                    const [key, value] = query.slice(1).split("&")[0].split("=");
                    if (key === QUERY_COUPON) {
                        coupon = value;
                    }
                }
                dispatch(planActions.openPopupPlanSelectCoupon(true, false, coupon));
            }
        }
        // eslint-disable-next-line
    }, [dispatch, memberId, planSelectedFlg]);

    /**
     * ログイン成功時に実行想定.
     */
    useEffect(() => {
        if (isInit && apiUtil.getXToken() !== "") init.init(dispatch, noneReadMap);
        // eslint-disable-next-line
    }, [dispatch, isInit]);

    /**
     * アプリフッター表示切替対応.
     */
    useEffect(() => {
        if (location.pathname.indexOf("/app/branch") !== -1) {
            // アプリフッター非表示.
            if (app.isAndroidApp()) {
                window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_FOOTER_HIDDEN;
            }
            if (app.isIosApp()) {
                window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_HIDDEN;
            }
        } else {
            // アプリフッター表示.
            if (app.isAndroidApp()) {
                window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_FOOTER_VISIBLE;
            }
            if (app.isIosApp()) {
                window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_FOOTER_VISIBLE;
            }
        }
    }, [location.pathname]);

    /**
     * プロフィール画像が不正パス時の処理.
     */
    useEffect(() => {
        if (profPictureList.length === 0) return;
        let flag = false;
        for (const picture of profPictureList) {
            if (picture[fileApi.IMAGE_PATH] === profPicture) {
                flag = true;
            }
        }
        if (!flag) dispatch(memberActions.updateProfPicture(profPictureList[0][fileApi.IMAGE_PATH]));
    }, [dispatch, profPicture, profPictureList]);

    /**
     * ログイン・ログアウト時にURLスキームを送信（アプリ対応）.
     */
    useEffect(() => {
        if (app.isAndroidApp()) {
            memberId === "" ?
            window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_LOGOUT :
            window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_LOGIN;
        }
        if (app.isIosApp()) {
            memberId === "" ?
            window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_LOGOUT :
            window.location.href = app.APP_URL_SCHEME_IOS + app.URL_SCHEME_LOGIN;
        }
    }, [memberId]);

    /**
     * メニューの表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (menuRef.current === null || menuListRef.current === null) return;

        // 表示切替.
        if (isMenu) {
            // 他の要素が開いていた場合は閉じる.
            if (isNotice) handleNotice();
            if (isProf) handleCloseProf();
            if (isSpecialCubeGroupList) handleCloseSpecialCubeGroup();
            menuRef.current.classList.add("is-active");
            menuListRef.current.classList.add("js-menuopen");
            // アプリ対応.
            if (app.isAllApp()) menuRef.current.style.display = "block";
        } else {
            menuRef.current.classList.remove("is-active");
            menuListRef.current.classList.remove("js-menuopen");
            // アプリ対応.
            if (app.isAllApp()) menuRef.current.style.display = "none";
        }
        // eslint-disable-next-line
    }, [isMenu]);

    /**
     * 通知の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (noticeRef.current.length === null) return;

        // 表示切替.
        if (isNotice) {
            // 他の要素が開いていた場合は閉じる.
            if (isMenu) handleMenu();
            if (isProf) handleCloseProf();
            if (isSpecialCubeGroupList) handleCloseSpecialCubeGroup();
            noticeRef.current.classList.add("js-noticeopen");
        } else {
            noticeRef.current.classList.remove("js-noticeopen");
        }
        // eslint-disable-next-line
    }, [isNotice]);

    /**
     * プロフィール表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (profRef.current === null) return;

        // 表示切替.
        if (isProf) {
            // 他の要素が開いていた場合は閉じる.
            if (isMenu) handleMenu();
            if (isNotice) handleNotice();
            if (isSpecialCubeGroupList) handleCloseSpecialCubeGroup();
            profRef.current.classList.add("js-profopen");
        } else {
            // 他の要素が開いていた場合は閉じる.
            if (isDigitalCard) handleDigitalCard();
            if (isCampaignList) handleCampaignList();
            profRef.current.classList.remove("js-profopen");
        }
        // eslint-disable-next-line
    }, [isProf]);

    /**
     * デジタル会員証の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (digitalCardRef.current === null) return;

        // 表示切替.
        isDigitalCard ?
        digitalCardRef.current.classList.add("is-show") :
        digitalCardRef.current.classList.remove("is-show");
    }, [isDigitalCard]);

    /**
     * キャンペーン一覧の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (campaignListRef.current === null) return;

        // 表示切替.
        isCampaignList ?
        campaignListRef.current.classList.add("is-show") :
        campaignListRef.current.classList.remove("is-show");
    }, [isCampaignList]);

    /**
     * スペシャル図鑑一覧の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (specialCubeGroupListRef.current === null) return;

        // 表示切替.
        if (isSpecialCubeGroupList) {
            // 他の要素が開いていた場合は閉じる.
            if (isMenu) handleMenu();
            if (isProf) handleCloseProf();
            if (isNotice) handleNotice();
            specialCubeGroupListRef.current.classList.add("is-show");
        } else {
            // 他の要素が開いていた場合は閉じる.
            if (isSpecialCubeGroup) handleSpecialCubeGroup();
            if (isDataCube) handleDataCube();
            if (isGiftConfirm) handleGiftConfirm();
            specialCubeGroupListRef.current.classList.remove("is-show");
        }
        // eslint-disable-next-line
    }, [isSpecialCubeGroupList]);

    /**
     * スペシャル図鑑の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (specialCubeGroupRef.current === null) return;

        // 表示切替.
        if (isSpecialCubeGroup) {
            // スペシャル図鑑一覧が非表示の場合は表示する.
            if (!isSpecialCubeGroupList) handleSpecialCubeGroupList();
            specialCubeGroupRef.current.classList.add("is-show");
        } else {
            specialCubeGroupRef.current.classList.remove("is-show");
        }
        // eslint-disable-next-line
    }, [isSpecialCubeGroup]);

    /**
     * データキューブ詳細の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (dataCubeRef.current === null) return;

        // 表示切替.
        isDataCube ?
        dataCubeRef.current.classList.add("is-show") :
        dataCubeRef.current.classList.remove("is-show");
    }, [isDataCube]);

    /**
     * 追加特典取得確認の表示/非表示.
     */
    useEffect(() => {
        // 要素の取得に失敗.
        if (giftConfirmRef.current === null) return;

        // 表示切替.
        isGiftConfirm ?
        giftConfirmRef.current.classList.add("is-show") :
        giftConfirmRef.current.classList.remove("is-show");
    }, [isGiftConfirm]);

    /**
     * ポップアップ表示中か.
     */
    const isShownPopup = useMemo(() => {
        return (isNotice || isProf || isDigitalCard || isCampaignList || isSpecialCubeGroupList || isSpecialCubeGroup || isDataCube || isGiftConfirm);
    }, [isNotice, isProf, isDigitalCard, isCampaignList, isSpecialCubeGroupList, isSpecialCubeGroup, isDataCube, isGiftConfirm]);

    /**
     * ポップアップ表示中は、背面スクロール禁止.
     * ポップアップを閉じた際、背面スクロール禁止を解除.
     */
    useEffect(() => {
        if (isShownPopup || (isMenu && !matches)) {
            document.body.style.overflow = "hidden";
        }
        else {
            document.body.style.overflow = "auto";
        }
    }, [isShownPopup, isMenu, matches]);

    /**
     * 3分岐画面への遷移（アプリ対応）.
     */
    window.appHandleBranch = () => {
        if (app.isAllApp()) {
            historys.historyAppBranch(history);
        }
    }

    /**
     * メニューの表示/非表示.
     */
    const handleMenu = () => {
        isMenu ?
        dispatch(screenActions.changeIsMenu(false)) :
        dispatch(screenActions.changeIsMenu(true));
    }

    /**
     * メニューの表示/非表示.（アプリ対応）
     */
    window.appHandleMenu = () => {
        if (app.isAllApp()) {
            isMenu ?
            dispatch(screenActions.changeIsMenu(false)) :
            dispatch(screenActions.changeIsMenu(true));
        }
    }

    /**
     * 通知の表示/非表示.
     */
    const handleNotice = () => {
        isNotice ?
        dispatch(screenActions.changeIsNotice(false)) :
        dispatch(screenActions.changeIsNotice(true));
    }

    /**
     * 通知の表示/非表示.（アプリ対応）
     */
    window.appHandleNotice = () => {
        if (app.isAllApp()) {
            isNotice ?
            dispatch(screenActions.changeIsNotice(false)) :
            dispatch(screenActions.changeIsNotice(true));
        }
    }

    /**
     * プロフィール表示/非表示.
     */
    const handleProf = () => {
        isProf ?
        dispatch(screenActions.changeIsProf(false)) :
        dispatch(screenActions.changeIsProf(true));
    }

    /**
     * プロフィールの表示/非表示.（アプリ対応）
     */
    window.appHandleProf = () => {
        if (app.isAllApp()) {
            isProf ?
            dispatch(screenActions.changeIsProf(false)) :
            dispatch(screenActions.changeIsProf(true));
        }
    }

    /**
     * デジタル会員証 or デジタル会員証 戻るボタン 押下.
     */
    const handleDigitalCard = () => {
        isDigitalCard ?
        dispatch(screenActions.changeIsDigitalCard(false)) :
        dispatch(screenActions.changeIsDigitalCard(true));
    }

    /**
     * キャンペーン一覧 or キャンペーン一覧戻るボタン 押下.
     */
    const handleCampaignList = () => {
        isCampaignList ?
        dispatch(screenActions.changeIsCampaignList(false)) :
        dispatch(screenActions.changeIsCampaignList(true));
    }

    /**
     * スペシャル図鑑一覧 or スペシャル図鑑一覧戻るボタン 押下.
     */
    const handleSpecialCubeGroupList = () => {
        isSpecialCubeGroupList ?
        dispatch(screenActions.changeIsSpecialCubeGroupList(false)) :
        dispatch(screenActions.changeIsSpecialCubeGroupList(true));
    }

    /**
     * スペシャル図鑑一覧の表示/非表示.（アプリ対応）
     */
    window.appHandleSpecialCubeGroupList = () => {
        if (app.isAllApp()) {
            isSpecialCubeGroupList ?
            dispatch(screenActions.changeIsSpecialCubeGroupList(false)) :
            dispatch(screenActions.changeIsSpecialCubeGroupList(true));
        }
    }

    /**
     * スペシャル図鑑バナー or スペシャル図鑑戻るボタン 押下.
     */
    const handleSpecialCubeGroup = (id) => {
        isSpecialCubeGroup ?
        dispatch(screenActions.changeIsSpecialCubeGroup(false, "", false)) :
        dispatch(screenActions.changeIsSpecialCubeGroup(true, id, false));
    }

    /**
     * データキューブ or データキューブ詳細戻るボタン 押下.
     */
    const handleDataCube = () => {
        isDataCube ?
        dispatch(screenActions.changeIsDataCube(false)) :
        dispatch(screenActions.changeIsDataCube(true));
    }

    /**
     * 追加特典確認の表示/非表示.
     */
    const handleGiftConfirm = () => {
        isGiftConfirm ?
        dispatch(screenActions.changeIsGiftConfirm(false)) :
        dispatch(screenActions.changeIsGiftConfirm(true));
    }

    /**
     * プロフィール要素のクローズ処理.
     */
    const handleCloseProf = () => {
        if (isProf) handleProf();
        if (isDigitalCard) handleDigitalCard();
        if (isCampaignList) handleCampaignList();
    }

    /**
     * スペシャル図鑑要素のクローズ処理.
     */
    const handleCloseSpecialCubeGroup = () => {
        if (isSpecialCubeGroupList) handleSpecialCubeGroupList();
        if (isSpecialCubeGroup) handleSpecialCubeGroup("");
        if (isDataCube) handleDataCube();
        if (isGiftConfirm) handleGiftConfirm();
    }

    /**
     * データキューブ詳細のTOPにスクロール.
     */
    const scrollTopDataCube = () => {
        // 要素の取得に失敗.
        if (dataCubeRef.current === null) return;
        dataCubeRef.current.scrollTop = 0;
    }

    /**
     * プロフィール画像変更ボタン押下.
     */
    const handleProfPicture = () => {
        // 要素の取得に失敗.
        if (profPictureRef.current === null) return;

        if (isProfPicture) {
            profPictureRef.current.classList.remove("is-show");
            setIsProfPicture(!isProfPicture);

            // 選択中を削除.
            const elementCurrent = document.getElementsByClassName("profPicture_is-current");
            // 要素の取得に失敗.
            if (elementCurrent.length !== 1) return;
            elementCurrent[0].classList.remove("is-current");
            elementCurrent[0].classList.remove("profPicture_is-current");
        } else {
            profPictureRef.current.classList.add("is-show");
            handleActiveProfPicture();
            setIsProfPicture(!isProfPicture);
        }
    };

    /**
     * プロフィール画像変更画面（選択中の画像をアクティブに変更）.
     */
    const handleActiveProfPicture = () => {
        const element = document.getElementsByClassName("c-prof__selectCharaItem");
        for (var i = 0; i < element.length; i++) {
            if (element[i].childNodes.length !== 1) return;
            if (element[i].childNodes[0].src.indexOf(profPicture) !== -1) {
                element[i].classList.add("is-current");
                element[i].classList.add("profPicture_is-current");
                return;
            }
        }
    };

    /**
     * ログイン.
     */
    const handleLogin = () => {
        if (isMenu) handleMenu();

        // 円谷ログイン画面に遷移.
        if (app.isAllApp()) apiUtil.tsuburayaAppLogin(dispatch, location);
        else apiUtil.tsuburayaWebLogin(dispatch, location);
    }

    /**
     * ログアウト.
     */
    const handleLogout = () => {
        if (isMenu) handleMenu();
        if (isProf) handleProf();
        setIsLogout(true);
    }

    /**
     * もどる押下時の処理.
     */
    const handleBack = () => {
        if(location.pathname.endsWith("search"))
        {
            const sessionNum = JSON.parse(sessionStorage.getItem("search"))
            sessionStorage.setItem("search",JSON.stringify(sessionNum.slice(0, sessionNum.length-1)))
            if(JSON.parse(sessionStorage.getItem("search")).length!==0){
                dispatch(Searchactions.backTrue())
            }else{
                history.goBack();
                sessionStorage.removeItem("search")
            }
        }else if(location.pathname.startsWith("/planet/")){
            const sessionNum = JSON.parse(sessionStorage.getItem("planet"))
            sessionStorage.setItem("planet",JSON.stringify(sessionNum.slice(0, sessionNum.length-1)))
            if(JSON.parse(sessionStorage.getItem("planet")).length!==0){
                dispatch(Planetactions.planetbackTrue())
            }else{
                history.goBack();
                sessionStorage.removeItem("planet")
            }
        }
        else{
            history.goBack();
        }
        
        // 黒画面が表示されるバグの対応.
        if (isIOS || app.isIosApp()) {
            setTimeout(() => {
                window.scrollTo(0, 1);
            }, 100);
        }
    }

    /**
     * トップ画面へ遷移（IMAGINATION BASEタブ）.
     */
    const historyTop = () => {
        if (isMenu) handleMenu();
        // IMAGINATION BASEアクティブタブを更新
        dispatch(topActions.changeTab(0));
        historys.historyTop(history);
    }

    /**
     * トップ画面に遷移（プラネットタブ）.
     */
    const historyPlanet = () => {
        if (isMenu) handleMenu();
        // プラネットアクティブタブを更新
        dispatch(topActions.changeTab(1));
        historys.historyTop(history);
    }

    /**
     * 検索一覧画面へ遷移.
     */
    const historySearch = () => {
        if (isMenu) handleMenu();
        const getSession = sessionStorage.getItem("search")
        if(getSession){
            sessionStorage.removeItem("search")
        }
        historys.historySearch(history);
    }

    /**
     * お気に入り一覧画面へ遷移.
     */
    const historyFavorite = () => {
        if (isMenu) handleMenu();
        historys.historyFavorite(history);
    }

    /**
     * 視聴中の作品一覧画面へ遷移.
     */
    const historyResume = () => {
        if (isMenu) handleMenu();
        historys.historyResume(history);
    }

    /**
     * レンタル・取得した作品一覧画面へ遷移.
     */
    const historyPurchase = () => {
        if (isMenu) handleMenu();
        historys.historyPurchase(history);
    }

    /**
     * イマジネーションとは画面へ遷移.
     */
    const historyImagination = () => {
        if (isMenu) handleMenu();
        historys.historyImagination(history);
    }

    /**
     * 操作ガイド画面に遷移.
     */
    const historyOperation = () => {
        if (isMenu) handleMenu();
        historys.historyOperation(history);
    }

    /**
     * FAQ/問い合わせ画面へ遷移.
     */
    const historyFaq = () => {
        if (isMenu) handleMenu();
        historys.historyFaq(history);
    }

    /**
     * 会員プラン選択画面を表示.
     */
    const handlePlanSelect = () => {
        if (isProf) handleProf();

        // アプリの場合、プランLPを遷移.
        if (app.isAllApp()) {
            historys.historyAppPlan(history);
        }
        else {
            dispatch(planActions.openPopupPlanSelect(false, false));
        }
    }

    /**
     * クレジットカード変更画面を表示.
     */
    const handleChangeCredit = () => {
        if (isProf) handleProf();

        creditUtil.goPopupCredit(dispatch, location, "", "", 0, creditNumber !== "");
    }

    /**
     * プラン変更キャンセル確認画面を表示.
     */
    const handlePlanCancel = () => {
        if (isProf) handleProf();
        setIsPlanCancel(true);
    }

    /**
     * 退会アンケートに遷移.
     */
    const handleWithdrawal = () => {
        if (isProf) handleProf();
        historys.historyContents(history, appConfigWeb[WITHDRAWAL_LP_CRID]);
    }

    /**
     * 通信失敗時の再試行.
     */
    const handleConectionError = () => {
        dispatch(actions.updateConectionError(false));
        window.location.reload();
    }

    /**
     * キャンペーン遷移失敗時の処理.
     */
    const handleCampaignDestinationFailure = () => {
        dispatch(actions.campaignDestinationFailure(false));
        // QRクッション画面からはTOP画面へ遷移.
        if (location.pathname.indexOf("/coupon/") !== -1) {
            historys.historyReplaceTop(history);
        }
    }

    /**
     * プラン未充足エラー時の処理.
     */
    const handleCouponPlanFailure = (flag) => {
        // クーポンコードを初期化.
        dispatch(couponActions.updateCouponCode(""));

        // アプリの場合はプランLP、Webの場合は会員プラン選択画面に遷移.
        if (app.isAllApp()) {
            historys.historyAppPlan(history);
        } else {
            // 「はい」押下時は会員プラン選択画面を表示.
            if (flag) dispatch(planActions.openPopupPlanSelect(false, false));
            // QRクッション画面からはTOP画面へ遷移.
            if (location.pathname.indexOf("/coupon/") !== -1) {
                historys.historyReplaceTop(history);
            }
        }

        // ダイアログを非表示.
        dispatch(actions.couponUsedPlanFailure(false));
    }

    /**
     * クーポン行使エラー時の処理.
     */
    const handleCouponFailure = () => {
        // クーポンコードを初期化.
        dispatch(couponActions.updateCouponCode(""));

        // QRクッション画面からはTOP画面へ遷移.
        if (location.pathname.indexOf("/coupon/") !== -1) {
            historys.historyReplaceTop(history);
        }

        // ダイアログを非表示.
        dispatch(actions.couponUsedFailure(false, "", ""));
    }

    /**
     * OSSライセンスページに遷移（Androidアプリのみ）.
     */
    const handleOssLicense = () => {
        window.location.href = app.APP_URL_SCHEME_ANDROID + app.URL_SCHEME_OSS_LICENSE;
    }

    /**
     * 3択画面判定（アプリ対応）.
     */
    const isAppBranch = () => {
        return location.pathname.indexOf("/app/branch") !== -1;
    }

    /**
     * Headerのcss（アプリ対応）.
     */
    const headerClass = () => {
        const cls = "l-header p-header";
        if (app.isAllApp() && !isAppBranch()) return cls + " l-header--app";
        return cls;
    }

    /**
     * もどるボタンのcss（アプリ対応）.
     */
    const backButtonClass = () => {
        const cls = " c-back";
        return app.isAllApp() ? cls + " c-back--app" : cls;
    }

    /**
     * プラン未選択エラー時の処理（アプリ対応）.
     */
    const handleNotSelectedPlanError = () => {
        setNotSelectedPlanError(false);
        doLogout(dispatch);
    }

    /**
     * シリーズのメタを返却する.（アプリ対応）
     */
    window.appGetSeriesMeta = () => {
        if (app.isAllApp()) {
            let seriesContentsList = []
            if (videoContentsList.length > 0) {
                seriesContentsList = videoContentsList.concat(addSeriesContentsList);
            }
            const seriesMeta = (seriesContentsList.length === 0 ? [videoContent] : seriesContentsList).map((vc) => {
                let lid = "";

                if (!plan.isNone(memberPlan)) {
                    // プラン充足確認.
                    if (!vc.campaignId && (vc.planList?.includes(memberPlan) || vc.isFree)) {
                        lid = memberPlan;
                    }

                    // レンタル・特典取得状況確認.
                    for (const purchase of purchaseList) {
                        if (purchase[contractif.LICENSE_ID] === vc.license[0]) {
                            lid = vc.license[0];
                            break;
                        }
                    }
                    for (const gift of giftList) {
                        if (gift[contractif.LICENSE_ID] === vc.license[0]) {
                            lid = vc.license[0];
                            break;
                        }
                    }
                }
                // VODとLIVEでリクエストキーを分けて設定.
                // VOD: cidで設定、LIVE: serviceIdで設定.
                let requestContent = {
                    [deliveryheif.KIND]: deliveryheif.KIND_MAIN,
                    [deliveryheif.CRID]: vc.crid,
                    [deliveryheif.LID]: lid
                };
                if (vc.contentType === contentType.VIDEO) {
                    requestContent = { ...requestContent, [deliveryheif.CID]: vc.cid };
                }
                else if (vc.contentType === contentType.LIVE) {
                    requestContent = { ...requestContent, [deliveryheif.SERVICE_ID]: vc.cid };
                }

                // 視聴開始APIリクエスト設定.
                vc["castStartWatch"] = {
                    [deliveryheif.AVAIL_STATUS]: vc.availStatus,
                    [deliveryheif.CONTENT_LIST]: [requestContent]
                };

                // 視聴可能かどうかの状態を設定.
                vc["castCanWatch"] = (lid !== "");

                // レジュームを設定.
                vc[accountif.STOP_POSITION] = 0;
                for (const resume of resumeList) {
                    if (resume[accountif.CRID] === vc.crid) {
                        vc[accountif.STOP_POSITION] = resume[accountif.STOP_POSITION];
                        break;
                    }
                }

                return vc;
            });
            return seriesMeta;
        }
    }

    /**
     * Cast状態を更新（Play）.
     */
    window.appCastPlay = (crid, deviceName) => {
        if (app.isAllApp()) {
            dispatch(castActions.castPlay(crid, deviceName));
            return "OK";
        }
    }

    /**
     * Cast状態を更新（Idle）.
     */
    window.appCastIdle = () => {
        if (app.isAllApp()) {
            dispatch(castActions.castIdle());
            return "OK";
        }
    }

    /**
     * Cast状態を更新（Stop）.
     */
    window.appCastStop = () => {
        if (app.isAllApp()) {
            if (apiUtil.getXToken() !== "") {
                dispatch(castActions.castStop());
                // レジューム情報取得API.
                accountif.getResume().then(
                    accountif.getResumeSuccess.bind(this, dispatch),
                    accountif.isSessionError.bind(this, dispatch)
                );
            }
            return "OK";
        }
    }

    /**
     * WiFiステータスON（画質全体設定）
     */
    window.appHandleWifiOn = () => {
        if(app.isAllApp()) {
            dispatch(qualityActions.setWifiStatus(true));
        }
    }

    /**
     * WiFiステータスOFF（画質全体設定）
     */
    window.appHandleWifiOff = () => {
        if(app.isAllApp()) {
            dispatch(qualityActions.setWifiStatus(false));
        }
    }

    const unreadNoticeCount = useMemo(() => {
        let count = 0;
        for (const nl of noticeList) {
            if (!nl[noticeKey.READ_FLG] && !noneReadMap[nl[noticeKey.ID]]) count += 1;
        }
        return count;
    }, [noticeList, noneReadMap]);

    const showConnetionErrorMessage = () => {
        let message = "再試行してください。"
        if (httpErrorCode !== "") {
            message += "(" + httpErrorCode + ")";
        }
        return message;
    };

    /**
     * ヘッダーの検索アイコン用.
     * 画質設定ポップアップの閉じるボタンとアイコンが重なった際に、検索画面に遷移するのを防ぐ.
     */
    const historySearchHeader = () => {
        if (!isImageQuality) historySearch()
    };

    return(
        <header className={headerClass()}>
            {/* 会員プラン選択画面. */}
            {popupPlanSelect && <PlanSelect />}
            {/* 会員プラン詳細画面. */}
            {popupPlanDetail && <PlanDetail />}
            {/* クレジットカード情報入力ダイアログ. */}
            {popupInputCredit && <InputCredit />}
            {/* 決済情報入力ダイアログ. */}
            {popupSimpleInputCredit && <SimpleInputCredit />}
            {/* 決済完了ダイアログ. */}
            {popupCreditResult && <CreditResult />}
            {/* push通知. */}
            {popupNotice && <NoticePopup />}
            {/* レクチャー画面. */}
            {popupLecture && <LecturePopup />}
            {/* クーポン行使画面. */}
            {isCouponUsed.status && <CouponUsed />}
            {/* ログアウト確認画面. */}
            {isLogout && <Logout setIsLogout={setIsLogout} />}
            {/* プラン変更キャンセル確認画面. */}
            {isPlanCancel && <PlanCancel setIsPlanCancel={setIsPlanCancel} />}
            {/* 退会確認画面. */}
            {isWithdrawal && <Withdrawal onClose={() => dispatch(screenActions.changeIsWithdrawal(false))} />}
            {/* 通信失敗. */}
            {isConectionError && <Warning input={{title: "通信エラー", content: showConnetionErrorMessage() }} onAccept={handleConectionError}/>}
            {/* プロフィール画像変更失敗. */}
            {isChangeProfPictureFailure && <Warning input={{title: "プロフィール画像変更失敗", content: "プロフィール画像の変更に失敗しました。"}} onAccept={() => dispatch(actions.updateChangeProfPictureFailure(false))}/>}
            {/* 端末時刻判定（WARN）. */}
            {isTimeWarn && <Warning input={{title: "端末時刻エラー", content: "端末時刻がずれています。端末側の設定を正しく変更してください。"}} onAccept={() => dispatch(actions.updateTimeWarn(false))}/>}
            {/* 端末時刻判定（NG）. */}
            {isTimeNG && <Warning input={{title: "端末時刻エラー", content: "端末時刻がずれています。端末側の設定を正しく変更してください。"}} onAccept={init.postTime}/>}
            {/* プランキャンセル失敗. */}
            {isCancelPlanFailure && <Warning input={{title: "プラン変更キャンセルエラー", content: "プラン変更のキャンセルに失敗しました。"}} onAccept={() => {dispatch(actions.cancelPlanFailure(false)); window.location.reload();}}/>}
            {/* 退会失敗. */}
            {isWithdrawalFailure && <Warning input={{title: "退会エラー", content: "退会に失敗しました。"}} onAccept={() => {dispatch(actions.withdrawalFailure(false)); window.location.reload();}}/>}
            {/* ログアウト失敗. */}
            {isLogoutFailure && <Warning input={{title: "ログアウトエラー", content: "ログアウトに失敗しました。"}} onAccept={() => dispatch(actions.logoutFailure(false))}/>}
            {/* お気に入り変更失敗. */}
            {favoriteFailure.status && <Warning input={{title: "お気に入りの変更失敗", content: favoriteFailure.content}} onAccept={() => dispatch(actions.favoriteFailure(false, ""))}/>}
            {/* キャンペーン遷移失敗. */}
            {isCampaignDestinationFailure && <Warning input={{title: error.CAMPAIGN_DESTINATION_FAILURE_TITIE, content: error.CAMPAIGN_DESTINATION_FAILURE_CONTENT}} onAccept={() => handleCampaignDestinationFailure()}/>}
            {/* データキューブ情報取得失敗. */}
            {isDataCubeFailure && <Warning input={{title: error.DATA_CUBE_FAILURE_TITLE, content: error.DATA_CUBE_FAILURE_CONTENT}} onAccept={() => dispatch(actions.dataCubeFailure(false))}/>}
            {/* 追加特典取得失敗. */}
            {isMoreGiftFailure && <Warning input={{title: error.MORE_GIFT_FAILURE_TITIE, content: error.MORE_GIFT_FAILURE_CONTENT}} onAccept={() => dispatch(actions.moreGiftFailure(false))}/>}
            {/* 追加特典取得失敗（オンラインクーポン）. */}
            {isMoreGiftCouponFailure && <Warning input={{title: error.MORE_GIFT_COUPON_FAILURE_TITIE, content: error.MORE_GIFT_COUPON_FAILURE_CONTENT}} onAccept={() => dispatch(actions.moreGiftCouponFailure(false))}/>}
            {/* クーポン プラン未充足エラー. */}
            {isCouponUsedPlanFailure && <WarningFrontTwo input={{title: error.COUPON_USED_FAILURE_PLAN_TITLE, content: error.COUPON_USED_FAILURE_PLAN_CONTENT}} onAccept={() => handleCouponPlanFailure(true)} onReject={() => handleCouponPlanFailure(false)}/>}
            {/* クーポン行使エラー. */}
            {isCouponUsedFailure.status && <Warning input={{title: isCouponUsedFailure.title, content: isCouponUsedFailure.content}} onAccept={() => handleCouponFailure()}/>}
            {/* 処理中ダイアログ. */}
            {isLoading && <Progress />}
            {/* 海外エラー. */}
            {isOverseasError && <Warning input={{title: "海外エラー", content: "日本国外ではご利用いただけません。"}} onAccept={() => dispatch(actions.overseasError(false))}/>}
            {/* プラン未選択エラー（アプリ対応）. */}
            {notSelectedPlanError && <Warning input={{title: error.APP_SELECT_PLAN_FAILURE_TITLE, content: error.APP_SELECT_PLAN_FAILURE_CONTENT}} onAccept={handleNotSelectedPlanError}/>}
            {/* アプリ閲覧不可LP警告（アプリ対応）. */}
            {isAppNotDisplayLP && <WarningFront input={{title: error.APP_NOT_DISPLAY_LP_TITLE, content: error.APP_NOT_DISPLAY_LP_CONTENT}} onAccept={() => dispatch(actions.appNotDisplayLP(false))}/>}
            <div className="c-ctrlMenu">
                {memberId === "" &&
                    <div className="c-ctrlMenu__NonMember">
                            <ul class="c-ctrlMenu__box__NonMember">
                                {/* 検索アイコン. */}
                                <li className={classes.searchIcon + " c-ctrlMenu__item"} key="c-ctrlMenu__itemIcon--search">
                                    {!isSearch && <span onClick={() => historySearchHeader()}><i className="c-ctrlMenu__itemIcon--search c-picIconSearch"></i></span>}
                                </li>
                                {/* 通知アイコン. */}
                                {/* アプリ対応：アプリで非表示. */}
                                {!app.isAllApp() &&
                                <li className={classes.clickable + " c-ctrlMenu__item"} key="c-ctrlMenu__NonMember__itemIcon--notice">
                                    <Badge
                                        badgeContent={unreadNoticeCount}
                                        classes={{
                                            badge: classes.noticeBadge
                                        }}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "right"
                                        }}
                                        onClick={() => handleNotice()}
                                    >
                                        <div className="c-ctrlMenu__unreadCount"></div><i className="c-ctrlMenu__itemIcon--notice c-picIconNotice"></i>
                                    </Badge>
                                </li>
                                }
                            </ul>
                        {!app.isAllApp() && !isAppBranch() && <span className={classes.clickable + " c-ctrlMenu__NonMemberBtn c-ctrlMenu__NonMemberBtn--login ctrlMenu__NonMemberBtn__txt"} onClick={() => handleLogin()}>ログイン&nbsp;/&nbsp;入会</span>}
                        {/* アプリ時 */}
                        {app.isAllApp() && !isAppBranch() && <span className={classes.clickable + " c-ctrlMenu__NonMemberBtn c-ctrlMenu__NonMemberBtn--login ctrlMenu__NonMemberBtn__txt"} onClick={() => handleLogin()}>ログイン</span>}
                    </div>
                }
                {memberId !== "" &&
                    <ul className="c-ctrlMenu__box">
                        {/* 検索アイコン. */}
                        <li className={classes.searchIcon +" c-ctrlMenu__item"} key="c-ctrlMenu__itemIcon--search">
                            {!isSearch && <span onClick={() => historySearchHeader()}><i className=" c-ctrlMenu__itemIcon--search c-picIconSearch"></i></span>}
                        </li>
                        {/* 通知アイコン. */}
                        {/* アプリ対応：アプリで非表示. */}
                        {!app.isAllApp() &&
                            <li className="c-ctrlMenu__item" key="c-ctrlMenu__itemIcon--notice">
                                <Badge
                                    badgeContent={unreadNoticeCount}
                                    classes={{
                                        badge: classes.noticeBadge
                                    }}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "right"
                                    }}
                                    onClick={() => handleNotice()}
                                >
                                    <div className="c-ctrlMenu__unreadCount"></div><i className="c-ctrlMenu__itemIcon--notice c-picIconNotice"></i>
                                </Badge>
                            </li>
                        }
                        {/* プロフィール画像. */}
                        {/* アプリ対応：アプリで非表示. */}
                        {!app.isAllApp() &&
                            <li className="c-ctrlMenu__item" key="c-ctrlMenu__itemIcon--logout" onClick={() => handleProf()}>
                                <img
                                	className="lozad loaded"
                                	data-src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)}
                                	src={profPicture === "" ? profPicture : (PROFILE_PICTURE + profPicture)}
                                	alt="" data-loaded="true" />
                            </li>
                        }
                    </ul>
                }
                {/* ＜もどる ボタン. */}
                {(!isTop && !isAppBranch()) &&
                <div className={classes.clickable + backButtonClass()} onClick={() => handleBack()}>
                        <div className="c-back__icon c-icon c-icon--arrow c-picIconArrow"></div>
                        <div className="c-back__txt">もどる</div>
                </div>
                }
            </div>
            {/* グローバルメニュー */}
            {/* web時. */}
            {!app.isAllApp() &&
                <span>
                    <div
                        className="c-menubtn c-glovbtn"
                        onClick={() => handleMenu()}
                        ref={menuRef}
                    >
                        <span></span><span></span><span></span>
                    </div>
                    <div
                        className="c-gnav"
                        ref={menuListRef}
                    >
                        <Menu
                            historyTop={historyTop}
                            historyPlanet={historyPlanet}
                            historySearch={historySearch}
                            historyFavorite={historyFavorite}
                            historyResume={historyResume}
                            historyPurchase={historyPurchase}
                            handleProf={handleProf}
                            historyImagination={historyImagination}
                            historyOperation={historyOperation}
                            historyFaq={historyFaq}
                            handleLogin={handleLogin}
                            handleLogout={handleLogout}
                            handleOssLicense={handleOssLicense}
                        />
                    </div>
                </span>
            }
            {/* アプリ時. */}
            {app.isAllApp() &&
                <div
                    className="c-gnav c-gnav--app"
                    ref={menuListRef}
                >
                    <div className="c-gnav__head c-gnav__head--app c-headnav c-headnav--app">
                        <div
                            className="c-menubtn c-glovbtn c-menubtn--closeApp"
                            onClick={() => handleMenu()}
                            ref={menuRef}
                        >
                            <span></span><span></span><span></span>
                        </div>
                    </div>
                    <Menu
                        historyTop={historyTop}
                        historyPlanet={historyPlanet}
                        historySearch={historySearch}
                        historyFavorite={historyFavorite}
                        historyResume={historyResume}
                        historyPurchase={historyPurchase}
                        handleProf={handleProf}
                        historyImagination={historyImagination}
                        historyOperation={historyOperation}
                        historyFaq={historyFaq}
                        handleLogin={handleLogin}
                        handleLogout={handleLogout}
                        handleOssLicense={handleOssLicense}
                    />
                </div>
            }
            {/* 通知. */}
            <div
                className="c-notice c-notice--app"
                ref={noticeRef}
            >
                <Notice handleNotice={handleNotice} />
            </div>
            <div
                className="c-profMenuWrap c-profMenuWrap--app"
                ref={profRef}
            >
                <div className="c-menubtn c-menubtn--close c-profbtn batsu is-active" onClick={() => handleCloseProf()}><span></span><span></span><span></span></div>
                {/* プロフィール. */}
                <div className="c-prof c-prof--app">
                    <Profile
                        handlePlanSelect={handlePlanSelect}
                        handleChangeCredit={handleChangeCredit}
                        handleProfPicture={handleProfPicture}
                        handleSpecialCubeGroupList={handleSpecialCubeGroupList}
                        handleCampaignList={handleCampaignList}
                        handleDigitalCard={handleDigitalCard}
                        handleLogout={handleLogout}
                        handleWithdrawal={handleWithdrawal}
                        handlePlanCancel={handlePlanCancel}
                    />
                </div>
                {/* デジタル会員証. */}
                <div
                    className="c-prof__digitalCard"
                    ref={digitalCardRef}
                >
                    <DigitalCard handleDigitalCard={handleDigitalCard} />
                </div>
                {/* プロフィール画像変更. */}
                <div
                    className="c-prof__selectChara"
                    ref={profPictureRef}
                >
                    <ProfPicture handleProfPicture={handleProfPicture}/>
                </div>
                {/* 参加・取得キャンペーン一覧 */}
                <div
                    className="c-prof__coupon"
                    ref={campaignListRef}
                >
                    <CampaignList handleCampaignList={handleCampaignList} />
                </div>
            </div>
            <div
                className="c-prof__datacubeTableBox c-prof__datacubeTableBox--app"
                ref={specialCubeGroupListRef}
            >
                <div className="c-menubtn c-menubtn--closeApp c-profbtn batsu is-active" onClick={() => handleCloseSpecialCubeGroup()}><span></span><span></span><span></span></div>
                {/* スペシャル図鑑一覧. */}
                <div className="c-prof__datacube c-prof__datacube--app">
                    <SpecialCubeGroupList
                        handleProf={handleProf}
                        handleSpecialCubeGroupList={handleSpecialCubeGroupList}
                        handleSpecialCubeGroup={handleSpecialCubeGroup}
                    />
                </div>
                {/* スペシャル図鑑. */}
                <div
                    className="c-prof__datacubeTable c-prof__datacubeTable--app"
                    ref={specialCubeGroupRef}
                >
                    <SpecialCubeGroup
                        handleSpecialCubeGroupList={handleSpecialCubeGroupList}
                        handleSpecialCubeGroup={handleSpecialCubeGroup}
                        handleDataCube={handleDataCube}
                        handleGiftConfirm={handleGiftConfirm}
                    />
                </div>
                {/* データキューブ詳細. */}
                <div
                    className="c-prof__datacubeDtl c-prof__datacubeDtl--app"
                    ref={dataCubeRef}
                >
                    <DataCube handleDataCube={handleDataCube} scrollTopDataCube={scrollTopDataCube} />
                </div>
                {/* 追加特典取得確認 */}
                <div
                    className="c-prof__cubeInfo c-prof__cubeInfo--app"
                    ref={giftConfirmRef}
                >
                    <GiftConfirm handleGiftConfirm={handleGiftConfirm} />
                </div>
            </div>
            {isShownPopup && <div className="c-profBg is-active"></div>}
        </header>
    );
};

export default Header;
