import * as actionTypes from "./actionTypes";

const initState = {
    popupInputCredit: false,
    popupSimpleInputCredit: false,
    popupCreditResult: false,
    kind: "",
    plan: "",
    amount: 0,
    cardlistId: ""
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** クレジットカード情報入力ダイアログを表示. */
        case actionTypes.OPEN_POPUP_INPUT_CREDIT:
            return { ...state, popupInputCredit: true, kind: action.kind, plan: action.plan, amount: action.amount };

        /** クレジットカード情報入力ダイアログを閉じる. */
        case actionTypes.CLOSE_POPUP_INPUT_CREDIT:
            return { ...state, popupInputCredit: false };

        /** 決済情報入力ダイアログを表示. */
        case actionTypes.OPEN_POPUP_SIMPLE_INPUT_CREDIT:
            return { ...state, popupSimpleInputCredit: true, kind: action.kind, plan: action.plan, amount: action.amount };

        /** 決済情報入力ダイアログを閉じる. */
        case actionTypes.CLOSE_POPUP_SIMPLE_INPUT_CREDIT:
            return { ...state, popupSimpleInputCredit: false };

        /** 決済完了ダイアログを表示. */
        case actionTypes.OPEN_POPUP_CREDIT_RESULT:
            return { ...state, popupCreditResult: true, popupInputCredit: false, popupSimpleInputCredit: false, kind: action.kind, plan: action.plan, amount: action.amount };

        /** 決済完了ダイアログを閉じる. */
        case actionTypes.CLOSE_POPUP_CREDIT_RESULT:
            return { ...state, popupCreditResult: false, popupInputCredit: false, popupSimpleInputCredit: false };

        /** 決済情報入力ダイアログを閉じる. */
        case actionTypes.SAVE_CARDLIST_ID:
            return { ...state, cardlistId: action.res };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;