/** 画質定数. */
/** 10以上だと自動と判定する. */
export const HD_AUTO = 50;
export const HD_1080 = 4;
export const HD_720 = 3;
export const HD_540 = 2;
export const HD_480 = 1;
export const HD_180 = 0;
export const HD_INDEX_LIST = [4, 3, 2, 1, 0];

export const SD_AUTO = 20;
export const SD_480 = 1;
export const SD_180 = 0;
export const SD_INDEX_LIST = [1, 0];

export const DATASAVER_INDEX_LIST = [1, 0];

export const HD_QUALITY_LIST = ["180", "480", "540", "720", "1080"];
export const SD_QUALITY_LIST = ["180", "480"];

/** ビットレート（FairPlayのライブのURLに指定する） */
export const BITRATE_1080 = "6000000";
export const BITRATE_720 = "4000000";
export const BITRATE_540 = "2000000";
export const BITRATE_480 = "1000000";
export const BITRATE_180 = "500000";

export const BITRATE_LIST = [BITRATE_180, BITRATE_480, BITRATE_540, BITRATE_720, BITRATE_1080];
