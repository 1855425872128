import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { isWithdrawalReasonContents } from "../../constants/setting";
import { changeIsWithdrawal } from "../../common/status/screen/actions";

/**
 * スタンダードなダイアログ(選択肢1つ).
 *
 * props
 *  -> input:title   タイトル.
 *  -> input:content 内容.
 *  -> input:ok     「OK」の文言変更（省略可）.
 *  -> onAccept     「OK」押下時の処理.
 */
const DefaultOne = (props) => {
    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();

    /** 汎用設定（app_config_web）. */
    const appConfigWeb = useSelector(state => state.Member.appConfigWeb);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        return () => {
            document.body.classList.remove("is-fixed");
        }
    }, []);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * 「はい」押下時の処理.
     */
    const onAccept = () => {
        props.onAccept();

        // 退会アンケートコンテンツの場合は、退会確認ダイアログを表示.
        if (isWithdrawalReasonContents(location, appConfigWeb)) dispatch(changeIsWithdrawal(true));
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup p-popup--dialog">
                <div className="p-popup__box">
                    <div className="p-popup__wrap p-popup__wrap--dialog">
                        <div className="p-popup__dtl">
                            <div className="p-popup__ttl">{props.input.title}</div>
                            <div className="p-popup__txt">{props.input.content}</div>
                            <div className="p-popup__aplBtn c-btn c-btn--thinRed" onClick={() => onAccept()}>{props.input.ok ? props.input.ok : "OK"}</div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default DefaultOne;
