import { AUTHIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";

/** type:ログイン. */
export const WEB_LOGIN = "web_login";

/** type:再リンク. */
export const WEB_RELINK = "web_relink";

/** type:アプリログイン. */
export const APP_LOGIN = "app_login";

/** type:再認証. */
export const WEB_DOUBLE = "web_double";

/** destination:TSUBURAYAアカウント. */
export const TSUBURAYA = "tsuburaya";

/** destination:dアカウント. */
export const DACCOUNT = "daccount";

/** destination:dアカウント、prompt=loginを指定する場合. */
export const DACCOUNT_FORCE = "daccount_force";

/** 操作種別. */
export const TYPE = "type";

/** アカウント種別. */
export const DESTINATION = "destination";

/**
 * ログインURL取得API.
 * API: POST /login
 */
export async function postLogin(data) {
    let form = document.createElement("form");
    form.action = AUTHIF + "/login";
    form.method = "post";

    let typeInput = document.createElement("input");
    typeInput.type = "hidden";
    typeInput.name = TYPE;
    typeInput.value = data[TYPE];

    let destInput = document.createElement("input");
    destInput.type = "hidden";
    destInput.name = DESTINATION;
    destInput.value = data[DESTINATION];

    form.appendChild(typeInput);
    form.appendChild(destInput);
    document.body.appendChild(form);
    form.submit();
};

/**
 * ログアウトAPI.
 * API: POST /logout
 */
export async function postLogout(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(AUTHIF + "/logout", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}
