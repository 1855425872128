import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import * as contentif from "./contentif";
import * as domain from "../constants/domain";
import * as memberActions from "../common/status/member/actions";

/** ファイルキャッシュを設定（15分）. */
const cache = setupCache({
    maxAge: 15 * 60 * 1000
});
const api = axios.create({
    adapter: cache.adapter
});

/** プラン. */
export const PLAN = "plan";

/** 画像リスト. */
export const IMAGE_LIST = "image_list";

/** 画像URL. */
export const IMAGE_PATH = "image_path";

/**
 * ファイル：プラン料金取得.
 * API: GET
 */
export async function getPlan() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_PLAN)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：汎用設定ファイル（app_config_web）取得.
 * API: GET
 */
export async function getAppConfigWeb() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_APP_CONFIG_WEB)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：プロフィール画像取得.
 * API: GET
 */
export async function getProfPicture() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_PROFILE_PICTURE)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：TOP定義取得.
 * API: GET
 */
export async function getTop(filename) {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_TOP + filename + "_" + contentif.getRequestPlatform() + "_v3.json")
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：プラネット定義取得.
 * API: GET
 */
export async function getPlanet() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_PLANET)
        .then(res => {
            resolve(res.data.planetMaster);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：プラネット定義取得.
 * API: GET
 */
 export async function getPlanetGroup() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_PLANET)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：ライブラリ定義ファイル取得.
 * API: GET
 */
export async function getDefinitionFile(filename) {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_DEFINITION + filename + ".json")
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * 静的コンテンツ取得.
 * API: GET
 */
export async function getContents(cid, flag) {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_TEMPLATE + cid + "/" + (flag ? "true_template.html" : "false_template.html"))
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：ランキングメタ定義取得.
 * API: GET
 */
 export async function getRankingMeta() {
    return new Promise((resolve, reject) => {
        api.get(domain.FILE_RANKING_META)
        .then(res => {
            resolve(res.data);
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ファイル：プラン料金取得成功時のデフォルト処理.
 */
export const getPlanSuccess = (dispatch, data) => {
    dispatch(memberActions.updatePlanPrice(data));
}

/**
 * ファイル：汎用設定ファイル（app_config_web）取得成功時のデフォルト処理.
 */
export const getAppConfigWebSuccess = (dispatch, data) => {
    dispatch(memberActions.updateAppConfigWeb(data));
}

/**
 * ファイル：プロフィール画像取得成功時のデフォルト処理.
 */
export const getProfPictureSuccess = (dispatch, data) => {
    const imageList = data[IMAGE_LIST];
    dispatch(memberActions.updateProfPictureList(imageList));
}

/**
 * ファイル：プラネット定義取得成功時のデフォルト処理.
 */
export const getPlanetSuccess = (dispatch, data) => {
    dispatch(memberActions.updatePlanetList(data));
}

export const getPlanetGroupSuccess = (dispatch, data) => {
    // FIXME getPlanetSuccessと統合して本メソッドを廃止したい
    dispatch(memberActions.updatePlanetList(data.planetMaster));
    dispatch(memberActions.updatePlanetGroupList(data));
}
