import * as actionTypes from "./actionTypes";

const initState = {
    /** 選択したプラン. */
    plan: "",
    /** 決済金額. */
    amount: 0,
    /** 決済種別. */
    kind: "",
    /** キャンペーン名. */
    campaignName: "",
    /** 無料期間. */
    freePeriod: "",
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 決済情報保存. */
        case actionTypes.SAVE_CREDIT_INFO:
            return {
                ...state,
                plan: action.plan,
                amount: action.amount,
                kind: action.kind
            };

        /** 無料キャンペーン情報保存. */
        case actionTypes.SAVE_CAMPAIGN_INFO:
            return {
                ...state,
                campaignName: action.campaignName,
                freePeriod: action.freePeriod
            };

        default:
            return state;
    }
};

export default reducer;