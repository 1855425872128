/** 詳細設定の画質 */
import * as actionTypes from "./actionTypes";
import { HD_AUTO } from "../../../constants/imageQuality";

const initState = {
    detailQuality: HD_AUTO
};

const reducer = (state = initState, action) => {
    switch(action.type) {
        case actionTypes.DETAIL_QUALITY:
            return { ...state, detailQuality: action.detailQuality };
        default:
            return state;
    }
};

export default reducer;