import * as actionTypes from "./actionTypes";

/**
 * クーポンコード更新.
 */
export const updateCouponCode = (data) => {
    return {
        type: actionTypes.UPDATE_COUPON_CODE,
        couponCode: data
    };
}