import * as actionTypes from "./actionTypes";

const initState = {
    /** 会員ID. */
    memberId: "",
    /** 会員プラン. */
    memberPlan: "none",
    /** プロフィール画像. */
    profPicture: "",
    /** 入会日. */
    joinDate: "",
    /** プラン選択済みフラグ. */
    planSelectedFlg: false,
    /** 有効期限. */
    expirationDate: "",
    /** 変更後の会員プラン. */
    changeMemberPlan: "",
    /** 変更日. */
    changeDate: "",
    /** クレジットカード番号. */
    creditNumber: "",
    /** キャンペーン中判定. */
    isCampaign: false,
    /** 通知一覧. */
    noticeList: [],
    /** お気に入り一覧. */
    favoriteList: [],
    /** 視聴中一覧. */
    resumeList: [],
    /** 視聴完了一覧. */
    completeList: [],
    /** 購入一覧（ライセンスID）. */
    purchaseList: [],
    /** 特典一覧（ライセンスID）. */
    giftList: [],
    /** キャンペーン一覧. */
    campaignList: [],
    /** スペシャル図鑑一覧. */
    specialCubeGroupList: [],
    /** スペシャル図鑑. */
    specialCubeGroup: {},
    /** データキューブ一覧. */
    dataCubeList: [],
    /** データキューブ詳細. */
    dataCube: {},
    /** プラン料金. */
    planPrice: {},
    /** 汎用設定（app_config_web）. */
    appConfigWeb: {},
    /** プロフィール画像リスト. */
    profPictureList: [],
    /** プラネットリスト（すべてのプラネット）. */
    planetList: [],
    /** プラネットグループ（プラネットセレクト）. */
    planetGroupList: []
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 会員情報更新. */
        case actionTypes.UPDATE_MEMBER_INFO:
            return {
                ...state,
                memberId: action.memberId,
                profPicture: action.profPicture,
                joinDate: action.joinDate,
                creditNumber: action.creditNumber,
                planSelectedFlg: action.planSelectedFlg
            };

        /** 通知一覧更新. */
        case actionTypes.UPDATE_NOTICE_LIST:
            return { ...state, noticeList: action.noticeList };

        /** お気に入りコンテンツ一覧更新. */
        case actionTypes.UPDATE_FAVORITE_LIST:
            return { ...state, favoriteList: action.favoriteList };

        /** 視聴中一覧更新. */
        case actionTypes.UPDATE_RESUME_LIST:
            return { ...state, resumeList: action.resumeList, completeList: action.completeList };

        /** 現在のプラン情報更新. */
        case actionTypes.UPDATE_MEMBER_PLAN:
            return {
                ...state,
                memberPlan: action.memberPlan,
                expirationDate: action.expirationDate,
                isCampaign: action.isCampaign
            };

        /** 変更後のプラン情報更新. */
        case actionTypes.UPDATE_CHANGE_MEMBER_PLAN:
            return {
                ...state,
                changeMemberPlan: action.changeMemberPlan,
                changeDate: action.changeDate
            };

        /** 購入一覧（ライセンスID）更新. */
        case actionTypes.UPDATE_PURCHASE_LIST:
            return {
                ...state,
                purchaseList: action.purchaseList,
                giftList: action.giftList
            };

        /** キャンペーン一覧更新. */
        case actionTypes.UPDATE_CAMPAIGN_LIST:
            return { ...state, campaignList: action.campaignList };

        /** スペシャル図鑑一覧更新. */
        case actionTypes.UPDATE_SPECIAL_CUBE_GROUP_LIST:
            return { ...state, specialCubeGroupList: action.specialCubeGroupList };

        /** スペシャル図鑑更新. */
        case actionTypes.UPDATE_SPECIAL_CUBE_GROUP:
            return { ...state, specialCubeGroup: action.specialCubeGroup };

        /** データキューブ一覧更新. */
        case actionTypes.UPDATE_DATA_CUBE_LIST:
            return { ...state, dataCubeList: action.dataCubeList };

        /** データキューブ更新. */
        case actionTypes.UPDATE_DATA_CUBE:
            return { ...state, dataCube: action.dataCube };

        /** ファイル：プラン料金更新. */
        case actionTypes.UPDATE_PLAN_PRICE:
            return { ...state, planPrice: action.planPrice };

        /** ファイル：汎用設定（app_config_web）更新. */
        case actionTypes.UPDATE_APP_CONFIG_WEB:
            return { ...state, appConfigWeb: action.appConfigWeb };

        /** ファイル：プロフィール画像リスト更新. */
        case actionTypes.UPDATE_PROF_PICTURE_LIST:
            return { ...state, profPictureList: action.profPictureList };

        /** ファイル：プラネット定義リスト更新. */
        case actionTypes.UPDATE_PLANET_LIST:
            return { ...state, 
                planetList: action.planetList
            };
        
        /** ファイル：プラネット定義リスト更新. */
        case actionTypes.UPDATE_PLANET_GROUP_LIST:
            return { ...state, 
                planetGroupList: action.planetGroupList
            };

        /** プロフィール画像更新. */
        case actionTypes.UPDATE_PROF_PICTURE:
            return { ...state, profPicture: action.profPicture };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
