import { CONTENTIF } from "../constants/domain";
import * as apiUtil from "../constants/apiUtil";
import * as app from "../constants/app";

/** 検索種別. */
export const SEARCH_TYPE = "searchType";
export const OPTION = "option";

/** 検索範囲（始点） 未指定の場合0. */
export const RANGE_START = "rangeStart";

/** 検索結果取得件数 . 未指定の場合100. */
export const ROW = "row";

export const ROW_DEFAULT = 30;
export const ROW_MIDDLECATEGORY = 8;
export const ROW_SERIES = 100;
export const ROW_RECOMMEND = 15;

/** カテゴリID. */
export const CATEGORY_ID = "categoryId";

/** プラネットID. */
export const PLANET_ID = "planetIdList";

/** シリーズ検索の親となるコンテンツID. */
export const PARENT_CRID = "parentCrid";

/** キーワード. */
export const KEYWORDS = "keywords";

/** ユーザのプラン. */
export const PLAN = "plan";

/** コンテンツリスト */
export const CONTENT_ID_LIST = "contentIdList";

/** ライセンスIDリスト */
export const LICENSE_ID_LIST = "licenseIdList";

/** プラットフォーム. */
export const PLATFORM = "platform";

/**
 * プラットフォームを取得.
 */
export const getRequestPlatform = () => {
    if (app.isAndroidApp()) return "android";
    if (app.isIosApp()) return "ios";
    return "web";
}

/**
 * 検索API.
 * API: POST /search
 */
export async function postSearch(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(CONTENTIF + "/search", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ID検索API.
 * API: POST /idsearch
 */
export async function postIdSearch(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(CONTENTIF + "/idsearch", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}

/**
 * ライセンスID検索API.
 * API: POST /licensesearch
 */
export async function postLicenseSearch(data) {
    return new Promise((resolve, reject) => {
        apiUtil.api.post(CONTENTIF + "/licensesearch", data)
        .then(res => {
            if (apiUtil.isSuccess(res.data)) {
                resolve(apiUtil.getData(res.data));
            } else {
                reject(apiUtil.getErrMsg(res.data));
            }
        })
        .catch(err => {
            reject(err.toString());
        });
    });
}
