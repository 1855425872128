import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import * as historys from "../../../historys";
import * as actions from "./actions";
import { readNoneNotice } from "../../../common/status/noneNotice/actions";
import * as noticeKey from "../../../constants/noticeKey";
import * as plan from "../../../constants/plan";
import * as accountif from "../../../middleware/accountif";

/**
 * push通知ポップアップ.
 */
const PopupNotice = () => {
    /** Hooks. */
    const history = useHistory();
    const dispatch = useDispatch();

    /** 会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** 通知一覧. */
    const noticeList = useSelector(state => state.Member.noticeList);
    /** コンテンツ情報. */
    const noticeInfo = useSelector(state => state.Notice.noticeInfo);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        document.body.classList.add("is-fixed");
        // ログイン時のみ既読APIを実行.
        if (!plan.isNone(memberPlan)) {
            let request = {};
            request[accountif.NOTICE_ID] = noticeInfo[noticeKey.ID];
            request[accountif.PLAN_NAME] = memberPlan;
            accountif.postNotice(request).then(
                accountif.postNoticeListSuccess.bind(this, dispatch),
                accountif.isSessionError.bind(this, dispatch)
            );
        }
        dispatch(readNoneNotice(noticeInfo[noticeKey.ID]));
        return () => document.body.classList.remove("is-fixed");
    }, [dispatch, memberPlan, noticeInfo]);

    /**
     * 右クリック時.
     */
    const handleContextMenu = (event) => {
        // 右クリックのメニュー非表示.
        event.preventDefault();
    };

    /**
     * 閉じるアイコン押下時.
     */
    const closePopup = () => {
        let popup = false;
        let notice;
        // 未読通知を検索.
        for (let i = 0; i < noticeList.length; i++) {
            if (!noticeList[i][accountif.READ_FLG] && !noneReadMap[noticeList[i][noticeKey.ID]] && noticeList[i][accountif.POPUP_TYPE] === accountif.BIG) {
                notice = noticeList[i];
                popup = true;
                break;
            }
        }

        // 表示対象がある場合は更新.
        if (popup) {
            dispatch(actions.openPopupNotice(notice));
        } else {
            dispatch(actions.closePopupNotice());
        }
    }

    /**
     * くわしくはこちら押下時.
     */
    const showDetali = () => {
        historys.historyContents(history, noticeInfo[noticeKey.LINK_CRID]);
        dispatch(actions.closePopupNotice());
    }

    return (
        <div
            onContextMenu={(event) => handleContextMenu(event)}
        >
            <div className="p-popup p-popup--info">
                <div className="p-popup__box">
                    <div className="p-popup__icon p-popup__icon--close" onClick={() => closePopup()}>
                        <div className="c-menubtn c-menubtn--popup"><span></span><span></span><span></span></div>
                    </div>
                    <div className="p-popup__wrap p-popup__wrap--info">
                        <div className="p-popup__dtl">
                            <div className="p-popup__img"><img src={noticeInfo[noticeKey.IMAGE_URL_AT_POPUP]} alt=""/></div>
                            <div className="p-popup__aplBtn c-btn c-btn--thinRed" onClick={() => showDetali()}>くわしくはこちら</div>
                            <div className="p-popup__aplBtnTxt"><i className="p-popup__notice c-picIconNotice"></i>
                                <p>お知らせのベルから後で読むことができます。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-popup__bg"></div>
            </div>
        </div>
    );
};

export default PopupNotice;
