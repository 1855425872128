import React from "react";

/**
 * セパレーター.
 *
 * props
 *  -> input:title タイトル.
 */
const Separator = (props) => {
    return (
        <h2 className="c-sect__band">{props.input.title}</h2>
    );
};

export default Separator;
