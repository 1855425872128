import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "./actions";
import * as memberActions from "../../../common/status/member/actions";
import * as couponActions from "../../../common/status/coupon/actions";
import { overseasError } from "../../../common/status/actions";
import * as credit from "../../../constants/credit";
import * as constantsPlan from "../../../constants/plan";
import * as apiUtil from "../../../constants/apiUtil";
import * as error from "../../../constants/error";
import { SONY_PAYMENT_TOKEN_URL } from "../../../env";
import * as payment from "../../../middleware/payment";
import * as contractif from "../../../middleware/contractif";
import * as accountif from "../../../middleware/accountif";
import * as creditUtil from "./creditUtils";
import Progress from "../../../components/dialog/Progress";
import ErrorFrontOne from "../../../components/dialog/ErrorFrontOne";
import WarningFront from "../../../components/dialog/WarningFront";
import * as cv from "../../../constants/cv";

/**
 * 決済画面.
 */
const InputCredit = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** カード番号. */
    const [cardNo, setCardNo] = useState("");
    /** カード有効期限（月）. */
    const [expirationMonth, setExpirationMonth] = useState("");
    /** カード有効期限（年）. */
    const [expirationYear, setExpirationYear] = useState("");
    /** セキュリティコード. */
    const [securityCode, setSecurityCode] = useState("");
    /** 生月日（月）. */
    const [birthMonth, setBirthMonth] = useState("");
    /** 生月日（日）. */
    const [birthDay, setBirthDay] = useState("");
    /** エラー状態. */
    const [isErrored, setIsErrored] = useState(false);
    /** ローディング状態. */
    const [isLoading, setIsLoading] = useState(false);
    /** 警告ダイアログ表示/非表示. */
    const [isWarning, setIsWarning] = useState(false);
    /** エラーダイアログ */
    const [isCriticalError, setIsCriticalError] = useState(false);
    /** apiエラーダイアログ表示/非表示. */
    //const [isApiError, setIsApiError] = useState(false);
    /** クーポン関連エラーダイアログ */
    const [couponErrorDialogState, setCouponErrorDialogState] = useState({open: false, title: "", content: ""});

    /** カード番号フォームDOM. */
    const cardNoRef = useRef();
    /** カード有効期限（月）フォームDOM. */
    const expirationMonthRef = useRef();
    /** カード有効期限（年）フォームDOM. */
    const expirationYearRef = useRef();
    /** セキュリティコードフォームDOM. */
    const securityCodeRef = useRef();
    /** 生月日（月）フォームDOM. */
    const birthMonthRef = useRef();
    /** 生月日（日）フォームDOM. */
    const birthDayRef = useRef();
    /** ソニーペイメントトークン取得でコールバック関数を呼び出したかどうかの状態. */
    const sonyCallBackRef = useRef(false);

    /** ダイアログ種別. */
    const kind = useSelector(state => state.Credit.kind);
    /** 選択された会員プラン. */
    const plan = useSelector(state => state.Credit.plan);
    /** 決済金額. */
    const amount = useSelector(state => state.Credit.amount);
    /** クレジットカードマスタの管理ID */
    //const cardlistId = useSelector(state => state.Credit.cardlistId);
    /** 現在の会員プラン. */
    const memberPlan = useSelector(state => state.Member.memberPlan);
    /** クーポンコード. */
    const couponCode = useSelector(state => state.Coupon.couponCode);
    /** キャンペーン名. */
    const campaignName = useSelector(state => state.LocalCredit.campaignName);
    /** 無料期間. */
    const freePeriod = useSelector(state => state.LocalCredit.freePeriod);
    /** 非会員お知らせ既読マップ. */
    const noneReadMap = useSelector(state => state.NoneNotice.noneReadMap);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // ソニーペイメントトークン取得スクリプト追加.
        const root = document.getElementById("spsvRoot");
        const spsvScript = document.createElement("script");
        spsvScript.type = "text/javascript";
        spsvScript.src = SONY_PAYMENT_TOKEN_URL;
        spsvScript.setAttribute("class", "spsvToken");
        spsvScript.setAttribute("callBackFunc", "setToken");
        root.appendChild(spsvScript);

        // ソニーペイメントトークン取得のコールバック関数スクリプト追加.
        const setTokenScript = document.createElement("script");
        setTokenScript.innerHTML = `
            function setToken (token,card) {
                document.getElementById("spsvToken").value = token;
                document.getElementById("spsvCard").value = card;
                document.getElementById("spsvToken").click();
            }`;
        root.appendChild(setTokenScript);

        document.body.classList.add("is-fixed");
        return () => {
            if (couponCode !== "") dispatch(couponActions.updateCouponCode(""));
            document.body.classList.remove("is-fixed");
        }
        // eslint-disable-next-line
    }, [dispatch]);

    /**
     * カード番号変更.
     */
    const handleCardNo = (event) => {
        setCardNo(event.target.value);
    };

    /**
     * カード有効期限（月）変更.
     */
    const handleExpirationMonth = (event) => {
        setExpirationMonth(event.target.value);
    };

    /**
     * カード有効期限（年）変更.
     */
    const handleExpirationYear = (event) => {
        setExpirationYear(event.target.value);
    };

    /**
     * セキュリティコード変更.
     */
    const handleSecurityCode = (event) => {
        setSecurityCode(event.target.value.replace(/[^0-9]/g, ''));
    };

    /**
     * 生月日（月）変更.
     */
    const handleBirthMonth = (event) => {
        setBirthMonth(event.target.value);
    };

    /**
     * 生月日（日）変更.
     */
    const handleBirthDay = (event) => {
        setBirthDay(event.target.value);
    };

    /**
     * キャンセルボタン押下時の処理.
     */
    const cancel = () => {
        dispatch(actions.closePopupInputCredit());
    };

    /**
     * 入力チェックとクレジットカードの登録・変更処理.
     */
    const submit = () => {
        // 入力チェック汎用関数.
        const checkInput = (pattern, name, nameRef) => {
            if (pattern.test(name)) {
                nameRef.classList.remove("p-formError");
                return false;
            } else {
                nameRef.classList.add("p-formError");
                return true;
            }
        };
        let isError = false;

        // カード番号の入力チェック.
        isError |= checkInput(/^[0-9]{11,16}$/, cardNo, cardNoRef.current);
        // カード有効期限（月）の入力チェック.
        isError |= checkInput(/^[0-9]{2}$/, expirationMonth, expirationMonthRef.current);
        // カード有効期限（年）の入力チェック.
        isError |= checkInput(/^[0-9]{2}$/, expirationYear, expirationYearRef.current);
        // セキュリティコードの入力チェック.
        isError |= checkInput(/^[0-9]{3,4}$/, securityCode, securityCodeRef.current);
        // 生月日（月）の入力チェック.
        isError |= checkInput(/^[0-9]{2}$/, birthMonth, birthMonthRef.current);
        // 生月日（日）の入力チェック.
        isError |= checkInput(/^[0-9]{2}$/, birthDay, birthDayRef.current);

        // 1つでもエラーがあれば、エラーメッセージを表示する.
        setIsErrored(isError);
        if (isError) return;

        setIsLoading(true);
        // ソニーペイメントトークン取得(クレジットカード登録).
        window.SpsvApi.spsvCreateToken(cardNo, expirationYear, expirationMonth, securityCode, birthMonth, birthDay, "", "", "");
    };

    /**
     * クレカ連携API(クレジットカード有効性チェック)を呼び出し.
     */
    const creditCardAuth = () => {
        // ソニーペイメントから突然コールバックされることがあるので入力チェックする.
        if (expirationYear === "" || expirationMonth === "" || birthMonth === "" || birthDay === "") return;
        // 既に呼び出している場合は、スキップ（二重呼び出し防止）.
        if (sonyCallBackRef.current) return;

        sonyCallBackRef.current = true;
        const bodyParams = {
            Command: payment.AUTH_CARD,
            CardNum: "",
            ExpDate: "20" + expirationYear + expirationMonth,
            Birthday: birthMonth + birthDay,
            Mode: 1,
            CardlistId: "",
            Token: document.getElementById("spsvToken").value
        };
        // key=value&key=value・・・のフォーマットに変換.
        const keys = Object.keys(bodyParams);
        let creditBody = "";
        for (let i = 0; i < keys.length; i++) {
            if (i !== 0) creditBody += "&";
            creditBody += keys[i] + "=" + bodyParams[keys[i]];
        };
        // 登録API呼び出し.
        if ([credit.CHANGE, credit.PLAN_EXPIRED, credit.RENTAL_EXPIRED, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
            // 変更・期限切れ更新の場合は、削除API実行後、登録APIを呼び出す.
            // payment.postPayment(`Command=${payment.DELETE_CARD_INFO}&CardlistId=${cardlistId}`)
            // .then((res) => {
                registerCreditCard(creditBody);
            // }, apiFailure);
        } else {
            registerCreditCard(creditBody);
        }
    };

    const registerCreditCard = (body) => {
        payment.postPayment(body, true).then(postPaymentSuccess, postPaymentFailure);
    };

    /**
     * クレカ連携API(クレジットカード有効性チェック)成功.
     */
    const postPaymentSuccess = (response) => {
        // セッションエラー確認.
        if (payment.isSessionError(dispatch, response)) {
            return;
        }

        // クレカ連携API(内部処理)の実行結果が"0"以外はエラー.
        if (response.Output !== undefined && response.Output.Result[0] !== "0") {
            postPaymentFailure();
            return;
        }

        // クレカ連携API(外部通信)の実行結果が"0"以外はエラー.
        if (response.OutputAuthCard.Result[0] !== "0") {
            postPaymentFailure();
            return;
        }

        // 会員情報のクレジットカード番号を更新.
        accountif.postUserInfo({"cardNumber": response.OutputAuthCard.CardNum[0]})
        .then((data) => {
            // 会員情報変更API(クレジットカード番号更新)成功.

            // storeのクレジットカード番号も更新.
            dispatch(memberActions.updateMemberInfo(data));

            switch (kind) {
                case credit.REGISTER:
                case credit.CHANGE:
                    // 決済自体はしないので、完了ダイアログを表示.
                    dispatch(actions.openPopupCreditResult(kind, plan, amount));
                    break;

                case credit.PLAN:
                case credit.PLAN_EXPIRED:
                case credit.PLAN_CAMPAIGN:
                case credit.PLAN_CAMPAIGN_EXPIRED:
                    // 契約管理API(プラン決済)呼び出し.
                    let contractBody = {
                        [contractif.CONTRACT_TYPE]: contractif.PLAN,
                        [contractif.BEFORE_PLAN]: memberPlan,
                        [contractif.AFTER_PLAN]: plan,
                        [contractif.CARDLIST_ID]: response.OutputAuthCard.CardlistId[0],
                        [contractif.SEC_CODE]: "000",
                        [contractif.CSRF_TOKEN]: response[payment.CSRF]
                    };
                    // 無料キャンペーンプランの場合、クーポンコードも送信.
                    if ([credit.PLAN_CAMPAIGN, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
                        // クーポンコードが空の場合は、不整合が発生しているのでエラーとする.
                        if (couponCode === "") {
                            setIsCriticalError(true);
                            return;
                        }
                        contractBody = {...contractBody, [contractif.COUPON_CODE]: couponCode}
                    }
                    contractif.postContractManage(contractBody)
                    .then(postContractManageSuccess, postContractManageFailure);
                    break;

                case credit.RENTAL:
                case credit.RENTAL_EXPIRED:
                    // 契約管理API(レンタル決済)呼び出し.
                    const contractLicenseBody = {
                        [contractif.CONTRACT_TYPE]: contractif.PURCHASE_TYPE,
                        [contractif.LICENSE_ID]: plan,
                        [contractif.CARDLIST_ID]: response.OutputAuthCard.CardlistId[0],
                        [contractif.SEC_CODE]: "000",
                        [contractif.CSRF_TOKEN]: response[payment.CSRF]
                    };
                    contractif.postContractManage(contractLicenseBody)
                    .then(postContractManageSuccess, postContractManageFailure);
                    break;

                default:
                    return;
            }
        }, (err) => {
            // セッションエラー確認.
            accountif.isSessionError(dispatch, err);

            // 会員情報変更API(クレジットカード番号更新)失敗.
            postPaymentFailure();

            // クレカ更新時の更新失敗は、クレカ連携APIの方の削除をしておく（ロールバック）.
            //if ([credit.CHANGE, credit.PLAN_EXPIRED, credit.RENTAL_EXPIRED, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
                payment.postPayment(`Command=${payment.DELETE_CARD_INFO}&CardlistId=${response.OutputAuthCard.CardlistId[0]}`);
            //}
        });
    };

    /**
     * クレカ連携API(クレジットカード有効性チェック)失敗.
     */
    const postPaymentFailure = () => {
        // クレカ更新時の登録失敗は、既存クレカ削除を行っているので、会員情報とReducerのクレカ番号を削除する.
        // if ([credit.CHANGE, credit.PLAN_EXPIRED, credit.RENTAL_EXPIRED, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
        //     accountif.postUserInfo({"cardNumber": ""}).then(
        //         (data) => dispatch(memberActions.updateMemberInfo(data)),
        //         accountif.isSessionError.bind(this, dispatch)
        //     );
        // }

        setIsLoading(false);
        setIsWarning(true);
        sonyCallBackRef.current = false;
    };

    /**
     *  契約管理API成功.
     */
    const postContractManageSuccess = (res) => {
        // プラン登録・変更時はCVを送信.
        if ([credit.PLAN, credit.PLAN_EXPIRED, credit.PLAN_CAMPAIGN, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
            // 非会員からの登録は新規入会.
            if (constantsPlan.isNone(memberPlan)) {
                cv.sendPlanCV(cv.SIGN_UP, plan);
            }
            // それ以外は、プランアップグレード.
            else {
                cv.sendPlanCV(cv.UP_SELL, plan);
            }
        }

        creditUtil.updateInfo(dispatch, res, noneReadMap);
        // 完了ダイアログを表示.
        dispatch(actions.openPopupCreditResult(kind, plan, amount));
    };

    /**
     * 契約管理API失敗.
     */
    const postContractManageFailure = (err) => {
        setIsLoading(false);

        // セッションエラーを確認.
        contractif.isSessionError(dispatch, err);

        const errCode = err[apiUtil.ERROR_CODE] ? err[apiUtil.ERROR_CODE].slice(-3) : "";
        if (errCode === contractif.OVERSEAS_ERROR_CODE) {
            dispatch(overseasError(true));
            cancel();
        }
        else {
            let title = "";
            let content = "";
            switch(err[apiUtil.ERROR_CODE]) {
                // クーポンが無効（存在しないなど）.
                case contractif.WAR04017:
                    title = error.COUPON_USED_FAILURE_INVALID_TITLE;
                    content = error.COUPON_USED_FAILURE_INVALID_CONTENT;
                    break;

                // クーポンが有効期間前.
                case contractif.WAR04018:
                    title = error.COUPON_USED_FAILURE_BEFORE_VALID_TITLE;
                    content = error.COUPON_USED_FAILURE_BEFORE_VALID_CONTENT;
                    break;

                // クーポンが期限切れ.
                case contractif.WAR04019:
                    title = error.COUPON_USED_FAILURE_EXPIRED_TITLE;
                    content = error.COUPON_USED_FAILURE_EXPIRED_CONTENT;
                    break;

                // 円谷クーポンAPIでエラー.
                case contractif.WAR04020:
                    title = error.COUPON_USED_FAILURE_TITLE;
                    content = error.COUPON_USED_FAILURE_CONTENT;
                    break;

                // クーポンが他ユーザで使用済み.
                case contractif.WAR04021:
                    title = error.COUPON_USED_FAILURE_OTHER_TITLE;
                    content = error.COUPON_USED_FAILURE_OTHER_CONTENT;
                    break;

                // クーポンの使用可能プラン外.
                case contractif.WAR04024:
                    title = error.COUPON_USED_FAILURE_PLAN_TITLE;
                    content = error.COUPON_USED_FAILURE_PLAN_CONTENT;
                    break;

                default:
            }
            if (title !== "") {
                setCouponErrorDialogState({
                    open: true,
                    title: title,
                    content: content
                });
            }
            else {
                setIsCriticalError(true);
            }
        }
    };

    /**
     * APIエラーにより決済画面を閉じる.
     */
    const apiErrorAccept = () => {
        window.location.reload();
        dispatch(actions.closePopupInputCredit());
    }

    /**
     * タイトル表示.
     */
    const title = () => {
        switch (kind) {
            case credit.REGISTER:
                return "決済情報の登録";
            case credit.CHANGE:
                return "決済情報の変更";
            case credit.PLAN:
            case credit.RENTAL:
            case credit.PLAN_CAMPAIGN:
                return "購入と決済情報の登録";
            case credit.PLAN_EXPIRED:
            case credit.RENTAL_EXPIRED:
            case credit.PLAN_CAMPAIGN_EXPIRED:
                return "購入と決済情報の更新";
            default:
                return "";
        }
    };

    /**
     * 会員プラン決済とレンタル決済で表示を変更.
     */
    const creditTarget = () => {
        if ([credit.PLAN, credit.PLAN_EXPIRED, credit.PLAN_CAMPAIGN, credit.PLAN_CAMPAIGN_EXPIRED].includes(kind)) {
            return credit.planAmount(plan, amount);
        } else {
            return "レンタル料金 (" + Number(amount).toLocaleString() + "円/税込)"
        }
    }

    /**
     * 前文表示.
     */
    const forwardMessage = () => {
        switch (kind) {
            case credit.REGISTER:　// クレジットカード登録.
                return (
                    <>
                        <div className="p-popupBoxWrap__companyTtl">決済情報をご登録ください。</div>
                        <div>クレジットカード払いと一部のデビットカード払いがご利用になれます。<br/>また、バンドルカード・LINEPayなど一部のプリペイドカードもご利用いただけます。</div>
                        <img src="/assets/images/img-company.png" alt="" />
                    </>
                );
            case credit.CHANGE:　// クレジットカード変更.
                return (
                    <>
                        <div className="p-popupBoxWrap__companyTtl">決済情報を変更します。</div>
                        <div>クレジットカード払いと一部のデビットカード払いがご利用になれます。<br/>また、バンドルカード・LINEPayなど一部のプリペイドカードもご利用いただけます。</div>
                        <img src="/assets/images/img-company.png" alt="" />
                    </>
                );
            default:
                const addition = () => {
                    switch (kind) {
                        case credit.PLAN:    // 会員プラン決済.
                            return (
                                <>
                                    <span>クレジットカード払いと一部のデビットカード払いがご利用になれます。<br/>また、バンドルカード・LINEPayなど一部のプリペイドカードもご利用いただけます。</span>
                                    {plan === constantsPlan.STANDARD &&
                                        <span>
                                            <br/>※{constantsPlan.isNone(memberPlan) ? "ご入会時" : "プラン変更時"}の決済に関して<br/>
                                            {constantsPlan.isNone(memberPlan) ? "ご入会" : "変更"}日に当月分の会費が発生します。日割り計算にはなりませんので予めご了承ください。<br/>
                                            継続の際は、会員登録は自動継続となり、次月以降は毎月1日に決済されます。
                                        </span>
                                    }
                                    {plan === constantsPlan.PREMIUM &&
                                        <span>
                                            <br/>※{constantsPlan.isNone(memberPlan) ? "ご入会時" : "プラン変更時"}の決済に関して<br/>
                                            {constantsPlan.isNone(memberPlan) ? "ご入会" : "変更"}日に初年度分の会費が発生します。日割り計算にはなりませんので予めご了承ください。<br/>
                                            継続の際は、会員登録は自動継続となり、翌年以降はご入会月の翌月1日に年額会費が決済されます。
                                        </span>
                                    }
                                </>
                            );
                        case credit.RENTAL:  // レンタル決済.
                            return <span>クレジットカード払いと一部のデビットカード払いがご利用になれます。<br/>また、バンドルカード・LINEPayなど一部のプリペイドカードもご利用いただけます。</span>

                        case credit.PLAN_EXPIRED:   // 会員プラン決済(有効期限切れ).
                        case credit.RENTAL_EXPIRED: // レンタル決済(有効期限切れ).
                            return <span style={{color: "red"}}>カードの有効期限が切れているため、新しいカードの有効期限入力が必要です。</span>

                        case credit.PLAN_CAMPAIGN:
                        case credit.PLAN_CAMPAIGN_EXPIRED:
                            return (
                                <>
                                    {kind === credit.PLAN_CAMPAIGN && <><span>クレジットカード払いと一部のデビットカード払いがご利用になれます。<br/>また、バンドルカード・LINEPayなど一部のプリペイドカードもご利用いただけます。</span></>}
                                    {kind === credit.PLAN_CAMPAIGN_EXPIRED && <span style={{color: "red"}}>カードの有効期限が切れているため、新しいカードの有効期限入力が必要です。</span>}
                                    <div className="p-popupBoxWrap__companyRankCoupon">{campaignName}クーポンが適用されています<br/>{creditUtil.showFreePeriod(freePeriod)}</div>
                                </>
                            );
                        default:
                            return null;
                    }
                }
                return (
                    <div className="p-popupBoxWrap__companyRank">
                        <span className="p-popupBoxWrap__companyRankTtl">{creditTarget()}</span>
                        <div className="p-popupBoxWrap__companyRankTxt">
                            を決済します。
                            <br></br>
                            {addition()}
                        </div>
                        <img src="/assets/images/img-company.png" alt="" />
                    </div>
                );
        }
    };

    /**
     * 注意事項表示.
     */
    const attentionMessage = () => {
        if (!isErrored) {
            return (
                <div className="p-popupBoxWrap__attention">
                    <div className="p-popupBoxWrap__attentionTxt">は入力必須項目です</div>
                </div>
            );
        } else {
            return (
                <div className="p-popupBoxWrap__attention p-popupBoxWrap__attention--error">
                    <div className="p-popupBoxWrap__attentionTxt">は入力必須項目です</div>
                    <span>赤枠内をご記入ください。</span>
                </div>
            );
        }
    };

    /**
     * 決定ボタンの文言表示.
     */
    const DecideButton = () => {
        switch (kind) {
            case credit.REGISTER:
                return "登録";
            case credit.CHANGE:
                return "変更";
            case credit.PLAN:
            case credit.RENTAL:
            case credit.PLAN_CAMPAIGN:
                return "登録して決済する";
            case credit.PLAN_EXPIRED:
            case credit.RENTAL_EXPIRED:
            case credit.PLAN_CAMPAIGN_EXPIRED:
                return "更新して決済する";
            default:
                return "";
        }
    };

    /**
     * 決定ボタン下の注釈文言表示.
     */
    const DecideAttentionMessage = () => {
        switch (kind) {
            case credit.PLAN:
            case credit.RENTAL:
            case credit.PLAN_CAMPAIGN:
            case credit.PLAN_EXPIRED:
            case credit.RENTAL_EXPIRED:
            case credit.PLAN_CAMPAIGN_EXPIRED:
                return (
                    <div class="p-popupBoxWrap__btnWrap">
                        <div class="p-popupBoxWrap__companyRank">
                            <div class="p-popupBoxWrap__companyRankTxt"><span class="p-popupBoxWrap__companyRankTtl">ご登録・決済後のキャンセルは致しかねます。<br/>今一度、ご契約プラン（金額）をご確認ください。</span></div>
                        </div>
                    </div>
                );
        }
    };

    /**
     * 有効期限切れの際は有効期限のラベルを赤文字にする.
     */
    const expirationDateColor = () => {
        return (kind === credit.PLAN_EXPIRED || kind === credit.RENTAL_EXPIRED || kind === credit.PLAN_CAMPAIGN_EXPIRED) ? "red" : "#707070";
    };

    /**
     * フォーム入力中のEnterボタン押下時の処理をキーボードを閉じる（フォーカスを外す）に変更.
     */
    const notEnterAction = (e) => {
        const key = e.keyCode || e.charCode || 0;
        if (key === 13) {
            e.preventDefault();
            document.activeElement.blur();
        }
    };

    /**
     * Eキーが押下された時は入力を受け付けない.
     */
    const notEKeyDown = (e) => {
        const key = e.keyCode || e.charCode || 0;
        if (key === 69) {
            e.preventDefault();
        }
    };

    return (
        <div className="p-popup" id="spsvRoot">
            {/* ローディング表示 */}
            {isLoading && <Progress />}
            {/* クレカ登録失敗警告ダイアログ表示 */}
            {isWarning && <ErrorFrontOne input={{title: "決済情報の登録失敗", content: "入力されたカード情報は有効ではありません。登録情報をお確かめください。 "}} onAccept={() => setIsWarning(false)}/>}
            {/* エラーダイアログ表示 */}
            {isCriticalError && <ErrorFrontOne input={{title: "決済失敗", content: "決済に失敗しました。"}} onAccept={apiErrorAccept}/>}
            {/* クレカ更新失敗警告ダイアログ表示 */}
            {/* {isApiError && <ErrorFrontOne input={{title: "クレジットカード更新失敗", content: "もう一度入力画面を開いて入力してください。"}} onAccept={apiErrorAccept}/>} */}
            {/* クーポン関連エラーダイアログ表示 */}
            {couponErrorDialogState.open && <WarningFront input={{title: couponErrorDialogState.title, content: couponErrorDialogState.content}} onAccept={apiErrorAccept}/>}
            <div className="p-popup__box p-popup__box--largeBox">
                <div className="p-popupBox">
                    <div className="p-popupBoxTtl">
                        <div className="p-popupBoxTtl__txt">{title()}</div>
                    </div>
                    <div className="p-popupBoxWrap">
                        {forwardMessage()}
                        {attentionMessage()}
                        <ul className="p-popupBoxWrap__form">
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory">カード番号（半角数字）</div>
                                <form className="p-popupBoxWrap__formBox" ref={cardNoRef} onKeyPress={notEnterAction}>
                                    <input className="p-popupBoxWrap__example" type="number" placeholder="例) 1234567891234" value={cardNo}
                                        onChange={handleCardNo}
                                        onKeyDown={notEKeyDown}
                                    />
                                </form>
                            </li>
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory" style={{color: expirationDateColor()}}>カード有効期限（半角数字）</div>
                                <div className="p-popupBoxWrap__formWrap">
                                    <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--smallBox" ref={expirationMonthRef} onKeyPress={notEnterAction}>
                                        <input className="p-popupBoxWrap__example p-popupBoxWrap__example--smallBox" type="number" placeholder="mm" value={expirationMonth}
                                            onChange={handleExpirationMonth}
                                            onKeyDown={notEKeyDown}
                                        />
                                    </form>
                                    <div className="p-popupBoxWrap__subTxt">月　/</div>
                                    <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--smallBox" ref={expirationYearRef} onKeyPress={notEnterAction}>
                                        <input className="p-popupBoxWrap__example p-popupBoxWrap__example--smallBox" type="number" placeholder="yy" value={expirationYear}
                                            onChange={handleExpirationYear}
                                            onKeyDown={notEKeyDown}
                                        />
                                    </form>
                                    <div className="p-popupBoxWrap__subTxt">年</div>
                                </div><span className="p-popupBoxWrap__smallTxt">※カードの有効期限の入力は2桁ずつご入力ください。<br></br>（例：2022年9月の場合→09月/22年）</span>
                            </li>
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory">セキュリティコード（半角数字）</div>
                                <div className="p-popupBoxWrap__formWrap p-popupBoxWrap__formWrap--noCenter">
                                    <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--smallBox" ref={securityCodeRef} onKeyPress={notEnterAction}>
                                        <input className="p-popupBoxWrap__example p-popupBoxWrap__example--smallBox" type="password" placeholder="000" value={securityCode} maxLength="4"
                                            onChange={handleSecurityCode}
                                        />
                                    </form><span className="p-popupBoxWrap__smallTxt p-popupBoxWrap__smallTxt--column">※セキュリティコードとは、カードの裏面または表面に記載された3桁もしくは4桁の番号です。</span>
                                </div>
                            </li>
                            <li className="p-popupBoxWrap__formItem">
                                <div className="p-popupBoxWrap__formTxt p-formTxtMandatory">ご本人確認・生月日（半角数字）</div>
                                <div className="p-popupBoxWrap__formWrap">
                                    <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--smallBox" ref={birthMonthRef} onKeyPress={notEnterAction}>
                                        <input className="p-popupBoxWrap__example p-popupBoxWrap__example--smallBox" type="number" placeholder="mm" value={birthMonth}
                                            onChange={handleBirthMonth}
                                            onKeyDown={notEKeyDown}
                                        />
                                    </form>
                                    <div className="p-popupBoxWrap__subTxt">月</div>
                                    <form className="p-popupBoxWrap__formBox p-popupBoxWrap__formBox--smallBox" ref={birthDayRef} onKeyPress={notEnterAction}>
                                        <input className="p-popupBoxWrap__example p-popupBoxWrap__example--smallBox" type="number" placeholder="dd" value={birthDay}
                                            onChange={handleBirthDay}
                                            onKeyDown={notEKeyDown}
                                        />
                                    </form>
                                    <div className="p-popupBoxWrap__subTxt">日</div>
                                </div>
                            </li>
                        </ul>
                        <input type="hidden" id="spsvToken" onClick={creditCardAuth} />
                        <input type="hidden" id="spsvCard" />
                        <div className="c-btnWrap p-popupBoxWrap__btnWrap">
                            <div className="c-btn c-btnWrap__size c-btn--gray" onClick={cancel}>キャンセル</div>
                            <div className="c-btn c-btnWrap__size c-btn--thinRed" onClick={submit}>{DecideButton()}</div>
                        </div>
                        {DecideAttentionMessage()}
                        <div class="p-popupBoxWrap__btnWrap">
※バンドルカードについては、<a target="_blank" class="p-faqList__dtlLink" href="https://vandle.jp/">こちら</a>を参照<br/>※LINEPayについては、<a target="_blank" class="p-faqList__dtlLink" href="https://pay.line.me/portal/jp/main">こちら</a>を参照。<br/>※デビットカードおよびプリペイドカードについてのご利用可否は各カード提供会社にご確認ください。<br/>※デビットカードおよびプリペイドカード共にVISA/Master card/JCBブランドのカードが対象となります。
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-popup__bg"></div>
        </div>
    );
};

export default InputCredit;