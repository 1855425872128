import * as actionTypes from "./actionTypes";


const initState = {
    /** コンテンツリスト. */
    contentsList: [],
    /** 選択中カテゴリー. */
    currentCategory: "C00",
    /** 選択中プラン. */
    currentPlan: "P00",
    /** ランキングメタリスト. */
    ranking_meta: [],
    /** 戻すとき. */
    back : 0,
    /** footerクリック時（URL：search画面時）. */
    footerVal: 0,
    /** レコメンドコンテンツリスト. */
    recommendContentsList: [],
    /** レコメンドクリック情報 */
    clickData: ""
};
let sessionNum = 0
let footerNum = 0

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** 検索コンテンツリスト更新. */
        case actionTypes.UPDATE_SEARCH_CONTENTS_LIST:
            return { ...state, contentsList: action.contentsList };

        /** 選択中カテゴリー更新. */
        case actionTypes.UPDATE_SEARCH_CURRENT_CATEGORY:
            return { ...state, currentCategory: action.currentCategory };

        /** 選択中プラン更新. */
        case actionTypes.UPDATE_SEARCH_CURRENT_PLAN:
            return { ...state, currentPlan: action.currentPlan };
        
        /** ランキングメタJSON取得 成功. */
        case actionTypes.GET_RANKING_META_SUCCESS:
            return { ...state, ranking_meta: action.ranking_meta };

        case actionTypes.BACKTRUE:
            sessionNum++
            return {...state,back: sessionNum};

        case actionTypes.FOOTERSEARCH:
            footerNum++
            return {...state,footerVal: footerNum};

        /** レコメンドコンテンツ取得 成功. */
        case actionTypes.UPDATE_SEARCH_RECOMMEND_CONTENTS_LIST:
            return { ...state, recommendContentsList: action.contentsList, clickData: action.clickData };

        default:
            return state;
    }
    
};

export default reducer;
