import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Parallax from "react-rellax";
import * as screenActions from "../../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { APP_PRIVACY_TITLE } from "../../../../constants/title";
import { setHeadDefault } from "../../../../constants/sns";

/**
 * プライバシーポリシー画面（アプリ対応）.
 */
const Privacy = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.appPrivacy());

        // head設定.
        setHeadDefault(APP_PRIVACY_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo"><img src="/assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination" /></div>
                    <div className="c-static__box">
                        <div className="c-static__boxTop">
                            <div className="c-static__boxTopTtl">アプリケーション・プライバシーポリシー</div>
                        </div>
                        <ul className="c-static__wrap">
                            <li className="c-static__item">
                                <div className="c-static__ttl">1.　利用者情報の取得と利用目的</div>
                                <div className="c-static__dtl">（１）当社が本アプリ経由で自動的に取得する利用者情報（以下、「利用者情報」といいます。）は以下のとおりです。
                                    <table className="c-staticTable">
                                        <tr className="c-staticTable__wrap">
                                            <th className="c-staticTable__ttl">利用する利用者情報</th>
                                            <th className="c-staticTable__ttl">利用者情報の利用目的</th>
                                        </tr>
                                        <tr className="c-staticTable__wrap">
                                            <td className="c-staticTable__item">・Cookie情報(識別ID)<br/>・本アプリの起動時に通知される端末固有ID<br/>・ご利用機種<br/>・会員の契約プラン及び会員ステータス</td>
                                            <td className="c-staticTable__item">本サービス提供の際に利用者を識別するため/サービスの改善等を目的とした利用状況の分析をするため</td>
                                        </tr>
                                        <tr className="c-staticTable__wrap">
                                            <td className="c-staticTable__item">各コンテンツへのアクセス履歴</td>
                                            <td className="c-staticTable__item">サービスの改善等を目的とした利用状況の分析をするため/広告運用のため</td>
                                        </tr>
                                        <tr className="c-staticTable__wrap">
                                            <td className="c-staticTable__item">広告配信用識別子：IDFA/AAID</td>
                                            <td className="c-staticTable__item">当社又は当社以外の事業者の商品及びサービスに関連する広告その他の情報の配信</td>
                                        </tr>
                                        <tr className="c-staticTable__wrap">
                                            <td className="c-staticTable__item">通知用トークン</td>
                                            <td className="c-staticTable__item">プッシュ通知機能の提供のため</td>
                                        </tr>
                                    </table>（２）当社は、当社が別に定める「プライバシーポリシー」その他利用者と当社の間の契約において別段の定めがある場合には、利用者情報を、これらに記載の利用目的の範囲で利用することがあります。<br/>（３）当社は、本アプリ及び本サービスの提供のために、本アプリ以外で当社が適正に取得した利用者の個人情報を利用することがあります。また、当社は利用者情報とこれらの個人情報とを組み合わせた上で、上記の利用目的の範囲で利用することがあります。
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">2．情報収集モジュールによる取得</div>
                                <div className="c-static__dtl">当社は、前条に記載の利用者情報を、本アプリ内に組み込まれた下記の情報収集モジュールにて取得します。情報収集モジュールとは、第三者が提供するプログラムであって、利用者情報を取得・解析するための機能をもつものをいいます。なお、当社は情報収集モジュールで取得した利用者情報を、利用者個人を識別するIDと組み合わせた上で、利用者の属性情報等を付加し、利用する場合があります。<br/><br/>GoogleAnalytics, Firebase<br/>提供会社：Google LLC及びその完全子会社<br/>取得項目：利用者のアプリご利用状況<br/>プライバシーポリシー：<a className="c-static__dtlLink" href="https://www.google.com/intl/ja/policies/privacy/partners/" target="_blank" rel="noreferrer">https://www.google.com/intl/ja/policies/privacy/partners/</a><br/>利用者情報はGoogleの管理するサーバシステムに格納されます。 なお、Googleは当該利用者情報を、Googleが定める利用目的の範囲で利用します。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">3．利用者情報の第三者提供</div>
                                <div className="c-static__dtl">（１）当社は、本アプリにおいて取得した会員情報の一部の取扱いを、TSUBURAYA IMAGINATIONのマーケティング及び今後の新事業への活用のために株式会社NTTドコモに提供します。
                                    <div className="c-static__indent">（２）利用者情報は、前項又は以下の各号に定める場合を除き、第三者に送信され、又は提供されることはありません。<br/>①第2条に定める情報収集モジュール提供者へ送信する場合<br/>②当社との間の契約にて明示的に承諾を取得している場合<br/>③法令に基づく提供、業務委託に伴う委託先への提供等、個人情報保護上、本人の同意を得ることなく第三者への提供が認められる場合</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">4．利用者情報の取得・蓄積・利用に関する同意</div>
                                <div className="c-static__dtl">本アプリ及び本サービスは本アプリケーション・プライバシーポリシーをご確認いただき、内容を理解したうえでご利用ください。ご利用者は本アプリをインストールする際に、本アプリケーション・プライバシーポリシーをご確認ください。また、本アプリケーション・プライバシーポリシーは本アプリ内の利用規約に公開いたします。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">5．利用者情報の取得停止等</div>
                                <div className="c-static__dtl">（１）本アプリでは、利用者情報の取得、利用を停止する手段を提供しておりません。これらを停止したい場合には、本アプリをアンインストールしてください。 なお、アンインストール時点で既に取得済みの利用者情報については、当社は、第1条に定める範囲で引き続き利用することがあります。<br/>（２）利用者は、自己の保有するデバイスの設定を変更することによって、IDFA/AAIDの自動収集の停止及びIDFA/AAIDを利用した行動ターゲティング広告の無効化を行うことができます。自動収集の停止等を希望される場合は、Apple Inc.及びGoogle LLC各社の案内に従って設定を変更してください。<br/>IDFA（Apple Inc.）：<a className="c-static__dtlLink" href="https://support.apple.com/ja-jp/HT202074" target="_blank" rel="noreferrer">https://support.apple.com/ja-jp/HT202074</a><br/>AAID（Google LLC）：<a className="c-static__dtlLink" href="https://support.google.com/googleplay/answer/3405269" target="_blank" rel="noreferrer">https://support.google.com/googleplay/answer/3405269</a></div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">6．利用者情報の取り扱いに関する問い合わせ窓口</div>
                                <div className="c-static__dtl">本アプリ及び本サービスにおける利用者情報の取扱いに関して、ご意見・ご要望がございましたら、下記窓口までご連絡くださいますようお願いします。<br/><br/>■掲載箇所： TSUBURAYA IMAGINATION ＞ FAQ/問合わせ ＞ お問い合わせはこちら<br/>■URL：<a className="c-static__dtlLink" href="https://account.m-78.jp/Account/Home/ContactUs?redirecturl=https%3A%2F%2Fimagination.m-78.jp" target="_blank" rel="noreferrer">https://account.m-78.jp/Account/Home/ContactUs</a></div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">7．本アプリケーション・プライバシーポリシーの変更</div>
                                <div className="c-static__dtl">当社は、本アプリのバージョンアップに伴って、本アプリケーション・プライバシーポリシーを変更することがあります。変更内容については、新バージョンのアプリケーションに付随するアプリケーション・プライバシーポリシーをご参照ください。</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Privacy;
