import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Parallax from "react-rellax";
import * as plan from "../../../constants/plan";
import * as screenActions from "../../../common/status/screen/actions";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import { IMAGINATION_TITLE } from "../../../constants/title";
import { setHeadDefault } from "../../../constants/sns";

/**
 * イマジネーションとは画面.
 */
const Imagination = () => {
    /** Hooks. */
    const dispatch = useDispatch();

    /** 会員プランの金額. */
    const planPrice = useSelector(state => state.Member.planPrice);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        dispatch(screenActions.imagination());

        // head設定.
        setHeadDefault(IMAGINATION_TITLE);
    }, [dispatch]);

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            <Parallax className="l-planetBg rellax" speed={2}></Parallax>
            <div className="l-main">
                <div className="c-static">
                    <div className="c-static__logo"><img src="./assets/images/logo-brand-imagination.png" alt="TSUBURAYA imagination" /></div>
                    <div className="c-static__box">
                        <ul className="c-static__wrap">
                            <li className="c-static__item"><span className="c-static__tag">サービス名称</span>
                                <div className="c-static__ttl">TSUBURAYA IMAGINATION</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">サービス概要</div>
                                <div className="c-static__dtl">円谷プロダクションの作品や世界観を楽しんでいただくためのデジタルプラットフォームです。 ウルトラマンシリーズをはじめとした映像作品やオリジナル新作など、いつでもどこでもお楽しみいただけます。さらに、オンラインイベントや限定商品販売・チケット先行販売などの体験でイマジネイティブな毎日をあなたにお届けします。</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">プランと料金</div>
                                <div className="c-static__dtl">無料<br/>{"スタンダード　月額　" + Number(planPrice[plan.STANDARD_WITHOUT_TAX]) + "円（税込 " + Number(planPrice[plan.STANDARD]) + "円）"}<br/>{"プレミアム　年額　" + Number(planPrice[plan.PREMIUM_WITHOUT_TAX]).toLocaleString() + "円（税込 " + Number(planPrice[plan.PREMIUM]).toLocaleString() + "円）"}</div>
                            </li>
                            <li className="c-static__item c-static__item--plan">
                                <div className="p-loggedPlanList">
                                    <div className="p-loggedPlanList__txt">プランについて</div>
                                </div>
                                <div className="p-popupPlanList p-popupPlanList--logged">
                                <ul className="p-popupPlanList__name p-popupPlanList__name--logged">
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--free">無料</li>
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--standard">スタンダード</li>
                                    <li className="p-popupPlanList__nameItem p-popupPlanList__nameItem--premium">プレミアム</li>
                                </ul>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged"><span className="p-popupPlanList__middleTxt p-popupPlanList__middleTxt--logged">{"月" + Number(planPrice[plan.STANDARD_WITHOUT_TAX])}</span><span className="p-popupPlanList__smallTxt p-popupPlanList__smallTxt--logged">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.STANDARD]) + "円（税込）"}</span></p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged"><span className="p-popupPlanList__middleTxt p-popupPlanList__middleTxt--logged">{"年" + Number(planPrice[plan.PREMIUM_WITHOUT_TAX]).toLocaleString()}</span><span className="p-popupPlanList__smallTxt p-popupPlanList__smallTxt--logged">円(税抜）</span><br/><span className="p-popupPlanList__smallTxt">{Number(planPrice[plan.PREMIUM]).toLocaleString() + "円（税込）"}</span></p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--play c-picIconPlay"></div>
                                    <div className="p-popupPlanListTtl__txt">映像</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--mb"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">ウルトラマン<br/>シリーズや<br/>オリジナル新作など<br/>約<span className="p-popupPlanList__largeTxt p-popupPlanList__largeTxt--logged">1,400</span><br/>エピソードが見放題！</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double p-popupPlanList__round--mb"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">スタンダードプラン<br/>に加え、<br/>円谷作品、他<br/>約<span className="p-popupPlanList__largeTxt p-popupPlanList__largeTxt--logged">1,900</span><br/>エピソードが見放題！</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                        <div className="c-icon__Txt">LIVE</div>
                                    </div>
                                    <div className="p-popupPlanListTtl__txt">オンラインイベント</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> アーカイブ配信は個別課金で視聴可能（※）</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">一部のオンライン<br/>イベントに参加可能！</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">プレミアム限定の<br/>オンライン企画も！</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--article c-picIconarticle"></div>
                                    <div className="p-popupPlanListTtl__txt">読み物</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free p-popupPlanList__dtlItem">
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--centerTxt">一部視聴可能</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged">小説、コミックス、<br/>コラム、解説等、<br/>一部コンテンツを除き、見放題</p>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> すべて見放題</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl p-popupPlanListTtl--bigSize">
                                    <div className="p-popupPlanListTtl__iconRect c-icon c-icon--rect">
                                        <div className="c-icon__Txt">TICKET</div>
                                    </div>
                                    <div className="p-popupPlanListTtl__txt">イベントチケット先行受付<br/>（リアル／オンライン）</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div class="p-popupPlanList__round p-popupPlanList__round--double"></div>
                                        <p class="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"> 最速受付</p>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                                    <div className="p-popupPlanListTtl__txt">会員限定グッズ</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                    </li>
                                </ul>
                                <div className="p-popupPlanListTtl">
                                    <div className="p-popupPlanListTtl__icon c-icon c-icon--cart c-picIconCart"></div>
                                    <div className="p-popupPlanListTtl__txt">プレミアム限定グッズ販売</div>
                                </div>
                                <ul className="p-popupPlanList__dtl">
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--free">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--standard">
                                        <div className="p-popupPlanList__line"></div>
                                    </li>
                                    <li className="p-popupPlanList__dtlItem p-popupPlanList__dtlItem--premium">
                                        <div className="p-popupPlanList__round"></div>
                                        <p className="p-popupPlanList__txt p-popupPlanList__txt--logged p-popupPlanList__txt--delTxt p-popupPlanList__txt--delTxt--logged"></p>プレミアムセールへの優先参加・<br/>ノベルティの付与なども！
                                    </li>
                                </ul>
                                <div className="p-popupPlanBox__attention p-popupPlanBox__attention--about">※一部イベントは対象とならない場合があります。</div>
                                </div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">提供環境</div>
                                <div className="c-static__dtl">Webブラウザ、アプリ</div>
                            </li>
                            <li className="c-static__item">
                                <div className="c-static__ttl">ご利用方法</div>
                                <div className="c-static__dtl"><b>ご利用には、TSUBURAYAアカウントとdアカウントの登録が必要です。</b>ドコモの携帯電話をお持ちでない方も含め、通信会社を問わず、どなたでもご利用いただけます。<br/><span className="c-static__dtlBdr">TSUBURAYAアカウントとは</span><br/>TSUBURAYAアカウントは、円谷プロの各種オンラインサービスをご利用いただくのに必要な共通IDです。登録は無料です。</div>
                            </li>
                            <li className="c-static__item">
                                <div class="c-static__ttl">お支払い方法</div>
                                <div class="c-static__dtl">クレジットカード払い、一部デビットカードまたは一部プリペイドカード払いになります。<br/>クレジットカード払いは(VISA/mastercard/JCB/AMERICAN EXPRESS/Diners Club)がご利用になれます。<br/>また、バンドルカードなど一部のプリペイドカードもご利用いただけます。</div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Imagination;
