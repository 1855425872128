/**
 * コンテンツのjson key.
 */

/** コンテンツID. */
export const CRID = "crid";

/** コンテンツID（cid）. */
export const CID = "cid";

/** 親コンテンツID. */
export const PARENT_CRID = "parentCrid";

/** サムネイル画像(横長)URL. */
export const THUMBNAIL_L = "thumbnailLandscape";

/** サムネイル画像(縦長)URL. */
export const THUMBNAIL_P = "thumbnailPortrait";

/** 特集バナー画像. */
export const BANNER_IMAGE_URL = "bannerImageUrl";

/** SP用の特集バナー画像. */
export const BANNER_SMALL_IMAGE_URL = "bannerSmallImageUrl";

/** シリーズタイトル. */
export const SERIES_TITLE = "seriesTitle";

/** タイトル. */
export const TITLE = "title";

/** タイトル帯. */
export const COMMENT = "comment";

/** エピソードタイトル. */
export const EPI_TITLE = "epiTitle";

/** エピソード話数. */
export const EPI_NUMBER = "epiNumber";

/** 紹介文(長). */
export const SYNOPSIS_LONG = "synopsisLong";

/** コンテンツ種別. */
export const CONTENT_TYPE = "contentType";

/** アイコン種別. */
export const ICON_TYPE = "iconType";

/** プランIDリスト. */
export const PLAN_LIST = "planList";

/** 無料フラグ. */
export const IS_FREE = "isFree";

/** 一覧表示フラグ. */
export const LIST_FLG = "listFlg";

/** 動画の再生時間. */
export const VIDEO_DUR = "videoDur";

/** 動画の視聴中位置. */
export const STOP_POSITION = "stopPosition";

/** 配信開始日. */
export const APPLY_START_DATE = "applyStartDate";

/** 配信終了日. */
export const APPLY_END_DATE = "applyEndDate";

/** 公開開始日. */
export const DISP_START = "dispStart";

/** プラネットID. */
export const PLANET_ID = "planetId";

/** プラネットアイコン. */
export const PLANET_ICON = "planetIcon";

/** プラネットロゴ. */
export const PLANET_TITLE_LOGO = "planetTitleLogo";

/** プラネット横長画像. */
export const PLANET_THUMBNAIL_L = "planetLandscapeImage";

/** プラネット縦長画像. */
export const PLANET_THUMBNAIL_P = "planetPortraitImage";

/** プラネットタイトル. */
export const PLANET_TITLE = "planetSynopTitle";

/** プラネット詳細. */
export const PLANET_SYNOPSIS = "planetSynopsis";

/** キャンペーンID. */
export const CAMPAIGN_ID = "campaignId";

/** 演者名リスト. */
export const CREDITS_LIST = "creditsList";

/** 演者名リスト（名前のみ。キャラクタを含む。）. */
export const KEYWORDS_LIST = "keywordsList";

/** お気に入りボタン表示/非表示. */
export const FAVORITE_BUTTON = "favoriteButton";

/** SNSボタン表示/非表示. */
export const SNS_BUTTON = "snsButton";

/** TVOD購入可能プランリスト. */
export const TVOD_PLAN_LIST = "tvodPlanList";

/** 特典元CRID. */
export const GIFT_SOURCE_CRID = "giftSourceCrid";

/** コンバージョン通知対象フラグ. */
export const CONVERSION_TARGET = "recommendAction";

/** SEOキーワード. */
export const SEO_LIST = "seoList";
