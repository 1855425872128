import * as actionTypes from "./actionTypes";

const initState = {
    popupLecture: false
};

const reducer = (state = initState, action) => {
    switch (action.type) {
        /** レクチャー画面を表示する. */
        case actionTypes.OPEN_POPUP_LECTURE:
            return { ...state, popupLecture: true };

        /** レクチャー画面を閉じる. */
        case actionTypes.CLOSE_POPUP_LECTURE:
            return { ...state, popupLecture: false };

        /** デフォルト. */
        default:
            return state;
    }
};

export default reducer;
