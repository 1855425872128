/**
 * 静的コンテンツ.
 */

/** ECリンク. */
export const EC_LINK = "tsuburaya";

/** チケットリンク. */
export const TICKET_LINK = "lawson";

/** eplusリンク. */
export const EPLUS_LINK = "eplus";

/** CNプレイガイドリンク. */
export const CN_PLAYGUIDE_LINK = "cnplayguide";

/** ダウンロードリンク. */
export const DOWNLOAD_LINK = "download";

/** サムネイル. */
export const THUMBNAIL = "thumbnail";

/** サムネイルONフラグ. */
export const THUMBNAIL_ON = "on";

/** コンテンツページリンク. */
export const CONTENTS_LINK = "contents_link";

/** 外部リンク. */
export const CONTRACT_RESTRICT_LINK = "contract_restrict_link";

/** コミック. */
export const COMIC = "comic";

/** ムビチケ. */
export const CONTRACT_RESTRICT_MT = "contract_restrict_mt";

/** プラン充足・未充足による表示分け. */
export const CONTRACT_RESTRICT = "contract_restrict";

/** プラン充足. */
export const TRUE_FLAG = "true";

/** プラン未充足. */
export const FALSE_FLAG = "false";

/** アンケート項目. */
export const QUESTION = "question";

/** アンケート回答. */
export const ANSWER = "answer";

/** コミック画像へのパス. */
export const COMIC_PATH = "comic";

/** コミック画像拡張子. */
export const COMIC_EXTENSION = ".jpg";

/** クエリキー（クーポン）. */
export const QUERY_COUPON = "coupon";

/** クーポン入力フォーム. */
export const COUPON_CODE = "coupon_code";

/** 通常クーポンボタン. */
export const COUPON_USE = "coupon_use";

/** 無料キャンペーンのクーポンボタン. */
export const COUPON_USE_FREE_PLAN = "coupon_use_free_plan";

/** アプリ非表示要素. */
export const NOT_DISPLAY = "not_display";

/** Androidアプリ非表示要素. */
export const APP_ANDROID = "android";

/** iOSアプリ非表示要素. */
export const APP_IOS = "ios";

/** 全アプリ非表示要素. */
export const APP_ALL = "app";

/** Web非表示要素. */
export const WEB = "web";

/** 図鑑. */
export const CONTENTS_LINK_CUBE_GROUP = "contents_link_cube_group";

/** クーポンチケット行使済み. */
export const USED_COUPON = "used_coupon";

/** クーポンチケット未行使. */
export const NOT_USED_COUPON = "not_used_coupon";

/** 有効期間判定. */
export const EXPIRED_PERIOD = "expired_period";

/** 有効期間判定: 有効期間前. */
export const BEFORE = "before";

/** 有効期間判定: 有効期間中. */
export const DURING = "during";

/** 有効期間判定: 有効期間後. */
export const AFTER = "after";

/** 退会遷移. */
export const LINK_WITHDRAWAL = "link_withdrawal";

/** 続きを読むボタンCSS. */
export const NEXT_BUTTON_DOM = "next-button-dom";

/** 送信するボタンCSS. */
export const SEND_BUTTON_DOM = "send-button-dom";

/** 遷移先URL（チケットサイト用） */
export const TICKET_ACTION = "action";

/** プラン選択画面表示用クラス */
export const LINK_PLAN_SELECT = "link_plan_select";
