import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import * as creditActions from "../../../common/popup/credit/actions";
import * as param from "../../../constants/redirectParameter";
import * as app from "../../../constants/app";
import * as error from "../../../constants/error";
import * as apiUtil from "../../../constants/apiUtil";
import * as authIf from "../../../middleware/authif";
import ScrollToTopOnMount from "../../../common/scrollToTopOnMount/ScrollToTopOnMount";
import Error from "../../dialog/Error";
import Warning from "../../dialog/Warning";
import DcmAccountLink from "../../../common/popup/login/DcmAccountLink";

import * as commonActions from "../../../common/status/actions";
import { historyReplaceCoupon, historyReplaceAppBranch, historyReplaceTop } from "../../../historys";
import * as creditUtil from "../../../common/popup/credit/creditUtils";
import { saveCreditInfo } from "../../../common/status/localCredit/actions";

/**
 * クッションページ.
 */
const Cushion = () => {
    /** リダイレクトパラメータ種別. */
    const [kind, setKind] = useState("");
    /** クレカ情報取得API失敗の表示/非表示. */
    const [isGetCreditFailure, setIsGetCreditFailure] = useState(false);

    /** Hooks. */
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    /** ログイン直前に閲覧していたページ. */
    const prevPage = useSelector(state => state.Cushion.prevPage);
    /** クーポンコード. */
    const couponCode = useSelector(state => state.Coupon.couponCode);
    /** クレジットカード番号. */
    const creditNumber = useSelector(state => state.Member.creditNumber);
    /** 選択したプラン. */
    const selectedPlan = useSelector(state => state.LocalCredit.plan);
    /** 決済金額. */
    const amount = useSelector(state => state.LocalCredit.amount);
    /** 決済種別. */
    const creditKind = useSelector(state => state.LocalCredit.kind);

    /**
     * ログイン直前に閲覧していたページに遷移.
     */
    const goPrevPage = useCallback(() => {
        history.replace(prevPage);
    }, [history, prevPage]);

    /**
     * 画面描画時に実行する処理.
     */
    useEffect(() => {
        // 先頭文字列(?q=)を除いたクエリ文字列を取得.
        const query = location.search.slice(3);

        switch(query) {
            case param.TSUBURAYA_LOGIN_SECOND: // 円谷ログイン成功（2回目以降）.
                // ログイン直前に閲覧していたページに遷移.
                dispatch(commonActions.init(true));
                if (couponCode !== "") {
                    // クーポンコードがLocalStorageにある場合はQRクッションページに遷移.
                    historyReplaceCoupon(history, couponCode);
                } else if (prevPage === "/app/branch" && apiUtil.getXToken() !== "") {
                    // 3分岐画面からログイン成功した場合、TOPに遷移.
                    // X-TOKENが存在すればログイン成功とする.
                    historyReplaceTop(history);
                } else {
                    goPrevPage();
                }
                break;
            case param.DOCOMO_LOGIN_SUCCESS: // dアカウントログイン成功.
                // ログイン直前に閲覧していたページに遷移して会員プラン選択画面を表示.
                dispatch(commonActions.init(true));
                goPrevPage();
                break;
            case param.TSUBURAYA_DOUBLE_SUCCESS: // 円谷再認証成功.
                // ログイン直前に閲覧していたページに遷移して、決済確認画面を表示.
                dispatch(commonActions.init(true));
                goPrevPage();

                // 直リンク対策. JavaScriptの処理からの正規遷移時のみ確認画面へ進む.
                if (![creditUtil.PLAN, creditUtil.PLAN_CAMPAIGN, creditUtil.RENTAL].includes(creditKind)) {
                    break;
                }

                // 決済確認画面へ移動する.
                creditUtil.getCredit(dispatch, creditNumber).then((nextAction) => {
                    switch(nextAction) {
                        case creditUtil.CARD_REGISTERED: // クレカ登録済みの場合.
                            dispatch(creditActions.openPopupSimpleInputCredit(creditKind + creditUtil.ONLY, selectedPlan, amount));
                            break;
                        case creditUtil.CARD_EXPIRED: // クレカ有効期限の場合.
                            dispatch(creditActions.openPopupInputCredit(creditKind + creditUtil.EXPIRED, selectedPlan, amount));
                            break;
                        case creditUtil.CARD_NEW: // クレカ未登録の場合.
                            dispatch(creditActions.openPopupInputCredit(creditKind, selectedPlan, amount));
                            break;
                        default:
                    }
                    dispatch(saveCreditInfo({plan: "", amount: 0, kind: ""}));
                }, () => {
                    setIsGetCreditFailure(true);
                    dispatch(saveCreditInfo({plan: "", amount: 0, kind: ""}));
                });

                break;
            default:
                setKind(query);
                document.body.classList.add("is-fixed");
        }
        return () => document.body.classList.remove("is-fixed");
        // eslint-disable-next-line
    }, [location, history, dispatch, goPrevPage]);


    /**
     * リダイレクトパラメータによって表示するダイアログを切替.
     */
    const Dialog = () => {
        const yes = "ログイン";
        const no = "キャンセル";
        let title = "";
        let content = "";

        // アプリでdアカウント未連携（エラー含む）の場合アプリ専用ダイアログを表示.
        if (app.isAllApp() && kind && ![param.TSUBURAYA_LOGIN_FAILURE, param.TSUBURAYA_LOGIN_SECOND].includes(kind)) {
            title = error.APP_DACCOUNT_FAILURE_TITLE;
            content = error.APP_DACCOUNT_FAILURE_CONTENT;

            return (
                <Warning
                    input={{title: title, content: content}}
                    onAccept={() => historyReplaceAppBranch(history)}
                />
            );
        }

        /**
         * 再度ログインする画面へ遷移.
         */
        const relogin = (type, destination) => {
            authIf.postLogin({[authIf.TYPE]: type, [authIf.DESTINATION]: destination})
        };

        switch(kind) {
            case param.TSUBURAYA_LOGIN_FIRST: // 円谷ログイン成功（初回登録時）.
                // dアカウント紐付画面表示.
                return (<DcmAccountLink />);
            case param.TSUBURAYA_LOGIN_LINKING_CLEAR: // 円谷ログイン成功（dアカ紐付解除）.
                title = "dアカウントが削除されています。再度dアカウントを取得し、リンクしてください。"

                return (
                    <Error
                        input={{title: title, content: "", yes: "リンク", no: no}}
                        onAccept={() => relogin(authIf.WEB_RELINK, authIf.DACCOUNT)}
                        onReject={() => historyReplaceTop(history)}
                    />
                );
            case param.TSUBURAYA_LOGIN_FAILURE: // 円谷ログイン失敗.
                title = "ログインに失敗しました。時間をおいて再度試してください。";

                const loginType = app.isAllApp() ? authIf.APP_LOGIN : authIf.WEB_LOGIN;

                // エラーダイアログ表示.
                return (
                    <Error
                        input={{title: title, content: "", yes: yes, no: no}}
                        onAccept={() => relogin(loginType, authIf.TSUBURAYA)}
                        onReject={goPrevPage}
                    />
                );
            case param.DOCOMO_LOGIN_FAILURE: // dアカウントログイン失敗.
                title = "ログインに失敗しました。時間をおいて再度試してください。";

                // エラーダイアログ表示.
                return (
                    <Error
                        input={{title: title, content: "", yes: yes, no: no}}
                        onAccept={() => relogin(authIf.WEB_LOGIN, authIf.DACCOUNT)}
                        onReject={goPrevPage}
                    />
                );
            case param.DOCOMO_RELINK_FAILURE: // dアカウント再紐付失敗.
                title = "dアカウントとのリンクに失敗しました。時間をおいて再度試してください。";

                // エラーダイアログ表示.
                return (
                    <Error
                        input={{title: title, content: "", yes: "リンク", no: no}}
                        onAccept={() => relogin(authIf.WEB_RELINK, authIf.DACCOUNT)}
                        onReject={goPrevPage}
                    />
                );
            case param.DOCOMO_LOGIN_DUPLICATE: // dアカウント重複（ログイン時）.
                title = "dアカウントはすでにリンクされています。他のアカウントで再度試してください。";
                // エラーダイアログ表示.
                return (
                    <Error
                        input={{title: title, content: "", yes: yes, no: no}}
                        onAccept={() => relogin(authIf.WEB_LOGIN, authIf.DACCOUNT_FORCE)}
                        onReject={goPrevPage}
                    />
                );
            case param.DOCOMO_RELINK_DUPLICATE: // dアカウント重複（再紐付時）.
                title = "dアカウントはすでにリンクされています。他のアカウントで再度試してください。";

                // エラーダイアログ表示.
                return (
                    <Error
                        input={{title: title, content: "", yes: yes, no: no}}
                        onAccept={() => relogin(authIf.WEB_RELINK, authIf.DACCOUNT_FORCE)}
                        onReject={goPrevPage}
                    />
                );
            case param.TSUBURAYA_DOUBLE_FAILURE: // 円谷再認証失敗.
                title = error.TSUBURAYA_DOUBLE_FAILURE_TITLE;
                content = error.TSUBURAYA_DOUBLE_FAILURE_CONTENT;
                return (
                    <Error
                        input={{title: title, content: content, yes: "再認証", no: no}}
                        onAccept={() => relogin(authIf.WEB_DOUBLE, authIf.TSUBURAYA)}
                        onReject={goPrevPage}
                    />
                )
            default:
                return null;
        }
    };

    return (
        <div>
            {/* 遷移時に画面トップに移動. */}
            <ScrollToTopOnMount />
            {Dialog()}
            {/* クレカ情報取得API失敗. */}
            {isGetCreditFailure && <Warning input={{title: "クレジットカード情報取得失敗", content: "クレジットカード情報が取得できません。"}} onAccept={() => setIsGetCreditFailure(false)}/>}
        </div>
    );
};

export default Cushion;
